import React, { Fragment, useContext } from "react";
import { CouponAddStateContext } from "../MenuCouponContext/MenuCouponAddContext";
import { CouponStateContext } from "../MenuCouponContext/MenuCouponContext";
import { useTranslation } from "react-i18next";

const CouponApplyTypeSection = ({ children }) => {
  return (
    <section className="couponApplyType">
      {React.Children.map(children, (child) => child)}
    </section>
  );
};

const CouponSectionTitle = () => {
  const { t } = useTranslation("couponSystem");
  return <h2>{t("addCoupon.offered")}</h2>;
};

const CouponLimited = () => {
  const { t, i18n } = useTranslation("couponSystem");
  const { CouponAddState, CouponAddDispatch } = useContext(
    CouponAddStateContext
  );
  const { couponSetting, checkSetting, originStatus } = CouponAddState;
  const { countLimited, voucherCount, sentCount } = couponSetting;

  const { CouponState } = useContext(CouponStateContext);
  const { editType } = CouponState;

  let errorStyle = "";

  if (checkSetting && countLimited === 1) {
    if (voucherCount === "" || voucherCount === "0") {
      errorStyle = "errorStyle";
    }
  }

  const handleCountLimited = (countLimited) => {
    CouponAddDispatch({ type: "setCountLimited", countLimited });
  };

  const handleApplyCount = (e) => {
    const reg = /^\d+$/;
    const txt = e.target.value;
    const regTest = reg.test(txt);
    if (txt !== "" && !regTest) {
      return window.app.alert.setMessage(
        t("addCoupon.pls_enterDigits"),
        "error"
      );
    }
    CouponAddDispatch({ type: "setVoucherCount", count: txt });
  };

  const handleFocus = () => {
    CouponAddDispatch({ type: "setCountLimited", countLimited: 1 });
  };

  const renderLimited = () => {
    if (
      editType === "edit" &&
      (originStatus === "enable" || originStatus === "disable")
    ) {
      //已進行的優惠券修張數不得小於已發張數
      return (
        <Fragment>
          <label htmlFor="couponType_1">
            <div className={`${errorStyle}`} data-lang={i18n.language}>
              <input
                type="tel"
                placeholder={t("addCoupon.placeholder_enterCoupons")}
                value={voucherCount}
                onFocus={handleFocus}
                onChange={(e) => handleApplyCount(e)}
              />
              <span>{t("addCoupon.couponLimits")}</span>
            </div>
          </label>
          <span>{t("addCoupon.sentCount", { X: sentCount })}</span>
        </Fragment>
      );
    }

    return (
      <div className="couponRow">
        <input
          type="radio"
          name="couponType"
          id="couponType_1"
          checked={countLimited === 1}
          onChange={() => handleCountLimited(1)}
        />
        <label htmlFor="couponType_1">
          <div className={`${errorStyle}`} data-lang={i18n.language}>
            <input
              type="tel"
              placeholder={t("addCoupon.placeholder_enterCoupons")}
              value={voucherCount}
              onFocus={handleFocus}
              onChange={(e) => handleApplyCount(e)}
            />
            <span>{t("addCoupon.couponLimits")}</span>
          </div>
        </label>
      </div>
    );
  };

  if (
    editType === "edit" &&
    (originStatus === "enable" || originStatus === "disable") &&
    countLimited === 0
  )
    return null;
  return renderLimited();
};

const CouponUnlimited = () => {
  const { t } = useTranslation("couponSystem");
  const { CouponAddState, CouponAddDispatch } = useContext(
    CouponAddStateContext
  );
  const { couponSetting, originStatus } = CouponAddState;
  const { countLimited } = couponSetting;

  const { CouponState } = useContext(CouponStateContext);
  const { editType } = CouponState;

  const handleCountLimited = (countLimited) => {
    CouponAddDispatch({ type: "setCountLimited", countLimited });
  };

  const renderUnlimited = () => {
    if (
      editType === "edit" &&
      (originStatus === "enable" || originStatus === "disable")
    ) {
      return <p>{t("coupon.unlimited")}</p>;
    }

    return (
      <div className="couponRow">
        <input
          type="radio"
          name="couponType"
          id="couponType_2"
          checked={countLimited === 0}
          onChange={() => handleCountLimited(0)}
        />
        <label htmlFor="couponType_2">
          <span>{t("coupon.unlimited")}</span>
        </label>
      </div>
    );
  };

  if (
    editType === "edit" &&
    (originStatus === "enable" || originStatus === "disable") &&
    countLimited === 1
  )
    return null;
  return renderUnlimited();
};

CouponApplyTypeSection.Title = CouponSectionTitle;
CouponApplyTypeSection.Limited = CouponLimited;
CouponApplyTypeSection.Unlimited = CouponUnlimited;

export default CouponApplyTypeSection;
