import React, { Fragment, useCallback, useContext } from "react";
import { CouponAddStateContext } from "../MenuCouponContext/MenuCouponAddContext";
import { useTranslation } from "react-i18next";

const MenuCouponUploadImg = ({ children }) => {
  return (
    <section className="uploadImgSection">
      {React.Children.map(children, (child) => child)}
    </section>
  );
};

const MenuCouponUploadImgTitle = () => {
  const { t } = useTranslation("couponSystem");
  return <h2>{t("addCoupon.coverImage")}</h2>;
};

const MenuCouponLandscape = ({ handleUploadImg, renderEditCropIcon }) => {
  const { i18n } = useTranslation();
  const { CouponAddState } = useContext(CouponAddStateContext);
  const { couponSetting, checkSetting } = CouponAddState;
  const { coverImgFile, coverURL } = couponSetting;

  const renderLandscape = () => {
    if (coverImgFile === null && coverURL === "") {
      return (
        <Fragment>
          <input
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            id="upload_landscape"
            className="file-upload__input"
            onChange={(e) => handleUploadImg(e, "setLandscapeImg")}
          />
          <label
            htmlFor="upload_landscape"
            className="add_landscape"
            data-lang={i18n.language}
          />
        </Fragment>
      );
    }

    if (coverURL !== "") {
      return (
        <Fragment>
          {renderEditCropIcon("landscape", "setDeleteLandscape")}
          <figure>
            <img src={coverURL} alt="" />
          </figure>
        </Fragment>
      );
    }
  };

  const errorStyle =
    checkSetting && coverImgFile === null && coverURL === ""
      ? "errorStyle"
      : "";
  return <div className={`landscape ${errorStyle}`} data-lang={i18n.language}>{renderLandscape()}</div>;
};

const MenuCouponPortrait = ({ handleUploadImg, renderEditCropIcon }) => {
  const { i18n } = useTranslation();
  const { CouponAddState } = useContext(CouponAddStateContext);
  const { couponSetting, checkSetting } = CouponAddState;
  const { thumbnailImgFile, thumbnailURL } = couponSetting;

  const renderPortrait = () => {
    if (thumbnailImgFile === null && thumbnailURL === "") {
      return (
        <Fragment>
          <input
            type="file"
            accept="image/*"
            id="upload_portrait"
            className="file-upload__input"
            onChange={(e) => handleUploadImg(e, "setPortraitImg")}
          />
          <label
            htmlFor="upload_portrait"
            className="add_landscape"
            data-lang={i18n.language}
          />
        </Fragment>
      );
    }

    if (thumbnailURL !== "") {
      return (
        <Fragment>
          {renderEditCropIcon("portrait", "setDeletePortrait")}

          <figure>
            <img src={thumbnailURL} alt="" />
          </figure>
        </Fragment>
      );
    }
  };

  const errorStyle =
    checkSetting && thumbnailImgFile === null && thumbnailURL === ""
      ? "errorStyle"
      : "";
  return <div className={`portrait ${errorStyle}`} data-lang={i18n.language}>{renderPortrait()}</div>;
};

const UploadImgDescription = () => {
  const { t } = useTranslation("couponSystem");
  return (
    <div className="uploadImgDescription">
      <p>{t("addCoupon.coverImage_note")}</p>
    </div>
  );
};

const UploadImgHOC = (Component) =>
  function Wrap() {
    const { t } = useTranslation("couponSystem");
    const { CouponAddDispatch } = useContext(CouponAddStateContext);

    const toCrop = useCallback(
      (imgType) => {
        CouponAddDispatch({ type: "setCropImg", imgType });
        CouponAddDispatch({ type: "setShowCropper", showCropper: true });
      },
      [CouponAddDispatch]
    );

    const handleUploadImg = useCallback(
      (e, actionType) => {
        e.preventDefault();

        const reader = new FileReader();
        const file = e.target.files[0];

        if (file.size > 2097152) {
          window.app.alert.setMessage(
            t("settings:maximumFileSize2MB_2", { number: 2 }),
            "error"
          );
        } else {
          reader.onloadend = (e) => {
            const blob = new Blob([file], { type: "image/png" });
            const objURL = window.URL.createObjectURL(file);

            if (actionType === "setLandscapeImg") {
              CouponAddDispatch({
                type: "setLandscapeImg",
                coverImgFile: blob,
                coverURL: objURL,
              });

              toCrop("landscape");
            }

            if (actionType === "setPortraitImg") {
              CouponAddDispatch({
                type: "setPortraitImg",
                thumbnailImgFile: blob,
                thumbnailURL: objURL,
              });

              toCrop("portrait");
            }
          };
          reader.readAsDataURL(file);
        }
      },
      [CouponAddDispatch, toCrop, t]
    );

    const removeImg = useCallback(
      (actionType) => {
        CouponAddDispatch({ type: actionType });
      },
      [CouponAddDispatch]
    );

    const renderEditCropIcon = useCallback(
      (imgType, setImgType) => {
        return (
          <Fragment>
            <button className="editCrop" onClick={() => toCrop(imgType)} />
            <button
              className="removeImg"
              onClick={() => removeImg(setImgType)}
            />
          </Fragment>
        );
      },
      [removeImg, toCrop]
    );

    return (
      <Component
        handleUploadImg={handleUploadImg}
        renderEditCropIcon={renderEditCropIcon}
      />
    );
  };

MenuCouponUploadImg.Title = MenuCouponUploadImgTitle;
MenuCouponUploadImg.Landscape = UploadImgHOC(MenuCouponLandscape);
MenuCouponUploadImg.Portrait = UploadImgHOC(MenuCouponPortrait);
MenuCouponUploadImg.Description = UploadImgDescription;

export default MenuCouponUploadImg;
