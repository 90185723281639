import React from "react";

const MenuCouponEntry = ({ children }) => (
  <div className="menuCouponWrap newModuleWrap system-layout">
    {children}
    <div id="modalPortal" />
  </div>
);

export default MenuCouponEntry;
