import React from "react";
import { useTranslation } from "react-i18next";
import { Space } from "antd";

function EditButton({ showModal }) {
  const { t } = useTranslation("settings");
  return (
    <button className="button-secondary button-setting" onClick={showModal}>
      <Space size={4} align="center">
        <div className="icon-sm edit-pen-icon" />
        {t("edit")}
      </Space>
    </button>
  );
}

export default EditButton;
