import $ from 'jquery';

export default class QueuePanelAPI {
	/*
        [Payload]
        "queue_group": <queue_id:int>,
        "name": string,
        "phone": string,
        "gender": "male"/"female",
        "people_count": int,

        [Reponse]
        "id": 21,
        "queue_group_id": 16,
        "name": "wwws",
        "phone": "0900",
        "email": "",
        "gender": "male",
        "waiting_number": 1006,
        "people_count": 1,
        "status": "waiting",
        "source": "online",
        "is_notified": false,
        "created_at": "2018-11-09T16:08:01.754+08:00",
        "updated_at": "2018-11-09T16:08:01.754+08:00",
        "tag": 3,
        "group_name": "Q1"
    */

	createQueueRecord(recordParam) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/queue_records',
				data: recordParam,
				method: 'POST',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((data) => {
					reject(data);
				});
		});
	}

	queryRecord(keyword) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: window.domain + '/dashboard/queue_records/search?keyword=' + keyword,
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					const error = new Error('Query 失敗');
					reject(error);
				});
		});
	}

	// date format: YYYY-MM-dd
	getQueueRecord(status, dateString) {
		return new Promise(function(resolve, reject) {
			if (dateString !== undefined) {
				// console.log('queueRecord API Endpoint:' + window.domain + '/dashboard/queue_record/?status='+status + '&date=' + dateString)
				$.ajax({
					url: window.domain + '/dashboard/queue_records/?status=' + status + '&date=' + dateString,
					method: 'GET',
					xhrFields: { withCredentials: true }
				})
					.done((data) => {
						resolve(data);
					})
					.fail(() => {
						const error = new Error('取得 Queue Record 失敗');
						error.number = 300;
						reject(error);
					});
			} else {
				$.ajax({
					url: window.domain + '/dashboard/queue_records/?status=' + status,
					method: 'GET',
					xhrFields: { withCredentials: true }
				})
					.done((data) => {
						resolve(data);
					})
					.fail(() => {
						const error = new Error('取得 Queue Record 失敗');
						error.number = 300;
						reject(error);
					});
			}
		});
	}

	// date formate YYYY-MM-dd~YYYY-MM-dd
	getPeriodQueueRecord(period) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: window.domain + '/dashboard/queue_records/analytics/counts/' + period,
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					const error = new Error('取得 Period 資料失敗');
					reject(error);
				});
		});
	}

	updateQueueRecord(id, status, waitTime, callback, event_id = null) {
		$.ajax({
			url: window.domain + '/dashboard/queue_records/' + id,
			data: {
				status: status,
				waiting_time: waitTime,
				event_id
			},
			method: 'PATCH',
			xhrFields: { withCredentials: true }
		})
			.done((data) => {
				callback(data);
			})
			.fail(() => {
				callback();
			});
	}

	updateQueueShopMemo(id, shop_memo, event_id = null) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/queue_records/' + id,
				data: {
					shop_memo,
					event_id
				},
				method: 'PATCH',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					reject(xhr);
				});
		});
	}

	editQueueGroup(groupParam, callback) {
		$.ajax({
			url: window.domain + '/dashboard/queue_settings/queue_group/' + groupParam.id,
			data: groupParam,
			method: 'PATCH',
			xhrFields: { withCredentials: true }
		})
			.done((data) => {
				callback(data);
			})
			.fail(() => {
				callback();
			});
	}

	/* 成功的話回傳一個 Queue Groups 陣列 */
	// getListedQueueGroup(callback) {
	getListedQueueGroup() {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: window.domain + '/dashboard/queue_settings/queue_groups',
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					// callback(data["queue_groups"]);
					resolve(data);
				})
				.fail(() => {
					const error = new Error('取得 Queue Group 失敗');
					error.number = 10;
					reject(error);
				});
		});
	}

	// 更新時段狀態 "start" || "pause || "end"
	updateQueueGroupStatus(status, event_id = null) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: window.domain + '/dashboard/queue_settings/queue_groups/update_all',
				data: {
					status,
					event_id
				},
				method: 'POST',
				xhrFields: { withCredentials: true }
			})
				.done(() => {
					// callback(data["queue_groups"]);
					// resolve(data["queue_groups"])
					resolve(true);
				})
				.fail(() => {
					const error = new Error('更新 Group Status 失敗');
					reject(error);
				});
		});
	}

	sendNotification(userID, event_id) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: window.domain + `/dashboard/queue_records/${userID}/notification`,
				data: {
					event_id
				},
				method: 'POST',
				xhrFields: { withCredentials: true }
			})
				.done(() => {
					// console.log("發送成功")
					resolve(true);
				})
				.fail(() => {
					const error = new Error('發送通知失敗');
					reject(error);
				});
		});
	}

	getNewModuleQueueList(date) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: window.domain + '/dashboard/queue_records/new_module_show?date=' + date,
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					const error = new Error('getNewModuleQueueList 失敗', xhr);
					reject(error);
				});
		});
	}

	callQueueNumber(id) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: window.domain + `/dashboard/queue_records/${id}/call_number`,
				method: 'POST',
				xhrFields: { withCredentials: true }
			})
				.done(() => {
					resolve(true);
				})
				.fail(() => {
					const error = new Error('叫號失敗');
					reject(error);
				});
		});
	}
}
