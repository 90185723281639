import React, {
  Fragment,
  useReducer,
  useEffect,
  useCallback,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { browserHistory } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import {
  signupState,
  SignUpStateContext,
  SignUpReducer,
} from "./MenuCouponContext/MenuCouponSignUpContext";
import MenuCouponSignUp from "./MenuCouponSignUp/MenuCouponSignUp";
import MenuCoupon from "./MenuCoupon";
import MenuCouponApi from "./MenuCouponApi";
import ConfirmModal from "../popup/ConfirmModal";

const menuCouponAPI = new MenuCouponApi();

const MenuCouponPanel = () => {
  const { t } = useTranslation("couponSystem");
  const auth = useSelector((state) => state.auth);
  const { shop: shopState } = auth;
  const [SignUpState, SignUpStateDispatch] = useReducer(
    SignUpReducer,
    signupState
  );
  const { menuShopUser } = SignUpState;
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const getShopInfo = useCallback(async () => {
    try {
      if (!Boolean(shopState.parse_obj_id)) {
        const shop = await menuCouponAPI.getShops();
        const { shop: shopInfo } = shop;

        if (!Boolean(shopInfo.parse_obj_id)) {
          setShowConfirmModal(true);
        } else {
          SignUpStateDispatch({
            type: "setInitShopInfo",
            shop: {
              menuShopUser: shopInfo.menu_shop_user,
              shopName: shopInfo.display_name || "",
              shopBranch: shopInfo.branch || "",
              tel: shopInfo.phone_number || "",
              address: shopInfo.address || "",
            },
          });
        }
      }

      if (Boolean(shopState.parse_obj_id)) {
        SignUpStateDispatch({
          type: "setInitShopInfo",
          shop: {
            menuShopUser: shopState.menu_shop_user,
            shopName: shopState.display_name || "",
            shopBranch: shopState.branch || "",
            tel: shopState.phone_number || "",
            address: shopState.address || "",
          },
        });
      }
    } catch (err) {
      console.log("nextStep err----", err);
    }
  }, [
    shopState.address,
    shopState.branch,
    shopState.display_name,
    shopState.menu_shop_user,
    shopState.parse_obj_id,
    shopState.phone_number,
  ]);

  useEffect(() => {
    getShopInfo();
  }, [getShopInfo]);

  if (menuShopUser === null && !showConfirmModal)
    return (
      <div className="loading">
        <LoadingOutlined style={{ fontSize: 30 }} />
        <p>Loading ...</p>
      </div>
    );

  return (
    <SignUpStateContext.Provider value={{ SignUpState, SignUpStateDispatch }}>
      <Fragment>
        {showConfirmModal && (
          <ConfirmModal
            title={t("popup.connectMenuFirstConfirmation")}
            content={t("popup.connectMenuFirstConfirmation_content")}
            submit={() => {
              browserHistory.push({
                pathname: "/dashboard/setting",
              });
              setShowConfirmModal(false);
            }}
            submitText={t("popup.go")}
          />
        )}

        {menuShopUser === false ? <MenuCouponSignUp /> : <MenuCoupon />}
      </Fragment>
    </SignUpStateContext.Provider>
  );
};

export default MenuCouponPanel;
