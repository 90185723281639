import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Space, Dropdown } from "antd";
import { bookingStatusTabJson } from "../../new_module/utils/data";

export const BookingListTab = ({
  scrollToActiveCode,
  activeBookingListStatusTab,
  changeBookingListStatusTab,
  bookings,
}) => {
  const { t } = useTranslation('bookingSystem');

  useEffect(() => {
    if (scrollToActiveCode) {
      setTimeout(() => {
        changeBookingListStatusTab(scrollToActiveCode.status);
      }, 0);
    }
  }, [changeBookingListStatusTab, scrollToActiveCode]);

  const handleMenuClick = (e) => {
    const selectedStatus = e.key;
    changeBookingListStatusTab(selectedStatus);
  };

  const items = Object.keys(bookingStatusTabJson).map((status) => {
    const statusBookings = bookings.filter(
      (booking) => booking.status === status
    );
    const isActive = status === activeBookingListStatusTab;

    return {
      label: (
        <Space size={8}>
          {t(`status.${status}`)}
          <span>{statusBookings.length}</span>
        </Space>
      ),
      className: isActive ? "active" : "",
      key: status,
    };
  });

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const activeStatusCount = bookings.filter(
    (booking) => booking.status === activeBookingListStatusTab
  ).length;

  return (
    <>
      <ul className="bookingList__status">
        {Object.keys(bookingStatusTabJson).map((status) => {
          let active =
            bookingStatusTabJson[status] ===
            bookingStatusTabJson[activeBookingListStatusTab]
              ? "bookingList__status-active"
              : "";
          let statusBookings = bookings.filter(
            (booking) => booking.status === status
          );

          return (
            <li
              id={status}
              key={status}
              className={`btn-text-lg color-intro ${active}`}
              onClick={() => changeBookingListStatusTab(status)}
            >
              <Space size={4}>
                {t(`status.${status}`)}
                <span>{statusBookings.length}</span>
              </Space>
            </li>
          );
        })}
      </ul>
      <Dropdown
        trigger={["click"]}
        menu={menuProps}
        overlayClassName="dropdown-mode dropdown-bookingList"
        placement="bottomLeft"
        arrow
        transitionName="ant-fade"
      >
        <button className="dropdown-mode-button">
          <Space size={8}>
            {t(`status.${activeBookingListStatusTab}`)}
            {activeStatusCount}
          </Space>
          <div className="icon-normal arrowDown-icon" />
        </button>
      </Dropdown>
    </>
  );
};
