import React, { useEffect, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../crmContext/MessageContext";

const CrmMessageNotification = () => {
  const { t } = useTranslation("messages");
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { notificationType, msgType } = MessageState;

  useEffect(() => {
    setTimeout(() => {
      MessageStateDispatch({ type: "closeMessageNotification" });
    }, 3000);
  }, [MessageStateDispatch]);

  const switchTab = useCallback(
    (tab) => {
      MessageStateDispatch({ type: "switchMessageStatus", messageStatus: tab });
      MessageStateDispatch({ type: "closeMessageNotification" });
    },
    [MessageStateDispatch]
  );

  const renderContent = () => {
    let msgTypeText = msgType === "sms" ? t("list.sms") : t("list.menuTalk");

    if (notificationType === "draft") {
      return (
        <>
          <div>{t("list.notification.draft", { type: msgTypeText })}</div>
          <button className="button-primary" onClick={() => switchTab("draft")}>
            {t("list.notification.checkDraft")}
          </button>
        </>
      );
    } else if (notificationType === "scheduling") {
      return (
        <>
          <div>{t("list.notification.scheduled", { type: msgTypeText })}</div>
          <button
            className="button-primary"
            onClick={() => switchTab("scheduling")}
          >
            {t("list.notification.checkScheduled")}
          </button>
        </>
      );
    } else if (notificationType === "sent") {
      return (
        <>
          <div>{t("list.notification.scheduled", { type: msgTypeText })}</div>
          <button className="button-primary" onClick={() => switchTab("sent")}>
            {t("list.notification.checkSend")}
          </button>
        </>
      );
    }
  };

  return <div className="crmMessageNotification">{renderContent()}</div>;
};

export default CrmMessageNotification;
