import React, { useEffect, useContext } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { CustomerContext } from "../../crmContext/CustomerContext";
import { MessageContext } from "../../crmContext/MessageContext";
import { MessageAddStateContext } from "../../crmContext/MessageAddContext";

const EnableMenuCouponCard = ({ children, id, coupon, onlyView }) => {
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(
    MessageAddStateContext
  );
  const { insertMenuCoupon, insertCoupons } = MessageAddState;

  const cursorStyle = onlyView ? "default" : "pointer";

  useEffect(() => {
    const menuCouponCard = document.getElementById(id);

    if (!onlyView) {
      if (insertMenuCoupon === id) {
        menuCouponCard.classList.add("selectedCard");
      } else {
        menuCouponCard.classList.remove("selectedCard");
      }
    }
  }, [insertMenuCoupon, id, onlyView]);

  useEffect(() => {
    const menuCouponCard = document.getElementById(id);
    let insertCouponId = [];

    if (editType === "menuTalk" && !onlyView) {
      for (let i = 0; i < insertCoupons.length; i++) {
        insertCouponId.push(insertCoupons[i].voucherId);
      }

      let index = insertCouponId.indexOf(id);

      if (index !== -1) {
        menuCouponCard.classList.add("disabledCard");
        menuCouponCard.style.cursor = "default";
      } else {
        menuCouponCard.classList.remove("disabledCard");
        menuCouponCard.style.cursor = "pointer";
      }
    }
  }, [editType, insertCoupons, id, onlyView]);

  const selectMenuCoupon = () => {
    const menuCouponCardDisabled = document
      .getElementById(id)
      .classList.contains("disabledCard");

    if (!onlyView) {
      if (editType === "sms") {
        if (insertMenuCoupon !== id) {
          MessageAddDispatch({
            type: "setInsertMenuCoupon",
            insertMenuCoupon: id,
          });
        } else {
          MessageAddDispatch({
            type: "setInsertMenuCoupon",
            insertMenuCoupon: null,
          });
        }
      } else if (editType === "menuTalk") {
        if (insertMenuCoupon !== id && !menuCouponCardDisabled) {
          MessageAddDispatch({
            type: "setInsertMenuCoupon",
            insertMenuCoupon: id,
            couponInfo: coupon,
          });
        } else {
          MessageAddDispatch({
            type: "setInsertMenuCoupon",
            insertMenuCoupon: null,
            couponInfo: {},
          });
        }
      }
    }
  };

  return (
    <div
      id={id}
      className="enableMenuCouponCard"
      style={{ cursor: cursorStyle }}
      onClick={() => selectMenuCoupon()}
    >
      {React.Children.map(children, (child) => child)}
    </div>
  );
};

const EnableMenuCouponCardImg = ({ thumbnailURL }) => {
  return (
    <div className="enableMenuCouponCard_img">
      <figure>
        {thumbnailURL !== "" && <img src={thumbnailURL} alt="" />}
      </figure>
    </div>
  );
};

const EnableMenuCouponCardContent = ({ coupon }) => {
  const { t } = useTranslation("messages");
  const { CustomerState } = useContext(CustomerContext);
  const { shopName } = CustomerState;

  const { title, item, endDateTime } = coupon;

  const newEndDateTime =
    endDateTime === null
      ? t("sendMenuTalk.coupon.noSet")
      : moment(new Date(endDateTime)).format("YYYY/MM/DD");

  return (
    <div className="enableMenuCouponCard_content">
      <section className="contentTitle">
        <h2>{title}</h2>
      </section>

      <section className="contentDescription">
        <div>
          {t("sendMenuTalk.coupon.item")}
          {item}
        </div>
        <div>{shopName}</div>
        <div style={{ fontSize: "10px" }}>
          {t("sendMenuTalk.coupon.redemptionPeriod")} {newEndDateTime}
        </div>
      </section>
    </div>
  );
};

EnableMenuCouponCard.Img = EnableMenuCouponCardImg;
EnableMenuCouponCard.Content = EnableMenuCouponCardContent;

export default EnableMenuCouponCard;
