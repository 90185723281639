import React, { useEffect, useContext, Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { browserHistory } from "react-router";
import { Pagination, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import CustomerList from "./crmCustomer/CrmCustomerList";
import CrmFollower from "./crmCustomer/CrmFollower";

import CustomerCreateModal from "./crmCustomer/crmCustomerPopup/CrmCustomerEditorModal";

import { CustomerContext } from "./crmContext/CustomerContext";

import {
  getCrmCustomerPage,
  getCrmCustomerCount,
  searchCustomer,
  filterCustomers,
} from "./api/CrmCustomerApi";
import { filterFollowers } from "./api/CrmMessageMenuTalkApi";
import MenuCouponApi from "../menu_coupon/MenuCouponApi";
const menuCouponAPI = new MenuCouponApi();

const { Option } = Select;
const pageSize = [10, 20, 30, 40, 50];

const CrmCustomerBox = () => {
  const { t } = useTranslation("customer");
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const {
    loading,
    customerStatus,
    searchCustomerMode,
    filterCustomerMode,
    page,
    per,
    count,
    listPage,
    listCount,
    filterCustomersParams,
    searchCustomerText,
    showAddCustomerModal,
  } = CustomerState;

  useEffect(() => {
    const customerItem = document.getElementsByClassName("crmCustomerItem")[0];

    if (customerItem) {
      customerItem.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [listPage]);

  const load = useCallback(async (page, per) => {
    const params = { page, per };

    try {
      CustomerStateDispatch({ type: "setLoading", loading: true });

      const { data } = await getCrmCustomerPage(params);
      const res = await getCrmCustomerCount();

      CustomerStateDispatch({
        type: "setLoadCustomers",
        pageCustomers: data.customers,
        page_num: Math.ceil(data.meta.size / per),
      });

      CustomerStateDispatch({
        type: "setCustomerCount",
        count: res.data.count,
      });
      CustomerStateDispatch({ type: "setLoading", loading: false });
    } catch (err) {
      console.log("get customer err ----------", err);
    }
  }, [CustomerStateDispatch]);

  useEffect(() => {
    let pageNum = _.cloneDeep(page);
    let pageSizeNum = _.cloneDeep(per);

    if (window.location.search) {
      window.location.search.split("&").forEach(function (q) {
        if (q.search("page") >= 0) {
          let p = parseInt(q.split("=").pop(), 10);
          if (p > 0) {
            pageNum = p;
            CustomerStateDispatch({ type: "setListPage", page: p });
          }
        }

        if (q.search("per") >= 0) {
          let e = parseInt(q.split("=").pop(), 10);
          if (e > 0) {
            pageSizeNum = e;
            CustomerStateDispatch({ type: "setListPageSize", per: e });
          }
        }
      });
    } else {
      pageNum = 1;
      pageSizeNum = 10;
      CustomerStateDispatch({ type: "setListPage", page: pageNum });
      CustomerStateDispatch({ type: "setListPageSize", per: pageSizeNum });
    }

    load(pageNum, pageSizeNum);
  }, [CustomerStateDispatch, load, page, per]);

  useEffect(() => {
    const getShop = async () => {
      try {
        const data = await menuCouponAPI.getShops();
        CustomerStateDispatch({
          type: "getShopName",
          shopName: data.shop.display_name,
          shopLogo: data.shop.logo,
        });
      } catch (err) {
        console.log("get shop name err-----", err);
      }
    };

    const getFollowersLength = async () => {
      try {
        const { data } = await filterFollowers();
        CustomerStateDispatch({
          type: "getFollowersLength",
          followers: data.followers.length,
        });
      } catch (err) {
        console.log("get followers length err", err);
      }
    };

    getShop();
    getFollowersLength();
  }, [CustomerStateDispatch]);

  const pageClick = (page) => {
    const handleSearchListPage = async () => {
      const query = {
        query: searchCustomerText,
        page: page,
        per: 10,
      };
      const { data } = await searchCustomer(query);
      CustomerStateDispatch({
        type: "setSearchCustomerResult",
        searchCustomers: data.customers,
        searchCustomerMode: true,
        listPage: page,
        listCount: data.meta.size,
      });
    };

    const handleFilterListPage = async () => {
      let newParams = Object.assign({}, filterCustomersParams);
      newParams.page = page;
      newParams.per = 10;
      const { data } = await filterCustomers(newParams);
      CustomerStateDispatch({
        type: "setFilterCustomerResult",
        filterCustomers: data.customers,
        filterCustomerMode: true,
        listPage: page,
        listCount: data.meta.size,
      });
    };

    if (searchCustomerMode) {
      return handleSearchListPage();
    }
    if (filterCustomerMode) {
      return handleFilterListPage();
    }

    CustomerStateDispatch({ type: "setListPage", page });
    browserHistory.push(`${window.location.pathname}?page=${page}&per=${per}`);
  };

  const pageSizeClick = (per) => {
    CustomerStateDispatch({ type: "setListPageSize", per });
    browserHistory.push(`${window.location.pathname}?page=${page}&per=${per}`);
  };

  const renderList = () => {
    if (loading) {
      return (
        <div className="crmCustomerLoading">
          <LoadingOutlined style={{ fontSize: 30 }} />
          <p>Loading ...</p>
        </div>
      );
    }

    if (customerStatus === "customers") {
      const pages = () => {
        if (searchCustomerMode || filterCustomerMode) return listPage;
        return page;
      };

      const counts = () => {
        if (searchCustomerMode || filterCustomerMode) return listCount;
        return count;
      };

      const pagePer = () => {
        if (searchCustomerMode || filterCustomerMode) return 10;
        return per;
      };

      return (
        <Fragment>
          <CustomerList />
          <div
            className={`crmCustomerBox_footer ${
              searchCustomerMode ? "footer-search" : ""
            } ${filterCustomerMode ? "footer-filter" : ""}`}
          >
            {!searchCustomerMode && !filterCustomerMode && count !== 0 ? (
              <Select
                className="crm_page_select btn-effect"
                bordered={false}
                value={per}
                onChange={(value) => pageSizeClick(value)}
              >
                {pageSize.map((option, index) => {
                  return (
                    <Option key={index} value={option}>
                      {option} {t("list.perPage")}
                    </Option>
                  );
                })}
              </Select>
            ) : (
              <div className="footer-mode-placeholder" />
            )}
            {((!searchCustomerMode && !filterCustomerMode && count !== 0) ||
              (searchCustomerMode && listCount !== 0) ||
              (filterCustomerMode && listCount !== 0)) && (
              <Pagination
                className="crm_page_picker"
                current={pages()}
                total={counts()}
                pageSize={pagePer()}
                showSizeChanger={false}
                showPrevNextJumpers={false}
                locale={{ items_per_page: t("list.perPage") }}
                onChange={pageClick}
                itemRender={(_, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <span className="btn-effect">{t("list.page_pre")}</span>
                    );
                  }
                  if (type === "next") {
                    return (
                      <span className="btn-effect">{t("list.page_next")}</span>
                    );
                  }
                  return originalElement;
                }}
              />
            )}
            {!searchCustomerMode && !filterCustomerMode && count !== 0 && (
              <div className="crmCustomer_count">
                {t("list.totalCustomers")}：{count}
              </div>
            )}
            {searchCustomerMode && listCount !== 0 && (
              <div className="crmCustomer_count">
                {t("list.searchResult")}：{listCount}
              </div>
            )}
          </div>
        </Fragment>
      );
    } else {
      return <CrmFollower />;
    }
  };

  return (
    <div className="crmCustomerBox_container">
      {renderList()}
      {showAddCustomerModal && <CustomerCreateModal load={load} />}
    </div>
  );
};

export default CrmCustomerBox;
