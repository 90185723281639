import React, { useState } from "react";
import { Modal, Space } from "antd";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import CheckModal from "../../../popup/CheckModal";

const RecordEditorModal = ({
  id,
  url,
  selectRecord,
  setSelectRecord,
  setShowRecordEditorModal,
  handleRecordSubmit,
}) => {
  const { t } = useTranslation("customer");
  const [showCancelEditCheckModal, setShowCancelEditCheckModal] =
    useState(false);
  const [showDeleteCheckModal, setShowDeleteCheckModal] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      record: selectRecord ? selectRecord.value : "",
    },
  });
  const recordValue = watch("record", selectRecord ? selectRecord.value : "");

  const cancelPopup = (type = "cancel") => {
    let isSaved = true;

    if (selectRecord) {
      if (selectRecord.value !== recordValue) isSaved = false;
    } else {
      if (recordValue !== "") isSaved = false;
    }

    if (!isSaved && type !== "submit") {
      setShowCancelEditCheckModal(true);
    } else {
      setShowRecordEditorModal(false);
      setSelectRecord(null);
    }
  };

  const renderDate = () => {
    if (selectRecord) {
      return moment(selectRecord.created_at).format("YYYY-MM-DD");
    } else {
      return moment().format("YYYY-MM-DD");
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    let newRecord = {};

    if (selectRecord && selectRecord.id) {
      newRecord = {
        record: Object.assign({}, selectRecord, { value: data.record }),
      };

      handleRecordSubmit(
        newRecord,
        "put",
        url + "/" + selectRecord.id + ".json"
      );
    } else {
      newRecord = {
        customer_id: id,
        record: {
          value: data.record,
        },
      };

      handleRecordSubmit(newRecord, "post", url + ".json");
    }
    cancelPopup("submit");
  };

  const handleDelete = () => {
    handleRecordSubmit("", "delete", url + "/" + selectRecord.id);
    cancelPopup("submit");
  };

  const handleCanceled = () => {
    setShowRecordEditorModal(false);
    setShowCancelEditCheckModal(false);
  };

  return (
    <Modal
      title={`${
        selectRecord
          ? t("popup.editCustomerRecord_title")
          : t("popup.addCustomerRecord_title")
      }`}
      visible
      destroyOnClose
      width={640}
      centered
      closable={false}
      className="recordEditorModal modal-base modal-form"
      footer={
        <div className="btn-action">
          {selectRecord && (
            <button
              type="button"
              className="btn-delete"
              onClick={() => setShowDeleteCheckModal(true)}
            >
              {t("popup.delete")}
            </button>
          )}
          <Space size={8}>
            <button
              type="button"
              className="button-common button-secondary"
              onClick={cancelPopup}
            >
              {t("settings:cancel")}
            </button>
            <button
              className="button-common button-primary"
              type="submit"
              disabled={errors.message?.record || recordValue.length === 0}
              onClick={handleSubmit(onSubmit)}
            >
              {selectRecord ? t("settings:save") : t("settings:add")}
            </button>
          </Space>
        </div>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="date">{renderDate()}</div>
        <div className="title">{t("popup.customerRecords")}</div>
        <textarea
          placeholder={t("popup.placeholder_customerRecords")}
          {...register("record", { required: true })}
        />
      </form>

      {showCancelEditCheckModal && (
        <CheckModal
          title={`${
            selectRecord
              ? t("popup.discardEdit_title")
              : t("popup.discardAdd_title")
          }`}
          content={`${
            selectRecord
              ? t("popup.discardEditRecord_content")
              : t("popup.discardAddRecord_content")
          }`}
          submit={handleCanceled}
          cancel={() => setShowCancelEditCheckModal(false)}
        />
      )}

      {showDeleteCheckModal && (
        <CheckModal
          title={t("popup.deleteRecord_title")}
          content={t("popup.deleteRecord_content")}
          submit={handleDelete}
          cancel={() => setShowDeleteCheckModal(false)}
        />
      )}
    </Modal>
  );
};

export default RecordEditorModal;
