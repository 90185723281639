import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  getBookingSetting,
  getBookingNotification,
  updateBookingNotification,
} from "../../api/booking/bookingSetting";
import SettingsSaveButton from "../commons/SettingsSaveButton";
import {
  BookingInsertText,
  BookingInsertKey,
} from "../notification_settings/InsertText";

import NotificationSettingBlock from "../notification_settings/NotificationSettingBlock";
import InsufficientCreditsAlert from "../notification_settings/InsufficientCreditsAlert";
import NotificationEditPopup from "../notification_settings/NotificationEditPopup";

const BookingSettingNotification = ({ router, route }) => {
  const { t } = useTranslation("settings");
  const [init, setInit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [credits, setCredits] = useState(0);
  const [shopName, setShopName] = useState("");

  const [createdSettings, setCreatedSettings] = useState({});
  const [reminderSettings, setReminderSettings] = useState({});
  const [canceledSettings, setCanceledSettings] = useState({});

  const [tempCreatedSettings, setTempCreatedSettings] = useState({});
  const [tempReminderSettings, setTempReminderSettings] = useState({});
  const [tempCanceledSettings, setTempCanceledSettings] = useState({});

  const [showCreatedModal, setShowCreatedModal] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showCanceledModal, setShowCanceledModal] = useState(false);
  const [showSmsTextareaError, setShowSmsTextareaError] = useState(false);

  const defaultSmsBookingCreatedMsg =
    `您已成功預約${shopName}*/預約時間/*，查詢或取消預約請至*/預約結果頁/*`;
  const defaultSmsBookingReminderMsg =
    `【提醒】您已預約${shopName}*/預約時間/*，查詢或取消預約請至*/預約結果頁/*`;
  const defaultSmsBookingCanceledMsg =
    `【提醒】您已預約${shopName}*/預約時間/*，查詢或取消預約請至*/預約結果頁/*`;

  const mappings = {
    [BookingInsertKey.SHORTEN_URL]: BookingInsertText.SHORTEN_URL,
    [BookingInsertKey.BOOKING_DATETIME]: BookingInsertText.BOOKING_DATETIME,
  };

  const routerWillLeave = useCallback(() => {
    if (!isSaved) return t("leaveNotice");
  }, [isSaved, t]);

  useEffect(() => {
    router.setRouteLeaveHook(route, routerWillLeave);
  }, [router, route, routerWillLeave]);

  useEffect(() => {
    const getBookingNotificationSettings = async () => {
      try {
        const { data } = await getBookingNotification();
        setCreatedSettings(data.booking_created);
        setReminderSettings(data.booking_reminder);
        setCanceledSettings(data.booking_canceled);
        setTempCreatedSettings(data.booking_created);
        setTempReminderSettings(data.booking_reminder);
        setTempCanceledSettings(data.booking_canceled);
      } catch (err) {
        console.log("getBookingNotification error", err);
      }
    };

    getBookingNotificationSettings();
  }, []);

  useEffect(() => {
    const getBookingSettings = async () => {
      try {
        const { data } = await getBookingSetting();
        setCredits(data.shop.msg_point);
        setShopName(data.shop.display_name + data.shop.branch);
        setInit(true);
      } catch (err) {
        console.log("getBookingSettings error", err);
        window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
      }
    };

    getBookingSettings();
  }, [t]);

  // 將顯示內容轉換為指定碼
  const displayToCode = (msg) => {
    return msg
      .replace(/\*\/預約時間\/\*/g, "MNUSHOP_BOOKING_DATETIME")
      .replace(/\*\/預約結果頁\/\*/g, "MNUSHOP_SHORTEN_URL "); // 最後的空格防止有英文文案被誤認為是網址的一部分
  };

  // 將指定碼轉換為顯示內容
  const convertToDisplay = (message) => {
    return message
      .replace(
        /MNUSHOP_BOOKING_DATETIME/g,
        mappings["MNUSHOP_BOOKING_DATETIME"]
      )
      .replace(/MNUSHOP_SHORTEN_URL /g, mappings["MNUSHOP_SHORTEN_URL"])
      .replace(/MNUSHOP_SHORTEN_URL/g, mappings["MNUSHOP_SHORTEN_URL"]);
  };

  // 更新啟用狀態
  const handleNotificationEnable = (category, type, checked) => {
    const setState =
      type === "booking_created"
        ? setCreatedSettings
        : type === "booking_reminder"
        ? setReminderSettings
        : setCanceledSettings;

    if (category === "email") {
      setState((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          online: checked,
          offline: checked,
        },
      }));
    } else if (category === "sms") {
      setState((prev) => ({
        ...prev,
        sms: {
          ...prev.sms,
          online: checked,
          offline: checked,
        },
      }));
    } else if (category === "line") {
      setState((prev) => ({
        ...prev,
        line_oa: {
          ...prev.line_oa,
          online: checked,
          offline: checked,
        },
      }));
    }

    setIsSaved(false);
    setShowSmsTextareaError(false);
  };

  // 處理來源啟用狀態 (SMS / LINE)
  const handleSourceEnable = (category, type, source) => {
    const settings =
      type === "booking_created"
        ? [createdSettings, setCreatedSettings]
        : type === "booking_reminder"
        ? [reminderSettings, setReminderSettings]
        : [canceledSettings, setCanceledSettings];

    const [state, setState] = settings;

    if (category === "sms") {
      if (source === "all") {
        const isEnabled = state.sms.online || state.sms.offline;
        setState((prev) => ({
          ...prev,
          sms: {
            ...prev.sms,
            online: !isEnabled,
            offline: !isEnabled,
          },
        }));
        setShowSmsTextareaError(false);
      } else {
        setState((prev) => {
          const newState = {
            ...prev,
            sms: {
              ...prev.sms,
              [source]: !prev.sms[source],
            },
          };
          if (!newState.sms.online && !newState.sms.offline) {
            setShowSmsTextareaError(false);
          }
          return newState;
        });
      }
    } else if (category === "line") {
      if (source === "all") {
        const isEnabled = state.line_oa.online || state.line_oa.offline;
        setState((prev) => ({
          ...prev,
          line_oa: {
            ...prev.line_oa,
            online: !isEnabled,
            offline: !isEnabled,
          },
        }));
        setShowSmsTextareaError(false);
      } else {
        setState((prev) => ({
          ...prev,
          line_oa: {
            ...prev.line_oa,
            [source]: !prev.line_oa[source],
          },
        }));
      }
    }

    setIsSaved(false);
  };

  const handleEmailMsg = (e) => {
    const type = e.target.name;
    const value = e.target.value;

    if (type === "booking_created") {
      setCreatedSettings((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          template: value,
        },
      }));
    } else if (type === "booking_reminder") {
      setReminderSettings((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          template: value,
        },
      }));
    } else if (type === "booking_canceled") {
      setCanceledSettings((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          template: value,
        },
      }));
    }

    setIsSaved(false);
  };

  const handleSmsMsg = (e) => {
    const type = e.target.name;
    const value = e.target.value;
    const encodedValue = displayToCode(value)

    if (type === "booking_created") {
      setCreatedSettings((prev) => ({
        ...prev,
        sms: {
          ...prev.sms,
          template: encodedValue,
        },
      }));
    } else if (type === "booking_reminder") {
      setReminderSettings((prev) => ({
        ...prev,
        sms: {
          ...prev.sms,
          template: encodedValue,
        },
      }));
    } else if (type === "booking_canceled") {
      setCanceledSettings((prev) => ({
        ...prev,
        sms: {
          ...prev.sms,
          template: encodedValue,
        },
      }));
    }

    setIsSaved(false);
    setShowSmsTextareaError(false);
  };

  const closeAllPopup = () => {
    setShowCreatedModal(false);
    setShowReminderModal(false);
    setShowCanceledModal(false);
    setShowSmsTextareaError(false);
  };

  const cancelPopup = useCallback(() => {
    setCreatedSettings(tempCreatedSettings);
    setReminderSettings(tempReminderSettings);
    setCanceledSettings(tempCanceledSettings);
    closeAllPopup();
  }, [tempCreatedSettings, tempReminderSettings, tempCanceledSettings]);

  const submitPopup = useCallback(
    (type) => {
      const checkSmsContent = (settings) => {
        const { online, offline, template } = settings.sms;
        if ((online || offline) && (!template || !template.trim())) {
          return false;
        }
        return true;
      };

      if (type === "booking_created") {
        if (!checkSmsContent(createdSettings)) {
          setShowSmsTextareaError(true);
          return;
        }
        setTempCreatedSettings(createdSettings);
      } else if (type === "booking_reminder") {
        if (!checkSmsContent(reminderSettings)) {
          setShowSmsTextareaError(true);
          return;
        }
        setTempReminderSettings(reminderSettings);
      } else if (type === "booking_canceled") {
        if (!checkSmsContent(canceledSettings)) {
          setShowSmsTextareaError(true);
          return;
        }
        setTempCanceledSettings(canceledSettings);
      }

      closeAllPopup();
    },
    [createdSettings, reminderSettings, canceledSettings]
  );

  const submit = useCallback(async () => {
    try {
      const notificationSettings = JSON.stringify({
        booking_settings: {
          notification_settings: {
            booking_created: createdSettings,
            booking_reminder: reminderSettings,
            booking_canceled: canceledSettings,
          },
        },
      });

      await updateBookingNotification(notificationSettings);
      window.app.alert.setMessage(t("status.saved"), "done");
      setSaving(false);
      setIsSaved(true);
    } catch (err) {
      window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
    }
  }, [t, createdSettings, reminderSettings, canceledSettings]);

  const shouldShowInsufficientCreditsAlert = useMemo(() => {
    const checkCredits = (setting) => {
      if (!setting) return false;
      
      const smsStatus = setting.sms?.online || setting.sms?.offline;
      const lineOaStatus = setting.line_oa?.online || setting.line_oa?.offline;
      
      return smsStatus || lineOaStatus;
    };

    return (
      checkCredits(createdSettings) ||
      checkCredits(reminderSettings) ||
      checkCredits(canceledSettings)
    );
  }, [createdSettings, reminderSettings, canceledSettings]);

  if (!init) return <div className="pageLoading">{t("status.loading")}</div>;
  return (
    <>
      <div className="setting-container">
        {saving && (
          <div className="lightBoxLayer">
            <h4>{t("status.saving")}</h4>
          </div>
        )}
        <div style={{ marginBottom: "24px" }}>
          <h3>{t("bookingNotification.title")}</h3>
          <h5>{t("bookingNotification.description")}</h5>
        </div>
        <hr />
        <div className="setting-block">
          {credits < 1 && shouldShowInsufficientCreditsAlert && (
            <InsufficientCreditsAlert />
          )}
          <NotificationSettingBlock
            titleKey="bookingCreateNotification"
            type="booking"
            emailMsg={createdSettings.email}
            smsMsg={createdSettings.sms}
            lineMsg={createdSettings.line_oa}
            credits={credits}
            showModal={() => setShowCreatedModal(true)}
          />
          <hr />
          <NotificationSettingBlock
            titleKey="bookingReminderNotification"
            type="booking"
            emailMsg={reminderSettings.email}
            smsMsg={reminderSettings.sms}
            lineMsg={reminderSettings.line_oa}
            credits={credits}
            showModal={() => setShowReminderModal(true)}
          />
          <hr />
          <NotificationSettingBlock
            titleKey="bookingCancelationNotification"
            type="booking"
            emailMsg={canceledSettings.email}
            smsMsg={canceledSettings.sms}
            lineMsg={canceledSettings.line_oa}
            credits={credits}
            showModal={() => setShowCanceledModal(true)}
          />
        </div>
      </div>
      <SettingsSaveButton handleSaved={submit} showDivider />

      {showCreatedModal && (
        <NotificationEditPopup
          title={t("bookingNotification.bookingCreateNotification")}
          description={t("bookingNotification.bookingCreateNotificationDes")}
          type="booking_created"
          credits={credits}
          emailMsg={createdSettings.email}
          smsMsg={createdSettings.sms}
          lineMsg={createdSettings.line_oa}
          handleEmailMsg={handleEmailMsg}
          handleSmsMsg={handleSmsMsg}
          handleNotificationEnable={handleNotificationEnable}
          handleSourceEnable={handleSourceEnable}
          cancelPopup={() => cancelPopup("booking_created")}
          submit={() => submitPopup("booking_created")}
          replaceVariablesWithDisplay={convertToDisplay}
          shopName={shopName}
          defaultMsg={defaultSmsBookingCreatedMsg}
          showSmsTextareaError={showSmsTextareaError}
        />
      )}

      {showReminderModal && (
        <NotificationEditPopup
          title={t("bookingNotification.bookingReminderNotification")}
          description={t("bookingNotification.bookingReminderNotificationDes")}
          type="booking_reminder"
          credits={credits}
          emailMsg={reminderSettings.email}
          smsMsg={reminderSettings.sms}
          lineMsg={reminderSettings.line_oa}
          handleEmailMsg={handleEmailMsg}
          handleSmsMsg={handleSmsMsg}
          handleNotificationEnable={handleNotificationEnable}
          cancelPopup={() => cancelPopup("booking_reminder")}
          submit={() => submitPopup("booking_reminder")}
          replaceVariablesWithDisplay={convertToDisplay}
          shopName={shopName}
          defaultMsg={defaultSmsBookingReminderMsg}
          showSmsTextareaError={showSmsTextareaError}
        />
      )}

      {showCanceledModal && (
        <NotificationEditPopup
          title={t("bookingNotification.bookingCancelationNotification")}
          description={t(
            "bookingNotification.bookingCancelationNotificationDes"
          )}
          type="booking_canceled"
          credits={credits}
          emailMsg={canceledSettings.email}
          smsMsg={canceledSettings.sms}
          lineMsg={canceledSettings.line_oa}
          handleEmailMsg={handleEmailMsg}
          handleSmsMsg={handleSmsMsg}
          handleNotificationEnable={handleNotificationEnable}
          cancelPopup={() => cancelPopup("booking_canceled")}
          submit={() => submitPopup("booking_canceled")}
          replaceVariablesWithDisplay={convertToDisplay}
          shopName={shopName}
          defaultMsg={defaultSmsBookingCanceledMsg}
          showSmsTextareaError={showSmsTextareaError}
        />
      )}
    </>
  );
};

export default BookingSettingNotification;
