import React from 'react'

var createReactClass = require('create-react-class');

export default createReactClass({
  getInitialState() {
    return {
      shop_name: '',
    };
  },
  handleShopNameChange(e) {
    this.setState({shop_name: e.target.value});
  },
  handleSubmit(e) {
    e.preventDefault();
    var shop_name = this.state.shop_name.trim();
    this.props.onLoginSubmit({'shop_name': shop_name});
  },
  render() {
    return (
      <form onSubmit={this.handleSubmit} id="shoplogin" className="form-signin">
        <h4>歡迎使用MENU店+</h4>
        <label htmlFor="shopaccount" className="sr-only">店家帳號</label>
        <input
          type="text"
          id="inputEmail"
          className="form-control" 
          placeholder="請輸入商家帳號"
          required
          autoFocus 
          value={this.state.shop_name}
          onChange={this.handleShopNameChange}
        />
        <button
          className="btn-menushop btn-color-green btn-login"
          type="submit"
          form="shoplogin"
        >
          登入
        </button>
        <div className="help loginframe_font">
          <ul>
            <li>
              <button onClick={this.props.toggleResetAccountModal}>
                <i className="fa fa-question-circle fa-fw"/>
                忘記商家帳號
              </button>
            </li>
          </ul>
        </div>
      </form>
    );
  }
});
