import { createContext } from 'react';
import { browserHistory } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import { bookingStatusObj } from '../utils/data';


export const commomSetting = {
	mode: 'tablemap',
	todayDate: '',
	date: '',
	isToday: true,
	hasSeletedBookingTime: false,
	moduleSelectedBookingTime: null,
	moduleTimePaused: false,
	customQAns: {},
	canvas: [],
	activeCanvasTab: '',
	activeCanvasTables: [],
	canvasEleImgs: [],
	tablemapTableGroup: {},
	bookingCardSeats: [], //點選預約列表桌子&點選canvas上桌子讓同筆預約canvas table亮起來
	queueSeatedData: {}, //要入座人的資料
	selectedBookingTableData: [], //排隊入座所選的桌子&選桌子建立預約所選的桌子
	swapOverlayTableData: [] // 所有換位重疊的桌子
};

export const CommomSettingContext = createContext(commomSetting);

export function CommomSettingReducer(state, action) {
	const newState = _.cloneDeep(state);
	const todayDate = moment().format('YYYY/MM/DD');
	// const urlDate = browserHistory.getCurrentLocation().query.date;
	// const queryDate = urlDate ? moment(new Date(urlDate)).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
	switch (action.type) {
		case 'setTodayDate':
			return Object.assign({}, newState, {
				todayDate,
				isToday: true,
				moduleSelectedBookingTime: null,
				moduleTimePaused: false
			});
		case 'setMode':
			return Object.assign({}, newState, {
				mode: action.mode
			});
		case 'setDate':
			return Object.assign({}, newState, {
				todayDate,
				date: action.date,
				isToday: todayDate === action.date,
				moduleSelectedBookingTime: todayDate === action.date ? null : 0,
				moduleTimePaused: todayDate === action.date ? false : true
			});
		case 'setHasSeletedBookingTime':
			return Object.assign({}, newState, {
				hasSeletedBookingTime: action.hasSeletedBookingTime
			});
		case 'setModuleSelectedBookingTime':
			return Object.assign({}, newState, {
				moduleSelectedBookingTime: action.moduleSelectedBookingTime
			});
		case 'setModuleTimePaused':
			return Object.assign({}, newState, {
				moduleTimePaused: action.moduleTimePaused
			});
		case 'changeUrlDate':
			return compareDate(newState, action);
		case 'goNow':
			return Object.assign({}, newState, {
				isToday: true,
				hasSeletedBookingTime: false,
				moduleTimePaused: false,
				moduleSelectedBookingTime: 0,
				date: newState.todayDate
			});
		case 'setCustomQAns':
			return Object.assign({}, newState, {
				customQAns: action.customQAns
			});
		case 'setInitCanvasSetting':
			return initCanvasSetting(newState, action);
		case 'changeCanvasView':
			return changeCanvasView(newState, action);
		case 'setBookingCardSeats':
			return setBookingSeatsId(newState, action);
		case 'setQueueSeatedData':
			return Object.assign({}, newState, {
				queueSeatedData: action.queue
			});
		case 'setSelectedBookingTableData':
			return Object.assign({}, newState, {
				selectedBookingTableData: action.selectedBookingTableData
			});
		case 'chooseQueueSeated':
			return Object.assign({}, newState, {
				queueSeatedData: action.queue,
				moduleTimePaused: false,
				moduleSelectedBookingTime: 0
			});
		case 'closeQueueSelectedTable':
			return Object.assign({}, newState, {
				selectedBookingTableData: [],
				queueSeatedData: {}
			});
		case 'changeSelectedBookingTime':
			return Object.assign({}, newState, {
				moduleTimePaused: true,
				moduleSelectedBookingTime: action.moduleSelectedBookingTime,
				hasSeletedBookingTime: true
			});
		case 'setStatusFake':
			return fakeNewStatus(newState, action);
		case 'setSwapOverlayTableData':
			return Object.assign({}, newState, {
				swapOverlayTableData: action.swapOverlayTableData
			});
		default:
			return state;
	}
}

function changeUrlDate(date, mode) {
	browserHistory.push({
		pathname: '/dashboard/new_module',
		query: { date, mode }
	});
}

function compareDate(state, action) {
	console.log("action---", action)

	const date = new moment(new Date(action.queryDate)).format('YYYY-MM-DD');
	const dateFormat = new moment(new Date(action.queryDate)).format('YYYY/MM/DD');
	const todayDate = moment().format('YYYY/MM/DD');
	changeUrlDate(date, action.mode);

	let moduleTimePaused = state.moduleTimePaused,
		hasSeletedBookingTime = state.hasSeletedBookingTime,
		moduleSelectedBookingTime = state.moduleSelectedBookingTime;

	if (todayDate === dateFormat) {
		moduleTimePaused = false;
		hasSeletedBookingTime = false;
		moduleSelectedBookingTime = 0;
	} else {
		moduleTimePaused = true;

		if (!hasSeletedBookingTime) {
			moduleSelectedBookingTime = 0;
		}
	}

	return Object.assign({}, state, {
		isToday: todayDate === dateFormat,
		date: dateFormat,
		moduleTimePaused,
		hasSeletedBookingTime,
		moduleSelectedBookingTime
	});
}

function initCanvasSetting(state, action) {
	const { tableMapList } = action;
	const { activeCanvasTab } = state;
	let tablemapTableGroup = {};

	tableMapList.map((tab) => {
		tab.table.map((t) => {
			const filterTimeline = t.timeline.filter((booking) => {
				return booking.status !== 'finish';
			});
			const sortTimeline = _.sortBy(filterTimeline, 'booking_datetime');
			t.timeline = sortTimeline;

			if (!tablemapTableGroup.hasOwnProperty(tab.name)) {
				tablemapTableGroup[tab.name] = [];
				tablemapTableGroup[tab.name].push(t.name);
			} else {
				tablemapTableGroup[tab.name].push(t.name);
			}
			return null;
		});
		return null;
	});

	let activeTabName = '',
		activeTableCanvas = [],
		activeEleCanvas = [];

	if (tableMapList.length !== 0) {
		if (activeCanvasTab === '' || !activeCanvasTab) {
			activeTabName = tableMapList[0].name;
			activeTableCanvas = tableMapList[0].table;
			activeEleCanvas = tableMapList[0].ele;
		} else {
			activeTabName = activeCanvasTab;

			for (let i = 0; i < tableMapList.length; i++) {
				if (tableMapList[i].name === activeTabName) {
					activeTableCanvas = tableMapList[i].table;
					activeEleCanvas = tableMapList[i].ele;
					break;
				}
			}
		}
	}

	return Object.assign({}, state, {
		canvas: tableMapList,
		activeCanvasTab: activeTabName,
		activeCanvasTables: activeTableCanvas,
		canvasEleImgs: activeEleCanvas,
		tablemapTableGroup
	});
}

function changeCanvasView(state, action) {
	const { activeTab } = action;
	const { canvas } = state;

	let activeCanvasTab = activeTab,
		activeCanvasTables = [],
		canvasEleImgs = [];

	canvas.map((t) => {
		if (t.name === activeTab) {
			activeCanvasTables = t.table;
			canvasEleImgs = t.ele;
		}
		return null;
	});

	return Object.assign({}, state, {
		activeCanvasTab,
		activeCanvasTables,
		canvasEleImgs
	});
}

function setBookingSeatsId(state, action) {
	const { seats } = action;

	const seatsTableNumber = seats.map((s) => {
		return s.table_number;
	});

	return Object.assign({}, state, {
		bookingCardSeats: seatsTableNumber
	});
}

function fakeNewStatus(state, action) {
	const newState = _.cloneDeep(state);
	const { booking, newStatus } = action;
	const { canvas } = newState;
	const parseStatusTime = JSON.parse(booking.status_time);

	if (newStatus === 'seated') {
		const bookingTime_min = moment().minute();
		const diff = 5 - bookingTime_min % 5;
		const seatedTime = moment().add(diff, 'm').format('YYYY/MM/DD HH:mm');
		parseStatusTime[newStatus] = seatedTime;
	} else {
		parseStatusTime[newStatus] = moment().format('YYYY-MM-DD HH:mm:ss');
	}

	canvas.forEach((tab) => {
		tab.table.forEach((t) => {
			t.timeline.forEach((findBooking, index) => {
				if (findBooking.id === booking.id) {
					findBooking.status = newStatus;
					findBooking.status_t = bookingStatusObj[newStatus];
					findBooking.status_time = JSON.stringify(parseStatusTime);

					if (newStatus === 'finish' || newStatus === 'cancel' || newStatus === 'no_show') {
						t.timeline.splice(index, 1);
					}
				}
			});
		});
	});

	return newState;
}
