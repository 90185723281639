import React, { Fragment, useContext } from "react";
import { CouponStateContext } from "../MenuCouponContext/MenuCouponContext";
import { CouponAddStateContext } from "../MenuCouponContext/MenuCouponAddContext";
import { useTranslation } from "react-i18next";

const CancelAddPopup = ({ confirmPopup, cancelPopup, saveDraft }) => {
  const { t } = useTranslation("couponSystem");
  const { CouponState } = useContext(CouponStateContext);
  const { editType } = CouponState;

  const { CouponAddState } = useContext(CouponAddStateContext);
  const { couponSetting } = CouponAddState;

  const renderHeader = () => {
    const txt = editType === "add" ? t("add") : t("edit");

    return <h2>{t("addCoupon.cancelConfirmation", { type: txt })}</h2>;
  };

  const renderBody = () => {
    const txt = editType === "add" ? t("adding") : t("editing");

    return (
      <Fragment>
        <p>{t("addCoupon.cancelConfirmation_note_1", { type: txt })}</p>
        {editType === "add" && (
          <p>{t("addCoupon.cancelConfirmation_note_2")}</p>
        )}
      </Fragment>
    );
  };

  const renderSaveDraftBtn = () => {
    if (editType === "edit" && couponSetting.status !== "draft") return null;
    return (
      <button className="greenThemeBtn" onClick={saveDraft}>
        {t("saveAsDraft")}
      </button>
    );
  };

  return (
    <div className="cancelAddPopup">
      <div className="cancelAddPopup__header">{renderHeader()}</div>
      <div className="cancelAddPopup__body">{renderBody()}</div>

      <div className="cancelAddPopup__foter">
        <div>{renderSaveDraftBtn()}</div>
        <div>
          <button className="grayThemeBtn" onClick={cancelPopup}>
            {t("back")}
          </button>
          <button className="deepGrayThemeBtn" onClick={confirmPopup}>
            {t("confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelAddPopup;
