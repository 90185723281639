import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const Opening = ({ idx, setting, openEditor, deleteOpening, serviceTimeOptionEnabled }) => {
	const { t } = useTranslation('settings');
	const type_time = {
		2: t('bookingTimesCommon.fixedIntervalTimeDurationOptions'),
		3: t('bookingTimesCommon.customTimeDurationOptions')
	};

	const renderOpeningHours = () => {
		let opening_hours = '';
		if (setting.setting_temp.enable === 3) {
			opening_hours = setting.setting_temp.setting3.join('、');
		} else if (setting.setting_temp.enable === 2) {
			opening_hours = setting.setting_temp.setting2.map((r) => `${r[0]}~${r[1]}`).join('、');
		}
		return opening_hours;
	};

	const renderBookingTimeInterval = () => {
		if (setting.setting_temp.enable === 2) {
			return (
				<div className="settingTblRow">
					<div className='header'>
						{t('bookingTimesCommon.timeSlotsInterval')}
						<Tooltip
							placement="bottom"
							overlayClassName="setting-tooltip"
							getPopupContainer={triggerNode => triggerNode.parentElement}
							title={t('bookingTimesCommon.timeSlotsInterval_tooltip')}
						>
							<i className="fa fa-question-circle-o" />
						</Tooltip>
					</div>
					<div className="text">{setting.booking_time_interval ? setting.booking_time_interval : 30}{t('time:minute')}</div>
				</div>
			);
		}
	};

	const renderDiningTime = () => {
		let diningTime = [];
		let diningTime_min = 0;
		let diningTime_max = 0;
		
		if(serviceTimeOptionEnabled) {
			if(setting.service_time_setting[0].mode === 'user_setting') {
				diningTime = setting.service_time_setting[0].user_setting.service_time_setting;
	
				if(setting.service_time_setting[0].user_setting.show_name) {
					return (
						<div>
							<div className="text">{t('bookingTimesCommon.customTimeDurationOptions_2')}</div>
							<div className="diningTime_userSetting">
								{diningTime.map((time, index) => {
									return (
										<div key={index}>
											<span className="text">{time.name} ({Math.floor(parseInt(time.service_time, 10) / 60)} {t('time:hour')} {Math.floor(parseInt(time.service_time, 10) % 60)} {t('time:minute')})
											{
												index !== diningTime.length - 1 ? (<span>、</span>) : null
											}
											</span>
										</div>
									)
								})}
							</div>
						</div>
					)
				} else {
					return (
						<div>
							<div className="text">{t('bookingTimesCommon.customTimeDurationOptions_2')}</div>
							<div className="diningTime_userSetting">
								{diningTime.map((time, index) => {
									return (
										<span key={index}>
											<span className="text">{Math.floor(parseInt(time.service_time, 10) / 60)} {t('time:hour')} {Math.floor(parseInt(time.service_time, 10) % 60)} {t('time:minute')}
											{
												index !== diningTime.length - 1 ? (<span>、</span>) : null
											}
											</span>
										</span>
									)
								})}
							</div>
						</div>
					)
				}
			} else if (setting.service_time_setting[0].mode === 'fixed') {
				diningTime_min = setting.service_time_setting[0].fixed.min;
				diningTime_max = setting.service_time_setting[0].fixed.max;
	
				return (
					<div>
						<div className="text">{t('bookingTimesCommon.fixedIntervalTimeDurationOptions_2')}</div>
						<div className="text">{t('bookingTimesCommon.minimum')} {Math.floor(parseInt(diningTime_min, 10) / 60)} {t('time:hour')} {Math.floor(parseInt(diningTime_min, 10) % 60)} {t('time:minute')}</div>
						<div className="text">{t('bookingTimesCommon.maximum')} {Math.floor(parseInt(diningTime_max, 10) / 60)} {t('time:hour')} {Math.floor(parseInt(diningTime_max, 10) % 60)} {t('time:minute')}</div>
					</div>
				)
			}
		} else {
			let diningTime = setting.dining_time;

			return (
				<div className="text">{Math.floor(parseInt(diningTime, 10) / 60)} {t('time:hour')} {Math.floor(parseInt(diningTime, 10) % 60)} {t('time:minute')}</div>
			)
		}
	};

	const renderDiningTimeShowName = () => {
		let show_name = ''

		if (setting.service_time_setting[0].user_setting.show_name) {
			show_name = t('enabled');
		} else {
			show_name = t('disabled');
		}

		if(serviceTimeOptionEnabled) {
			if (setting.service_time_setting[0].mode === 'user_setting') {
				return (
					<div className="settingTblRow">
						<div className='header'>
							{t('bookingTimesCommon.customizeOptionNames')}
							<Tooltip
								placement="bottom"
								overlayClassName="setting-tooltip"
								getPopupContainer={triggerNode => triggerNode.parentElement}
								title={t('bookingTimesCommon.customizeOptionNames_tooltip')}
								>
								<i className="fa fa-question-circle-o" />
							</Tooltip>
						</div>
						<div className="text">{show_name}</div>
					</div>
				)
			}
		}
	}

	const renderDiningTimeInterval = () => {
		let interval = 30;

		if (setting.service_time_setting[0].fixed.unit) {
			interval = setting.service_time_setting[0].fixed.unit;
		}

		if(serviceTimeOptionEnabled) {
			if (setting.service_time_setting[0].mode === 'fixed') {
				return (
					<div className="settingTblRow">
						<div className='header'>
							{t('bookingTimesCommon.timeDurationIntervals')}
						</div>
						<div className="text">{interval}{t('time:minute')}</div>
					</div>
				);
			}
		}
	};

	return (
		<div>
			<div className="rangeTitle">
				<img src={require('../../../images/clock.svg')} alt="" />
				<h4>{t('specialOperatingDates.specialBookingTimes')}{idx + 1}</h4>
				<div>
					<button className="msLink" onClick={() => openEditor()}>
						{t('edit')}
					</button>
					<button className="msLink" onClick={() => deleteOpening()}>
						{t('delete')}
					</button>
				</div>
			</div>
			<div className="settingTbl">
				<div className="settingTblRow">
					<div className="header">{t('specialOperatingDates.specialBusinessDates')}</div>
					<div className="text">
						{setting.start_date} ~ {setting.end_date}
					</div>
				</div>
				<div className="settingTblRow">
					<div className="header">{t('bookingTimesCommon.bookingTimeSlots')}</div>
					<div className="text">
						{type_time[setting.setting_temp.enable]}
						<br />
						{renderOpeningHours()}
					</div>
				</div>
				{renderBookingTimeInterval()}
				<div className="settingTblRow">
					<div className='header'>
						{t('bookingTimesCommon.serviceDuration')}
						<Tooltip
							placement="bottom"
							overlayClassName="setting-tooltip"
							getPopupContainer={triggerNode => triggerNode.parentElement}
							title={t('bookingTimesCommon.serviceDuration_tooltip')}
						>
							<i className="fa fa-question-circle-o" />
						</Tooltip>
					</div>
					{renderDiningTime()}
				</div>
				{renderDiningTimeInterval()}
				{renderDiningTimeShowName()}
				<div className="settingTblRow">
					<div className='header'>
						{t('bookingTimesCommon.concurrentBookingLimit')}
						<Tooltip
							placement="bottom"
							overlayClassName="setting-tooltip"
							getPopupContainer={triggerNode => triggerNode.parentElement}
							title={t('bookingTimesCommon.concurrentBookingLimit_tooltip')}
						>
							<i className="fa fa-question-circle-o" />
						</Tooltip>
					</div>

					<div className="text">
						{!setting.max_people_at_a_time || parseInt(setting.max_people_at_a_time, 10) === 0 ? (
							t('bookingTimesCommon.unlimited')
						) : (
							setting.max_people_at_a_time + ` ${t('person')}`
						)}
					</div>
				</div>
			</div>

			<hr />
		</div>
	);
};

export default Opening;
