import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import moment from "moment";
import { browserHistory } from "react-router";
import { Link } from "react-router";
import { Space, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import BookingCustomerTags from "../items/BookingCustomerTags";
import BookingStatus from "../items/BookingStatus";

import BookingSystemApi from "../../booking_settings/BookingSystemApi";
import CheckModal from "../../popup/CheckModal";

import { useInterval } from "../../new_module/utils/data";
import { DudooSyncDisplay } from "../../commons/DudooSyncDisplay";

const BookingAPI = new BookingSystemApi();
export default (props) => {
  const { t, i18n } = useTranslation("bookingSystem");
  const { booking, serviceTimeOptionEnabled, isLastInTimeSlot } = props;
  const bookingDetailsRef = useRef(null);
  const bookingDetailsToggleRef = useRef(null);

  const renderBookingSource = () => {
    if (booking.source === "online") {
      if (booking.source_url_t === "google") {
        return <span className="bookingSourceIcon bookingSourceIcon-google" />;
      } else if (booking.source_url_t === "facebook") {
        return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
      } else if (booking.source_url_t === "instagram") {
        return (
          <span className="bookingSourceIcon bookingSourceIcon-instagram" />
        );
      } else if (booking.source_url_t === "menuApp") {
        return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
      } else if (booking.source_url_t === "other") {
        return <span className="bookingSourceIcon bookingSourceIcon-other" />;
      }
    }

    if (
      booking.source === "google_booking" &&
      booking.source_url_t === "google_reservation"
    ) {
      return (
        <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />
      );
    }
  };

  const renderDiningTime = () => {
    const { service_time_str, dining_time, source } = booking;

    const formatDiningDuration = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `(${t("time.diningTime", { X: hours, Y: mins })})`;
    };

    const shouldShowServiceTime =
      serviceTimeOptionEnabled && source !== "offline";

    return (
      <div className="diningTime color-subtitle">
        {shouldShowServiceTime && (
          <div className="text-body" style={{ marginBottom: "2px" }}>
            {service_time_str}
          </div>
        )}
        <div className="text-caption">{formatDiningDuration(dining_time)}</div>
      </div>
    );
  };

  const renderDeposit = () => {
    const {
      deposit_required,
      deposit_paid,
      last_pay_info,
      deposit,
      order_no,
      deposit_due_date,
    } = booking;

    if (!deposit_required) return null;

    const PaymentInfo = ({
      status,
      payTime,
      amount,
      paymentMethod,
      details,
      orderNo,
    }) => (
      <div className="payment-info-tooltip">
        <div>
          {t("payment.status")}：<span>{status}</span>
          {payTime && `(${payTime})`}
        </div>
        <div>
          {t("payment.amount")}：{amount}
        </div>
        {paymentMethod && (
          <div>
            {t("payment.method")}：{paymentMethod}
          </div>
        )}
        {details && <div>{details}</div>}
        {orderNo && (
          <div>
            {t("payment.orderID")}：{orderNo}
          </div>
        )}
      </div>
    );

    const getTooltipContent = () => {
      if (!deposit_paid) {
        return (
          <PaymentInfo
            status={t("payment.due")}
            amount={deposit}
            details={`${t("payment.dueDate")}：${
              deposit_due_date
                ? moment(deposit_due_date).format("YYYY/MM/DD HH:mm:ss")
                : ""
            }`}
          />
        );
      }

      if (!last_pay_info?.result?.data) return null;

      const payInfo = last_pay_info.result.data;
      const isCreditCard = payInfo.PaymentType === "CREDIT";

      return (
        <PaymentInfo
          status={t("payment.paid")}
          payTime={payInfo.PayTime}
          amount={deposit}
          paymentMethod={
            isCreditCard ? t("payment.creditCard") : t("payment.csv")
          }
          details={
            isCreditCard
              ? `${t("payment.cardNumber")}：${payInfo.Card6No.slice(
                  0,
                  4
                )} - ${payInfo.Card6No.slice(4)} - ** - ${payInfo.Card4No}`
              : `${t("payment.barcode")}：${payInfo.CodeNo}`
          }
          orderNo={order_no}
        />
      );
    };

    return (
      <Tooltip
        title={getTooltipContent()}
        trigger="click"
        placement="right"
        overlayClassName="deposit-popover"
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
      >
        <div className={`pay-info ${!deposit_paid ? "unpaid" : ""}`}>
          $ {deposit}
        </div>
      </Tooltip>
    );
  };

  const renderVisitRecordTotalTimes = () => {
    return Object.values(booking.customer_info.booking_records).reduce(
      (total, count) => total + count,
      0
    );
  };

  const renderSeatingBlock = () => {
    return (
      <Space size={[4, 4]} wrap>
        {JSON.parse(booking.seating_json).map((s, i) => (
          <div className="seatingBlock btn-text-md color-title" key={i}>
            {s.table_number}
          </div>
        ))}
      </Space>
    );
  };

  const renderServiceTagBlock = () => {
    const { customQ } = props;
    let serviceTagBlock = [],
      quantityBlock = [];
    let serviceMark = {};

    const existQuestion = customQ.map((q) => q.id.toString());

    try {
      serviceMark = JSON.parse(booking["customized_questions"]);
    } catch (e) {
      serviceMark = {};
    }

    const keys = Object.keys(serviceMark);

    customQ.forEach((q) => {
      keys.forEach((el) => {
        if (
          q["id"].toString() === el &&
          typeof serviceMark[el] === "object" &&
          existQuestion.indexOf(el) !== -1
        ) {
          let content = JSON.parse(q.content);
          let ansObj = serviceMark[el];

          Object.keys(ansObj).forEach((index) => {
            let optionAnsw = {};
            if (content[index] && ansObj[index] === -1) {
              serviceTagBlock.push(content[index].name);
            } else if (
              content[index] &&
              ansObj[index] !== -1 &&
              ansObj[index] !== 0
            ) {
              optionAnsw["name"] = content[index].name;
              optionAnsw["value"] = ansObj[index];
              quantityBlock.push(optionAnsw);
            }
          });
        }
      });
    });

    return (
      <Space size={4} wrap>
        {serviceTagBlock.map((ele, index) => (
          <div className="tagBlock serviceTagBlock" key={`service-${index}`}>
            {ele}
          </div>
        ))}
        {quantityBlock.map((ele, index) => (
          <div className="tagBlock serviceTagBlock" key={`quantity-${index}`}>
            {ele.name}x{ele.value}
          </div>
        ))}
      </Space>
    );
  };

  const renderQuestionsBlock = () => {
    const { customQ } = props;
    let question = {};
    let keys = [];
    let questionsBlock = [];
    const existQuestion = customQ.map((q) => q.id.toString());

    try {
      question = JSON.parse(booking["customized_questions"]);
    } catch (e) {
      question = {};
    }

    keys = Object.keys(question);

    customQ.forEach((q) => {
      keys.forEach((el) => {
        if (
          q["id"].toString() === el &&
          q["question_type"] === "question" &&
          existQuestion.indexOf(el) !== -1 &&
          question[el] !== ""
        ) {
          questionsBlock.push(question[q.id]);
        }
      });
    });

    if (questionsBlock.length !== 0) {
      return (
        <ol className="res-questionBlock text-body color-subtitle">
          {questionsBlock.map((ele, index) => {
            return ele.length > 0 ? <li key={index}>{ele}</li> : null;
          })}
        </ol>
      );
    }
  };

  const renderMemo = () => {
    if (booking["memo"] !== null && booking["memo"].trim() !== "") {
      return (
        <div className="customerMemo">
          <Space size={2} align="start">
            <div className="account-icon icon-md" />
            <div className="text-body color-subtitle">{booking["memo"]}</div>
          </Space>
        </div>
      );
    }
  };

  const renderShopMemo = () => {
    if (booking["shop_memo"] != null && booking["shop_memo"].trim() !== "") {
      return (
        <div className="res-shopMemo color-subtitle">
          <Space size={2} align="start">
            <div className="pen-icon icon-md" />
            {booking["shop_memo"]}
          </Space>
        </div>
      );
    }
  };

  const renderShortCutBtn = () => {
    const today = moment().format("YYYY-MM-DD");
    const queryDate = moment(
      new Date(browserHistory.getCurrentLocation().query.date)
    ).format("YYYY-MM-DD");

    if (queryDate === today) {
      return (
        <ShortCutBtn
          booking={booking}
          handleUpdateStatus={handleUpdateStatus}
        />
      );
    }
  };

  const toggleBookingDetails = () => {
    if (bookingDetailsRef.current && bookingDetailsToggleRef.current) {
      bookingDetailsRef.current.classList.toggle("bookingDetails-narrow");
      bookingDetailsToggleRef.current.classList.toggle("bookingDetails-narrow");
    }
  };

  const handleUpdateStatus = (new_status) => {
    const showTime = moment().format().slice(11, 16);
    props.setStatus(booking, new_status, props.updateData, showTime);
  };

  const renderStatus = () => {
    return (
      <div className="res-status">
        <BookingStatus
          mode="list"
          booking={booking}
          handleUpdateStatus={handleUpdateStatus}
        />
      </div>
    );
  };

  const renderBookingDetails = (className = "") => {
    const serviceBlock = renderServiceTagBlock();
    const questionsBlock = renderQuestionsBlock();
    const memo = renderMemo();
    const shopMemo = renderShopMemo();

    return (
      <div ref={bookingDetailsRef} className={`bookingDetails ${className}`}>
        {serviceBlock}
        {questionsBlock}
        {memo}
        {shopMemo}
      </div>
    );
  };

  return (
    <div
      className={`listViewRow ${isLastInTimeSlot ? "listViewRow-last" : ""}`}
      id={booking.active_code}
    >
      <div className="listViewRow-top">
        <div className="listViewRow-layer">
          <div className="listViewRow-top-cell listViewRow-top-cell1">
            <div>
              <Space size={5}>
                <span className="res-date heading-3-responsive">
                  {moment(booking.booking_datetime).format("HH:mm")}
                </span>
                {booking.on_site ? (
                  <span className="icon-md onsite-icon" />
                ) : null}
                {renderBookingSource()}
              </Space>
              {renderDiningTime()}
            </div>
            {renderDeposit()}
          </div>
          <div className="listViewRow-top-cell listViewRow-top-cell2">
            {booking["customer_id"] !== null &&
            (booking.customer_info.tags.length !== 0 ||
              booking.customer_info.note.length !== 0 ||
              booking.customer_info.blacklisted) ? (
              <BookingCustomerTags
                isBlocked={booking.customer_info.blacklisted}
                note={booking.customer_info.note}
                tags={booking.customer_info.tags}
              />
            ) : (
              <div className="customer_tag">
                <div className="icon-md" />
              </div>
            )}
            <div>
              {booking["customer_id"] !== null ? (
                <div className="res-name">
                  <Link
                    to={
                      props.booking.customer_id !== ""
                        ? `/dashboard/customers/${booking.customer_id}`
                        : window.location.pathname
                    }
                  >
                    <Space size={4} align="end" wrap>
                      {i18n.language !== "zh" && booking.gender !== "other" && (
                        <span className="text-body">
                          {t(`item.${booking.gender}`)}
                        </span>
                      )}
                      <span className="heading-3-responsive">
                        {booking.last_name}
                      </span>
                      {i18n.language === "zh" &&
                        booking.gender_desc.length !== 0 && (
                          <span className="text-body">
                            {booking.gender_desc}
                          </span>
                        )}
                    </Space>
                  </Link>
                </div>
              ) : (
                <div className="res-name">
                  <Space size={4} align="end" wrap>
                    <span className="heading-3-responsive">
                      {booking.last_name}
                    </span>
                    {booking.gender_desc.length !== 0 && (
                      <span className="text-body">{booking.gender_desc}</span>
                    )}
                  </Space>
                </div>
              )}
              <div>
                {booking.phone_number !== "" ? (
                  <a
                    className="res-phone-mobile text-body color-subtitle"
                    href={"tel:" + booking.phone_number}
                  >
                    {booking.phone_number}
                  </a>
                ) : (
                  ""
                )}
                <p className="res-phone text-subtitle color-subtitle">
                  {booking.phone_number}
                </p>
                {booking.phone_number !== "" && booking.customer_id === null ? (
                  <button
                    onClick={() => props.showAddCustomerModal(booking)}
                    className="addCustomer button-secondary btn-text-md"
                  >
                    <span className="icon-sm plus-icon" />
                    {t("saveCustomer")}
                  </button>
                ) : null}
                {booking?.customer_id &&
                  booking?.customer_info?.booking_records &&
                  booking?.customer_info?.last_booking && (
                    <div className="res-visitRecord text-caption">
                      {t("customerBookingCount")}{" "}
                      {renderVisitRecordTotalTimes()}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="listViewRow-top-cell listViewRow-top-cell3">
            <Space size={0} wrap>
              <span className="res-attendance heading-1-responsive">
                {booking.attendance}
              </span>
              <span className="icon-normal account-icon" />
            </Space>
          </div>
        </div>

        <div className="listViewRow-layer">
          <div className="listViewRow-top-cell listViewRow-top-cell4">
            {booking.source !== "offline" &&
            booking.space_group_selection_t !== "" ? (
              <div className="seatArea text-body-responsive text-subtitle">
                <span>{booking.space_group_selection_t}</span>
              </div>
            ) : null}
            <div>{renderSeatingBlock()}</div>
            {renderBookingDetails()}
          </div>
          {renderShortCutBtn()}
        </div>

        <div className="listViewRow-mobile">
          {renderBookingDetails("toggleBlock")}
        </div>
      </div>
      <div className="listViewRow-bottom">
        <div className="listViewRow-bottom-left">
          <button
            ref={bookingDetailsToggleRef}
            className="toggleBtn btn-effect"
          >
            <span className="toggleBtn-icon" onClick={toggleBookingDetails} />
          </button>
          <div>
            <DudooSyncDisplay
              mode="list"
              booking={booking}
              updateData={props.updateData}
            />
            <span className="color-intro text-caption-responsive">
              {t(`source.${booking.source}`)}｜
              {booking.updated_at !== booking.created_at
                ? `${t("item.updated")}: ${new moment(
                    booking.updated_at
                  ).format("YYYY/MM/DD HH:mm")} ｜ `
                : null}
              <span className="bookingList_time">
                {t("item.created")}:{" "}
                {new moment(booking.created_at).format("YYYY/MM/DD HH:mm")} ｜{" "}
                {t("item.bookingID")}: {booking.active_code}
              </span>
            </span>
          </div>
        </div>
        <div className="listViewRow-bottom-right">
          <Space size={12}>
            <button
              className="rsv-editBtn btn-effect"
              onClick={() => props.showNewAddBookingModal(booking, "editor")}
            >
              <div className="icon-normal edit-pen-icon" />
            </button>
            {renderStatus()}
          </Space>
        </div>
      </div>
    </div>
  );
};

const ShortCutBtn = ({ booking, handleUpdateStatus }) => {
  const { t } = useTranslation("bookingSystem");
  const today = moment().format("YYYY/MM/DD");
  const bookingTime = moment(new Date(booking.booking_datetime));
  const pre = bookingTime.clone().subtract(60, "m").valueOf();
  const tomorrow = moment(new Date(today)).add(1, "d").valueOf();
  
  const [hideStatusBtn, setHideStatusBtn] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [warningTales, setWarningTales] = useState([]);

  const seatedWarning = useCallback(() => {
    BookingAPI.checkSeated(booking.id).then((data) => {
      if (data.overlay_table.length !== 0) {
        setShowWarning(true);
        setWarningTales(data.overlay_table);
      } else {
        handleUpdateStatus("seated");
      }
    });
  }, [booking.id, handleUpdateStatus]);

  const statusBtnElement = useMemo(() => {
    if (hideStatusBtn) return null;
    
    let statusBtn = null;
    const newStatusTime = JSON.parse(booking.status_time);

    switch (booking.status) {
      case "unconfirmed":
        statusBtn = (
          <div className="listViewRow-top-cell listViewRow-top-cell5">
            <Space size={8} wrap>
              <button
                className="button-bookingStatus btn-confirmed"
                onClick={() => handleUpdateStatus("confirmed")}
              >
                {t("buttonStatus.confirmed")}
              </button>
              <button
                className="button-bookingStatus btn-cancel"
                onClick={() => handleUpdateStatus("cancel")}
              >
                {t("buttonStatus.cancel")}
              </button>
            </Space>
          </div>
        );
        break;
      case "confirmed":
        statusBtn = (
          <div className="listViewRow-top-cell listViewRow-top-cell5">
            <Space size={8} wrap>
              <button
                className="button-bookingStatus btn-show"
                onClick={() => handleUpdateStatus("show")}
              >
                {t("buttonStatus.show")}
              </button>
              <button
                className="button-bookingStatus btn-seated"
                onClick={() => handleUpdateStatus("seated")}
              >
                {t("buttonStatus.seated")}
              </button>
            </Space>
          </div>
        );
        break;
      case "show":
        statusBtn = (
          <div className="listViewRow-top-cell listViewRow-top-cell5">
            <Space size={8} wrap align="center">
              <div className="text-body">
                {t("item.arrivalTime")}
                <div>{newStatusTime.show}</div>
              </div>
              <button
                className="button-bookingStatus btn-seated"
                onClick={() => seatedWarning()}
              >
                {t("buttonStatus.seated")}
              </button>
            </Space>
          </div>
        );
        break;
      case "seated":
        statusBtn = (
          <div className="listViewRow-top-cell listViewRow-top-cell5">
            <Space size={8} wrap align="center">
              <div className="text-body">
                {t("item.seatingTime")}
                <div>{newStatusTime.seated}</div>
              </div>
              <button
                className="button-bookingStatus btn-finish"
                onClick={() => handleUpdateStatus("finish")}
              >
                {t("buttonStatus.finish")}
              </button>
            </Space>
          </div>
        );
        break;
      case "finish":
        statusBtn = (
          <div className="listViewRow-top-cell listViewRow-top-cell5">
            <div className="text-body">
              {t("item.exitTime")}
              <div>{newStatusTime.finish}</div>
            </div>
          </div>
        );
        break;
      default:
        statusBtn = null;
        break;
    }

    return statusBtn;
  }, [booking.status, booking.status_time, hideStatusBtn, handleUpdateStatus, t, seatedWarning]);

  useInterval(() => {
    const currentTime = Date.now();
    const shouldHideBtn = !(currentTime >= pre && currentTime < tomorrow);
    
    if (shouldHideBtn !== hideStatusBtn) {
      setHideStatusBtn(shouldHideBtn);
    }
  }, 1000);
  
  useEffect(() => {
    const currentTime = Date.now();
    setHideStatusBtn(!(currentTime >= pre && currentTime < tomorrow));
  }, [pre, tomorrow]);

  const cancelWarning = useCallback(() => {
    setShowWarning(false);
    setWarningTales([]);
  }, []);

  const confirmWarning = useCallback(() => {
    handleUpdateStatus("seated");
    setShowWarning(false);
    setWarningTales([]);
  }, [handleUpdateStatus]);

  return (
    <React.Fragment>
      {statusBtnElement}
      {showWarning && (
        <CheckModal
          title={t("popup.overlapping_title")}
          content={t("popup.overlapping_content", {
            tables: warningTales.join("、"),
          })}
          submit={confirmWarning}
          cancel={cancelWarning}
        />
      )}
    </React.Fragment>
  );
};