import axios from 'axios';
import { config } from '../../utils/config';

const BookingSettingApi = axios.create({
	baseURL: config.domain,
	withCredentials: true
});


// Booking Setting
export const getBookingSetting = () => BookingSettingApi.get('/dashboard/booking_settings', {
	headers: {
		'Accept': 'application/json',
	}
});


// updateBookingSetting
export const updateBookingSetting = (booking_setting) =>
	BookingSettingApi.patch('/dashboard/booking_settings', booking_setting, {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
		}
	});

  
// 座位設定
export const getBookingSettingSeats = () => BookingSettingApi.get('/dashboard/table_settings');

// 桌位圖
export const getTableMap = (date) =>
	BookingSettingApi.get('/dashboard/canvas_spaces', {
		params:{
			date
		},
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
		}
	});

// updateTableMap
export const updateTableMap = (canvas, canvas_id) =>
	BookingSettingApi.patch('/dashboard/canvas_spaces',  JSON.stringify({canvas, canvas_id}), {
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		}
	});

// get booking notification setting
export const getBookingNotification = () => BookingSettingApi.get("/dashboard/booking_settings/notification_settings");

export const updateBookingNotification = (booking_setting) => BookingSettingApi.post("/dashboard/booking_settings/notification_settings", booking_setting, {
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json; charset=utf-8',
	}
})