import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { config } from '../../utils/config'

import './shopFormBindModal.scss';

export default ({ select, logo, visible, cancelSelect, onSync }) => {
  const { t } = useTranslation('settings');
  const renderSelectPhotos = () => {
    if(select.objectId !== '' && select.photos) {
      if(select.photos.length > 0) {
        let photos = select.photos.map((photo) => {
          return (
            <div className="photo col-md-4 col-xs-4" key={photo.object_id}>
              <a
                href={config.photo_domain + "/" + photo.object_id}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={photo.url} style={{ width: "100%" }} alt="" />
              </a>
            </div>
          );
        })

        return (
          <div className="photos">
            { photos }
          </div>
        )
      } else {
        return <div className='text-center'><h5>{t('accountSettings.menu.noImageForThisLocation')}</h5></div>
      };
    } else {
      return <div/>
    };
  };

  const addShopDefaultSrc = (e) => {
		e.onerror = null;
		e.target.src = require('../../images/menuUser/Profile_Picture.svg');
  };

	return (
    <Modal
      destroyOnClose
      centered
      visible={visible}
      wrapClassName="shopBindModal"
      title={t("accountSettings.menu.confirmMerchantLocation")}
      footer={
        <button onClick={() => onSync(select.objectId)}>
          {t("accountSettings.menu.connectMENU")}
        </button>
      }
      onCancel={cancelSelect}
      transitionName="ant-move-down"
    >
      <div className="shopBindModalBody">
        <div className="logo">
          <img src={logo} onError={(e) => addShopDefaultSrc(e)} alt="" />
        </div>
        <div className="info">
          <div className="info_block">
            <div className="info_block_title">
              {t("accountSettings.merchantName")}
            </div>
            <div className="info_block_content">
              {select.name ? select.name : "-"}
            </div>
          </div>
          <div className="info_block">
            <div className="info_block_title">
              {t("accountSettings.branch")}
            </div>
            <div className="info_block_content">
              {select.branch ? select.branch : "-"}
            </div>
          </div>
          <div className="info_block">
            <div className="info_block_title">{t("accountSettings.phone")}</div>
            <div className="info_block_content">
              {select.PhoneNumber ? select.PhoneNumber : "-"}
            </div>
          </div>
          <div className="info_block">
            <div className="info_block_title">
              {t("accountSettings.address")}
            </div>
            <div className="info_block_content">
              {select.address ? select.address : "-"}
            </div>
          </div>
          <div className="info_block">
            <div className="info_block_title">
              {t("accountSettings.website")}
            </div>
            <div className="info_block_content">
              {select.objectId ? (
                <a
                  href={config.restaurant_domain + "/" + select.objectId}
                  target="_blank"
                  style={{ color: "#676767" }}
                  rel="noopener noreferrer"
                >
                  {config.restaurant_domain + "/" + select.objectId}
                </a>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="info_block">
            <div className="info_block_title">
              {t("accountSettings.menu.images_sum")}
            </div>
            <div className="info_block_content">
              {select.photoCount ? select.photoCount : "0"}
            </div>
          </div>
        </div>
        {renderSelectPhotos()}
      </div>
    </Modal>
  );
};
