import React, { Fragment, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { Progress } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../../crmContext/MessageContext";
import { MessageAddStateContext } from "../../crmContext/MessageAddContext";

import { filterCustomers } from "../../api/CrmMessageSmsApi";
import { filterFollowers } from "../../api/CrmMessageMenuTalkApi";

const customerTagJson = {
  vip: "VIP",
  vegan: "全素",
  egg_vegan: "蛋奶素",
  not_friendly: "奧客",
};

const MessageSendObjectSection = ({ children }) => {
  return (
    <section className="crmMessageAdd_block">
      {React.Children.map(children, (child) => child)}
    </section>
  );
};

const SendObjectSectionTitle = () => {
  const { t } = useTranslation("messages");
  const { MessageAddState } = useContext(MessageAddStateContext);
  const { error } = MessageAddState;

  return (
    <Fragment>
      <h3>{t("sendMenuTalk.targetAudience")}</h3>
      {error.includes("customers") || error.includes("followers") ? (
        <div className="error_text">
          {t("sendMenuTalk.pls_chooseTargetAudience")}
        </div>
      ) : null}
    </Fragment>
  );
};

const SendObjectSectionProgress = () => {
  const { t } = useTranslation("messages");
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState } = useContext(MessageAddStateContext);
  const { allCustomers, selectCustomer, allFollowers, selectFollower } =
    MessageAddState;

  const customers = allCustomers ? allCustomers : 0;
  const followers = allFollowers ? allFollowers : 0;
  let percent = 0;
  let percentText = 0;

  if (editType === "sms") {
    percent =
      selectCustomer && customers ? (selectCustomer / customers) * 100 : 0;
    percentText = Math.round((percent + Number.EPSILON) * 100) / 100;
  } else if (editType === "menuTalk") {
    percent =
      selectFollower && followers ? (selectFollower / followers) * 100 : 0;
    percentText = Math.round((percent + Number.EPSILON) * 100) / 100;
  }

  const renderObject = () => {
    if (editType === "sms") {
      if (allCustomers === selectCustomer || selectCustomer === 0) {
        return <div>所有顧客 {allCustomers} 人</div>;
      } else if (allCustomers !== selectCustomer) {
        return <div>篩選結果 {selectCustomer} 人</div>;
      }
    } else if (editType === "menuTalk") {
      if (allFollowers === selectFollower || selectFollower === 0) {
        return (
          <div>
            {t("sendMenuTalk.totalFollowers", { number: allFollowers })}
          </div>
        );
      } else if (allFollowers !== selectFollower) {
        return (
          <div>
            {t("sendMenuTalk.filterResult", { number: selectFollower })}
          </div>
        );
      }
    }
  };

  return (
    <div style={{ marginTop: "24px", marginBottom: "16px" }}>
      <div className="crmMessageAdd_customers">
        {renderObject()}
        <div>{percentText}%</div>
      </div>
      <Progress percent={percent} />
    </div>
  );
};

const SendObjectSectionFilter = () => {
  const { t } = useTranslation("messages");
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(
    MessageAddStateContext
  );
  const {
    allCustomers,
    selectCustomer,
    allFollowers,
    selectFollower,
    messageSetting,
  } = MessageAddState;
  const { filterConditions, filterTag, filterJson } = messageSetting;
  const {
    dateAfter,
    dateBefore,
    bookingMin,
    bookingMax,
    exchangeMin,
    exchangeMax,
    fetchMin,
    fetchMax,
    gender,
    tags,
  } = filterConditions;

  const genderJson = {
    male: t("sendMenuTalk.filterFollowers.male"),
    female: t("sendMenuTalk.filterFollowers.female"),
    other: t("sendMenuTalk.filterFollowers.others"),
  };

  const selectAllCustomers = async () => {
    try {
      const customersId = [];
      const { data } = await filterCustomers();

      data.customers.map((customer) => {
        return customersId.push(customer.id);
      });

      MessageAddDispatch({
        type: "setSelectCustomer",
        selectCustomer: allCustomers,
        customers: customersId,
      });
      MessageAddDispatch({ type: "cleanFilterConditions" });
    } catch (err) {
      console.log("select all customers err ----------", err);
    }
  };

  const selectAllFollowers = async () => {
    try {
      const followersId = [];
      const { data } = await filterFollowers();

      data.followers.map((follower) => {
        return followersId.push(follower.followerId);
      });

      MessageAddDispatch({
        type: "setSelectFollower",
        selectFollower: allFollowers,
        followers: followersId,
      });

      MessageAddDispatch({ type: "cleanFilterConditions" });
    } catch (err) {
      console.log("select all followers err ----------", err);
    }
  };

  const showFilterModal = () => {
    MessageAddDispatch({ type: "showSendObjectFilterModal" });
  };

  const handleFilterGender = (gender) => {
    const newFilterJson = _.cloneDeep(filterJson);

    if (editType === "sms") {
      delete newFilterJson.gender;
    } else {
      delete newFilterJson.isMale;
    }

    MessageAddDispatch({ type: "setFilterGender", gender, editType });
    if (JSON.stringify(newFilterJson) !== "{}") {
      submitFilterConditions(newFilterJson);
    } else {
      cleanFilterCondition();
    }
  };

  const handleFilterTag = (tag) => {
    const newTags = _.cloneDeep(tags);
    const newFilterJson = _.cloneDeep(filterJson);

    let index = tags.indexOf(tag);
    newTags.splice(index, 1);
    newFilterJson.customer_tags = newTags.toString();

    MessageAddDispatch({ type: "setFilterTag", tags: newTags });
    if (JSON.stringify(newFilterJson) !== "{}") {
      submitFilterConditions(newFilterJson);
    } else {
      cleanFilterCondition();
    }
  };

  const handleBookingDayRange = () => {
    const newFilterJson = _.cloneDeep(filterJson);
    delete newFilterJson.date_after;
    delete newFilterJson.date_before;

    MessageAddDispatch({
      type: "setFilterDateRanges",
      dateAfter: null,
      dateBefore: null,
    });
    if (JSON.stringify(newFilterJson) !== "{}") {
      submitFilterConditions(newFilterJson);
    } else {
      cleanFilterCondition();
    }
  };

  const handleBookingRecord = () => {
    const newFilterJson = _.cloneDeep(filterJson);
    delete newFilterJson.booking_min;
    delete newFilterJson.booking_max;

    MessageAddDispatch({
      type: "setFilterConditions",
      filterType: "bookingMin",
      value: "",
    });
    MessageAddDispatch({
      type: "setFilterConditions",
      filterType: "bookingMax",
      value: "",
    });
    if (JSON.stringify(newFilterJson) !== "{}") {
      submitFilterConditions(newFilterJson);
    } else {
      cleanFilterCondition();
    }
  };

  const handleObjectFilterTag = (e) => {
    const value = e.target.value;
    MessageAddDispatch({ type: "setObjectFilterTag", filterTag: value });
  };

  const handleCouponExchange = () => {
    const newFilterJson = _.cloneDeep(filterJson);
    delete newFilterJson.minExchangeCount;
    delete newFilterJson.maxExchangeCount;

    MessageAddDispatch({
      type: "setFilterConditions",
      filterType: "exchangeMin",
      value: "",
    });
    MessageAddDispatch({
      type: "setFilterConditions",
      filterType: "exchangeMax",
      value: "",
    });
    if (JSON.stringify(newFilterJson) !== "{}") {
      submitFilterConditions(newFilterJson);
    } else {
      cleanFilterCondition();
    }
  };

  const handleCouponFetch = () => {
    const newFilterJson = _.cloneDeep(filterJson);
    delete newFilterJson.minFetchCount;
    delete newFilterJson.maxFetchCount;

    MessageAddDispatch({
      type: "setFilterConditions",
      filterType: "fetchMin",
      value: "",
    });
    MessageAddDispatch({
      type: "setFilterConditions",
      filterType: "fetchMax",
      value: "",
    });
    if (JSON.stringify(newFilterJson) !== "{}") {
      submitFilterConditions(newFilterJson);
    } else {
      cleanFilterCondition();
    }
  };

  const submitFilterConditions = async (newFilterJson) => {
    if (editType === "sms") {
      try {
        const customersId = [];
        const { data } = await filterCustomers(newFilterJson);

        data.customers.map((customer) => {
          return customersId.push(customer.id);
        });

        MessageAddDispatch({
          type: "setSelectCustomer",
          selectCustomer: data.customers.length,
          customers: customersId,
        });
      } catch (err) {
        console.log("filter customers err ----------", err);
      }
    } else if (editType === "menuTalk") {
      if (newFilterJson["isMale"] === null) {
        delete newFilterJson.isMale;
      }

      try {
        const followersId = [];
        const { data } = await filterFollowers(newFilterJson);

        data.followers.map((follower) => {
          return followersId.push(follower.followerId);
        });

        MessageAddDispatch({
          type: "setSelectFollower",
          selectFollower: data.followers.length,
          followers: followersId,
        });

        if (gender === "other") {
          MessageAddDispatch({
            type: "setFilterMenuOtherGender",
            followers: data.followers,
          });
        }
      } catch (err) {
        console.log("filter customers err ----------", err);
      }
    }
  };

  const cleanFilterCondition = () => {
    MessageAddDispatch({
      type: "setSelectCustomer",
      selectCustomer: 0,
      customers: [],
    });

    MessageAddDispatch({
      type: "setSelectFollower",
      selectFollower: 0,
      followers: [],
    });

    MessageAddDispatch({ type: "cleanFilterConditions" });
  };

  const renderSmsFilterTag = () => {
    const renderCustomerTag = tags.map((item, index) => {
      return (
        <div className="tags" key={index}>
          標記：{customerTagJson[item]}
          <CloseOutlined onClick={() => handleFilterTag(item)} />
        </div>
      );
    });

    const renderBookingRecord = () => {
      if (bookingMin !== "" && bookingMax !== "") {
        return (
          <div className="tags">
            顧客擁有有效紀錄筆數：{bookingMin}筆-{bookingMax}筆
            <CloseOutlined onClick={() => handleBookingRecord()} />
          </div>
        );
      } else if (bookingMin !== "" && bookingMax === "") {
        return (
          <div className="tags">
            顧客擁有有效紀錄筆數：至少{bookingMin}筆
            <CloseOutlined onClick={() => handleBookingRecord()} />
          </div>
        );
      } else if (bookingMin === "" && bookingMax !== "") {
        return (
          <div className="tags">
            顧客擁有有效紀錄筆數：最多{bookingMax}筆
            <CloseOutlined onClick={() => handleBookingRecord()} />
          </div>
        );
      }
    };

    const renderBookingDay = () => {
      const $dateAfter = moment(dateAfter).format("YYYY年MM月DD日");
      const $dateBefore = moment(dateBefore).format("YYYY年MM月DD日");

      if (dateAfter !== null && dateBefore !== null) {
        return (
          <div className="tags">
            預約紀錄時間範圍：{$dateAfter}-{$dateBefore}
            <CloseOutlined onClick={() => handleBookingDayRange()} />
          </div>
        );
      } else if (dateAfter !== null && dateBefore === null) {
        return (
          <div className="tags">
            預約紀錄時間範圍：{$dateAfter}之後
            <CloseOutlined onClick={() => handleBookingDayRange()} />
          </div>
        );
      } else if (dateAfter === null && dateBefore !== null) {
        return (
          <div className="tags">
            預約紀錄時間範圍：{$dateBefore}之前
            <CloseOutlined onClick={() => handleBookingDayRange()} />
          </div>
        );
      }
    };

    const renderFilterConditionsNum = () => {
      let num = 0;

      if (editType === "sms") {
        const tagsLength = tags ? tags.length : 0;

        if (dateAfter !== null || dateBefore !== null) {
          num++;
        }

        if (bookingMin !== "" || bookingMax !== "") {
          num++;
        }

        if (gender !== "") {
          num++;
        }

        return num + tagsLength;
      }
    };

    return (
      <div className="filterTags">
        <div className="tagGroup">
          <button onClick={() => showFilterModal()}>
            <img
              src={require("../../../../images/crm/filter_icon.svg")}
              alt=""
            />
            {t("sendMenuTalk.filterFollowers.filter")}
            <span className="tags_num">{renderFilterConditionsNum()}</span>
          </button>
          {gender !== "" && (
            <div className="tags">
              性別：{genderJson[gender]}
              <CloseOutlined onClick={() => handleFilterGender("")} />
            </div>
          )}
          {renderCustomerTag}
          {renderBookingRecord()}
          {renderBookingDay()}
        </div>
        <div className="cleanTags" onClick={() => cleanFilterCondition()}>
          <CloseOutlined />
        </div>
        <div className="crmMessageAdd_input" style={{ marginTop: "8px" }}>
          <h5>為篩選顧客命名標籤（選填）</h5>
          <input
            type="text"
            value={filterTag}
            onChange={(e) => handleObjectFilterTag(e)}
            placeholder={`請輸入篩選顧客標籤名稱`}
          />
          <div>此命名僅會顯示於後台的「訊息」頁面供您備註傳送對象。</div>
        </div>
      </div>
    );
  };

  const renderMenuTalkFilterTag = () => {
    const renderCouponExchange = () => {
      if (exchangeMin !== "" && exchangeMax !== "") {
        return (
          <div className="tags">
            {t("sendMenuTalk.filterFollowers.redeemRange", {
              min: exchangeMin,
              max: exchangeMax,
            })}
            <CloseOutlined onClick={() => handleCouponExchange()} />
          </div>
        );
      } else if (exchangeMin !== "" && exchangeMax === "") {
        return (
          <div className="tags">
            {t("sendMenuTalk.filterFollowers.redeemMin", { min: exchangeMin })}
            <CloseOutlined onClick={() => handleCouponExchange()} />
          </div>
        );
      } else if (exchangeMin === "" && exchangeMax !== "") {
        return (
          <div className="tags">
            {t("sendMenuTalk.filterFollowers.redeemMax", { max: exchangeMax })}

            <CloseOutlined onClick={() => handleCouponExchange()} />
          </div>
        );
      }
    };

    const renderCouponFetch = () => {
      if (fetchMin !== "" && fetchMax !== "") {
        return (
          <div className="tags">
            {t("sendMenuTalk.filterFollowers.claimRange", {
              min: fetchMin,
              max: fetchMax,
            })}
            <CloseOutlined onClick={() => handleCouponFetch()} />
          </div>
        );
      } else if (fetchMin !== "" && fetchMax === "") {
        return (
          <div className="tags">
            {t("sendMenuTalk.filterFollowers.claimMin", { min: fetchMin })}

            <CloseOutlined onClick={() => handleCouponFetch()} />
          </div>
        );
      } else if (fetchMin === "" && fetchMax !== "") {
        return (
          <div className="tags">
            {t("sendMenuTalk.filterFollowers.claimMax", { max: fetchMax })}
            <CloseOutlined onClick={() => handleCouponFetch()} />
          </div>
        );
      }
    };

    const renderFilterConditionsNum = () => {
      let num = 0;

      if (editType === "menuTalk") {
        if (fetchMax !== "" || fetchMin !== "") {
          num++;
        }

        if (exchangeMax !== "" || exchangeMin !== "") {
          num++;
        }

        if (gender !== "") {
          num++;
        }

        return num;
      }
    };

    return (
      <div className="filterTags">
        <div className="tagGroup">
          <button onClick={() => showFilterModal()}>
            <img
              src={require("../../../../images/crm/filter_icon.svg")}
              alt=""
            />
            {t("sendMenuTalk.filterFollowers.filter")}
            <span className="tags_num">{renderFilterConditionsNum()}</span>
          </button>
          {gender !== "" && (
            <div className="tags">
              {t("sendMenuTalk.filterFollowers.gender_2")}
              {genderJson[gender]}
              <CloseOutlined onClick={() => handleFilterGender("")} />
            </div>
          )}
          {renderCouponExchange()}
          {renderCouponFetch()}
        </div>
        <div className="cleanTags" onClick={() => cleanFilterCondition()}>
          <CloseOutlined />
        </div>
        <div className="crmMessageAdd_input" style={{ marginTop: "8px" }}>
          <h5>{t("sendMenuTalk.filterFollowers.nameFilterFollowers")}</h5>
          <input
            type="text"
            value={filterTag}
            onChange={(e) => handleObjectFilterTag(e)}
            placeholder={t(
              "sendMenuTalk.filterFollowers.placeholder_nameFilterFollowers"
            )}
          />
          <div>
            {t("sendMenuTalk.filterFollowers.note_nameFilterFollowers")}
          </div>
        </div>
      </div>
    );
  };

  const renderRadio = () => {
    if (editType === "sms") {
      return (
        <Fragment>
          <div className="crmMessageAdd_radio" style={{ marginBottom: "16px" }}>
            <label>
              <input
                type="radio"
                name="customers"
                checked={
                  selectCustomer === allCustomers && selectCustomer !== 0
                    ? true
                    : false
                }
                onChange={() => selectAllCustomers()}
              />
              所有顧客
            </label>
          </div>
          <div className="crmMessageAdd_radio">
            <label>
              <input
                type="radio"
                name="customers"
                checked={
                  selectCustomer !== allCustomers && selectCustomer !== 0
                    ? true
                    : false
                }
                onChange={() => showFilterModal()}
              />
              篩選顧客
            </label>
          </div>
        </Fragment>
      );
    } else if (editType === "menuTalk") {
      return (
        <Fragment>
          <div className="crmMessageAdd_radio" style={{ marginBottom: "16px" }}>
            <label>
              <input
                type="radio"
                name="followers"
                checked={
                  selectFollower === allFollowers && selectFollower !== 0
                    ? true
                    : false
                }
                onChange={() => selectAllFollowers()}
              />
              {t("sendMenuTalk.allFollowers")}
            </label>
          </div>
          <div className="crmMessageAdd_radio">
            <label>
              <input
                type="radio"
                name="followers"
                checked={
                  selectFollower !== allFollowers && selectFollower !== 0
                    ? true
                    : false
                }
                onChange={() => showFilterModal()}
              />
              {t("sendMenuTalk.filterFollowers.title")}
            </label>
          </div>
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      {renderRadio()}
      {selectCustomer !== allCustomers && selectCustomer !== 0
        ? renderSmsFilterTag()
        : ""}
      {selectFollower !== allFollowers && selectFollower !== 0
        ? renderMenuTalkFilterTag()
        : ""}
    </Fragment>
  );
};

MessageSendObjectSection.Title = SendObjectSectionTitle;
MessageSendObjectSection.Progress = SendObjectSectionProgress;
MessageSendObjectSection.Filter = SendObjectSectionFilter;

export default MessageSendObjectSection;
