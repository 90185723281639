import React, { Component } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { withTranslation, Trans } from "react-i18next";
import classNames from "classnames";

import QueueOptionSelect from "./QueueOptionSelect";
import QueuePanelAPI from "./api/QueuePanelAPI";
import BookingSystemApi from "../booking_settings/BookingSystemApi";
import { getEstimatedWaitingTime } from "./QueuePanelHelper";
import { EMAIL_REGEX } from "../../utils/regex";

const Link = require("react-router").Link;

class QueueOptionArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOptionEditing: false,
      peopleCount: 0,
      name: "",
      phone: "",
      email: "",
      gender: "other",
      groupStatus: {},
      createdResponse: {},
      currentState: props.currentState,
      addDisabled: false,
    };
  }

  componentDidMount() {
    this.API = new QueuePanelAPI();
    this.BookingSystemAPI = new BookingSystemApi();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentState !== this.props.currentState) {
      if (this.props.currentState !== "addFailed")
        this.clearQueueRecordContent();
      this.setState({
        currentState: this.props.currentState,
      });
    }

    if (prevProps.groupTypes !== this.props.groupTypes) {
      let groupStatus = {};
      this.props.groupTypes.forEach((group) => {
        groupStatus[group.id] = group.status;
      });
      this.setState({
        groupStatus: groupStatus,
      });
    }
  }

  isNextButtonAvailable = () => {
    if (this.state.name !== "" && this.state.phone !== "") {
      return true;
    } else {
      return false;
    }
  };

  goToCustomQuestions = () => {
    this.props.updateOptionAreaState("customQuestion");
  };

  createQueueRecord = async () => {
    const { t } = this.props;

    if (isNaN(this.state.peopleCount)) {
      alert(t("option.pls_peopleNumerals"));
      return;
    }

    if (this.state.peopleCount === 0) {
      alert(t("option.pls_enteredPeople"));
      return;
    }

    if (isNaN(this.state.phone)) {
      alert(t("option.pls_phoneNumerals"));
      return;
    }

    if (this.state.email !== "" && !EMAIL_REGEX.test(this.state.email)) {
      alert(t("option.pls_emailFormat"));
      return;
    }

    this.setState({ addDisabled: true });

    const customerInfoData = {
      customer: {
        last_name: this.state.name,
        email: this.state.email,
        phone_number: this.state.phone.trim(),
        tele_number: "",
        gender: this.state.gender,
        note: "",
      },
    };

    await this.BookingSystemAPI.createCustomer(customerInfoData).catch((err) =>
      console.log("queue system createCustomer---", err)
    );

    await this.API.createQueueRecord({
      queue_group_id: this.props.currentGroupType.id,
      name: this.state.name,
      phone: this.state.phone,
      gender: this.state.gender,
      people_count: this.state.peopleCount,
      email: this.state.email,
    })
      .then((data) => {
        this.setState({
          createdResponse: data,
          addDisabled: false,
        });
        this.props.updateOptionAreaState("addSuccess");
      })
      .catch(() => {
        this.setState({
          addDisabled: false,
        });
        this.props.updateOptionAreaState("addFailed");
      });
  };

  clearQueueRecordContent = () => {
    this.setState({
      peopleCount: 0,
      name: "",
      phone: "",
      email: "",
    });
  };

  updateSelectStatus = (id, status) => {
    this.setState((prevState) => ({
      groupStatus: {
        ...prevState.groupStatus,
        [id]: status,
      },
    }));
  };

  toggleSwitch = (e) => {
    this.setState(
      (prevState) => ({
        isOptionEditing: !this.state.isOptionEditing,
      }),
      () => {
        if (!this.state.isOptionEditing) {
          Object.keys(this.state.groupStatus).forEach((key) => {
            this.API.editQueueGroup(
              {
                id: key,
                status: this.state.groupStatus[key],
              },
              (data) => {
                this.props.refreshAction();
                this.props.startTimer();
              }
            );
          });
        } else {
          this.props.stopTimer();
        }
      }
    );
  };

  expandOptionArea = (e) => {
    e.stopPropagation();
    let arrow = e.target;
    let arrow_icon = e.target.childNodes[0];
    let option = arrow.parentNode.parentNode.nextSibling;
    let style = window.getComputedStyle(option);

    console.log("arrow_icon", arrow_icon);

    if (style.display === "none") {
      arrow_icon.classList.remove("fa-angle-double-down");
      arrow_icon.classList.add("fa-angle-double-up");
      option.style.display = "block";
    } else if (style.display === "block") {
      option.style.display = "none";
      arrow_icon.classList.remove("fa-angle-double-up");
      arrow_icon.classList.add("fa-angle-double-down");
    }
  };

  render() {
    const { t } = this.props;
    let queueOptionArea = [];
    let customQuestion = [];
    this.props.customQuestions.forEach((question) => {
      switch (question.question_type) {
        case "tag":
          let tagContents = [];
          let tagObject = JSON.parse(question.content);
          Object.keys(tagObject).forEach((key, idx) => {
            tagContents.push(
              <div
                className="custom-question-tag"
                key={"custom-question-tag-" + idx}
              >
                {key}
              </div>
            );
          });
          customQuestion.push(
            <div>
              <h3>
                {question.title}
                {question.mutiple_select ? `（${t("option.multiple")}）` : ""}
              </h3>
              {tagContents}
            </div>
          );
          break;
        case "quantity":
          let optionObject = JSON.parse(question.content);
          let optionContents = [];
          Object.keys(optionObject).forEach((key, idx) => {
            optionContents.push(
              <div className="custom-question-option-container">
                <span className="custom-question-option-name">{key}</span>
                <select className="custom-question-option" defaultValue={1}>
                  <option value={1}>{2}</option>
                </select>
              </div>
            );
          });
          customQuestion.push(
            <div>
              <h3>
                {question.title}
                {question.mutiple_select ? `（${t("option.multiple")}）` : ""}
              </h3>
              {optionContents}
            </div>
          );
          break;
        case "question":
          customQuestion.push(
            <div>
              <h3>
                {question.title}
                {question.mutiple_select ? `（${t("option.multiple")}）` : ""}
              </h3>
              <input type="text" className="custom-question-input" />
            </div>
          );
          break;
        default:
          break;
      }
    });

    switch (this.state.currentState) {
      case "settings":
        return (
          <div
            className={classNames("queue-dashboard-options", {
              hideArea: this.props.hideOptionArea,
            })}
            style={{ border: "1px solid #3FBA87" }}
          >
            <div className="queue-dashboard-options-header">
              <h3>{t("option.startPeriod")}</h3>
            </div>
            <div className="queue-dashboard-options-area empty">
              <p>
                {t("option.initContent")}
                <br />
                {t("option.go")}
                <Link
                  to={{
                    pathname: "/dashboard/setting/group",
                  }}
                  style={{ color: "#404040", textDecoration: "none" }}
                >
                  <span>{t("option.setting")}</span>
                </Link>
              </p>
            </div>
          </div>
        );
      case "addFailed":
        return (
          <div
            className={classNames("queue-dashboard-options", {
              hideArea: this.props.hideOptionArea,
            })}
          >
            <div className="option-failed-container">
              <img
                src={require("../../images/queue/img_queue_failed.png")}
                alt=""
              />
              <h3>{t("option.notSuccess")}</h3>
              <p>{t("option.pls_repeatQueue")}</p>
              <button
                className="option-action-button"
                onClick={() => this.createQueueRecord()}
              >
                {t("option.resend")}
              </button>
              <button
                className="option-action-button complete"
                onClick={(e) => {
                  this.props.updateOptionAreaState("normal", e);
                }}
              >
                {t("option.back")}
              </button>
            </div>
          </div>
        );
      case "addSuccess":
        return (
          <div
            className={classNames("queue-dashboard-options", {
              hideArea: this.props.hideOptionArea,
            })}
          >
            <div className="option-success-container">
              <h3>{t("option.joinedQueue")}</h3>
              <p>
                {this.props.currentGroupType.name}
                <Trans
                  i18nKey="queueSystem:option.joinedGroup"
                  components={{ span: <span /> }}
                  values={{
                    title: this.props.currentGroupType.title,
                  }}
                />
              </p>
              <h2>{this.state.createdResponse.waiting_number}</h2>
              <p>
                <Trans
                  i18nKey="queueSystem:option.joinedWaiting"
                  components={{ span1: <span />, span2: <span /> }}
                  values={{
                    waiting_groups: this.state.createdResponse.waiting_groups,
                    waiting_time: getEstimatedWaitingTime(
                      this.props.timeCheckingCondition,
                      this.state.createdResponse.waiting_groups
                    ),
                  }}
                />
              </p>
              <button
                className="option-action-button"
                onClick={(e) => {
                  this.props.updateOptionAreaState("add", e);
                }}
              >
                {t("option.addMore")}
              </button>
              <button
                className="option-action-button complete"
                onClick={(e) => {
                  this.props.updateOptionAreaState("normal", e);
                }}
              >
                {t("option.back")}
              </button>
            </div>
          </div>
        );
      case "add":
        return (
          <div
            className={classNames("queue-dashboard-options", {
              hideArea: this.props.hideOptionArea,
            })}
          >
            {this.state.addDisabled && (
              <div className="status_loading">
                <LoadingOutlined />
              </div>
            )}
            <div className="queue-dashboard-options-header">
              <h3>
                {t("option.group")}
                {this.props.currentGroupType.title}
              </h3>
            </div>
            <div className="option-add-container">
              <p>*{t("option.partySize")}</p>
              <input
                type="text"
                placeholder={
                  this.props.currentGroupType.min_people_count ===
                  this.props.currentGroupType.max_people_count
                    ? this.props.currentGroupType.min_people_count
                    : this.props.currentGroupType.min_people_count +
                      "~" +
                      this.props.currentGroupType.max_people_count
                }
                onChange={(e) => {
                  this.setState({
                    peopleCount: e.target.value,
                  });
                }}
                value={
                  this.state.peopleCount !== 0 ? this.state.peopleCount : ""
                }
              />
            </div>
            <div className="option-add-container">
              <p>*{t("option.name")}</p>
              <input
                type="text"
                onChange={(e) => {
                  this.setState({
                    name: e.target.value,
                  });
                }}
                value={this.state.name}
              />
              <select
                className="custom-select"
                id="inlineFormCustomSelect"
                value={
                  this.state.gender === "other"
                    ? "other"
                    : this.state.gender === "male"
                    ? 1
                    : 2
                }
                onChange={(e) => {
                  if (e.target.value === "1") {
                    this.setState({
                      gender: "male",
                    });
                  } else if (e.target.value === "2") {
                    this.setState({
                      gender: "female",
                    });
                  } else if (e.target.value === "other") {
                    this.setState({
                      gender: "other",
                    });
                  }
                }}
              >
                <option value="other">{t("option.gender")}</option>
                <option value="1">{t("option.male")}</option>
                <option value="2">{t("option.female")}</option>
              </select>
            </div>
            <div className="option-add-container">
              <p>*{t("option.phoneNumber")}</p>
              <input
                type="text"
                onChange={(e) => {
                  this.setState({
                    phone: e.target.value,
                  });
                }}
                value={this.state.phone}
              />
            </div>
            <div className="option-add-container">
              <p>Email</p>
              <input
                type="text"
                onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                  });
                }}
              />
            </div>
            {this.isNextButtonAvailable() && !this.state.addDisabled ? (
              <button
                className="option-add-button"
                onClick={(e) => this.createQueueRecord()}
              >
                {t("option.queueUp")}
              </button>
            ) : (
              <button
                className="option-add-button"
                disabled
                onClick={(e) => {
                  this.props.updateOptionAreaState("addSuccess", e);
                }}
              >
                {t("option.queueUp")}
              </button>
            )}
            <button
              className="option-prev-button"
              onClick={(e) => this.props.updateOptionAreaState("normal", e)}
            >
              {t("option.goBack")}
            </button>
          </div>
        );
      case "customQuestion":
        return (
          <div
            className={classNames("queue-dashboard-options", {
              hideArea: this.props.hideOptionArea,
            })}
          >
            {customQuestion}
            {this.isNextButtonAvailable() && !this.state.addDisabled ? (
              <button
                className="option-add-button"
                onClick={(e) => this.createQueueRecord()}
              >
                {t("option.queueUp")}
              </button>
            ) : (
              <button
                className="option-add-button"
                disabled
                onClick={(e) => {
                  this.props.updateOptionAreaState("addSuccess", e);
                }}
              >
                {t("option.queueUp")}
              </button>
            )}
            <button
              className="option-prev-button"
              onClick={(e) => this.props.updateOptionAreaState("normal", e)}
            >
              {t("option.goBack")}
            </button>
          </div>
        );
      case "pause":
        this.props.groupTypes.forEach((groupType, index) => {
          queueOptionArea.push(
            <QueueOptionSelect
              key={index}
              optionParam={groupType}
              updateSelectStatus={this.updateSelectStatus}
              state="unavailable"
              isOptionEditing={this.state.isOptionEditing}
            />
          );
        });

        return (
          <div
            className={classNames("queue-dashboard-options", {
              hideArea: this.props.hideOptionArea,
            })}
          >
            <div className="queue-dashboard-options-header">
              <h3>
                <span className="queue-dashboard-expand">
                  <i className="fa fa-angle-double-down" aria-hidden="true" />
                </span>
                {t("option.startPeriod")}
              </h3>
            </div>
            <div className="queue-dashboard-options-area">
              {queueOptionArea}
            </div>
          </div>
        );
      case "normal":
        this.props.groupTypes.forEach((param, index) => {
          let enableSelectAction =
            param["status"] === "start" || param["status"] === "pause";
          let optionState = "normal";

          if (this.state.isOptionEditing) {
            optionState = "editing";
          } else if (param["status"] === "start") {
            optionState = "normal";
          } else if (param["status"] === "pause") {
            if (param["auto_pause"] === false) {
              optionState = "pause";
            } else {
              optionState = "autopause";
            }
          } else {
            optionState = "pause";
          }

          queueOptionArea.push(
            <QueueOptionSelect
              key={index}
              optionParam={param}
              updateSelectStatus={this.updateSelectStatus}
              selectAction={(ev) => {
                return enableSelectAction
                  ? this.props.startAddCustomers(param.id)
                  : console.log("不能用GG");
              }}
              state={optionState}
              isOptionEditing={this.state.isOptionEditing}
            />
          );
        });

        return (
          <div
            className={classNames("queue-dashboard-options", {
              hideArea: this.props.hideOptionArea,
            })}
          >
            <div className="queue-dashboard-options-header">
              <h3>
                <span
                  className="queue-dashboard-expand"
                  onClick={(e) => this.expandOptionArea(e)}
                >
                  <i className="fa fa-angle-double-down" aria-hidden="true" />
                </span>
                {this.state.isOptionEditing
                  ? t("option.enableOnlineQueuing")
                  : t("option.startPeriod")}
              </h3>
              <button onClick={this.toggleSwitch}>
                {this.state.isOptionEditing ? t("back") : t("edit")}
              </button>
            </div>
            <div className="queue-dashboard-options-area">
              {queueOptionArea}
            </div>
          </div>
        );
      default:
        console.log(" ");
        break;
    }

    return <div />;
  }
}

export default withTranslation("queueSystem")(QueueOptionArea);
