import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default ({ booking, toList }) => {
  const { t, i18n } = useTranslation("bookingSystem");
  return (
    <div className="bookingCard">
      <p>{moment(booking.booking_datetime).format("YYYY/MM/DD")}</p>
      <div
        className="bookingCardContent"
        onClick={() =>
          toList(moment(booking.booking_datetime).format("YYYY-MM-DD"), booking)
        }
      >
        <p className="time">
          {moment(booking.booking_datetime).format("HH:mm")}
        </p>
        <div className="infoFlex">
          <div className="infoLeft">
            <h2>
              <span>
                {booking.gender &&
                  i18n.language !== "zh" &&
                  t(`item.${booking.gender}`)}
              </span>
              {booking.last_name}
              <span>
                {booking.gender &&
                  i18n.language === "zh" &&
                  t(`item.${booking.gender}`)}
              </span>
            </h2>
            <div className="attendance">
              <span>{booking.attendance}</span>
              <span>{t("item.person")}</span>
            </div>
          </div>
          <div className="infoRight">
            {JSON.parse(booking.seating_json).map((seat, index) => (
              <label key={index} className="seat">
                {seat.table_number}
              </label>
            ))}
          </div>
        </div>
        <div className="bottom">
          <div>{booking.phone_number}</div>
          <div className="extra">
            <div className={`status status-${booking.status}`}>
              ●<span>{t(`status.${booking.status}`)}</span>
            </div>
            <div className="code">
              {t("item.bookingID")}：{booking.active_code}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
