import React, { useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { TablePicker } from "./TablePicker";
import { TimelineLeaveTimePanel } from "./TimelineLeaveTimePanel";

import BookingSystemApi from "../../booking_settings/BookingSystemApi";

//reducer
import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";

const BookingAPI = new BookingSystemApi();

const TimelineSeat = ({
  timelineTables,
  closeTimelineSeat,
  queueSeatedData,
  diningTime,
  updateAfterSeated,
  setSeatedSuccessData,
  setShowSeatedSuccess,
}) => {
  const { t } = useTranslation("settings");
  const bookingTime_min = moment().minute();
  const diff = 5 - (bookingTime_min % 5);
  const bookingTime_date = moment().add(diff, "m").format("YYYY/MM/DD HH:mm");

  const { setUuid } = useContext(CommomSettingContext);

  const [date] = useState(moment().format("YYYY/MM/DD"));
  const [current] = useState(bookingTime_date);
  const [step, setStep] = useState(1); //1選桌子 2離開時間
  const [bookingTable, setBookingTable] = useState([]);

  const createBooking = useCallback(
    (dataSubmit, checkBtn) => {
      BookingAPI.createBooking(dataSubmit)
        .then(() => {
          // window.app.alert.setMessage('新增預約成功', 'done');
          setSeatedSuccessData(queueSeatedData.name);
          setShowSeatedSuccess(true);
          closeTimelineSeat();
          updateAfterSeated();

          checkBtn.disabled = false;

          setTimeout(() => {
            setShowSeatedSuccess(false);
            setSeatedSuccessData("");
          }, 3000);
        })
        .catch(() => {
          checkBtn.disabled = false;

          window.app.alert.setMessage(
            t("status.pls_tryAgainLater"),
            "error"
          );
        });
    },
    [
			t,
      updateAfterSeated,
      closeTimelineSeat,
      setSeatedSuccessData,
      setShowSeatedSuccess,
      queueSeatedData,
    ]
  );

  const bookingSubmit = useCallback(
    (startTime, leaveTime, checkBtn) => {
      const diningTime = moment
        .duration(moment(new Date(leaveTime)).diff(new Date(startTime)))
        .asMinutes();

      let dataSubmit = {};
      const customerInfoData = {
        customer: {
          last_name: queueSeatedData.name,
          email: queueSeatedData.email,
          phone_number: queueSeatedData.phone.trim(),
          tele_number: "",
          gender: queueSeatedData.gender,
          note: "",
        },
      };

      //set uuid
      const eventId = uuidv4();
      setUuid(eventId);
      dataSubmit["event_id"] = eventId;

      dataSubmit["last_name"] = queueSeatedData.name;
      dataSubmit["email"] = queueSeatedData.email;
      dataSubmit["phone_number"] = queueSeatedData.phone.trim();
      dataSubmit["gender"] = queueSeatedData.gender;
      dataSubmit["booking_datetime"] = startTime;
      dataSubmit["attendance"] = queueSeatedData.people_count;
      dataSubmit["dining_time"] = diningTime;
      dataSubmit["seating_json"] = JSON.stringify(bookingTable);
      //排隊入座
      dataSubmit["source"] = "line_up";
      dataSubmit["queue_record_id"] = queueSeatedData.id;
      dataSubmit["shop_memo"] = queueSeatedData.shop_memo;
      dataSubmit["memo"] = queueSeatedData.memo;
      dataSubmit["waiting_time"] = moment().diff(
        moment(queueSeatedData.created_at),
        "minutes"
      );

      BookingAPI.createCustomer(customerInfoData)
        .then(() => {})
        .catch(() => {})
        .then(() => {
          createBooking(dataSubmit, checkBtn);
        });
    },
    [
      queueSeatedData.name,
      queueSeatedData.email,
      queueSeatedData.phone,
      queueSeatedData.gender,
      queueSeatedData.people_count,
      queueSeatedData.id,
      queueSeatedData.shop_memo,
      queueSeatedData.memo,
      queueSeatedData.created_at,
      setUuid,
      bookingTable,
      createBooking,
    ]
  );

  const backToStep1 = () => {
    setStep(1);
  };

  const renderStep = useCallback(() => {
    if (step === 1) {
      return (
        <TablePicker
          date={date}
          current={current}
          timelineTables={timelineTables}
          closeTimelineSeat={closeTimelineSeat}
          queueSeatedData={queueSeatedData}
          checkTable={checkTable}
        />
      );
    } else {
      return (
        <TimelineLeaveTimePanel
          bookingTable={bookingTable}
          backToStep1={backToStep1}
          currentTime={current}
          date={date}
          diningTime={diningTime}
          bookingSubmit={bookingSubmit}
          closeTimelineSeat={closeTimelineSeat}
        />
      );
    }
  }, [
    step,
    timelineTables,
    closeTimelineSeat,
    queueSeatedData,
    bookingTable,
    current,
    date,
    diningTime,
    bookingSubmit,
  ]);

  const checkTable = (choosenTable) => {
    setStep(2);
    setBookingTable(choosenTable);
  };

  return <div className="TimelineSeat">{renderStep()}</div>;
};

export default TimelineSeat;
