import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../crmContext/CustomerContext";
import { Tooltip, Space } from "antd";
import { useTranslation } from "react-i18next";

import BlockActionModal from "../crmCustomerPopup/BlockActionModal";
import { getTagsTranslationKey } from "../../utils/data";

const CrmCustomerRecordInfo = ({
  data,
  load,
  setIsHiddenNewBookingAddView,
  setShowRecordEditorModal,
}) => {
  const { t, i18n } = useTranslation("customer");
  const { CustomerState } = useContext(CustomerContext);
  const [isBlocked, setIsBlocked] = useState(false);
  const [showBlockActionModal, setShowBlockActionModal] = useState(false);

  const { loading } = CustomerState;

  useEffect(() => {
    setIsBlocked(data.blacklisted);
  }, [data]);

  const handleBlockActionModal = () => {
    setShowBlockActionModal(!showBlockActionModal);
  };

  const renderGender = () => {
    if (data.gender === "male") return t("info.male");
    if (data.gender === "female") return t("info.female");
    if (data.gender === "other") return t("info.other");
  };

  const renderTags = () => {
    return data.customer_tags.map(function (tag) {
      return (
        <div key={tag.id} className="c_tag">
          {t(getTagsTranslationKey(tag.description))}
        </div>
      );
    });
  };

  return (
    <div className={`crmCustomerRecordInfo ${i18n.language === 'en' ? "crmCustomerRecordInfo-en" : ""}`}>
      <div className="customer_contact">
        <div className="c_row" style={{ alignItems: "flex-end" }}>
          <div className="c_row c_tag_row">
            <div className="c_gender">{renderGender()}</div>
            {isBlocked || data.customer_tags.length !== 0 ? (
              <div className="c_divider" />
            ) : null}
            <div className="c_tags">
              <Space size={8} wrap>
                {isBlocked && (
                  <div className="c_tag c_blocked">{t("tags.blocked")}</div>
                )}
                {renderTags()}
              </Space>
            </div>
          </div>
          <div className="c_action">
            <button
              type="button"
              onClick={() => setShowRecordEditorModal(true)}
              disabled={loading}
            >
              {t("info.addNotes")}
            </button>
            <button
              type="button"
              onClick={() => setIsHiddenNewBookingAddView(false)}
              disabled={loading}
            >
              {t("info.addBookings")}
            </button>
          </div>
        </div>

        <hr />

        <div className="c_profile">
          <div className="c_profile_row">
            <div className="c_profile_title">{t("info.phone")}</div>
            <div className="c_profile_content">{data.phone_number}</div>
            {data.phone_verification && (
              <Tooltip
                placement="bottom"
                title={t("info.validatePhone")}
                trigger="hover"
                color="#333"
                arrowPointAtCenter
                overlayClassName="c_item_tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentNode} // scroll position fixed
              >
                <div className="c_verified">
                  <img
                    src={require("../../../../images/crm/verified_icon.svg")}
                    alt=""
                  />
                </div>
              </Tooltip>
            )}
            <button className="btn_outline" onClick={handleBlockActionModal}>
              {isBlocked ? t("info.unblockNumber") : t("info.blockNumber")}
            </button>
          </div>
          <div className="c_profile_row">
            <div className="c_profile_title">{t("info.email")}</div>
            <div className="c_profile_content">
              {data.email ? data.email : ""}
            </div>
          </div>
          <div className="c_profile_row">
            <div className="c_profile_title">{t("info.birth")}</div>
            <div className="c_profile_content">
              {data.birthday ? data.birthday : ""}
            </div>
          </div>
          <div className="c_profile_row">
            <div className="c_profile_title">{t("info.companyName")}</div>
            <div className="c_profile_content">
              {data.company ? data.company : ""}
            </div>
          </div>
          <div className="c_profile_row">
            <div className="c_profile_title">{t("info.companyPhone")}</div>
            <div className="c_profile_content">
              {data.company_tele ? data.company_tele : ""}
            </div>
          </div>
          <div className="c_profile_row">
            <div className="c_profile_title">{t("info.texId")}</div>
            <div className="c_profile_content">
              {data.company_id ? data.company_id : ""}
            </div>
          </div>
          <div className="c_profile_row">
            <div
              className="c_profile_title"
              style={{ alignSelf: "flex-start" }}
            >
              {t("info.remarks")}
            </div>
            <div className="c_profile_content">
              {data.note ? data.note : ""}
            </div>
          </div>
        </div>

        <div className="c_created">
          <div className="c_created_block">
            <div>
              <div className="c_created_title">{t("info.creator")}</div>
              <div className="c_created_content">
                {data.user_create ? data.user_create : t("info.autoCreated")}
              </div>
            </div>
            <div>
              <div className="c_created_title">{t("info.dateCreated")}</div>
              <div className="c_created_content">{data.user_created_at}</div>
            </div>
          </div>
          <div className="c_created_block">
            <div>
              <div className="c_created_title">{t("info.modifier")}</div>
              <div className="c_created_content">
                {data.user_update ? data.user_update : t("info.autoCreated")}
              </div>
            </div>
            <div>
              <div className="c_created_title">{t("info.dateModified")}</div>
              <div className="c_created_content">{data.user_updated_at}</div>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      {showBlockActionModal && (
        <BlockActionModal
          phone_number={data.phone_number}
          isBlocked={isBlocked}
          load={load}
          setIsBlocked={setIsBlocked}
          setShowBlockActionModal={setShowBlockActionModal}
        />
      )}
    </div>
  );
};

export default CrmCustomerRecordInfo;
