import React from "react";
import { browserHistory } from "react-router";
import { useTranslation } from "react-i18next";

export default function SMSCreditsWithBtn({ smsCredits }) {
  const { t } = useTranslation("queueSystem");

  const goToSMSSetting = () => {
    browserHistory.push("/dashboard/setting/notification-credits");
  };

  return (
    <div className="smsCreditsWithBtn">
      <div className="text-caption color-intro">{t("panel.smsCredits")}</div>
      <span>{smsCredits}</span>
      <button className="btn-text-md color-subtitle" onClick={goToSMSSetting}>
        {t("panel.topUp")}
      </button>
    </div>
  );
}
