import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { handleHeaderTab } from "../../../actions/announcementAction";

const AnnouncementTab = () => {
  const { t } = useTranslation("bulletinSystem");
  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const { currentTab } = announcementListReducer;
  const dispatch = useDispatch();

  const announcementStatusTab = {
    shop: t("list.internalAnnouncement"),
    official: t("list.systemAnnouncement"),
  };

  return (
    <div className="dateModeSwitcher-tab">
      {Object.keys(announcementStatusTab).map((option) => {
        return (
          <div
            key={option}
            className={option === currentTab ? "active" : ""}
            onClick={() => dispatch(handleHeaderTab(option))}
          >
            {announcementStatusTab[option]}
          </div>
        );
      })}
    </div>
  );
};

export default AnnouncementTab;
