import React, { createContext, useContext, useState, useCallback } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import OrderSeat from './BookingSeatsOrderSeat.js';
import { SEATTYPE } from './BookingSeatsOrder';
import { useTranslation } from 'react-i18next';

const OrderRowContext = createContext({});

const OrderRow = ({ children, title, seats, droppableId, openIndex, saveOpenIndex, hideHandle }) => {
	const [ showSeatList, setShowSeatList ] = useState(false);

	const toggleSeatList = useCallback(
		() => {
			if (showSeatList) {
				const newOpenIndex = manageArray.remove({ array: openIndex, removeItem: droppableId });
				saveOpenIndex(newOpenIndex);
				setShowSeatList(false);
			} else {
				const newOpenIndex = manageArray.add({ array: openIndex, addItem: droppableId });
				saveOpenIndex(newOpenIndex);
				setShowSeatList(true);
			}
		},
		[ droppableId, openIndex, saveOpenIndex, showSeatList ]
	);

	return (
		<OrderRowContext.Provider
			value={{ title, droppableId, showSeatList, toggleSeatList, openIndex, saveOpenIndex, hideHandle, seats }}
		>
			<section className="orderArea">
				<div className="dragRow orderRow" onClick={() => toggleSeatList()}>
					{React.Children.map(children, (child) => {
						if (child.key !== 'OrderSeats') return child;
					})}
				</div>
				{showSeatList &&
					React.Children.map(children, (child) => {
						if (child.key === 'OrderSeats') return child;
					})}
			</section>
		</OrderRowContext.Provider>
	);
};

const OrderDragHandle = () => {
	const { hideHandle } = useContext(OrderRowContext);
	return (
		<div className="dragRow__cell">
			<span className="dragHandle" style={{ display: hideHandle ? 'none' : 'block' }}>
				<i className="fa fa-ellipsis-v" aria-hidden="true" />
				<i className="fa fa-ellipsis-v" aria-hidden="true" />
			</span>
		</div>
	);
};

const OrderSeatGroup = () => {
	const { title } = useContext(OrderRowContext);
	return <div className="dragRow__cell">{title}</div>;
};

const OrderExpandButton = () => {
	const { t } = useTranslation('settings');
	const { seats, showSeatList } = useContext(OrderRowContext);
	const showListStyle = showSeatList ? 'showListStyle' : '';
	return (
		<div className="dragRow__cell">
			<span>{t('numberOfTables')}：{seats.length}</span>
			<button className={`${showListStyle}`}>
				<i className="fa fa-angle-right" aria-hidden="true" />
			</button>
		</div>
	);
};

const OrderSeats = ({ isDragDisabled }) => {
	const { droppableId, seats } = useContext(OrderRowContext);

	return (
		<div className="orderSeatsWrap">
			<Droppable droppableId={droppableId} type={SEATTYPE} isDropDisabled={isDragDisabled}>
				{(provided) => (
					<ul className="orderSeatsArea" ref={provided.innerRef}>
						{seats.map((seat, index) => {
							return (
								<Draggable key={seat.table_number} draggableId={`dragging_${seat.table_number}`} index={index}>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											style={provided.draggableProps.style}
											className="orderSeatsArea__row"
											{...provided.draggableProps}
											{...provided.dragHandleProps}
										>
											<div
												style={{
													boxShadow: snapshot.isDragging
														? '1px 1px 5px 0px rgba(51, 51, 51, 0.3)'
														: 'none'
												}}
											>
												<OrderSeat>
													<OrderSeat.SeatHandle />
													<OrderSeat.SeatTableNumber tableNumber={seat.table_number} />
													<OrderSeat.SeatCount
														minSeat={seat.min_seat}
														maxSeat={seat.max_seat}
													/>
												</OrderSeat>
											</div>
											{provided.placeholder}
										</div>
									)}
								</Draggable>
							);
						})}
						{provided.placeholder}
					</ul>
				)}
			</Droppable>
		</div>
	);
};

const manageArray = {
	add: ({ array, addItem }) => {
		let newArray = [ ...array ];
		newArray.push(addItem);
		return newArray;
	},
	remove: ({ array, removeItem }) => {
		let newArray = [ ...array ];
		const removeIndex = newArray.indexOf(removeItem);
		newArray.splice(removeIndex, 1);
		return newArray;
	}
};

OrderRow.OrderDragHandle = OrderDragHandle;
OrderRow.OrderSeatGroup = OrderSeatGroup;
OrderRow.OrderExpandButton = OrderExpandButton;
OrderRow.OrderSeats = OrderSeats;

export default OrderRow;
