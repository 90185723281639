import React, { Component } from "react";
import { Carousel } from "antd";
import QRCode from "qrcode.react";
import { debounce } from "lodash";
import { Howl } from 'howler';

import QueuePanelAPI from "./api/QueuePanelAPI";
import QueueSystemAPI from "../queue_system_settings/QueueSystemAPI";
import MenuCouponApi from "../menu_coupon/MenuCouponApi";
import {
  handleWebsocketConnection,
  handleWebsocketEventRegistry,
} from "../../libs/handler";
import { getEstimatedWaitingTime } from "./QueuePanelHelper";
import { queueDisplayLists_ikea } from "../../shopLists/queueDisplayLists_ikea";
import { config } from "../../utils/config";

import playSoundOn from "../../images/queue/icon-voice_on.svg";
import playSoundOff from "../../images/queue/icon-voice_off.svg";

import "antd/dist/antd.css";
import "../../sass/customize_ikea.scss";

class QueueRTDashboard extends Component {
  state = {
    groupTypes: [],
    timeCheckingCondition: "",
    displayName: "",
    custom_ikea: false,
    isSpeaking: false,
    isSoundAllowed: false 
  };

  constructor(props) {
    super(props);
    this.API = new QueuePanelAPI();
    this.systemAPI = new QueueSystemAPI();
    this.menuCouponAPI = new MenuCouponApi();

    this.detectWindow = debounce(this.detectWindow.bind(this), 200);

    this.speechQueue = [];
  }

  componentDidMount() {
    this.refreshCurrentContent();
    window.addEventListener("resize", this.detectWindow);

    this.setupWebsocketListeners();
    this.initializeAudio();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.detectWindow);
  }

  initializeAudio = () => {
    try {
      if (typeof Howl === "undefined") {
        console.error("Howler is not supported on this device/browser.");
        return;
      }

      this.sounds = {
        please: new Howl({ src: ["/sounds/phrases/zh/please.mp3"] }),
        goToCounter: new Howl({ src: ["/sounds/phrases/zh/goToCounter.mp3"] }),
        numbers: Array.from(
          { length: 10 },
          (_, i) => new Howl({ src: [`/sounds/digits/zh/${i}.mp3`] })
        ),
      };
    } catch (error) {
      console.error("Error initializing audio:", error);
    }
  };

  queueSpeech = (number) => {
    if (number === null || number === undefined) return;
    if (!this.state.isSoundAllowed) return;

    if (!this.state.isSpeaking) {
      this.speak(number);
    } else {
      this.speechQueue.push(number);
    }
  };

  speak = (number) => {
    if (!this.state.isSoundAllowed) {
      this.speechQueue = [];
      return;
    }

    this.setState({ isSpeaking: true });

    const sequence = [
      this.sounds.please,
      ...number.toString().split('').map(n => this.sounds.numbers[n]),
      this.sounds.goToCounter
    ];
    let currentIndex = 0;

    const playNext = () => {
      if (currentIndex >= sequence.length) {
        this.setState({ isSpeaking: false }, () => {
          if (this.speechQueue.length > 0) {
            const nextNumber = this.speechQueue.shift();
            this.speak(nextNumber);
          }
        });
        return;
      }

      const sound = sequence[currentIndex];
      sound.once('end', () => {
        currentIndex++;
        playNext();
      });

      try {
        sound.play();
      } catch (error) {
        console.error('Error playing sound:', error);
      }
    };

    playNext();
  };

  setupWebsocketListeners() {
    handleWebsocketConnection();

    handleWebsocketEventRegistry("sync_frontend", () =>
      this.refreshCurrentContent()
    );

    handleWebsocketEventRegistry("sync_queue_groups", () =>
      this.refreshCurrentContent()
    );

    handleWebsocketEventRegistry("play_sound", (data) => {
      this.queueSpeech(data.number);
      this.refreshCurrentContent();
    });
  }

  refreshCurrentContent() {
    Promise.all([
      this.API.getListedQueueGroup(),
      this.systemAPI.getQueueSettings(),
      this.menuCouponAPI.getShops(),
    ])
      .then(([queueData, settingsData, shopData]) => {
        const custom_ikea = queueDisplayLists_ikea.includes(shopData.shop.id);

        this.setState(
          {
            groupTypes: queueData?.queue_groups || [],
            currentStartTime: queueData?.start_time
              ? `開始時間: ${queueData.start_time}`
              : "",
            timeCheckingCondition: settingsData?.time_checking,
            displayName: settingsData?.display_name,
            live_page_url: settingsData?.live_page_url,
            custom_ikea,
          },
          this.detectWindow
        );
      })
      .catch((error) => {
        console.error("queue RTD dashboard error -----", error);
      });
  }

  detectWindow() {
    let w_w = window.innerWidth,
      w_h = window.innerHeight;
    // let container = document.getElementsByClassName("container-black")[0],
    //   container_padding_top = parseInt(
    //     window
    //       .getComputedStyle(container, null)
    //       .getPropertyValue("padding-top"),
    //     10
    //   ),
    //   container_padding_bottom = parseInt(
    //     window
    //       .getComputedStyle(container, null)
    //       .getPropertyValue("padding-bottom"),
    //     10
    //   ),
    //   container_padding_height = parseInt(
    //     container_padding_top + container_padding_bottom,
    //     10
    //   );
    let display_group = document.getElementsByClassName("queue-display-group");
    // let header_height =
    //   document.getElementsByClassName("queue-display-header")[0].offsetHeight +
    //   14;
    // let footerHeight = document.getElementsByClassName(
    //     "queue-display-footer"
    //   )[0].offsetHeight,
    //   qrcodeHeight = document.getElementsByClassName("queue-display-qrcode")[0]
    //     .offsetHeight,
      // offset_Height = qrcodeHeight - footerHeight + 30;
    // let all_height =
      // footerHeight + offset_Height + header_height + container_padding_height;
    // let portrait_group_height = Math.floor((w_h - all_height) * 0.3);
    // let portrait_marginBottomHeight =
      // (w_h - all_height - portrait_group_height * 3) / 3;
    // let landscape_group_height = w_h - all_height - 20;

    if (w_w < w_h) {
      for (let i = 0; i < display_group.length; i++) {
        // display_group[i].style.height = portrait_group_height - 5 + "px";
        // display_group[i].style.marginBottom =
        //   portrait_marginBottomHeight + "px";

        if (w_h >= 1120) {
          display_group[i].classList.remove("portrait__layout");
        } else {
          display_group[i].classList.add("portrait__layout");
        }
      }
    } else {
      for (let i = 0; i < display_group.length; i++) {
        // display_group[i].style.height = landscape_group_height + "px";
        display_group[i].classList.remove("portrait__layout");
      }
    }
  }

  renderGroupContent(groupType, index) {
    const { timeCheckingCondition } = this.state;
    const showEstimateTime =
      timeCheckingCondition && timeCheckingCondition !== "[]";

    const groupClasses = [
      "queue-display-group",
      groupType.status === "end" ? "unavailable" : "",
      index % 3 === 2 ? "last" : "",
    ]
      .filter(Boolean)
      .join(" ");

    const estimatedTime = getEstimatedWaitingTime(
      timeCheckingCondition,
      groupType.current_waiting_groups,
      false
    );

    return (
      <div key={index} className={groupClasses}>
        <div className="queue-display-group-header">
          <h2 className="queue-display-group-name">
            {groupType.title} {groupType.status === "end" && "(未開放)"}
          </h2>
        </div>
        <p className="queue-display-group-content-num">
          {groupType.last_seated_number > -1 ? (
            groupType.last_seated_number
          ) : (
            <span>尚未叫號</span>
          )}
        </p>
        <div className="queue-display-group-waiting">
          {showEstimateTime && (
            <>
              等候時間約
              <span>
                {typeof estimatedTime === "string"
                  ? estimatedTime.replace("少於", "")
                  : estimatedTime}
              </span>
              分鐘
              {typeof estimatedTime === "string" &&
                estimatedTime.includes("少於") &&
                "內"}
            </>
          )}
        </div>
        <div className="queue-display-group-content">
          <p className="queue-display-group-content-count">
            等候組數<span>{groupType.current_waiting_groups}</span>
          </p>
        </div>
      </div>
    );
  }

  toggleSpeech = () => {
    if (!this.sounds || typeof Howl === "undefined") return;

    this.setState(
      (prevState) => ({ isSoundAllowed: !prevState.isSoundAllowed }),
      () => {
        if (!this.state.isSoundAllowed) {
          // 停止所有音訊播放
          this.speechQueue = [];
          this.setState({ isSpeaking: false });
        }
      }
    );
  };

  render() {
    const { groupTypes, custom_ikea, displayName, live_page_url } = this.state;

    const allPausedOrClosed = groupTypes.every(
      (groupType) => groupType.status === "pause" || groupType.status === "end"
    );

    const scrollGroupContents = groupTypes
      .filter(
        (groupType) =>
          !(groupType.auto_pause && groupType.current_waiting_groups === 0)
      )
      .reduce((acc, groupType, index) => {
        const groupIndex = Math.floor(index / 3);
        if (!acc[groupIndex]) acc[groupIndex] = [];
        acc[groupIndex].push(this.renderGroupContent(groupType, index));
        return acc;
      }, [])
      .map((groups, index) => <div key={index}>{groups}</div>);

    return (
      <div
        style={{ background: "#4F4F5B" }}
        className={custom_ikea ? "custom-ikea" : ""}
      >
        <div className="container container-shadow-dark container-black">
          <h3 className="queue-display-header" onClick={toggleFullScreen}>
            排隊動態
          </h3>
          <button className="button-speaker" onClick={this.toggleSpeech}>
            <img
              src={this.state.isSoundAllowed ? playSoundOn : playSoundOff}
              alt=""
            />
          </button>

          {custom_ikea
            ? this.renderIkeaContent(scrollGroupContents)
            : this.renderCarouselContent(scrollGroupContents)}

          {this.renderFooter(allPausedOrClosed, displayName, live_page_url)}
        </div>
      </div>
    );
  }

  renderIkeaContent(scrollGroupContents) {
    return (
      <div className="ikea-content">
        <div className="ikea-queue">{scrollGroupContents}</div>
        <div className="ikea-ad">
          <Carousel autoplaySpeed={15000} autoplay>
            {[1, 2, 3, 4, 5].map((num) => (
              <img
                key={num}
                src={require(`../../images/ikea/ikea_system_${num}.jpg`)}
                alt={`IKEA 系統 ${num}`}
              />
            ))}
          </Carousel>
        </div>
      </div>
    );
  }

  renderCarouselContent(scrollGroupContents) {
    return (
      <Carousel style={{ height: "600px" }} autoplay>
        {scrollGroupContents}
      </Carousel>
    );
  }

  renderFooter(allPausedOrClosed, displayName, live_page_url) {
    const { custom_ikea } = this.state;

    return (
      <div className="queue-display-footer">
        <div className="queue-display-qrcode">
          <QRCode
            size={200}
            value={`${config.queue_domain}/live/${live_page_url}`}
          />
        </div>
        <h2>{displayName}</h2>

        {allPausedOrClosed ? (
          <h3 className="error">暫停開放新的排隊登記</h3>
        ) : (
          <div className="subtitle">
            <h3>掃描 QR Code 線上登記排隊</h3>
          </div>
        )}

        {custom_ikea && (
          <div className="ikea_logo">
            <img src={require("../../images/IKEA_logo.svg")} alt="IKEA" />
          </div>
        )}
      </div>
    );
  }
}

// 全螢幕切換函式
function toggleFullScreen() {
  const doc = document.documentElement;
  const fullscreenElement =
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement;

  if (!fullscreenElement) {
    if (doc.requestFullscreen) doc.requestFullscreen();
    else if (doc.msRequestFullscreen) doc.msRequestFullscreen();
    else if (doc.mozRequestFullScreen) doc.mozRequestFullScreen();
    else if (doc.webkitRequestFullscreen)
      doc.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
  } else {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.msExitFullscreen) document.msExitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
  }
}

export default QueueRTDashboard;
