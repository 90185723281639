import React from 'react'
import SettingsSaveButton from '../commons/SettingsSaveButton';
import { useTranslation } from "react-i18next";

function UserPassword({
  oldPassword,
  newPassword,
  newPasswordConfirm,
  passwordError,
  handleInputChange,
  submit
}) {
  const { t } = useTranslation("settings");
  const {
    oldPasswordEmpty,
    oldPasswordWrong,
    newPasswordLength,
    confirmPasswordLength,
    passwordValidate
  } = passwordError;
  return (
    <div className='userBLock'>
      <div className='title'>{t('userPanel.changePasswords')}</div>
      <div className='row__input'>
        <div className="subtitle">{t('userPanel.currentPasswords')}</div>
        <input
          type="password"
          name='old_password'
          className={`${oldPasswordEmpty || oldPasswordWrong ? 'input_error' : ''}`}
          value={oldPassword}
          onChange={handleInputChange}
        />
        { oldPasswordEmpty && <div className='text_error'>{t('userPanel.pls_enterCurrentPasswords')}</div> }
        { oldPasswordWrong && <div className='text_error'>{t('userPanel.pls_currentPasswordsIsIncorrect')}</div> }
      </div>
      <div className='row__input'>
        <div className="subtitle">{t('userPanel.enterNewPasswords')}</div>
        <input
          type="password"
          name='new_password'
          className={`${newPasswordLength || passwordValidate? 'input_error' : ''}`}
          value={newPassword}
          onChange={handleInputChange}
          placeholder={t('userPanel.placeholder_passwords')}
        />
        { passwordValidate && <div className='text_error'>{t('userPanel.pls_passwordsNotMatch')}</div>}
        { newPasswordLength && <div className='text_error'>{t('userPanel.pls_passwordsAtLeast')}</div>}
      </div>
      <div className='row__input'>
        <div className="subtitle">{t('userPanel.confirmPasswords')}</div>
        <input
          type="password"
          name='new_password_confirm'
          className={`${passwordValidate || confirmPasswordLength ? 'input_error' : ''}`}
          value={newPasswordConfirm}
          onChange={handleInputChange}
          placeholder={t('userPanel.placeholder_passwords')}
        />
        { passwordValidate && <div className='text_error'>{t('userPanel.pls_passwordsNotMatch')}</div>}
        { confirmPasswordLength && <div className='text_error'>{t('userPanel.pls_passwordsAtLeast')}</div>}
      </div>
      <SettingsSaveButton handleSaved={submit} showDivider/>
    </div>
  )
}

export default UserPassword