import $ from 'jquery';

export default class QueueSystemAPI {

    createQueueSettings(callback) {
        $.ajax({url: window.domain + '/dashboard/queue_settings', 
                method: 'POST',
                xhrFields: { withCredentials: true }})
        .done((data) => {
            callback(data);
        })
        .fail((xhr) => {
            callback();
        }); 
    }

    /* 
        [Settings Format]

        display_name: String,
        branch: String,
        addr: String,
        phone: String,
        intro: Text,
        external_link: String,
        external_link_text: String,
        pause_msg: Text,
        show_waiting_group: Boolean,
        show_waiting_time: Boolean,
        time_checking: Text,
        default_opening_time: Text,
        notification_msg: Text,
        auto_notify: Boolean,
        sms_enable: Boolean
    */

    getQueueSettings() {
       return new Promise(function(resolve, reject) {
            $.ajax({url: window.domain + '/dashboard/queue_settings', 
                    method: 'GET',
                    xhrFields: { withCredentials: true }})
            .done((data) => {
                resolve(data)
            })
            .fail((xhr) => {
                let error = new Error('取得 Queue Settings 資料失敗')
                reject(error)
            }); 
        });
    }

    updateQueueSettings(settings) {
        return new Promise(function(resolve, reject) {
            $.ajax({url: window.domain + '/dashboard/queue_settings', 
                    data: {
                        queue_settings: settings
                    },
                    method: 'PATCH',
                    xhrFields: { withCredentials: true }})
            .done((data) => {
                resolve(data);
            })
            .fail((xhr) => {
                let error = new Error('更新 Queue Settings 資料失敗')
                reject(error)
            }); 
        });
    }

    updateQueueBackgroundImage(file) {
        let fd = new FormData()
        return new Promise(function(resolve, reject) {
            fd.append('queue_settings[background_img]', file)
            $.ajax({url: window.domain + '/dashboard/queue_settings', 
                    data: fd,
                    processData: false,
                    contentType: false,
                    method: 'PATCH',
                    xhrFields: { withCredentials: true }})
            .done((data) => {
                resolve(data);
            })
            .fail((xhr) => {
                let error = new Error('更新 Queue Settings 資料失敗')
                reject(error)
            }); 
        });
    }


    deleteQueueMenuImage(imageID) {
        return new Promise(function(resolve, reject) {
            $.ajax({url: window.domain + '/dashboard/queue_settings/menu_img/'+imageID, 
                    method: 'DELETE',
                    xhrFields: { withCredentials: true }})
            .done((data) => {
                resolve(data);
            })
            .fail((xhr) => {
                let error = new Error('刪除 menu image 失敗')
                reject(error)
            }); 
        });
    }

    updateQueueMenuImageOrder(imageID, order) {
        return new Promise(function(resolve, reject) {
            $.ajax({url: window.domain + '/dashboard/queue_settings/menu_img_order/'+imageID, 
                    data: {
                        image_order: order
                    },
                    method: 'PATCH',
                    xhrFields: { withCredentials: true }})
            .done((data) => {
                resolve(data);
            })
            .fail((xhr) => {
                let error = new Error('更新 menu image 順序資料失敗')
                reject(error)
            }); 
        });
    }

    updateQueueMenuImage(files, menuPreviewUrls) {
        let fd = new FormData();
        let updateQueueMenuUrls = JSON.parse(JSON.stringify(menuPreviewUrls))

        //new images
        if(files.length !== 0){
            updateQueueMenuUrls.forEach(item=>{
                if(item.hasOwnProperty("fileImgOrder")){
                    files.forEach(ele=>{
                        if(ele.fileImgOrder === item.fileImgOrder){
                            fd.append('queue_settings[upload_imgs][]', ele)
                        }
                    })
                    Object.keys(item).forEach(k=> delete item[k])
                }
            })
        }
        
        //changes 
        fd.append('queue_settings[queue_menu_imgs]', JSON.stringify(updateQueueMenuUrls))

        return new Promise(function(resolve, reject) {
            
            $.ajax({url: window.domain + '/dashboard/queue_settings/queue_menu_imgs',
                    data: fd,
                    processData: false,
                    contentType: false,
                    method: 'PATCH',
                    xhrFields: { withCredentials: true }})
            .done((data) => {
                resolve(data);
                // console.log("更新菜單圖片！")
            })
            .fail((xhr) => {
                // console.log("更新菜單圖片失敗！")
                let error = new Error('更新 Queue Menu Image 資料失敗')
                reject(error)
            }); 
        });
    }

    /* 
        [groupParam Format]

        title: String,
        available: boolean,
        max_people_count: int,
        min_pepople_count: int,
        count_limit: int , #排隊上限組數
        begin_number: int, #排隊開始序號
    */
    createQueueGroup(groupParam, callback) {
        $.ajax({url: window.domain + '/dashboard/queue_settings/queue_group', 
                data: groupParam,
                method: 'POST',
                xhrFields: { withCredentials: true }})
        .done((data) => {
            callback(data);
        })
        .fail((xhr) => {
            callback();
        }); 
    }

    editQueueGroup(groupParam, callback) {
        $.ajax({url: window.domain + '/dashboard/queue_settings/queue_group/' + groupParam.id, 
                data: groupParam,
                method: 'PATCH',
                xhrFields: { withCredentials: true }})
        .done((data) => {
            callback(data);
        })
        .fail((xhr) => {
            callback();
        }); 
    }


    /* 成功的話回傳一個 Queue Groups 陣列 */
    getListedQueueGroup(callback) {
        $.ajax({url: window.domain + '/dashboard/queue_settings/queue_groups', 
                method: 'GET',
                xhrFields: { withCredentials: true }})
        .done((data) => {
            callback(data["queue_groups"]);
        })
        .fail((xhr) => {
            callback();
        }); 
    }

    deleteQueueGroup(id, callback) {
        $.ajax({url: window.domain + '/dashboard/queue_settings/queue_group/' + id, 
                method: 'DELETE',
                xhrFields: { withCredentials: true }})
        .done((data) => {
            callback(data);
        })
        .fail((xhr) => {
            console.log("error");
            callback();
        }); 
    }


    /* 自訂題目 */
    createCustomQuestion(questionParam) {
        /*
            "title" : <String>,
            "question_type" : "quantity"/"tag"/"question" <String>,
            "content" : JSON format string,
            "required": <boolean> (Optional: default false),
            "multiple_seleced" : <boolean> (Optional: default false),
            "is_available": <boolean>(Optional: default false)
        */

        return new Promise(function(resolve, reject) {
            $.ajax({url: window.domain + '/dashboard/queue_settings/customized_question', 
                    data: questionParam,
                    method: 'POST',
                    xhrFields: { withCredentials: true }})
            .done((data) => {
                resolve(data)
            })
            .fail((xhr) => {
                let error = new Error('建立 自訂題目 失敗')
                reject(error)
            }); 
        });
    }

    deleteCustomQuestion(id) {
        return new Promise(function(resolve, reject) {
            $.ajax({url: window.domain + '/dashboard/queue_settings/customized_question/' + id, 
                    method: 'DELETE',
                    xhrFields: { withCredentials: true }})
            .done((data) => {
                resolve(data)
            })
            .fail((xhr) => {
                let error = new Error('刪除 自訂題目 失敗')
                reject(error)
            }); 
        });
    }

    updateCustomQuestion(id, questionParam) {
        return new Promise(function(resolve, reject) {
            $.ajax({url: window.domain + '/dashboard/queue_settings/customized_question/' + id, 
                    data: questionParam,
                    method: 'PATCH',
                    xhrFields: { withCredentials: true }})
            .done((data) => {
                resolve(data)
            })
            .fail((xhr) => {
                let error = new Error('編輯 自訂題目 失敗')
                reject(error)
            }); 
        });
    }

    updateQuestionOrder(id, fromIndex, toIndex) {
        return new Promise(function(resolve, reject) {
            $.ajax({url: window.domain + '/dashboard/queue_settings/customized_question_order', 
                    data: {"id": id, "fromIndex": fromIndex, "toIndex": toIndex},
                    method: 'PATCH',
                    xhrFields: { withCredentials: true }})
            .done((data) => {
                resolve(data)
            })
            .fail((xhr) => {
                let error = new Error('修改 題目順序 失敗')
                reject(error)
            }); 
        });
    }
}
