import React from "react";
import { Modal, Space } from "antd";
import { useTranslation } from "react-i18next";

const ConfirmBlockedCustomerModal = ({
  type,
  isBlocked,
  setShowConfirmBlockedListModal,
  submit,
}) => {
  const { t } = useTranslation("customer");

  let content = type === "create" ? t("popup.add") : t("popup.save");

  const handleSubmit = () => {
    setShowConfirmBlockedListModal(false);
    submit();
  };

  return (
    <Modal
      title={t("popup.confirmBlocked_title", { type: content })}
      visible
      destroyOnClose
      width={374}
      centered
      closable={false}
      className="modal-base modal-sm"
      footer={
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={() => setShowConfirmBlockedListModal(false)}
          >
            {t("settings:cancel")}
          </button>
          <button
            className="button-common button-primary"
            onClick={handleSubmit}
          >
            {t("settings:ok")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {isBlocked
        ? t("popup.confirmBlocked_edit_content")
        : t("popup.confirmBlocked_exist_content", { type: content })}
    </Modal>
  );
};

export default ConfirmBlockedCustomerModal;
