import $ from 'jquery';
import { config } from '../../utils/config';

export default class BookingSystemApi {
	//new module


	newModuleSetting(){
		return new Promise((resolve, reject)=>{
			$.ajax({
				url: window.domain + '/dashboard/settings_and_data/settings',
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
			.done(data=>{
				resolve(data)
			})
			.fail(xhr=>{
				reject(xhr)
			})
		})
	}

	newModuleBooking(date) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/settings_and_data/booking_info?date=' + date,
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					reject(xhr);
				});
		});
	}

	newModuleQueue(date){
		return new Promise((resolve, reject)=>{
			$.ajax({
				url: window.domain + '/dashboard/settings_and_data/queue_info?date=' + date,
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					reject(xhr);
				});
		})
	}

	/* Booking Setting */
	getBookingSetting() {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/booking_settings',
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('getBookingSetting 失敗'));
				});
		});
	}

	updateBookingSetting(settings) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/booking_settings',
				method: 'PATCH',
				dataType: 'json',
				processData: false,
				contentType: 'application/json; charset=utf-8',
				data: settings,
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('updateBookingSetting 失敗'));
				});
		});
	}

	updateBookingSettingWithBgImg(settings) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/booking_settings',
				method: 'PATCH',
				dataType: 'json',
				processData: false,
				contentType: false,
				data: settings,
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('updateBookingSettingWithBgImg 失敗'));
				});
		});
	}

	/* 預約資料 */
	getBookingsByDate(queryDate) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/bookings.json',
				data: { date: queryDate },
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('getBookings 失敗'));
				});
		});
	}

	/* 取得當月各種狀態 */
	getMonthStatus(month, status) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: config.domain + '/dashboard/bookings/month_count.json',
				data: { month: month, status },
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					// reject(new Error('getMonthStatus 失敗'));
					reject(xhr);
					console.log('getMonthStatus 失敗');
				});
		});
	}

	getTimeLine(date) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/bookings/test22',
				method: 'POST',
				dataType: 'json',
				contentType: 'application/json; charset=utf-8',
				xhrFields: { withCredentials: true },
				data: JSON.stringify({ date: date })
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('getTimeLine 失敗'));
				});
		});
	}

	/* 設定預約狀態 */
	setBookingStatus(id, newStatus, date, status_time, event_id=null) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/bookings/' + id,
				dataType: 'json',
				data: { status: newStatus, date, status_time, event_id },
				type: 'PATCH',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('setBookingStatus 失敗'));
				});
		});
	}

	/* 取得假日日期 */
	holidays() {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: config.domain + '/dashboard/booking_settings/holiday_data',
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					// reject(new Error('holidays 失敗'));
					reject(xhr);
					console.log('holidays 失敗')
				});
		});
	}

	/* 自建預約 */
	getBookingTable(tableData) {
		return new Promise((resolve, reject) => {
			$.ajax({
				method: 'POST',
				url: window.domain + '/dashboard/bookings/combination_test',
				data: tableData,
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('getBookingTable 失敗'));
				});
		});
	}

	createBooking(dataSubmit) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/bookings',
				method: 'POST',
				data: JSON.stringify(dataSubmit),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					// let error = new Error('createBooking 失敗');
					reject(xhr);
				});
		});
	}

	updateBooking(id, dataSubmit) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/bookings/' + id,
				method: 'PATCH',
				data: JSON.stringify(dataSubmit),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					// let error = new Error('updateBooking 失敗');
					reject(xhr);
				});
		});
	}

	/* 訂金簡訊通知 */
	sendSmsNotification(data) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/bookings/send_sms_notification',
				method: 'POST',
				data: JSON.stringify(data),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					// let error = new Error('createBooking 失敗');
					reject(xhr);
				});
		});
	}

	/* 訂金信件通知 */
	sendEmailNotification(data) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/bookings/send_email_notification',
				method: 'POST',
				data: JSON.stringify(data),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					// let error = new Error('createBooking 失敗');
					reject(xhr);
				});
		});
	}

	/* 入座檢查 */
	checkSeated(booking_id) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: `${window.domain}/dashboard/bookings/${booking_id}/check_overlay_previous_booking`,
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					// let error = new Error('updateBooking 失敗');
					reject(xhr);
				});
		});
	}

	/* CRM */
	createCustomer(data) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: config.domain + '/dashboard/customers',
				dataType: 'json',
				type: 'POST',
				data: data,
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					// let error = new Error('CRM createCustomer 失敗')
					reject(xhr);
				});
		});
	}

	/* 自訂題目 */
	getQuestions() {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: config.domain + '/dashboard/booking_settings/customized_questions',
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					// reject(new Error('getQuestions 失敗'));
					reject(xhr);
					console.log('getQuestions 失敗')
				});
		});
	}

	createCustomQuestion(questionParam) {
		/*
            "title" : <String>,
            "question_type" : "quantity"/"tag"/"question" <String>,
            "content" : JSON format string,
            "required": <boolean> (Optional: default false),
            "multiple_seleced" : <boolean> (Optional: default false),
            "is_available": <boolean>(Optional: default false),
            "question_order": Int(Optional:若未帶此param, 順序預設會是最後)

        */
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: config.domain + '/dashboard/booking_settings/customized_questions',
				data: JSON.stringify({ customized_questions: questionParam }),
				dataType: 'json',
				contentType: 'application/json',
				method: 'POST',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('建立 自訂題目 失敗'));
				});
		});
	}

	deleteCustomQuestion(questionParam) {
		/*
            [49,50,51]
        */
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: config.domain + '/dashboard/booking_settings/customized_questions',
				data: JSON.stringify({ customized_questions: questionParam }),
				method: 'DELETE',
				dataType: 'json',
				contentType: 'application/json',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('刪除 自訂題目 失敗'));
				});
		});
	}

	updateCustomQuestion(questionParam) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: config.domain + '/dashboard/booking_settings/customized_questions',
				data: JSON.stringify({ customized_questions: questionParam }),
				method: 'PATCH',
				dataType: 'json',
				contentType: 'application/json',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('更新 自訂題目 失敗'));
				});
		});
	}

	/* 顧客關係管理 */
	crm_getCustomerData(pathname) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: window.domain + pathname,
				dataType: 'json',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('crm_getCustomerData 失敗'));
				});
		});
	}

	/* 座位設定 */
	getBookingSettingSeats() {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: config.domain + '/dashboard/table_settings',
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('getBookingSettingSeats 失敗'));
				});
		});
	}

	saveBookingSettingSeats(tables) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: config.domain + '/dashboard/table_settings',
				data: { table_settings: JSON.stringify(tables) },
				method: 'POST',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					// let error = new Error('saveBookingSettingSeats 失敗');
					reject(xhr);
				});
		});
	}

	/* 新增刪除修改組別設定 */
	spaceGroupSetting(group, deleted) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/booking_settings/space_groups',
				data: JSON.stringify({ space_groups: group, deleted_space_groups: deleted }),
				method: 'POST',
				dataType: 'json',
				contentType: 'application/json',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((err) => {
					// let error = new Error('spaceGroupSetting 失敗');
					reject(err);
				});
		});
	}

	/* 對外開放設定-菜單 */
	menuSetting(menuImgFd) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: window.domain + '/dashboard/booking_settings/booking_menu_imgs',
				data: menuImgFd,
				method: 'PATCH',
				processData: false,
				contentType: false,
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail(() => {
					reject(new Error('menuSetting 失敗'));
				});
		});
	}

	// 桌位圖
	getTableMap(date) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/canvas_spaces?date=' + date,
				method: 'GET',
				contentType: 'application/json',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((err) => {
					reject(new Error('getTableMap 失敗', err));
				});
		});
	}

	updateTableMap(canvas, canvas_id) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: window.domain + '/dashboard/canvas_spaces',
				method: 'PATCH',
				data: JSON.stringify({ canvas, canvas_id }),
				dataType: 'json',
				contentType: 'application/json',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((err) => {
					reject(new Error('updateTableMap 失敗', err));
				});
		});
	}
}
