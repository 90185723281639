import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import BookingSystemApi from '../BookingSystemApi';
import OrderRow from './BookingSeatsOrderRow';
import SettingsSaveButton from '../../commons/SettingsSaveButton';

const API = new BookingSystemApi();

const GROUPTYPE = 'groupDroppableItem';
export const SEATTYPE = 'droppableSeatItem';

const BookingSeatsOrder = (props) => {
	const { t } = useTranslation('settings');
	const [ isSaved, setIsSaved ] = useState(true);
	const [ sending, setSending ] = useState(false);
	const [ groupList, setGroupList ] = useState([]);
	const [ openIndex, setOpenIndex ] = useState([]);
	const [ dragGroup, setDragGroup ] = useState('');

	const routerWillLeave = useCallback(
		() => {
			if (!isSaved) return t('leaveNotice');
		},
		[ isSaved, t ]
	);

	useEffect(
		() => {
			props.router.setRouteLeaveHook(props.route, routerWillLeave);
		},
		[ props, routerWillLeave ]
	);

	useEffect(() => {
		API.getBookingSetting().then((data) => {
			const { tables_order } = data;
			setGroupList(JSON.parse(tables_order));
		});
	}, []);

	const onDragStart = (dragItem) => {
		const { droppableId } = dragItem.source;
		setDragGroup(droppableId);
	};

	const onDragEnd = (result) => {
		setIsSaved(false);
		setDragGroup('');

		if (!result.destination) {
			return;
		}
		if (result.type === GROUPTYPE) changeGroupOrder({ result });
		if (result.type === SEATTYPE) changeSeatOrder({ result });
	};

	const changeGroupOrder = ({ result }) => {
		const startIndex = result.source.index;
		const endIndex = result.destination.index;

		const group = reorderGroup({ groupList, startIndex, endIndex });
		setGroupList(group);
	};

	const changeSeatOrder = ({ result }) => {
		const startIndex = result.source.index;
		const endIndex = result.destination.index;
		const groupName = result.source.droppableId;

		const seat = reorderSeat({ groupList, groupName, startIndex, endIndex });
		setGroupList(seat);
	};

	const saveOpenIndex = useCallback((draggableId) => {
		setOpenIndex(draggableId);
	}, []);

	const submit = useCallback(
		() => {
			const bookingSetting = {
				booking_settings: {
					tables_order: JSON.stringify(groupList)
				}
			};
			setSending(true);

			API.updateBookingSetting(JSON.stringify(bookingSetting))
				.then((data) => {
					// console.log('success---', data);
					setSending(false);
					setIsSaved(true);
					window.app.alert.setMessage(t('status.saved'), 'done');
				})
				.catch((err) => {
					console.log('booking seates order err---', err);
					window.app.alert.setMessage(t('status.pls_tryAgainLater'), 'error');
					setSending(false);
					setIsSaved(true);
				});
		},
		[ groupList, t ]
	);

	return (
		<>
			<div id="seatsOrder" className="setting-container">
				{sending && (
					<div className="lightBoxLayer">
						<h4>{t('status.saving')}</h4>
					</div>
				)}
				<div style={{ display: 'flex' }}>
					<div style={{ flexGrow: 1 }}>
						<h3>{t('tableOrder.subtitle')}</h3>
						<h5>{t('tableOrder.description')}</h5>
					</div>
				</div>
				<hr />

				<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
					<Droppable droppableId="groupDroppable" type={GROUPTYPE}>
						{(provided) => (
							<div ref={provided.innerRef} className="orderWrap">
								{groupList.map((group, index) => (
									<Draggable
										key={group.group}
										draggableId={group.group}
										index={index}
										isDragDisabled={DragDisable({ openIndex, title: group.group })}
										// isDropDisabled={DropDisable({dragGroup, title: group.group})}
									>
										{(provided, snapshot) => (
											<div
												className="orderRowArea"
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
											>
												<section className="orderArea">
													<OrderRow
														title={group.group}
														droppableId={group.group}
														openIndex={openIndex}
														saveOpenIndex={saveOpenIndex}
														hideHandle={DragDisable({ openIndex, title: group.group })}
														seats={group.tables}
													>
														<OrderRow.OrderDragHandle />
														<OrderRow.OrderSeatGroup />
														<OrderRow.OrderExpandButton />
														<OrderRow.OrderSeats
															key="OrderSeats"
															isDragDisabled={group.group !== dragGroup}
														/>
													</OrderRow>
												</section>
												{provided.placeholder}
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
			<SettingsSaveButton handleSaved={submit} showDivider />
		</>
	);
};

const getItemStyle = (isDragging, draggableStyle) => ({
	boxShadow: isDragging ? '1px 1px 5px 0px rgba(51, 51, 51, 0.3)' : 'none',
	...draggableStyle
});

const DragDisable = ({ openIndex, title }) => {
	if (openIndex.length === 0) return false;
	const titleIndex = openIndex.indexOf(title);

	//true 打開不能移動
	//false 可以移動

	return titleIndex !== -1;
};

const reorderSeat = ({ groupList, groupName, startIndex, endIndex }) => {
	let result = JSON.parse(JSON.stringify(groupList));
	result = result.map((list) => {
		if (list.group === groupName) {
			const [ removed ] = list.tables.splice(startIndex, 1);
			list.tables.splice(endIndex, 0, removed);
		}
		return list;
	});

	return result;
};

const reorderGroup = ({ groupList, startIndex, endIndex }) => {
	let result = JSON.parse(JSON.stringify(groupList));
	const [ removed ] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

export default BookingSeatsOrder;
