import React, { Fragment, useContext } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import MenuCouponCard from "../EnableMenuCouponCard";

import { MessageAddStateContext } from "../../../crmContext/MessageAddContext";

const CrmMenuTalkItem = ({ children }) => {
  return <section>{React.Children.map(children, (child) => child)}</section>;
};

const MenuTalkHeader = ({ index, item, deleteMENUContent }) => {
  const { t } = useTranslation("messages");
  const { MessageAddState } = useContext(MessageAddStateContext);
  const { errorCoupon } = MessageAddState;

  const renderTitle = () => {
    if (item.type === "text") {
      return t("sendMenuTalk.campaignSettings.text");
    } else if (item.type === "new_image" || item.type === "image") {
      return t("sendMenuTalk.campaignSettings.images");
    } else if (item.type === "voucher") {
      if (errorCoupon.length > 0) {
        // 優惠券有效期限是否在排程時間內
        const findIndex = errorCoupon
          .map((coupon) => coupon.voucherId)
          .indexOf(item.voucherId);

        if (findIndex !== -1) {
          return (
            <Fragment>
              {t("sendMenuTalk.campaignSettings.coupons")}
              <span className="error_text" style={{ marginLeft: "8px" }}>
                {t("sendMenuTalk.campaignSettings.notice_coupons")}
              </span>
            </Fragment>
          );
        } else {
          return t("sendMenuTalk.campaignSettings.coupons");
        }
      } else {
        return t("sendMenuTalk.campaignSettings.coupons");
      }
    }
  };

  return (
    <div className="edit_block_header edit_content_header">
      <div>{renderTitle()}</div>
      <CloseOutlined onClick={() => deleteMENUContent(index, item)} />
    </div>
  );
};

const MenuTalkContent = ({ index, item, couponLists, handleMENUContent }) => {
  const { t } = useTranslation("messages");

  const renderContent = () => {
    if (item.type === "text") {
      return (
        <div className="edit_block_content" style={{ padding: "16px" }}>
          <textarea
            id={`text_${index}`}
            value={item.text}
            onChange={(e) => handleMENUContent(index, e)}
            placeholder={t("sendMenuTalk.campaignSettings.placeholder_text")}
          />
        </div>
      );
    } else if (item.type === "new_image" || item.type === "image") {
      return (
        <div className="edit_block_content">
          <img src={item.url} alt="" />
        </div>
      );
    } else if (item.type === "voucher") {
      let voucherInfo = "";
      const couponIndex = couponLists
        .map((coupon) => coupon.id)
        .indexOf(item.voucherId);
      voucherInfo = couponLists[couponIndex];

      return (
        <div className="edit_block_content edit_block_content_coupon">
          <MenuCouponCard
            id={`view_${item.voucherId}`}
            key={index}
            coupon={voucherInfo}
            onlyView={true}
          >
            <MenuCouponCard.Img thumbnailURL={voucherInfo.thumbnailURL} />
            <MenuCouponCard.Content coupon={voucherInfo} />
          </MenuCouponCard>
        </div>
      );
    }
  };

  return <Fragment>{renderContent()}</Fragment>;
};

CrmMenuTalkItem.Header = MenuTalkHeader;
CrmMenuTalkItem.Content = MenuTalkContent;

export default CrmMenuTalkItem;
