import React from "react";
import { Modal, Space } from "antd";
import { useTranslation, Trans } from "react-i18next";

const NotEnoughWarning = ({
  attendanceDiff = "",
  bookingAttend = 0,
  tempSeating = [],
  cancelWarning,
  checkWarning,
}) => {
  const { t } = useTranslation("bookingSystem");
  const maxCount = tempSeating.reduce(
    (res, table) => (res += table.max_seat),
    0
  );
  const attendanceCount = attendanceDiff || Math.abs(maxCount - bookingAttend);

  return (
    <Modal
      title={
        <Trans
          i18nKey="bookingSystem:popup.partySizeNotEnough_title"
          values={{ attendance: attendanceCount }}
        >
          尚不足 <span className="color-alert">{attendanceCount}</span> 人座位
        </Trans>
      }
      visible
      destroyOnClose
      width={374}
      centered
      closable={false}
      className="modal-base modal-sm"
      footer={
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={cancelWarning}
          >
            {t("popup.rearrangeTable")}
          </button>
          <button
            className="button-common button-primary"
            onClick={checkWarning}
          >
            {t("settings:confirm")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {t("popup.partySizeNotEnough_content")}
    </Modal>
  );
};

export default NotEnoughWarning;
