import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CouponAddStateContext } from '../MenuCouponContext/MenuCouponAddContext';

const CouponAttentionSection = ({ children }) => {
	return <section className="couponAttention">{React.Children.map(children, (child) => child)}</section>;
};

const CouponSectionTitle = () => {
	const { t } = useTranslation('couponSystem');
	return <h2>{t('addCoupon.notificationOfCoupon')}</h2>;
};

const CouponAttentionTxt = () => {
	const { t } = useTranslation('couponSystem');
	const { CouponAddState, CouponAddDispatch } = useContext(CouponAddStateContext);
	const { couponSetting } = CouponAddState;
	const { description } = couponSetting;

	const minRowsRef = useRef(3);
	const [ rows, setRows ] = useState(3);

	const handleAttention = (e) => {
		const txt = e.target.value;
		const textareaLineHeight = 24;
		const previousRows = e.target.rows;
		e.target.rows = minRowsRef.current;

		const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);
		if (currentRows === previousRows) {
			e.target.rows = currentRows;
		}

		CouponAddDispatch({ type: 'setAttentionText', description: txt });
		setRows(currentRows);
	};

	return (
		<div className='attentionWrap'>
			<textarea
				id="attention"
				className="attentionEditor"
				placeholder={t('addCoupon.placeholder_notificationOfCoupon')}
				rows={rows}
				value={description}
				onChange={(e) => handleAttention(e)}
			/>
		</div>
	);
};

CouponAttentionSection.Title = CouponSectionTitle;
CouponAttentionSection.Content = CouponAttentionTxt;

export default CouponAttentionSection;
