import React, { useContext } from "react";
import { CouponStateContext } from "../MenuCouponContext/MenuCouponContext";
import MenuCouponListCard from "../ListCard";
import { useTranslation, Trans } from 'react-i18next';

const SharePopup = () => {
	const { t } = useTranslation('couponSystem');
  const { CouponState } = useContext(CouponStateContext);
  const { listInfo } = CouponState;
	const { title } = listInfo;

  const copy = () => {
    const el = document.getElementById("copyInput");
    const range = document.createRange();
    window.app.alert.setMessage(t('popup.copySuccess'), "info");
    range.selectNodeContents(el);
    window.getSelection().addRange(range);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    el.select();
    el.setSelectionRange(0, 999999);
    document.execCommand("copy");

    window.getSelection().removeAllRanges();
  };

  return (
    <div className="sharePopupBody">
      <p>
        <Trans
          i18nKey="couponSystem:popup.shareCouponConfirmation"
          title={title}
        >
          您已成功發布<span>「{{ title }}」</span>
          優惠券。分享優惠券讓更多人知道優惠訊息吧！
        </Trans>
      </p>
      <MenuCouponListCard>
        <MenuCouponListCard.Img thumbnailURL={listInfo.thumbnailURL} />
        <MenuCouponListCard.Content showType="sharePopup" list={listInfo} />
      </MenuCouponListCard>

      <div className="copyUrl">
        <div>
          <input
            id="copyInput"
            readOnly
            type="text"
            value={`https://menutaiwan.com/tw/coupon/${listInfo.id}`}
          />
        </div>
        <button onClick={() => copy()}>{t("popup.copyLink")}</button>
      </div>
    </div>
  );
};

export default SharePopup;
