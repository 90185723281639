import React, { useState, useEffect, useReducer } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { userBindState, UserBindContext, UserBindReducer } from './MenuUserBindContext/MenuUser_bindContext';

import Bar from './MenuUserBindCommon/MenuUserBind_bar';
import Footer from './MenuUserBindCommon/MenuUserBind_footer';
import UserVerification from './MenuUserBindCommon/MenuUserVerificationModal';

import { getMenuUserInfo, searchMenuUser } from '../menu_user/MenuUserApi'

const MenuAccountBox = () => {
	const { t } = useTranslation('settings');
	const [ menuUser, setMenuUser ] = useState('');
	const [ bindError, setBindError ] = useState(false);
	const [ bindDisabled, setBindDisabled ] = useState(false);

	const [ UserBindState, UserBindStateDispatch ] = useReducer(UserBindReducer, userBindState);
	const { step } = UserBindState;

	const itunes_link = 'https://itunes.apple.com/tw/app/menu%E7%BE%8E%E9%A3%9F%E8%AA%8C-%E7%BE%8E%E9%A3%9F%E8%A8%98%E9%8C%84-%E4%BD%A0%E6%88%91%E5%88%86%E4%BA%AB/id976520817?mt=8';
	const googleStore_link = 'https://play.google.com/store/apps/details?id=com.findlife.menu&hl=zh_TW';

	useEffect(() => {
			window.scrollTo(0, 0);
		},[]);

	useEffect(() => {
		getBindStatus();
	}, [])

	useEffect(() => {
		const search_input_block = document.getElementsByClassName('search_input')[0];

		if(bindError) {
			search_input_block.classList.add('search_input_error');
		} else {
			search_input_block.classList.remove('search_input_error');
		}
	},[ bindError ]);

	// 點擊 search input 會有 focus style，點空白處取消
	useEffect(() => {
		const inputFocus = (e) => {
			const search_input = document.getElementById('search_input');
			const search_input_block = document.getElementsByClassName('search_input')[0];
		
			if (e.target === search_input) {
				search_input_block.classList.add('search_input_focus');
			} else if (search_input_block) {
				search_input_block.classList.remove('search_input_focus');
			}
		}

		if(!bindDisabled) {
			document.addEventListener('click', inputFocus);
		}
		return () => {
			document.removeEventListener('click', inputFocus);
		}
	})

	const getBindStatus = async () => {
		try {
			const { data } = await getMenuUserInfo();

			if(data.menu_user.bind_menu) {
				setBindDisabled(true);
			} else {
				setBindDisabled(false);
			}
		} catch(err) {
			setBindDisabled(false);
			console.log(err)
		}
	};

	const goDownload_iOS = () => {
		window.open(itunes_link, '_blank');
	};

	const goDownload_Android = () => {
		window.open(googleStore_link, '_blank');
	};

	const goHaveMenuAccount = () => {
    const downloadMenuApp = document.getElementById('have_account');
    downloadMenuApp.scrollIntoView({ behavior: "smooth" });
  };

	const handleMenuAccount = (e) => {
		const menuUser = e.target.value;
		setMenuUser(menuUser);
	};

	const submit = async () => {
		try {
			const { data } = await searchMenuUser(menuUser);

			setBindError('');
			UserBindStateDispatch({ 
				type: 'setInitUserInfo',
				menuUserId: menuUser,
				displayName: data.menu_user.name,
				thumbnailURL: data.menu_user.thumbnailURL
			});
			UserBindStateDispatch({ type: 'goUserVerification', step: 'userVerification' });
		} catch(err) {
			setBindError(true);
		}
	};

	return (
    <UserBindContext.Provider value={{ UserBindState, UserBindStateDispatch }}>
      <div id="menu_account" className="system-layout">
        <Bar />
        <div id="instant_bind">
          <img
            className="logo"
            src={require("../../../images/menuUser/Web_MENUXMENULogo.svg")}
            alt=""
          />
          <div className="title">
            <div>{t("bindMENUAccount.connectWithMENU")}</div>
            <div>{t("bindMENUAccount.interact")}</div>
          </div>
          <div className="search_account">
            <div>{t("bindMENUAccount.enterYourMENU_ID")}</div>
            <div className="search_input">
              <i className="fa fa-search searchIcon" aria-hidden="true"></i>
              <input
                id="search_input"
                type="text"
                placeholder={`${t("bindMENUAccount.placeholder_MENU")}`}
                value={menuUser}
                onChange={handleMenuAccount}
                autoComplete="off"
                disabled={bindDisabled}
              />
              <button onClick={submit} disabled={bindDisabled}>
                {t("bindMENUAccount.confirm")}
              </button>
            </div>

            {bindError && (
              <div className="search_input_error_text">
                {t("bindMENUAccount.pls_invalidAccount")}
              </div>
            )}
          </div>
          <div className="find_account" onClick={goHaveMenuAccount}>
            {t("bindMENUAccount.findMENU_ID")}
          </div>
        </div>

        <div id="howTo_bind">
          <div className="howTo_bind_title">
            {t("bindMENUAccount.howToConnectWithMENU")}
          </div>
          <div className="without_account">
            <div
              id="without_account_download"
              className="without_account_download"
            >
              <div>{t("bindMENUAccount.noAccount")}</div>
              <div>
                <Trans i18nKey="settings:bindMENUAccount.scanQRCodeToDownload">
                  掃描 QRCode 下載 <strong>MENU 美食誌</strong> 並完成註冊。
                </Trans>
              </div>
              <div>
                <Trans i18nKey="settings:bindMENUAccount.downloadMENU">
                  下載 <strong>MENU 美食誌</strong> 並完成註冊。
                </Trans>
              </div>
              <div>
                <button onClick={goDownload_iOS}>
                  {t("bindMENUAccount.iOS")}
                </button>
                <button onClick={goDownload_Android}>
                  {t("bindMENUAccount.android")}
                </button>
              </div>
            </div>
            <div className="menuApp_QRCode">
              <img
                src={require("../../../images/menuUser/Web_Connect_DownloadAppQRCode_WithFrame.svg")}
                alt=""
              />
            </div>
          </div>
          <div id="have_account" className="have_account">
            <div className="have_account_step">
              <div>{t("bindMENUAccount.haveAccount")}</div>
              <div>{t("bindMENUAccount.findMENU_IDInMENUApp")}</div>
              <ul>
                <li>
                  <Trans i18nKey={"settings:bindMENUAccount.findStep_1"}>
                    登入您的 <strong>MENU 美食誌帳號</strong>。
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey={"settings:bindMENUAccount.findStep_2"}>
                    點擊首頁右下角個人頁面按鈕
                    <img
                      src={require("../../../images/menuUser/Icons_Connect_Info_ProfilePage_Pics.svg")}
                      alt=""
                    />
                    。
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey={"settings:bindMENUAccount.findStep_3"}>
                    進入右上角的設定按鈕
                    <img
                      src={require("../../../images/menuUser/Icons_Connect_Info_Setting_Pics.svg")}
                      alt=""
                    />
                    ，點擊「個人資訊」，您可以在 MENU ID 欄位找到由 10
                    碼英數文字組成的 MENU ID 。
                  </Trans>
                </li>
                <li>{t("bindMENUAccount.findStep_4")}</li>
              </ul>
            </div>
            <div className="have_account_img">
              <img
                src={require("../../../images/menuUser/Web_Connect_ConnectAccountInfoPic.svg")}
                alt=""
              />
              <img
                src={require("../../../images/menuUser/Mobile_Connect_ConnectAccountInfoPic.svg")}
                alt=""
              />
            </div>
          </div>
        </div>

        <div id="download_menuApp">
          <div>
            <img
              src={require("../../../images/menuCoupon/menuAppIcon.png")}
              alt=""
            />
            <img
              src={require("../../../images/menuUser/logo_noIcon_black.svg")}
              alt=""
            />
          </div>
          <div>
            <button onClick={goDownload_iOS}>{t("bindMENUAccount.iOS")}</button>
            <button onClick={goDownload_Android}>
              {t("bindMENUAccount.android")}
            </button>
          </div>
        </div>
        <Footer />

        {step === "userVerification" && <UserVerification />}
      </div>
    </UserBindContext.Provider>
  );
};

export default MenuAccountBox;

