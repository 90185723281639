import React from 'react';
import { useTranslation } from 'react-i18next';

const SettingsSaveButton = ({
  handleSaved,
  showDivider=false
}) => {
  const { t } = useTranslation('settings');
  return (
    <div className='setting-save-button'>
      { showDivider && <hr className='setting-divider' /> }
      <div className='setting-fix-bottom'>
        <button
          className='btn_submit'
          onClick={handleSaved}
        >
          {t('save')}
        </button>
      </div>
    </div>
  )
}

export default SettingsSaveButton;