import React, { useState, useContext } from "react";
import moment from "moment";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import { Space } from "antd";
import { useTranslation, Trans } from "react-i18next";
import i18n from "i18next";
import { Link } from "react-router";

import { useInterval } from "../utils/data";
import { QueueActionSeatIn, QueueActionCall, QueueActionCancel, QueueActionMessage } from "../../queue_system/QueueListItemAction"
import QueuePanelAPI from "../../queue_system/api/QueuePanelAPI";

//reducer
import { BaseSettingContext } from "../NewModuleReducer/BaseSettingReducer";
import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";

const queueAPI = new QueuePanelAPI();

const QueueListCard = ({ children, queue }) => {
  return (
    <section id={queue.id} className="queueList__card">
      {React.Children.map(children, (child) => child)}
    </section>
  );
};

const WaitingTimeCount = ({ queue }) => {
  const { t } = useTranslation("queueSystem");
  const [currentStamp, setCurrentStamp] = useState(moment());
  const [interval] = useState(queue.waiting_time === null ? 1000 : null);

  useInterval(() => {
    setCurrentStamp(moment());
  }, interval);

  return (
    <p>
      {t("item.waited_2")}
      <span className="queueStatus-alert">
        {queue.waiting_time === null
          ? currentStamp.diff(moment(queue.created_at), "minutes")
          : queue.waiting_time}
      </span>
      {t("item.minutes")}
    </p>
  );
};

const getEstimatedWaitingTime = (time_checking, groupCount) => {
  let timeCheckingConditions = [];
  try {
    timeCheckingConditions = JSON.parse(time_checking);
  } catch (e) {
    // console.log('Parse condition failed');
  }

  if (
    Array.isArray(timeCheckingConditions) &&
    timeCheckingConditions.length > 1
  ) {
    timeCheckingConditions.sort((first, second) => {
      return first.count - second.count;
    });
  }

  let estimatedWaitingTime = 0;

  for (let i = 0; i < timeCheckingConditions.length; i++) {
    const element = timeCheckingConditions[i];
    if (groupCount <= element.count) {
      if (i === 0) {
        if (groupCount === element.count) {
          estimatedWaitingTime = element.time;
        } else {
          estimatedWaitingTime = i18n.t("queueSystem:item.less") + element.time;
        }
      } else {
        const prevElement = timeCheckingConditions[i - 1];
        estimatedWaitingTime = prevElement.time;
      }
      break;
    } else {
      const prevElement = timeCheckingConditions[i];
      estimatedWaitingTime = prevElement.time;
    }
  }

  return estimatedWaitingTime;
};

const QueueListCardHeader = ({ queue }) => {
  const { t } = useTranslation("queueSystem");
  const { BaseSettingState } = useContext(BaseSettingContext);
  const { timeChecking } = BaseSettingState;

  const { CommomSettingState } = useContext(CommomSettingContext);
  const { isToday } = CommomSettingState;

  const renderWaitingInfo = () => {
    const sourceTxt =
      queue.source === "offline"
        ? t("bookingSystem:source.offline")
        : t("bookingSystem:source.online");
    if (isToday) {
      return (
        <p>
          {sourceTxt} | {" "}
          <Trans
            i18nKey="queueSystem:item.ahead_2"
            values={{ groups: queue.waiting_groups }}
          >
            前面有{queue.waiting_groups}組
          </Trans>
        </p>
      );
    } else {
      return <p>{sourceTxt}</p>;
    }
  };

  const renderWaitingTime = () => {
    if (isToday) {
      return (
        <React.Fragment>
          <p>
            {t("item.est_2")}
            <span className="queueStatus-alert">
              {getEstimatedWaitingTime(timeChecking, queue.waiting_groups)}
            </span>
            {t("item.minutes")}
          </p>
          <WaitingTimeCount queue={queue} />
        </React.Fragment>
      );
    }
  };

  return (
    <div className="queueList__card__header">
      <div className="queueGroupName">
        <span>{queue.group_name}</span>
      </div>
      <div className="queueStatus">
        {renderWaitingInfo()}
        {renderWaitingTime()}
      </div>
    </div>
  );
};

const QueueListCardBody = ({ children }) => (
  <div className="queueList__card__body">
    {React.Children.map(children, (child) => child)}
  </div>
);

const QueueListCardBodyInfo = ({ queue }) => {
	const { t } = useTranslation('queueSystem');
  const renderGender = () => {
    if (queue.gender === "male") {
      return <span className="gender">{t("option.male")}</span>;
    }

    if (queue.gender === "female") {
      return <span className="gender">{t("option.female")}</span>;
    }
  };

  return (
    <div className="queueInfo">
      <div className="queueName">
        <div className="horizontal-between">
          <div>
            {i18n.language !== "zh" && renderGender()}
            {queue.customer_id ? (
              <Link to={`/dashboard/customers/${queue.customer_id}`}>
                <span>{queue.name}</span>
              </Link>
            ) : (
              <span>{queue.name}</span>
            )}
            {i18n.language === "zh" && renderGender()}
          </div>
          <span className="attendance">
            {queue.people_count}
            <span class="icon-md account-icon" />
          </span>
        </div>
        <p>{queue.phone}</p>
        {queue.email.trim() === "" ? null : <p>{queue.email}</p>}
      </div>
      <div className="queueNumber">{queue.waiting_number}</div>
    </div>
  );
};

const QueueShopMemo = ({ queue }) => {
	const { t } = useTranslation('queueSystem');
  const { setUuid } = useContext(CommomSettingContext);

  const [editMode, setEditMode] = useState(false);
  const [shopMemo, setShopMemo] = useState(
    queue.shop_memo ? queue.shop_memo : ""
  );
  const [tempShopmemoTxt] = useState(shopMemo);

  const replaceShopMemo = (memo) => {
    queue["shop_memo"] = memo;
  };

  const renderQueueShopMemo = () => {
    if (!editMode) {
      return (
        <React.Fragment>
          <div className="text-left">
            <div className="eidtWrap" onClick={() => setEditMode(true)}>
              <div className="eidtWrap__img" />
              <div className="eidtWrap__txt">
                {shopMemo !== "" ? t("item.editRemarks") : t("item.remarks")}
              </div>
            </div>
          </div>
          {shopMemo !== "" && (
            <div className="queueShopMemo__txt">{shopMemo}</div>
          )}
        </React.Fragment>
      );
    }

    return (
      <div className="shopmemo__editMode">
        <textarea
          value={shopMemo}
          placeholder={t("item.placeholder_addRemarks")}
          onChange={(e) => handleShopMemo(e)}
        />
        <Space size={4} className="shopmemo__editMode__btn">
          <button className="button-secondary" onClick={() => discardMemo()}>
            {t("cancel")}
          </button>
          <button className="button-primary" onClick={() => checkMemo()}>
            {t("confirm")}
          </button>
        </Space>
      </div>
    );
  };

  const handleShopMemo = (e) => {
    const txt = e.target.value;
    setShopMemo(txt);
  };

  const discardMemo = () => {
    setEditMode(false);
    setShopMemo(tempShopmemoTxt);
  };

  const checkMemo = () => {
    //uuid
    const eventId = uuidv4();
    setUuid(eventId);

    queueAPI
      .updateQueueShopMemo(queue.id, shopMemo, eventId)
      .then(() => {
        setEditMode(false);
        setShopMemo(shopMemo);
        replaceShopMemo(shopMemo);
      })
      .catch(() => {
        window.app.alert.setMessage(
          t("settings:status.pls_tryAgainLater"),
          "error"
        );
      });
  };

  return <div className="queueShopMemo">{renderQueueShopMemo()}</div>;
};

const QueueListCardBodyButton = ({
  queue,
  startQueueTimeline,
  updateQueueRecord,
  groupInfo,
  waitingRecords,
  groupName,
  smsLineUpRemindEnabled,
  emailLineUpRemindEnabled,
}) => {
  const { t } = useTranslation("settings");
  const { CommomSettingState, CommomSettingDispatch, setUuid } =
    useContext(CommomSettingContext);
  const { mode, isToday } = CommomSettingState;

  const { BaseSettingState } = useContext(BaseSettingContext);
  const { showCanvas } = BaseSettingState;

  const [standbyLoading, setStandbyLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  const moveTableMap = () => {
    CommomSettingDispatch({ type: "chooseQueueSeated", queue });

    const $canvas = document.getElementsByClassName("tablemap")[0];
    const $newModule = document.getElementsByClassName("newModule")[0];
    const $newModuleMain =
      document.getElementsByClassName("newModule__main")[0];

    $newModuleMain.style.overflow = "visible";
    $newModule.style.zIndex = 1032;

    $("#canvasSeated").show();
    $("#canvasSeated").append($canvas);
  };

  const queueSeated = () => {
    if (showCanvas && mode === "tablemap") {
      moveTableMap();
    } else {
      CommomSettingDispatch({ type: "setMode", mode: "timeline" });
      startQueueTimeline(queue);
    }
  };

  const changeListItemToStandby = (id) => {
    setStandbyLoading(true);
    const createdTime = moment().diff(moment(queue.created_at), "minutes");
    //uuid
    const eventId = uuidv4();
    setUuid(eventId);

    setTimeout(() => {
      queueAPI.updateQueueRecord(
        id,
        "standby",
        createdTime,
        (data) => {
          setStandbyLoading(false);

          if (data !== undefined) updateQueueRecord();
        },
        eventId
      );
    }, 500);
  };

	const sendNotification = (id) => {
    setEmailLoading(true);
    setTimeout(() => {
      //set uuid
      const eventId = uuidv4();
      setUuid(eventId);

      queueAPI
        .sendNotification(id, eventId)
        .then((data) => {
          if (data !== undefined) {
            updateQueueRecord();
          }
        })
        .catch((error) => {
          window.app.alert.setMessage(
            t("status.pls_tryAgainLater"),
            "error"
          );
          console.log("sendNotification error---", error);
        })
        .finally(() => {
          setEmailLoading(false);
        });
    }, 500);
  };

  const renderMemo = () => {
    if (queue.memo) {
      return <div className="queueMemo-customer">{queue.memo}</div>;
    }
  };

	const renderStandbyBtn = () => {
    if (queue.status === "waiting") {
      return (
        <QueueActionCancel
          queueNumber={queue.waiting_number}
          changeListItemToStandby={() => changeListItemToStandby(queue.id)}
          standbyLoading={standbyLoading}
          position={6}
					lastSeatedNumber={groupInfo?.last_seated_number}
					groupName={groupName}
					waitingRecords={waitingRecords}
        />
      );
    }
  };

	const renderEmailBtn = () => {
    if ((emailLineUpRemindEnabled && queue.email) || smsLineUpRemindEnabled) {
      return (
        <QueueActionMessage
          sendNotification={() => sendNotification(queue.id)}
          notificationLoading={emailLoading}
          position={6}
          hideAction={queue.status === "canceled"}
          isNotified={queue.is_notified}
        />
      );
    }
  };

  const renderSeatedBtn = () => {
    if (queue.status !== "canceled") {
      return (
        <QueueActionSeatIn
          queueNumber={queue.waiting_number}
          queueSeated={queueSeated}
          lastSeatedNumber={groupInfo?.last_seated_number}
          groupName={groupName}
					waitingRecords={waitingRecords}
        />
      );
    }
  };

	return (
    <div className="queueTools">
      <div className="queueMemo">{renderMemo()}</div>
      <div className="queueActionBtns">
        <Space size={16}>
          <QueueActionCall
            id={queue.id}
            number={queue.waiting_number}
						groupName={groupName}
						waitingRecords={waitingRecords}
            lastSeatedNumber={groupInfo?.last_seated_number}
						refreshAction={updateQueueRecord}
          />
          {renderEmailBtn()}
        </Space>
        <Space size={16}>
          {isToday && renderStandbyBtn()}
          {isToday && renderSeatedBtn()}
        </Space>
      </div>
    </div>
  );
};

QueueListCard.Header = QueueListCardHeader;
QueueListCard.Body = QueueListCardBody;

QueueListCard.Body.Info = QueueListCardBodyInfo;
QueueListCard.Body.Memo = QueueShopMemo;
QueueListCard.Body.Button = QueueListCardBodyButton;

export default QueueListCard;
