import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const SwapSeatPanel = ({ swapBookingInfo, closeSwapSeate }) => {
  const { t } = useTranslation("bookingSystem");

  useEffect(() => {
    document.getElementById(swapBookingInfo.status).click();

    setTimeout(() => {
      const $bookingCard = document.getElementById(swapBookingInfo.id);
      const $clone = $bookingCard.cloneNode(true);
      const $switchSeatPanel = document.getElementById("switchSeatPanel");

      $switchSeatPanel.append($clone);
      document.getElementById("confirmed").click();

      return () => {
        $switchSeatPanel.innerHTML = "";
      };
    }, 0);
  }, [swapBookingInfo]);

  useEffect(() => {
    const insertDom = `<div class="SwitchSeatPanelTitle">${t('tableMap.exchangeTables')}</div>`;
    const $tablemap = document.getElementsByClassName("tablemap")[0];
    $tablemap.insertAdjacentHTML("afterbegin", insertDom);

    return () => {
      const $SwitchSeatPanelTitle = document.getElementsByClassName(
        "SwitchSeatPanelTitle"
      )[0];
      $tablemap.removeChild($SwitchSeatPanelTitle);
    };
  }, [t]);

  return (
    <div id="switchSeatPanel" className="SwitchSeatPanel">
      <button className="closeSeatedPanel" onClick={() => closeSwapSeate()} />
    </div>
  );
};

export default SwapSeatPanel;
