import React, { useState, useEffect, useCallback } from "react";
import { useTranslation, Trans } from "react-i18next";
import axios from "axios";
import { Space, Switch } from "antd";

import { config } from "../../utils/config";
import {
  getBookingSetting,
  updateBookingSetting,
} from "../../api/booking/bookingSetting";
import SettingsSaveButton from "../commons/SettingsSaveButton";

const NotificationCredits = (props) => {
  const { t } = useTranslation("settings");
  const [init, setInit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(true);

  const [credits, setCredits] = useState(0);
  const [selectedPointsOption, setSelectedPointsOption] = useState(null);
  const [customPoints, setCustomPoints] = useState(0);
  const [showCustomPointsError, setShowCustomPointsError] = useState(false);
  const [apParams, setApParams] = useState(undefined);
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState(["aaa@com.tw", "bbb@com.tw"]);
  const [isLoading, setIsLoading] = useState(false);

  const routerWillLeave = useCallback(() => {
    if (!isSaved) return t("leaveNotice");
  }, [isSaved, t]);

  useEffect(() => {
    props.router.setRouteLeaveHook(props.route, routerWillLeave);
  }, [props, routerWillLeave]);

  useEffect(() => {
    const getBookingSettings = async () => {
      try {
        const { data } = await getBookingSetting();
        setCredits(data.shop.msg_point);
        setInit(true);
      } catch (err) {
        window.app.alert.setMessage(
          t("status.pls_tryAgainLater"),
          "error"
        );
      }
    };

    getBookingSettings();
  }, [t]);

  const buyMsgPoint = (e, isCustom = false) => {
    setIsLoading(true);
    let value = e.target.value;

    if (parseInt(value, 10) < 100 || isNaN(value)) {
      setApParams(undefined);
    }

    // 自訂
    if (isCustom) setCustomPoints(parseInt(value, 10));
    if (value === "custom" || isCustom) {
      setSelectedPointsOption("custom");
    } else {
      setSelectedPointsOption(parseInt(value, 10));
    }

    setShowCustomPointsError(false);

    document.getElementById("apForm").innerHTML = "";

    axios
      .post(
        `${window.domain}/dashboard/booking_settings/buy_msg_point`,
        {
          amount: value === "custom" ? customPoints : value,
          category: "booking",
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        const data = response.data;
        document.getElementById("apForm").innerHTML = "";

        for (let k in data) {
          if (data.hasOwnProperty(k))
            document.getElementById(
              "apForm"
            ).innerHTML += `<input name="${k}" type="hidden" value="${data[k]}">`;
        }
        setApParams(data);
      })
      .catch((error) => {
        window.app.alert.setMessage(
          t("status.pls_tryAgainLater"),
          "error"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitBuyMsgPoint = () => {
    if (isLoading) {
      window.app.alert.setMessage(
        t("status.pls_tryAgainLater"),
        "error"
      );
      return;
    }

    // 自訂點數小於 100
    if (
      selectedPointsOption === "custom" &&
      (customPoints < 100 || !customPoints)
    ) {
      setShowCustomPointsError(true);
      return;
    }

    if (apParams !== undefined && selectedPointsOption) {
      document.getElementById("apForm").submit();

      // Reset state after submission
      setSelectedPointsOption(null);
      setCustomPoints(null);
      setShowCustomPointsError(true);
      setApParams(undefined);
    } else {
      return window.app.alert.setMessage(
        t("notificationCommon.pls_AtLeast100Credits"),
        "tip"
      );
    }
  };

  const addEmail = () => {
    if (email && !emailList.includes(email)) {
      setEmailList([...emailList, email]);
      setEmail("");
      setIsSaved(false);
    }
  };

  const removeEmail = (email) => {
    setEmailList(emailList.filter((e) => e !== email));
  };

  const submit = async () => {
    try {
      // const notificationSettings = JSON.stringify({
      //   booking_settings: {
      //     emailList,
      //   },
      // });

      // await updateBookingSetting(notificationSettings);
      await updateBookingSetting();
      window.app.alert.setMessage(t("status.saved"), "done");
      setSaving(false);
    } catch (err) {
      window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
    }
  };

  const renderCustomPointsInput = () => {
    const handleKeyPress = (e) => {
      // 只能輸入數字
      const pattern = /^[0-9\b]+$/;
      const inputChar = String.fromCharCode(e.charCode);

      if (!pattern.test(inputChar)) {
        e.preventDefault();
      }
    };

    return (
      <div className={showCustomPointsError ? "custom_input-error" : ""}>
        <input
          type="number"
          value={customPoints || ""}
          placeholder="100"
          onChange={(e) => buyMsgPoint(e, true)}
          onKeyPress={handleKeyPress}
        />
        {showCustomPointsError && (
          <div>{t("notificationCommon.pls_AtLeast100Credits")}</div>
        )}
      </div>
    );
  };

  if (!init) return <div className="pageLoading">{t("status.loading")}</div>;
  return (
    <>
      <div className="setting-container notificationCredits">
        {saving && (
          <div className="lightBoxLayer">
            <h4>{t("status.saving")}</h4>
          </div>
        )}

        <div style={{ marginBottom: "24px" }}>
          <h3>{t("notificationCredits.title")}</h3>
          <h5>{t("notificationCredits.description")}</h5>
        </div>

        <hr />

        <Space
          direction="vertical"
          size={32}
          className="setting-content setting-block"
        >
          <Space size={16} className="heading-2-responsive">
            {t("notificationCredits.remainingCredits")}
            <span className={credits <= 0 ? "color-alert" : "color-primary"}>
              {credits}
            </span>
          </Space>
          <Space direction="vertical" size={20}>
            <Space direction="vertical" size={8}>
              <div className="heading-3">
                {t("notificationCredits.buyCredits")}
              </div>
              <div className="text-body color-subtitle">
                {t("notificationCredits.buyCredits_note")}
              </div>
            </Space>

            <div className="radioBox">
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={100}
                  checked={selectedPointsOption === 100}
                  onChange={(e) => buyMsgPoint(e)}
                />
                100
              </label>
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={200}
                  checked={selectedPointsOption === 200}
                  onChange={(e) => buyMsgPoint(e)}
                />
                200
              </label>
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={300}
                  checked={selectedPointsOption === 300}
                  onChange={(e) => buyMsgPoint(e)}
                />
                300
              </label>
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={400}
                  checked={selectedPointsOption === 400}
                  onChange={(e) => buyMsgPoint(e)}
                />
                400
              </label>
              <div className="custom_input">
                <label>
                  <input
                    type="radio"
                    name="selectPoints"
                    value={"custom"}
                    checked={selectedPointsOption === "custom"}
                    onChange={(e) => buyMsgPoint(e)}
                  />
                  {t("notificationCredits.custom")}
                </label>
                {selectedPointsOption === "custom"
                  ? renderCustomPointsInput()
                  : null}
              </div>
            </div>
            <button
              type="button"
              onClick={submitBuyMsgPoint}
              className="button-secondary btn-text-md"
            >
              {t("notificationCredits.topUp")}
            </button>
          </Space>
          <div>
            <div className="block_notice">
              <div className="title">{t("notificationCredits.notice")}</div>
              <ul>
                <li>{t("notificationCredits.notice_1")}</li>
                <li>{t("notificationCredits.notice_2")}</li>
              </ul>
            </div>
            {/* <hr style={{ margin: "32px 0" }} />
            <Space direction="vertical" size={32} className="setting-content">
              <Space direction="vertical" size={8}>
                <h2 className="heading-2-responsive">
                  {t("notificationCredits.notificationOfInsufficient")}
                </h2>
                <div className="text-body color-subtitle">
                  {t("notificationCredits.notificationOfInsufficient_note")}
                </div>
              </Space>
              <div className="settingTbl">
                <div className="settingTblRow">
                  <div className="header">
                    {t("notificationCredits.enableFeature")}
                  </div>
                  <div className="text">
                    <Switch
                      onChange={(checked) => {
                        // (checked) =>{setEnable(checked)}
                        setIsSaved(false);
                      }}
                      // checked={}
                    />
                  </div>
                </div>
              </div>
              <div className="settingTbl">
                <div className="settingTblRow">
                  <div className="header">
                    {t("notificationCredits.customReminderValues")}
                  </div>
                  <div className="text">
                    <Trans
                      i18nKey="settings:notificationCredits.reminderValues"
                      components={{
                        input: (
                          <input
                            style={{ width: "64px", textAlign: "center" }}
                            defaultValue={1}
                            onChange={(e) => {
                              setIsSaved(false);
                            }}
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <Space direction="vertical" size={20} className="setting-content">
                <div className="settingTbl flex-settingTbl email">
                  <div className="settingTblRow">
                    <div className="header">
                      {t("notificationCredits.emailSetting")}
                    </div>
                    <div className="text">
                      <input
                        type="email"
                        placeholder={t("notificationCredits.placeholder_email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button
                        className="button-secondary btn-text-md"
                        onClick={addEmail}
                      >
                        {t("notificationCredits.add")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="settingTbl flex-settingTbl">
                  <div className="settingTblRow">
                    <div className="header" />
                    <div className="text">
                      <Space direction="vertical" size={8}>
                        {emailList.map((email) => {
                          return (
                            <Space key={email} size={8}>
                              <div
                                className="text-body color-title"
                                style={{ padding: "4px 0" }}
                              >
                                {email}
                              </div>
                              <button
                                onClick={() => removeEmail(email)}
                                className="btn-effect"
                              >
                                <span className="icon-md delete-mask-icon" />
                              </button>
                            </Space>
                          );
                        })}
                      </Space>
                    </div>
                  </div>
                </div>
              </Space>
            </Space> */}
          </div>
        </Space>
      </div>
      {/* <SettingsSaveButton handleSaved={submit} showDivider /> */}

      <form
        action={config.allpay_checkout}
        id="apForm"
        method="post"
        target="_blank"
      ></form>
    </>
  );
};

export default NotificationCredits;
