import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Space } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  announcementSetting,
  showAnnouncementPreviewModal,
} from "../../actions/announcementAction";
import { dayList } from "../../utils/constants";
import useScreenSize from "../../hooks/useScreenSize";

function DashboardAnnouncementList({ count, toggleAnnouncement }) {
  const { t } = useTranslation("bulletinSystem");
  const { isMobile } = useScreenSize();

  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const { todayOfficialAnnouncements, dayAnnouncements } =
    announcementListReducer;
  const dispatch = useDispatch();

  const previewAnnouncement = (item) => {
    dispatch(announcementSetting(item));
    dispatch(showAnnouncementPreviewModal());
  };

  return (
    <Modal
      title={
        <div onClick={toggleAnnouncement}>
          {t("todayAnnouncement")}（{count}）
          <div className="icon-normal arrowDown-icon" />
        </div>
      }
      visible
      mask={isMobile ? true : false}
      width={316}
      closable={false}
      className={`dashboardAnnouncementList ${count === 0 ? "isEmpty" : ""}`}
      wrapClassName="dashboardAnnouncementList-wrap"
      footer={null}
      transitionName="ant-move-down"
    >
      {count === 0 ? (
        <EmptyAnnouncement />
      ) : (
        <PopulatedAnnouncement
          todayOfficialAnnouncements={todayOfficialAnnouncements}
          dayAnnouncements={dayAnnouncements}
          previewAnnouncement={previewAnnouncement}
        />
      )}
    </Modal>
  );
}

const PopulatedAnnouncement = ({
  todayOfficialAnnouncements,
  dayAnnouncements,
  previewAnnouncement,
}) => {
  const hasOfficialAnnouncements =
    todayOfficialAnnouncements && todayOfficialAnnouncements.length > 0;
  const hasShopAnnouncements = dayAnnouncements && dayAnnouncements.length > 0;
  const showDivider = hasOfficialAnnouncements && hasShopAnnouncements;

  const renderAnnouncementList = (announcements, style, previewHandler) => {
    if (!announcements || !announcements.length) return null;

    return announcements.map((item, idx) => {
      const startDate = new Date(item.start_time);
      const endDate = new Date(item.end_time);

      const formatDateTime = (date, day) =>
        `${moment(date).format("YYYY-MM-DD")}（${dayList[day]}）${moment(
          date
        ).format("HH:mm")}`;

      const startTime = formatDateTime(item.start_time, startDate.getDay());
      const endTime = formatDateTime(item.end_time, endDate.getDay());

      return (
        <Space
          size={6}
          direction="vertical"
          key={idx}
          className="announcementBlock-list_cell btn-effect"
          onClick={() => previewHandler(item)}
        >
          <h4 className={style}>{item.title}</h4>
          <Space
            size={2}
            direction="vertical"
            className="text-small color-intro"
          >
            <span>
              {startTime} - <br />
            </span>
            {endTime}
          </Space>
        </Space>
      );
    });
  };

  const officialList = renderAnnouncementList(
    todayOfficialAnnouncements,
    "cell_official",
    previewAnnouncement
  );

  const shopList = renderAnnouncementList(
    dayAnnouncements,
    "cell_shop",
    previewAnnouncement
  );

  return (
    <div className="announcementBlock-list">
      <Space size={12} direction="vertical">
        {officialList}
      </Space>
      {showDivider && <div className="divider" />}
      <Space size={12} direction="vertical">
        {shopList}
      </Space>
    </div>
  );
};

const EmptyAnnouncement = () => {
  const { t } = useTranslation("bulletinSystem");
  return (
    <div className="announcementBlock-list text-title color-subtitle">
      {t("list.empty_content_1")}
    </div>
  );
};

export default DashboardAnnouncementList;
