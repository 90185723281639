import React, { useState, useEffect, useMemo, useContext } from "react";
import moment from "moment";
import { Space } from "antd";
import { useTranslation } from "react-i18next";

import { DudooSyncDisplay } from "../../commons/DudooSyncDisplay";
import BookingCustomerTags from "../../bookings/items/BookingCustomerTags";

import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";

const NextBooking = ({ children }) => {
  return (
    <div className="canvasTableBookingInfo__nextBooking">
      {React.Children.map(children, (child) => child)}
    </div>
  );
};

const NextBookingHeader = () => {
  const { t } = useTranslation("bookingSystem");
  return (
    <div className="canvasTableBookingInfo__nextBooking__header">
      <h3>{t('tableMap.nextBooking')}</h3>
    </div>
  );
};

const NextBookingBody = ({
  nextBooking,
  switchMode,
  swapMode,
  swapMoreSeatMode,
}) => {
  const { t, i18n } = useTranslation("bookingSystem");
  const { editBooking, updateBookingAndSetting } =
    useContext(CommomSettingContext);
  const statusStyle = nextBooking.status + "Status";

  const renderNextBookingSource = () => {
    if (nextBooking.source === "online") {
      if (nextBooking.source_url_t === "google") {
        return <span className="bookingSourceIcon bookingSourceIcon-google" />;
      } else if (nextBooking.source_url_t === "facebook") {
        return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
      } else if (nextBooking.source_url_t === "instagram") {
        return (
          <span className="bookingSourceIcon bookingSourceIcon-instagram" />
        );
      } else if (nextBooking.source_url_t === "menuApp") {
        return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
      } else if (nextBooking.source_url_t === "other") {
        return <span className="bookingSourceIcon bookingSourceIcon-other" />;
      }
    }

    if (
      nextBooking.source === "google_booking" &&
      nextBooking.source_url_t === "google_reservation"
    ) {
      return (
        <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />
      );
    }
  };

  const renderOnsiteTag = () => {
    if (nextBooking.on_site) {
      return <span class="icon-md onsite-icon" />;
    }
  };

  const renderSeats = () => {
    if (swapMode || swapMoreSeatMode) {
      return (
        <ul className="swapSeatWrap">
          {nextBooking.seats.map((seat) => {
            return <li key={seat.table_number}>{seat.table_number}</li>;
          })}
        </ul>
      );
    }
  };

  return (
    <div className="canvasTableBookingInfo__nextBooking__body">
      <div className="canvasTableBookingInfo__nextBookingTop">
        <div className="nextBookingTop__time">
          <Space size={8}>
            <span>{renderNextBookingSource()}</span>
            {renderOnsiteTag()}
            <span>{nextBooking.time}</span>
          </Space>
        </div>
        <button
          className={`nextBookingTop__statusBtn ${statusStyle}`}
          onClick={() => switchMode(2, nextBooking)}
        >
          <i className="fa fa-circle" aria-hidden="true" />
          <span style={{ color: "#676767" }}>{t(`status.${nextBooking.status}`)}</span>
        </button>
      </div>
      <div className="info__top info__top_margin_bottom">
        <div className="info__top__name">
          <Space size={2} align="center">
            <BookingCustomerTags
              padding={2}
              isBlocked={nextBooking.customer_info.blacklisted}
              note={nextBooking.customer_info.note}
              tags={nextBooking.customer_info.tags}
            />
            <Space size={8} align="end">
              <Space size={2} align="end">
                {i18n.language !== "zh" && nextBooking.gender !== "other" && (
                  <span className="info__top__gender">
                    {t(`item.${nextBooking.gender}`)}
                  </span>
                )}
                <span className="name">{nextBooking.last_name}</span>
                {i18n.language === "zh" && nextBooking.gender !== "other" && (
                  <span className="info__top__gender">
                    {t(`item.${nextBooking.gender}`)}
                  </span>
                )}
              </Space>
              <button
                className="info__top__edit"
                onClick={() => editBooking(nextBooking)}
              >
                <div className="icon-normal edit-pen-icon" />
              </button>
            </Space>
          </Space>
          <p className="info__top__tel">{nextBooking.phone_number}</p>
        </div>
        <Space size={4}>
          <span className="info__top__attendance">
            {nextBooking.attendance}
          </span>
          <span className="icon-md account-icon" />
        </Space>
      </div>
      {renderSeats()}
      <DudooSyncDisplay
        mode="timeline"
        booking={nextBooking}
        updateData={updateBookingAndSetting}
      />
    </div>
  );
};

const NextBookingButton = ({
  currentBooking = [],
  nextBooking,
  swapMode,
  swapMoreSeatMode,
  startSwapSeat,
  startSwapMoreSeat,
  checkConflict = () => {},
}) => {
  const { t } = useTranslation("bookingSystem");
  const hasCurrentBooking = currentBooking.length !== 0;
  const [hideSwapButton, setHideSwapButton] = useState(hasCurrentBooking);

  useEffect(() => {
    setHideSwapButton(hasCurrentBooking);
  }, [currentBooking, hasCurrentBooking]);

  const conflict = useMemo(() => {
    //false無衝突 true有衝突
    let conflictTable = false;

    if (currentBooking.length !== 0) {
      const targetBookingStartTime = moment(
          currentBooking[0].service_start_time
        ),
        targetBookingEndTime = moment(currentBooking[0].service_start_time).add(
          currentBooking.dining_time,
          "m"
        );
      const seatedBookingStartTime = moment(nextBooking.service_start_time),
        seatedBookingEndTime = moment(nextBooking.service_start_time).add(
          nextBooking.dining_time,
          "m"
        );
      conflictTable =
        targetBookingStartTime < seatedBookingEndTime &&
        targetBookingEndTime > seatedBookingStartTime;
    }

    return conflictTable;
  }, [currentBooking, nextBooking]);

  const renderSwapBtn = () => {
    if (!swapMode && !swapMoreSeatMode && !conflict) {
      return (
        <Space size={4} className="canvasTableBookingInfo__nextBooking__footer">
          <button
            className="btn-swap switchBtn"
            onClick={() => startSwapSeat(nextBooking)}
          >
            {t("buttonStatus.change")}
          </button>
          <button
            className="btn-swap swapBtn"
            onClick={() => startSwapMoreSeat(nextBooking)}
          >
            {t("buttonStatus.select")}
          </button>
        </Space>
      );
    } else if (!swapMode && conflict) {
      return (
        <div className="canvasTableBookingInfo__nextBooking__footer">
          <p>{t("newModule.bookingConflict")}</p>
        </div>
      );
    } else if (swapMode && !swapMoreSeatMode && !hideSwapButton) {
      return (
        <div className="canvasTableBookingInfo__nextBooking__footer">
          <button
            className="switchBtn"
            onClick={() => checkConflict("swap", nextBooking)}
          >
            {t("buttonStatus.exchange")}
          </button>
        </div>
      );
    }
  };
  return <React.Fragment>{renderSwapBtn()}</React.Fragment>;
};

NextBooking.Header = NextBookingHeader;
NextBooking.Body = NextBookingBody;
NextBooking.Button = NextBookingButton;

export default NextBooking;
