import React, { useCallback, useEffect, useState, useRef } from "react";
import moment from "moment";
import locale from "antd/lib/date-picker/locale/zh_CN";
import "moment/locale/zh-cn";
import { DatePicker, Space, ConfigProvider } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import BookingSystemApi from "../booking_settings/BookingSystemApi";
import {
  // updateDayAnnouncements,
  announcementFetchMonth,
} from "../../actions/announcementAction";
import { useSelector, useDispatch } from "react-redux";
import { browserHistory, Link } from "react-router";
import { useTranslation } from "react-i18next";
import { dayList, dayListAbbrevEN } from "../../utils/constants";
import { DudooSyncStatus } from "./DudooSyncDisplay";
import BookingCalendar from "./BookingCalendar";
import useScreenSize from "../../hooks/useScreenSize";

const today = moment();
const formattedToday = today.format("YYYY-MM-DD");
const API = new BookingSystemApi();

moment.locale("zh-cn", {
  week: {
    dow: 0
  }
});

export default function DateModeSwitcher({
  mode,
  calPrevious,
  calNext,
  handlePicker,
  isDudooEnable = false,
  isDudooSync = false,
  changeUrlDate,
}) {
  const { t, i18n } = useTranslation("common");
  const { isMobile } = useScreenSize();
  const auth = useSelector((state) => state.auth);
  const { shop_name } = auth.user;
  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const { monthAnnouncements } = announcementListReducer;

  const [date, setDate] = useState(formattedToday);
  const [queryYear, setQueryYear] = useState(today.format("YYYY"));
  const [queryMonth, setQueryMonth] = useState(today.format("MM"));
  const [monthBookingTotalEffectiveCount, setMonthBookingTotalEffectiveCount] =
    useState(0);
  const [monthBookingEffectiveCount, setMonthBookingEffectiveCount] = useState(
    {}
  );
  const [monthBookingAttendance, setMonthBookingAttendance] = useState({});
  const [monthBookingTotalAttendance, setMonthBookingTotalAttendance] =
    useState(0);
  const [monthBookingUnconfirmedCount, setMonthBookingUnconfirmedCount] =
    useState(0);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const datePickerRef = useRef(null);

  const dispatch = useDispatch();
  const queryDate = browserHistory.getCurrentLocation().query.date;

  const initDate = useCallback(() => {
    if (queryDate) {
      let month = moment(queryDate).format("MM");
      let year = moment(queryDate).format("YYYY");

      setDate(queryDate);
      setQueryMonth(month);
      setQueryYear(year);
      dispatch(announcementFetchMonth(moment(queryDate).format("YYYY-MM")));
    }
  }, [dispatch, queryDate]);

  useEffect(() => {
    const isClickInsideDatePicker = (target) => {
      return target.closest(".ant-picker-dropdown") !== null;
    };

    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target) &&
        !isClickInsideDatePicker(event.target)
      ) {
        setShowDatePicker(false);
        initDate();
      }
    };

    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showDatePicker, initDate]);

  useEffect(() => {
    if (isMobile) {
      setShowCalendar(false);
    }
  }, [isMobile]);

  useEffect(() => {
    sessionStorage.setItem("userId", shop_name);
  }, [shop_name]);

  useEffect(() => {
    initDate();
  }, [initDate]);

  const getMonthEffectiveCount = useCallback(
    async (queryYear, queryMonth) => {
      if (date === "") return;

      const month = `${queryYear}/${queryMonth}`;
      try {
        API.getMonthStatus(month, "effective").then((data) => {
          const total = Object.values(data).reduce(
            (res, key) => (res += key),
            0
          );

          setMonthBookingTotalEffectiveCount(total);
          setMonthBookingEffectiveCount(data);
        });
      } catch (err) {
        console.log("getMonthEffectiveCount err-----", err);
      }
    },
    [date]
  );

  const getMonthAttendance = useCallback(
    async (queryYear, queryMonth) => {
      if (!date || date === "") return;

      const month = `${queryYear}/${queryMonth}`;
      try {
        API.getMonthStatus(month, "attendance").then((data) => {
          const total = Object.values(data).reduce(
            (res, key) => (res += key),
            0
          );

          setMonthBookingAttendance(data);
          setMonthBookingTotalAttendance(total);
        });
      } catch (err) {
        console.log("getMonthAttendance error-----", err);
      }
    },
    [date]
  );

  const getMonthUnconfirmedCount = useCallback(
    async (queryYear, queryMonth) => {
      if (!date || date === "") return;

      const month = `${queryYear}/${queryMonth}`;
      try {
        API.getMonthStatus(month, "unconfirmed").then((data) => {
          setMonthBookingUnconfirmedCount(data);
        });
      } catch (err) {
        console.log("getMonthUnconfirmedCount error-----", err);
      }
    },
    [date]
  );

  const calendarSetting = useCallback(() => {
    getMonthEffectiveCount(queryYear, queryMonth);
    getMonthUnconfirmedCount(queryYear, queryMonth);
    getMonthAttendance(queryYear, queryMonth);
  }, [
    getMonthEffectiveCount,
    getMonthUnconfirmedCount,
    getMonthAttendance,
    queryMonth,
    queryYear,
  ]);

  useEffect(() => {
    if (!queryYear || !queryMonth) return;
    calendarSetting();
  }, [calendarSetting, queryYear, queryMonth]);

  const renderTitle = () => {
    let title = "";

    if (mode === "queue") {
      title = t("system.queue");
    } else if (mode === "calendar") {
      title = t("calendar");
    } else {
      title = t("system.booking");
    }

    return <h2 className="heading-2-responsive">{title}</h2>;
  };

  const handlePreMonth = () => {
    let newYear =
      Number(queryMonth) - 1 === 0 ? Number(queryYear) - 1 : Number(queryYear);
    let newMonth = Number(queryMonth) - 1 === 0 ? 12 : Number(queryMonth) - 1;
    let formattedMonth = newMonth < 10 ? `0${newMonth}` : `${newMonth}`;
    setQueryYear(newYear);
    setQueryMonth(formattedMonth);

    let fetchMonth = `${newYear}-${formattedMonth}`;
    dispatch(announcementFetchMonth(fetchMonth));
  };

  const handleNextMonth = () => {
    let newYear =
      Number(queryMonth) + 1 === 13 ? Number(queryYear) + 1 : Number(queryYear);
    let newMonth = Number(queryMonth) + 1 === 13 ? 1 : Number(queryMonth) + 1;
    let formattedMonth = newMonth < 10 ? `0${newMonth}` : `${newMonth}`;
    setQueryYear(newYear);
    setQueryMonth(formattedMonth);

    let fetchMonth = `${newYear}-${formattedMonth}`;
    dispatch(announcementFetchMonth(fetchMonth));
  };

  const customFormat = (value) => {
    const dayOfWeek = value.format("d");
    if (i18n.language === "zh") {
      return `${value.format("YYYY-MM-DD")} 週${dayList[dayOfWeek]}`;
    } else {
      return `${value.format("YYYY-MM-DD")} ${dayListAbbrevEN[dayOfWeek]}`;
    }
  };

  const renderDatePicker = () => {
    let isToday = queryDate === formattedToday;

    const dateCellRender = (current) => {
      let dateStyle = "";
      let queryDate = moment(current).format("YYYY-MM-DD");
      let dateKey = moment(current).format("YYYYMMDD");
      const effectAnnouncement = monthAnnouncements[queryDate];
      const unconfirmedCount = monthBookingUnconfirmedCount[dateKey];
      const effectCount = monthBookingEffectiveCount[dateKey];

      if (effectAnnouncement && effectAnnouncement.length > 0)
        dateStyle += " effect-announcement";
      if (unconfirmedCount) dateStyle += " unconfirmedCount";
      if (effectCount) dateStyle += " effectCount";

      return (
        <div className={`ant-picker-cell-inner ${dateStyle}`}>
          {current.date()}
        </div>
      );
    };

    const updateDateInfo = (value) => {
      const newDate = value.format("YYYY-MM-DD");
      const newYear = value.format("YYYY");
      const newMonth = value.format("MM");
      
      setDate(newDate);
      setQueryYear(newYear);
      setQueryMonth(newMonth);
      dispatch(announcementFetchMonth(moment(value).format("YYYY-MM")));
    };

    const resetDate = (open) => {
      if (!open) return;
      if (isMobile) {
        setShowDatePicker(true);
      } else {
        setShowCalendar(true);
      }

      calendarSetting();
    };

    return (
      <div ref={datePickerRef}>
        <ConfigProvider locale={locale}>
          <DatePicker
            className={`date-picker ${!isToday ? "non-current" : ""}`}
            popupClassName="date-picker-popup"
            locale={i18n.language === "zh" ? locale : null}
            allowClear={false}
            bordered={false}
            inputReadOnly
            showToday={false}
            suffixIcon={null}
            value={moment(date)}
            format={customFormat}
            dateRender={dateCellRender}
            onChange={(date) => {
              handlePicker(date);
              setShowDatePicker(false);
            }}
            onPanelChange={updateDateInfo}
            onOpenChange={resetDate}
            open={showDatePicker && isMobile}
            disabled={mode === "queue"}
          />
        </ConfigProvider>
      </div>
    );
  };

  const renderMonthPicker = () => {
    const currentYear = today.year();
    const currentMonth = today.month();
    let isCurrent = moment(today).format("MM") === queryMonth;

    const monthCellRender = (value) => {
      const isCurrentMonth =
        currentYear === value.year() && value.month() === currentMonth;
      const isSelectedMonth = value.month() === moment(date).month();

      return (
        <div
          className={`ant-picker-cell-inner ${
            isCurrentMonth && !isSelectedMonth ? "current" : ""
          } ${isSelectedMonth && !isCurrentMonth ? "selected" : ""}`}
        >
          {t(`time:numericMonths.${value.month() + 1}`)}
        </div>
      );
    };

    return (
      <DatePicker
        picker="month"
        allowClear={false}
        bordered={false}
        inputReadOnly
        value={moment(date)}
        className={`month-picker ${!isCurrent ? "non-current" : ""}`}
        popupClassName="month-picker-popup"
        suffixIcon={null}
        monthCellRender={monthCellRender}
        onChange={handlePicker}
      />
    );
  };

  const renderModeSwitcher = () => {
    return (
      <div className="dateModeSwitcher-tab-wrapper">
        <div className="dateModeSwitcher-tab">
          <Link
            className={mode === "timeline" ? "active" : ""}
            to={{
              pathname: "/dashboard/new_module",
              query: { date, mode: "timeline" },
            }}
          >
            {t("timeline")}
          </Link>
          <Link
            className={mode === "tablemap" ? "active" : ""}
            to={{
              pathname: "/dashboard/new_module",
              query: { date, mode: "tablemap" },
            }}
          >
            {t("tables")}
          </Link>
          <Link
            className={mode === "list" ? "active" : ""}
            to={{
              pathname: "/dashboard/bookings",
              query: { date, mode: "list" },
            }}
          >
            {t("list")}
          </Link>
        </div>
      </div>
    );
  };

  const handleTodayClick = () => {
    const currentToday = moment();
    handlePicker(currentToday);
  }

  const syncMode = isDudooEnable && mode !== "calendar" && mode !== "queue";
  return (
    <div
      className={`dateModeSwitcher switch-${mode} ${
        syncMode ? "dateModeSwitcher-sync" : ""
      }`}
    >
      {renderTitle()}
      {mode !== "queue" ? (
        <Space size={6} className="dateModeSwitcher-picker">
          <button
            className="button-secondary button-round-50"
            onClick={calPrevious}
            disabled={mode === "queue"}
          >
            <CaretLeftOutlined />
          </button>
          {mode === "calendar" ? renderMonthPicker() : renderDatePicker()}
          <button
            className="button-secondary button-round-50"
            onClick={calNext}
            disabled={mode === "queue"}
          >
            <CaretRightOutlined />
          </button>
          <button
            className="button-secondary btn-text-sm button-round-100"
            onClick={handleTodayClick}
            disabled={mode === "queue"}
          >
            {t("today", { ns: "time" })}
          </button>
        </Space>
      ) : (
        <div className="btn-text-lg">{customFormat(today)}</div>
      )}
      {mode !== "calendar" && mode !== "queue" ? (
        renderModeSwitcher()
      ) : (
        <div className="title-placeholder" />
      )}
      {syncMode && <DudooSyncStatus isDudooSync={isDudooSync} />}
      {showCalendar && (
        <BookingCalendar
          queryYear={queryYear}
          queryMonth={queryMonth}
          handlePreMonth={handlePreMonth}
          handleNextMonth={handleNextMonth}
          closeCalender={() => {
            setShowCalendar(false);
            initDate();
          }}
          monthBookingTotalEffectiveCount={monthBookingTotalEffectiveCount}
          monthBookingTotalAttendance={monthBookingTotalAttendance}
          monthBookingEffectiveCount={monthBookingEffectiveCount}
          monthBookingAttendance={monthBookingAttendance}
          monthBookingUnconfirmedCount={monthBookingUnconfirmedCount}
          changeUrlDate={(queryDate) => {
            changeUrlDate(queryDate);
            setShowCalendar(false);
          }}
        />
      )}
    </div>
  );
}
