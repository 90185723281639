import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Space, Tooltip, Switch } from "antd";
import $ from "jquery";
import _ from "lodash";

import { handleError } from "../../libs/handler";
import SettingsSaveButton from "../commons/SettingsSaveButton";

const BookingSettingTracking = (props) => {
  const { t } = useTranslation("settings");
  const [init, setInit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(true);

  const [calendarEnabled, setCalendarEnabled] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [googleCalendarId, setGoogleCalendarId] = useState("");
  const [googleCalendarName, setGoogleCalendarName] = useState("");
  const [userGoogleEmail, setUserGoogleEmail] = useState("");
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState("");

  const routerWillLeave = useCallback(() => {
    if (!isSaved) return t("leaveNotice");
  }, [isSaved, t]);

  useEffect(() => {
    props.router.setRouteLeaveHook(props.route, routerWillLeave);
  }, [props, routerWillLeave]);

  useEffect(() => {
    const initToken = (auth_code) => {
      $.ajax({
        url: window.domain + "/dashboard/booking_settings/init_google_token",
        method: "POST",
        data: { auth_code: auth_code },
        xhrFields: { withCredentials: true },
      })
        .done(function (data) {})
        .fail(function (xhr) {
          handleError(xhr);
        });
    };

    if (props.location.query.code !== undefined) {
      initToken(props.location.query.code);
    }
  }, [props.location.query.code]);

  useEffect(() => {
    $.when(
      $.ajax({
        url: window.domain + "/dashboard/booking_settings",
        method: "GET",
        xhrFields: { withCredentials: true },
      }),
      $.ajax({
        url: window.domain + "/dashboard/booking_settings/calendar_settings",
        method: "GET",
        xhrFields: { withCredentials: true },
      })
    )
      .then(function (bookingResponse, calendarResponse) {
        const bookingData = bookingResponse[0];
        setCalendarEnabled(bookingData.calendar_enabled);
        setEmailList(bookingData.booking_notification_email);

        const calendarData = calendarResponse[0];
        setGoogleCalendarId(calendarData.google_calendar_id);
        setAccessToken(calendarData.access_token);
        setGoogleCalendarName(calendarData.google_calendar_name);
        setUserGoogleEmail(calendarData.user_google_email);
        setInit(true);
      })
      .fail(function (xhr) {
        handleError(xhr);
      });
  }, []);

  const submitCalendarSettings = () => {
    $.ajax({
      url: window.domain + "/dashboard/booking_settings/calendar_settings",
      data: {
        booking_settings: {
          calendar_enabled: calendarEnabled,
        },
        calendar_settings: {
          google_calendar_id: googleCalendarId,
        },
      },
      method: "PATCH",
      xhrFields: { withCredentials: true },
    })
      .done((data) => {
        setIsSaved(true);
        window.app.alert.setMessage(t("status.saved"), "done");
      })
      .fail(function (xhr) {
        window.app.alert.setMessage(xhr.responseJSON.errors.join(" "), "error");
        handleError(xhr);
      });
  };

  const submit = () => {
    if (accessToken) submitCalendarSettings();

    $.ajax({
      url: window.domain + "/dashboard/booking_settings",
      data: {
        booking_settings: {
          booking_notification_email: emailList,
        },
      },
      method: "PATCH",
      xhrFields: { withCredentials: true },
    })
      .done((data) => {
        setIsSaved(true);
        setSaving(false);
        window.app.alert.setMessage(t("status.saved"), "done");
      })
      .fail(function (xhr) {
        setSaving(false);
        window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
        handleError(xhr);
      });
  };

  const clickRegister = () => {
    $.ajax({
      url: window.domain + "/dashboard/booking_settings/init_google_calendar",
      method: "GET",
      xhrFields: { withCredentials: true },
    })
      .done(function (data) {
        window.location = data;
      })
      .fail(function (xhr) {
        handleError(xhr);
      });
  };

  const addEmail = () => {
    if (email && !emailList.includes(email)) {
      setEmailList(emailList + ";" + email);
      setEmail("");
      setIsSaved(false);
    }
  };

  const removeEmail = (email) => {
    const emailArray = emailList.split(";");
    const newEmailList = emailArray.filter((e) => e !== email);
    setEmailList(_.compact(newEmailList).join(";"));
  };

  if (!init) return <div className="pageLoading">{t("status.loading")}</div>;
  return (
    <>
      <div className="setting-container bookingSettingTracking">
        {saving && (
          <div className="lightBoxLayer">
            <h4>{t("status.saving")}</h4>
          </div>
        )}

        <div style={{ marginBottom: "24px" }}>
          <h3>{t("bookingTracking.title")}</h3>
          <h5>{t("bookingTracking.description")}</h5>
        </div>

        <hr />

        <div className="setting-block">
          <Space direction="vertical" size={32}>
            <Space direction="vertical" size={8}>
              <div className="heading-2-responsive">
                {t("synchronizeWithGoogleCalendar.title")}
              </div>
              <div className="text-body color-subtitle">
                {t("synchronizeWithGoogleCalendar.description")}
              </div>
            </Space>
            {accessToken === null || accessToken === "" || !accessToken ? (
              <button
                className="button-secondary button-register btn-text-md"
                onClick={clickRegister}
              >
                {t("synchronizeWithGoogleCalendar.start")}
              </button>
            ) : (
              <Space direction="vertical" size={20}>
                <div className="settingTbl">
                  <div className="settingTblRow">
                    <div className="header">
                      {t("synchronizeWithGoogleCalendar.enableFeature")}
                      <Tooltip
                        placement="bottom"
                        overlayClassName="setting-tooltip"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        title={t(
                          "synchronizeWithGoogleCalendar.enableFeature_tooltip"
                        )}
                      >
                        <i className="fa fa-question-circle-o" />
                      </Tooltip>
                    </div>
                    <div className="text" style={{ lineHeight: "normal" }}>
                      <Switch
                        onChange={(checked) => {
                          setCalendarEnabled(checked);
                          setIsSaved(false);
                        }}
                        checked={calendarEnabled}
                      />
                    </div>
                  </div>
                </div>
                <div className="settingTbl">
                  <div className="settingTblRow calendarInfo-row">
                    <div className="header" />
                    <div className="text">
                      <Space direction="vertical" size={12}>
                        <Space size={8}>
                          <div className="text-body color-intro">
                            {t("synchronizeWithGoogleCalendar.userEmail")}
                          </div>
                          <div className="text-body color-title">
                            {userGoogleEmail}
                          </div>
                        </Space>
                        <Space size={8}>
                          <div className="text-body color-intro">
                            {t("synchronizeWithGoogleCalendar.calendarName")}
                          </div>
                          <div className="text-body color-title">
                            {googleCalendarName}
                          </div>
                        </Space>
                      </Space>
                    </div>
                  </div>
                </div>
              </Space>
            )}
          </Space>

          <hr />

          <Space direction="vertical" size={32} className="setting-content">
            <Space direction="vertical" size={8}>
              <div className="heading-2-responsive">
                {t("bookingTracking.bookingChangeNotification")}
              </div>
              <div className="text-body color-subtitle">
                {t("bookingTracking.bookingChangeNotification_note")}
              </div>
            </Space>
            <Space direction="vertical" size={20} className="setting-content">
              <div className="settingTbl flex-settingTbl email">
                <div className="settingTblRow">
                  <div className="header">
                    {t("bookingTracking.emailSetting")}
                  </div>
                  <div className="text">
                    <input
                      type="email"
                      placeholder={t("bookingTracking.placeholder_email")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                      className="button-secondary btn-text-md"
                      onClick={addEmail}
                    >
                      {t("bookingTracking.add")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="settingTbl flex-settingTbl">
                <div className="settingTblRow">
                  <div className="header" />
                  <div className="text">
                    <Space direction="vertical" size={8}>
                      {emailList &&
                        emailList !== null &&
                        _.compact(emailList.split(";")).map((email) => {
                          return (
                            <Space key={email} size={8}>
                              <div
                                className="text-body color-title"
                                style={{ padding: "4px 0" }}
                              >
                                {email}
                              </div>
                              <button
                                onClick={() => removeEmail(email)}
                                className="btn-effect"
                              >
                                <span className="icon-md delete-mask-icon" />
                              </button>
                            </Space>
                          );
                        })}
                    </Space>
                  </div>
                </div>
              </div>
            </Space>
          </Space>
        </div>
      </div>
      <SettingsSaveButton handleSaved={submit} showDivider />
    </>
  );
};

export default BookingSettingTracking;
