import React, { useCallback, useContext } from "react";
import { browserHistory } from "react-router";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { CustomerContext } from "../../crmContext/CustomerContext";
import { MessageContext } from "../../crmContext/MessageContext";
import { MessageAddStateContext } from "../../crmContext/MessageAddContext";

const SelectMessagePathPopup = () => {
  const { t } = useTranslation("messages");
  const { CustomerState } = useContext(CustomerContext);
  const { menuUserBindStatus } = CustomerState;
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { showSelectMessagePathPopup } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(
    MessageAddStateContext
  );
  const { allCustomers, allFollowers } = MessageAddState;

  const addMessage = useCallback(
    (editType) => {
      MessageAddDispatch({ type: "cleanMessageSetting" });
      MessageStateDispatch({
        type: "switchMode",
        mode: "addMessage",
        editType,
      });
      MessageStateDispatch({ type: "cancelPopup" });
    },
    [MessageStateDispatch, MessageAddDispatch]
  );

  const cancelPopup = useCallback(() => {
    MessageStateDispatch({ type: "cancelPopup" });
  }, [MessageStateDispatch]);

  const showMenuUserInfo = () => {
    MessageStateDispatch({ type: "showMenuAppIntroModal" });
    cancelPopup();
  };

  const goToBindMenuUser = () => {
    browserHistory.push({
      pathname: "/dashboard/menu_user",
      state: {
        source: "crm_msg_menuTalk",
      },
    });
  };

  const renderMessagePath = () => {
    if (menuUserBindStatus) {
      return (
        <div className="selectMessagePath_content">
          <h3>{t("popup.selectChannel_title")}</h3>
          <div className="selectMessagePath_block">
            <div
              className="selectMessagePath_box select-disabled"
              // onClick={() => addMessage('sms')}
            >
              <div>
                <img
                  src={require("../../../../images/crm/path_message_icon.svg")}
                  alt=""
                />
                {t("popup.sendSMS")}
                <div>（{t("popup.unavailableNow")}）</div>
              </div>
              {t("popup.totalCustomerCount", { count: allCustomers })}
            </div>
            <div
              className="selectMessagePath_box"
              onClick={() => addMessage("menuTalk")}
            >
              <div>
                <img
                  src={require("../../../../images/crm/path_menuTalk_icon.svg")}
                  alt=""
                />
                {t("sendMenuTalk.title")}
              </div>
              {t("popup.totalFollowerCount", { count: allFollowers })}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="selectMessagePath_content_unbind">
          <h3>{t("popup.selectChannel_title")}</h3>
          <div className="selectMessagePath_block">
            <div
              className="selectMessagePath_box select-disabled"
              // onClick={() => addMessage('sms')}
            >
              <div>
                <img
                  src={require("../../../../images/crm/path_message_icon.svg")}
                  alt=""
                />
                {t("popup.sendSMS")}
                <div>（{t("popup.unavailableNow")}）</div>
              </div>
              {t("popup.totalCustomerCount", { count: allCustomers })}
            </div>
            <div className="selectMessagePath_box_unbind">
              <div>
                <img
                  src={require("../../../../images/crm/path_menuTalk_icon.svg")}
                  alt=""
                />
                {t("sendMenuTalk.title")}
              </div>
              <button
                className="button-primary"
                onClick={() => goToBindMenuUser()}
              >
                {t("popup.connectWithMenu")}
              </button>
            </div>
          </div>
          <div className="selectMessagePath_text">
            {t("popup.menuIntro_1")}
            <br />
            {t("popup.menuIntro_2")}
          </div>
          <div
            className="selectMessagePath_menuIntro"
            onClick={() => showMenuUserInfo()}
          >
            {t("popup.menuIntro_3")}
          </div>
        </div>
      );
    }
  };
  return (
    <Modal
      title={t("list.sentMessages")}
      visible={showSelectMessagePathPopup}
      centered
      className="modal-base modal-xl selectMessagePathPopup"
      destroyOnClose
      width={644}
      onCancel={cancelPopup}
      footer={null}
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {renderMessagePath()}
    </Modal>
  );
};

export default SelectMessagePathPopup;
