import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, Space } from "antd";

export const WarningTemplate = ({
  cancelWarning,
  confirmWarning,
  title,
  children,
  type = "",
}) => {
  const { t } = useTranslation("settings");
  return (
    <div className="waringWrap">
      <div className="waringLayout" />
      <div className="waring">
        <div className="waring__header">{title}</div>
        <div className="waring__body">{children}</div>
        <div className="waring__footer">
          <button className="waring-cancel" onClick={() => cancelWarning(type)}>
            {t("cancel")}
          </button>
          <button className="waring-check" onClick={() => confirmWarning(type)}>
            {t("ok")}
          </button>
        </div>
      </div>
    </div>
  );
};

export const TimelineWarningTemplate = ({
  cancelWarning = () => {},
  confirmWarning = () => {},
  title,
  children,
  conflict = false,
}) => {
	const { t } = useTranslation('bookingSystem');

  const renderFooter = () => {
		if(conflict) {
			return (
				<Space size={8}>
					<button
						className="button-common button-secondary"
            onClick={() => confirmWarning("seated")}
					>
						{t('buttonStatus.move')}
					</button>
					<button
						className="button-common button-primary"
            onClick={() => confirmWarning("swap")}
					>
						{t('buttonStatus.change')}
					</button>
			</Space>
			)
		}

    return (
      <button
        className="button-common button-primary"
        onClick={() => confirmWarning("seated")}
      >
        {t('buttonStatus.seated')}
      </button>
    );
  };

	return (
		<Modal
			title={title}
			visible
			destroyOnClose
			width={374}
			centered
			onCancel={cancelWarning}
			className="modal-base modal-sm"
			footer={renderFooter()}
			transitionName="ant-move-down"
		>
			{children}
		</Modal>
	)
};
