import { useState, useEffect, useRef } from "react";

const useDebounce = (value, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const pathname = window.location.pathname;
  const pathRef = useRef(window.location.pathname);

  useEffect(() => {
    if(pathRef.current !== pathname) {
      setDebouncedValue("");
    }
    pathRef.current = pathname;
  }, [pathname]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
