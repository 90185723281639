import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import BookingSystemApi from '../BookingSystemApi';
import { checkDudooTableSync } from "../../../api/posSync";
import Modal from './BookingSettingSeatsModal';
import { useTranslation } from 'react-i18next';
import SettingsSaveButton from '../../commons/SettingsSaveButton';
import CheckModal from '../../popup/CheckModal';
import ConfirmModal from '../../popup/ConfirmModal';

const API = new BookingSystemApi();

const BookingSettingSeats = (props) => {
	const { t } = useTranslation('settings');
	const [ sending, setSending ] = useState(false);
	const [ canvasRefresh, setCanvasRefresh ] = useState(false);
	const [ isSaved, setIsSaved ] = useState(true);
	const [ showModal, setShowModal ] = useState(false);
	const [ tableMapSetting, setTableMapSetting ] = useState(false);
	const [ groups, setGroups ] = useState([]);
	const [ groupSetting, setGroupSetting ] = useState({});
	const [ groupSettingIndex, setGroupSettingIndex ] = useState(null);
	const [ dudooSyncEnabled, setDudooSyncEnabled ] = useState(false);
	const [ showConfirmModal, setShowConfirmModal] = useState(false);

	const getBookingSetting = useCallback(async () => {
		try {
			await API.getBookingSetting().then((data) => {
				setTableMapSetting(data.canvas_enabled);
				setDudooSyncEnabled(data.pos_enabled);
			});

			await API.getBookingSettingSeats().then((data) => {
				setGroups(_.sortBy(data.results, [ 'group', 'max_seat' ]));
			});
		} catch (err) {
			window.app.alert.setMessage(t('status.pls_tryAgainLater'), 'error');
		}
	}, [t]);

	useEffect(
		() => {
			getBookingSetting();
		},
		[ getBookingSetting ]
	);

	useEffect(
		() => {
			props.router.setRouteLeaveHook(props.route, () => {
				if (!isSaved) return t('leaveNotice');
			});
		},
		[ props, isSaved, t ]
	);

	const openModal = () => {
		setGroupSetting({
			group: '',
			combinable: false,
			min_seat: 1,
			max_seat: 1,
			open_qty: 0,
			quantity: 0,
			seats: null,
			table_numbers: [],
			table_objs: []
		});
		setGroupSettingIndex(null)
		setShowModal(true);
	};

	const handleOk = (setting) => {
		setShowModal(false);
		const newGroups = _.cloneDeep(groups);


		if (groupSettingIndex !== null) {
			const { table_objs } = newGroups[groupSettingIndex];
			let tableIdx = table_objs.length - 1;
			
			setting.table_objs.map((table) => {
				if (table.idx === null) {
					++tableIdx;
					table.idx = tableIdx;
				}
				return null;
			});

			newGroups[groupSettingIndex] = setting;
			setGroups(_.sortBy(newGroups, [ 'group', 'max_seat' ]));
		} else {
			let tableIdx = 0;

			setting.table_objs.map((table) => {
				if (table.idx === null) {
					table.idx = tableIdx;
					tableIdx++;
				}
				return null;
			});

			newGroups.push(setting);
			setGroups(_.sortBy(newGroups, [ 'group', 'max_seat' ]));
		}
		setGroupSetting({});
		setGroupSettingIndex(null);
		setIsSaved(false);
	};

	const handleCancel = () => {
		setShowModal(false);
		setGroupSetting({});
	};

	const removeGroup = (index) => {
		const newGroups = _.cloneDeep(groups);
		newGroups.splice(index, 1);
		setGroups(newGroups);
		setIsSaved(false);
	};

	const editGroup = (index) => {
		const newGroups = _.cloneDeep(groups);
		const group = newGroups[index];
		setGroupSettingIndex(index);
		setGroupSetting(group);
		setShowModal(true);
	};

	const renderGroupRow = () => {
		return groups.map((group, index) => (
			<GroupRow
				key={index}
				group={group}
				removeGroup={() => removeGroup(index)}
				editGroup={() => editGroup(index)}
			/>
		));
	};

	// const handleSubmit = async() => {
	// 	let isValid = true;
	// 	let groupName = '',
	// 		groupMin = null,
	// 		groupMax = null,
	// 		groupCombinable = false;

	// 	let tables = _.sortBy(groups, [ 'group', 'max_seat' ]);

	// 	if (tables.length === 0) return window.app.alert.setMessage(`${t('tableSettings.pls_completeTableGroups')}`, 'error');

	// 	tables.forEach((ts) => {
	// 		if (groupName === '' || groupName !== ts.group) {
	// 			groupName = ts.group;
	// 			groupMin = ts.min_seat;
	// 			groupMax = ts.max_seat;
	// 			groupCombinable = ts.combinable;

	// 			ts.table_objs.forEach((table) => {
	// 				if(table.idx === null || isNaN(table.idx)) {
	// 					window.app.alert.setMessage(t('tableSettings.alertTableError_1', {
	// 						groupName,
	// 						tableNumber: table.table_number
	// 					}), 'error');
	// 					isValid = false;
	// 					return;
	// 				}
	// 			})
	// 		} else if (groupName === ts.group) {
	// 			if (groupMin === ts.min_seat && groupMax === ts.max_seat && groupCombinable === ts.combinable) {
	// 				window.app.alert.setMessage(t('tableSettings.alertTableError_2'), 'error');
	// 				isValid = false;
	// 				return;
	// 			}

	// 			ts.table_objs.forEach((table) => {
	// 				if(table.idx === null || isNaN(table.idx)) {
	// 					window.app.alert.setMessage(t('tableSettings.alertTableError_1', {
	// 						groupName,
	// 						tableNumber: table.table_number
	// 					}), 'error');
	// 					isValid = false;
	// 					return;
	// 				}
	// 			})
	// 		}
	// 	});

	// 	if (!isValid) return;
	// 	settingSubmit();
	// }

	const settingSubmit = async () => {
		try {
			setSending(true);

			const groupsRes = await API.saveBookingSettingSeats(groups);
			let canvasRefresh = groupsRes.canvas_refresh_required;

			if (!tableMapSetting) {
				//沒有開啟桌位圖不用顯示提醒
				canvasRefresh = false;
			}

			if(dudooSyncEnabled) {
				try {
					await checkDudooTableSync();
				} catch(error) {
					if(!canvasRefresh) setShowConfirmModal(true);
				}
			}

			setGroups(_.sortBy(groupsRes.results, [ 'group', 'max_seat' ]));
			setIsSaved(true);
			setSending(false);
			setCanvasRefresh(canvasRefresh);

			if (!canvasRefresh) window.app.alert.setMessage(t('status.saved'), 'done');
		} catch (err) {
			console.log('settingSubmit err---', err);

			setSending(false);
			window.app.alert.setMessage(t('status.pls_tryAgainLater'), 'error');
		}
	};

	const goCanvas = () => {
		props.router.push('/dashboard/setting/tablemap_setting');
		return;
	};

	return (
    <>
      <div id="seats" className="setting-container">
        {sending && (
          <div className="lightBoxLayer">
            <h4>{t("status.saving")}</h4>
          </div>
        )}
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <h3>{t("tableSettings.subtitle")}</h3>
            <h5>{t("tableSettings.description")}</h5>
            <div className="des-notice">
              {t("tableSettings.pleaseNote")}
              <ul>
                <li>{t("tableSettings.pleaseNote_1")}</li>
                <li>{t("tableSettings.pleaseNote_2")}</li>
              </ul>
            </div>
          </div>
        </div>
        <hr />

        <div className="newSeatsSetting">
          <button className="msBtn" onClick={() => openModal()}>
            ＋ {t("tableSettings.createTableType")}
          </button>

          <div className="seatsTable">
            <div className="seatsTableRow seatsTableTitleSection">
              <div>{t("tableSettings.tableType")}</div>
              <div>{t("tableSettings.tableCapacity")}</div>
              <div>{t("tableSettings.totalNumberOfTables")}</div>
              <div>
                {t("tableSettings.numberOfTablesAvailableForOnlineBooking")}
              </div>
              <div>{t("tableSettings.availableForSplitTables")}</div>
              <div />
              <div />
            </div>
            {renderGroupRow()}
          </div>
        </div>
        {showModal && (
          <Modal
            showModal={showModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
            groupSetting={groupSetting}
            groupSettingIndex={groupSettingIndex}
            groups={
              groupSettingIndex !== null
                ? groups.filter((_, index) => index !== groupSettingIndex)
                : groups
            }
          />
        )}
        {canvasRefresh && (
          <ConfirmModal
            title={t("tableSettings.updated_title")}
            content={t("tableSettings.updated_content")}
            submit={goCanvas}
            submitText={t("tableSettings.go")}
          />
        )}
        {showConfirmModal && (
          <ConfirmModal
            title={t("dudooPosSettings.popup.tableMappingInconsistent_title")}
            content={t(
              "dudooPosSettings.popup.tableMappingInconsistent_content"
            )}
            submit={() => {
              setShowConfirmModal(false);
            }}
          />
        )}
        <hr />
      </div>
      <SettingsSaveButton handleSaved={settingSubmit} />
    </>
  );
};

const GroupRow = ({ group, removeGroup, editGroup }) => {
	const { t } = useTranslation('settings');
	const [ expand, setExpand ] = useState(false);
	const [ showDeleteModal, setShowDeleteModal ] = useState(false);

	const toggleExpandSeat = (e) => {
		const icon = e.target;
		if (expand) {
			icon.classList.remove('fa-angle-up');
			icon.classList.add('fa-angle-down');
			setExpand(false);
		} else {
			icon.classList.remove('fa-angle-down');
			icon.classList.add('fa-angle-up');
			setExpand(true);
		}
	};

	const renderTable = () => {
		return group.table_objs
			.filter((table) => table.available === true)
			.map((table) => <span key={table.table_number}>{table.table_number}</span>);
	};

	return (
		<section className="seatsTableSection">
			<div className="seatsTableRow">
				<div>{group.group}</div>
				<div>
					{group.min_seat}-{group.max_seat}{t('person')}
				</div>
				<div>{group.quantity}</div>
				<div>{group.open_qty}</div>
				<div>
					{group.combinable ? (
						<span className="onlineOpenIcon" />
					) : (
						<span className="onlineOpenIcon onlineOpenIcon-disable" />
					)}
				</div>
				<div>
					<i className="fa fa-angle-down" aria-hidden="true" onClick={(e) => toggleExpandSeat(e)} />
				</div>
				<div>
					<div className="dropdown">
						<i className="fa fa-ellipsis-v" aria-hidden="true" data-toggle="dropdown" />
						<ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
							<li onClick={() => editGroup()}>{t('edit')}</li>
							<li onClick={() => setShowDeleteModal(true)}>
								{t('delete')}
							</li>
						</ul>
					</div>
				</div>
			</div>
			{expand && (
				<div className="expandSeat">
					<p>{t('tableSettings.table')}</p>
					<div className="seatsSection">{renderTable()}</div>
				</div>
			)}

			{showDeleteModal &&
				<CheckModal
					title={t('tableSettings.deleteConfirmation')}
					content={t('tableSettings.deleteConfirmation_content')}
					submit={() => {
						removeGroup();
						setShowDeleteModal(false);
					}}
					cancel={() => setShowDeleteModal(false)}
				/>
			}
		</section>
	);
};

export default BookingSettingSeats;
