import React from "react";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";

const CouponIcon = () => {
  const { t } = useTranslation();
  return (
    <div className="dashboard-item">
      <Link
        to={{
          pathname: "/dashboard/menu_coupon",
        }}
        role="button"
      >
        <div className="dashboard-thumbnail">
          <img
            alt="booking"
            src={require("../../images/dashboard/coupon.svg")}
          />
          <div className="dashboard-system-description text-body">
            {t("dashboard:couponSystemDescription")}
          </div>
          <div className="horizontal-between">
            <h3 className="heading-3-responsive">{t("common:system.coupon")}</h3>
            <div className="icon-normal doubleArrowRight-icon" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CouponIcon;
