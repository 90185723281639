import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { CouponStateContext } from "../MenuCouponContext/MenuCouponContext";
import MenuCouponApi from "../MenuCouponApi";
import { Space } from "antd";
import useScrollableArrows from "../../../hooks/useScrollableArrows";
import { useTranslation } from "react-i18next";

const menuCouponAPI = new MenuCouponApi();

const MenuCouponHeaderFilter = () => {
  const { t } = useTranslation("couponSystem");
  const auth = useSelector((state) => state.auth);
  const { name } = auth.shop;
  const filterType = {
    all: t("couponOverview.all"),
    enable: t("couponOverview.inProgress"),
    draft: t("couponOverview.draft"),
    standby: t("couponOverview.scheduled"),
    disable: t("couponOverview.paused"),
    expired: t("couponOverview.expired"),
  };

  const { CouponState, CouponDispatch } = useContext(CouponStateContext);
  const { couponLists, activeCategory } = CouponState;
  const { scrollRef, showLeftArrow, showRightArrow } =
    useScrollableArrows(couponLists);
  const scrollAmount = 100;

  const handleLeftArrowClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  const handleRightArrowClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const switchCategory = (category) => {
    CouponDispatch({ type: "switchCategory", category });
  };

  const changeSortType = async ({ sortType, orderType }) => {
    try {
      CouponDispatch({ type: "setLoading", loading: true });
      const list = await menuCouponAPI.getCoupon(name, sortType, orderType);
      CouponDispatch({ type: "getCouponList", list });
    } catch (err) {
      console.log("changeSortType err-----", err);
    }
  };

  const renderCouponType = () => {
    return (
      <ul ref={scrollRef}>
        {Object.keys(filterType).map((item, index) => {
          const activeStyle = activeCategory === item ? "active" : "";
          const filterTypeLength = couponLists.filter(
            (filterItem) => filterItem.status === item
          ).length;
          if (item === "all") {
            return (
              <li
                key={item}
                className={`${activeStyle}`}
                onClick={() => switchCategory(item)}
              >
                <Space size={4}>
                  {filterType[item]}
                  <span>{couponLists.length}</span>
                </Space>
              </li>
            );
          }

          return (
            <li
              key={item}
              className={`${activeStyle}`}
              onClick={() => switchCategory(item)}
            >
              <Space size={4}>
                {filterType[item]}
                <span>{filterTypeLength}</span>
              </Space>
            </li>
          );
        })}
      </ul>
    );
  };

  if (couponLists.length === 0) return null;
  return (
    <div className="menuCouponHeaderFilter">
      <div className="menuCouponHeaderFilter__list scrollable-tabs">
        {showLeftArrow && (
          <div className="scroll-left-arrow" onClick={handleLeftArrowClick}>
            <div className="arrowLeft-icon-dark" />
          </div>
        )}
        {renderCouponType()}
        {showRightArrow && (
          <div className="scroll-right-arrow " onClick={handleRightArrowClick}>
            <div className="arrowRight-icon-dark" />
          </div>
        )}
      </div>
      {activeCategory === "all" && (
        <div className="menuCouponHeaderFilter__button">
          <button
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div className="dropdown-menu dropdown-menu-right">
            <ul>
              <li
                onClick={() =>
                  changeSortType({ sortType: "createdAt", orderType: "1" })
                }
              >
                {t("couponOverview.fromNewToOld")}
              </li>
              <li
                onClick={() =>
                  changeSortType({ sortType: "createdAt", orderType: "0" })
                }
              >
                {t("couponOverview.fromOldToNew")}
              </li>
              <li
                onClick={() =>
                  changeSortType({ sortType: "sentCount", orderType: "1" })
                }
              >
                {t("couponOverview.claims")}
              </li>
              <li
                onClick={() =>
                  changeSortType({ sortType: "exchangedCount", orderType: "1" })
                }
              >
                {t("couponOverview.redemption")}
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuCouponHeaderFilter;
