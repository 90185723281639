import React from "react";
import { useTranslation } from "react-i18next";
import { Space } from "antd";

import NotificationTitle from "./NotificationTitle";
import NotificationTypeStatus from "./NotificationTypeStatus";
import EditButton from "./EditButton";

const NotificationSettingBlock = ({
  titleKey,
  type,
  emailMsg,
  smsMsg,
  lineMsg,
  credits,
  showModal,
}) => {
  const { t } = useTranslation("settings");
  return (
    <div className="notification-setting">
      <Space direction="vertical" size={12}>
        <NotificationTitle
          title={t(`${type === "booking" ? "bookingNotification" : "queueNotification"}.${titleKey}`)}
          tooltip={t(`${type === "booking" ? "bookingNotification" : "queueNotification"}.${titleKey}_tooltip`)}
        />
        <NotificationTypeStatus
          type={type}
          email={emailMsg}
          sms={smsMsg}
          line={lineMsg}
          credits={credits}
        />
      </Space>
      <EditButton showModal={showModal} />
    </div>
  );
};

export default NotificationSettingBlock;
