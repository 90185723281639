import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { browserHistory } from "react-router";

import DayNames from "./DayNames";
import Week from "./Week";

import {
  announcementFetchMonth,
  updateDayAnnouncements,
  resetAnnouncementList,
} from "../../../actions/announcementAction";

export default (props) => {
  const selectedRef = useRef(null);
  const [month, setMonth] = useState(props.selected.clone());
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      dispatch(announcementFetchMonth(moment(month).format("YYYY-MM")));
    }

    return () => {
      isMounted = false;
      dispatch(resetAnnouncementList());
    };
  }, [dispatch, month]);

  useEffect(() => {
    if (selectedRef.current === null) {
      selectedRef.current = props.selected;
    }

    if (
      selectedRef.current.format("YYYY-MM-DD") !==
      props.selected.format("YYYY-MM-DD")
    ) {
      setMonth(props.selected);
      selectedRef.current = props.selected;
    }
  }, [props.selected]);

  const select = (day) => {
    browserHistory.push({
      pathname: "/dashboard/bookings",
      query: { date: day.date.format("YYYY-MM-DD"), mode: "list" },
    });
    props.renderDisableDate(moment(day.date).format("YYYY-MM-DD"));
    props.changeViewMode("list");
    dispatch(updateDayAnnouncements(day.date.format("YYYY-MM-DD")));
  };

  const renderWeeks = () => {
    let weeks = [],
      done = false,
      date = month
        .clone()
        .startOf("month")
        .add("w" - 1)
        .day("Sunday"),
      monthIndex = date.month(),
      count = 0;
    let disableDatesObj = {};

    if (props.disableDates !== undefined) {
      props.disableDates.map((item) => (disableDatesObj[item] = 1));
    }

    while (!done) {
      weeks.push(
        <Week
          disableDates={disableDatesObj}
          monthEffectiveCount={props.monthEffectiveCount}
          monthUnconfirmedCount={props.monthUnconfirmedCount}
          monthIneffectiveCount={props.monthIneffectiveCount}
          monthAttendance={props.monthAttendance}
          key={date.toString()}
          date={date.clone()}
          month={month}
          select={select}
          selected={props.selected}
        />
      );
      date.add(1, "w");
      done = count++ > 2 && monthIndex !== date.month();
      monthIndex = date.month();
    }

    return weeks;
  };

  return (
    <div className="horizontal-center bookingPanel-layout">
      <div className="ms-calendar">
        <DayNames />
        {renderWeeks()}
      </div>
    </div>
  );
};
