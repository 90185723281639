import moment from 'moment';

import {
	getAnnouncements,
	getMonthAnnouncements,
	getSpecifiedDateAnnouncements,
	getFinishedAnnouncementsByPage,
	searchAnnouncementsByTitle
} from '../components/announcement/api/AnnouncementApi';
import {
	getAllOfficialAnnouncements,
	getTodayOfficialAnnouncements,
	getFinishedOfficialAnnouncementsByPage,
	searchOfficialAnnouncementsByTitle
} from '../components/announcement/api/OfficialAnnouncementApi';

export const ANNOUNCEMENTLIST_RESET = 'ANNOUNCEMENTLIST_RESET';
export const ANNOUNCEMENTLIST_CHANGEPAGE = 'ANNOUNCEMENTLIST_CHANGEPAGE';
export const ANNOUNCEMENTLIST_SETROLE = 'ANNOUNCEMENTLIST_SETROLE';
export const ANNOUNCEMENTLIST_FETCHSTART = 'ANNOUNCEMENTLIST_FETCHSTART';
export const ANNOUNCEMENTLIST_FETCHOFFICIAL = 'ANNOUNCEMENTLIST_FETCHOFFICIAL';
export const ANNOUNCEMENTLIST_FETCHMONTH = 'ANNOUNCEMENTLIST_FETCHMONTH';
export const ANNOUNCEMENTLIST_FETCOFFICIALTODAY = 'ANNOUNCEMENTLIST_FETCOFFICIALTODAY';
export const ANNOUNCEMENTLIST_UPDATEDAY = 'ANNOUNCEMENTLIST_UPDATEDAY';
export const ANNOUNCEMENTLIST_SETTODAY = 'ANNOUNCEMENTLIST_SETTODAY';
export const ANNOUNCEMENTLIST_CHANGETAB = 'ANNOUNCEMENTLIST_CHANGETAB';
export const ANNOUNCEMENTLIST_CHANGEFILTER = 'ANNOUNCEMENTLIST_CHANGEFILTER';
export const ANNOUNCEMENTLIST_FILTERNUMBER = 'ANNOUNCEMENTLIST_FILTERNUMBER';
export const ANNOUNCEMENTLIST_SELECTED = 'ANNOUNCEMENTLIST_SELECTED';
export const ANNOUNCEMENTLIST_SHOWLISTMODAL = 'ANNOUNCEMENTLIST_SHOWLISTMODAL';
export const ANNOUNCEMENTLIST_CLOSELISTMODAL = 'ANNOUNCEMENTLIST_CLOSELISTMODAL';
export const ANNOUNCEMENTLIST_LISTMODALSEARCH = 'ANNOUNCEMENTLIST_LISTMODALSEARCH';
export const ANNOUNCEMENTLIST_SEARCH = 'ANNOUNCEMENTLIST_SEARCH';
export const ANNOUNCEMENTLIST_RESETSEARCH = 'ANNOUNCEMENTLIST_RESETSEARCH';
export const ANNOUNCEMENTLIST_SHOWCANCELEDITMODAL = 'ANNOUNCEMENTLIST_SHOWCANCELEDITMODAL';
export const ANNOUNCEMENTLIST_SETTINGENDED = 'ANNOUNCEMENTLIST_SETTINGENDED';
export const ANNOUNCEMENTLIST_SETTINGCANCEL = 'ANNOUNCEMENTLIST_SETTINGCANCEL';

export const ANNOUNCEMENT_CHANGE = 'ANNOUNCEMENT_CHANGE';
export const ANNOUNCEMENT_CLEAR = 'ANNOUNCEMENT_CLEAR';
export const ANNOUNCEMENT_EDIT = 'ANNOUNCEMENT_EDIT';
export const ANNOUNCEMENT_INIT = 'ANNOUNCEMENT_INIT';
export const ANNOUNCEMENT_SHOWADDMODAL = 'ANNOUNCEMENT_SHOWADDMODAL';
export const ANNOUNCEMENT_SHOWPREVIEWMODAL = 'ANNOUNCEMENT_SHOWPREVIEWMODAL';

export function resetAnnouncementList() {
	return (dispatch) => {
		dispatch({ type: ANNOUNCEMENTLIST_RESET });
		dispatch({ type: ANNOUNCEMENT_CLEAR });
	}
}

// 是否為官方帳號
export function handleAuthority(role) {
	return {
		type: ANNOUNCEMENTLIST_SETROLE,
		role
	};
}

// init lists
export function announcementFetchAll() {
	return async(dispatch) => {
		try {
      const allAnnouncements = await getAnnouncements();

      dispatch({
				type: ANNOUNCEMENTLIST_FETCHSTART,
				lists: allAnnouncements.data
			});

			dispatch(announcementFilterNum());
    } catch(err) {
      console.log('get all announcement error ----------', err);
    }
	}
}

// 更新頁數
export function changePage(page) {
	return {
		type: ANNOUNCEMENTLIST_CHANGEPAGE,
		page
	}
}

// 取得該月公告
export function announcementFetchMonth(month) {
	return async(dispatch, getState) => {
		const state = getState().announcementListReducer;
		const { searchModalTxt } = state;
		const currentMonth = moment(new Date()).format('YYYY-MM');
		const params = {
      "month": month ?  moment(month).format('YYYY-MM') : currentMonth
    };

		try {
      const { data } = await getMonthAnnouncements(params);

      dispatch({
				type: ANNOUNCEMENTLIST_FETCHMONTH,
				lists: data
			});

			dispatch({
				type: ANNOUNCEMENTLIST_LISTMODALSEARCH,
				searchModalTxt
			});
    } catch(err) {
      console.log('get month announcement error ----------', err);
    } 
	}
}

export function handleAnnouncementListModal(showListType, currentDate = '') {
	return {
		type: ANNOUNCEMENTLIST_SHOWLISTMODAL,
		showListType,
		currentDate,
	}
}

export function closeAnnouncementListModal() {
	return {
		type: ANNOUNCEMENTLIST_CLOSELISTMODAL
	};
}

// 取得該日公告
export function updateDayAnnouncements(date) {
	return async(dispatch) => {
		const day = date ? moment(new Date(date)).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
		const isToday = moment(new Date(date)).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
		const params = {
      "date": day
    };

		try {
      const { data } = await getSpecifiedDateAnnouncements(params);

			let effectiveAnnouncements = []

			effectiveAnnouncements = data.filter((i) => {
				return !i.finished && i.show_enabled;
			});

			effectiveAnnouncements.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

      dispatch({
				type: ANNOUNCEMENTLIST_UPDATEDAY,
				lists: effectiveAnnouncements
			});

			if(!date || isToday) {
				dispatch({
					type: ANNOUNCEMENTLIST_SETTODAY,
					lists: effectiveAnnouncements
				});
			}
    } catch(err) {
      console.log('update date announcement error ----------', err);
    } 
	}
}

// 取得找活公告
export function announcementOfficialFetch(selectedDate = null) {
	return async(dispatch, getState) => {
		function isDateInRange(date, start, end) {
			const currentDate = date ? moment(date) : moment();
			const startDate = moment(start);
			const endDate = moment(end);
			return currentDate.isBetween(startDate, endDate, null, '[]');
		}

		try {
      const { data } = await getAllOfficialAnnouncements();

			data.forEach(item => {
				item.type = 'official';
			});

			const filteredAnnouncements = data.filter(item => 
        isDateInRange(selectedDate, item.start_time, item.end_time)
      );

      dispatch({
				type: ANNOUNCEMENTLIST_FETCHOFFICIAL,
				lists: data,
				dayLists: filteredAnnouncements
			});

			dispatch(announcementFilterNum());
    } catch(err) {
      console.log('get official announcement error ----------', err);
    } 
	}
}

// 取得當日找活公告
export function announcementOfficialFetchToday() {
	return async(dispatch) => {
		try {
      const { data } = await getTodayOfficialAnnouncements();

			data.forEach(item => {
				item.type = 'official';
			});

      dispatch({
				type: ANNOUNCEMENTLIST_FETCOFFICIALTODAY,
				lists: data
			});
    } catch(err) {
      console.log('get month official announcement error ----------', err);
    } 
	}
}

// header tab
export function handleHeaderTab(tab) {
	return (dispatch, getState) => {
		const state = getState().announcementListReducer;
		const { searchListTxt } = state;

		if(searchListTxt.length > 0) {
			dispatch({ type: ANNOUNCEMENTLIST_CHANGETAB, tab });
			dispatch(handleSearchList(searchListTxt))
		} else {
			dispatch({ type: ANNOUNCEMENTLIST_CHANGETAB, tab });
			dispatch(handleHeaderFilter('all', 1));
		}

		dispatch(announcementSelected([]));
	}
}

// header filter
export function handleHeaderFilter(filter, p, sortBy, order = null) {
	return async(dispatch, getState) => {
		const state = getState().announcementListReducer;
		const { searchMode, page, currentTab } = state;

		let params = {
			"page": p ? p : page,
		};;

		if(searchMode) {
			dispatch({
				type: ANNOUNCEMENTLIST_CHANGEFILTER,
				filter,
				sortBy: sortBy ? sortBy : 'start_time',
				order
			});
			dispatch(announcementFilterNum());
		} else {
			if(currentTab === 'shop') {
				if(order !== null) {
					params.sort_by = sortBy;
					params.order = order;
				}

				if(filter === 'finished') {
					try {
						const { data } = await getFinishedAnnouncementsByPage(params);
			
						dispatch({
							type: ANNOUNCEMENTLIST_CHANGEFILTER,
							filter,
							page: p ? p : page,
							lists: data,
							sortBy: sortBy ? sortBy : 'start_time',
							order
						});
						dispatch(announcementFilterNum());
					} catch(err) {
						console.log('get finished announcement error ----------', err);
					}
				} else {
					if(filter !== 'all') {
						params.category = filter;
					}
		
					try {
						const { data } = await getAnnouncements(params);
			
						dispatch({
							type: ANNOUNCEMENTLIST_CHANGEFILTER,
							filter,
							page: p ? p : page,
							lists: data,
							sortBy: sortBy ? sortBy : 'start_time',
							order
						});
						dispatch(announcementFilterNum());
					} catch(err) {
						console.log('change announcement filter error ----------', err);
					}
				}
			} else {
				if(order !== null) {
					params.sort_by = sortBy;
					params.order = order;
				}

				if(filter === 'finished') {
					try {
						const { data } = await getFinishedOfficialAnnouncementsByPage(params);

						data.forEach(item => {
							item.type = 'official';
						});
			
						dispatch({
							type: ANNOUNCEMENTLIST_CHANGEFILTER,
							filter,
							page: p ? p : page,
							lists: data,
							sortBy: sortBy ? sortBy : 'start_time',
							order
						});
						dispatch(announcementFilterNum());
					} catch(err) {
						console.log('get finished official announcement error ----------', err);
					}
				} else {
					if(filter !== 'all') {
						params.category = filter;
					}
		
					try {
						const { data } = await getAllOfficialAnnouncements(params);

						data.forEach(item => {
							item.type = 'official';
						});
			
						dispatch({
							type: ANNOUNCEMENTLIST_CHANGEFILTER,
							filter,
							page: p ? p : page,
							lists: data,
							sortBy: sortBy ? sortBy : 'start_time',
							order
						});
						dispatch(announcementFilterNum());
					} catch(err) {
						console.log('change announcement filter error ----------', err);
					}
				}
			}
		}
	}
}

// filter num
export function announcementFilterNum() {
	return {
		type: ANNOUNCEMENTLIST_FILTERNUMBER,
	};
}

// 列表搜尋
export function handleSearchList(searchText) {
	return async(dispatch, getState) => {
		const state = getState().announcementListReducer;
		const { currentFilter, currentTab } = state;

		if(searchText.length > 0) {
			const params = {
				"title": searchText,
			};
	
			if(currentTab === 'shop') {
				try {
					const { data } = await searchAnnouncementsByTitle(params);
		
					dispatch({
						type: ANNOUNCEMENTLIST_SEARCH,
						searchText,
						lists: data
					})
	
					dispatch(handleHeaderFilter(currentFilter));
				} catch(err) {
					console.log('search announcement err ----------', err);
				}
			} else {
				try {
					const { data } = await searchOfficialAnnouncementsByTitle(params);
		
					dispatch({
						type: ANNOUNCEMENTLIST_SEARCH,
						searchText,
						lists: data
					})
	
					dispatch(handleHeaderFilter(currentFilter));
				} catch(err) {
					console.log('search official announcement err ----------', err);
				}
			}
		} else {
			dispatch({
				type: ANNOUNCEMENTLIST_SEARCH,
				searchText
			})
			dispatch(handleHeaderFilter(currentFilter, 1));
		}
	}
}

export function resetSearch() {
	return {
		type: ANNOUNCEMENTLIST_RESETSEARCH
	}
}

// 當月份列表搜尋
export function searchAnnouncementListModal(searchModalTxt) {
  return {
    type: ANNOUNCEMENTLIST_LISTMODALSEARCH,
    searchModalTxt
  }
}

// 列表選取
export function announcementSelected(selected) {
	return {
		type: ANNOUNCEMENTLIST_SELECTED,
    selected
	};
}

// 取消動作
export function announcementSettingCancel() {
	return {
		type: ANNOUNCEMENTLIST_SETTINGCANCEL,
	};
}

// 選取刪除 or 設為已結束
export function announcementSettingEnded(settingType) {
	return {
		type: ANNOUNCEMENTLIST_SETTINGENDED,
		settingType
	};
}

// 取消編輯
export function showAnnouncementCancelModal() {
	return {
		type: ANNOUNCEMENTLIST_SHOWCANCELEDITMODAL
	};
}

// edit update
export function announcementChange(editType, value) {
	return {
		type: ANNOUNCEMENT_CHANGE,
		editType,
    value
	};
}

export function showAnnouncementPreviewModal() {
	return {
		type: ANNOUNCEMENT_SHOWPREVIEWMODAL
	};
}

export function showAnnouncementAddModal() {
	return {
		type: ANNOUNCEMENT_SHOWADDMODAL
	};
}

export function clearAnnouncement() {
	return {
		type: ANNOUNCEMENT_CLEAR
	};
}

export function announcementSetting(item) {
	return {
		type: ANNOUNCEMENT_EDIT,
    item
	};
}

// 新增公告初始化
export function announcementInit() {
	return {
		type: ANNOUNCEMENT_INIT
	};
}