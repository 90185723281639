import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { customQAns } from "../NewModuleComponent/NewModuleTools";
import BookingCustomerTags from "../../bookings/items/BookingCustomerTags";

import { time, useInterval } from "../utils/data";

import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";
import { BaseSettingContext } from "../NewModuleReducer/BaseSettingReducer";

import BookingSystemApi from "../../booking_settings/BookingSystemApi";
import { DudooSyncDisplay } from "../../commons/DudooSyncDisplay";
import CheckModal from "../../popup/CheckModal";
import { Space } from "antd";

const BookingAPI = new BookingSystemApi();

const CurrentBooking = ({ children }) => {
  return (
    <div className="canvasTableBookingInfo__currentBooking">
      {React.Children.map(children, (child) => child)}
    </div>
  );
};

const CurrentBookingHeader = ({ currentBooking, switchMode }) => {
  const { t } = useTranslation("bookingSystem");
  const endTime = useMemo(() => {
    return moment(currentBooking.booking_datetime)
      .add(currentBooking.original_dining_time, "m")
      .format("HH:mm");
  }, [currentBooking]);

  return (
    <div
      className={`canvasTableBookingInfo__currentBooking__header bg-${currentBooking.status}`}
    >
      <h3 className="currentBooking__header__title">
        {currentBooking.time} - {endTime}
      </h3>
      {currentBooking.status !== "finish" && (
        <button onClick={() => switchMode(2, currentBooking)}>
          {t(`status.${currentBooking.status}`)}
        </button>
      )}
    </div>
  );
};

const CurrentBookingBody = ({
  currentBooking,
  systemMode,
  swapMode,
  swapMoreSeatMode,
}) => {
  const { t, i18n } = useTranslation("bookingSystem");
  const { BaseSettingState } = useContext(BaseSettingContext);
  const { customQ } = BaseSettingState;

  const { editBooking, updateBookingAndSetting } =
    useContext(CommomSettingContext);

  const customQAnsMemo = useMemo(() => {
    return customQAns(customQ, currentBooking["customized_questions"]);
  }, [customQ, currentBooking]);

  const renderCurrentBookingSource = () => {
    if (currentBooking.source === "online") {
      if (currentBooking.source_url_t === "google") {
        return <span className="bookingSourceIcon bookingSourceIcon-google" />;
      } else if (currentBooking.source_url_t === "facebook") {
        return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
      } else if (currentBooking.source_url_t === "instagram") {
        return (
          <span className="bookingSourceIcon bookingSourceIcon-instagram" />
        );
      } else if (currentBooking.source_url_t === "menuApp") {
        return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
      } else if (currentBooking.source_url_t === "other") {
        return <span className="bookingSourceIcon bookingSourceIcon-other" />;
      }
    }

    if (
      currentBooking.source === "google_booking" &&
      currentBooking.source_url_t === "google_reservation"
    ) {
      return (
        <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />
      );
    }
  };

  const renderOnsiteTag = () => {
    if (currentBooking.on_site) {
      return <span className="icon-md onsite-icon" />;
    }
  };

  const renderBookingStatusTime = useCallback(() => {
    let statusTime = JSON.parse(currentBooking.status_time);
    const { status } = currentBooking;
    const time = statusTime[currentBooking.status].slice(-8, -3);
    const space = i18n.language === "en" ? " " : "";

    if (status === "show") {
      return (
        <span className="stautsBtnTime">
          {time}
          {space}
          {t("newModule.checkedIn")}
        </span>
      );
    } else if (status === "seated") {
      return (
        <span className="stautsBtnTime">
          {time}
          {space}
          {t("buttonStatus.seated")}
        </span>
      );
    } else if (status === "finish") {
      const seatedTime = statusTime["seated"].slice(-8, -3);
      const seatedTimeTxt =
        seatedTime.length !== 0
          ? `${seatedTime}${t("buttonStatus.seated")} - `
          : "";
      return (
        <span className="stautsBtnTime">
          {seatedTimeTxt}
          {time}
          {space}
          {t("item.exitTime")}
        </span>
      );
    }
  }, [currentBooking, t, i18n.language]);

  const renderSeats = () => {
    if (swapMode || swapMoreSeatMode || systemMode === "timeline") {
      return (
        <ul className="swapSeatWrap">
          {currentBooking.seats.map((seat) => {
            return <li key={seat.table_number}>{seat.table_number}</li>;
          })}
        </ul>
      );
    }
  };

  const renderShopMemo = () => {
    if (currentBooking.shop_memo && currentBooking.shop_memo.trim() !== null) {
      return (
        <p className="currentBooking__body__shopMemo">
          {currentBooking.shop_memo}
        </p>
      );
    }
    return null;
  };

  const renderCustomerMemo = () => {
    if (currentBooking.memo !== null) {
      return (
        <p className="currentBooking__body__customerMemo">
          {currentBooking.memo}
        </p>
      );
    }

    return null;
  };

  return (
    <div className="canvasTableBookingInfo__currentBooking__body">
      <div className="currentBooking__info">
        <Space className="currentBooking__time" size={5}>
          {renderCurrentBookingSource()}
          {renderOnsiteTag()}
          {renderBookingStatusTime()}
          <PassedDiningTime
            systemMode={systemMode}
            currentBooking={currentBooking}
          />
        </Space>
        <div className="info__top">
          <div className="info__top__name">
            <Space size={2} align="center">
              <BookingCustomerTags
                padding={2}
                isBlocked={currentBooking.customer_info.blacklisted}
                note={currentBooking.customer_info.note}
                tags={currentBooking.customer_info.tags}
              />
              <Space size={8} align="end">
                <Space size={2} align="end">
                  {i18n.language !== "zh" &&
                    currentBooking.gender !== "other" && (
                      <span className="info__top__gender">
                        {t(`item.${currentBooking.gender}`)}
                      </span>
                    )}
                  <span className="name">{currentBooking.last_name}</span>
                  {i18n.language === "zh" &&
                    currentBooking.gender !== "other" && (
                      <span className="info__top__gender">
                        {t(`item.${currentBooking.gender}`)}
                      </span>
                    )}
                </Space>
                <button
                  className="info__top__edit"
                  onClick={() => editBooking(currentBooking)}
                >
                  <div className="icon-normal edit-pen-icon" />
                </button>
              </Space>
            </Space>
            <p className="info__top__tel">{currentBooking.phone_number}</p>
          </div>
          <Space size={4}>
            <span className="info__top__attendance">
              {currentBooking.attendance}
            </span>
            <span className="icon-md account-icon" />
          </Space>
        </div>
        {renderSeats()}
        {renderShopMemo()}
        {renderCustomerMemo()}
        <div className="currentBooking__body__customerTag">
          <ul>
            {customQAnsMemo.serviceTagBlock.map((tag, index) => {
              return <li key={index}>{tag}</li>;
            })}
            {customQAnsMemo.quantityBlock.map((ele, index) => {
              return (
                <li key={index}>
                  {ele.name}x{ele.value}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="currentBooking__body__noteWRap">
          <ul>
            {customQAnsMemo.questionsBlock.map((q, index) => {
              return <li key={index}>{q}</li>;
            })}
          </ul>
        </div>
      </div>
      <DudooSyncDisplay
        mode="timeline"
        booking={currentBooking}
        updateData={updateBookingAndSetting}
      />
    </div>
  );
};

const CurrentBookingFooter = ({
  currentBooking,
  systemMode,
  swithcStatus,
  swapMode = false,
  swapMoreSeatMode,
  startSwapSeat = () => {},
  checkConflict = () => {},
  startSwapMoreSeat = () => {},
}) => {
  const { t } = useTranslation("bookingSystem");
  const { CommomSettingState } = useContext(CommomSettingContext);
  const { isToday } = CommomSettingState;

  const renderSwapBtn = () => {
    if (systemMode !== "timeline") {
      if (!swapMode && !swapMoreSeatMode) {
        //開始交換模式
        return (
          <Space size={4}>
            <button
              className="btn-swap switchBtn"
              onClick={() => startSwapSeat(currentBooking)}
            >
              {t("buttonStatus.change")}
            </button>
            <button
              className="btn-swap swapBtn"
              onClick={() => startSwapMoreSeat(currentBooking)}
            >
              {t("buttonStatus.select")}
            </button>
          </Space>
        );
      } else if (swapMode) {
        //交換模式
        return (
          <SwapNSeatBtn
            checkConflict={checkConflict}
            beSwappedInfo={currentBooking}
          />
        );
      }
    }
  };

  return (
    <div className="canvasTableBookingInfo__currentBooking__footer">
      {renderSwapBtn()}
      {isToday && !swapMode && !swapMoreSeatMode && (
        <StatusBtn
          currentBooking={currentBooking}
          swithcStatus={swithcStatus}
          systemMode={systemMode}
        />
      )}
    </div>
  );
};

const SwapNSeatBtn = ({ checkConflict, beSwappedInfo = {} }) => {
  const { t } = useTranslation("bookingSystem");
  return (
    <Space size={4} className="bookingStatusBtnWrap">
      <button
        className="seatedBtn"
        onClick={() => checkConflict("seated", beSwappedInfo)}
      >
        {t("buttonStatus.move")}
      </button>
      <button
        className="switchBtn"
        onClick={() => checkConflict("swap", beSwappedInfo)}
      >
        {t("buttonStatus.exchange")}
      </button>
    </Space>
  );
};

const StatusBtn = ({ systemMode, currentBooking, swithcStatus }) => {
  const [currentTime, setCurrentTime] = useState(moment());
  const [intervalTime, setIntervalTime] = useState(
    systemMode === "timeline" ? 1000 : null
  );
  const today = moment().format("YYYY/MM/DD");
  const pre = moment(new Date(currentBooking.booking_datetime))
    .add(-60, "m")
    .format("YYYY/MM/DD HH:mm");
  const tomorrow = moment(new Date(today))
    .add(1, "d")
    .format("YYYY/MM/DD HH:mm");
  const [hideStatusBtn, setHideStatusBtn] = useState(true);

  const findStatus = useCallback(() => {
    if (currentTime.isBefore(new Date(pre))) {
      //當天時間>預約60分鐘
      // console.log('----預約>60分鐘----');
      setHideStatusBtn(true);
    } else if (
      currentTime.isBetween(new Date(pre), new Date(tomorrow), null, "[)")
    ) {
      //當天時間<=預約60分鐘
      // console.log('----預約前60分鐘----');
      setHideStatusBtn(false);
    }
  }, [pre, tomorrow, currentTime]);

  useEffect(() => {
    if (systemMode === "timeline") {
      setIntervalTime(1000);
      findStatus();
    }
  }, [systemMode, findStatus]);

  useInterval(() => {
    setCurrentTime(moment());
  }, intervalTime);

  const renderStatusBtn = () => {
    if (systemMode !== "timeline") {
      return (
        <div className="bookingStatusBtnWrap">
          {
            <ShortCutStatusBtn
              mode="tablemap"
              booking={currentBooking}
              swithcStatus={swithcStatus}
            />
          }
        </div>
      );
    } else if (
      systemMode === "timeline" &&
      currentBooking.status !== "finish"
    ) {
      if (!hideStatusBtn) {
        return (
          <div className="bookingStatusBtnWrap">
            {
              <ShortCutStatusBtn
                mode="timeline"
                booking={currentBooking}
                swithcStatus={swithcStatus}
              />
            }
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return renderStatusBtn();
};

const ShortCutStatusBtn = ({ mode, booking, swithcStatus }) => {
  const { t } = useTranslation("bookingSystem");
  const [showWarning, setShowWarning] = useState(false);
  const [warningTales, setWarningTales] = useState([]);

  const seatedWarning = useCallback(() => {
    BookingAPI.checkSeated(booking.id).then((data) => {
      if (data.overlay_table.length !== 0) {
        setShowWarning(true);
        setWarningTales(data.overlay_table);
      } else {
        swithcStatus(booking, "seated");
      }
    });
  }, [booking, swithcStatus]);

  const cancelWarning = () => {
    setShowWarning(false);
    setWarningTales([]);
  };

  const confirmWarning = () => {
    swithcStatus(booking, "seated");
    setShowWarning(false);
    setWarningTales([]);
  };

  const renderBtn = () => {
    switch (booking.status) {
      case "confirmed":
        return (
          <Space size={8}>
            <button
              className="currentBooking__statusBtn currentBooking__statusBtn-noshow"
              onClick={() => swithcStatus(booking, "no_show")}
            >
              {t("buttonStatus.no_show_2")}
            </button>
            {mode === "timeline" && (
              <button
                className="currentBooking__statusBtn currentBooking__statusBtn-show"
                onClick={() => swithcStatus(booking, "show")}
              >
                {t("buttonStatus.show")}
              </button>
            )}
            <button
              className="currentBooking__statusBtn currentBooking__statusBtn-seated"
              onClick={() => seatedWarning()}
            >
              {t("buttonStatus.seated")}
            </button>
          </Space>
        );
      case "show":
        return (
          <React.Fragment>
            <button
              className="currentBooking__statusBtn currentBooking__statusBtn-seated"
              onClick={() => seatedWarning()}
            >
              {t("buttonStatus.seated")}
            </button>
          </React.Fragment>
        );

      case "seated":
        return (
          <React.Fragment>
            <button
              className="currentBooking__statusBtn currentBooking__statusBtn-finish"
              onClick={() => swithcStatus(booking, "finish")}
            >
              {t("buttonStatus.finish")}
            </button>
          </React.Fragment>
        );

      default:
        return (
          <Space size={8}>
            <button
              className="currentBooking__statusBtn currentBooking__statusBtn-cancel"
              onClick={() => swithcStatus(booking, "cancel")}
            >
              {t("buttonStatus.cancel")}
            </button>
            <button
              className="currentBooking__statusBtn currentBooking__statusBtn-confirmed"
              onClick={() => swithcStatus(booking, "confirmed")}
            >
              {t("buttonStatus.confirmed")}
            </button>
          </Space>
        );
    }
  };

  return (
    <>
      {renderBtn()}
      {showWarning && (
        <CheckModal
          title={t("popup.overlapping_title")}
          content={t("popup.overlapping_content", {
            tables: warningTales.join("、"),
          })}
          submit={confirmWarning}
          cancel={cancelWarning}
        />
      )}
    </>
  );
};

const PassedDiningTime = ({ currentBooking, systemMode }) => {
  const { t } = useTranslation("bookingSystem");
  const { CommomSettingState } = useContext(CommomSettingContext);
  const { date, moduleSelectedBookingTime, moduleTimePaused } =
    CommomSettingState;

  const [intervalTime, setIntervalTime] = useState(1000);
  const [currentTimestamp, setCurrentTimestamp] = useState(moment());
  const [serviceStartTime, setServiceStartTime] = useState(
    moment(currentBooking.service_start_time)
  );
  const [diningTime, setDiningTime] = useState(
    JSON.parse(currentBooking.dining_time)
  );
  const [duration, setDuration] = useState(
    moment.duration(currentTimestamp.diff(serviceStartTime))
  );
  const [durationHour, setDurationHour] = useState(duration.hours());
  const [durationMin, setDurationMin] = useState(duration.minutes());
  const [durationDiff, setDurationDiff] = useState(duration.asMinutes()); //已用餐幾分鐘

  let showDiningTimeBool = false;

  if (currentTimestamp >= serviceStartTime && durationDiff <= diningTime) {
    showDiningTimeBool = true;
  }

  const [showDiningTime, setShowDiningTime] = useState(showDiningTimeBool);

  useEffect(() => {
    const newServiceStartTime = moment(currentBooking.service_start_time);
    const newDiningTime = JSON.parse(currentBooking.dining_time);
    const newDuration = moment.duration(moment().diff(newServiceStartTime));
    const newDurationDiff = newDuration.asMinutes();

    let showDiningTimeBool = false;

    if (moment() >= newServiceStartTime && newDurationDiff <= newDiningTime) {
      showDiningTimeBool = true;
    }

    setServiceStartTime(newServiceStartTime);
    setDiningTime(newDiningTime);
    setShowDiningTime(showDiningTimeBool);
  }, [currentBooking]);

  useEffect(() => {
    if (systemMode === "tablemap") {
      if (!moduleTimePaused) {
        setIntervalTime(1000);
      } else if (moduleTimePaused) {
        setCurrentTimestamp(
          moment(new Date(date + " " + time[moduleSelectedBookingTime]))
        );
        setIntervalTime(null);
      }
    }
  }, [moduleSelectedBookingTime, date, moduleTimePaused, systemMode]);

  useEffect(() => {
    if (currentTimestamp < serviceStartTime) {
      //尚未到預約時間不顯示用餐時間
      setShowDiningTime(false);
      setIntervalTime(null);
      return;
    } else {
      if (durationDiff > diningTime && showDiningTime) {
        //超過用餐時間不顯示也不計時
        setIntervalTime(null);
        setShowDiningTime(false);
        return;
      }

      const durationCount = moment.duration(
        currentTimestamp.diff(serviceStartTime)
      );
      const durationHourCount = durationCount.hours(),
        durationMinCount = durationCount.minutes(),
        durationDiffCount = durationCount.asMinutes(); //已用餐幾分鐘

      setDuration(durationCount);
      setDurationHour(durationHourCount);
      setDurationMin(durationMinCount);
      setDurationDiff(durationDiffCount);

      if (durationDiff <= diningTime && !showDiningTime) {
        //尚未超過用餐時間
        setShowDiningTime(true);
      }
    }
  }, [
    currentTimestamp,
    diningTime,
    durationDiff,
    intervalTime,
    moduleTimePaused,
    serviceStartTime,
    showDiningTime,
    systemMode,
  ]);

  useInterval(() => {
    setCurrentTimestamp(moment());
  }, intervalTime);

  return (
    <div>
      {showDiningTime && currentBooking.status !== "finish" && (
        <p className={`currentBooking__diningTime`}>
          {t("newModule.elapsedTime", { durationHour, durationMin })}
        </p>
      )}
    </div>
  );
};

CurrentBooking.Header = CurrentBookingHeader;
CurrentBooking.Body = CurrentBookingBody;
CurrentBooking.Footer = CurrentBookingFooter;

export default CurrentBooking;
