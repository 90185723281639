import React from "react";
import { Link } from "react-router";
import { useTranslation } from 'react-i18next';

const CustomerIcon = () => {
	const { t } = useTranslation();
  return (
    <div className="dashboard-item">
      <Link to="/dashboard/customers" role="button">
        <div className="dashboard-thumbnail">
          <img
            alt="customer"
            src={require("../../images/dashboard/customers.svg")}
          />
          <div className="dashboard-system-description text-body">
            {t('dashboard:crmDescription')}
          </div>
          <div className="horizontal-between">
            <h3 className="heading-3-responsive">{t('common:system.customer')}</h3>
            <div className="icon-normal doubleArrowRight-icon" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CustomerIcon;
