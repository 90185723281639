import React, { useCallback, useContext } from "react";
import moment from "moment";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../../crmContext/MessageContext";

const PreviewMessagePopup = () => {
  const { t, i18n } = useTranslation("messages");
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { previewInfo } = MessageState;

  const renderSentDateTime = () => {
    const dateTime =
      i18n.language === "zh"
        ? moment(new Date(previewInfo.schedule_time)).format(
            "YYYY年MM月DD日 HH:mm:ss"
          )
        : moment(new Date(previewInfo.schedule_time)).format(
            "YYYY.MM.DD HH:mm:ss"
          );
    return dateTime;
  };

  const renderCounts = () => {
    if (previewInfo.status === "finished") {
      return previewInfo.sending_counts;
    } else if (
      previewInfo.status === "real_time" ||
      previewInfo.status === "scheduled"
    ) {
      return previewInfo.estimated_sending_counts;
    }
  };

  const renderPoints = () => {
    if (previewInfo.status === "finished") {
      return previewInfo.consumed_msg_points;
    } else if (
      previewInfo.status === "real_time" ||
      previewInfo.status === "scheduled"
    ) {
      return previewInfo.estimated_msg_points;
    }
  };

  const cancelPopup = useCallback(() => {
    MessageStateDispatch({ type: "cancelPopup" });
  }, [MessageStateDispatch]);

  return (
    <Modal
      title={previewInfo.title}
      visible
      centered
      destroyOnClose
      width={423}
      className="modal-base modal-sm previewMessagePopup"
      onCancel={cancelPopup}
      footer={null}
      transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className="content">{previewInfo.content}</div>
      <hr />
      <div className="block">
        {previewInfo.schedule_time && (
          <div className="text">
            {t("message.dateSent")}
            {renderSentDateTime()}
          </div>
        )}
        {previewInfo.filter_tag !== "" && (
          <div className="text">
            {t("message.audience")}
            {previewInfo.filter_tag}
          </div>
        )}
        <div className="tags">
          <div className="tag type">{t("list.sms")}</div>
          <div className="tag">
            {t("message.count", { count: renderCounts() })}
          </div>
          <div className="tag">
            {t("message.point", { point: renderPoints() })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewMessagePopup;
