import React, { useState, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";
import { ListStateContext } from "../NewModuleReducer/ListReducer";
import CheckModal from "../../popup/CheckModal";

const QueueActionButton = ({ updateQueueSystem, setShowQueueGroupPanel }) => {
  const { t } = useTranslation("queueSystem");
  const { ListReducerState } = useContext(ListStateContext);

  const { queueGroup, queueState } = ListReducerState;

  const { CommomSettingState } = useContext(CommomSettingContext);
  const { isToday } = CommomSettingState;

  const [showEndQueue, setShowEndQueue] = useState(false);
  const [showPauseQueue, setShowPauseQueue] = useState(false);

  const showWarning = (state) => {
    if (state === "end") {
      setShowEndQueue(true);
    } else if (state === "pause") {
      setShowPauseQueue(true);
    }
  };

  const confirmWarning = (state) => {
    updateQueueSystem(state);

    if (state === "end") {
      setShowEndQueue(false);
      setShowQueueGroupPanel(false);
    } else if (state === "pause") {
      setShowPauseQueue(false);
    }
  };

  const cancelWarning = (state) => {
    if (state === "end") {
      setShowEndQueue(false);
    } else if (state === "pause") {
      setShowPauseQueue(false);
    }
  };

  const renderActionButton = () => {
    //當日才顯示按鈕且有設定排隊組別
    if (isToday && queueGroup.length !== 0) {
      if (queueState === "normal") {
        return (
          <div className="queueBtnWrap">
            <button
              className="queueBtn-finish"
              onClick={() => showWarning("end")}
            >
              <i className="glyphicon glyphicon-stop" />{" "}
              {t("panel.closeQueuePeriod_2")}
            </button>
            <button
              className="queueBtn-pause"
              onClick={() => showWarning("pause")}
            >
              <i className="glyphicon glyphicon-pause" />{" "}
              {t("popup.pauseQueue")}
            </button>
          </div>
        );
      } else if (queueState === "pause") {
        return (
          <div className="queueBtnWrap">
            <button
              className="queueBtn-finish"
              onClick={() => showWarning("end")}
            >
              <i className="glyphicon glyphicon-stop" />{" "}
              {t("panel.closeQueuePeriod_2")}
            </button>
            <button
              className="queueBtn-pause"
              onClick={() => updateQueueSystem("start")}
            >
              <i className="glyphicon glyphicon-play" />{" "}
              {t("panel.restartQueuePeriod")}
            </button>
          </div>
        );
      }
    }
  };

  return (
    <Fragment>
      {renderActionButton()}

      {/* 結束排隊警告 */}
      {showEndQueue && (
        <CheckModal
          title={t("popup.closeQueuePeriod")}
          content={t("popup.closeQueuePeriod_content")}
          submit={() => confirmWarning("end")}
          cancel={() => cancelWarning("end")}
        />
      )}

      {/* 暫停排隊警告 */}
      {showPauseQueue && (
        <CheckModal
          title={t("popup.pauseQueue")}
          content={t("popup.pauseQueue_content")}
          submit={() => confirmWarning("pause")}
          cancel={() => cancelWarning("pause")}
        />
      )}
    </Fragment>
  );
};

export default QueueActionButton;
