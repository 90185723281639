import React, { useEffect, useState, forwardRef } from "react";
import { DIGITS_ONLY_REGEX } from "../../utils/regex"

const ExpandSearchInput = forwardRef(
  (
    {
      disabled,
      handleInput = () => {},
      handlePress,
      placeholder,
      setIsComposing = () => {},
      controlled = false,
      isExpandControlled,
      onExpandChange,
      clearResult = () => {},
      digitalOnly = false
    },
    ref
  ) => {
    const [isExpandInternal, setIsExpandInternal] = useState(false);
    const [hasValue, setHasValue] = useState(false);

    const isExpand = controlled ? isExpandControlled : isExpandInternal;

    useEffect(() => {
      if (isExpand && ref.current) {
        ref.current.focus();
      }
    }, [isExpand, ref]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!ref.current) return;

        const isClickOnInput = ref.current.contains(event.target);
        const isClickOnButton = event.target.closest(".expandSearchInput");
        const isClickOnMenu =
          event.target.classList.contains("select_option") ||
          event.target.classList.contains("result_option");

        if (!isClickOnInput && !isClickOnMenu && !isClickOnButton && !hasValue) {
          if (controlled) {
            onExpandChange(false);
          } else {
            setIsExpandInternal(false);
          }
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [controlled, onExpandChange, ref, hasValue]);

    const expandInput = (e) => {
      e.stopPropagation();

      if (controlled) {
        onExpandChange(true);
      } else {
        setIsExpandInternal(true);
      }
    };

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      
      if (digitalOnly) {
        if (DIGITS_ONLY_REGEX.test(inputValue) || inputValue === '') {
          if (ref.current) {
            setHasValue(inputValue.length > 0);
          }
          handleInput(e);
        } else {
          e.preventDefault();
          if (ref.current) {
            const onlyDigits = inputValue.replace(/\D/g, '');
            ref.current.value = onlyDigits;
            setHasValue(onlyDigits.length > 0);
            const newEvent = {
              ...e,
              target: { ...e.target, value: onlyDigits }
            };
            handleInput(newEvent);
          }
        }
      } else {
        if (ref.current) {
          setHasValue(inputValue.length > 0);
        }
        handleInput(e);
      }
    };

    const clearInput = () => {
      if (ref.current) {
        ref.current.value = "";
        setHasValue(false);
        clearResult();
        setIsExpandInternal(false);
      }
    };

    return (
      <div className="expandSearchInput">
        {isExpand ? (
          <div className="search-input">
            <div className="search-icon icon-normal" />
            <input
              ref={ref}
              type="text"
              onChange={handleInputChange}
              onKeyDown={handlePress}
              placeholder={placeholder}
              onCompositionStart={() => setIsComposing(true)}
              onCompositionEnd={() => setIsComposing(false)}
            />
            {hasValue && (
              <div
                className="search-cancel-icon icon-sm"
                onClick={clearInput}
              />
            )}
          </div>
        ) : (
          <button disabled={disabled} onClick={expandInput}>
            <div
              className={`search-icon icon-normal ${
                disabled ? "disabled" : ""
              }`}
            />
          </button>
        )}
      </div>
    );
  }
);

export default ExpandSearchInput;
