import React, { useEffect } from 'react';
import { Link, browserHistory } from "react-router";
import PageNav from './PageNav';
import PageFooter from './PageFooter';

import '../../sass/pageContact.scss';

const PageDoneNew = (props) => {
  useEffect(() => {
    if(!props.location.state) toContact();
  }, [props.location.state]);

  const toContact = () => {
    browserHistory.push('/contact');
  };

  return (
    <div>
      <PageNav />
      <div className="contact contact_success">
        <div className="text-center">
          <h1>已收到您的申請</h1>
          <h1>
            感謝您選擇
            <img src={require("../../images/pageIdx/logo2_r.png")} alt="" />
          </h1>
          <p>我們將於 3 個工作日內與您聯繫，<br/>敬請留意陌生訊息。</p>
          <Link to="/">
            <button className="btn-submit">返回首頁</button>
          </Link>
        </div>
      </div>
      <PageFooter />
    </div>
  )
}

export default PageDoneNew;