import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Space } from "antd";

import {
  handleWebsocketEventRegistry,
  cancelWebsocketSubscription,
} from "../../libs/handler";
import CustomerIcon from "../icons/Customer";
import QueueIcon from "../icons/Queue";
import BookingIcon from "../icons/Booking";
import CouponIcon from "../icons/Coupon";
import PushIcon from "../icons/Push";
import AnnouncementIcon from "../icons/Announcement";

import OfficialAnnouncementModal from "../announcement/announcementPopup/AnnouncementOfficialModal";
import PreviewAnnouncementModal from "../announcement/announcementPopup/AnnouncementPreviewModal";
import DashboardAnnouncementList from "../announcement/DashboardAnnouncementList";

import {
  announcementOfficialFetchToday,
  updateDayAnnouncements,
} from "../../actions/announcementAction";
import { getDisplayOfficialAnnouncements } from "../announcement/api/OfficialAnnouncementApi";

const DashboardBox = () => {
  const { t, i18n } = useTranslation("dashboard");
  const auth = useSelector((state) => state.auth);
  const { shop_name } = auth.user;
  const { display_name, branch } = auth.shop;

  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const { showPreviewAnnouncementModal } = announcementInfo;

  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const { todayOfficialAnnouncements, dayAnnouncements } =
    announcementListReducer;

  const dispatch = useDispatch();

  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [displayOfficialAnnouncements, setDisplayOfficialAnnouncements] =
    useState([]);
  const [language, setLanguage] = useState(i18n.language);
  const [totalAnnouncementCount, setTotalAnnouncementCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.setItem("userId", shop_name);
  }, [shop_name]);

  useEffect(() => {
    getOfficialAnnouncementsDisplay();
  }, []);

  useEffect(() => {
    dispatch(updateDayAnnouncements());
    dispatch(announcementOfficialFetchToday());
  }, [dispatch]);

  useEffect(() => {
    const count = todayOfficialAnnouncements.length + dayAnnouncements.length;

    setTotalAnnouncementCount(count);
  }, [todayOfficialAnnouncements, dayAnnouncements]);

  useEffect(() => {
    // 公告: 建立/更新/過期/刪除 -> update 今日公告
    handleWebsocketEventRegistry("sync_announcements", () => {
      setTimeout(() => {
        dispatch(updateDayAnnouncements());
      }, 3000);
    });

    return () => {
      cancelWebsocketSubscription(["sync_announcements"]);
    };
  }, [dispatch]);

  const getOfficialAnnouncementsDisplay = async () => {
    try {
      const { data } = await getDisplayOfficialAnnouncements();
      setDisplayOfficialAnnouncements(data);
    } catch (err) {
      console.log("get display official announcements err ----------", err);
    }
  };

  const toggleAnnouncement = () => {
    setShowAnnouncement((prev) => !prev);
  };

  const switchLanguage = () => {
    const newLanguage = language === "zh" ? "en" : "zh";
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("userLanguage", newLanguage);
  };

  return (
    <div className="system-layout dashboard-bg">
      <div className="dashboard-title">
        <h2 className="heading-2-responsive">
          {t("hi")}
          <Space direction="vertical" size={4}>
            {display_name}
            <h3 className="heading-3-responsive">{branch}</h3>
          </Space>
        </h2>
      </div>
      <div className="dashboard-subtitle">
        <h2 className="heading-2-responsive">{t("welcome")}</h2>
        <img
          src={require("../../images/navbar/MENUshop_logo_dark.png")}
          alt=""
        />
      </div>
      <div className="dashboard-switch" onClick={switchLanguage}>
        <div className="text-body">{t("switchLanguage")}</div>
        <div className="arrowRight-icon-dark" />
      </div>
      <Space
        className="dashboard-container"
        size={[96, 24]}
        align="center"
        wrap
      >
        <BookingIcon key="booking" />
        <QueueIcon key="queue" />
        <CustomerIcon key="customer" />
        <CouponIcon key="coupon" />
        <AnnouncementIcon key="announcement" />
        <PushIcon key="push" />
      </Space>

      <button
        className={`btn-announcement ${
          totalAnnouncementCount === 0 ? "isEmpty" : ""
        }`}
        onClick={toggleAnnouncement}
      >
        <div className="announcement-icon" />
      </button>

      {showAnnouncement && (
        <DashboardAnnouncementList count={totalAnnouncementCount} toggleAnnouncement={toggleAnnouncement} />
      )}

      {displayOfficialAnnouncements.length > 0 && (
        <OfficialAnnouncementModal
          key={displayOfficialAnnouncements[0].id}
          item={displayOfficialAnnouncements[0]}
          displayOfficialAnnouncements={displayOfficialAnnouncements}
          setDisplayOfficialAnnouncements={setDisplayOfficialAnnouncements}
        />
      )}
      {showPreviewAnnouncementModal && (
        <PreviewAnnouncementModal isDashboard={true} />
      )}
    </div>
  );
};

export default DashboardBox;
