import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
  useRef,
} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import withScrolling from "react-dnd-scrolling";
import { usePreview } from "react-dnd-preview";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import $ from "jquery";
import { useTranslation, Trans } from "react-i18next";
import { Space } from "antd";

import CanvasTableBookingInfo from "./BookingInfo/CanvasTableBookingInfo";
import TimelineSeat from "./TimelineQueue/TimelineSeate";
import TimelineBookingRow from "./Timeline/BookingRow";
import { TimelineWarningTemplate } from "./NewModuleComponent/NewModuleWarning";
import { SeatedSuccess } from "./NewModuleComponent/SelectedTableContainer";
import { timelineTime, useInterval } from "./utils/data";
import { swapReorder, SeatedReorder } from "./utils/timelineReorder";
import { Portal } from "./utils/Portal";

//reducer
import { BaseSettingContext } from "./NewModuleReducer/BaseSettingReducer";
import { CommomSettingContext } from "./NewModuleReducer/CommomReuducer";
import { ListStateContext } from "./NewModuleReducer/ListReducer";

//hook
import useSubmitSwapBooking from "./hooks/useSubmitSwapBooking";

const headerHeight = 68,
  actionBarHeight = 48,
  gapHeight = 12,
  timelineTimeCellHeight = 30;

const TimelineTotalHeight =
  headerHeight + actionBarHeight + timelineTimeCellHeight + gapHeight;

const $totalHeight = timelineTimeCellHeight;

const scale = 48;

const statusColor = {
  confirmed: "#3fba88",
  unconfirmed: "#bad959",
  show: "#42a0a4",
  seated: "#27496e",
  noShow: "#676767",
  cancel: "#c8c8c8",
  finish: "#664c77",
};

const bookingInfos = {
  currentBooking: [],
  nextBooking: [],
};

const ScrollingComponent = withScrolling("div");

// const options = navigator.maxTouchPoints === 0 ? {}:{delayTouchStart: 300}

const NewModuleTimeline = ({
	timelineTables,
	closeTimelineSeat,
	updateAfterSeated,
	showTimelineSeat,
	newModuleBooking,
	handleClickSeatAddBooking
}) => {
  const { t } = useTranslation("bookingSystem");
  const { BaseSettingState } = useContext(BaseSettingContext);
  const { diningTime } = BaseSettingState;

  const { CommomSettingState, setUuid } = useContext(CommomSettingContext);
  const { date, isToday, queueSeatedData } = CommomSettingState;

  const { ListReducerState } = useContext(ListStateContext);
  const { originBookings } = ListReducerState;

  const [tables, setTables] = useState(timelineTables);
  const [groupTables, setGroupTables] = useState([]);
  const [showCanvasTableBookingInfo, setShowCanvasTableBookingInfo] =
    useState(false);
  const [bookingListInfoBookings, setBookingListInfoBookings] =
    useState(bookingInfos);
  const [selectBookings, setSelectBookings] = useState(null); //欲編輯的預約
  const [showSeatedSuccess, setShowSeatedSuccess] = useState(false); //時間軸入座成功提醒
  const [seatedSuccessData, setSeatedSuccessData] = useState(""); //時間軸入座成功資料
  const [firstIn, setFirstIn] = useState(false); //第一次進入timeline 移到現在時間
  const [droppedTable, setDroppedTable] = useState({}); //變更後的位置
  const [dragBooking, setDragBooking] = useState({}); //欲變更的預約
  const [dragTableName, setDragTableName] = useState(""); //欲變更的桌名稱
  const [dragBookingIndex, setDragBookingIndex] = useState(null); //變更的timeline booking index
  const [dropBookingIndex, setDropBookingIndex] = useState(null); //被交換的timeline booking index
  const [swappedBooking, setSwappedBooking] = useState({}); //被交換的預約
  const [showSwapWarning, setShowSwapWarning] = useState(false); //交換位置警告
  const [showSeatedWarning, setShowSeatedWarning] = useState(false); //入座警告
  const [todayIn, setTodayIn] = useState(false);
  const dateRef = useRef(null);

  const submitSwapBooking = useSubmitSwapBooking(newModuleBooking);

  const ScrollLeftDisMemo = useMemo(() => {
    let leftDis = 0,
      topDis = 0;

    const filterBookings = originBookings.filter(
      (booking) => booking.status !== "no_show" && booking.status !== "cancel"
    );

    if (filterBookings.length !== 0 && tables.length !== 0) {
      const bookingCellHeight = 50;

      if (dateRef.current !== null && dateRef.current !== date)
        setFirstIn(true);
      if (dateRef.current !== null && dateRef.current === date)
        setFirstIn(false);

      const time = moment(new Date(filterBookings[0]["service_start_time"]));
      leftDis =
        moment.duration(time.diff(new Date(date))).asHours() * 2 * scale;

      const { seating_json } = filterBookings[0];
      const seating = JSON.parse(seating_json);
      const newTables = _.cloneDeep(tables).map((table) => table.table_number);
      const idxs = [];
      seating.forEach((seat) => {
        const findIndex = newTables.indexOf(seat.table_number);
        idxs.push(findIndex);
      });
      const minIndex = Math.min(...idxs);
      topDis = minIndex * bookingCellHeight;
    }

    return { leftDis, topDis };
  }, [originBookings, tables, date]);

  useEffect(() => {
    let sortGroupTablesArray = [];

    timelineTables.forEach((t) => {
      const group = t.group;

      //find group in sortGroupTablesArray
      const groupIndex = sortGroupTablesArray
        .map((groupName) => groupName.group)
        .indexOf(group);

      if (groupIndex === -1) {
        const groupTable = {
          group,
          tables: [],
        };
        groupTable.tables.push(t);
        sortGroupTablesArray.push(groupTable);
      } else {
        const targetGroup = sortGroupTablesArray[groupIndex];
        targetGroup.tables.push(t);
      }
    });

    setGroupTables(sortGroupTablesArray);
  }, [timelineTables]);

  const bookingMap = useMemo(() => {
    const map = new Map();
    timelineTables.forEach((table) => {
      table.timeline.forEach((booking) => {
        map.set(booking.id, booking);
      });
    });
    return map;
  }, [timelineTables]);

  useEffect(() => {
    if (selectBookings) {
      const specificBooking = bookingMap.get(selectBookings.id);

      if (specificBooking) {
        setBookingListInfoBookings((prev) => ({
          ...prev,
          nextBooking: [],
          currentBooking: [specificBooking],
        }));
      }
    }
  }, [bookingMap, selectBookings]);

  useEffect(() => {
    setShowCanvasTableBookingInfo(false);
  }, [date]);

  useEffect(() => {
    setTables(timelineTables);

    if (!isToday && timelineTables.length !== 0 && dateRef.current !== date) {
      setFirstIn(true);
    }

    if (isToday && timelineTables.length !== 0 && dateRef.current !== date) {
      setTodayIn(true);
      dateRef.current = null;
    }
  }, [date, isToday, timelineTables]);

  useEffect(() => {
    const $timelineBooking = $(".timeline__right__booking");

    if (isToday && todayIn && dateRef.current !== date) {
      const currentTimeLeft =
        moment.duration(moment().format("HH:mm")).asHours() * 2 * scale + scale;
      const bottom_witdth = Math.round($timelineBooking.width() / 2);
      $timelineBooking.scrollLeft(currentTimeLeft - bottom_witdth);
      setTodayIn(false);
      dateRef.current = date;
    } else if (!isToday && firstIn) {
      $timelineBooking.scrollLeft(ScrollLeftDisMemo.leftDis);
      $timelineBooking.scrollTop(ScrollLeftDisMemo.topDis);

      if (
        originBookings.length !== 0 &&
        moment(originBookings[0].booking_datetime).format("YYYY/MM/DD") === date
      ) {
        setFirstIn(false);
        dateRef.current = date;
      }
    }
  }, [ScrollLeftDisMemo, originBookings, date, firstIn, isToday, todayIn]);

  useEffect(() => {
    if (showCanvasTableBookingInfo) {
      const bookingInfArrow = document.getElementsByClassName(
        "canvasTableBookingInfo__arrow"
      )[0];
      const bookingStatus = bookingListInfoBookings.currentBooking.status;

      bookingInfArrow.style.backgroundColor = statusColor[bookingStatus];
    }
  }, [showCanvasTableBookingInfo, bookingListInfoBookings]);

  const syncScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const scrollTop = e.target.scrollTop;

    $(".timeline__right__time").scrollLeft(scrollLeft);
    $(".timelineTableGroup").scrollTop(scrollTop);
  };

  const clearTimelineSwapInfo = () => {
    setShowSeatedWarning(false);
    setShowSwapWarning(false);
    setDragBooking({});
    setDragTableName("");
    setDroppedTable({});
    setSwappedBooking({});
    setDragBookingIndex(null);
    setDropBookingIndex(null);
  };

  //入座
  const timelineSeated = useCallback(
    ({ dragBooking, dragTableName, droppedTable }) => {
      const { newTables, newBooking } = SeatedReorder({
        tables,
        dragTableName,
        droppedTable,
        dragBookingIndex,
        dragBooking,
      });

      setTables(newTables);

      //set uuid
      const eventId = uuidv4();
      setUuid(eventId);

      const dataSubmit = {
        seating_json: JSON.stringify(newBooking.seats),
        booking_datetime: moment(newBooking.booking_datetime).format(
          "YYYY/MM/DD HH:mm"
        ),
        date: moment(newBooking.booking_datetime).format("YYYY/MM/DD"),
        event_id: eventId,
      };

      submitSwapBooking(newBooking.id, dataSubmit);
    },
    [dragBookingIndex, submitSwapBooking, tables, setUuid]
  );

  //交換位置
  const timelineSwap = useCallback(
    ({ dragBooking, dragTableName, droppedTable, swappedBooking }) => {
      const { newTables, newDragBooking, newSwappedBooking } = swapReorder({
        tables,
        dragTableName,
        droppedTable,
        dragBookingIndex,
        swappedBooking,
        dropBookingIndex,
        dragBooking,
      });

      setTables(newTables);

      const data = [
        {
          id: newDragBooking.id,
          seats: _.uniqBy(newDragBooking.seats, "table_number"),
          booking_datetime: newDragBooking.booking_datetime,
        },
        {
          id: newSwappedBooking.id,
          seats: _.uniqBy(newSwappedBooking.seats, "table_number"),
          booking_datetime: newSwappedBooking.booking_datetime,
        },
      ];

      //set uuid
      const eventId = uuidv4();
      setUuid(eventId);

      data.forEach((booking) => {
        const dataSubmit = {
          seating_json: JSON.stringify(booking.seats),
          booking_datetime: moment(booking.booking_datetime).format(
            "YYYY/MM/DD HH:mm"
          ),
          date: moment(booking.booking_datetime).format("YYYY/MM/DD"),
          event_id: eventId,
        };

        submitSwapBooking(booking.id, dataSubmit);
      });
    },
    [dragBookingIndex, dropBookingIndex, setUuid, submitSwapBooking, tables]
  );

  const confirmSwapWarning = useCallback(() => {
    // console.log('----確定換位子----');
    timelineSwap({ dragBooking, dragTableName, droppedTable, swappedBooking });
    clearTimelineSwapInfo();
  }, [dragBooking, dragTableName, droppedTable, swappedBooking, timelineSwap]);

  const confirmSeatedWarning = useCallback(() => {
    // console.log('----確定入座----');
    timelineSeated({ dragBooking, dragTableName, droppedTable });
    clearTimelineSwapInfo();
  }, [dragBooking, dragTableName, droppedTable, timelineSeated]);

  const cancelWarning = useCallback(() => {
    // console.log('----取消換位子----');
    clearTimelineSwapInfo();
  }, []);

  const confirmWarning = useCallback(
    (type) => {
      if (type === "seated") confirmSeatedWarning();
      if (type === "swap") confirmSwapWarning();
    },
    [confirmSeatedWarning, confirmSwapWarning]
  );

  const setBookingListInfo = useCallback((e, booking) => {
    // console.log('booking----', booking);
    const newBookingInfos = {
      nextBooking: [],
      currentBooking: [booking],
    };

    setBookingListInfoBookings(newBookingInfos);
    setShowCanvasTableBookingInfo(true);

    const infos = document.getElementsByClassName(
      "canvasTableBookingInfoWrap"
    )[0];
    const bookingCell = e.target,
      bookingCellParent = bookingCell.offsetParent;

    const bookingCellX = bookingCell.offsetLeft,
      bookingCellY = bookingCell.offsetParent.offsetTop;

    if (bookingCellX < 0) {
      infos.style.left = "5px";
      infos.style.top = bookingCellY + 50 + "px";
      infos.classList.remove("arrowRight");
    } else {
      if (bookingCellParent.offsetWidth - bookingCell.offsetLeft < 350) {
        //計算bookingCell是否離最右邊距離超過350
        //false: 顯示在左邊

        infos.style.left = bookingCellX - 310 + "px";
        infos.style.top = bookingCellY + 20 + "px";
        infos.classList.add("arrowRight");
      } else {
        //true: 正常顯示在下方
        infos.classList.remove("arrowRight");

        infos.style.left = bookingCellX + "px";
        infos.style.top = bookingCellY + 50 + "px";
      }
    }
  }, []);

  const closeCanvasTableBookingInfo = useCallback(() => {
    setShowCanvasTableBookingInfo(false);
    setSelectBookings(null);
    $(".canvasTableBookingInfoWrap").removeAttr("style");
  }, []);

  const handleTimelineClick = useCallback((e) => {
    e.stopPropagation();
    // 檢查點擊的是否是時間格子
    if (!e.target.classList.contains("spaceCell")) return;
    
    closeCanvasTableBookingInfo();
  
    const hour = e.target.getAttribute("data-time");
    const index = parseInt(e.target.getAttribute("data-index"), 10);
    const tableIndex = parseInt(e.target.getAttribute("data-table-index"), 10);
    
    const canClick = !showCanvasTableBookingInfo && index < 48;
    if (!canClick || isNaN(tableIndex) || tableIndex < 0 || tableIndex >= tables.length) return;
    
    const tableInfo = tables[tableIndex];
  
    const pickerDropdown = document.querySelector(".ant-picker-dropdown");
    const canTriggerBooking =
      !pickerDropdown ||
      pickerDropdown.classList.contains("ant-picker-dropdown-hidden");
  
    if (canTriggerBooking) {
      handleClickSeatAddBooking(date, hour, tableInfo);
    }
  }, [
    closeCanvasTableBookingInfo,
    showCanvasTableBookingInfo,
    tables,
    date,
    handleClickSeatAddBooking
  ]);

  const renderCurrentTime = useCallback(() => {
    if (isToday) return <CurrentTimeTag />;
  }, [isToday]);

  const renderCurrentTimeWrap = useCallback(() => {
    if (isToday) return <CurrentTimeWrap />;
  }, [isToday]);

  const findDropTableBooking = useCallback(
    ({ tableInfo }) => {
      const newTables = _.cloneDeep(tables);
      const newDragBooking = _.cloneDeep(dragBooking);
      const dropTables = newTables.filter(
        (table) => table.table_number === tableInfo.table_number
      );
      const timelineBookings = _.cloneDeep(dropTables[0].timeline).filter(
        (booking) => booking.date === newDragBooking.date
      ); //過濾非當日的預約

      if (timelineBookings.length === 0) {
        //沒有預約：入座
        setShowSeatedWarning(true);
        return;
      }

      const sameTimeBookingIndex = timelineBookings.findIndex(
        (booking) => booking.time === newDragBooking.time
      );

      if (sameTimeBookingIndex !== -1) {
        //有相同預約時間的預約：入座or交換
        setSwappedBooking(timelineBookings[sameTimeBookingIndex]);
        setDropBookingIndex(sameTimeBookingIndex);
        setShowSwapWarning(true);
        return;
      }

      setShowSeatedWarning(true);
    },
    [dragBooking, tables]
  );

  const DraggingEnd = useCallback(
    ({ tableInfo }) => {
      setDroppedTable(tableInfo);
      findDropTableBooking({ tableInfo });
    },
    [findDropTableBooking]
  );

  const renderTables = useCallback(() => {
    return (
      <div className="timelineTableGroup">
        {groupTables.map((group, idx) => {
          return (
            <div key={idx} className="timelineTableGroup__groupArea">
              <div className="timelineTableGroup__groupName">
                <p>{group.group}</p>
              </div>
              <div className="timelineTableGroup__tables">
                {group.tables.map((table, id) => {
                  return (
                    <div
                      key={id}
                      className="timelineTableGroup__tables__tableLabel"
                    >
                      <p>{table.table_number}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="tableLabel" />
        <div className="tableLabel" />
        <div className="tableLabel" />
      </div>
    );
  }, [groupTables]);

  const renderTimelineTime = useCallback(() => {
    return timelineTime.map((t, index) => {
      return (
        <div key={index} className="timeCell">
          {t}
        </div>
      );
    });
  }, []);

  const startDrag = useCallback(({ booking, tableName, bookingIndex }) => {
    setDragBooking(booking);
    setDragTableName(tableName);
    setShowCanvasTableBookingInfo(false);
    setDragBookingIndex(bookingIndex);
  }, []);

  const renderTimelineBookingRow = useCallback(() => {
    if (tables.length === 0) return;

    return tables.map((t, tableIndex) => {
      return (
        <TimelineBookingRow
          key={t.table_number}
          date={date}
          tableInfo={t}
          tableIndex={tableIndex}
          droppedTable={droppedTable}
          showCanvasTableBookingInfo={showCanvasTableBookingInfo}
          DraggingEnd={DraggingEnd}
          setBookingListInfo={setBookingListInfo}
          startDrag={startDrag}
          dragTableName={dragTableName}
          dragBooking={dragBooking}
          setSelectBookings={setSelectBookings}
        />
      );
    });
  }, [
    DraggingEnd,
    date,
    dragBooking,
    dragTableName,
    droppedTable,
    setBookingListInfo,
    startDrag,
    tables,
    showCanvasTableBookingInfo
  ]);

  const renderCanvasTableBookingInfo = () => {
    if (showCanvasTableBookingInfo) {
      return (
        <CanvasTableBookingInfo
          systemMode={"timeline"}
          bookingListInfoBookings={bookingListInfoBookings}
          setShowCanvasTableBookingInfo={setShowCanvasTableBookingInfo}
        />
      );
    }
  };

  const TimelineTimeMemo = useMemo(
    () => renderTimelineTime(),
    [renderTimelineTime]
  );

  const TablesMemo = useMemo(() => renderTables(), [renderTables]);

  const MyPreview = () => {
    const { display, style } = usePreview();

    if (!display || navigator.maxTouchPoints === 0) {
      return null;
    }

    return (
      <div class="item-list__item" style={style}>
        <p>
          {dragBooking.time} {dragBooking.phone_number}
        </p>
        <p>
          {dragBooking.last_name} {dragBooking.attendance}人
        </p>
      </div>
    );
  };

  return (
    <React.Fragment>
      {showTimelineSeat && (
        <Portal>
          <TimelineSeat
            timelineTables={timelineTables}
            closeTimelineSeat={closeTimelineSeat}
            queueSeatedData={queueSeatedData}
            diningTime={diningTime}
            updateAfterSeated={updateAfterSeated}
            setShowSeatedSuccess={setShowSeatedSuccess}
            setSeatedSuccessData={setSeatedSuccessData}
          />
        </Portal>
      )}
      <div
        className="NewModuleTimeline"
        style={{ height: window.innerHeight - $totalHeight }}
      >
        {showSeatedSuccess ? (
          <SeatedSuccess seatedSuccessData={seatedSuccessData} />
        ) : null}

        <div className="timeline">
          <div className="timeline__left">
            <div className={`timeline__left__title`}>
              {t("timeline.tableGroup")}
            </div>
            {TablesMemo}
          </div>
          <div className="timeline__right">
            <div className="timeline__right__time">
              {TimelineTimeMemo}
              <div className="timeCell" />
              {renderCurrentTime()}
            </div>
            <DndProvider
              backend={
                navigator.maxTouchPoints === 0 ? HTML5Backend : TouchBackend
              }
            >
              <ScrollingComponent
                className="timeline__right__booking"
                onScroll={(e) => syncScroll(e)}
                onClick={handleTimelineClick}
                style={{ height: window.innerHeight - TimelineTotalHeight }}
              >
                <div className="timelineBooking">
                  {renderTimelineBookingRow()}
                  <MyPreview />
                  {renderCurrentTimeWrap()}

                  <div className="canvasTableBookingInfoWrap">
                    {renderCanvasTableBookingInfo()}
                  </div>
                </div>
              </ScrollingComponent>
            </DndProvider>
          </div>
        </div>

        {/* 交換位置警告 */}
        {showSwapWarning && (
          <TimelineWarningTemplate
            cancelWarning={cancelWarning}
            confirmWarning={confirmWarning}
            title={t("popup.timelineDoubleBooking_title")}
            conflict={true}
          >
            <Space size={8} direction="vertical">
              <Trans
                i18nKey="bookingSystem:popup.timelineDoubleBooking_content"
                values={{
                  dragTime: dragBooking.time,
                  dragCustomer: dragBooking.name,
                  swappedTime: swappedBooking.time,
                  swappedCustomer: swappedBooking.name,
                  table: droppedTable.table_number
                }}
                components={{
                  outer: <Space size={4} wrap />,
                  highlight: <span className="color-alert text-bold" />,
                  div: <div />
                }}
              />
            </Space>
          </TimelineWarningTemplate>
        )}

        {/* 入座警告 */}
        {showSeatedWarning && (
          <TimelineWarningTemplate
            cancelWarning={cancelWarning}
            confirmWarning={confirmWarning}
            title={t("popup.timelineChangeTables_title")}
          >
            <Trans
              i18nKey="bookingSystem:popup.timelineChangeTables_content"
              values={{
                time: dragBooking.time,
                customer: dragBooking.name,
                fromTable: dragBooking.target_seat.table_number,
                toTable: droppedTable.table_number,
              }}
              components={{
                outer: <Space size={4} wrap />,
                highlight: <span className="color-alert text-bold" />,
              }}
            />
          </TimelineWarningTemplate>
        )}
      </div>
    </React.Fragment>
  );
};

const CurrentTimeTag = () => {
  const [currentTimeStamp, setCurrentTimeStamp] = useState(moment());

  useInterval(() => {
    setCurrentTimeStamp(moment());
  }, 1000);

  const currentTimeLeft =
    moment.duration(currentTimeStamp.format("HH:mm")).asHours() * 2 * scale;

  return <div className="currentTimeTag" style={{ left: currentTimeLeft }} />;
};

const CurrentTimeWrap = () => {
  const [currentTimeStamp, setCurrentTimeStamp] = useState(moment());

  useInterval(() => {
    setCurrentTimeStamp(moment());
  }, 1000);

  const currentTimeLeft =
    moment.duration(currentTimeStamp.format("HH:mm")).asHours() * 2 * scale;

  return (
    <div
      className="currentTimeWrap"
      style={{ width: currentTimeLeft, left: currentTimeLeft }}
    />
  );
};

export default NewModuleTimeline;
