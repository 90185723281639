import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import BookingSystemApi from '../BookingSystemApi';
import Opening from './Opening';
import DateConfigModal from './DateConfigModal';
import SettingsSaveButton from '../../commons/SettingsSaveButton';

import './dateConfig.scss';

const api = new BookingSystemApi();

const BookingSpecialOperatingDatesConfig = (props) => {
	const { t } = useTranslation('settings');
	const [ init, setInit ] = useState(false);
	const [ sending, setSending ] = useState(false);
	const [ isSaved, setIsSaved ] = useState(true);
	const [ diningTime, setDiningTime ] = useState(0);
	const [ daySettings, setDaySettings ] = useState([]);
	const [ editSetting, setEditSetting ] = useState({});
	const [ showModal, setShowModal ] = useState(false);
	const [ serviceTimeOptionEnabled, setServiceTimeOptionEnabled ] = useState(false)
	const editIndexRef = useRef(null);

	const routerWillLeave = useCallback(() => {
    if (!isSaved) return t("leaveNotice");
  }, [isSaved, t]);

	useEffect(
		() => {
			props.router.setRouteLeaveHook(props.route, routerWillLeave);
		},
		[ props, routerWillLeave ]
	);

	useEffect(() => {
    const getBookingSetting = async () => {
      try {
        const data = await api.getBookingSetting();
        const { day_settings, dining_time, service_time_option_enabled } = data;

        setDiningTime(dining_time);
        setDaySettings(day_settings);
        setServiceTimeOptionEnabled(service_time_option_enabled);
        setInit(true);
      } catch (err) {
        console.log("dateConfig2 getBookingSetting err---", err);
        window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
      }
    };

    getBookingSetting();
  }, [t]);

	const submit = () => {
		setSending(true);

		const settings = JSON.stringify({
			booking_settings: {
				day_settings: JSON.stringify(daySettings)
			}
		});

		api
			.updateBookingSetting(settings)
			.then(() => {
				setSending(false);
				setIsSaved(true);
				window.app.alert.setMessage(t('status.saved'), 'done');
			})
			.catch((error) => {
				setSending(false);
				console.log(error);
				window.app.alert.setMessage(t('status.pls_tryAgainLater'), 'error');
			});
	};

	const openEditorModal = (index) => {
		if (index === undefined) {
			editIndexRef.current = null;
			setEditSetting({
				start_date: moment().format('YYYY/MM/DD'),
				end_date: moment().format('YYYY/MM/DD'),
				hours: [],
				setting_temp: {
					enable: 2,
					setting2: [ [ '00:00', '00:30' ] ],
					setting3: [ '00:00' ]
				},
				dining_time: diningTime,
				max_people_at_a_time: 0,
				booking_time_interval: 30,
				service_time_setting: [{
					mode: 'user_setting',
					user_setting: {
						show_name: true,
						service_time_setting: [{
							name: t('bookingTimesCommon.optionName'),
							service_time: diningTime
						}]
					},
					fixed: {
						service_time: [],
						max: 240,
						min: 60,
						unit: 30
					}
				}]
			});
		} else {
			const setting = _.cloneDeep(daySettings[index]);
			setEditSetting(setting);
			editIndexRef.current = index;
		}

		setShowModal(true);
	};

	const deleteOpening = (index) => {
		const newDaySettings = _.cloneDeep(daySettings);
		newDaySettings.splice(index, 1);
		setDaySettings(newDaySettings);
		setIsSaved(false);
	};

	const editSubmit = useCallback(
		(setting, index) => {
			const newDaySettings = _.cloneDeep(daySettings);
			newDaySettings[index] = setting;
			setDaySettings(newDaySettings);
		},
		[ daySettings ]
	);

	const addSubmit = useCallback(
		(setting) => {
			const newDaySettings = _.cloneDeep(daySettings);
			newDaySettings.push(setting);
			setDaySettings(newDaySettings);
		},
		[ daySettings ]
	);

	const updateSettings = useCallback(
		(newSettings) => {
		
			setShowModal(false);
			setIsSaved(false);
			const timeRange = _.cloneDeep(newSettings.setting_temp.setting2),
				timeRangeLength = timeRange.length;

			if (timeRangeLength > 1) {
				let sortArray = _.sortBy(timeRange);

				for (let i = 0; i < sortArray.length; i++) {
					if (!sortArray[i]) break;
					const one = parseInt(sortArray[i][1].slice(0, 2), 10) * 60 + parseInt(sortArray[i][1].slice(3), 10);
					for (let j = i + 1; j <= i + 1; j++) {
						if (!sortArray[j]) break;
						const second =
							parseInt(sortArray[j][0].slice(0, 2), 10) * 60 + parseInt(sortArray[j][0].slice(3), 10);
						if (one > second) {
							let second_till =
								parseInt(sortArray[j][1].slice(0, 2), 10) * 60 + parseInt(sortArray[j][1].slice(3), 10);
							let newArray = [];
							if (one > second_till) {
								newArray = [ sortArray[i][0], sortArray[i][1] ];
							} else {
								newArray = [ sortArray[i][0], sortArray[j][1] ];
							}
							sortArray.splice(j + 1, 0, newArray);
							sortArray.splice(i, 2);
							i = -1;
							continue;
						}
					}
				}
				newSettings['setting_temp']['setting2'] = sortArray;
			}

			if (editIndexRef.current !== undefined && editIndexRef.current !== null) {
				editSubmit(newSettings, editIndexRef.current);
			} else {
				addSubmit(newSettings);
			}
			editIndexRef.current = null;
		},
		[ addSubmit, editSubmit ]
	);

	const closeEditor = useCallback(() => {
		setShowModal(false);
		editIndexRef.current = null;
	}, []);

	const renderOpenings = () => {
		return daySettings.map((setting, index) => {
			return (
				<Opening
					key={index}
					setting={setting}
					idx={index}
					openEditor={() => openEditorModal(index)}
					deleteOpening={() => deleteOpening(index)}
					serviceTimeOptionEnabled={serviceTimeOptionEnabled}
				/>
			);
		});
	};

	if (!init) return <div className="pageLoading">{t('status.loading')}</div>;
	return (
		<>
			<div className="setting-container dateConfig2Setting">
				{sending && (
					<div className="lightBoxLayer">
						<h4>{t('status.saving')}</h4>
					</div>
				)}
				<div style={{ display: 'flex' }}>
					<div style={{ flexGrow: 1 }}>
						<h3>{t('specialOperatingDates.title')}</h3>
						<h5>{t('specialOperatingDates.description')}</h5>
					</div>
				</div>
				<hr />
				<div>{renderOpenings()}</div>

				{showModal && (
					<DateConfigModal
						status={editIndexRef.current === null ? 'add' : 'edit'}
						editSetting={editSetting}
						updateSettings={updateSettings}
						closeEditor={closeEditor}
						serviceTimeOptionEnabled={serviceTimeOptionEnabled}
					/>
				)}
				<button className="msBtn" onClick={() => openEditorModal()}>
					＋ {t('specialOperatingDates.specialBookingTimes')}
				</button>
				<br />
				<hr />
			</div>
			<SettingsSaveButton handleSaved={submit} />
		</>
	);
};

export default BookingSpecialOperatingDatesConfig;
