import React from "react";
import { Modal, Space } from "antd";
import { updateCrmBlockedList } from "../../api/CrmSettingsApi";
import { useTranslation } from "react-i18next";

function BlockActionModal({
  phone_number,
  isBlocked,
  setIsBlocked,
  load,
  setShowBlockActionModal,
}) {
  const { t } = useTranslation("customer");
  let title = isBlocked
    ? t("popup.removeBlocked_title")
    : t("popup.addBlocked_title");
  let content = isBlocked
    ? t("popup.removeBlocked_content")
    : t("popup.addBlocked_content");

  const handleSubmit = async () => {
    try {
      await updateCrmBlockedList({
        phone_numbers: [phone_number],
        operation: isBlocked ? "delete" : "add",
      });

      if (isBlocked) {
        setIsBlocked(false);
        window.app.alert.setMessage(t("popup.removeBlocked_Success"), "done");
      } else {
        setIsBlocked(true);
        window.app.alert.setMessage(t("popup.addBlockedSuccess"), "done");
      }

      setShowBlockActionModal(false);
      load();
    } catch (error) {
      window.app.alert.setMessage(
        t("settings:status.pls_tryAgainLater"),
        "error"
      );
    }
  };

  return (
    <Modal
      title={title}
      visible
      destroyOnClose
      width={374}
      centered
      closable={false}
      className="modal-base modal-sm"
      footer={
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={() => setShowBlockActionModal(false)}
          >
            {t("settings:cancel")}
          </button>
          <button
            className="button-common button-primary"
            onClick={handleSubmit}
          >
            {t("settings:ok")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {content}
    </Modal>
  );
}

export default BlockActionModal;
