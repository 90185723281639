import React, { useContext } from "react";
import { SignUpStateContext } from "../MenuCouponContext/MenuCouponSignUpContext";
import SignUpStepOne from "./SignUpStep_1";
// import SignUpDone from "./SignUpDone";

const MenuCouponSignUp = () => {
  const { SignUpState } = useContext(SignUpStateContext);
  const { step } = SignUpState;

  // shopInfo 店家資訊
  // done 綁定完成

  return (
    <section className="menuCouponSignUp">
      {step === "shopInfo" && <SignUpStepOne />}
      {/* {step === "done" && <SignUpDone />} */}
    </section>
  );
};

export default MenuCouponSignUp;
