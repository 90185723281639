import React from "react";

const NewModuleEntry = ({ children }) => {
  return (
    <div className="newModuleWrap" style={{ height: window.innerHeight }}>
      <div className="newModule">
        <div className="newModule__main">
          <div className="newModule__main__radius system-layout-with-date">
            {children}
          </div>
        </div>
      </div>
      <div id="modalPortal"></div>
    </div>
  );
};

export default NewModuleEntry;
