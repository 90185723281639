import React, { Component } from "react";
import { withTranslation } from "react-i18next";
class QueueChartToolTip extends Component {
  render() {
    const { external, label, t } = this.props;
    let currData = external.filter((entry) => entry.name === label)[0];

    if (currData !== undefined) {
      return (
        <div className="container-chart-tip">
          <p className="chart-tip-date">{label}</p>
          <div className="chart-tip-infoitem">
            <span className="chart-tip-color" />
            <span className="chart-tip-content">
              {t("status.checkedIn_2")}：{currData["seated"]}
            </span>
          </div>
          <div className="chart-tip-infoitem">
            <span className="chart-tip-color blue" />
            <span className="chart-tip-content">
              {t("status.invalid")}：{currData["unavailable"]}
            </span>
          </div>
          <div className="chart-tip-infoitem">
            <span className="chart-tip-color yellow" />
            <span className="chart-tip-content">
              {t("status.missed")}：{currData["standby"]}
            </span>
          </div>
          <div className="chart-tip-infoitem">
            <span className="chart-tip-color red" />
            <span className="chart-tip-content">
              {t("status.cancelled_2")}：{currData["cancel"]}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withTranslation("queueSystem")(QueueChartToolTip);
