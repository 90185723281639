import React, { Fragment, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { MoreOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import {
  announcementSetting,
  announcementSettingEnded,
  showAnnouncementAddModal,
} from "../../actions/announcementAction";
import { dayList, dayListAbbrevEN } from "../../utils/constants";

const AnnouncementCard = ({ data, item, idx, onlyRead = false, date = "" }) => {
  const { t, i18n } = useTranslation("bulletinSystem");
  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const { showListType } = announcementListReducer;
  const dispatch = useDispatch();

  const handleContentExpend = useCallback(
    (e) => {
      if (e.target.closest(".listCardDropDown")) {
        return;
      }

      const lists = document.getElementsByClassName("announcementCard");
      const cardClassName = `${date}_${idx}`;
      const card = document.getElementsByClassName(cardClassName)[0];

      if (!card) {
        console.warn(`Card with className ${cardClassName} not found`);
        return;
      }

      if (card.classList.contains("open")) {
        card.classList.remove("open");
      } else {
        console.log('lists', lists)
        Array.from(lists).forEach((list) => {
          list.classList.remove("open");
        });
        card.classList.add("open");
      }
    },
    [date, idx]
  );

  const handleEditor = (item, id) => {
    let findIndex, announcementState;

    if (showListType === "month") {
      findIndex = data[date].map((list) => list.id).indexOf(id);
      announcementState = _.cloneDeep(data[date][findIndex]);
    } else {
      findIndex = data.map((list) => list.id).indexOf(id);
      announcementState = _.cloneDeep(data[findIndex]);
    }

    dispatch(announcementSetting(announcementState));

    if (item === "edit") {
      dispatch(showAnnouncementAddModal());
    } else {
      dispatch(announcementSettingEnded(item));
    }
  };

  const renderEditList = (id) => {
    const announceEditList = [
      { title: t("edit"), action: "edit" },
      { title: t("list.setAsExpired"), action: "finished" },
      { title: t("list.delete"), action: "deleted" },
    ];

    return announceEditList.map((item) => {
      return (
        <li key={item.action} onClick={() => handleEditor(item.action, id)}>
          {item.title}
        </li>
      );
    });
  };

  const renderDateTime = () => {
    const days = i18n.language === "zh" ? dayList : dayListAbbrevEN;
    let day_start = new Date(item.start_time).getDay();
    let day_end = new Date(item.end_time).getDay();
    let startTime =
      moment(item.start_time).format("YYYY-MM-DD") +
      "（" +
      days[day_start] +
      "）" +
      moment(item.start_time).format("HH:mm");
    let endTime =
      moment(item.end_time).format("YYYY-MM-DD") +
      "（" +
      days[day_end] +
      "）" +
      moment(item.end_time).format("HH:mm");

    return (
      <Fragment>
        {startTime} - <br />
        {endTime}
      </Fragment>
    );
  };

  return (
    <div
      className={`announcementCard ${date}_${idx}`}
      onClick={handleContentExpend}
    >
      <div className="title">
        <div>{item.title}</div>
        {!onlyRead && (
          <div
            className="listCardDropDown"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="editDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <MoreOutlined />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <ul>{renderEditList(item.id)}</ul>
            </div>
          </div>
        )}
      </div>
      <div className="dateTime">
        {renderDateTime()}
        <div className="announcementBlock_arrow" />
      </div>
      <div className="content">{item.content}</div>
    </div>
  );
};

export default AnnouncementCard;
