export const createSettingsItem = (t, ns) => {
  return [
    {
      title: t('accountSettings.title', ns),
      subPage: [
        {
          title: t('accountSettings.subTitle', ns),
          path: '/dashboard/setting/shops'
        },
        {
          title: t('dudooPosSettings.title', ns),
          path: '/dashboard/setting/integratewithdudooeat'
        },
        {
          title: t('notificationCredits.title', ns),
          path: '/dashboard/setting/notification-credits'
        }
      ]
    },
    {
      title: t('tableSettings.title', ns),
      subPage: [
        {
          title: t('tableSettings.subtitle', ns),
          path: '/dashboard/setting/seats'
        },
        {
          title: t('tableOrder.title', ns),
          path: '/dashboard/setting/seats_order'
        },
        {
          title: t('tableMap.title', ns),
          path: '/dashboard/setting/tablemap_setting'
        }
      ]
    },
    {
      title: t('bookingSystem.title', ns),
      subPage: [
        {
          title:  t('onlineBookingSettings.title', ns),
          path: '/dashboard/setting/enable'
        },
        {
          title: t('bookingTimeSettings.title', ns),
          path: '/dashboard/setting/available_time'
        },
        {
          title: t('specialOperatingDates.title', ns),
          path: '/dashboard/setting/special-operating-dates-config'
        },
        {
          title: t('unavailableBookingDates.title', ns),
          path: '/dashboard/setting/unavailable-dates-config'
        },
        {
          title: t('advancedPayments.title', ns),
          path: '/dashboard/setting/payment'
        },
        {
          title: t('serviceOptionSetting.title', ns),
          path: '/dashboard/setting/area'
        },
        {
          title: t('customBookingQuestionnaire.title', ns),
          path: '/dashboard/setting/custom_question'
        },
        {
          title: t('bookingNotification.title', ns),
          path: '/dashboard/setting/booking-notification'
        },
        {
          title: t('bookingTracking.title', ns),
          path: '/dashboard/setting/booking-tracking'
        },
        {
          title: t('reserveWithGoogleSettings.title', ns),
          path: '/dashboard/setting/google-reservation'
        }
      ]
    },
    {
      title: t('queuingSystem.title', ns),
      subPage: [
        {
          title: t('queuingSystem.subtitle', ns),
          path: '/dashboard/setting/display'
        },
        {
          title: t('queueTypes.title', ns),
          path: '/dashboard/setting/group'
        },
        {
          title: t('estWaitingTime.title', ns),
          path: '/dashboard/setting/time'
        },
        {
          title: t('queueNotification.title', ns),
          path: '/dashboard/setting/queue-notification'
        }
      ]
    }
  ];
};
