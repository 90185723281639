import React from "react";
import { useTranslation } from 'react-i18next';

const MenuCouponEmpty = ({ addCoupon }) => {
	const { t } = useTranslation('couponSystem');
  return (
    <div className="menuCouponEmpty">
      <h2>{t('couponEmpty.title')}</h2>
      <p>{t('couponEmpty.description')}</p>
      <button className="greenThemeBtn" onClick={addCoupon}>
        {t('couponEmpty.addFirstCoupon')}
      </button>
      <p>{t('couponEmpty.note')}</p>
    </div>
  );
};

export default MenuCouponEmpty;
