/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { config } from "../../utils/config";
import QueueSystemAPI from "./QueueSystemAPI";
import QueuePanelAPI from "../queue_system/api/QueuePanelAPI";

import QRCode from "qrcode.react";
import Reorder, { reorder } from "react-reorder";
import { withTranslation } from "react-i18next";
import { Tooltip, Switch } from "antd";
import SettingsSaveButton from "../commons/SettingsSaveButton";

class QueueSettingDisplay extends Component {
  state = {
    queuePageLink: "",
    displayName: "",
    branch: "",
    address: "",
    phone: "",
    introduction: "",
    showWaitingGroup: false,
    showWaitingTime: false,
    externalLinkText: "",
    externalLink: "",
    pauseText: "",
    shopName: "",
    isSaved: true,
    menuFiles: [],
    deleteMenuImages: [],
    remote_line_up: true,
    menuPreviewUrls: [],
    email_required: false,
    refresh_live_url: false,
    queueEnded: false,
    shopNote: "",
    allowDuplicatedPhone: false,
    merchant_guidelines: "",
    customer_memo_enabled: false,
  };

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
    this.API = new QueueSystemAPI();
    this.GROUP = new QueuePanelAPI();

    this.API.getQueueSettings().then(
      (data) => {
        this.setState({
          displayName: data["display_name"],
          branch: data["branch"],
          queuePageLink: data["queue_page_link"],
          address: data["addr"],
          phone: data["phone"],
          introduction: data["intro"],
          externalLink: data["external_link"],
          externalLinkText: data["external_link_text"],
          pauseText: data["pause_msg"],
          showWaitingGroup: data["show_waiting_group"],
          showWaitingTime: data["show_waiting_time"],
          backgroundImage: data["background_img"],
          menuPreviewUrls: data["queue_menu_imgs"],
          shopName: data["shop_name"],
          email_required: data["email_required"],
          remote_line_up: data["remote_line_up"],
          live_page_url: data["live_page_url"],
          refresh_live_url: data["refresh_live_url"],
          allowDuplicatedPhone: data["allow_duplicated_phone"],
          merchant_guidelines: data["merchant_guidelines"],
          customer_memo_enabled: data["customer_memo_enabled"],
        });

        if (data["shop_note"] === null) {
          this.setState({ shopNote: "" });
        } else {
          this.setState({ shopNote: data["shop_note"] });
        }
      },
      (error) => {}
    );
    this.GROUP.getListedQueueGroup()
      .then((data) => {
        let endIndex = data.queue_groups
          .map((group) => {
            return group.status;
          })
          .indexOf("end");
        if (endIndex === -1) {
          this.setState({ queueEnded: false });
        } else {
          this.setState({ queueEnded: true });
        }
      })
      .catch((err) => {});
  }

  routerWillLeave = (nextLocation) => {
    const { t } = this.props;
    if (!this.state.isSaved) return t("leaveNotice");
  };

  _handleImageChange = (e) => {
    e.preventDefault();
    const { t } = this.props;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file.size > 2097152) {
      window.app.alert.setMessage(
        t("maximumFileSize2MB_2", { number: 2 }),
        "error"
      );
    } else {
      reader.onloadend = (ev) => {
        this.setState(
          {
            file: file,
            imagePreviewUrl: reader.result,
            isSaved: false,
          },
          () => {
            this.setState({ isSaved: false });
          }
        );
      };

      reader.readAsDataURL(file);
    }
  };

  _handleMenuImageChange = (e) => {
    e.preventDefault();
    const { t } = this.props;
    let files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      let file = files[i];
      if (file.size > 2097152) {
        window.app.alert.setMessage(
          t("maximumFileSize2MB_2", { number: 2 }),
          "error"
        );
      } else {
        reader.onloadend = (event) => {
          let { menuFiles, menuPreviewUrls } = this.state;
          let time = new Date(),
            timeStamp = time.getTime().toString();
          if (menuPreviewUrls.length >= 5) {
            window.app.alert.setMessage(
              t("imageCommon.uploadImages", { number: 5 }),
              "error"
            );
          } else {
            menuPreviewUrls.push({
              url: reader.result,
              fileImgOrder: timeStamp,
            });
            file.fileImgOrder = timeStamp;
            menuFiles.push(file);
            this.setState({
              menuFiles: menuFiles,
              menuPreviewUrls: menuPreviewUrls,
              isSaved: false,
            });
          }
        };
      }
      reader.readAsDataURL(file);
    }
  };

  delMenuImg(imgIndex, file) {
    let { menuPreviewUrls, menuFiles } = this.state;

    menuPreviewUrls.splice(imgIndex, 1);

    if (!file.id) {
      menuFiles.some((item, index) => {
        if (item["fileImgOrder"] === file["fileImgOrder"]) {
          menuFiles.splice(index, 1);
          return true;
        }
        return false;
      });
    }

    if (menuPreviewUrls.length === 0) {
      this.setState({ isSaved: false, menuPreviewUrls: menuPreviewUrls });
    } else {
      this.onReorder();
    }
  }

  onReorder(event, previousIndex, nextIndex, fromId, toId) {
    this.setState({
      isSaved: false,
      menuPreviewUrls: reorder(
        this.state.menuPreviewUrls,
        previousIndex,
        nextIndex
      ),
    });
  }

  downloadImage = (e) => {
    var canvas = document.getElementById("qrcode").firstChild;
    var button = document.getElementById("qrcode-btn");
    let newCanvas = document.createElement("canvas");
    let ctx = newCanvas.getContext("2d");

    newCanvas.width = canvas.width + 40;
    newCanvas.height = canvas.height + 40;

    let centerWidth = newCanvas.width / 2 - canvas.width / 2,
      centerHeight = newCanvas.height / 2 - canvas.height / 2;

    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);
    ctx.drawImage(canvas, centerWidth, centerHeight);

    button.setAttribute("href", newCanvas.toDataURL("image/png"));
  };

  submit = (ev) => {
    const { t } = this.props;
    this.API.updateQueueSettings({
      display_name: this.state.displayName,
      branch: this.state.branch,
      addr: this.state.address,
      phone: this.state.phone,
      intro: this.state.introduction,
      external_link: this.state.externalLink,
      external_link_text: this.state.externalLinkText,
      pause_msg: this.state.pauseText,
      show_waiting_group: this.state.showWaitingGroup,
      show_waiting_time: this.state.showWaitingTime,
      email_required: this.state.email_required,
      remote_line_up: this.state.remote_line_up,
      refresh_live_url: this.state.refresh_live_url,
      shop_note: this.state.shopNote,
      allow_duplicated_phone: this.state.allowDuplicatedPhone,
      merchant_guidelines: this.state.merchant_guidelines,
      customer_memo_enabled: this.state.customer_memo_enabled,
    }).then(
      (data) => {
        window.app.alert.setMessage(t("status.saved"), "done");
        this.setState({
          isSaved: true,
        });
      },
      (error) => {
        window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
      }
    );

    if (this.state.file !== undefined) {
      this.API.updateQueueBackgroundImage(this.state.file).then(
        (data) => {
          this.setState({
            file: undefined,
          });
        },
        (error) => {}
      );
    }

    if (this.state.menuFiles !== undefined) {
      this.API.updateQueueMenuImage(
        this.state.menuFiles,
        this.state.menuPreviewUrls
      ).then(
        (data) => {
          this.setState({
            menuFiles: [],
          });
        },
        (error) => {}
      );
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="setting-container">
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <h3>{t("queuingSystem.subtitle")}</h3>
              <h5>{t("queuingSystem.description")}</h5>
            </div>
          </div>
          <hr />
          <div className="rangeTitle">
            <img src={require("../../images/homepage.svg")} alt="" />
            <h4>
              {t("queuingSystem.goToQueueStatusPage")}
              <div>{t("queuingSystem.showQueueStatusForCustomer")}</div>
            </h4>
          </div>
          <div className="settingTbl">
            <div className="settingTblRow">
              <div className="header">{t("queuingSystem.queueStatusPage")}</div>
              <div className="text">
                <a
                  rel="noopener noreferrer"
                  href="/dashboard/queue_system/display/store"
                  target="_blank"
                  style={{ color: "#404040", textDecoration: "none" }}
                >
                  <label className="msBtn" style={{ marginTop: "16px" }}>
                    {t("queuingSystem.go")}
                  </label>
                </a>
              </div>
            </div>
            <div className="settingTblRow">
              <div className="header">QR Code</div>
              <div className="text">
                <a
                  className="msBtn"
                  id="qrcode-btn"
                  download
                  style={{
                    marginTop: "16px",
                    textDecoration: "none",
                    fontWeight: "bold",
                    borderColor:
                      this.state.queueEnded && this.state.refresh_live_url
                        ? "rgba(0, 0, 0, 0.25)"
                        : "rgb(51, 51, 51)",
                  }}
                  disabled={
                    this.state.queueEnded && this.state.refresh_live_url
                  }
                  onClick={this.downloadImage}
                >
                  {t("queuingSystem.download")}
                </a>
                {this.state.queueEnded && this.state.refresh_live_url && (
                  <p className="text-danger" style={{ fontSize: 14 }}>
                    {t("queuingSystem.reQueue")}
                  </p>
                )}
                <div id="qrcode" hidden>
                  <QRCode
                    size={220}
                    value={
                      config.queue_domain + "/live/" + this.state.live_page_url
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />
          <div className="rangeTitle">
            <img src={require("../../images/clock.svg")} alt="Setting" />
            <h4>{t("queuingSystem.onlineQueuingSetting")}</h4>
          </div>
          <div className="settingTbl">
            <div className="settingTblRow">
              <div className="header">
                <Tooltip
                  placement="bottom"
                  overlayClassName="setting-tooltip"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={t("queuingSystem.remoteQueuing_tooltip")}
                >
                  {t("queuingSystem.remoteQueuing")}
                  <i className="fa fa-question-circle-o" />
                </Tooltip>
              </div>
              <div className="text">
                <Switch
                  onChange={(checked) => {
                    this.setState({
                      remote_line_up: checked,
                    });
                  }}
                  checked={this.state.remote_line_up}
                />
              </div>
            </div>

            {this.state.remote_line_up ? (
              <div className="settingTblRow">
                <div className="header" />
                <div className="text">
                  <a
                    style={{ fontSize: "18px", fontWeight: "normal" }}
                    className="msLink"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={config.queue_domain + "/" + this.state.shopName}
                  >
                    {config.queue_domain}/{this.state.shopName}
                  </a>
                </div>
              </div>
            ) : null}
            <div className="settingTblRow">
              <div className="header">
                <Tooltip
                  placement="bottom"
                  overlayClassName="setting-tooltip"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={t("queuingSystem.updateOnlineQueuingWebURL_tooltip")}
                >
                  {t("queuingSystem.updateOnlineQueuingWebURL")}
                  <i className="fa fa-question-circle-o" />
                </Tooltip>
              </div>
              <div className="text">
                <Switch
                  onChange={(checked) =>
                    this.setState({
                      refresh_live_url: checked,
                    })
                  }
                  checked={this.state.refresh_live_url}
                />
                {this.state.refresh_live_url && (
                  <p className="text-danger" style={{ fontSize: 14 }}>
                    {t("queuingSystem.noticeQRCodeWillBeUnavailable")}
                  </p>
                )}
              </div>
            </div>
            <div className="settingTblRow">
              <div className="header">{t("queuingSystem.pageBanner")}</div>
              <div className="text">
                <p className="help-block">
                  <img
                    src={
                      this.state.imagePreviewUrl || this.state.backgroundImage
                    }
                    style={{ maxWidth: "100%", maxHeight: "230px" }}
                    alt=""
                  />
                </p>

                <div className="file-upload">
                  <span>{t("imageCommon.recommendedSize1170X300")}</span>
                  <label
                    htmlFor="upload"
                    className="msBtn"
                    style={{ marginLeft: "15px" }}
                  >
                    + {t("imageCommon.upload")}
                  </label>
                  <input
                    id="upload"
                    onChange={this._handleImageChange}
                    className="file-upload__input"
                    type="file"
                    name="file-upload"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="settingTblRow">
              <div className="header">
                {t("queuingSystem.QueuingInformation")}
              </div>
              <div className="text">
                <textarea
                  rows="7"
                  cols="50"
                  value={this.state.introduction}
                  name="note"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({
                      introduction: e.target.value,
                      isSaved: false,
                    });
                  }}
                />
              </div>
            </div>
            <br />
            <br />
            <div className="settingTblRow">
              <div className="header">
                <Tooltip
                  placement="bottom"
                  overlayClassName="setting-tooltip"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={t("queuingSystem.uploadMenu_tooltip")}
                >
                  {t("queuingSystem.uploadMenu")}
                  <i className="fa fa-question-circle-o" />
                </Tooltip>
              </div>
              <div className="text">
                {
                  <Reorder
                    reorderId="q-list"
                    reorderGroup="reorder-group"
                    onReorder={this.onReorder.bind(this)}
                    disabled={false}
                    placeholder={<div className="menuDivStylePlaceHolder" />}
                  >
                    {this.state.menuPreviewUrls.map((item, index) => (
                      <div key={index} className="menuDivStyle">
                        <button
                          className="menuDel"
                          onClick={() => this.delMenuImg(index, item)}
                        />
                        <img src={item.url} className="menuStyle" alt="" />
                      </div>
                    ))}
                  </Reorder>
                }

                <div
                  className="file-upload"
                  style={{ display: "block", width: "100%" }}
                >
                  <span>
                    {t("imageCommon.uploadImagesAndRecommended", {
                      numbers: 5,
                    })}
                  </span>
                  <label
                    htmlFor="upload-menu"
                    className="msBtn"
                    style={{ marginLeft: "16px" }}
                  >
                    + {t("imageCommon.upload")}
                  </label>
                  <input
                    id="upload-menu"
                    multiple
                    onChange={this._handleMenuImageChange}
                    className="file-upload__input"
                    type="file"
                    name="file-upload-menu"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="settingTblRow">
              <div className="header">
                <Tooltip
                  placement="bottom"
                  overlayClassName="setting-tooltip"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={t(
                    "queuingSystem.pauseOnlineQueuingInstructions_tooltip"
                  )}
                >
                  {t("queuingSystem.pauseOnlineQueuingInstructions")}
                  <i className="fa fa-question-circle-o" />
                </Tooltip>
              </div>
              <div className="text">
                <textarea
                  rows="7"
                  cols="50"
                  value={this.state.pauseText}
                  name="pause_text"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({
                      pauseText: e.target.value,
                      isSaved: false,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="settingTbl">
            <div className="rangeTitle mb-32">
              <h4>
                {t("queuingSystem.queueRegistrationPageSettings")}
                <div>
                  {t("queuingSystem.queueRegistrationPageSettings_des")}
                </div>
              </h4>
            </div>
            <div className="settingTblRow">
              <div className="header">
                <Tooltip
                  placement="bottom"
                  overlayClassName="setting-tooltip"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={t("queuingSystem.restrictDuplicatePhoneNumber_tooltip")}
                >
                  {t("queuingSystem.restrictDuplicatePhoneNumber")}
                  <i className="fa fa-question-circle-o" />
                </Tooltip>
              </div>
              <div className="text">
                <Switch
                  onChange={(checked) =>
                    this.setState({
                      allowDuplicatedPhone: checked,
                    })
                  }
                  checked={this.state.allowDuplicatedPhone}
                />
              </div>
            </div>
            <br />
            <div className="settingTblRow">
              <div className="header">
                <Tooltip
                  placement="bottom"
                  overlayClassName="setting-tooltip"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={t("queuingSystem.emailRequired_tooltip")}
                >
                  {t("queuingSystem.emailRequired")}
                  <i className="fa fa-question-circle-o" />
                </Tooltip>
              </div>
              <div className="text">
                <Switch
                  onChange={(checked) => {
                    this.setState({
                      email_required: checked,
                    });
                  }}
                  checked={this.state.email_required}
                />
              </div>
            </div>
            <br />
            <div className="settingTblRow">
              <div className="header">
                <Tooltip
                  placement="bottom"
                  overlayClassName="setting-tooltip"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={t("queuingSystem.allowCustomerNote_tooltip")}
                >
                  {t("queuingSystem.allowCustomerNote")}
                  <i className="fa fa-question-circle-o" />
                </Tooltip>
              </div>
              <div className="text">
                <Switch
                  onChange={(checked) => {
                    this.setState({
                      customer_memo_enabled: checked,
                    });
                  }}
                  checked={this.state.customer_memo_enabled}
                />
              </div>
            </div>
            <br />
            <div className="settingTblRow">
              <div className="header">{t("queuingSystem.queueReminder")}</div>
              <div className="text">
                <textarea
                  rows="7"
                  cols="30"
                  name="note_text"
                  className="form-control"
                  value={this.state.merchant_guidelines}
                  onChange={(e) => {
                    this.setState({
                      merchant_guidelines: e.target.value,
                      isSaved: false,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="settingTbl">
            <div className="rangeTitle mb-32">
              <h4>
                {t("queuingSystem.queueRegistrationResultsPageSetting")}
                <div>
                  {t("queuingSystem.queueRegistrationResultsPageSetting_des")}
                </div>
              </h4>
            </div>
            <div className="settingTblRow">
              <div className="header">{t("queuingSystem.instructions")}</div>
              <div className="text">
                <textarea
                  rows="7"
                  cols="30"
                  name="note_text"
                  className="form-control"
                  value={this.state.shopNote}
                  onChange={(e) => {
                    this.setState({
                      shopNote: e.target.value,
                      isSaved: false,
                    });
                  }}
                />
              </div>
            </div>
            <br />
            <div className="settingTblRow">
              <div className="header">
                <Tooltip
                  placement="bottom"
                  overlayClassName="setting-tooltip"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={t("queuingSystem.redirectButtonTitle_tooltip")}
                >
                  {t("queuingSystem.redirectButtonTitle")}
                  <i className="fa fa-question-circle-o" />
                </Tooltip>
              </div>
              <div className="text">
                <input
                  placeholder={t(
                    "queuingSystem.placeholder_redirectButtonTitle"
                  )}
                  className="form-control input-lg"
                  style={{
                    width: "100%",
                    display: "inline",
                    marginRight: "12px",
                  }}
                  value={this.state.externalLinkText}
                  name="external_link_text"
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      this.setState({
                        externalLinkText: e.target.value,
                        isSaved: false,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <br />
            <div className="settingTblRow">
              <div className="header">{t("queuingSystem.redirectURL")}</div>
              <div className="text">
                <input
                  placeholder={t("queuingSystem.placeholder_redirectURL")}
                  className="form-control input-lg"
                  style={{
                    width: "100%",
                    display: "inline",
                    marginRight: "12px",
                  }}
                  value={this.state.externalLink}
                  name="external_link"
                  onChange={(e) => {
                    this.setState({
                      externalLink: e.target.value,
                      isSaved: false,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <hr />
        </div>
        <SettingsSaveButton handleSaved={this.submit} />
      </>
    );
  }
}

export default withTranslation("settings")(QueueSettingDisplay);
