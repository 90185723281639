import React from 'react';
import { Modal, Button, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import './menuUserDelete.scss';

export default ({ checkDelete, handleCheckDelete, deleteMenuUser}) => {
  const { t } = useTranslation('settings');

	return (
		<Modal
			destroyOnClose
			centered
			visible={checkDelete}
			wrapClassName="menuAccountDeleteModal"
      title={t('accountSettings.menu.disconnectConfirmation')}
			footer={[
        <ConfigProvider key="menuAccountDelete" autoInsertSpaceInButton={false}>
          <Button className="btn_cancel" onClick={handleCheckDelete}>
            {t('cancel')}
          </Button>
          <Button className="btn_save" onClick={deleteMenuUser}>
            {t('accountSettings.menu.disconnect')}
          </Button>
        </ConfigProvider>
      ]}
      transitionName="ant-move-down"
		>
			<div className="menuAccountDeleteModalBody">
        {t('accountSettings.menu.disconnectConfirmation_content')}
      </div>
		</Modal>
	);
};
