import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { browserHistory, Link } from "react-router";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { useTranslation } from "react-i18next";

import { CustomerContext } from "../crmContext/CustomerContext";
import { MessageContext } from "../crmContext/MessageContext";

import {
  searchCustomer,
  filterCustomersCSV,
  getCustomersCSV,
} from "../api/CrmCustomerApi";
import useDebounce from "../utils/useDebounce";
import ExpandSearchInput from "../../commons/ExpandSearchInput";

const currentSearchInputWidth = 248;

const CrmToolbar = () => {
  const { t } = useTranslation("customer");
  const auth = useSelector((state) => state.auth);
  const { shop } = auth;
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const {
    searchCustomerText,
    searchCustomerMode,
    customerFilterNum,
    showCustomerFilterBar,
    filterCustomersParams,
  } = CustomerState;
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { mode, messageStatus, messageFilter, messageSort } = MessageState;
  const [searchCustomerResult, setSearchCustomerResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchSelect, setShowSearchSelect] = useState(false);
  const [searchWidth, setSearchWidth] = useState(currentSearchInputWidth);
  const [isComposing, setIsComposing] = useState(false);
  const [focusedId, setFocusedId] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSearchBarExpand, setIsSearchBarExpand] = useState(false);

  const prevMode = useRef(mode);
  const searchInputRef = useRef(null);
  const searchBlockRef = useRef(null);
  const searchQuery = browserHistory.getCurrentLocation().query.search;
  const debouncedValue = useDebounce(searchValue);

  const cancelSearch = useCallback(() => {
    // customer
    setSearchCustomerResult([]);
    CustomerStateDispatch({
      type: "setSearchCustomerText",
      searchCustomerText: "",
    });
    CustomerStateDispatch({
      type: "setSearchCustomerResult",
      searchCustomers: [],
      searchCustomerMode: false,
      listPage: 1,
      listCount: null,
    });
    // message
    MessageStateDispatch({ type: "setMessageSort", messageSort });
    MessageStateDispatch({
      type: "switchMessageStatus",
      messageStatus,
      searchMode: false,
    });
    MessageStateDispatch({ type: "setMessageFilter", messageFilter });
    MessageStateDispatch({ type: "setSearchInputText", searchInputText: "" });
    MessageStateDispatch({ type: "setStatusMessagesNum" });

    setSearchValue("");
  }, [
    CustomerStateDispatch,
    MessageStateDispatch,
    messageFilter,
    messageSort,
    messageStatus,
    setSearchValue,
  ]);

  useEffect(() => {
    if (mode !== prevMode.current || !searchQuery) {
      cancelSearch();
      setIsSearchBarExpand(false);
      setShowSearchSelect(false);
    }
    prevMode.current = mode;
  }, [
    mode,
    searchQuery,
    cancelSearch,
    setIsSearchBarExpand,
    setShowSearchSelect,
  ]);

  useEffect(() => {
    if (!searchCustomerMode) {
      setSearchValue("");
      setSearchCustomerResult([]);
      setIsSearchBarExpand(false);
    }
  }, [searchCustomerMode]);

  useEffect(() => {
    if (showCustomerFilterBar) cancelSearch();
  }, [showCustomerFilterBar, cancelSearch]);

  const resizeScreen = useCallback(() => {
    if (!searchBlockRef.current) return;
    let inputWidth = searchBlockRef.current.offsetWidth;

    if (isSearchBarExpand) {
      setSearchWidth(
        inputWidth < currentSearchInputWidth
          ? currentSearchInputWidth
          : inputWidth
      );
    }
  }, [searchBlockRef, isSearchBarExpand]);

  useEffect(() => {
    resizeScreen();

    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  }, [resizeScreen]);

  const handleDebounceSearch = useCallback(
    async (limit) => {
      if (mode === "customerList" && searchValue) {
        const query = {
          query: debouncedValue,
        };

        if (limit) query.limit = limit;

        try {
          const { data } = await searchCustomer(query);

          if (!limit) {
            CustomerStateDispatch({
              type: "setSearchCustomerResult",
              searchCustomers: data.customers,
              searchCustomerMode: true,
              listPage: 1,
              listCount: data.meta.size,
            });
          } else {
            setSearchCustomerResult(data.customers);
            setShowSearchSelect(true);
          }
        } catch (err) {
          console.log("search customer err ----------", err);
        }
      }
    },
    [
      mode,
      searchValue,
      debouncedValue,
      CustomerStateDispatch,
    ]
  );

  useEffect(() => {
    let limit = 6;
    if (debouncedValue) handleDebounceSearch(limit);
  }, [debouncedValue, handleDebounceSearch]);

  const handleSearchValue = (e) => {
    const value = e.target.value;

    if (mode === "customerList") {
      CustomerStateDispatch({
        type: "setSearchCustomerText",
        searchCustomerText: value,
      });
    } else {
      MessageStateDispatch({
        type: "setSearchInputText",
        searchInputText: value,
      });
    }

    if (value.trim().length === 0 && !searchCustomerMode) {
      cancelSearch();
      setShowSearchSelect(false);
    }

    setSearchValue(value);
  };

  const resetSearchComplete = useCallback(() => {
    setFocusedId(null);
  }, []);

  const handleKeyDown = (e) => {
    const { key } = e;
    let nextIndexCount = 0;

    if (searchValue.trim().length === 0 || searchCustomerResult.length === 0)
      return;

    if (key === "ArrowDown") {
      nextIndexCount =
        (searchCustomerResult.findIndex((item) => item.id === focusedId) + 1) %
        searchCustomerResult.length;
      setFocusedId(searchCustomerResult[nextIndexCount].id);
    }

    if (key === "ArrowUp") {
      nextIndexCount =
        (searchCustomerResult.findIndex((item) => item.id === focusedId) +
          searchCustomerResult.length -
          1) %
        searchCustomerResult.length;
      setFocusedId(searchCustomerResult[nextIndexCount].id);
    }

    if (key === "Escape") {
      resetSearchComplete();
    }

    if (!isComposing && key === "Enter") {
      if (focusedId) return toCustomerPage(focusedId);

      handleToSearchList();
      setShowSearchSelect(false);

      browserHistory.push({
        pathname: "/dashboard/customers",
        query: {
          search: searchValue,
        },
      });
    }
  };

  const handleKeyPressEnter = (e) => {
    if (e.key === "Enter" && searchValue.trim().length !== 0 && !isComposing) {
      MessageStateDispatch({ type: "setSearchRes" });
      MessageStateDispatch({ type: "setSearchResSort", messageSort });
      MessageStateDispatch({
        type: "switchMessageStatus",
        messageStatus,
        searchMode: true,
      });
      MessageStateDispatch({ type: "setMessageFilter", messageFilter });
      MessageStateDispatch({ type: "setStatusMessagesNum" });

      browserHistory.push({
        pathname: "/dashboard/messages",
        query: {
          search: searchValue,
        },
      });
    }
  };

  const handleCreateCustomer = () => {
    CustomerStateDispatch({ type: "setAddCustomerModal", show: true });
    CustomerStateDispatch({ type: "cleanCustomerInfo" });
  };

  const renderTitle = () => {
    if (mode === "customerList") {
      if (searchCustomerMode) return t("list.searchResult");
      return t("list.title");
    } else {
      return t("messages:list.title");
    }
  };

  const handleToSearchList = () => {
    let limit = null;

    resetSearchComplete();
    handleDebounceSearch(limit);
  };

  const toCustomerPage = (id) => {
    browserHistory.push(window.location.pathname + "/" + id);
    resetSearchComplete();
  };

  const renderSearchCustomerResult = () => {
    return (
      <div className="search_customers_select" style={{ width: searchWidth }}>
        {searchCustomerResult.map((customer) => {
          return (
            <div
              key={customer.id}
              className="select_option"
              style={{
                backgroundColor:
                  customer.id === focusedId ? "rgba(240, 240, 240, 0.5)" : "",
              }}
              onClick={() => toCustomerPage(customer.id)}
            >
              <div className="s_name">{customer.last_name}</div>
              <div className="s_phone">{customer.phone_number}</div>
              <div className="s_note">{customer.note}</div>
            </div>
          );
        })}
        {searchCustomerResult.length > 0 ? (
          <div className="result_option" onClick={handleToSearchList}>
            <SearchOutlined className="search_icon" />
            {t("list.searchMatch", { text: searchCustomerText })}
          </div>
        ) : (
          <div className="result_option result_option_empty">
            <SearchOutlined className="search_icon" />
            {t("list.searchNotMatch", { text: searchCustomerText })}
          </div>
        )}
      </div>
    );
  };

  const renderSearch = () => {
    const placeholderText =
      mode === "customerList"
        ? t("list.searchCustomer")
        : t("messages:list.placeholder_search");

    return (
      <div className="block_search action-display-button">
        <div ref={searchBlockRef}>
          <ExpandSearchInput
            ref={searchInputRef}
            disabled={
              (mode === "customerList" && showCustomerFilterBar) ||
              customerFilterNum !== 0
            }
            handleInput={handleSearchValue}
            handlePress={
              mode === "customerList" ? handleKeyDown : handleKeyPressEnter
            }
            placeholder={placeholderText}
            setIsComposing={setIsComposing}
            controlled={true}
            isExpandControlled={isSearchBarExpand}
            clearResult={() => {
              setShowSearchSelect(false);
              setIsSearchBarExpand(false);
              cancelSearch();
            }}
            onExpandChange={setIsSearchBarExpand}
          />
        </div>
        {showSearchSelect && renderSearchCustomerResult()}
      </div>
    );
  };

  const renderAction = () => {
    if (mode === "customerList") {
      const handleCustomersCSV = async () => {
        try {
          setIsDownloading(true);
          const response = await getCustomersCSV();

          const url = URL.createObjectURL(
            new Blob([response.data], { type: "text/csv;charset=utf-8;" })
          );
          const a = document.createElement("a");
          const date = moment().format("YYYYMMDDhhmm");
          a.href = url;
          a.download = `menushop_customers_${shop.name}_${date}.csv`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error downloading CSV:", error);
        } finally {
          setIsDownloading(false);
        }
      };

      const handleFilterCustomersCSV = async (params) => {
        try {
          setIsDownloading(true);
          const response = await filterCustomersCSV(params);

          const url = URL.createObjectURL(
            new Blob([response.data], { type: "text/csv;charset=utf-8;" })
          );
          const a = document.createElement("a");
          const date = moment().format("YYYYMMDDhhmm");
          a.href = url;
          a.download = `menushop_customers_${shop.name}_${date}.csv`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error downloading CSV:", error);
        } finally {
          setIsDownloading(false);
        }
      };

      const handleExport = async () => {
        if (isDownloading) return;
        if (Object.keys(filterCustomersParams).length === 0) {
          handleCustomersCSV();
        } else {
          handleFilterCustomersCSV(filterCustomersParams);
        }
      };

      return (
        <Space size={searchCustomerMode ? 2 : 8}>
          <button
            className="action-display-button"
            onClick={handleExport}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <LoadingOutlined />
            ) : (
              <div className="icon-normal export-icon dark" />
            )}
          </button>
          <Link to={"/dashboard/crm/blocked"}>
            <button className="action-display-button">
              <div className="icon-normal blocked-icon" />
            </button>
          </Link>
          <button
            className="button-round-big button-primary"
            onClick={handleCreateCustomer}
          >
            <img
              src={require("../../../images/crm/plus_icon_white.svg")}
              alt=""
            />
            <span>{t("list.addCustomer")}</span>
          </button>
        </Space>
      );
    } else {
      return (
        <button
          className="button-round-big button-primary"
          onClick={() => MessageStateDispatch({ type: "setSelectMessagePath" })}
        >
          <img
            className="send_white_icon"
            src={require("../../../images/crm/sendMessage_icon.svg")}
            alt=""
          />
          <span>{t("messages:list.sentMessages")}</span>
        </button>
      );
    }
  };

  return (
    <div className="crmToolbar crmMessage_toolbar">
      <h2
        className={`title heading-2-responsive ${
          searchCustomerMode ? "title-search" : ""
        }`}
      >
        {renderTitle()}
      </h2>
      <Space
        className="crmMessage_toolbar_right"
        size={searchCustomerMode ? 2 : 8}
      >
        {renderSearch()}
        {renderAction()}
      </Space>
    </div>
  );
};

export default CrmToolbar;
