import React, { useState, useCallback, useEffect, useContext } from "react";
import { browserHistory } from "react-router";
import $ from "jquery";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";
import { ListStateContext } from "../NewModuleReducer/ListReducer";

import { time, useInterval } from "../utils/data";

const Clocker = () => {
  const { t } = useTranslation('bookingSystem');
  const { ListStateDispatch } = useContext(ListStateContext);

  const { CommomSettingState, CommomSettingDispatch } =
    useContext(CommomSettingContext);
  const {
    todayDate,
    date,
    isToday,
    moduleSelectedBookingTime,
    moduleTimePaused,
    hasSeletedBookingTime,
  } = CommomSettingState;
  //date  YYYY/MM/DD url上的日期
  //today YYYY/MM/DD

  const [timestamp, setTimestamp] = useState(moment());
  const [clockerTime, setClockerTime] = useState(0);
  const [clockerTimerDelay, setClockerTimerDelay] = useState(
    todayDate === date ? 1000 : null
  );
  const [hour, setHour] = useState(null);

  useEffect(() => {
    if (moduleTimePaused) {
      setClockerTimerDelay(null);
      setHour(null);

      if (todayDate !== date && !hasSeletedBookingTime) {
        setClockerTime(0);
      }
    } else {
      setClockerTimerDelay(1000);
    }
  }, [date, hasSeletedBookingTime, moduleTimePaused, todayDate]);

  useInterval(() => {
    setTimestamp(moment());

    //找尋現在時間
    for (let i = 0; i < time.length; i++) {
      const findTime = moment(new Date(todayDate + " " + time[i]));

      if (timestamp < findTime) {
        let hourIndex = i - 1;

        setHour(hourIndex);
        setClockerTime(hourIndex);

        if (moduleSelectedBookingTime !== 0) {
          CommomSettingDispatch({
            type: "setModuleSelectedBookingTime",
            moduleSelectedBookingTime: 0,
          });
        }
        break;
      }
    }
  }, clockerTimerDelay);

  useEffect(() => {
    $(".timerSlider").owlCarousel("destroy");

    $(".timerSlider").owlCarousel({
      dots: false,
      autoWidth: true,
      nav: true,
      items: 12,
      navText: ["", ""],
      startPosition: clockerTime,
    });
  }, [clockerTime]);

  const changeSelectedBookingTime = useCallback(
    (index) => {
      setClockerTimerDelay(null);
      CommomSettingDispatch({
        type: "changeSelectedBookingTime",
        moduleSelectedBookingTime: index,
      });
    },
    [CommomSettingDispatch]
  );

  const goNow = useCallback(() => {
    setClockerTimerDelay(1000);
    CommomSettingDispatch({ type: "goNow" });

    //change url date
    const date = moment(new Date(todayDate)).format("YYYY-MM-DD");
    browserHistory.push({
      pathname: "/dashboard/new_module",
      query: { date, mode: browserHistory.getCurrentLocation().query.mode },
    });

    ListStateDispatch({ type: "setActiveQueueStatusTab", date });
  }, [CommomSettingDispatch, ListStateDispatch, todayDate]);

  const renderButton = useCallback(() => {
    let paused = ""; //for go now btn
    if (moduleTimePaused) {
      paused = "nowBtn-paused";
    }
    return (
      <button
        className={`newModule__clock__nowBtn goNow ${paused}`}
        disabled={isToday && !hasSeletedBookingTime}
        onClick={() => goNow()}
      >
        {t('tableMap.current')}
      </button>
    );
  }, [goNow, hasSeletedBookingTime, isToday, moduleTimePaused, t]);

  const renderTime = useCallback(() => {
    return time.map((t, index) => {
      let active = "";
      let hourStyle = "";

      if (index === moduleSelectedBookingTime) {
        active = "timerSlider__cell-active";
      } else {
        active = "";
      }

      if (isToday && index === hour) {
        hourStyle = "timerSlider__cell-now";
      } else {
        hourStyle = "";
      }

      if (index % 2 !== 0) {
        return (
          <div
            className={`timerSlider__cell ${active} ${hourStyle}`}
            key={t}
            onClick={() => changeSelectedBookingTime(index)}
          >
            <i className="fa fa-circle" aria-hidden="true" />
          </div>
        );
      } else {
        return (
          <div
            className={`timerSlider__cell ${active} ${hourStyle}`}
            key={t}
            onClick={() => changeSelectedBookingTime(index)}
          >
            {t}
          </div>
        );
      }
    });
  }, [changeSelectedBookingTime, hour, isToday, moduleSelectedBookingTime]);

  return (
    <div className="newModule__clock">
      <div className="timerSliderWrap">
        <div className="timerSlider">{renderTime()}</div>
      </div>
      {renderButton()}
    </div>
  );
};

export default Clocker;
