import React from "react";
import { Modal, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const BlockedWarning = ({
  modalType = "addNew",
  addBookingSubmit,
  cancelBlockedWarning,
  isLoading,
}) => {
  const { t } = useTranslation("settings");
  const content = t("bookingSystem:popup.blocked_content", {
    type:
      modalType === "editor"
        ? t("bookingSystem:popup.edit")
        : t("bookingSystem:popup.add"),
  });

  return (
    <Modal
      title={t("bookingSystem:popup.blocked_title", {
        type:
          modalType === "editor"
            ? t("bookingSystem:popup.edit")
            : t("bookingSystem:popup.add"),
      })}
      visible
      destroyOnClose
      width={374}
      centered
      closable={false}
      className="modal-base modal-sm"
      footer={
        <Space size={8}>
          <button
            className="button-common button-secondary"
            disabled={isLoading}
            onClick={cancelBlockedWarning}
          >
            {t("cancel")}
          </button>
          <button
            className="button-common button-primary"
            disabled={isLoading}
            onClick={addBookingSubmit}
          >
            {isLoading ? <LoadingOutlined /> : t("ok")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {content}
    </Modal>
  );
};

export default BlockedWarning;
