import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import BookingSystemApi from "../../booking_settings/BookingSystemApi";

const API = new BookingSystemApi();

export default (newModuleBooking) => {
  const { t } = useTranslation("settings");
  return useCallback(
    (id, dataSubmit) => {
      API.updateBooking(id, dataSubmit)
        .then(() => {
          newModuleBooking();
        })
        .catch(() => {
          window.app.alert.setMessage(
            t("status.pls_tryAgainLater"),
            "error"
          );
        });
    },
    [newModuleBooking, t]
  );
};
