import React from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Modal, Space } from "antd";

import { announcementOfficialFetchToday } from "../../../actions/announcementAction";
import { updateOfficialAnnouncementDisplay } from "../api/OfficialAnnouncementApi";

const AnnouncementOfficialModal = ({
  item,
  displayOfficialAnnouncements,
  setDisplayOfficialAnnouncements,
}) => {
  const { t } = useTranslation("bulletinSystem");
  const dispatch = useDispatch();

  const closePopup = () => {
    let newOfficialAnnouncement = _.cloneDeep(displayOfficialAnnouncements);
    let idx = displayOfficialAnnouncements.findIndex((d) => (d.id = item.id));

    newOfficialAnnouncement.splice(idx, 1);
    setDisplayOfficialAnnouncements(newOfficialAnnouncement);
  };

  const closeAnnouncement = async (type) => {
    let setting = {};

    if (type === "close") {
      // 設定成關閉且明日還會顯示
      setting = {
        id: item.id,
        display_mode: "once",
        last_seen: new Date(),
      };
    } else if (type === "finish") {
      // 不再顯示
      setting = {
        id: item.id,
        display_mode: "never",
      };
    }

    try {
      await updateOfficialAnnouncementDisplay(setting);
      dispatch(announcementOfficialFetchToday());
      closePopup();
    } catch (err) {
      console.log("close announcement err ----------", err);
    }
  };

  return (
    <Modal
      title={t("list.systemAnnouncement")}
      visible
      centered
      destroyOnClose
      width={640}
      closable={false}
      className="modal-base announcementOfficialModal"
      footer={
        <Space size={10}>
          <button
            className="button-common button-secondary"
            onClick={() => closeAnnouncement("finish")}
          >
            {t("popup.doNotShowAgain")}
          </button>
          <button
            className="button-common button-primary"
            onClick={() => closeAnnouncement("close")}
          >
            {t("popup.knew")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className="announcement_title">{item.title}</div>
      <div className="announcement_content">{item.content}</div>
    </Modal>
  );
};

export default AnnouncementOfficialModal;
