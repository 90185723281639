import React from "react";
import { Modal, Space } from "antd";
import { useTranslation } from "react-i18next";

export const ConflictWarning = ({
  targetTableName,
  swapTableName,
  cancelSwap,
  checkSwap,
}) => {
  const { t } = useTranslation("bookingSystem");
  return (
    <Modal
      visible
      destroyOnClose
      width={374}
      closable={false}
      centered
      className="modal-base modal-sm"
      footer={
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={cancelSwap}
          >
            {t("settings:cancel")}
          </button>
          <button className="button-common button-primary" onClick={checkSwap}>
            {t("settings:ok")}
          </button>
        </Space>
      }
      title={t("popup.bookingOverlapping_title")}
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {t("popup.bookingOverlapping_content", {
        targetTableName,
        swapTableName,
      })}
    </Modal>
  );
};
