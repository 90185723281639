import React from 'react'
import $ from 'jquery'
import BindModal from './ShopFormBindModal'
import { withTranslation } from 'react-i18next';

var createReactClass = require('create-react-class');
export default withTranslation('settings')(createReactClass({
  getInitialState() {
    return { query: [], empty: false, shopBindModalVisible: false };
  },
  componentDidUpdate(prevProps){
    if(prevProps.query !== this.props.query){
      this.setState({ query:  this.props.query, empty:  this.props.empty });
    }
  },
  handleSelect(object_id) {
    this.setState({ query: [] });
    this.props.onSelect(object_id);
  },
  handleConfirm() {
    $(this.refs.confirm).modal('show')
  },
  renderQuery() {
    const { t } = this.props;
    if (this.props.empty) {
      return (
        <div className="shopBind_query_false">
          <div className="tt-menu tt-open">
            <div className="tt-dataset tt-dataset-best-pictures">
              {t('accountSettings.menu.notResultFound')}
            </div>
          </div>
        </div>
      )
    } else {
      if (this.state.query.length > 0) {
        var queries = this.state.query.map(function(query) {
          return (
            <div className="suggestion tt-suggestion tt-selectable" 
              key={query.objectId}
              onClick={ () => this.handleSelect(query.objectId) }
            >
              <strong>{query.name}</strong> - {query.address} - <b>{t('accountSettings.menu.images_sum') + '：' + (typeof(query.photoCount)!=='undefined' ? query.photoCount : 0 )}</b>
            </div>
          );
        }.bind(this));
        return (
          <div className="shopBind_query_success">
            <div className="tt-menu tt-open ">
              <div className="tt-dataset tt-dataset-best-pictures">
                { queries }
              </div>
            </div>
          </div>
        )
      }
    }
  },
  render() {
    const { t } = this.props;
    return (
      <div id="shopBind">
        <div className="shopBind">
          <div className="shopBind_input">
            <input
              id="queryShop"
              type="text"
              ref="query"
              className="form-control"
              style={{ float: "left" }}
              placeholder={t("accountSettings.menu.placeholder_enterLocation")}
              name="display_name"
              title="query"
            />
            <input
              className="btn btn-success"
              type="submit"
              value={t("search")}
              onClick={() => this.props.onQuery(this.refs.query.value)}
            />
          </div>
          {this.renderQuery()}
          <br />
          <button
            className="btn-notice"
            onClick={() => this.props.onFormSwitch()}
          >
            {t("accountSettings.menu.createOne")}
          </button>
          <BindModal
            select={this.props.select}
            visible={this.props.shopBindModalVisible}
            cancelSelect={this.props.onCancelSelect}
            logo={this.props.data.logo + "?" + new Date().getTime()}
            onSync={this.props.onSync}
          />
        </div>
      </div>
    );
  }
}));
