import React from "react";
import { Link } from "react-router";
import { config } from "../../utils/config";

export const Logo = ({ isLinkEnabled, showTestText = false }) => {
  if (isLinkEnabled) {
    return (
      <Link className="dashboard-nav__logo" to={"/dashboard"}>
        <img src={require("../../images/navbar/logo_512.png")} alt="Menu Shop" />
        {config.env !== "production" && showTestText && (
          <span className="dashboard-nav__test">測試</span>
        )}
      </Link>
    );
  }

  return (
    <div className="dashboard-nav__logo">
      <img src={require("../../images/newLogo.svg")} alt="Menu Shop" />
    </div>
  );
};

export const LogoWithName = ({ isLinkEnabled, callback }) => {
  if (isLinkEnabled) {
    return (
      <div onClick={callback}>
        <Link className="dashboard-nav__logoWithName" to={"/dashboard"}>
          <img
            src={require("../../images/navbar/MENUshop logo_dark.png")}
            alt="Menu Shop"
          />
        </Link>
      </div>
    );
  }

  return (
    <div className="dashboard-nav__logoWithName">
      <img src={require("../../images/navbar/logo_512.png")} alt="Menu Shop" />
    </div>
  );
};