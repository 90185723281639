import React from "react";
import $ from "jquery";
import { handleError } from "../../libs/handler";
import { withTranslation, Trans } from "react-i18next";
import { Tooltip, Switch } from "antd";
import SettingsSaveButton from "../commons/SettingsSaveButton";

class BookingSettingCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      init: false,
      isSaved: true,
      goRegister: false,
    };
  }
  routerWillLeave = (nextLocation) => {
    const { t } = this.props;
    // return false to prevent a transition w/o prompting the user,
    // or return a string to allow the user to decide:
    // return `null` or nothing to let other hooks to be executed
    //
    // NOTE: if you return true, other hooks will not be executed!
    if (!this.state.isSaved) return t("leaveNotice");
  };

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
    if (this.props.location.query.code !== undefined) {
      this.initToken(this.props.location.query.code);
    }
    $.ajax({
      url: window.domain + "/dashboard/booking_settings",
      method: "GET",
      xhrFields: { withCredentials: true },
    })
      .done(
        function (data) {
          this.setState(data);
        }.bind(this)
      )
      .fail(function (xhr) {
        handleError(xhr);
      });

    $.ajax({
      url: window.domain + "/dashboard/booking_settings/calendar_settings",
      method: "GET",
      xhrFields: { withCredentials: true },
    })
      .done(
        function (data) {
          this.setState(data);
          this.setState({ init: true });
        }.bind(this)
      )
      .fail(function (xhr) {
        handleError(xhr);
      });
  }

  onChange = (name) => {
    const newState = Object.assign({}, this.state);
    const res = !newState[name];
    let result = {};
    result[name] = res;
    this.setState(result, () => this.setState({ isSaved: false }));
  };

  submit = () => {
    const { t } = this.props;
    $.ajax({
      url: window.domain + "/dashboard/booking_settings/calendar_settings",
      data: {
        booking_settings: {
          calendar_enabled: this.state.calendar_enabled,
        },
        calendar_settings: {
          google_calendar_id: this.state.google_calendar_id,
        },
      },
      method: "PATCH",
      xhrFields: { withCredentials: true },
    })
      .done((data) => {
        this.setState({ isSaved: true });
        window.app.alert.setMessage(t("status.saved"), "done");
      })
      .fail(function (xhr) {
        window.app.alert.setMessage("error");
        window.app.alert.setMessage(xhr.responseJSON.errors.join(" "), "error");
        handleError(xhr);
      });
  };

  clickRegister = () => {
    this.setState({ goRegister: true });
    $.ajax({
      url: window.domain + "/dashboard/booking_settings/init_google_calendar",
      method: "GET",
      xhrFields: { withCredentials: true },
    })
      .done(function (data) {
        window.location = data;
      })
      .fail(function (xhr) {
        handleError(xhr);
      });
  };

  initToken = (auth_code) => {
    $.ajax({
      url: window.domain + "/dashboard/booking_settings/init_google_token",
      method: "POST",
      data: { auth_code: auth_code },
      async: false,
      xhrFields: { withCredentials: true },
    })
      .done(
        function (data) {
          this.setState();
        }.bind(this)
      )
      .fail(function (xhr) {
        handleError(xhr);
      });
  };

  render() {
    const { t } = this.props;
    // TODO: the checking is temporarily now, need to modify it.
    if (
      this.state.init &&
      (this.state.access_token === null ||
        this.state.access_token === "" ||
        !this.state.access_token)
    ) {
      return (
        <div className="setting-container">
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <h3>{t("synchronizeWithGoogleCalendar.title")}</h3>
              <h5>{t("synchronizeWithGoogleCalendar.description")}</h5>
            </div>
          </div>
          <hr />
          <div style={{ marginTop: 143 }}>
            <h5 style={{ fontSize: 24 }}>
              {t("synchronizeWithGoogleCalendar.content_before")}
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 59,
            }}
          >
            <button className="msBtn msBtnMain" onClick={this.clickRegister}>
              {t("synchronizeWithGoogleCalendar.start")}
            </button>
            <br />
            <hr />
          </div>
        </div>
      );
    }

    if (this.state.init) {
      const { t } = this.props;
      return (
        <>
          <div className="setting-container">
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <h3>{t("synchronizeWithGoogleCalendar.title")}</h3>
                <h5>{t("synchronizeWithGoogleCalendar.description")}</h5>
              </div>
            </div>
            <hr />
            <div className="rangeTitle">
              <img src={require("../../images/check_icon.png")} alt="check" />
              <h4 style={{ color: "#3fba87", fontWeight: "bold" }}>
                {t("synchronizeWithGoogleCalendar.integratedGoogleCalendar")}
              </h4>
            </div>
            <div
              className="reservation-settings "
              style={{ marginTop: 41, paddingTop: 0 }}
            >
              <h5 style={{ fontSize: "18px" }}>
                <Trans
                  i18nKey="settings:synchronizeWithGoogleCalendar.calendarHasBeenCreated"
                  values={{
                    email: this.state.user_google_email,
                    calendar: this.state.google_calendar_name,
                  }}
                  components={{
                    1: <span style={{ color: "#3fba87" }} />,
                    2: <br />,
                    3: <span style={{ color: "#3fba87" }} />,
                  }}
                />
                <br />
                {t('synchronizeWithGoogleCalendar.viewBookingsInGoogleCalendar')}
              </h5>
            </div>
            <div
              className="settingTbl"
              style={{ marginTop: 28, marginLeft: 0 }}
            >
              <div className="settingTblRow">
                <div className="header" style={{ flexBasis: "30%" }}>
                  <Tooltip
                    placement="bottom"
                    overlayClassName="setting-tooltip"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    title={t(
                      "synchronizeWithGoogleCalendar.synchronizeBookingData_tooltip"
                    )}
                  >
                    {t("synchronizeWithGoogleCalendar.synchronizeBookingData")}
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </div>
                <div className="text">
                  <Switch
                    onChange={() => this.onChange("calendar_enabled")}
                    checked={this.state.calendar_enabled}
                  />
                </div>
              </div>
            </div>
          </div>
          <SettingsSaveButton handleSaved={this.submit} showDivider />
        </>
      );
    } else {
      return <div className="pageLoading">{t('status.loading')}</div>;
    }
  }
}

export default withTranslation("settings")(BookingSettingCalendar);
