import React, { useState, useCallback, useEffect, useContext } from "react";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";

import { TableBookingCheckInfo } from "./CheckInfo";
import { tableBookingNum } from "../utils/data";
import NotEnoughWarning from "../../AddNewBooking/items/NotEnoughWarning";

//reducer
import { BaseSettingContext } from "../NewModuleReducer/BaseSettingReducer";
import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";

const TableBookingPanel = ({
  tableBookingAttendance,
  checkToSeated,
  backToChooseTable,
  updateBooking,
  closeTableBookingPanel,
  setLoading,
  isBookingLoading,
  setIsBookingLoading,
  tableBookingDateTime,
  tableBookingSubmit,
  spg_hash_iv,
  spg_hash_key,
  spg_merchant_id,
  merchant_verified,
}) => {
  const { t } = useTranslation("bookingSystem");
  const { BaseSettingState } = useContext(BaseSettingContext);
  const { customQ, customQAns, diningTime } = BaseSettingState;

  const { CommomSettingState } = useContext(CommomSettingContext);
  const { isToday, date, selectedBookingTableData } = CommomSettingState;

  const [table, setTable] = useState(selectedBookingTableData);
  const [step, setStep] = useState(1); //1選桌子 2輸入時間 3填寫資訊
  const [currentTime] = useState(tableBookingDateTime);
  const [bookingStartTime, setBookingStartTime] = useState(null);
  const [bookingLeaveTime, setBookingLeaveTime] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [attendanceDiff, setAttendanceDiff] = useState(0);

  useEffect(() => {
    setTable(selectedBookingTableData);
  }, [selectedBookingTableData]);

  const renderSelectedTableCount = useCallback(() => {
    const seatMaxAttendance = table.reduce(
      (prev, cur) => prev + cur.max_attendance,
      0
    );

    const chosenTableStyle = table.length > 0 ? "chosenTableStyle" : "";
    return (
      <React.Fragment>
        <p>
					<Trans
						i18nKey="bookingSystem:tableMap.selectedTable"
						values={{ tables: table.length }}
						components={{
							countStyle: <span className={chosenTableStyle} />,
						}}
					/>
        </p>
        <p>
					<Trans
						i18nKey="bookingSystem:tableMap.tableCapacity"
						values={{ attendance: seatMaxAttendance }}
						components={{
							countStyle: <span className={chosenTableStyle} />,
						}}
					/>
        </p>
      </React.Fragment>
    );
  }, [table]);

  const toStep2 = () => {
    const seatMaxAttendance = table.reduce(
      (prev, cur) => prev + cur.max_attendance,
      0
    );

    if (parseInt(tableBookingAttendance, 10) > seatMaxAttendance) {
      setAttendanceDiff(
        Math.abs(parseInt(tableBookingAttendance, 10) - seatMaxAttendance)
      );
      setShowWarning(true);
    } else {
      setStep(2);
      checkToSeated();
    }
  };

  const toStep3 = (startTime, leaveTime) => {
    setBookingStartTime(startTime.format("YYYY/MM/DD HH:mm"));
    setBookingLeaveTime(leaveTime.format("YYYY/MM/DD HH:mm"));

    setStep(3);
  };

  const backToStep1 = () => {
    setStep(1);
    backToChooseTable();
  };

  const cancelWarning = () => {
    setShowWarning(false);
  };

  const checkWarning = () => {
    setShowWarning(false);
    setStep(2);
    checkToSeated();
  };

  const renderStep = () => {
    if (step === 1) {
      return (
        <div className="seatedPanel tableBookingPanel">
          <div className="tableBookingPanel__header">
            <i className="fa fa-calendar-check-o" aria-hidden="true" />
            <span>{tableBookingDateTime.format("YYYY/MM/DD HH:mm")}</span>
          </div>
          <div className="seatedPanel__body tableBookingPanel__body">
            <div className="tableBookingPanel__body__top">
              <Trans
                i18nKey="bookingSystem:tableMap.totalAttendance_2"
                values={{ attendance: tableBookingAttendance }}
                components={{ countStyle: <span /> }}
              />
            </div>
            {renderSelectedTableCount()}
          </div>
          <div
            className={`seatedPanel__bottom tableBookingPanel__bottom ${emptyTableStyle}`}
          >
            <button onClick={() => toStep2()} disabled={table.length === 0}>
              {t("buttonStatus.seated")}
            </button>
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <TableBookingTimePanel
          date={date}
          diningTime={diningTime}
          currentTime={currentTime}
          table={table}
          backToStep1={backToStep1}
          bookingSubmit={toStep3}
        />
      );
    } else if (step === 3) {
      return (
        <TableBookingCheckInfo
          selectedBookingTableData={selectedBookingTableData}
          customQ={customQ}
          customQAns={customQAns}
          bookingStartTime={bookingStartTime}
          bookingLeaveTime={bookingLeaveTime}
          tableBookingAttendance={tableBookingAttendance}
          updateBooking={updateBooking}
          closeTableBookingPanel={closeTableBookingPanel}
          isToday={isToday}
          setLoading={setLoading}
          isBookingLoading={isBookingLoading}
          setIsBookingLoading={setIsBookingLoading}
          tableBookingSubmit={tableBookingSubmit}
          spg_hash_iv={spg_hash_iv}
          spg_hash_key={spg_hash_key}
          spg_merchant_id={spg_merchant_id}
          merchant_verified={merchant_verified}
        />
      );
    }
  };

  const emptyTableStyle = table.length === 0 ? "emptyTableStyle" : "";

  return (
    <React.Fragment>
      {renderStep()}
      {showWarning && (
        <NotEnoughWarning
          attendanceDiff={attendanceDiff}
          checkWarning={checkWarning}
          cancelWarning={cancelWarning}
        />
      )}
    </React.Fragment>
  );
};

const TableBookingTimePanel = ({
  date,
  currentTime,
  table,
  diningTime,
  backToStep1,
  bookingSubmit,
}) => {
	const { t } = useTranslation("bookingSystem");
  const [longestDiningTime] = useState(23 * 60 + 55); //最長diningTime 23小時 55分
  const [SeatedTimePanelCount, setSeatedTimePanelCount] = useState(0);
  const [displayTime, setDisplayTime] = useState("");
  const [timeCell, setTimeCell] = useState(["-", "-", ":", "-", "-"]);
  const [latestBookingTime, setLatestBookingTime] = useState([]);

  useEffect(() => {
    let closestTime = diningTime;

    if (table.length !== 0) {
      //計算最短dining time
      table.map((t) => {
        if (Object.keys(t.nextBooking).length !== 0) {
          let bookingTimestamp = moment(t.nextBooking.booking_datetime);
          let bookingDiff = moment.duration(bookingTimestamp.diff(currentTime)),
            durationDiff = bookingDiff.asMinutes();

          if (closestTime > durationDiff) {
            closestTime = durationDiff;
          }
        }
        return null;
      });
    }

    //最晚預約時間longestDiningTime
    //顯示時間預設dining time
    const LatestBookingTime = moment(currentTime)
      .add(longestDiningTime, "m")
      .format("YYYY/MM/DD HH:mm");
    const time = moment(currentTime)
      .add(diningTime, "m")
      .format("HH:mm")
      .split("");
    setTimeCell(time);
    setLatestBookingTime(LatestBookingTime);

    // if (!hasNextBooking) {
    // 	//所選的桌子都沒有下一筆預約:
    // 	//最晚預約時間longestDiningTime
    // 	//顯示時間預設dining time
    // 	const LatestBookingTime = moment(currentTime).add(longestDiningTime, 'm').format('YYYY/MM/DD HH:mm');
    // 	const time = moment(currentTime).add(diningTime, 'm').format('HH:mm').split('');
    // 	setTimeCell(time);
    // 	setLatestBookingTime(LatestBookingTime);
    // } else {
    // 	//所選的桌子都有下一筆預約: 最短的dining time
    // 	// console.log('closestTime----', closestTime);
    // 	const LatestBookingTime = moment(currentTime).add(closestTime, 'm').format('YYYY/MM/DD HH:mm');
    // 	const time = moment(currentTime).add(closestTime, 'm').format('HH:mm').split('');
    // 	setTimeCell(time);
    // 	setLatestBookingTime(LatestBookingTime);
    // }
  }, [table, currentTime, longestDiningTime, diningTime]);

  useEffect(() => {
    const time = timeCell.join("");
    setDisplayTime(time);
  }, [timeCell]);

  const timeClick = (keyboard) => {
    let newTime = displayTime.split("");

    if (SeatedTimePanelCount === 0) {
      newTime = ["-", "-", ":", "-", "-"];
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(1);
    } else if (SeatedTimePanelCount === 1) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(3);
    } else if (SeatedTimePanelCount === 3) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(4);
    } else if (SeatedTimePanelCount === 4) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(0);
    }

    setTimeCell(newTime);
  };

  const timeDelete = () => {
    let newTime = displayTime.split("");

    if (newTime[0] === "-") return;

    if (SeatedTimePanelCount === 0) {
      newTime[4] = "-";
      setSeatedTimePanelCount(4);
    } else if (SeatedTimePanelCount === 4) {
      newTime[3] = "-";
      setSeatedTimePanelCount(3);
    } else if (SeatedTimePanelCount === 3) {
      newTime[1] = "-";
      setSeatedTimePanelCount(1);
    } else if (SeatedTimePanelCount === 1) {
      newTime[0] = "-";
      setSeatedTimePanelCount(0);
    }

    setTimeCell(newTime);
  };

  const timeCheck = () => {
    const displayTime_hour = parseInt(displayTime.substring(0, 2), 10),
      displayTime_min = parseInt(displayTime.substring(3), 10);

    if (displayTime_hour >= 24 || displayTime_min > 59) {
      return window.app.alert.setMessage(
        t("tableMap.pls_enterCorrectTimeFormat"),
        "error"
      );
    }

    if (displayTime_min % 5 !== 0) {
      return window.app.alert.setMessage(
        t("settings:bookingTimesCommon.pls_minimumTimeUnit"),
        "error"
      );
    }

    const todayDate = moment(new Date(date)).format("YYYY-MM-DD"),
      tomorrowDate = moment(new Date(date)).add(1, "day").format("YYYY-MM-DD");

    const latestBookingTimeMoment = moment(new Date(latestBookingTime));

    const current = moment(currentTime);
    const displayTimeMoment_today = moment(todayDate + " " + displayTime);
    const displayTimeMoment_tomorrow = moment(tomorrowDate + " " + displayTime);

    // const expiredTable = $('.queueTable.removeStyle').length;

    // if (expiredTable > 0) {
    // 	return window.app.alert.setMessage('請先移除將有顧客入座之座位', 'error');
    // }

    if (
      displayTimeMoment_today.isAfter(current) &&
      displayTimeMoment_today.isSameOrBefore(latestBookingTimeMoment)
    ) {
      bookingSubmit(currentTime, displayTimeMoment_today);
    } else if (
      displayTimeMoment_tomorrow.isAfter(current) &&
      displayTimeMoment_tomorrow.isSameOrBefore(latestBookingTimeMoment)
    ) {
      bookingSubmit(currentTime, displayTimeMoment_tomorrow);
    } else {
      window.app.alert.setMessage(t("tableMap.pls_outOfTimeRange"), "error");
    }
  };

  const renderKeyboardCell = () => {
    return tableBookingNum.map((keyboard, index) => {
      if (keyboard === "delete") {
        return (
          <div
            key={index}
            className="keyboardCell keyboardCell-del"
            onClick={() => timeDelete()}
          >
            <img
              className="numPad_del"
              src={require("../../../images/newModule/delete.svg")}
              alt="delete"
            />
          </div>
        );
      } else if (keyboard === "check") {
        return (
          <div
            key={index}
            className="keyboardCell keyboardCell-check"
            onClick={() => timeCheck()}
          >
            {t("settings:ok")}
          </div>
        );
      } else {
        return (
          <div
            key={index}
            className="keyboardCell"
            onClick={() => timeClick(keyboard)}
          >
            {keyboard}
          </div>
        );
      }
    });
  };

  return (
    <div className="seatedTimePanelWrap tableBookingTimePanel">
      <span className="seatedTimePanel__back">
        <button onClick={() => backToStep1()}>
          {t("popup.rearrangeTable")}
        </button>
      </span>
      <div className="seatedTimePanel text-center">
        <div className="seatedTimePanel__title">
          {t("tableMap.estimatedDepartureTime")}
        </div>
        <div className="seatedTimePanel__header">
          <span style={{ fontWeight: "bold" }}>{displayTime}</span>
        </div>
        <div className="seatedTimePanel__body">
          <div className="keyboard">{renderKeyboardCell()}</div>
        </div>
      </div>
    </div>
  );
};

export { TableBookingPanel };
