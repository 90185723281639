import BookingSystemApi from '../components/booking_settings/BookingSystemApi';
import moment from 'moment';
import { browserHistory } from 'react-router';

export const NEWADDBOOKING_FETCHSTART = 'NEWADDBOOKING_FETCHSTART';
export const NEWADDBOOKING_FETCHSUCCESS = 'NEWADDBOOKING_FETCHSUCCESS';
export const NEWADDBOOKING_FETCHFAILURE = 'NEWADDBOOKING_FETCHFAILURE';

export const NEWADDBOOKING_GETTABLE = 'NEWADDBOOKING_GETTABLE';
export const NEWADDBOOKING_SEARCHING = 'NEWADDBOOKING_SEARCHING';

export const ADDBOOKING = 'ADDBOOKING';
export const EDITBOOKING = 'EDITBOOKING';

export const NEWADDBOOKING_UPDATEDATA = 'NEWADDBOOKING_UPDATEDATA';
export const NEWADDBOOKING_GETCQ = 'NEWADDBOOKING_GETCQ';
export const NEWADDBOOKING_HANDLETAGCHANGE = 'NEWADDBOOKING_HANDLETAGCHANGE';
export const NEWADDBOOKING_HANDLEQUESTIONCHANGE = 'NEWADDBOOKING_HANDLEQUESTIONCHANGE';
export const NEWADDBOOKING_HANDLEQUANTITYCHANGE = 'NEWADDBOOKING_HANDLEQUANTITYCHANGE';
export const NEWADDBOOKING_HANDLECUSTOMERINFOCHANGE = 'NEWADDBOOKING_HANDLECUSTOMERINFOCHANGE';
export const NEWADDBOOKING_HANDLEPHONEKEYIN = 'NEWADDBOOKING_HANDLEPHONEKEYIN';
export const NEWADDBOOKING_TYPEHEADDATA = 'NEWADDBOOKING_TYPEHEADDATA';
export const NEWADDBOOKING_PHONENUMPADVISIBLE = 'NEWADDBOOKING_PHONENUMPADVISIBLE';
export const NEWADDBOOKING_TOGGLECUSTOMERMEMO = 'NEWADDBOOKING_TOGGLECUSTOMERMEMO';
export const NEWADDBOOKING_TOGGLEONSITE = 'NEWADDBOOKING_TOGGLEONSITE';
export const NEWADDBOOKING_SETTABLECONDITION = 'NEWADDBOOKING_SETTABLECONDITION';
export const NEWADDBOOKING_ATTENDANCENUMPADVISIBLE = 'NEWADDBOOKING_ATTENDANCENUMPADVISIBLE';
export const NEWADDBOOKING_HANDLEATTENDANCECHANGE = 'NEWADDBOOKING_HANDLEATTENDANCECHANGE';
export const NEWADDBOOKING_BOOKINGATTENDACNE = 'NEWADDBOOKING_BOOKINGATTENDACNE';
export const NEWADDBOOKING_SENDTABLETIME = 'NEWADDBOOKING_SENDTABLETIME';
export const NEWADDBOOKING_TOGGLEDATEPICKER = 'NEWADDBOOKING_TOGGLEDATEPICKER';
export const NEWADDBOOKING_TOGGLETABLEPICKER = 'NEWADDBOOKING_TOGGLETABLEPICKER';
export const NEWADDBOOKING_TIMECLICK = 'NEWADDBOOKING_TIMECLICK';
export const NEWADDBOOKING_CHOOSEDATE = 'NEWADDBOOKING_CHOOSEDATE';
export const NEWADDBOOKING_DATEPICKERSUBMIT = 'NEWADDBOOKING_DATEPICKERSUBMIT';
export const NEWADDBOOKING_RESETBOOKINGTIME = 'NEWADDBOOKING_RESETBOOKINGTIME';
export const NEWADDBOOKING_TABLEPICKERSUBMIT = 'NEWADDBOOKING_TABLEPICKERSUBMIT';
export const NEWADDBOOKING_RESETTABLEPICKER = 'NEWADDBOOKING_RESETTABLEPICKER';
export const NEWADDBOOKING_SERVICETIMECHANGE = 'NEWADDBOOKING_SERVICETIMECHANGE';
export const NEWADDBOOKING_HIDEONSPOT = 'NEWADDBOOKING_HIDEONSPOT';
export const NEWADDBOOKING_SETCUSTOMERINFO = 'NEWADDBOOKING_SETCUSTOMERINFO';
export const NEWADDBOOKING_RESETTABLECONDITION = 'NEWADDBOOKING_RESETTABLECONDITION';
export const NEWADDBOOKING_SETTINGBOOKINGTIME = 'NEWADDBOOKING_SETTINGBOOKINGTIME';

export const NEWADDBOOKING_RESETDEFAULT_SEATCONDITION = 'NEWADDBOOKING_RESETDEFAULT_TABLECONDITION';
export const NEWADDBOOKING_RESETDEFAULT_UI = 'NEWADDBOOKING_RESETDEFAULT_UI';
export const NEWADDBOOKING_RESETDEFAULT_CUSTOMERQ = 'NEWADDBOOKING_RESETDEFAULT_CUSTOMERQ';
export const NEWADDBOOKING_RESETDEFAULT_CUSTOMERDETAIL = 'NEWADDBOOKING_RESETDEFAULT_CUSTOMERDETAIL';

export const NEWADDBOOKING_EDIT_SEAT_STATE = 'NEWADDBOOKING_EDIT_SEAT_STATE';
export const NEWADDBOOKING_EDIT_FETCHSUCCESS = 'NEWADDBOOKING_EDIT_FETCHSUCCESS';
export const NEWADDBOOKING_HANDLEDEPOSITDETAILCHANGE = 'NEWADDBOOKING_HANDLEDEPOSITDETAILCHANGE';
export const NEWADDBOOKING_HANDLEDEPOSITNOTIFICATIONERROR = 'NEWADDBOOKING_HANDLEDEPOSITNOTIFICATIONERROR';
export const NEWADDBOOKING_HANDLEDEPOSITNOTIFICATIONRESEND = 'NEWADDBOOKING_HANDLEDEPOSITNOTIFICATIONRESEND';
export const NEWADDBOOKING_HANDLESEATCELL = "NEWADDBOOKING_HANDLESEATCELL";

const api = new BookingSystemApi();

export function NewAddBooking_ResetDefault() {
	return (dispatch) => {
		dispatch({ type: NEWADDBOOKING_RESETDEFAULT_SEATCONDITION });
		dispatch({ type: NEWADDBOOKING_RESETDEFAULT_UI });
		dispatch({ type: NEWADDBOOKING_RESETDEFAULT_CUSTOMERQ });
		dispatch({ type: NEWADDBOOKING_RESETDEFAULT_CUSTOMERDETAIL });
	};
}

function fetchSettingSuccess(data, time) {
	return {
		type: NEWADDBOOKING_FETCHSUCCESS,
		data,
		time
	};
}

export function editFetchSetting() {
	return async (dispatch, getState) => {
		const state = getState().NewAddBooking_SeatCondition;
		let tableData = {
			attendance: state.bookingAttend,
			date: state.tempBookingTime.date,
			dining_time: parseInt(state.severTime_hour, 10) * 60 + parseInt(state.severTime_min, 10),
			table_order_sort: true
		};

		if (state.newSelected.id !== null) {
			tableData['id'] = state.newSelected.id;
		}

		try {
			const data = await api.getBookingTable(tableData);

			dispatch({
				type: NEWADDBOOKING_EDIT_FETCHSUCCESS,
				data,
			});

		} catch (error) {
			dispatch({ type: NEWADDBOOKING_FETCHFAILURE, error });
		}
	};
}

export function fetchSetting(time) {
	return async (dispatch, getState) => {
		const state = getState().NewAddBooking_SeatCondition;
		const onSite = getState().NewAddBooking_CustomerInfo.onSite;
		let tableData = {
			attendance: state.bookingAttend,
			date: state.tempBookingTime.date,
			dining_time: parseInt(state.severTime_hour, 10) * 60 + parseInt(state.severTime_min, 10),
			table_order_sort: true
		};

		if (state.newSelected.id !== null) {
			tableData['id'] = state.newSelected.id;
		}

		if (state.bookingAttend === '') return;

		dispatch({ type: NEWADDBOOKING_FETCHSTART });
		try {
			const data = await api.getBookingTable(tableData);
			dispatch(fetchSettingSuccess(data, time));

			const hour = getState().NewAddBooking_SeatCondition.bookingTime.hour;

			if (onSite && hour === '') {
				dispatch(toggleDatepicker(false));
			}
		} catch (error) {
			dispatch({ type: NEWADDBOOKING_FETCHFAILURE, error });
		}
	};
}

export function addBooking(date, serviceTime, autoTable) {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({ type: ADDBOOKING });
			dispatch({ type: NEWADDBOOKING_SETCUSTOMERINFO });
			dispatch(setTableCondition(date, serviceTime, autoTable));
			resolve();
		});
	};
}

export function editBooking(editBooking) {
	let status = editBooking.memo && editBooking.memo !== '' ? false : true;

	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({ type: EDITBOOKING });
			// dispatch({ type: NEWADDBOOKING_SEARCHING });
			dispatch({ type: NEWADDBOOKING_HIDEONSPOT });
			dispatch({ type: NEWADDBOOKING_SETCUSTOMERINFO, editBooking });
			dispatch(toggleCustomerMemo(status));
			console.log('dispathc updateData');
			dispatch(updateData(editBooking));
			dispatch({ type: NEWADDBOOKING_EDIT_SEAT_STATE });
			dispatch(editFetchSetting());
			// dispatch(fetchSetting());
			resolve();
		});
	};
}

export function updateData(editBooking) {
	return {
		type: NEWADDBOOKING_UPDATEDATA,
		editBooking
	};
}

export function handleClickSeatAddBooking(dateTime, tableInfo) {
	return (dispatch) => {
		dispatch(setBookingTime({ bookingTime_date: dateTime }));
		dispatch(handleClickSeatInfo(tableInfo));
	};
}

export function handleClickSeatInfo(tableInfo) {
	return {
		type: NEWADDBOOKING_HANDLESEATCELL,
		tableInfo
	};
}

export function handleChooseDate(e, date) {
	return (dispatch) => {
		dispatch(chooseDate(e, date));
		dispatch(fetchSetting('time'));
	};
}

export function getCQ(customQ, customQAns) {
	return {
		type: NEWADDBOOKING_GETCQ,
		customQ,
		customQAns
	};
}

export function handleTagChange(id, multiple, optionId) {
	return {
		type: NEWADDBOOKING_HANDLETAGCHANGE,
		id,
		multiple,
		optionId
	};
}

export function handleQuestionChange(id, e) {
	return {
		type: NEWADDBOOKING_HANDLEQUESTIONCHANGE,
		id,
		e
	};
}

export function handleQuantityChange(id, e, multiple, optionId) {
	return {
		type: NEWADDBOOKING_HANDLEQUANTITYCHANGE,
		id,
		e,
		multiple,
		optionId
	};
}

export function handleInfoChange(e, infoType, addType = 'booking') {
	return {
		type: NEWADDBOOKING_HANDLECUSTOMERINFOCHANGE,
		e,
		infoType,
		addType
	};
}

export function phoneTypeahead(e) {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch(phoneNumpadKeyIn(e));
			resolve();
		});
	};
}

export function setTypehead(data) {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch(typeheadData(data));
			resolve();
		});
	};
}

export function phoneNumpadVisible(status) {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch(handlePhoneNumpadVisible(status));
			resolve();
		});
	};
}

export function toggleCustomerMemo(status) {
	return {
		type: NEWADDBOOKING_TOGGLECUSTOMERMEMO,
		status
	};
}

export function attendanceNumpadVisible(status, attendanceChange) {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch(handleAttendanceNumpadVisible(status));
			dispatch(handleBookingAttendChange(attendanceChange));
			resolve();
		});
	};
}

export function handleAttendanceNumpadVisible(status) {
	return {
		type: NEWADDBOOKING_ATTENDANCENUMPADVISIBLE,
		status
	};
}

export function bookingAttendance(attendance) {
	return {
		type: NEWADDBOOKING_BOOKINGATTENDACNE,
		attendance
	};
}

export function handleBookingAttendChange(attendanceChange) {
	return {
		type: NEWADDBOOKING_HANDLEATTENDANCECHANGE,
		attendanceChange
	};
}

export function resetTableCondtion() {
	return {
		type: NEWADDBOOKING_RESETTABLECONDITION
	};
}

export function sendTableTimeOpenDatepicker() {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({ type: NEWADDBOOKING_SENDTABLETIME });
			let load = getState().NewAddBooking_SeatCondition.loadingTime;
			let hour = getState().NewAddBooking_SeatCondition.bookingTime.hour,
				min = getState().NewAddBooking_SeatCondition.bookingTime.min;
			let onSite = getState().NewAddBooking_CustomerInfo.onSite;

			if (onSite && hour !== '' && min !== '') {
				dispatch(fetchSetting());
			} else {
				if (load) {
					dispatch(toggleDatepicker(false));
				} else {
					dispatch(toggleDatepicker(true));
				}
				dispatch(fetchSetting());
			}

			resolve(load);
		});
	};
}

export function handleTimeClick(timeType, time) {
	return {
		type: NEWADDBOOKING_TIMECLICK,
		timeType,
		time
	};
}

export function datepickerSubmit() {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({ type: NEWADDBOOKING_DATEPICKERSUBMIT });
			dispatch({ type: NEWADDBOOKING_GETTABLE });
			let seats = getState().NewAddBooking_SeatCondition.seating;
			resolve(seats);
		});
	};
}

export function toggleDatepicker(status) {
	return {
		type: NEWADDBOOKING_TOGGLEDATEPICKER,
		status
	};
}

export function toggleTablepicker(status) {
	return {
		type: NEWADDBOOKING_TOGGLETABLEPICKER,
		status
	};
}

export function resetBookingTime() {
	return {
		type: NEWADDBOOKING_RESETBOOKINGTIME
	};
}

export function tablepickerSubmit(tempSeating, chosenGroup) {
	return {
		type: NEWADDBOOKING_TABLEPICKERSUBMIT,
		tempSeating,
		chosenGroup
	};
}

export function resetTablepicker() {
	return {
		type: NEWADDBOOKING_RESETTABLEPICKER
	};
}

export function serviceTimeChange(serviceType, t) {
	return (dispatch) => {
		dispatch({ type: NEWADDBOOKING_SERVICETIMECHANGE, serviceType, t });
		dispatch(fetchSetting());
	};
}

export function onSiteNsetTime() {
	return (dispatch, getState) => {
		dispatch(toggleOnSite());
		let onSite = getState().NewAddBooking_CustomerInfo.onSite;
		let bookingTime_hour = getState().NewAddBooking_SeatCondition.bookingTime.hour,
			bookingTime_min = getState().NewAddBooking_SeatCondition.bookingTime.min;
		let attendance = getState().NewAddBooking_SeatCondition.bookingAttend;

		if (onSite && bookingTime_hour === '' && bookingTime_min === '') {
			let bookingTime_min = moment().minute();
			let diff = 5 - bookingTime_min % 5;
			let bookingTime_date = moment().add(diff, 'm').format('YYYY/MM/DD HH:mm');
			let today = moment().format('YYYY-MM-DD');
			let urlDate = browserHistory.getCurrentLocation().query.date;
			let queryDate = moment(new Date(urlDate)).format('YYYY-MM-DD');
			if (today === queryDate) {
				dispatch(setBookingTime({ bookingTime_date }));
				if (attendance !== '') {
					dispatch({ type: NEWADDBOOKING_GETTABLE });
				}
			}
		}
	};
}

function setBookingTime({ bookingTime_date }) {
	return {
		type: NEWADDBOOKING_SETTINGBOOKINGTIME,
		bookingTime_date
	};
}

export function toggleOnSite() {
	return {
		type: NEWADDBOOKING_TOGGLEONSITE
	};
}

function setTableCondition(date, serviceTime, autoTable) {
	return {
		type: NEWADDBOOKING_SETTABLECONDITION,
		date,
		serviceTime,
		autoTable
	};
}

function handlePhoneNumpadVisible(status) {
	return {
		type: NEWADDBOOKING_PHONENUMPADVISIBLE,
		status
	};
}

function typeheadData(data) {
	return {
		type: NEWADDBOOKING_TYPEHEADDATA,
		data
	};
}

function phoneNumpadKeyIn(e) {
	return {
		type: NEWADDBOOKING_HANDLEPHONEKEYIN,
		e
	};
}

function chooseDate(e, date) {
	return {
		type: NEWADDBOOKING_CHOOSEDATE,
		date
	};
}

export function handleDepositDetailChange(e, detailType) {
	return {
		type: NEWADDBOOKING_HANDLEDEPOSITDETAILCHANGE,
		e,
		detailType
	};
}

export function handleDepositNotificationError(e) {
	return {
		type: NEWADDBOOKING_HANDLEDEPOSITNOTIFICATIONERROR,
		e
	};
}

export function handleDepositNotificationResend(e) {
	return {
		type: NEWADDBOOKING_HANDLEDEPOSITNOTIFICATIONRESEND,
		e
	};
}
