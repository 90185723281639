import React, { Component } from "react";
import { Link } from "react-router";
import moment from "moment";
import { getEstimatedWaitingTime } from "./QueuePanelHelper";
import { Space } from "antd";
import { withTranslation } from "react-i18next";

import QueuePanelAPI from "./api/QueuePanelAPI";
import {
  QueueActionCall,
  QueueActionCancel,
  QueueActionSeated,
  QueueActionMessage,
} from "./QueueListItemAction";

class QueueListItem extends Component {
  state = {
    notificationLoading: false,
    seatedLoading: false,
    standbyLoading: false,
    hideMailBtn: false,
    editing: false,
    shopmemoTxt: "",
    tempShopmemoTxt: "",
  };

  sendNotification = (id) => {
    this.setState({
      notificationLoading: true,
    });
    setTimeout(() => {
      this.API.sendNotification(id)
        .then((data) => {
          if (data !== undefined) {
            this.props.refreshAction();
          }
        })
        .catch((error) => {
          window.app.alert.setMessage("請重新再試一次", "error");
          console.log("sendNotification error:", error);
        })
        .finally(() => {
          this.setState({
            notificationLoading: false,
          });
        });
    }, 500);
  };

  changeListItemToSeated = (id) => {
    this.setState({
      seatedLoading: true,
    });
    setTimeout(() => {
      this.API.updateQueueRecord(
        id,
        "seated",
        moment().diff(moment(this.props.data.created_at), "minutes"),
        (data) => {
          this.setState({
            seatedLoading: false,
          });
          if (data !== undefined) {
            this.props.refreshAction();
          }
        }
      );
    }, 500);
  };

  changeListItemToStandby = (id) => {
    this.setState({
      standbyLoading: true,
    });

    setTimeout(() => {
      this.API.updateQueueRecord(
        id,
        "standby",
        moment().diff(moment(this.props.data.created_at), "minutes"),
        (data) => {
          this.setState({
            standbyLoading: false,
          });
          if (data !== undefined) {
            this.props.refreshAction();
          }
        }
      );
    }, 500);
  };

  componentDidMount() {
    this.API = new QueuePanelAPI();
    this.setState({
      shopmemoTxt: this.props.data.shop_memo,
      tempShopmemoTxt: this.props.data.shop_memo,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        shopmemoTxt: this.props.data.shop_memo,
        tempShopmemoTxt: this.props.data.shop_memo,
      });
    }
  }

  renderShopmemo = () => {
    const { t } = this.props;
    const { editing, shopmemoTxt } = this.state;

    if (editing) {
      return this.renderEditMode();
    } else {
      if (!shopmemoTxt) {
        return (
          <div className="queue-list-item-shopmemo">
            <p className="eidtWrap" onClick={() => this.switchEditMode(true)}>
              <span className="edit-img">
                <img
                  src={require("../../images/queue/memo.svg")}
                  alt="Shop Memo"
                />
              </span>
              <span className="edit-txt">{t("item.addRemarks")}</span>
            </p>
          </div>
        );
      } else if (shopmemoTxt.trim() === "") {
        return (
          <div className="queue-list-item-shopmemo">
            <p className="eidtWrap" onClick={() => this.switchEditMode(true)}>
              <span className="edit-img">
                <img
                  src={require("../../images/queue/memo.svg")}
                  alt="Shop Memo"
                />
              </span>
              <span className="edit-txt">{t("item.addRemarks")}</span>
            </p>
          </div>
        );
      } else {
        return (
          <div className="queue-list-item-shopmemo">
            <div className="shopmemo">{shopmemoTxt}</div>
            <div className="editBtn">
              <p className="eidtWrap" onClick={() => this.switchEditMode(true)}>
                <span className="edit-img">
                  <img
                    src={require("../../images/queue/memo.svg")}
                    alt="Shop Memo"
                  />
                </span>
                <span className="edit-txt">{t("edit")}</span>
              </p>
            </div>
          </div>
        );
      }
    }
  };

  renderEditMode = () => {
    const { t } = this.props;
    const { shopmemoTxt } = this.state;

    return (
      <div className="queue-list-item-shopmemo shopmemo_editMode">
        <textarea
          value={shopmemoTxt || ""}
          placeholder={t("item.placeholder_addRemarks")}
          onChange={(e) => this.changeShopmemo(e)}
        />
        <Space size={4} className="btns">
          <button
            className="button-secondary"
            onClick={() => this.discardMemo()}
          >
            {t("cancel")}
          </button>
          <button
            className="button-primary"
            onClick={() => this.checkShopMemo()}
          >
            {t("confirm")}
          </button>
        </Space>
      </div>
    );
  };

  switchEditMode = (setting) => {
    this.setState({ editing: setting });
  };

  discardMemo = () => {
    const { tempShopmemoTxt } = this.state;
    this.setState({ shopmemoTxt: tempShopmemoTxt, editing: false });
  };

  changeShopmemo = (e) => {
    let txt = e.target.value;

    this.setState({ shopmemoTxt: txt });
  };

  checkShopMemo = () => {
    const { t } = this.props;
    const { id } = this.props.data;
    const { shopmemoTxt } = this.state;

    this.API.updateQueueShopMemo(id, shopmemoTxt)
      .then((data) => {
        this.switchEditMode(false);
        this.setState({ tempShopmemoTxt: shopmemoTxt });
      })
      .catch((err) => {
        window.app.alert.setMessage(
          t("settings:status.pls_tryAgainLater"),
          "error"
        );
      });
  };

  renderEmail = () => {
    const { email } = this.props.data;
    if (email.trim() === "") return null;

    return <p>{email}</p>;
  };

  render() {
    const { t, i18n } = this.props;
    let showEstimateTime =
      this.props.timeCheckingCondition !== undefined &&
      this.props.timeCheckingCondition !== "[]";
    let spanClassName = "";
    let estimatedContent = getEstimatedWaitingTime(
      this.props.timeCheckingCondition,
      this.props.data.waiting_groups
    );
    let estimatedWaitingTime = estimatedContent;

    if (typeof estimatedContent === "string") {
      estimatedWaitingTime = estimatedContent.startsWith("少於")
        ? `${t("item.less")} ${estimatedContent.substr(2)}`
        : estimatedContent;
    }

    if (
      moment().diff(moment(this.props.data.created_at), "minutes") >=
      estimatedWaitingTime
    ) {
      spanClassName = "expired";
    }

    let searchCategory = null;
    if (this.props.searching === true) {
      switch (this.props.data.status) {
        case "waiting":
          searchCategory = (
            <div
              style={{
                fontSize: "12px",
                background: "#5a92da",
                borderRadius: "4px",
                padding: "2px 6px",
                color: "white",
                width: "fit-content",
              }}
            >
              {t("status.waiting")}
            </div>
          );
          break;
        case "seated":
          searchCategory = (
            <div
              style={{
                fontSize: "12px",
                background: "#3fba87",
                borderRadius: "4px",
                padding: "2px 6px",
                color: "white",
                width: "fit-content",
              }}
            >
              {t("status.checkedIn")}
            </div>
          );
          break;
        case "standby":
          searchCategory = (
            <div
              style={{
                fontSize: "12px",
                background: "#f6a622",
                borderRadius: "4px",
                padding: "2px 6px",
                color: "white",
                width: "fit-content",
              }}
            >
              {t("status.missed")}
            </div>
          );
          break;
        case "canceled":
          searchCategory = (
            <div
              style={{
                fontSize: "12px",
                background: "#979797",
                borderRadius: "4px",
                padding: "2px 6px",
                color: "white",
                width: "fit-content",
              }}
            >
              {t("status.cancelled")}
            </div>
          );
          break;
        default:
      }
    }

    return (
      <div
        className="queue-list-item"
        style={this.props.showBottomSeparator ? { borderBottom: "none" } : {}}
      >
        <div className="queue-list-item-top">
          <div className="queue-list-item-main">
            <div className="queue-list-item-group">
              <div className="list-item-category">
                {this.props.data.group_name}
                {searchCategory}
              </div>
              <div className="list-item-number">
                {this.props.data.waiting_number}
              </div>
            </div>
            <div className="list-item-customer">
              <div className="info">
                <Space size={4} className="customer">
                  {this.props.data.gender === "male" &&
                    i18n.language === "en" &&
                    t("option.male")}
                  {this.props.data.gender === "female" &&
                    i18n.language === "en" &&
                    t("option.female")}
                  {this.props.data.customer_id ? (
                    <Link
                      to={`/dashboard/customers/${this.props.data.customer_id}`}
                    >
                      <span className="name">{this.props.data.name}</span>
                    </Link>
                  ) : (
                    <span className="name">{this.props.data.name}</span>
                  )}
                  {this.props.data.gender === "male" &&
                    i18n.language === "zh" &&
                    t("option.male")}
                  {this.props.data.gender === "female" &&
                    i18n.language === "zh" &&
                    t("option.female")}
                </Space>
                <div className="attendance">
                  {this.props.data.people_count}
                  <span className="icon-md account-icon" />
                </div>
              </div>
              <p>{this.props.data.phone}</p>
              {this.renderEmail()}
            </div>
            {this.props.data.memo ? (
              <div className="queue-list-item-memo">{this.props.data.memo}</div>
            ) : null}

            {this.renderShopmemo()}
          </div>
          <div className="list-item-actions queueActionBlock">
            <QueueActionCall
              id={this.props.data.id}
              number={this.props.data.waiting_number}
              groupName={this.props.data.group_name}
              waitingRecords={this.props.waitingRecords}
              lastSeatedNumber={this.props.groupInfo?.last_seated_number}
              refreshAction={this.props.refreshAction}
            />
            {this.props.hideMailBtn ? (
              <div className="btn-placeholder" />
            ) : (
              <QueueActionMessage
                sendNotification={() =>
                  this.sendNotification(this.props.data.id)
                }
                notificationLoading={this.state.notificationLoading}
                isNotified={this.props.data.is_notified}
                hideAction={this.props.hideAction}
              />
            )}
            {this.props.hideSeatedAction ? null : (
              <QueueActionSeated
                queueNumber={this.props.data.waiting_number}
                changeListItemToSeated={() =>
                  this.changeListItemToSeated(this.props.data.id)
                }
                seatedLoading={this.state.seatedLoading}
                lastSeatedNumber={this.props.groupInfo?.last_seated_number}
                groupName={this.props.data.group_name}
                waitingRecords={this.props.waitingRecords}
              />
            )}
            {this.props.hideStandbyAction ? null : (
              <QueueActionCancel
                queueNumber={this.props.data.waiting_number}
                changeListItemToStandby={() =>
                  this.changeListItemToStandby(this.props.data.id)
                }
                standbyLoading={this.state.standbyLoading}
                lastSeatedNumber={this.props.groupInfo?.last_seated_number}
                groupName={this.props.data.group_name}
                waitingRecords={this.props.waitingRecords}
              />
            )}
          </div>
        </div>

        {showEstimateTime ? (
          <div className="queue-list-item-info">
            {this.props.data.source === "online"
              ? t("common:source.online")
              : t("common:source.manual")}{" "}
            {moment(this.props.data.created_at).format("HH:mm")}
            {!this.props.hideAction && !this.props.hideSeatedAction
              ? "｜" + t("item.ahead") + this.props.data.waiting_groups
              : ""}
            {!this.props.hideAction &&
            !this.props.hideSeatedAction &&
            i18n.language === "zh"
              ? "組"
              : ""}
            ｜{t("item.est")}
            {estimatedWaitingTime}
            {t("item.minutes")} ｜{t("item.waited")}
            <span className={spanClassName}>
              {this.props.data["waiting_time"] === null
                ? moment().diff(moment(this.props.data.created_at), "minutes")
                : this.props.data.waiting_time}
              {t("item.minutes")}
            </span>
          </div>
        ) : (
          <div className="queue-list-item-info">
            {this.props.data.source === "online"
              ? t("common:source.online")
              : t("common:source.manual")}{" "}
            {moment(this.props.data.created_at).format("HH:mm")}
            {!this.props.hideAction && !this.props.hideSeatedAction
              ? "｜" + t("item.ahead") + this.props.data.waiting_groups
              : ""}
            {!this.props.hideAction &&
            !this.props.hideSeatedAction &&
            i18n.language === "zh"
              ? "組"
              : ""}
            ｜{t("item.waited")}
            <span>
              {this.props.data["waiting_time"] === null
                ? moment().diff(moment(this.props.data.created_at), "minutes")
                : this.props.data.waiting_time}
              {t("item.minutes")}
            </span>
          </div>
        )}
      </div>
    );
  }
}

QueueListItem.defaultProps = {
  refreshAction: () => {},
};

export default withTranslation("queueSystem")(QueueListItem);
