import React, { useCallback } from "react";
import { Link } from "react-router";
import _ from "lodash";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { Modal, Space, Switch } from "antd";
import {
  closeAreaModal,
  changeAreaBlockTitle,
  chooseTableGroup,
  checkModalState,
  openEditAvailableTime,
  togglePayment,
  changeDepositEnable,
  changeDeposit,
  changeDepositPeople,
  addNewWeekSetting,
  delSetting,
} from "../../../actions/bookingSettingAreaAction";
import SettingEditor from "./AreaEditor";
import { dayList, dayListFullEN } from "../../../utils/constants";

const AreaModal = ({ setSaved, serviceTimeOptionEnabled }) => {
  const { t } = useTranslation("settings");
  const dispatch = useDispatch();
  const BSareaSetting = useSelector((state) => state.BookingSettingAreaReducer);
  const { tables, space_groups, targetIndex } = BSareaSetting;
  const BSareaModalState = useSelector(
    (state) => state.BookingSettingAreaModalReducer
  );
  const {
    areaBlockTables,
    areaBlockTitle,
    areaBlockWeekSettings,
    paymentSettings,
    activeEditId,
    editMode,
    setting,
    weekSettingType,
    spgMerchantId,
  } = BSareaModalState;

  const handleAreaTitle = (e) => {
    const txt = e.target.value;
    dispatch(changeAreaBlockTitle(txt));
  };

  const handleTableGroup = useCallback(
    (table) => {
      dispatch(chooseTableGroup(table));
    },
    [dispatch]
  );

  const renderTableGroups = useCallback(() => {
    return _.unionBy(tables, "group").map((table) => {
      const active = _.includes(areaBlockTables, table.id);
      return (
        <span
          key={table.id}
          className={classNames("groups_table", { active })}
          onClick={() => handleTableGroup(table)}
        >
          {table.group}
        </span>
      );
    });
  }, [areaBlockTables, handleTableGroup, tables]);

  const hideModal = () => {
    dispatch(closeAreaModal());
  };

  const checkModal = () => {
    if (areaBlockTitle.trim() === "") {
      window.app.alert.setMessage(
        t("serviceOptionSetting.placeholder_enterServiceOptionName"),
        "tip"
      );
      return;
    }
    if (areaBlockTables.length === 0) {
      window.app.alert.setMessage(
        t("serviceOptionSetting.pls_selectAtLeastOneTableType"),
        "tip"
      );
      return;
    }

    const duplicateName = space_groups
      .map((group, index) => {
        if (targetIndex !== index) return group.name;
        return true;
      })
      .indexOf(areaBlockTitle.trim());

    if (duplicateName !== -1) {
      window.app.alert.setMessage(
        t("serviceOptionSetting.pls_serviceOptionNameCannotBeDuplicate"),
        "error"
      );
      return;
    }

    dispatch(checkModalState());
    setSaved();
    dispatch(closeAreaModal());
  };

  const editView = useCallback(
    (index = 1) => {
      const activeWeeks = setting.week_days.map((w) => `星期${dayList[w]}`);
      const activeTimes = setting.setting_temp.setting3;
      const activeTimeRange = setting.setting_temp.setting2;

      return (
        <SettingEditor key={index}>
          <SettingEditor.Title />
          <SettingEditor.Week activeOption={activeWeeks} settingTye="week" />
          <SettingEditor.Enable_2 enable={setting.setting_temp.enable} />
          {setting.setting_temp.enable === 2 && (
            <React.Fragment>
              <SettingEditor.TimeRange activeTimeRange={activeTimeRange} />
              <SettingEditor.Interval
                interval={setting.booking_time_interval}
              />
            </React.Fragment>
          )}
          <SettingEditor.Enable_3 enable={setting.setting_temp.enable} />
          {setting.setting_temp.enable === 3 && (
            <SettingEditor.Time activeOption={activeTimes} settingTye="time" />
          )}
          <hr />
          <SettingEditor.DiningTime
            fixedDiningTime={setting.dining_time}
            diningTimeOptions={setting.service_time_setting[0]}
            serviceTimeOptionEnabled={serviceTimeOptionEnabled}
          />
        </SettingEditor>
      );
    },
    [setting, serviceTimeOptionEnabled]
  );

  const renderEditMode = () => {
    if (editMode && weekSettingType === "add") {
      return editView();
    }
  };

  const renderTimeSetting = useCallback(() => {
    return (
      <div className="availableTime">
        {areaBlockWeekSettings.map((setting, index) => {
          if (weekSettingType === "edit" && activeEditId === index) {
            return editView(index);
          }

          const showButton = activeEditId === null;
          return (
            <AvailableTimeRow key={index}>
              <AvailableTimeRow.Content
                index={index + 1}
                weekDays={setting.week_days}
                fixedDiningTime={setting.dining_time}
                diningTimeOptions={setting.service_time_setting[0]}
                serviceTimeOptionEnabled={serviceTimeOptionEnabled}
              />

              <AvailableTimeRow.Button show={showButton} index={index} />
            </AvailableTimeRow>
          );
        })}
      </div>
    );
  }, [
    activeEditId,
    areaBlockWeekSettings,
    editView,
    weekSettingType,
    serviceTimeOptionEnabled,
  ]);

  const renderPaymentSetting = () => {
    if (spgMerchantId === "") {
      return (
        <NoSetting>
          <Trans i18nKey="setting:serviceOptionSetting.advancePaymentNotice">
            ＊您尚未開通付款功能設定，請至
            <span>
              <Link to={"/dashboard/setting/payment"}> 付款設定 </Link>
            </span>
            啟用設定
          </Trans>
        </NoSetting>
      );
    }

    return (
      <PaymentRow>
        <PaymentRow.Setting paymentSettingsActive={paymentSettings.active} />
        {paymentSettings.active && (
          <PaymentRow.Price paymentSettings={paymentSettings} />
        )}
      </PaymentRow>
    );
  };

  const handleNewWeekSetting = () => {
    dispatch(addNewWeekSetting());
    addNewWeekSettingBtnNoShow();
  };

  return (
    <Modal
      className="areaModal modal-base modal-xl setting-diningTime"
      destroyOnClose
      centered
      maskClosable={false}
      closable={false}
      width={640}
      title={
        targetIndex === null
          ? t("serviceOptionSetting.addTheServiceOption")
          : t("serviceOptionSetting.editTheServiceOption")
      }
      visible
      wrapClassName="availableTimeSetting"
      footer={
        <Space size={10}>
          <button
            className="button-common button-secondary"
            onClick={hideModal}
          >
            {t("cancel")}
          </button>
          <button
            className="button-common button-primary"
            disabled={weekSettingType !== ""}
            onClick={checkModal}
          >
            {t("save")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
    >
      <section>
        <h4 style={{ marginBottom: "8px" }}>
          {t("serviceOptionSetting.serviceOptionName")}
        </h4>
        <input
          value={areaBlockTitle}
          type="text"
          placeholder={t(
            "serviceOptionSetting.placeholder_enterServiceOptionName"
          )}
          onChange={(e) => handleAreaTitle(e)}
        />
      </section>
      <section>
        <h4 style={{ marginBottom: "16px" }}>
          {t("serviceOptionSetting.tableType")}
        </h4>
        <div>{renderTableGroups()}</div>
      </section>
      <section>
        <h4 className="weekSettings" style={{ marginBottom: 0 }}>
          {t("serviceOptionSetting.bookingTime")}
        </h4>
        <div className="bookingSettingAreaModal_subtitle">
          {t("bookingTimesCommon.bookingTimeSlots_note")}
        </div>
        <button
          id="addNewWeekSettingBtn"
          className="button-tertiary"
          disabled={weekSettingType !== ""}
          onClick={() => handleNewWeekSetting()}
        >
          + {t("bookingTimesCommon.addBookingTime")}
        </button>
        {renderEditMode()}
        {renderTimeSetting()}
      </section>
      <hr />
      <section>{renderPaymentSetting()}</section>
      <div id="areaModalNumpad" />
    </Modal>
  );
};

const NoSetting = ({ children }) => {
  return (
    <div className="noSetting">
      {React.Children.map(children, (child) => child)}
    </div>
  );
};

const AvailableTimeRow = ({ children }) => {
  return (
    <div className="settingRow active">
      {React.Children.map(children, (child) => child)}
    </div>
  );
};

const AvailableTimeRowContent = ({
  weekDays,
  index,
  fixedDiningTime,
  diningTimeOptions,
  serviceTimeOptionEnabled,
}) => {
  const { t, i18n } = useTranslation("settings");
  const diningTimeModeContent = () => {
    let diningTime_min = 0;
    let diningTime_max = 0;

    if (serviceTimeOptionEnabled) {
      if (diningTimeOptions.mode === "user_setting") {
        if (diningTimeOptions.user_setting.show_name) {
          return (
            <div style={{ marginTop: "12px" }}>
              <span>
                {t("bookingTimesCommon.serviceDuration")}：
                {t("bookingTimesCommon.customTimeDurationOptions")}
              </span>
              <div>
                {t("bookingTimesCommon.customizeOptionNames")}：{t("enabled")}
              </div>
              <div>
                {diningTimeOptions.user_setting.service_time_setting.map(
                  (time, index) => {
                    return (
                      <div key={index}>
                        <span>
                          {time.name} (
                          {Math.floor(parseInt(time.service_time, 10) / 60)}{" "}
                          {t("time:hour")}{" "}
                          {Math.floor(parseInt(time.service_time, 10) % 60)}{" "}
                          {t("time:minute")})
                          {index !==
                          diningTimeOptions.user_setting.service_time_setting
                            .length -
                            1 ? (
                            <span>、</span>
                          ) : null}
                        </span>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div style={{ marginTop: "12px" }}>
              <span>
                {t("bookingTimesCommon.serviceDuration")}：
                {t("bookingTimesCommon.customTimeDurationOptions")}
              </span>
              <div>
                {t("bookingTimesCommon.customizeOptionNames")}
                {t("disabled")}{" "}
              </div>
              <div>
                {diningTimeOptions.user_setting.service_time_setting.map(
                  (time, index) => {
                    return (
                      <span key={index}>
                        <span>
                          {Math.floor(parseInt(time.service_time, 10) / 60)}{" "}
                          {t("time:hour")}{" "}
                          {Math.floor(parseInt(time.service_time, 10) % 60)}{" "}
                          {t("time:minute")}
                          {index !==
                          diningTimeOptions.user_setting.service_time_setting
                            .length -
                            1 ? (
                            <span>、</span>
                          ) : null}
                        </span>
                      </span>
                    );
                  }
                )}
              </div>
            </div>
          );
        }
      } else if (diningTimeOptions.mode === "fixed") {
        diningTime_min = diningTimeOptions.fixed.min;
        diningTime_max = diningTimeOptions.fixed.max;

        return (
          <div style={{ marginTop: "12px" }}>
            <span>
              {t("bookingTimesCommon.serviceDuration")}：
              {t("bookingTimesCommon.fixedIntervalTimeDurationOptions")}
            </span>
            <div>
              <span>
                {t("bookingTimesCommon.minimum")}{" "}
                {Math.floor(parseInt(diningTime_min, 10) / 60)} {t("time:hour")}{" "}
                {Math.floor(parseInt(diningTime_min, 10) % 60)}{" "}
                {t("time:minute")}
              </span>
              <span>~</span>
              <span>
                {t("bookingTimesCommon.maximum")}{" "}
                {Math.floor(parseInt(diningTime_max, 10) / 60)} {t("time:hour")}{" "}
                {Math.floor(parseInt(diningTime_max, 10) % 60)}{" "}
                {t("time:minute")}
              </span>
            </div>
            <div>
              {t("bookingTimesCommon.timeDurationIntervals")}：
              {diningTimeOptions.fixed.unit}
              {t("time:minute")}
            </div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <span>
            {t("bookingTimesCommon.serviceDuration")}：{" "}
            {Math.floor(parseInt(fixedDiningTime, 10) / 60)} {t("time:hour")}{" "}
            {Math.floor(parseInt(fixedDiningTime, 10) % 60)} {t("time:minute")}
          </span>
        </div>
      );
    }
  };

  return (
    <div className="settingRow__content">
      <p className="settingRow__Title">
        {t("bookingTimesCommon.title")}
        {index}
      </p>
      <div className="settingRow__setting">
        <span>
          {i18n.language === "zh"
            ? weekDays.map((w) => `星期${dayList[w]}`).join("、")
            : weekDays.map((w) => dayListFullEN[w]).join(", ")}
        </span>
        {diningTimeModeContent()}
      </div>
    </div>
  );
};

const addNewWeekSettingBtnNoShow = () => {
  const addNewWeekSettingBtn = document.getElementById("addNewWeekSettingBtn");
  addNewWeekSettingBtn.style.display = "none";
};

const AvailableTimeRowButton = ({ show, index }) => {
  const { t } = useTranslation("settings");
  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(openEditAvailableTime(index));
    addNewWeekSettingBtnNoShow();
  };

  const handleDel = () => {
    dispatch(delSetting(index));
  };

  if (!show) return null;
  return (
    <div className="settingRow__button">
      <button className="areaEditButton" onClick={() => handleEdit()}>
        <span>{t("edit")}</span>
      </button>
      <button className="areaDelButton" onClick={() => handleDel()}>
        <span>{t("delete")}</span>
      </button>
    </div>
  );
};

const PaymentRow = ({ children }) => {
  return (
    <div className="payment">
      {React.Children.map(children, (child) => child)}
    </div>
  );
};

const PaymentRowToggle = ({ paymentSettingsActive = false }) => {
  const { t } = useTranslation("settings");
  const dispatch = useDispatch();

  const handleTogglePayment = () => {
    dispatch(togglePayment());
  };

  return (
    <div className="diningTime_name_switch_block">
      <div className="diningTime_name_switch">
        <div className="diningTime_name_title">
          {t("serviceOptionSetting.requireAdvancedPayment")}
        </div>
        <Switch
          checked={paymentSettingsActive}
          onChange={() => handleTogglePayment()}
        />
      </div>
    </div>
  );
};

const PaymentRowPrice = ({ paymentSettings }) => {
  const dispatch = useDispatch();
  const { enable, setting1, setting2 } = paymentSettings;
  const { deposit, deposit_people_required } = setting1;

  const handleDepositionEnable = (enable) => {
    dispatch(changeDepositEnable(enable));
  };

  const handleDepositionSettingDeposit = (enable, e) => {
    const value = parseInt(e.target.value, 10);
    dispatch(changeDeposit(enable, value));
  };

  const handleDepositionSettingDepositPeople = (e) => {
    const value = parseInt(e.target.value, 10);
    dispatch(changeDepositPeople(value));
  };

  return (
    <div className="paymentSetting">
      <div className="paymentSettingRow">
        <input
          type="radio"
          name="paymentSetting"
          checked={enable === 1}
          onChange={() => handleDepositionEnable(1)}
        />
        <div>
          <Trans i18nKey="settings:serviceOptionSetting.advancePaymentForEachPerson">
            <input
              type="number"
              min="1"
              placeholder="1"
              value={deposit_people_required}
              onChange={(e) => handleDepositionSettingDepositPeople(e)}
            />
            <span>人以上（含），每人</span>
            <input
              type="number"
              min="1"
              placeholder="1"
              value={deposit}
              onChange={(e) => handleDepositionSettingDeposit(1, e)}
            />
            <span>元</span>
          </Trans>
        </div>
      </div>
      <div className="paymentSettingRow">
        <input
          type="radio"
          name="paymentSetting"
          checked={enable === 2}
          onChange={() => handleDepositionEnable(2)}
        />
        <Trans i18nKey="settings:serviceOptionSetting.advancePaymentFixed">
          <span>固定金額</span>
          <input
            type="number"
            min="1"
            placeholder="1"
            value={setting2}
            onChange={(e) => handleDepositionSettingDeposit(2, e)}
          />
          <span>元</span>
        </Trans>
        <div></div>
      </div>
    </div>
  );
};

AvailableTimeRow.Content = AvailableTimeRowContent;
AvailableTimeRow.Button = AvailableTimeRowButton;

PaymentRow.Setting = PaymentRowToggle;
PaymentRow.Price = PaymentRowPrice;

export default AreaModal;
