import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

export const Table = ({ timeline, findTable, table, selectTable, current }) => {
	const { t } = useTranslation('bookingSystem');
  const [opacity, setOpacity] = useState(1);
  const [nextBooking, setNextBooking] = useState({});

  const findBooking = useCallback(() => {
    if (timeline.length === 0) {
      // 該桌次無任何預約
      setOpacity(1);
      setNextBooking({});
    } else {
      const currentTimestamp = moment(new Date(current));
      const firstBookingTimestamp = moment(timeline[0].service_start_time);
      const bookingDiff = moment.duration(
          firstBookingTimestamp.diff(currentTimestamp)
        ),
        durationDiff = bookingDiff.asMinutes();

      if (currentTimestamp < firstBookingTimestamp) {
        // 未到第一筆預約時間
        // console.log('----- 未到第一筆預約時間-----', timeline[0].table_number)

        if (durationDiff <= 5) {
          setOpacity(0.1);
        } else {
          setOpacity(1);
        }

        setNextBooking(timeline[0]);
        return;
      } else if (currentTimestamp >= firstBookingTimestamp) {
        // 已到第一筆預約時間
        for (let i = 0; i < timeline.length; i++) {
          const diningTime = JSON.parse(timeline[i].dining_time),
            lastBookingDiningTime = JSON.parse(
              timeline[timeline.length - 1].dining_time
            );
          const bookingTimestamp = moment(timeline[i].service_start_time),
            bookingTimeEnd = moment(bookingTimestamp)
              .add(diningTime, "m")
              .format("YYYY-MM-DD HH:mm");
          const bookingTimeEndTimestamp = moment(bookingTimeEnd);
          const lastBookingTimeEnd = moment(
              timeline[timeline.length - 1].service_start_time
            )
              .add(lastBookingDiningTime, "m")
              .format("YYYY-MM-DD HH:mm"),
            lastBookingTimeEndTimestamp = moment(lastBookingTimeEnd);

          if (
            bookingTimestamp <= currentTimestamp &&
            currentTimestamp <= bookingTimeEndTimestamp - 1
          ) {
            // console.log('---現在時間 在 某筆預約時間內---', table.table_number);

            // 現在時間在某比預約時間內
            setOpacity(0.1);
            setNextBooking(timeline[i + 1] ? timeline[i + 1] : {});
            return;
          } else if (
            // currentTimestamp <= bookingTimestamp &&
            currentTimestamp <=
            bookingTimeEndTimestamp - 1
          ) {
            // console.log('---現在時間 已過上一筆預約 且未到 下一筆預約時間---',timeline[i].name,  table.table_number);
            const nextBookingTimestamp = moment(timeline[i].service_start_time);
            const nextBookingDiff = moment.duration(
                nextBookingTimestamp.diff(currentTimestamp)
              ),
              nextBookingDurationDiff = nextBookingDiff.asMinutes();

            if (nextBookingDurationDiff <= 5) {
              setOpacity(0.1);
            } else {
              setOpacity(1);
            }

            setNextBooking(timeline[i]);
            return;
          } else if (currentTimestamp > lastBookingTimeEndTimestamp - 1) {
            //現在時間已過最後一筆預約時間, 已無下一筆預約
            // console.log('----- 已無下一筆預約 -----', table.table_number);
            setOpacity(1);
            setNextBooking({});
            return;
          }
        }
      }
    }
  }, [current, timeline]);

  useEffect(() => {
    findBooking();
  }, [findBooking]);

  const clickTable = (table) => {
    table["nextBooking"] = nextBooking;
    selectTable(table);
  };

  const active = findTable === -1 ? "" : "active";
  const notClickable = opacity !== 1 ? "notClickable" : "";

  return (
    <div
      className={`groupTable ${active} ${notClickable}`}
      onClick={() => clickTable(table)}
    >
      <p>{table.table_number}</p>
      <p>
        （{table.min_seat} - {table.max_seat} {t('addBooking.attendance')}）
      </p>
    </div>
  );
};
