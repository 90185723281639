import { useState, useEffect, useRef, useCallback } from "react";

const useScrollableArrows = (items) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const scrollRef = useRef(null);

  const handleScroll = () => {
    if (!scrollRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    const isScrollable = scrollWidth > clientWidth;

    setShowLeftArrow(isScrollable && scrollLeft > 0);
    setShowRightArrow(isScrollable && scrollLeft + clientWidth < scrollWidth);
  };

  const observeWidthChange = useCallback(() => {
    if (scrollRef.current) {
      const observer = new ResizeObserver(() => {
        handleScroll(); // Recheck on width change
      });
      observer.observe(scrollRef.current);
      
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const currentRef = scrollRef.current;

    if (currentRef) {
      handleScroll(); // Check on mount
      currentRef.addEventListener("scroll", handleScroll);
    }

    const cleanupResizeObserver = observeWidthChange();

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
      if (cleanupResizeObserver) {
        cleanupResizeObserver();
      }
    };
  }, [items, observeWidthChange, scrollRef]);

  return {
    scrollRef,
    showLeftArrow,
    showRightArrow,
  };
};

export default useScrollableArrows;
