import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Modal, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import AnnouncementCard from "../AnnouncementCard";
import {
  announcementInit,
  showAnnouncementAddModal,
  closeAnnouncementListModal,
  searchAnnouncementListModal,
} from "../../../actions/announcementAction";
import { dayList, dayListAbbrevEN } from "../../../utils/constants";

const AnnouncementListModal = ({ month = "" }) => {
  const { t, i18n } = useTranslation("bulletinSystem");
  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const {
    showListType,
    currentDate,
    dayAnnouncements,
    officialDayAnnouncements,
    displayMonthAnnouncements,
    searchModalTxt,
    searchModeModal,
  } = announcementListReducer;
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("shop");
  const [searchMode, setSearchMode] = useState(false);

  const cancelPopup = () => {
    dispatch(closeAnnouncementListModal());
    dispatch(searchAnnouncementListModal(""));
  };

  const showSearchInput = (type) => {
    const searchInput = document.getElementsByClassName("searchInput")[0];

    if (type === "open") {
      setSearchMode(true);
      searchInput.focus();
    } else {
      setSearchMode(false);
      // 清除搜尋、恢復原始列表
      dispatch(searchAnnouncementListModal(""));
    }
  };

  const handleScrollTop = () => {
    const top = document.getElementsByClassName("announcementLisContent")[0];
    top.scrollIntoView({ behavior: "smooth" });
  };

  const showAddAnnouncementModal = () => {
    dispatch(announcementInit());
    dispatch(showAnnouncementAddModal());
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    dispatch(searchAnnouncementListModal(value));
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return {
        formattedDate: moment().format('YYYY-MM-DD'),
        day: moment().day()
      };
    }
  
    const momentDate = dateString.includes('/')
      ? moment(dateString.split('/').join('-'))
      : moment(dateString);
  
    return {
      formattedDate: momentDate.format('YYYY-MM-DD'),
      day: momentDate.day()
    };
  };

  const renderTitleAndAction = () => {
    if (showListType === "month") {
      return (
        <div className={`title ${searchMode ? "open" : ""}`}>
          <div className="month_text">{t("listPopup.title")}</div>
          <Space size={8} className="announcementAction">
            <div className="searchBlock">
              <button
                className={`${searchMode ? "" : "action-display-button"}`}
                onClick={() => showSearchInput("open")}
              >
                <SearchOutlined className="search_icon" />
              </button>
              <input
                type="text"
                className="searchInput"
                value={searchModalTxt}
                placeholder={t("list.placeholder_search")}
                onChange={(e) => handleSearch(e)}
              />
              <img
                className="closeSearch"
                src={require("../../../images/announcement/icon_close.svg")}
                onClick={() => showSearchInput("close")}
                alt=""
              />
            </div>
            <button
              className="action-display-button"
              onClick={showAddAnnouncementModal}
            >
              <div className="plus-icon" />
            </button>
          </Space>
        </div>
      );
    } else {
      const { formattedDate, day } = formatDate(currentDate);
      const dayText = i18n.language === "zh" ? dayList[day] : dayListAbbrevEN[day];

      return (
        <>
          <div className="date">
            {formattedDate}（{dayText}）
          </div>
          <div className="tab">
            <button
              className={`btn-text-lg ${currentTab === "shop" ? "active" : ""}`}
              onClick={() => setCurrentTab("shop")}
            >
              {t("list.internalAnnouncement")}
            </button>
            <button
              className={`btn-text-lg ${
                currentTab === "official" ? "active" : ""
              }`}
              onClick={() => setCurrentTab("official")}
            >
              {t("list.systemAnnouncement")}
            </button>
          </div>
        </>
      );
    }
  };

  const renderList = () => {
    const { formattedDate, day } = formatDate(currentDate);
    const dayText = i18n.language === "zh" ? dayList[day] : dayListAbbrevEN[day];

    if (showListType === "month") {
      let displayAnnouncementCount = [];

      Object.keys(displayMonthAnnouncements).forEach((key) => {
        if (displayMonthAnnouncements[key].length > 0) {
          for (let i = 0; i < displayMonthAnnouncements[key].length; i++) {
            displayAnnouncementCount.push(key);
          }
        }
      });

      if (searchModeModal && displayAnnouncementCount.length === 0) {
        return <div className="list_empty">{t("list.searchNoResult")}</div>;
      }

      if (displayAnnouncementCount.length === 0) {
        return <div className="list_empty">{t("listPopup.monthEmpty")}</div>;
      }

      return Object.keys(displayMonthAnnouncements)
        .filter((item) => displayMonthAnnouncements[item].length > 0)
        .map((item, idx) => {
          displayMonthAnnouncements[item].sort(
            (a, b) => new Date(a.start_time) - new Date(b.start_time)
          );

          return (
            <Fragment key={idx}>
              <h4>
                {item}（{dayText}）
              </h4>
              {displayMonthAnnouncements[item].map((i, idx) => (
                <AnnouncementCard
                  data={displayMonthAnnouncements}
                  item={i}
                  idx={idx}
                  key={i.id}
                  date={item}
                />
              ))}
            </Fragment>
          );
        });
    } else {
      if (currentTab === "shop") {
        if (dayAnnouncements.length === 0) {
          return <div className="list_empty">{t("listPopup.dayEmpty")}</div>;
        }

        return dayAnnouncements.map((item, idx) => {
          return (
            <AnnouncementCard
              data={dayAnnouncements}
              item={item}
              idx={idx}
              key={item.id}
              date={formattedDate}
            />
          );
        });
      } else {
        if (officialDayAnnouncements.length === 0) {
          return <div className="list_empty">{t("listPopup.dayEmpty")}</div>;
        }

        return officialDayAnnouncements.map((item, idx) => {
          return (
            <AnnouncementCard
              data={officialDayAnnouncements}
              item={item}
              idx={idx}
              key={item.id}
              onlyRead={true}
              date={formattedDate}
            />
          );
        });
      }
    }
  };

  return (
    <Modal
      title={renderTitleAndAction()}
      visible
      destroyOnClose
      centered
      width={640}
      closable={false}
      className={`modal-base modal-xl announcementListModal ${
        showListType === "month" ? "" : "dayList"
      }`}
      onCancel={cancelPopup}
      footer={
        <Space size={10}>
          <div
            className="btn-effect toTop_icon"
            onClick={() => handleScrollTop()}
          />
          <button
            className="button-common button-primary"
            onClick={() => cancelPopup()}
          >
            {t("close")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className="announcementLisContent">{renderList()}</div>
    </Modal>
  );
};

export default AnnouncementListModal;
