import React, { useState, useEffect, useRef, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation, Trans } from "react-i18next";
import i18n from "i18next";
import { LoadingOutlined } from "@ant-design/icons";

import QueuePanelAPI from "../../queue_system/api/QueuePanelAPI";
import BookingSystemApi from "../../booking_settings/BookingSystemApi";

import QueueActionButton from "./QueueActionButton";
import {
  QueueAttendanceNumPad,
  QueuePhoneNumPad,
} from "../NewModuleComponent/NewModuleTools";
import {
  moveCanvasClass,
  moveBackCanvasClass,
} from "../NewModuleComponent/NewModuleTools";

//reducer
import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";

import { DIGITS_ONLY_REGEX } from "../../../utils/regex";
import useScreenSize from "../../../hooks/useScreenSize";
import { Modal } from "antd";

const API = new QueuePanelAPI();
const BookingSettingAPI = new BookingSystemApi();

const NewModuleQueueGroup = ({
  mode,
  closeQueueGroupPanel,
  queueGroup,
  createQueueRecord,
  timeChecking,
  updateQueueSystem,
  setShowQueueGroupPanel,
}) => {
  const { t } = useTranslation("queueSystem");
  const { setUuid } = useContext(CommomSettingContext);

  const [moduleQueueGroup, setModuleQueueGroup] = useState(queueGroup);
  const [queueGroupStep, setQueueGroupStep] = useState(1); //1選組別 2填資料 3完成
  const [selectedGroup, setSelectedGroup] = useState({});
  const [finishDate, setFinishDate] = useState({});

  const [attendance, setAttendance] = useState("");
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("male");
  const [sameTel, setSameTel] = useState(false);
  const [addDisabled, setAddBtnDisabled] = useState(false);

  let isNextButtonAvailable = false;

  useEffect(() => {
    if (mode !== "tablemap") return;
    moveCanvasClass();
    return () => {
      if (mode !== "tablemap") return;
      moveBackCanvasClass();
    };
  }, [mode]);

  useEffect(() => {
    setModuleQueueGroup(queueGroup);
  }, [queueGroup]);

  const setAttendanceFunc = (attendance) => {
    if (DIGITS_ONLY_REGEX.test(attendance) || attendance === "") {
      setAttendance(attendance);
    } else {
      window.app.alert.setMessage(t("newModule.pls_enterNumbers"), "error");
    }
  };

  const setTelFunc = (phone) => {
    if (DIGITS_ONLY_REGEX.test(phone) || phone === "") {
      setTel(phone);
    } else {
      window.app.alert.setMessage(t("newModule.pls_enterNumbers"), "error");
    }
  };

  const getInLine = async () => {
    //uuid
    const eventId = uuidv4();
    const customerInfoData = {
      customer: {
        last_name: name,
        email,
        phone_number: tel,
        tele_number: "",
        gender: gender,
        note: "",
      },
    };
    setUuid(eventId);
    setAddBtnDisabled(true);

    await BookingSettingAPI.createCustomer(customerInfoData).catch((err) =>
      console.log("new module queue createCustomer---", err)
    );

    await API.createQueueRecord({
      queue_group_id: selectedGroup.id,
      name,
      phone: tel,
      gender,
      people_count: attendance,
      email,
      event_id: eventId,
    })
      .then((data) => {
        if (typeof data.status === "string") {
          setFinishDate(data);
          clearInfos();
          setQueueGroupStep(3);
          setAddBtnDisabled(false);
          createQueueRecord();
        }
      })
      .catch((err) => {
        if (err.status === 409) {
          setSameTel(true);
        }
      })
      .finally(() => {
        setAddBtnDisabled(false);
      });
  };

  const clearInfos = () => {
    setAttendance("");
    setName("");
    setTel("");
    setEmail("");
    setGender("male");
    setSameTel(false);
  };

  const toPrevStep = () => {
    clearInfos();
    setQueueGroupStep(1);
  };

  if (name.trim() !== "" && attendance.trim() !== "" && tel.trim() !== "") {
    isNextButtonAvailable = true;
  } else {
    isNextButtonAvailable = false;
  }

  const renderQueueGroupPanelBody = () => {
    if (queueGroupStep === 1) {
      return renderQueueGroupCard();
    } else if (queueGroupStep === 2) {
      return (
        <div className="queueGroupPanel">
          {addDisabled && (
            <div className="status_loading">
              <LoadingOutlined />
            </div>
          )}
          <div className="queueGroupPanel__header">
            <span>{t("newModule.enterInfo")}</span>

            <div className="stepGroup">
              <button
                className="stepGroup-preStep"
                onClick={() => toPrevStep()}
              >
                {t("newModule.lastStep")}
              </button>
              <button
                className="stepGroup-check"
                disabled={!isNextButtonAvailable || addDisabled}
                onClick={() => getInLine()}
              >
                {t("option.queueUp")}
              </button>
            </div>
          </div>
          <div className="queueGroupPanel__body">
            <QueueGroupCardInfo
              selectedGroup={selectedGroup}
              attendance={attendance}
              name={name}
              tel={tel}
              email={email}
              gender={gender}
              setAttendance={setAttendanceFunc}
              setName={setName}
              setTel={setTelFunc}
              setEmail={setEmail}
              setGender={setGender}
              sameTel={sameTel}
            />
          </div>
        </div>
      );
    } else if (queueGroupStep === 3) {
      return (
        <QueueFinishCard
          timeChecking={timeChecking}
          finishDate={finishDate}
          closeQueueGroupPanel={closeQueueGroupPanel}
          toPrevStep={toPrevStep}
        />
      );
    }
  };

  const renderQueueGroupCard = () => {
    return (
      <div className="queueGroupPanel">
        <div className="queueGroupPanel__header">
          <span>{t("option.startPeriod")}</span>
          <QueueActionButton
            updateQueueSystem={updateQueueSystem}
            setShowQueueGroupPanel={setShowQueueGroupPanel}
          />
        </div>
        <div className="queueGroupPanel__body groupCardBody">
          <div className="queueGroupList">
            {moduleQueueGroup.map((group, index) => {
              return (
                <QueueGroupCard key={index} group={group} toStep2={toStep2} />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const toStep2 = (group) => {
    setSelectedGroup(group);
    setQueueGroupStep(2);
  };

  return (
    <Modal
      title={null}
      visible
      destroyOnClose
      centered
      footer={null}
      transitionName="ant-move-down"
      maskClosable={false}
      onCancel={closeQueueGroupPanel}
      closeIcon={<div className="NewModuleQueueGroup__close" />}
      className="modal-base NewModuleQueueGroup"
    >
      {renderQueueGroupPanelBody()}
    </Modal>
  );
};

const QueueGroupCard = ({ group, toStep2 }) => {
  const { t } = useTranslation("queueSystem");
  const renderPausedIcon = () => {
    return <i className="queueGroupCard-pause" />;
  };
  return (
    <div className="queueGroupCard" onClick={() => toStep2(group)}>
      {group.status === "pause" && renderPausedIcon()}
      <div className="queueGroupCard__top">
        <span>{group.title}</span>
      </div>
      <div className="queueGroupCard__bottom">
        <span>
          {t("select.groupsInQueue")} {group.current_waiting_groups}
        </span>
      </div>
    </div>
  );
};

const QueueGroupCardInfo = ({
  selectedGroup,
  attendance,
  setAttendance,
  name,
  setName,
  tel,
  setTel,
  email,
  setEmail,
  gender,
  setGender,
  sameTel,
}) => {
  const { t } = useTranslation("queueSystem");
  const [sameTelStyle, setSameTelStyle] = useState(sameTel);
  const [showAttendanceNumpad, setShowAttendanceNumpad] = useState(false);
  const [showPhoneNumpad, setShowPhoneNumpad] = useState(false);
  const attendanceNumpadRef = React.createRef();
  const phoneNumpadRef = React.createRef();
  const attendanceInput = useRef();
  const phoneInput = useRef();

  const { isMobile } = useScreenSize();

  useEffect(() => {
    if(isMobile) {
      attendanceInput.current.setAttribute("readonly", true);
      phoneInput.current.setAttribute("readonly", true);
    } else {
      attendanceInput.current.removeAttribute("readOnly");
      phoneInput.current.removeAttribute("readOnly");
    }
  }, [isMobile])

  useEffect(() => {
    setSameTelStyle(sameTel);
  }, [sameTel]);

  const renderGender = () => {
    let male_active = gender === "male" ? "active" : "";
    let female_active = gender === "female" ? "active" : "";

    return (
      <div className="genderPicker">
        <span className={male_active} onClick={() => setGender("male")}>
          {t("option.male")}
        </span>
        <span className={female_active} onClick={() => setGender("female")}>
          {t("option.female")}
        </span>
      </div>
    );
  };

  const handleAttendanceKeyin = (e) => {
    let attendanceArray = attendance.toString().split("");

    if (e === "delete") {
      attendanceArray.pop();
    } else if (e === "clear") {
      attendanceArray = [];
    } else {
      attendanceArray.push(e.toString());
    }

    const newAttendance = attendanceArray.join("");
    handleAttendance(newAttendance);
  };

  const handleAttendance = (attendance) => {
    setAttendance(attendance);
  };

  useEffect(() => {
    if (attendanceNumpadRef.current) {
      attendanceNumpadRef.current.focus();
    }
  }, [attendanceNumpadRef]);

  useEffect(() => {
    if (phoneNumpadRef.current) {
      phoneNumpadRef.current.focus();
    }
  }, [phoneNumpadRef]);

  const handleInputFocus = (input) => {
    if (!isMobile) return;
    if (input === "attendance") {
      setShowAttendanceNumpad(true);
    } else if (input === "phone") {
      setShowPhoneNumpad(true);
    }
  };

  const handlePhoneKeyin = (e) => {
    let phoneArray = tel.toString().split("");

    if (e === "delete") {
      phoneArray.pop();
    } else if (e === "clear") {
      phoneArray = [];
    } else {
      phoneArray.push(e.toString());
    }

    const newPhoneArray = phoneArray.join("");
    handlePhone(newPhoneArray);
  };

  const handlePhone = (phone) => {
    setTel(phone);
  };

  return (
    <div className="queueGroupCardInfo">
      <div className="queueGroupCardInfo__left">
        <div className="queueGroupCard">
          <div className="queueGroupCard__top">
            <span>{selectedGroup.title}</span>
          </div>
          <div className="queueGroupCard__bottom">
            <span>
              {t("select.groupsInQueue")} {selectedGroup.current_waiting_groups}
            </span>
          </div>
        </div>
      </div>
      <div className="queueGroupCardInfo__right">
        <div className="queueGroupCardInfo__form">
          <div className="queueGroupCardInfo__form__row">
            <input
              ref={attendanceInput}
              type="tel"
              placeholder={`*${selectedGroup.min_people_count}-${
                selectedGroup.max_people_count
              }${t("item.person_2")}`}
              value={attendance}
              onFocus={() => handleInputFocus("attendance")}
              onChange={(e) => handleAttendance(e.target.value)}
            />

            {showAttendanceNumpad && (
              <QueueAttendanceNumPad
                ref={attendanceNumpadRef}
                handleKeyin={handleAttendanceKeyin}
                numpadBlur={() => setShowAttendanceNumpad(false)}
              />
            )}
          </div>
          <div className="queueGroupCardInfo__form__row queueGroupCardInfo__form__row-name">
            <div className="nameInput">
              <input
                type="text"
                placeholder={`*${t("option.name")}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {renderGender()}
          </div>
          <div className="queueGroupCardInfo__form__row">
            <div
              className={`queueGroupCardInfo__form__row-tel ${sameTelStyle}`}
            >
              <input
                ref={phoneInput}
                type="tel"
                placeholder={`*${t("option.phoneNumber")}`}
                value={tel}
                onFocus={() => handleInputFocus("phone")}
                onChange={(e) => handlePhone(e.target.value)}
              />
              {sameTelStyle && <span>{t("newModule.duplicatePhone")}</span>}
            </div>

            {showPhoneNumpad && (
              <QueuePhoneNumPad
                ref={phoneNumpadRef}
                handleKeyin={handlePhoneKeyin}
                numpadBlur={() => setShowPhoneNumpad(false)}
              />
            )}
          </div>
          <div className="queueGroupCardInfo__form__row">
            <input
              type="text"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const QueueFinishCard = ({
  finishDate,
  closeQueueGroupPanel,
  toPrevStep,
  timeChecking,
}) => {
  const { t } = useTranslation("queueSystem");
  return (
    <div className="queueFinishCardWrap">
      <div className="queueFinishCardAlert">{t("newModule.addSuccess")}</div>
      <div className="queueFinishCard">
        <div className="queueFinishCard__group">{finishDate.group_name}</div>
        <div className="queueFinishCard__number">
          {finishDate.waiting_number}
        </div>
        <div className="queueFinishCard__infos">
          <span>
            <Trans
              i18nKey="queueSystem:option.joinedWaiting_2"
              components={{ span1: <span /> }}
              values={{
                waiting_groups: finishDate.waiting_groups,
              }}
            />
          </span>
          <span>
            <Trans
              i18nKey="queueSystem:option.joinedWaiting_3"
              components={{ span2: <span /> }}
              values={{
                waiting_time: getEstimatedWaitingTime(
                  timeChecking,
                  finishDate.waiting_groups
                ),
              }}
            />
          </span>
        </div>
        <div className="queueFinishCard__bthGroup">
          <div>
            <button
              className="queueFinishCard-toMain"
              onClick={() => closeQueueGroupPanel()}
            >
              {t("newModule.back")}
            </button>
          </div>
          <div>
            <button
              className="queueFinishCard-oneMore"
              onClick={() => toPrevStep()}
            >
              {t("option.addMore")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const getEstimatedWaitingTime = (time_checking, groupCount) => {
  let timeCheckingConditions = [];
  try {
    timeCheckingConditions = JSON.parse(time_checking);
  } catch (e) {
    // console.log('Parse condition failed');
  }

  if (
    Array.isArray(timeCheckingConditions) &&
    timeCheckingConditions.length > 1
  ) {
    timeCheckingConditions.sort((first, second) => {
      return first.count - second.count;
    });
  }

  let estimatedWaitingTime = 0;

  for (let i = 0; i < timeCheckingConditions.length; i++) {
    const element = timeCheckingConditions[i];
    if (groupCount <= element.count) {
      if (i === 0) {
        if (groupCount === element.count) {
          estimatedWaitingTime = element.time;
        } else {
          estimatedWaitingTime = i18n.t("queueSystem:item.less") + element.time;
        }
      } else {
        const prevElement = timeCheckingConditions[i - 1];
        estimatedWaitingTime = prevElement.time;
      }
      break;
    } else {
      const prevElement = timeCheckingConditions[i];
      estimatedWaitingTime = prevElement.time;
    }
  }

  return estimatedWaitingTime;
};

export default NewModuleQueueGroup;
