import React from "react";
import { useTranslation } from 'react-i18next';
import { CalendarOutlined } from "@ant-design/icons";

export default () => {
  const { t } = useTranslation('bookingSystem');
  return (
    <div className="emptyRes text-center">
      <CalendarOutlined />
      <span>{t('search.empty')}</span>
    </div>
  );
};
