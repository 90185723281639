import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

const BookingCustomerTags = ({ isBlocked, note, tags, padding = 4 }) => {
  const { t } = useTranslation("bookingSystem");
  const customerTagsMap = {
    vip: t("tags.vip"),
    egg_vegan: t("tags.egg_vegan"),
    vegan: t("tags.vegan"),
    not_friendly: t("tags.not_friendly"),
  };

  if (note.length === 0 && tags.length === 0 && !isBlocked) return null;

  const content = (
    <div>
      {isBlocked && (
        <span className="c-tag c-blocked">{t("tags.blocked")}</span>
      )}
      {tags.length !== 0 && (
        <span>
          {tags.map((tag, index) => {
            return (
              <span key={index} className="c-tag">
                {customerTagsMap[tag]}
              </span>
            );
          })}
        </span>
      )}
      {tags.length !== 0 && (note.length !== 0 || isBlocked) ? (
        <div style={{ marginTop: "16px" }} />
      ) : null}
      {note.length !== 0 && <span>{note}</span>}
    </div>
  );
  return (
    <div style={{ padding: `${padding}px` }}>
      <Tooltip
        title={content}
        overlayClassName="bookingCustomerTags"
        placement="bottomLeft"
        align={{
          offset: [-15, 0],
        }}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
      >
        <span className="file-icon icon-md" />
      </Tooltip>
    </div>
  );
};

export default BookingCustomerTags;
