import React, { Fragment, useCallback, useContext } from "react";
import { browserHistory } from "react-router";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../../crmContext/MessageContext";
import { MessageAddStateContext } from "../../crmContext/MessageAddContext";

import { validateCrmSmsContent } from "../../api/CrmMessageSmsApi";
import {
  createCrmMenuTalk,
  updateCrmMenuTalk,
} from "../../api/CrmMessageMenuTalkApi";

const MessageNextStep = ({ children }) => {
  return (
    <section className="crmMessageAdd_block crmMessageAdd_next">
      {React.Children.map(children, (child) => child)}
    </section>
  );
};

const NextStepButton = () => {
  const { t } = useTranslation("messages");
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(
    MessageAddStateContext
  );
  const { messageSetting, insertCoupons } = MessageAddState;
  const {
    id,
    status,
    title,
    content,
    customers,
    followers,
    scheduleTime,
    menuTalkContent,
    filterConditions,
    filterTag,
    uploadImg,
  } = messageSetting;

  const { exchangeMin, exchangeMax, fetchMin, fetchMax, gender } =
    filterConditions;

  const switchMode = useCallback(() => {
    MessageStateDispatch({ type: "switchMode", mode: "confirmMessage" });
  }, [MessageStateDispatch]);

  const toNext = async () => {
    const nowDateTime = new Date();
    let error = [];

    if (title.trim() === "") {
      error.push("title");
    } else {
      const idx = error.indexOf("title");
      if (idx !== -1) {
        error.splice(idx, 1);
      }
    }

    if (content.trim() === "") {
      error.push("content");
    } else {
      // 檢查內文
      const checkContent = {
        content,
      };

      try {
        const { message } = await validateCrmSmsContent(checkContent);

        if (message === "Content validated.") {
          const invalid_idx = error.indexOf("content_invalid");
          const length_idx = error.indexOf("content");

          if (invalid_idx !== -1) {
            error.splice(invalid_idx, 1);
          }

          if (length_idx !== -1) {
            error.splice(length_idx, 1);
          }
        }
      } catch (err) {
        if (err.response.data.message === "內容含有無法使用簡訊傳送的字元。") {
          error.push("content_invalid");
        }
      }
    }

    if (customers.length === 0) {
      error.push("customers");
    } else {
      const idx = error.indexOf("customers");
      if (idx !== -1) {
        error.splice(idx, 1);
      }
    }

    if (status === "" || (scheduleTime === null && status === "scheduled")) {
      error.push("dateTime");
    } else {
      const idx = error.indexOf("dateTime");
      if (idx !== -1) {
        error.splice(idx, 1);
      }
    }

    MessageAddDispatch({ type: "checkMessage", error });

    if (
      status === "scheduled" &&
      scheduleTime !== null &&
      nowDateTime > new Date(scheduleTime)
    ) {
      window.app.alert.setMessage("排程時間應晚於現在時間", "error");
      return;
    }

    if (error.length === 0) {
      let contentLength = content.length;
      let letterPoints = 0;

      if (contentLength <= 70) {
        letterPoints = 1;
      } else if (contentLength >= 71 && contentLength <= 134) {
        letterPoints = 2;
      } else if (contentLength >= 135 && contentLength <= 201) {
        letterPoints = 3;
      } else if (contentLength >= 202 && contentLength <= 268) {
        letterPoints = 4;
      } else if (contentLength >= 269 && contentLength <= 335) {
        letterPoints = 5;
      }

      let totalPoints = letterPoints * customers.length;

      switchMode();
      MessageAddDispatch({ type: "checkMessage", error: [] });
      MessageAddDispatch({
        type: "setEstimatedMsgPoints",
        oneSmsPoint: letterPoints,
        estimatedMsgPoints: totalPoints,
      });
    }
  };

  // const checkSendTimes = () => {
  //   MessageAddDispatch({ type: 'showExceedLimitModal' });
  // };

  const send = async () => {
    const nowDateTime = new Date();
    let error = [];
    let emptyContent = false;
    let errorCoupon = []; // 在排程日期前過期的優惠券

    for (let i = 0; i < menuTalkContent.length; i++) {
      if (
        menuTalkContent[i].type === "text" &&
        menuTalkContent[i].text.trim() === ""
      ) {
        emptyContent = true;
      }
    }

    for (let i = 0; i < insertCoupons.length; i++) {
      if (new Date(insertCoupons[i].endDateTime) < new Date(scheduleTime)) {
        errorCoupon.push(insertCoupons[i]);
      }
    }

    if (title.trim() === "") {
      error.push("title");
    } else {
      const idx = error.indexOf("title");
      if (idx !== -1) {
        error.splice(idx, 1);
      }
    }

    if (menuTalkContent.length === 0 || emptyContent) {
      error.push("content");
    } else {
      const idx = error.indexOf("content");
      if (idx !== -1) {
        error.splice(idx, 1);
      }
    }

    if (followers.length === 0) {
      error.push("followers");
    } else {
      const idx = error.indexOf("followers");
      if (idx !== -1) {
        error.splice(idx, 1);
      }
    }

    if (
      status === "" ||
      status === "draft" ||
      (scheduleTime === null && status === "scheduled")
    ) {
      error.push("dateTime");
    } else {
      const idx = error.indexOf("dateTime");
      if (idx !== -1) {
        error.splice(idx, 1);
      }
    }

    MessageAddDispatch({ type: "checkMessage", error, errorCoupon });

    if (
      status === "scheduled" &&
      scheduleTime !== null &&
      nowDateTime > new Date(scheduleTime)
    ) {
      window.app.alert.setMessage(
        t("sendMenuTalk.campaignSettings.pls_wrongScheduleTime"),
        "error"
      );
      return;
    }

    if (error.length === 0 && errorCoupon.length === 0) {
      MessageAddDispatch({ type: "checkMessage", error: [], errorCoupon: [] });
      MessageAddDispatch({ type: "setIsLoading", isLoading: true });

      const newMenuTalkContent = _.cloneDeep(menuTalkContent);

      for (let i = 0; i < newMenuTalkContent.length; i++) {
        if (newMenuTalkContent[i].type === "new_image") {
          delete newMenuTalkContent[i].url;
        }
      }

      for (let i = 0; i < newMenuTalkContent.length; i++) {
        if (newMenuTalkContent[i].type === "voucher") {
          delete newMenuTalkContent[i].endDateTime;
        }
      }

      const fd = new FormData();
      const crmMenuTalkContent = {
        title: title.trim(),
        contents: newMenuTalkContent,
        filter_tag: filterTag,
        status: status,
        schedule_time: scheduleTime,
        followers: followers,
        filter_conditions: {
          exchange_min: exchangeMin ? exchangeMin : null,
          exchange_max: exchangeMax ? exchangeMax : null,
          fetch_min: fetchMin ? fetchMin : null,
          fetch_max: fetchMax ? fetchMax : null,
          gender: gender ? gender : null,
        },
      };

      if (uploadImg.length !== 0) {
        uploadImg.forEach((ele) => {
          fd.append("upload_imgs[]", ele);
        });
      }

      fd.append("crm_menu_body", JSON.stringify(crmMenuTalkContent));

      if (!id) {
        try {
          await createCrmMenuTalk(fd);

          MessageAddDispatch({ type: "setIsLoading", isLoading: false });

          // 清除從顧客列表進入新增 menu 私訊的 state
          browserHistory.push({
            pathname: "/dashboard/messages",
          });

          // 儲存進入排程
          if (status === "real_time") {
            MessageStateDispatch({
              type: "setMessageNotification",
              notificationType: "sent",
              msgType: "menuTalk",
            });
          } else if (status === "scheduled") {
            MessageStateDispatch({
              type: "setMessageNotification",
              notificationType: "scheduling",
              msgType: "menuTalk",
            });
          }

          MessageAddDispatch({ type: "cleanMessageSetting" });
          MessageStateDispatch({ type: "switchMode", mode: "messageList" });
          MessageStateDispatch({
            type: "switchMessageStatus",
            messageStatus: "sent",
          });
        } catch (err) {
          console.log("save crm menuTalk message to scheduled err", err);

          MessageAddDispatch({ type: "setIsLoading", isLoading: false });
          window.app.alert.setMessage(
            t("settings:status.pls_tryAgainLater"),
            "error"
          );
        }
      } else {
        try {
          await updateCrmMenuTalk(id, fd);

          MessageAddDispatch({ type: "setIsLoading", isLoading: false });

          // 儲存進入排程
          if (status === "real_time") {
            MessageStateDispatch({
              type: "setMessageNotification",
              notificationType: "sent",
              msgType: "menuTalk",
            });
          } else if (status === "scheduled") {
            MessageStateDispatch({
              type: "setMessageNotification",
              notificationType: "scheduling",
              msgType: "menuTalk",
            });
          }

          MessageAddDispatch({ type: "cleanMessageSetting" });
          MessageStateDispatch({ type: "switchMode", mode: "messageList" });
          MessageStateDispatch({
            type: "switchMessageStatus",
            messageStatus: "sent",
          });
        } catch (err) {
          console.log("update crm menuTalk message to scheduled err", err);

          MessageAddDispatch({ type: "setIsLoading", isLoading: false });
          window.app.alert.setMessage(
            t("settings:status.pls_tryAgainLater"),
            "error"
          );
        }
      }
    }
  };

  const renderActionBtn = () => {
    if (editType === "sms") {
      return (
        <button className="button-primary btn-next" onClick={() => toNext()}>
          下一步
        </button>
      );
    } else if (editType === "menuTalk") {
      return (
        <button
          className="button-primary btn-next"
          onClick={() => send()}
          // onClick={() => checkSendTimes()}
        >
          {t("sendMenuTalk.campaignSettings.send")}
        </button>
      );
    }
  };

  return <Fragment>{renderActionBtn()}</Fragment>;
};

MessageNextStep.Button = NextStepButton;

export default MessageNextStep;
