import React, { Fragment, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { CouponStateContext } from "./MenuCouponContext/MenuCouponContext";
import { CouponAddStateContext } from "./MenuCouponContext/MenuCouponAddContext";
import { MoreOutlined } from "@ant-design/icons";
import { useTranslation, Trans } from "react-i18next";

const MenuCouponListCard = ({ children }) => {
  return (
    <div className="menuCouponListCard">
      {React.Children.map(children, (child) => child)}
    </div>
  );
};

const MenuCouponListCardImg = ({ status, thumbnailURL }) => {
  const { t } = useTranslation("couponSystem");
  let notActiveCard = "";

  if (status === "draft" && status === "disable") {
    notActiveCard = "notActiveCard";
  }

  const renderCouponStatus = () => {
    const couponStatus = {
      enable: t("couponOverview.inProgress"),
      draft: t("couponOverview.draft"),
      standby: t("couponOverview.scheduled"),
      disable: t("couponOverview.paused"),
      expired: t("couponOverview.expired"),
    };
    if (status)
      return (
        <label className={`couponStatus_${status}`}>
          {couponStatus[status]}
        </label>
      );
  };
  return (
    <div className="menuCouponListCard__img">
      {renderCouponStatus()}
      <figure className={`${notActiveCard}`}>
        {thumbnailURL !== "" && (
          <img src={thumbnailURL} alt={t("addCoupon.coverImage")} />
        )}
      </figure>
    </div>
  );
};

const MenuCouponListCardContent = ({ showType = "listType", list }) => {
  const { t } = useTranslation("couponSystem");
  const { CouponState, CouponDispatch } = useContext(CouponStateContext);
  const { couponLists } = CouponState;

  const { CouponAddDispatch } = useContext(CouponAddStateContext);

  const {
    id,
    status,
    title,
    endDateTime,
    exchangedCount,
    sentCount,
    voucherCount,
    lastEditDateTime,
    publishDateTime,
    countLimited,
  } = list;
  const lastEditTimeFormat = moment(new Date(lastEditDateTime)).format(
    "YYYY-MM-DD HH:mm"
  );
  let notActiveCard = "";
  let endCouponStyle = "";

  if (status === "draft" && status === "disable") {
    notActiveCard = "notActiveCard";
  }

  if (status === "expired") endCouponStyle = "endCoupon";

  const newPublishTime =
    publishDateTime === null
      ? t("coupon.unset")
      : moment(new Date(publishDateTime)).format("YYYY-MM-DD HH:mm");
  const newEndDateTime =
    endDateTime === null
      ? t("coupon.unset")
      : moment(new Date(endDateTime)).format("YYYY-MM-DD HH:mm");

  const handleEditor = (item) => {
    if (item === "edit") {
      CouponDispatch({ type: "setEditorType", editType: item, editId: id });
      const findCouponIndex = couponLists.map((list) => list.id).indexOf(id);
      const couponSettingState = _.cloneDeep(couponLists[findCouponIndex]);
      CouponAddDispatch({ type: "setEditorSetting", couponSettingState });
    }

    if (item === "delete") {
      CouponDispatch({ type: "setDeleteCoupon", title, id });
    }

    if (item === "disable") {
      CouponDispatch({ type: "setPauseCoupon", title, id });
    }

    if (item === "password") {
      CouponDispatch({ type: "setPasswordCoupon", title, id });
    }

    if (item === "cancel") {
      CouponDispatch({ type: "setCancelPause", title, id });
    }

    if (item === "preview") {
      const previewIndex = couponLists.map((list) => list.id).indexOf(id);

      CouponDispatch({
        type: "setPreview",
        previewInfo: couponLists[previewIndex],
      });
    }

    if (item === "share") {
      const shareIndex = couponLists.map((list) => list.id).indexOf(id);
      CouponDispatch({
        type: "showSharepopup",
        listInfo: couponLists[shareIndex],
      });
    }

    if (item === "export") {
      const url = "https://voucher.menushop.tw/voucherReport/" + id;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
    }
  };

  const renderEditList = () => {
    const couponEditList = {
      enable: [
        { title: t("coupon.edit"), action: "edit" },
        { title: t("coupon.preview"), action: "preview" },
        { title: t("coupon.changePassword"), action: "password" },
        { title: t("coupon.paused"), action: "disable" },
        { title: t("coupon.share"), action: "share" },
        { title: t("coupon.export"), action: "export" },
      ],
      draft: [
        { title: t("coupon.edit"), action: "edit" },
        { title: t("coupon.preview"), action: "preview" },
        { title: t("coupon.delete"), action: "delete" },
      ],
      standby: [
        { title: t("coupon.edit"), action: "edit" },
        { title: t("coupon.preview"), action: "preview" },
        { title: t("coupon.changePassword"), action: "password" },
      ],
      disable: [
        { title: t("coupon.edit"), action: "edit" },
        { title: t("coupon.preview"), action: "preview" },
        { title: t("coupon.changePassword"), action: "password" },
        { title: t("coupon.restart"), action: "cancel" },
        { title: t("coupon.export"), action: "export" },
      ],
      expired: [
        { title: t("coupon.preview"), action: "preview" },
        { title: t("coupon.export"), action: "export" },
      ],
    };

    return couponEditList[status].map((item) => {
      return (
        <li key={item.action} onClick={() => handleEditor(item.action)}>
          {item.title}
        </li>
      );
    });
  };

  const renderVoucherCount = () => {
    if (countLimited === 1) {
      return (
        <label className={`supplied  ${endCouponStyle}`}>
          <span>
            <Trans
              i18nKey="couponSystem:couponOverview.offered"
              voucherCount={voucherCount}
            >
              提供<strong>{{ voucherCount }} 張</strong>
            </Trans>
          </span>
        </label>
      );
    }

    if (countLimited === 0) {
      return (
        <label className={`supplied ${endCouponStyle}`}>
          <span>{t("coupon.unlimited")}</span>
        </label>
      );
    }
  };

  return (
    <div className={`menuCouponListCard__content ${notActiveCard}`}>
      <section className="contentTitle">
        <div>
          <h2>{title}</h2>
        </div>
        {showType === "listType" && (
          <div className="listCardDropDown">
            <button
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <MoreOutlined />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <ul>{renderEditList()}</ul>
            </div>
          </div>
        )}
      </section>

      <div>
        <section className="contentTime">
          <p className="publishTime">
            <span className="title">{t("couponOverview.releasedDate")}</span>
            <span className="time">{newPublishTime}</span>
          </p>
          <p className="exchangeTime">
            <span className="title">{t("couponOverview.expiryDate")}</span>
            <span className="time">{newEndDateTime}</span>
          </p>
        </section>

        {showType === "listType" && (
          <Fragment>
            <section className='contentCount'>
                <label className={`exchanged  ${endCouponStyle}`}>
                  <span>
                    <Trans
                      i18nKey="couponSystem:couponOverview.redeemed"
                      exchangedCount={exchangedCount}
                    >
                      已兌換<strong>{{ exchangedCount }} 份</strong>
                    </Trans>
                  </span>
                </label>
                {renderVoucherCount()}
                <label className={`received  ${endCouponStyle}`}>
                  <span>
                    <Trans
                      i18nKey="couponSystem:couponOverview.claimed"
                      sentCount={sentCount}
                    >
                      已領取<strong>{{ sentCount }} 份</strong>
                    </Trans>
                  </span>
                </label>
            </section>

            <div className="contentUpdateTime">
              {t("couponOverview.updated")}: {lastEditTimeFormat}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

MenuCouponListCard.Img = MenuCouponListCardImg;
MenuCouponListCard.Content = MenuCouponListCardContent;

export default MenuCouponListCard;
