import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { dayList, dayListFullEN } from '../../../utils/constants';

const AreaBlock = ({ children }) => {
	return <div className="areaBlock">{React.Children.map(children, (child) => child)}</div>;
};

const AreaBlockHeader = ({ group, groupName, index, showWarning }) => {
	const { t } = useTranslation('settings');
	return (
		<div className="areaBlock__title">
			<h2>
				{group.name}
				<span>({groupName.length}{t('serviceOptionSetting.tableTypes')})</span>
			</h2>
			<div className="areaBlock__title__buttons">
				<button className="areaDelButton" onClick={() => showWarning('delete', index)}>
					<span>{t('delete')}</span>
				</button>
				<button className="areaEditButton" onClick={() => showWarning('edit', index)}>
					<span>{t('edit')}</span>
				</button>
			</div>
		</div>
	);
};

const AreaBlockBody = ({ groupName, bookingTimeSetting, payment = {}, serviceTimeOptionEnabled }) => {
	const { t } = useTranslation('settings');
	const showTimeSetting = useMemo(
		() => {
			return bookingTimeSetting.length !== 0;
		},
		[ bookingTimeSetting ]
	);

	const renderServiceTime = () => {
		return bookingTimeSetting.map((setting, index) => {
			return (
				<AreaBlockServiceRow key={index}>
					<AreaBlockServiceRow.Title index={index} />
					<AreaBlockServiceRow.ServiceTime settingAns={setting} />
					<AreaBlockServiceRow.DiningTime
						fixedDiningTime={setting.dining_time}
						diningTimeOptions={setting.service_time_setting[0]}serviceTimeOptionEnabled={serviceTimeOptionEnabled}
					/>
				</AreaBlockServiceRow>
			);
		});
	};

	const renderPayment = () => {
		if (!payment.active) return null;

		if (payment.enable === 1) {
			return (
        <section>
          <p>{t("serviceOptionSetting.requireAdvancedPayment")}</p>
          <p>
            {t("serviceOptionSetting.displayAdvancePaymentForEachPerson", {
              person: payment.setting1.deposit_people_required,
              dollar: payment.setting1.deposit,
            })}
          </p>
        </section>
      );
		}

		if (payment.enable === 2) {
      return (
        <section>
          <p>{t("serviceOptionSetting.requireAdvancedPayment")}</p>
          <p>
            {t("serviceOptionSetting.displayAPaymentFixed", {
              dollar: payment.setting2,
            })}
          </p>
        </section>
      );
    }
	};

	return (
		<div className="areaBlock__body">
			<section>
				<p>{t('serviceOptionSetting.tableType_2')}</p>
				<div className="groups">
					{groupName.map((table, index) => {
						return (
							<span key={index} className="groups_table">
								{table.group}
							</span>
						);
					})}
				</div>
			</section>
			{showTimeSetting && (
				<section>
					<p>{t('serviceOptionSetting.availableBookingTimeAndServiceDuration')}</p>
					{renderServiceTime()}
				</section>
			)}
			{renderPayment()}
		</div>
	);
};

const AreaBlockServiceRow = ({ children }) => {
	return <div className="groupsServiceTime">{React.Children.map(children, (child) => child)}</div>;
};

const AreaBlockServiceRowTitle = ({ index }) => {
	const { t } = useTranslation('settings');
	return <div className="groupsServiceTime__title">{t('bookingTimesCommon.title')}{index + 1}</div>;
};

const AreaBlockServiceTime = ({ settingAns }) => {
	const { i18n } = useTranslation('settings');

	const renderTime = () => {
		const { enable, setting2, setting3 } = settingAns.setting_temp;

		if (enable === 2) return setting2.map((r) => `${r[0]}~${r[1]}`).join('、');
		if (enable === 3) return setting3.join('、');
	};

	const renderWeek = () => {
		if(i18n.language === 'zh') {
			return settingAns.week_days.map((w) => `星期${dayList[w]}`).join('、');
		} else {
			return settingAns.week_days.map((w) => dayListFullEN[w]).join(', ');
		}
	};

	return (
		<div className="groupsServiceTime__content">
			<p>{renderWeek()}</p>
			<p>{renderTime()}</p>
		</div>
	);
};

const AreaBlockServiceRowDiningTime = ({ fixedDiningTime, diningTimeOptions, serviceTimeOptionEnabled }) => {
	const { t } = useTranslation('settings');
	let diningTime_all = [];
	let diningTime_min = 0;
	let diningTime_max = 0;
	
	if(serviceTimeOptionEnabled) {
		if(diningTimeOptions.mode === 'user_setting') {
			diningTime_all = diningTimeOptions.user_setting.service_time_setting;
	
			if(diningTimeOptions.user_setting.show_name) {
				return (
					<div className="groupsServiceTime__diningTime">
						{t('bookingTimesCommon.serviceDuration')}：
						<span>{t('bookingTimesCommon.customTimeDurationOptions_2')}</span>
						<div>{t('bookingTimesCommon.customizeOptionNames')}：{t('enabled')}</div>
						<div>
							{diningTime_all.map((time, index) => {
								return (
									<div key={index}>
										<span>{time.name} ({Math.floor(parseInt(time.service_time, 10) / 60)} {t('time:hour')} {Math.floor(parseInt(time.service_time, 10) % 60)} {t('time:minute')})
										{
											index !== diningTime_all.length - 1 ? (<span>、</span>) : null
										}
										</span>
									</div>
								)
							})}
						</div>
					</div>
				)
			} else {
				return (
					<div className="groupsServiceTime__diningTime">
					{t('bookingTimesCommon.serviceDuration')}：
					<span>{t('bookingTimesCommon.customTimeDurationOptions_2')}</span>
					<div>{t('bookingTimesCommon.customizeOptionNames')}：{t('disabled')}</div>
					<div>
						{diningTime_all.map((time, index) => {
							return (
								<span key={index}>
									<span>{Math.floor(parseInt(time.service_time, 10) / 60)} {t('time:hour')} {Math.floor(parseInt(time.service_time, 10) % 60)} {t('time:minute')}
									{
										index !== diningTime_all.length - 1 ? (<span>、</span>) : null
									}
									</span>
								</span>
							)
						})}
					</div>
				</div>
				)
			}
		} else if (diningTimeOptions.mode === 'fixed') {
			diningTime_min = diningTimeOptions.fixed.min;
			diningTime_max = diningTimeOptions.fixed.max;
	
			return (
				<div className="groupsServiceTime__diningTime">
				{t('bookingTimesCommon.serviceDuration')}：
					<span>{t('bookingTimesCommon.fixedIntervalTimeDurationOptions_2')}</span>
					<div>
						<span>{t('bookingTimesCommon.minimum')} {Math.floor(parseInt(diningTime_min, 10) / 60)} {t('time:hour')} {Math.floor(parseInt(diningTime_min, 10) % 60)} {t('time:minute')}</span>
						～ 
						<span>{t('bookingTimesCommon.maximum')} {Math.floor(parseInt(diningTime_max, 10) / 60)} {t('time:hour')} {Math.floor(parseInt(diningTime_max, 10) % 60)} {t('time:minute')}</span>
						</div>
					<div>{t('bookingTimesCommon.timeDurationIntervals')}：{diningTimeOptions.fixed.unit}{t('time:minute')}</div>
				</div>
			)
		}
	} else {
		return (
			<div className="groupsServiceTime__diningTime" style={{ width: 'auto' }}>
				{t('bookingTimesCommon.serviceDuration')}：{Math.floor(parseInt(fixedDiningTime, 10) / 60)} {t('time:hour')} {Math.floor(parseInt(fixedDiningTime, 10) % 60)} {t('time:minute')}
			</div>
		)
	}
};

AreaBlock.Header = AreaBlockHeader;
AreaBlock.Body = AreaBlockBody;

AreaBlockServiceRow.Title = AreaBlockServiceRowTitle;
AreaBlockServiceRow.ServiceTime = AreaBlockServiceTime;
AreaBlockServiceRow.DiningTime = AreaBlockServiceRowDiningTime;

export default AreaBlock;
