import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

//reducer
const tableBookingNum = [1, 2, 3, 4, 5, 6, 7, 8, 9, "delete", 0, "check"];

export const TimelineLeaveTimePanel = ({
  date,
  currentTime,
  bookingTable,
  diningTime,
  backToStep1,
  bookingSubmit,
  closeTimelineSeat,
}) => {
  const { t } = useTranslation("bookingSystem");
  const checkRef = useRef(null);
  const [longestDiningTime] = useState(8 * 60); //最長diningTime 8小時
  const [SeatedTimePanelCount, setSeatedTimePanelCount] = useState(0);
  const [displayTime, setDisplayTime] = useState("");
  const [timeCell, setTimeCell] = useState(["-", "-", ":", "-", "-"]);
  const [latestBookingTime, setLatestBookingTime] = useState([]);

  useEffect(() => {
    let hasNextBooking = false,
      closestTime = diningTime;

    if (bookingTable.length !== 0) {
      //計算最短dining time
      bookingTable.forEach((t) => {
        if (Object.keys(t.nextBooking).length !== 0) {
          hasNextBooking = true;
          const bookingTimestamp = moment(t.nextBooking.booking_datetime);
          const bookingDiff = moment.duration(
              bookingTimestamp.diff(currentTime)
            ),
            durationDiff = bookingDiff.asMinutes();

          if (closestTime > durationDiff) {
            closestTime = durationDiff;
          }
        }
      });
    } else {
      hasNextBooking = false;
    }

    if (!hasNextBooking) {
      //所選的桌子都沒有下一筆預約:
      //最晚預約時間longestDiningTime
      //顯示時間預設dining time
      const LatestBookingTime = moment(new Date(currentTime))
        .add(longestDiningTime, "m")
        .format("YYYY/MM/DD HH:mm");
      const time = moment(new Date(currentTime))
        .add(diningTime, "m")
        .format("HH:mm")
        .split("");
      setTimeCell(time);
      setLatestBookingTime(LatestBookingTime);
    } else {
      //所選的桌子都有下一筆預約: 最短的dining time
      const LatestBookingTime = moment(new Date(currentTime))
        .add(closestTime, "m")
        .format("YYYY/MM/DD HH:mm");
      const time = moment(new Date(currentTime))
        .add(closestTime, "m")
        .format("HH:mm")
        .split("");
      setTimeCell(time);
      setLatestBookingTime(LatestBookingTime);
    }
  }, [bookingTable, currentTime, longestDiningTime, diningTime]);

  useEffect(() => {
    const time = timeCell.join("");
    setDisplayTime(time);
  }, [timeCell]);

  const timeClick = (keyboard) => {
    let newTime = displayTime.split("");

    if (SeatedTimePanelCount === 0) {
      newTime = ["-", "-", ":", "-", "-"];
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(1);
    } else if (SeatedTimePanelCount === 1) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(3);
    } else if (SeatedTimePanelCount === 3) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(4);
    } else if (SeatedTimePanelCount === 4) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(0);
    }

    setTimeCell(newTime);
  };

  const timeDelete = () => {
    let newTime = displayTime.split("");

    if (SeatedTimePanelCount === 0) {
      newTime[4] = "-";
      setSeatedTimePanelCount(4);
    } else if (SeatedTimePanelCount === 4) {
      newTime[3] = "-";
      setSeatedTimePanelCount(3);
    } else if (SeatedTimePanelCount === 3) {
      newTime[1] = "-";
      setSeatedTimePanelCount(1);
    } else if (SeatedTimePanelCount === 1) {
      newTime[0] = "-";
      setSeatedTimePanelCount(0);
    }

    setTimeCell(newTime);
  };

  const timeCheck = () => {
    const displayTime_hour = parseInt(displayTime.substring(0, 2), 10),
      displayTime_min = parseInt(displayTime.substring(3), 10);

    if (displayTime_hour >= 24) {
      return window.app.alert.setMessage(
        t("tableMap.pls_enterCorrectTimeFormat"),
        "error"
      );
    }

    if (displayTime_min > 59) {
      return window.app.alert.setMessage(
        t("tableMap.pls_enterCorrectTimeFormat"),
        "error"
      );
    }

    if (displayTime_min % 5 !== 0) {
      return window.app.alert.setMessage(
        t("settings:bookingTimesCommon.pls_minimumTimeUnit"),
        "error"
      );
    }

    const todayDate = moment(new Date(date)).format("YYYY-MM-DD"),
      tomorrowDate = moment(new Date(date)).add(1, "day").format("YYYY-MM-DD");
    const latestBookingTimeMoment = moment(new Date(latestBookingTime));
    const current = moment(new Date(currentTime));
    const displayTimeMoment_today = moment(todayDate + " " + displayTime);
    const displayTimeMoment_tomorrow = moment(tomorrowDate + " " + displayTime);
    const $checkButton = checkRef.current;

    if (
      displayTimeMoment_today.isAfter(current) &&
      displayTimeMoment_today.isSameOrBefore(latestBookingTimeMoment)
    ) {
      $checkButton.disabled = true;

      bookingSubmit(currentTime, displayTimeMoment_today, $checkButton);
    } else if (
      displayTimeMoment_tomorrow.isAfter(current) &&
      displayTimeMoment_tomorrow.isSameOrBefore(latestBookingTimeMoment)
    ) {
      $checkButton.disabled = true;

      bookingSubmit(currentTime, displayTimeMoment_tomorrow, $checkButton);
    } else {
      $checkButton.disabled = false;
      window.app.alert.setMessage(t("tableMap.pls_outOfTimeRange"), "error");
    }
  };

  const renderKeyboardCell = () => {
    return tableBookingNum.map((keyboard, index) => {
      if (keyboard === "delete") {
        return (
          <div
            key={index}
            className="keyboardCell keyboardCell-del"
            onClick={() => timeDelete()}
          >
            <img
              className="numPad_del"
              src={require("../../../images/newModule/delete.svg")}
              alt="delete"
            />
          </div>
        );
      } else if (keyboard === "check") {
        return (
          <button
            ref={checkRef}
            key={index}
            className="keyboardCell keyboardCell-check"
            onClick={() => timeCheck()}
          >
            {t("settings:ok")}
          </button>
        );
      } else {
        return (
          <div
            key={index}
            className="keyboardCell"
            onClick={() => timeClick(keyboard)}
          >
            {keyboard}
          </div>
        );
      }
    });
  };

  return (
    <div className="TimelineLeaveTime">
      <button
        className="closeTimelineSeatBtn"
        onClick={() => closeTimelineSeat()}
      />

      <button className="backToStep1" onClick={() => backToStep1()}>
        {t("popup.rearrangeTable")}
      </button>
      <section className="TimelineLeaveTimePanel">
        <div className="TimelineLeaveTimePanel__title">
          {t("tableMap.estimatedDepartureTime")}
        </div>

        <div className="TimelineLeaveTimePanel__header">
          <span>{displayTime}</span>
          <p>
            {t("tableMap.theLatestAppointmentTime")} {latestBookingTime}
          </p>
        </div>

        <div className="TimelineLeaveTimePanel__body">
          <div className="keyboard">{renderKeyboardCell()}</div>
        </div>
      </section>
    </div>
  );
};
