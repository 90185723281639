import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
  Fragment,
} from "react";
import moment from "moment";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";

import BookingSystemApi from "../../booking_settings/BookingSystemApi";
import { customQAns } from "../NewModuleComponent/NewModuleTools";

import { bookingStatus, useInterval } from "../utils/data";

import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";
import { BaseSettingContext } from "../NewModuleReducer/BaseSettingReducer";
import { Space, Tooltip } from "antd";
import CheckModal from "../../popup/CheckModal";
import BookingCustomerTags from "../../bookings/items/BookingCustomerTags";
import { DudooSyncDisplay }from "../../commons/DudooSyncDisplay";

const BookingAPI = new BookingSystemApi();

const BookingListCard = ({ booking, children }) => {
  return (
    <section id={booking.id} className="bookingList__card">
      {React.Children.map(children, (child) => child)}
    </section>
  );
};

const BookingListCardHeader = ({ children }) => {
  return (
    <div className="bookingList__card__header">
      {React.Children.map(children, (child) => child)}
    </div>
  );
};

const BookingListCardHeaderTime = ({ booking }) => {
  const renderBookingSource = () => {
    if (booking.source === "online") {
      if (booking.source_url_t === "google") {
        return <span className="bookingSourceIcon bookingSourceIcon-google" />;
      } else if (booking.source_url_t === "facebook") {
        return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
      } else if (booking.source_url_t === "instagram") {
        return (
          <span className="bookingSourceIcon bookingSourceIcon-instagram" />
        );
      } else if (booking.source_url_t === "menuApp") {
        return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
      } else if (booking.source_url_t === "other") {
        return <span className="bookingSourceIcon bookingSourceIcon-other" />;
      }
    }

    if (
      booking.source === "google_booking" &&
      booking.source_url_t === "google_reservation"
    ) {
      return (
        <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />
      );
    }
  };

  const renderBookingEndTime = () => {
    const endTime = moment(booking.booking_datetime)
      .add(booking.original_dining_time, "m")
      .format("HH:mm");

    return <span className="bookingEndTime"> - {endTime}</span>;
  };

  return (
    <div className="bookingList__card__time color-title">
      <span>{renderBookingSource()}</span>
      <span>
        {booking.time}
        {renderBookingEndTime()}
      </span>
    </div>
  );
};

const BookingListCardHeaderStatus = ({ booking }) => {
  const { CommomSettingState, setStatus } = useContext(CommomSettingContext);
  const { isToday, date } = CommomSettingState;

  const renderBookingStatus = () => {
    if (
      booking.status !== "finish" &&
      booking.status !== "cancel" &&
      booking.status !== "no_show"
    ) {
      return (
        <BookingStatus
          setStatus={setStatus}
          booking={booking}
          isToday={isToday}
          date={date}
        />
      );
    }
  };

  return (
    <div className="bookingList__card__status color-subtitle">
      {renderBookingStatus()}
    </div>
  );
};

const BookingListCardBody = ({ booking, children }) => {
  const { t } = useTranslation("bookingSystem");
	const { updateBookingAndSetting } = useContext(CommomSettingContext);
  const { BaseSettingState } = useContext(BaseSettingContext);
  const { customQ } = BaseSettingState;

  const customQAnsMemo = useMemo(() => {
    return customQAns(customQ, booking["customized_questions"]);
  }, [customQ, booking]);

  return (
    <div className="bookingList__card__body">
      {React.Children.map(children, (child) => child)}

      <Space direction="vertical" size={2}>
        {booking.shop_memo && booking.shop_memo !== "" ? (
          <p className="bookingList__card__memo shopMemo text-small color-subtitle">
            {booking.shop_memo}
          </p>
        ) : null}
        {booking.memo !== null ? (
          <p className="bookingList__card__memo text-small color-subtitle">
            {booking.memo}
          </p>
        ) : null}
      </Space>
      <div className="bookingList__card__customerTag color-subtitle">
        <ul>
          {customQAnsMemo.serviceTagBlock.map((tag, index) => (
            <li key={index}>{tag}</li>
          ))}
          {customQAnsMemo.quantityBlock.map((ele, index) => (
            <li key={index}>
              {ele.name}x{ele.value}
            </li>
          ))}
        </ul>
      </div>

      <div className="bookingList__card__noteWRap color-subtitle">
        <ul>
          {customQAnsMemo.questionsBlock.map((q, index) => (
            <li key={index}>{q}</li>
          ))}
        </ul>
      </div>
			<DudooSyncDisplay
				mode="timeline"
				booking={booking}
				updateData={updateBookingAndSetting}
			/>
      <div className="bookingList__card__bookingCode">
        <p>
          {t(`source.${booking.source}`)} | {t("item.created")}:{" "}
          {moment(new Date(booking.created_at)).format("YYYY/MM/DD HH:mm")}
        </p>
        <p>
          {t("item.updated")}:{" "}
          {moment(new Date(booking.updated_at)).format("YYYY/MM/DD HH:mm")} |{" "}
          {t("item.bookingID")}: {booking.active_code}{" "}
        </p>
      </div>
    </div>
  );
};

const BookingListCardBodyInfo = ({
  booking,
  index,
  serviceTimeOptionEnabled,
}) => {
  const { t, i18n } = useTranslation("bookingSystem");
  const { editBooking } = useContext(CommomSettingContext);

  const renderSeatArea = () => {
    const { source, space_group_selection_t } = booking;
    if (source === "online" && space_group_selection_t !== "") {
      return (
        <span className="seatArea color-subtitle">
          {space_group_selection_t}
        </span>
      );
    }
  };

  const renderStandbyTag = () => {
    if (booking.status === "show") {
      return <span className="standbyTag">{index + 1}</span>;
    }
  };

  const renderOnSiteTag = () => {
    if (booking.on_site) {
      return <span className="icon-sm onsite-icon" />;
    }
  };

  const renderDiningTimeTag = () => {
    const { service_time_str, dining_time, source } = booking;
    const diningTime_hour = Math.floor(parseInt(dining_time, 10) / 60);
    const diningTime_min = Math.floor(parseInt(dining_time, 10) % 60);

    const diningTimeLabel = t("time.diningTime", {
      X: diningTime_hour,
      Y: diningTime_min,
    });

    return (
      <span className="diningTimeTag">
        <span className="icon-xs time-icon" />
        {serviceTimeOptionEnabled && source !== "offline" && (
          <>
            <span className="diningTimeContent">{service_time_str}</span>&nbsp;
          </>
        )}
        <span>({diningTimeLabel})</span>
      </span>
    );
  };

  const hasContent =
    renderStandbyTag() || renderSeatArea() || renderOnSiteTag();

  return (
    <Fragment>
      {hasContent && (
        <div className="bookingList__card__tags">
          {renderStandbyTag()}
          {renderSeatArea()}
          {renderOnSiteTag()}
        </div>
      )}
      <div>{renderDiningTimeTag()}</div>
      <div className="bookingList__card__info">
        <div className="bookingList__card__name">
          <Space size={2} align="center">
            <BookingCustomerTags
              padding={2}
              isBlocked={booking.customer_info.blacklisted}
              note={booking.customer_info.note}
              tags={booking.customer_info.tags}
            />
            <Space size={8} align="end">
              <Space size={2}>
                {i18n.language !== "zh" && booking.gender !== "other" && (
                  <span className="gender">{t(`item.${booking.gender}`)}</span>
                )}
                {booking.customer_id ? (
                  <Link to={`/dashboard/customers/${booking.customer_id}`}>
                    <span className="name">{booking.last_name}</span>
                  </Link>
                ) : (
                  <span className="name">{booking.last_name}</span>
                )}
                {i18n.language === "zh" && booking.gender_desc.length !== 0 && (
                  <span className="gender">{booking.gender_desc}</span>
                )}
              </Space>
              <button onClick={() => editBooking(booking)}>
                <div className="icon-normal edit-pen-icon" />
              </button>
            </Space>
          </Space>
          <p className="bookingList__card__tel">{booking.phone_number}</p>
        </div>
        <Space size={4}>
          <span className="bookingList__card__attendance">
            {booking.attendance}
          </span>
          <span className="icon-md account-icon" />
        </Space>
      </div>
    </Fragment>
  );
};

const BookingListCardBodySeats = ({ booking }) => {
  const { t } = useTranslation("bookingSystem");
  const { BaseSettingState } = useContext(BaseSettingContext);
  const { showCanvas } = BaseSettingState;

  const { CommomSettingState, CommomSettingDispatch } =
    useContext(CommomSettingContext);
  const { mode, tablemapTableGroup } = CommomSettingState;

  const bookingSeat = () => {
    if (mode !== "tablemap") return;
    const newSeat = JSON.parse(booking.seating_json);
    CommomSettingDispatch({ type: "setBookingCardSeats", seats: newSeat });
  };

  const renderGroupSeat = () => {
    let seatGroup = {},
      notHaveSeatGroup = [];

    JSON.parse(booking.seating_json).forEach((seat) => {
      let emptyGroupSeat = true;
      Object.keys(tablemapTableGroup).forEach((group) => {
        const findSeatIndx = tablemapTableGroup[group].indexOf(
          seat.table_number
        );
        if (findSeatIndx !== -1) {
          emptyGroupSeat = false;
          if (!seatGroup.hasOwnProperty(group)) {
            seatGroup[group] = [];
            seatGroup[group].push(seat.table_number);
          } else {
            seatGroup[group].push(seat.table_number);
          }
        }
      });
      if (emptyGroupSeat) notHaveSeatGroup.push(seat.table_number);
    });

    return (
      <React.Fragment>
        <div className="bookingList__card__seats">
          <Space direction="vertical" size={5}>
            {Object.keys(seatGroup).map((group, index) => {
              return (
                <div key={index}>
                  <span>{group}</span>
                  {seatGroup[group].map((seat, seatIndex) => (
                    <span key={seatIndex} onClick={() => bookingSeat()}>
                      {seat}
                    </span>
                  ))}
                </div>
              );
            })}
          </Space>

          <div className="bookingList__card__seats bookingList__card__normalSeats">
            {notHaveSeatGroup.map((seat, index) => (
              <span key={index}>{seat}</span>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderSeat = () => {
    return (
      <div className="bookingList__card__seats bookingList__card__normalSeats">
        {JSON.parse(booking.seating_json).map((seat, index) => (
          <span key={index}>{seat.table_number}</span>
        ))}
      </div>
    );
  };

  const renderDeposit = () => {
    const { deposit_required } = booking;
    const { last_pay_info, deposit_paid, deposit, deposit_due_date, order_no } =
      booking;

    if (!deposit_required) return;

    if (deposit_paid && last_pay_info) {
      let paymentInfo = null;
      const parseLastPayInfo = JSON.parse(last_pay_info);
      const payInfo = parseLastPayInfo.result.data;

      if (payInfo.PaymentType === "CREDIT") {
        paymentInfo = (
          <Space direction="vertical" size={10}>
            <div>
              {t("payment.status")}：
              <span style={{ color: "#3AA177" }}>{t("payment.paid")}</span> (
              {payInfo.PayTime})
            </div>
            <div>
              {t("payment.amount")}：{deposit}
            </div>
            <div>
              {t("payment.method")}：{t("payment.creditCard")}
            </div>
            <div>
              {t("payment.cardNumber")}：{payInfo.Card6No.slice(0, 4)} -{" "}
              {payInfo.Card6No.slice(4)}** - **** - {payInfo.Card4No}
            </div>
            <div>
              {t("payment.orderID")}：{order_no}
            </div>
          </Space>
        );
      } else {
        paymentInfo = (
          <Space direction="vertical" size={10}>
            <div>
              {t("payment.status")}：
              <span style={{ color: "#3AA177" }}>{t("payment.paid")}</span> (
              {payInfo.PayTime})
            </div>
            <div>
              {t("payment.amount")}：{deposit}
            </div>
            <div>
              {t("payment.method")}：{t("payment.csv")}
            </div>
            <div>
              {t("payment.barcode")}：{payInfo.CodeNo}
            </div>
            <div>
              {t("payment.orderID")}：{order_no}
            </div>
          </Space>
        );
      }

      return (
        <Tooltip
          title={paymentInfo}
          overlayClassName="deposit-popover"
          placement="right"
          trigger="click"
          align={{
            offset: [-15, 0],
          }}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
          <div className="bookingList__card__payment">$ {deposit}</div>
        </Tooltip>
      );
    }

    if (!deposit_paid) {
      const content = (
        <Space direction="vertical" size={10}>
          <div>
            {t("payment.status")}：{t("payment.due")}
          </div>
          <div>
            {t("payment.amount")}：{deposit}
          </div>
          <div>
            {t("payment.dueDate")}：
            {deposit_due_date
              ? moment(deposit_due_date).format("YYYY/MM/DD HH:mm:ss")
              : ""}
          </div>
        </Space>
      );
      return (
        <Tooltip
          title={content}
          overlayClassName="deposit-popover"
          placement="right"
          trigger="click"
          align={{
            offset: [-15, 0],
          }}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
          <div className="bookingList__card__payment unpayment">
            $ {deposit}
          </div>
        </Tooltip>
      );
    }
  };

  return (
    <div className="bookingList__card__row">
      {showCanvas ? renderGroupSeat() : renderSeat()}
      {renderDeposit()}
    </div>
  );
};

const BookingListCardFooter = ({ booking }) => {
  const { CommomSettingState, setStatus } = useContext(CommomSettingContext);
  const { isToday } = CommomSettingState;

  const renderStatusBtn = () => {
    if (isToday) {
      return <ShortCutStatusBtn booking={booking} setStatus={setStatus} />;
    } else if (
      !isToday &&
      (booking.status === "cancel" || booking.status === "finish")
    ) {
      //非當日：取消預約和完成消費才需要出現
      return <ShortCutStatusBtn booking={booking} setStatus={setStatus} />;
    }
  };
  return <Fragment>{renderStatusBtn()}</Fragment>;
};

const BookingStatus = ({ setStatus, booking, isToday, date }) => {
  const { t } = useTranslation("bookingSystem");
  const [currentTime, setCurrentTime] = useState(moment());
  const today = moment().format("YYYY/MM/DD");
  const pre = moment(new Date(booking.booking_datetime))
    .add(-60, "m")
    .format("YYYY/MM/DD HH:mm");
  const tomorrow = moment(new Date(today))
    .add(1, "d")
    .format("YYYY/MM/DD HH:mm");
  const [delay, setDelay] = useState(isToday ? 1000 : null);
  const [bookingStatusBtn, setBookingStatusBtn] = useState(3); //1: 前一天 2:當天時間>預約60分鐘 3:當天時間<=預約60分鐘
  const [showWarning, setShowWarning] = useState(false);
  const [warningTales, setWarningTales] = useState([]);

  const findStatus = useCallback(() => {
    if (currentTime.isAfter(new Date(date), "date")) {
      //過去: 前一天
      //待確認 確認預約 取消預約 完成預約
      //不顯示 finish show
      setBookingStatusBtn(1);
    } else if (currentTime.isBefore(new Date(pre))) {
      //當天時間>預約60分鐘
      //待確認 確認預約 已到店 取消預約
      setBookingStatusBtn(2);
    } else if (
      currentTime.isBetween(new Date(pre), new Date(tomorrow), null, "[)")
    ) {
      //當天時間<=預約60分鐘
      //待確認 確認預約 已到店 取消預約 未到店 已入座 完成消費
      setBookingStatusBtn(3);
      setDelay(null);
    }
  }, [currentTime, date, pre, tomorrow]);

  useEffect(() => {
    if (isToday) {
      setDelay(1000);
    } else {
      setDelay(null);
    }
    findStatus();
  }, [isToday, findStatus]);

  useInterval(() => {
    setCurrentTime(moment());
  }, delay);

  const seatedWarning = useCallback(() => {
    BookingAPI.checkSeated(booking.id).then((data) => {
      if (data.overlay_table.length !== 0) {
        setShowWarning(true);
        setWarningTales(data.overlay_table);
      } else {
        setStatus(booking, "seated");
      }
    });
  }, [booking, setStatus]);

  const cancelWarning = () => {
    setShowWarning(false);
    setWarningTales([]);
  };

  const confirmWarning = () => {
    setStatus(booking, "seated");
    setShowWarning(false);
    setWarningTales([]);
  };

  const renderStatus = () => {
		const excludedStatuses = booking.pos_sync_required
    ? ["unconfirmed", "confirmed", "show", "seated", "no_show"]
    : [];
    const filteredStatus = bookingStatus.filter((b) => {
      if (bookingStatusBtn === 1) {
        return !["show", "no_show", "seated"].includes(b.status);
      }
      return true;
    });

    return (
      <ul
        className="dropdownMenu-booking-status dropdown-menu fix-dropdown-menu-newModule"
        aria-labelledby="status"
      >
        {filteredStatus.map((b) => {
          const isActive = booking.status === b.status;
          const statusClassName = `${b.status + "Status"} ${
            isActive ? "active" : ""
          } btn-effect`;

          const handleClick =
            b.status === "seated"
              ? seatedWarning
              : () => setStatus(booking, b.status);

          if (
            booking.status === "seated" &&
            excludedStatuses.includes(b.status)
          ) {
            return null;
          }

          return (
            <li
              key={b.status}
              className={statusClassName}
              onClick={handleClick}
            >
              <span>{t(`status.${b.status}`)}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <button
        className={`bookingStatus-dropdown color-${booking.status}`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
				<Space size={8}>
					<div className={`bookingStatus-circle bg-${booking.status}`} />
					<span className="color-subtitle">{t(`status.${booking.status}`)}</span>
				</Space>
      </button>
      {renderStatus()}

      {showWarning && (
        <CheckModal
          title={t("popup.overlapping_title")}
          content={t("popup.overlapping_content", {
            tables: warningTales.join("、"),
          })}
          submit={confirmWarning}
          cancel={cancelWarning}
        />
      )}
    </>
  );
};

const ShortCutStatusBtn = ({ booking, setStatus }) => {
  const [currentTime, setCurrentTime] = useState(moment());
  const [delay, setDelay] = useState(1000);
  const today = moment().format("YYYY/MM/DD");
  const pre = moment(new Date(booking.booking_datetime))
    .add(-60, "m")
    .format("YYYY/MM/DD HH:mm");
  const tomorrow = moment(new Date(today))
    .add(1, "d")
    .format("YYYY/MM/DD HH:mm");
  const [hideStatusBtn, setHideStatusBtn] = useState(true);

  useEffect(() => {
    if (currentTime.isBefore(new Date(pre))) {
      //當天時間>預約60分鐘
      setHideStatusBtn(true);
    } else if (
      currentTime.isBetween(new Date(pre), new Date(tomorrow), null, "[)")
    ) {
      //當天時間<=預約60分鐘
      setHideStatusBtn(false);
      setDelay(null);
    }
  }, [currentTime, pre, tomorrow]);

  useInterval(() => {
    setCurrentTime(moment());
  }, delay);

  const renderBtn = useCallback(() => {
    if (!hideStatusBtn) {
      return (
        <div className="bookingList__card__footer">
          <StatusBtn booking={booking} setStatus={setStatus} />
        </div>
      );
    } else {
      return null;
    }
  }, [hideStatusBtn, setStatus, booking]);

  return renderBtn();
};

const StatusBtn = ({ booking, setStatus }) => {
  const { t } = useTranslation("bookingSystem");
  const [showWarning, setShowWarning] = useState(false);
  const [warningTales, setWarningTales] = useState([]);
  const statusTime = JSON.parse(booking.status_time);

  const seatedWarning = useCallback(() => {
    BookingAPI.checkSeated(booking.id).then((data) => {
      if (data.overlay_table.length !== 0) {
        setShowWarning(true);
        setWarningTales(data.overlay_table);
      } else {
        setStatus(booking, "seated");
      }
    });
  }, [booking, setStatus]);

  const cancelWarning = () => {
    setShowWarning(false);
    setWarningTales([]);
  };

  const confirmWarning = () => {
    setStatus(booking, "seated");
    setShowWarning(false);
    setWarningTales([]);
  };

  const renderBtn = () => {
    switch (booking.status) {
      case "confirmed":
        return (
          <React.Fragment>
            <button
              className="newModule__statusBtn newModule__statusBtn-show"
              onClick={() => setStatus(booking, "show")}
            >
              {t("buttonStatus.show")}
            </button>
            <button
              className="newModule__statusBtn newModule__statusBtn-seated"
              onClick={() => seatedWarning()}
            >
              {t("buttonStatus.seated")}
            </button>
          </React.Fragment>
        );
      case "show":
        return (
          <button
            className="newModule__statusBtn newModule__statusBtn-seated"
            onClick={() => seatedWarning()}
          >
            {t("buttonStatus.seated")}
            <span>
              （{t("item.arrivalTime")}: {statusTime["show"]}）
            </span>
          </button>
        );
      case "seated":
        return (
          <button
            className="newModule__statusBtn newModule__statusBtn-finish"
            onClick={() => setStatus(booking, "finish")}
          >
            {t("buttonStatus.finish")}
            <span>
              （{t("item.seatingTime")}: {statusTime["seated"]}）
            </span>
          </button>
        );
      case "finish":
        return (
          <span className="newModule__statusBtn newModule__statusBtn-finishTxt">
            {statusTime["seated"] &&
              `${statusTime["seated"]}${t("buttonStatus.seated")} - `}
            <Space size={2}>
              {statusTime["finish"]}
              {t("item.exitTime")}
            </Space>
          </span>
        );
      case "no_show":
        return (
          <span className="newModule__statusBtn newModule__statusBtn-noShowTxt">
            {statusTime["no_show"]}
            {t("buttonStatus.no_show")}
          </span>
        );
      case "cancel":
        return (
          <span className="newModule__statusBtn newModule__statusBtn-cancelTxt">
            {statusTime["cancel"]}
            {t("status.cancel")}
          </span>
        );
      default:
        return (
          <React.Fragment>
            <button
              className="newModule__statusBtn newModule__statusBtn-cancel"
              onClick={() => setStatus(booking, "cancel")}
            >
              {t("status.cancel")}
            </button>
            <button
              className="newModule__statusBtn newModule__statusBtn-confirmed"
              onClick={() => setStatus(booking, "confirmed")}
            >
              {t("buttonStatus.confirmed")}
            </button>
          </React.Fragment>
        );
    }
  };

  return (
    <React.Fragment>
      {renderBtn()}
      {showWarning && (
        <CheckModal
          title={t("popup.overlapping_title")}
          content={t("popup.overlapping_content", {
            tables: warningTales.join("、"),
          })}
          submit={confirmWarning}
          cancel={cancelWarning}
        />
      )}
    </React.Fragment>
  );
};

BookingListCard.Header = BookingListCardHeader;
BookingListCard.Header.Time = BookingListCardHeaderTime;
BookingListCard.Header.Status = BookingListCardHeaderStatus;

BookingListCard.BookingListCardBody = BookingListCardBody;
BookingListCard.BookingListCardBody.Info = BookingListCardBodyInfo;
BookingListCard.BookingListCardBody.Seats = BookingListCardBodySeats;

BookingListCard.BookingListCardFooter = BookingListCardFooter;

export default BookingListCard;
