import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BookingSystemApi from './BookingSystemApi';
import verifiedIcon from '../../images/icon_verified.svg';
import deleteIcon from '../../images/icon_delete.svg';
import { useTranslation } from 'react-i18next';
import SettingsSaveButton from '../commons/SettingsSaveButton';
import { Switch } from 'antd';

const API = new BookingSystemApi();

const GoogleBusinessInfo = ({
  google_reservation_enabled,
  google_merchant_category,
  address
}) => {
  const { t } = useTranslation('settings');
  return (
    <div>
      <div className='row_title mb-4'>{t('reserveWithGoogleSettings.yourGoogleBusinessProfile')}</div>
      <div className='title_text'>{t('reserveWithGoogleSettings.yourGoogleBusinessProfile_note')}</div>
      <div className='mt-20'>
        <div className='row_subtitle-16 mb-8'>{t('reserveWithGoogleSettings.businessCategory')}</div>
        <input
          className='row_input'
          type="text"
          disabled
          value={google_merchant_category ? google_merchant_category : t('reserveWithGoogleSettings.notDefined')}
        />
      </div>
      {
        google_reservation_enabled &&
        <div className='mt-20'>
          <div className='row_subtitle-16 mb-8'>{t('reserveWithGoogleSettings.addressOnGoogleMaps')}</div>
          <input
            className='row_input mb-2'
            type="text"
            disabled
            value={address}
          />
          <div className='additional_text'>{t('reserveWithGoogleSettings.addressOnGoogleMaps_note')}</div>
        </div>
      }
    </div>
  )
};

const BookingSettingGoogleReservation = () => {
  const { t } = useTranslation('settings');
  const auth = useSelector((state) => state.auth);
  const { shop } = auth;
  const {
    google_reservation_enabled,
    google_merchant_category
  } = shop;

  const [ init, setInit ] = useState(false);
  const [ sending, setSending ] = useState(false);

  const [ address, setAddress ] = useState(''); // 店家地址
  const [ onlineEnabled, setOnlineEnabled ] = useState(false); // 線上預約開放狀態
  const [ maxBookingTimeDay, setMaxBookingTimeDay ] = useState(0); // 最早可預約時間
  const [ areaSettingEnabled, setAreaSettingEnabled ] = useState(false); // 最早可預約時間
  const [ serviceTimeOptionEnabled, setServiceTimeOptionEnabled ] = useState(false); // 服務時間多選選項
  const [ phoneVerification, setPhoneVerification ] = useState(false); // 電話認證
  const [ depositRequired, setDepositRequired ] = useState(false); // 預先收款功能
  const [ verifyRequired, setVerifyRequired ] = useState(false); // 審核預約

  const [ isGoogleReservationEnabled, setIsGoogleReservationEnabled ] = useState(false);
  const [ googleReservation, setGoogleReservation ] = useState(false);

  useEffect(() => {
    const getBookingSetting = async () => {
      try {
        const data = await API.getBookingSetting();
        const {
          shop, 
          enable,
          max_booking_time_day,
          space_group_selection,
          service_time_option_enabled,
          phone_verification,
          deposit_required,
          verify_required,
          google_reservation
        } = data;
        const { address } = shop;
  
        if(
          address &&
          enable &&
          max_booking_time_day >= 30 &&
          !space_group_selection &&
          !service_time_option_enabled &&
          !phone_verification &&
          !deposit_required &&
          !verify_required
        ) {
          setIsGoogleReservationEnabled(true)
        }
  
        setAddress(address);
        setOnlineEnabled(enable);
        setMaxBookingTimeDay(max_booking_time_day);
        setAreaSettingEnabled(space_group_selection);
        setServiceTimeOptionEnabled(service_time_option_enabled);
        setPhoneVerification(phone_verification);
        setDepositRequired(deposit_required);
        setVerifyRequired(verify_required);
        setGoogleReservation(google_reservation);
  
        setInit(true);
      } catch (err) {
        console.log('google reservation getBookingSetting err---', err);
        window.app.alert.setMessage(t('status.pls_tryAgainLater'), 'error');
      }
    };

		getBookingSetting();
	}, [t]);


  const onChange = () => {
    setGoogleReservation(prev => !prev);
  }

  const submit = async() => {
    setSending(true);

    try {
      const googleReservationSetting = JSON.stringify({
        booking_settings: {
          google_reservation: googleReservation
        }
      });

      await API.updateBookingSetting(googleReservationSetting);
      window.app.alert.setMessage(t('status.saved'), 'done');
      setSending(false);
    } catch (err) {
      console.log('google reservation update err---', err);
			window.app.alert.setMessage(t('status.pls_tryAgainLater'), 'error');
      setSending(false);
    }
  }

  const renderEnabled = () => {
    return (
      <>
        <div className="settingTbl">
          <div className="settingTblRow fix-settingTblRow">
            <div className="header fix-header">
              {t("reserveWithGoogleSettings.reserveWithGoogleEnabled")}
            </div>
            <div className="text fix-text">
              <Switch
                onChange={onChange}
                checked={googleReservation}
                disabled={!isGoogleReservationEnabled}
              />
            </div>
          </div>
        </div>

        <GoogleBusinessInfo
          google_reservation_enabled={google_reservation_enabled}
          google_merchant_category={google_merchant_category}
          address={address}
        />

        <hr className="hr-32" />

        <div className="row_subtitle-18 mb-4">
          {t("reserveWithGoogleSettings.prerequisite")}
        </div>
        <div className="title_text">
          {t("reserveWithGoogleSettings.prerequisite_note")}
        </div>
        {!isGoogleReservationEnabled && (
          <div className="alert_text error_text mt-4">
            {t("reserveWithGoogleSettings.notMeetAllOfTheConditions")}
          </div>
        )}

        <div className="block_googleRequired mt-20 mb-32">
          <div className={address ? "success_text" : "error_text"}>
            {address ? (
              <img src={verifiedIcon} alt="" />
            ) : (
              <img src={deleteIcon} alt="" />
            )}
            {t("reserveWithGoogleSettings.addressCannotBeBlank")}
          </div>
          <div className={onlineEnabled ? "success_text" : "error_text"}>
            {onlineEnabled ? (
              <img src={verifiedIcon} alt="" />
            ) : (
              <img src={deleteIcon} alt="" />
            )}
            {t("reserveWithGoogleSettings.enableOnlineBookingEnabled")}
          </div>
          <div
            className={maxBookingTimeDay >= 30 ? "success_text" : "error_text"}
          >
            {maxBookingTimeDay >= 30 ? (
              <img src={verifiedIcon} alt="" />
            ) : (
              <img src={deleteIcon} alt="" />
            )}
            {t("reserveWithGoogleSettings.maximumBookingHorizonGreater")}
          </div>
          <div className={!areaSettingEnabled ? "success_text" : "error_text"}>
            {!areaSettingEnabled ? (
              <img src={verifiedIcon} alt="" />
            ) : (
              <img src={deleteIcon} alt="" />
            )}
            {t("reserveWithGoogleSettings.enableServiceOptionsDisabled")}
          </div>
          <div
            className={
              !serviceTimeOptionEnabled ? "success_text" : "error_text"
            }
          >
            {!serviceTimeOptionEnabled ? (
              <img src={verifiedIcon} alt="" />
            ) : (
              <img src={deleteIcon} alt="" />
            )}
            {t(
              "reserveWithGoogleSettings.enableMultipleServiceDurationOptionsDisabled"
            )}
          </div>
          <div className={!phoneVerification ? "success_text" : "error_text"}>
            {!phoneVerification ? (
              <img src={verifiedIcon} alt="" />
            ) : (
              <img src={deleteIcon} alt="" />
            )}
            {t("reserveWithGoogleSettings.enablePhoneVerificationDisabled")}
          </div>
          <div className={!depositRequired ? "success_text" : "error_text"}>
            {!depositRequired ? (
              <img src={verifiedIcon} alt="" />
            ) : (
              <img src={deleteIcon} alt="" />
            )}
            {t("reserveWithGoogleSettings.enableAdvancedPaymentDisabled")}
          </div>
          <div className={!verifyRequired ? "success_text" : "error_text"}>
            {!verifyRequired ? (
              <img src={verifiedIcon} alt="" />
            ) : (
              <img src={deleteIcon} alt="" />
            )}
            {t("reserveWithGoogleSettings.requireBookingApprovalDisabled")}
          </div>
        </div>
        <div className="block_notice">
          <div className="title">
            {t("reserveWithGoogleSettings.otherInstructions")}
          </div>
          <ul>
            <li>{t("reserveWithGoogleSettings.otherInstructions_notice_1")}</li>
            <li>{t("reserveWithGoogleSettings.otherInstructions_notice_2")}</li>
            <li>{t("reserveWithGoogleSettings.otherInstructions_notice_3")}</li>
          </ul>
        </div>
        <hr className="hr-32 md-hidden" />
      </>
    );
  };

  const renderDisabled = () => {
    return (
      <div>
        <h4 style={{ margin: '80px 0 36px' }}>{t('reserveWithGoogleSettings.notAvailable')}</h4>
        <GoogleBusinessInfo
          google_reservation_enabled={google_reservation_enabled}
          google_merchant_category={google_merchant_category}
        />
      </div>
    )
  };

  if (!init) return <div className="pageLoading">{t('status.loading')}</div>;
  return (
    <>
      <div className='setting-container bookingSettingGoogleReservation'>
        {sending && (
          <div className="lightBoxLayer">
            <h4>{t('status.saving')}</h4>
          </div>
        )}
        <h3>{t('reserveWithGoogleSettings.title')}</h3>
        <h5>{t('reserveWithGoogleSettings.description')}</h5>
  
        <hr />
  
        {google_reservation_enabled ? renderEnabled() : renderDisabled()}
      </div>
  
      {google_reservation_enabled && (
        <SettingsSaveButton handleSaved={submit} />
      )}
    </>
  )
}

export default BookingSettingGoogleReservation