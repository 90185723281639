import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { CustomerContext } from "../crmContext/CustomerContext";

import Customer from "./CrmCustomer";

const CrmCustomerList = () => {
  const { t } = useTranslation("customer");
  const { CustomerState } = useContext(CustomerContext);
  const {
    searchCustomerMode,
    pageCustomers,
    searchCustomers,
    filterCustomerMode,
    filterCustomers,
  } = CustomerState;

  const [showCustomers, setShowCustomers] = useState([]);

  useEffect(() => {
    if (searchCustomerMode) {
      setShowCustomers(searchCustomers);
    } else if (filterCustomerMode) {
      setShowCustomers(filterCustomers);
    } else {
      setShowCustomers(pageCustomers);
    }
  }, [
    searchCustomerMode,
    filterCustomerMode,
    pageCustomers,
    searchCustomers,
    filterCustomers,
  ]);

  const renderList = () => {
    if (showCustomers.length > 0) {
      return showCustomers.map((customer) => {
        return <Customer key={customer.id} data={customer} />;
      });
    } else {
      if (filterCustomerMode)
        return <div className="customer_empty">{t("list.filterEmpty")}</div>;
      if (searchCustomerMode)
        return <div className="customer_empty">{t("list.searchEmpty")}</div>;

      return <div className="customer_empty">{t("list.default")}</div>;
    }
  };

  return <div className="crmCustomerList">{renderList()}</div>;
};

export default CrmCustomerList;
