import React, { useState, useContext, useRef } from "react";
import moment from "moment";
import Datetime from "react-datetime";
import _ from "lodash";
import { Popover, DatePicker, Select, Space } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { CustomerContext } from "../crmContext/CustomerContext";
import { filterCustomers } from "../api/CrmCustomerApi";
import { DIGITS_ONLY_REGEX } from "../../../utils/regex";

const { RangePicker } = DatePicker;

// tags fake data
const options = [];
for (let i = 10; i < 50; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

const templateCustomerFilter = {
  bookingRecord: {
    bookingDate: {
      // 紀錄時間
      fixed: "",
      startDate: "",
      endDate: "",
    },
    bookingCount: {
      // 預約次數
      min: "",
      max: "",
    },
    validCount: {
      // 有效預約次數
      min: "",
      max: "",
    },
    cancelCount: {
      // 取消預約次數
      min: "",
      max: "",
    },
    noShowCount: {
      // 未到店次數
      min: "",
      max: "",
    },
  },
  queueRecord: {
    queueDate: {
      fixed: "",
      startDate: "",
      endDate: "",
    },
    queueCount: {
      // 排隊次數
      min: "",
      max: "",
    },
    finishCount: {
      // 報到次數
      min: "",
      max: "",
    },
  },
  customerInfo: {
    gender: [], // 顧客性別: male, female, other
    birthday: {
      // 顧客生日
      year: "",
      month: "",
      date: "",
    },
    tags: [], // 顧客標記
    type: [], // 顧客身份: verified, blocked
  },
};
const templateVisible = {
  bookingRecord: false,
  queueRecord: false,
  customerInfo: false,
};
const templatePopoverError = {
  bookingDate: false,
  bookingCount: false,
  validCount: false,
  cancelCount: false,
  noShowCount: false,
  queueCount: false,
  finishCount: false,
};
const templatePickerVisible = {
  month: false,
  date: false,
};
const month = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

const CrmCustomerFilterBar = () => {
  const { t, i18n } = useTranslation("customer");
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const { customerFilter, customerFilterNum } = CustomerState;

  const [displayCustomerFilter, setDisplayCustomerFilter] = useState(
    templateCustomerFilter
  );
  // popover
  const [visible, setVisible] = useState(templateVisible);
  const [pickerVisible, setPickerVisible] = useState(templatePickerVisible);
  const [popoverError, setPopoverError] = useState(templatePopoverError);

  const yearRef = useRef(null);

  const isLeapYear = (value) => {
    let year = parseInt(value, 10);
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  };

  // 關閉所有 Popover
  const hide = () => {
    setVisible(templateVisible);
    datePickerHide();
  };

  // 開啟特定 Popover
  const handleOpenChange = (filter) => {
    let newVisible = _.cloneDeep(visible);
    let newFilter = _.cloneDeep(displayCustomerFilter);

    if (newVisible[filter]) {
      newVisible[filter] = false;
    } else {
      Object.keys(newVisible).forEach((i) => {
        newVisible[i] = false;
      });

      newVisible[filter] = true;
    }

    setVisible(newVisible);
    setPickerVisible(templatePickerVisible);

    // recover
    newFilter[filter] = customerFilter[filter];
    setDisplayCustomerFilter(newFilter);
  };

  const findActivePopover = () => {
    let activePopover = null;

    Object.keys(visible).forEach((i) => {
      if (visible[i]) {
        activePopover = i;
      }
    });

    return activePopover;
  };

  // 關閉 birthday Popover
  const datePickerHide = () => {
    setPickerVisible(templatePickerVisible);
  };

  // 開啟 birthday Popover
  const handleDatePickerOpen = (e, filter) => {
    let newPickerVisible = _.cloneDeep(pickerVisible);

    e.stopPropagation();
    Object.keys(newPickerVisible).forEach((i) => (newPickerVisible[i] = false));
    newPickerVisible[filter] = true;

    setPickerVisible(newPickerVisible);
  };

  const handleBookingDatePicker = (value, dateString) => {
    let newFilter = _.cloneDeep(displayCustomerFilter);

    newFilter.bookingRecord["bookingDate"]["fixed"] = "";
    newFilter.bookingRecord["bookingDate"]["startDate"] = dateString[0];
    newFilter.bookingRecord["bookingDate"]["endDate"] = dateString[1];

    setDisplayCustomerFilter(newFilter);
  };

  const handleQueueDatePicker = (value, dateString) => {
    let newFilter = _.cloneDeep(displayCustomerFilter);

    newFilter.queueRecord["queueDate"]["fixed"] = "";
    newFilter.queueRecord["queueDate"]["startDate"] = dateString[0];
    newFilter.queueRecord["queueDate"]["endDate"] = dateString[1];

    setDisplayCustomerFilter(newFilter);
  };

  const handleYearPicker = (value) => {
    let newFilter = _.cloneDeep(displayCustomerFilter);
    let SelectYear = new Date(value).getFullYear();
    let leapYear = isLeapYear(SelectYear);

    newFilter.customerInfo["birthday"]["year"] = SelectYear;

    // 不是閏年 && 選擇 2月29日
    if (
      !leapYear &&
      displayCustomerFilter.customerInfo.birthday.month === "2" &&
      displayCustomerFilter.customerInfo.birthday.date === "29"
    ) {
      newFilter.customerInfo["birthday"]["date"] = "";
    }

    setDisplayCustomerFilter(newFilter);
  };

  const handleTagsChange = (e) => {
    let newFilter = _.cloneDeep(displayCustomerFilter);

    newFilter.customerInfo["tags"] = e;

    setDisplayCustomerFilter(newFilter);
  };

  const handleKeyPress = (event) => {
    if (!DIGITS_ONLY_REGEX.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleFilterChange = (e, category) => {
    const name = e.target.name;
    const value = e.target.value;
    const reg = /^[0-9]*$/;
    const regTest = reg.test(value);
    let newFilter = _.cloneDeep(displayCustomerFilter);

    // bookingRecord
    if (name === "bookingDate") {
      if (category === "fixed") {
        newFilter.bookingRecord["bookingDate"]["fixed"] =
          value === displayCustomerFilter.bookingRecord["bookingDate"]["fixed"]
            ? ""
            : value;
        newFilter.bookingRecord["bookingDate"]["startDate"] = "";
        newFilter.bookingRecord["bookingDate"]["endDate"] = "";
      }

      if (category === "custom") {
        newFilter.bookingRecord["bookingDate"]["fixed"] = "";
        newFilter.bookingRecord["bookingDate"]["startDate"] = value[0];
        newFilter.bookingRecord["bookingDate"]["endDate"] = value[1];
      }
    }

    if (
      name === "bookingCount" ||
      name === "validCount" ||
      name === "cancelCount" ||
      name === "noShowCount"
    ) {
      if (!regTest) return;
      newFilter.bookingRecord[name][category] = value;
    }

    // queueRecord
    if (name === "queueDate") {
      if (category === "fixed") {
        newFilter.queueRecord["queueDate"]["fixed"] =
          value === displayCustomerFilter.queueRecord["queueDate"]["fixed"]
            ? ""
            : value;
        newFilter.queueRecord["queueDate"]["startDate"] = "";
        newFilter.queueRecord["queueDate"]["endDate"] = "";
      }

      if (category === "custom") {
        newFilter.queueRecord["queueDate"]["fixed"] = "";
        newFilter.queueRecord["queueDate"]["startDate"] = value[0];
        newFilter.queueRecord["queueDate"]["endDate"] = value[1];
      }
    }

    if (name === "queueCount" || name === "finishCount") {
      if (!regTest) return;
      newFilter.queueRecord[name][category] = value;
    }

    if (name === "birthday") {
      newFilter.customerInfo[name][category] = value;

      // 小月選了 31號
      if (
        displayCustomerFilter.customerInfo.birthday.date === "31" &&
        (value === "4" || value === "6" || value === "9" || value === "11")
      ) {
        newFilter.customerInfo[name]["date"] = "";
      }

      // 2月選了 30, 31號
      if (
        value === "2" &&
        (displayCustomerFilter.customerInfo.birthday.date === "30" ||
          displayCustomerFilter.customerInfo.birthday.date === "31")
      ) {
        newFilter.customerInfo[name]["date"] = "";
      }
    }

    if (name === "type" || name === "gender") {
      const idx = newFilter["customerInfo"][name].indexOf(value);

      if (idx !== -1) {
        newFilter["customerInfo"][name].splice(idx, 1);
      } else {
        newFilter["customerInfo"][name].push(value);
      }
    }

    setDisplayCustomerFilter(newFilter);
    datePickerHide();
  };

  const submitFilterCustomers = async (newFilter) => {
    const filterState = newFilter ? newFilter : displayCustomerFilter;
    const { bookingRecord, queueRecord } = filterState;
    const { bookingDate, bookingCount, validCount, cancelCount, noShowCount } =
      bookingRecord;
    const { queueDate, queueCount, finishCount } = queueRecord;
    let params = {};

    if (
      _.isEqual(displayCustomerFilter, templateCustomerFilter) ||
      _.isEqual(newFilter, templateCustomerFilter)
    ) {
      return CustomerStateDispatch({ type: "resetCustomerFilter" });
    }

    const initBookingFilter = () => {
      if (!params.booking_filter) params.booking_filter = {};
    };
    const initBookingCountsWithStatus = () => {
      if (!params.booking_filter["counts_with_status"])
        params.booking_filter["counts_with_status"] = {};
    };
    const initQueueFilter = () => {
      if (!params.queue_filter) params.queue_filter = {};
    };
    const initQueueCountsWithStatus = () => {
      if (!params.queue_filter["counts_with_status"])
        params.queue_filter["counts_with_status"] = {};
    };

    if (bookingCount.min || bookingCount.max) {
      initBookingFilter();
      params.booking_filter["counts"] = {};
      if (bookingCount.min)
        params.booking_filter["counts"].min = Number(bookingCount.min);
      if (bookingCount.max)
        params.booking_filter["counts"].max = Number(bookingCount.max);
    }

    if (validCount.min || validCount.max) {
      initBookingFilter();
      params.booking_filter["effective_bookings_counts"] = {};
      if (validCount.min)
        params.booking_filter["effective_bookings_counts"].min = Number(
          validCount.min
        );
      if (validCount.max)
        params.booking_filter["effective_bookings_counts"].max = Number(
          validCount.max
        );
    }

    if (bookingDate.fixed || (bookingDate.startDate && bookingDate.endDate)) {
      initBookingFilter();
      let start_date, end_date;
      params.booking_filter["booking_date"] = {};

      if (bookingDate.fixed) {
        end_date = new Date();
        start_date = new Date();
        start_date.setDate(end_date.getDate() - Number(bookingDate.fixed));
      } else {
        start_date = bookingDate.startDate;
        end_date = bookingDate.endDate;
      }

      params.booking_filter["booking_date"].start_date =
        moment(start_date).format("YYYY-MM-DD");
      params.booking_filter["booking_date"].end_date =
        moment(end_date).format("YYYY-MM-DD");
    }

    if (cancelCount.min || cancelCount.max) {
      initBookingFilter();
      initBookingCountsWithStatus();
      params.booking_filter["counts_with_status"].cancel = {};
      if (cancelCount.min)
        params.booking_filter["counts_with_status"].cancel.min = Number(
          cancelCount.min
        );
      if (cancelCount.max)
        params.booking_filter["counts_with_status"].cancel.max = Number(
          cancelCount.max
        );
    }

    if (noShowCount.min || noShowCount.max) {
      initBookingFilter();
      initBookingCountsWithStatus();
      params.booking_filter["counts_with_status"].no_show = {};
      if (noShowCount.min)
        params.booking_filter["counts_with_status"].no_show.min = Number(
          noShowCount.min
        );
      if (noShowCount.max)
        params.booking_filter["counts_with_status"].no_show.max = Number(
          noShowCount.max
        );
    }

    // 排隊篩選
    // 排隊日期
    if (queueDate.fixed || (queueDate.startDate && queueDate.endDate)) {
      initQueueFilter();
      let start_date, end_date;
      params.queue_filter["created_date"] = {};

      if (queueDate.fixed) {
        end_date = new Date();
        start_date = new Date();
        start_date.setDate(end_date.getDate() - Number(queueDate.fixed));
      } else {
        start_date = queueDate.startDate;
        end_date = queueDate.endDate;
      }

      params.queue_filter["created_date"].start_date =
        moment(start_date).format("YYYY-MM-DD");
      params.queue_filter["created_date"].end_date =
        moment(end_date).format("YYYY-MM-DD");
    }

    if (queueCount.min || queueCount.max) {
      initQueueFilter();
      params.queue_filter["counts"] = {};
      if (queueCount.min)
        params.queue_filter["counts"].min = Number(queueCount.min);
      if (queueCount.max)
        params.queue_filter["counts"].max = Number(queueCount.max);
    }

    if (finishCount.min || finishCount.max) {
      initQueueFilter();
      initQueueCountsWithStatus();
      params.queue_filter["counts_with_status"].seated = {};
      if (finishCount.min)
        params.queue_filter["counts_with_status"].seated.min = Number(
          finishCount.min
        );
      if (finishCount.max)
        params.queue_filter["counts_with_status"].seated.max = Number(
          finishCount.max
        );
    }

    try {
      CustomerStateDispatch({ type: "setLoading", loading: true });
      params.page = 1;
      params.per = 10;
      const { data } = await filterCustomers(params);
      CustomerStateDispatch({
        type: "setFilterCustomerResult",
        filterCustomers: data.customers,
        filterCustomerMode: true,
        listPage: 1,
        listCount: data.meta.size,
      });
      CustomerStateDispatch({
        type: "setFilterCustomersParams",
        filterCustomersParams: params,
      });
      CustomerStateDispatch({ type: "setLoading", loading: false });
    } catch (err) {
      console.log("crm filter customer error ----------", err);
    }
  };

  const submitFilter = (action) => {
    let newFilter = _.cloneDeep(displayCustomerFilter);
    let newPopoverError = _.cloneDeep(popoverError);
    let activePopover = findActivePopover();

    if (action === "saved") {
      // 最多次數需大於最少次數
      const checkValueValidity = (type, filter) => {
        const minValue = displayCustomerFilter[type][filter]["min"]
          ? Number(displayCustomerFilter[type][filter]["min"])
          : undefined;
        const maxValue = displayCustomerFilter[type][filter]["max"]
          ? Number(displayCustomerFilter[type][filter]["max"])
          : undefined;

        if (!isNaN(minValue) && !isNaN(maxValue) && minValue > maxValue) {
          newPopoverError[filter] = true;
        } else {
          newPopoverError[filter] = false;
        }

        setPopoverError(newPopoverError);
      };

      if (activePopover === "bookingRecord") {
        checkValueValidity("bookingRecord", "bookingCount");
        checkValueValidity("bookingRecord", "validCount");
        checkValueValidity("bookingRecord", "cancelCount");
        checkValueValidity("bookingRecord", "noShowCount");
      }

      if (activePopover === "queueRecord") {
        checkValueValidity("queueRecord", "queueCount");
        checkValueValidity("queueRecord", "finishCount");
      }

      if (!_.isEqual(newPopoverError, templatePopoverError)) return; // 有錯誤就返回

      if (
        _.isEqual(
          customerFilter[activePopover],
          templateCustomerFilter[activePopover]
        ) &&
        !_.isEqual(
          customerFilter[activePopover],
          displayCustomerFilter[activePopover]
        )
      ) {
        CustomerStateDispatch({
          type: "setCustomerFilterNum",
          customerFilterNum: customerFilterNum + 1,
        });
      }

      // 清空套用
      if (
        _.isEqual(
          displayCustomerFilter[activePopover],
          templateCustomerFilter[activePopover]
        ) &&
        !_.isEqual(
          customerFilter[activePopover],
          displayCustomerFilter[activePopover]
        )
      ) {
        CustomerStateDispatch({
          type: "setCustomerFilterNum",
          customerFilterNum: customerFilterNum - 1,
        });
      }

      CustomerStateDispatch({
        type: "setCustomerFilter",
        name: activePopover,
        value: displayCustomerFilter[activePopover],
      });
      submitFilterCustomers();
    }

    if (action === "clear") {
      newFilter[activePopover] = customerFilter[activePopover];
      setDisplayCustomerFilter(newFilter);
      setPopoverError(templatePopoverError);
    }

    hide();
  };

  const clearFilter = () => {
    let newFilter = _.cloneDeep(displayCustomerFilter);
    let activePopover = findActivePopover();

    newFilter[activePopover] = templateCustomerFilter[activePopover];
    setDisplayCustomerFilter(newFilter);

    if (activePopover === "customerInfo") yearRef.current.state.inputValue = "";
  };

  const cancelFilter = (e, filter) => {
    let newFilter = _.cloneDeep(displayCustomerFilter);
    e.stopPropagation();

    newFilter[filter] = templateCustomerFilter[filter];

    setDisplayCustomerFilter(newFilter);
    submitFilterCustomers(newFilter);
    CustomerStateDispatch({
      type: "setCustomerFilter",
      name: filter,
      value: templateCustomerFilter[filter],
    });
    CustomerStateDispatch({
      type: "setCustomerFilterNum",
      customerFilterNum: customerFilterNum - 1,
    });
  };

  const renderContent = (filter) => {
    const { bookingRecord, queueRecord, customerInfo } = displayCustomerFilter;
    let content = null;
    let selectMonth = null;
    let selectDate = null;
    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth() + 1;
    let currentDate = new Date().getDate();

    let dates = [];
    let dateMaxLength = 31;

    if (displayCustomerFilter.customerInfo.birthday.month === "2") {
      // 如果當前月份是2月
      dateMaxLength = isLeapYear(
        displayCustomerFilter.customerInfo.birthday.year
      )
        ? 29
        : 28; // 根據閏年判斷二月天數
    } else if (
      // 如果當前月份是4,6,9,11月
      displayCustomerFilter.customerInfo.birthday.month === "4" ||
      displayCustomerFilter.customerInfo.birthday.month === "6" ||
      displayCustomerFilter.customerInfo.birthday.month === "9" ||
      displayCustomerFilter.customerInfo.birthday.month === "11"
    ) {
      dateMaxLength = 30;
    } else {
      dateMaxLength = 31;
    }

    for (let i = 1; i <= dateMaxLength; i++) {
      dates.push(i);
    }

    selectMonth = (
      <div className="birth_select month">
        {month.map((m) => (
          <button
            name="birthday"
            className={`${
              m === displayCustomerFilter.customerInfo.birthday.month
                ? "active"
                : ""
            } ${currentMonth.toString() === m && "current"}`}
            key={m}
            value={m}
            onClick={(m) => handleFilterChange(m, "month")}
          >
            {t(`time:numericMonths.${m}`)}
          </button>
        ))}
      </div>
    );

    selectDate = (
      <div className="birth_select date">
        {dates.map((d) => (
          <button
            name="birthday"
            className={`${
              d.toString() === displayCustomerFilter.customerInfo.birthday.date
                ? "active"
                : ""
            } ${currentDate === d && "current"}`}
            key={d}
            value={d}
            onClick={(e) => handleFilterChange(e, "date")}
          >
            {d}
          </button>
        ))}
      </div>
    );

    const renderYear = (props, year, selectedDate) => {
      if (year === currentYear) {
        return (
          <td {...props}>
            <div className="current">{year}</div>
          </td>
        );
      } else {
        return <td {...props}>{year}</td>;
      }
    };

    if (filter === "bookingRecord") {
      content = (
        <div className="popover_content">
          <div className="subtitle">{t("filterBar.bookingRecord.date")}</div>
          <div className="rangePicker_content">
            <Space size={8} wrap>
              <button
                name="bookingDate"
                value={7}
                className={`${
                  bookingRecord.bookingDate.fixed === "7" ? "active" : ""
                }`}
                onClick={(e) => handleFilterChange(e, "fixed")}
              >
                {t("filterBar.last_7")}
              </button>
              <button
                name="bookingDate"
                value={14}
                className={`${
                  bookingRecord.bookingDate.fixed === "14" ? "active" : ""
                }`}
                onClick={(e) => handleFilterChange(e, "fixed")}
              >
                {t("filterBar.last_14")}
              </button>
              <button
                name="bookingDate"
                value={30}
                className={`${
                  bookingRecord.bookingDate.fixed === "30" ? "active" : ""
                }`}
                onClick={(e) => handleFilterChange(e, "fixed")}
              >
                {t("filterBar.last_30")}
              </button>
            </Space>
            <div className="option">{t("filterBar.custom")}</div>
            <RangePicker
              className="rangePicker"
              format="YYYY-MM-DD"
              value={
                bookingRecord.bookingDate.startDate &&
                bookingRecord.bookingDate.endDate
                  ? [
                      moment(bookingRecord.bookingDate.startDate),
                      moment(bookingRecord.bookingDate.endDate),
                    ]
                  : null
              }
              placeholder={[
                t("time:datePicker.start"),
                t("time:datePicker.end"),
              ]}
              placement="bottomLeft"
              onChange={handleBookingDatePicker}
              inputReadOnly
            />
          </div>
          <div className="subtitle">{t("filterBar.bookingRecord.count")}</div>
          <div
            className={`${popoverError.bookingCount ? "popover_error" : ""}`}
          >
            <div className="count_content" data-lang={i18n.language}>
              <div>
                <input
                  name="bookingCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.min")}
                  value={bookingRecord.bookingCount.min}
                  onChange={(e) => handleFilterChange(e, "min")}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <span>{t("filterBar.to")}</span>
              <div>
                <input
                  name="bookingCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.max")}
                  value={bookingRecord.bookingCount.max}
                  onChange={(e) => handleFilterChange(e, "max")}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            {popoverError.bookingCount && (
              <div className="text_error">
                {t("filterBar.pls_maxMustBeGreaterThanMin")}
              </div>
            )}
          </div>
          <div className="subtitle">
            {t("filterBar.bookingRecord.validCount")}
          </div>
          <div className={`${popoverError.validCount ? "popover_error" : ""}`}>
            <div className="count_content" data-lang={i18n.language}>
              <div>
                <input
                  name="validCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.min")}
                  value={bookingRecord.validCount.min}
                  onChange={(e) => handleFilterChange(e, "min")}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <span>{t("filterBar.to")}</span>
              <div>
                <input
                  name="validCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.max")}
                  value={bookingRecord.validCount.max}
                  onChange={(e) => handleFilterChange(e, "max")}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            {popoverError.validCount && (
              <div className="text_error">
                {t("filterBar.pls_maxMustBeGreaterThanMin")}
              </div>
            )}
          </div>
          <div className="subtitle">
            {t("filterBar.bookingRecord.canceledCount")}
          </div>
          <div className={`${popoverError.cancelCount ? "popover_error" : ""}`}>
            <div className="count_content" data-lang={i18n.language}>
              <div>
                <input
                  name="cancelCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.min")}
                  value={bookingRecord.cancelCount.min}
                  onChange={(e) => handleFilterChange(e, "min")}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <span>{t("filterBar.to")}</span>
              <div>
                <input
                  name="cancelCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.max")}
                  value={bookingRecord.cancelCount.max}
                  onChange={(e) => handleFilterChange(e, "max")}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            {popoverError.cancelCount && (
              <div className="text_error">
                {t("filterBar.pls_maxMustBeGreaterThanMin")}
              </div>
            )}
          </div>
          <div className="subtitle">
            {t("filterBar.bookingRecord.noShowCount")}
          </div>
          <div className={`${popoverError.noShowCount ? "popover_error" : ""}`}>
            <div className="count_content" data-lang={i18n.language}>
              <div>
                <input
                  name="noShowCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.min")}
                  value={bookingRecord.noShowCount.min}
                  onChange={(e) => handleFilterChange(e, "min")}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <span>{t("filterBar.to")}</span>
              <div>
                <input
                  name="noShowCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.max")}
                  value={bookingRecord.noShowCount.max}
                  onChange={(e) => handleFilterChange(e, "max")}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            {popoverError.noShowCount && (
              <div className="text_error">
                {t("filterBar.pls_maxMustBeGreaterThanMin")}
              </div>
            )}
          </div>
        </div>
      );
    }

    if (filter === "queueRecord") {
      content = (
        <div className="popover_content">
          <div className="subtitle">{t("filterBar.queueRecord.date")}</div>
          <div className="rangePicker_content">
            <Space size={8} wrap>
              <button
                name="queueDate"
                value={7}
                className={`${
                  queueRecord.queueDate.fixed === "7" ? "active" : ""
                }`}
                onClick={(e) => handleFilterChange(e, "fixed")}
              >
                {t("filterBar.last_7")}
              </button>
              <button
                name="queueDate"
                value={14}
                className={`${
                  queueRecord.queueDate.fixed === "14" ? "active" : ""
                }`}
                onClick={(e) => handleFilterChange(e, "fixed")}
              >
                {t("filterBar.last_14")}
              </button>
              <button
                name="queueDate"
                value={30}
                className={`${
                  queueRecord.queueDate.fixed === "30" ? "active" : ""
                }`}
                onClick={(e) => handleFilterChange(e, "fixed")}
              >
                {t("filterBar.last_30")}
              </button>
            </Space>
            <div className="option">{t("filterBar.custom")}</div>
            <RangePicker
              className="rangePicker"
              format="YYYY-MM-DD"
              value={
                queueRecord.queueDate.startDate && queueRecord.queueDate.endDate
                  ? [
                      moment(queueRecord.queueDate.startDate),
                      moment(queueRecord.queueDate.endDate),
                    ]
                  : null
              }
              placeholder={[
                t("time:datePicker.start"),
                t("time:datePicker.end"),
              ]}
              placement="bottomLeft"
              onChange={handleQueueDatePicker}
              inputReadOnly
            />
          </div>
          <div className="subtitle">{t("filterBar.queueRecord.count")}</div>
          <div className={`${popoverError.queueCount ? "popover_error" : ""}`}>
            <div className="count_content" data-lang={i18n.language}>
              <div>
                <input
                  name="queueCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.min")}
                  value={queueRecord.queueCount.min}
                  onChange={(e) => handleFilterChange(e, "min")}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <span>{t("filterBar.to")}</span>
              <div>
                <input
                  name="queueCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.max")}
                  value={queueRecord.queueCount.max}
                  onChange={(e) => handleFilterChange(e, "max")}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            {popoverError.queueCount && (
              <div className="text_error">
                {t("filterBar.pls_maxMustBeGreaterThanMin")}
              </div>
            )}
          </div>
          <div className="subtitle">{t("filterBar.queueRecord.checkedIn")}</div>
          <div className={`${popoverError.finishCount ? "popover_error" : ""}`}>
            <div className="count_content" data-lang={i18n.language}>
              <div>
                <input
                  name="finishCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.min")}
                  value={queueRecord.finishCount.min}
                  onChange={(e) => handleFilterChange(e, "min")}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <span>{t("filterBar.to")}</span>
              <div>
                <input
                  name="finishCount"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("filterBar.max")}
                  value={queueRecord.finishCount.max}
                  onChange={(e) => handleFilterChange(e, "max")}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            {popoverError.finishCount && (
              <div className="text_error">
                {t("filterBar.pls_maxMustBeGreaterThanMin")}
              </div>
            )}
          </div>
        </div>
      );
    }

    if (filter === "customerInfo") {
      content = (
        <div className="popover_content" onClick={datePickerHide}>
          <div className="subtitle">
            {t("filterBar.customerProfile.gender")}
          </div>
          <div className="gender_content">
            <button
              name="gender"
              className={`${
                customerInfo.gender.includes("male") ? "active" : ""
              }`}
              value="male"
              onClick={(e) => handleFilterChange(e)}
            >
              男性
            </button>
            <button
              name="gender"
              className={`${
                customerInfo.gender.includes("female") ? "active" : ""
              }`}
              value="female"
              onClick={(e) => handleFilterChange(e)}
            >
              女性
            </button>
            <button
              name="gender"
              className={`${
                customerInfo.gender.includes("other") ? "active" : ""
              }`}
              value="other"
              onClick={(e) => handleFilterChange(e)}
            >
              其他
            </button>
          </div>
          <div className="subtitle">生日</div>
          <div className="birthday_content">
            <div className="birth_box">
              <div className="option">出生年份</div>
              <div className="crmCustomerFilter_birth birth_year">
                <Datetime
                  className="year_picker"
                  inputProps={{
                    readOnly: true,
                    placeholder: "年",
                  }}
                  ref={yearRef}
                  dateFormat="YYYY"
                  timeFormat={false}
                  renderYear={renderYear}
                  value={
                    customerInfo.birthday.year
                      ? moment(customerInfo.birthday.year, "YYYY")
                      : ""
                  }
                  closeOnSelect
                  onChange={(value) => handleYearPicker(value)}
                />
                <CalendarOutlined
                  className="icon_calendar"
                  style={{ color: "#c8c8c8" }}
                />
              </div>
            </div>
            <div className="birth_box">
              <div className="option">出生月份</div>
              <Popover
                overlayClassName="crmCustomerFilterBirth_popover"
                content={selectMonth}
                title="選擇月份"
                trigger="click"
                placement="bottomLeft"
                visible={pickerVisible.month}
              >
                <div
                  className="crmCustomerFilter_birth"
                  onClick={(e) => handleDatePickerOpen(e, "month")}
                >
                  <input
                    type="text"
                    placeholder="月"
                    value={customerInfo.birthday.month}
                    readOnly
                  />
                  <CalendarOutlined
                    className="icon_calendar"
                    style={{ color: "#c8c8c8" }}
                  />
                </div>
              </Popover>
            </div>
            <div className="birth_box">
              <div className="option">出生日期</div>
              <Popover
                overlayClassName="crmCustomerFilterBirth_popover"
                content={selectDate}
                title="選擇日期"
                trigger="click"
                placement="bottomLeft"
                visible={pickerVisible.date}
              >
                <div
                  className="crmCustomerFilter_birth"
                  onClick={(e) => handleDatePickerOpen(e, "date")}
                >
                  <input
                    type="text"
                    placeholder="日"
                    value={customerInfo.birthday.date}
                    readOnly
                  />
                  <CalendarOutlined
                    className="icon_calendar"
                    style={{ color: "#c8c8c8" }}
                  />
                </div>
              </Popover>
            </div>
          </div>
          <div className="note">
            可個別選填出生年份、月份、日期，或是組合選填想要篩選的日期條件
          </div>
          <div className="subtitle">標記</div>
          <div className="tags_content">
            <Space
              style={{
                width: "100%",
              }}
              direction="vertical"
            >
              <Select
                mode="multiple"
                className="customer_select_tags"
                style={{
                  width: "100%",
                }}
                placeholder="輸入標記關鍵字"
                value={customerInfo.tags}
                options={options}
                onChange={handleTagsChange}
                notFoundContent={"無相關標記"}
                dropdownClassName="customer_select_tags_dropdown"
              />
            </Space>
          </div>
          <div className="subtitle">其他</div>
          <div className="other_content">
            <label>
              <input
                name="type"
                type="checkbox"
                value="blocked"
                onChange={(e) => handleFilterChange(e)}
                checked={customerInfo.type.includes("blocked")}
              />
              <span>已被加入封鎖名單</span>
            </label>
            <label>
              <input
                name="type"
                type="checkbox"
                value="verified"
                onChange={(e) => handleFilterChange(e)}
                checked={customerInfo.type.includes("verified")}
              />
              <span>通過電話認證</span>
            </label>
          </div>
        </div>
      );
    }

    return (
      <div className="popover_block">
        {content}
        <div className="popover_footer">
          <div className="btn_clear" onClick={clearFilter}>
            {t("filterBar.clear")}
          </div>
          <div>
            <button
              className="btn_cancel"
              onClick={() => submitFilter("clear")}
            >
              {t("filterBar.cancel")}
            </button>
            <button className="btn_saved" onClick={() => submitFilter("saved")}>
              {t("filterBar.apply")}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderMask = () => {
    let maskVisible = false;

    Object.keys(visible).forEach((i) => {
      if (visible[i] === true) maskVisible = true;
    });

    if (maskVisible) {
      return (
        <div className="popover_mask" onClick={(e) => e.stopPropagation()} />
      );
    }
  };

  return (
    <div className="filterBar_sticky">
      <div className="crmCustomerFilterBar">
        {renderMask()}

        <Popover
          overlayClassName="crmCustomerFilterBar_popover"
          content={renderContent("bookingRecord")}
          title={t("filterBar.bookingRecord.title")}
          trigger="click"
          placement="bottomLeft"
          visible={visible.bookingRecord}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
          <div
            className={`crmCustomerFilterBar_btn ${
              visible.bookingRecord && "active"
            } ${
              !_.isEqual(
                customerFilter["bookingRecord"],
                templateCustomerFilter["bookingRecord"]
              )
                ? "isFiltering"
                : ""
            }`}
            onClick={() => handleOpenChange("bookingRecord")}
          >
            {t("filterBar.bookingRecord.title")}
            {_.isEqual(
              customerFilter["bookingRecord"],
              templateCustomerFilter["bookingRecord"]
            ) ? (
              <img
                src={require("../../../images/arrowDown_icon_dark.svg")}
                alt=""
              />
            ) : (
              <img
                src={require("../../../images/cross_icon_green.svg")}
                alt=""
                onClick={(e) => cancelFilter(e, "bookingRecord")}
              />
            )}
          </div>
        </Popover>

        <Popover
          overlayClassName="crmCustomerFilterBar_popover"
          content={renderContent("queueRecord")}
          title={t("filterBar.queueRecord.title")}
          trigger="click"
          placement="bottomLeft"
          visible={visible.queueRecord}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
          <div
            className={`crmCustomerFilterBar_btn ${
              visible.queueRecord ? "active" : ""
            } ${
              !_.isEqual(
                customerFilter["queueRecord"],
                templateCustomerFilter["queueRecord"]
              )
                ? "isFiltering"
                : ""
            }`}
            onClick={() => handleOpenChange("queueRecord")}
          >
            {t("filterBar.queueRecord.title")}
            {_.isEqual(
              customerFilter["queueRecord"],
              templateCustomerFilter["queueRecord"]
            ) ? (
              <img
                src={require("../../../images/arrowDown_icon_dark.svg")}
                alt=""
              />
            ) : (
              <img
                src={require("../../../images/cross_icon_green.svg")}
                alt=""
                onClick={(e) => cancelFilter(e, "queueRecord")}
              />
            )}
          </div>
        </Popover>

        {/* <Popover
          overlayClassName='crmCustomerFilterBar_popover'
          content={renderContent('customerInfo')}
          title="顧客資料"
          trigger="click"
          placement='bottomLeft'
          visible={visible.customerInfo}
          getPopupContainer={triggerNode => triggerNode.parentElement}
        >
          <div
            className={`crmCustomerFilterBar_btn ${visible.customerInfo ? 'active' : ''} ${
              !_.isEqual(customerFilter['customerInfo'], templateCustomerFilter['customerInfo']) ? 'isFiltering' : ''}`}
            onClick={() => handleOpenChange('customerInfo')}
          >
            顧客資料
            {
              _.isEqual(customerFilter['customerInfo'], templateCustomerFilter['customerInfo'])
              ? <img src={require('../../../images/arrowDown_icon_dark.svg')} alt="" />
              : <img
                  src={require('../../../images/cross_icon_green.svg')}
                  alt=""
                  onClick={(e) => cancelFilter(e, 'customerInfo')}
                />
            }
          </div>
        </Popover> */}
      </div>
    </div>
  );
};

export default CrmCustomerFilterBar;