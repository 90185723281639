import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../crmContext/MessageContext";

const CrmMessageEmpty = ({ messageStatus }) => {
  const { t } = useTranslation("messages");
  const { MessageState } = useContext(MessageContext);
  const { searchMode } = MessageState;

  const searchMessageEmpty = () => {
    return (
      <div className="crmMessageEmpty_content">{t("list.noSearchResult")}</div>
    );
  };

  const messageEmptyContent = () => {
    if (messageStatus === "sent") {
      return (
        <div className="crmMessageEmpty_content">
          {t("list.sentEmpty_1")} <br />
          {t("list.sentEmpty_2")}
        </div>
      );
    } else if (messageStatus === "draft") {
      return (
        <div className="crmMessageEmpty_content">
          {t("list.draftEmpty_1")} <br />
          {t("list.draftEmpty_2")}
        </div>
      );
    } else if (messageStatus === "scheduling") {
      return (
        <div className="crmMessageEmpty_content">
          {t("list.scheduledEmpty_1")} <br />
          {t("list.scheduledEmpty_2")}
        </div>
      );
    }
  };

  return (
    <div className="crmMessageEmpty">
      {searchMode ? searchMessageEmpty() : messageEmptyContent()}
    </div>
  );
};

export default CrmMessageEmpty;
