import React from "react";
import $ from "jquery";
import { withTranslation, Trans } from "react-i18next";
import { Tooltip, Switch } from "antd";
import { config } from "../../utils/config";
import { handleError } from "../../libs/handler";

import zipData from "../../utils/zipData";
import twCity from "../../utils/twCity";
import businessType from "../../utils/businessType";
import PropTypes from "prop-types";
import twCityId from "../../utils/twCityId";

import GoogleReservationRequiredCheckModal from "./bookingSettingsPopup/GoogleReservationRequiredCheckModal";
import SettingsSaveButton from "../commons/SettingsSaveButton";

const MCTypes = {
  1: "網路商店",
  2: "實體商店(無刷卡機)",
  3: "ezAIO 卡機商店",
};
const MerchantTypes = {
  1: "1.實體商品",
  2: "2.服務",
  3: "3.虛擬商品",
  4: "4.票券",
};

class BookingSettingPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      init: false,
      isSaved: true,
      goRegister: false,
      sending: false,
      register_success: false,
      showGoogleReservationRequiredCheckModal: false,
    };
  }
  routerWillLeave = (nextLocation) => {
    const { t } = this.props;
    // return false to prevent a transition w/o prompting the user,
    // or return a string to allow the user to decide:
    // return `null` or nothing to let other hooks to be executed
    //
    // NOTE: if you return true, other hooks will not be executed!
    if (!this.state.isSaved) return t("leaveNotice");
  };

  change = (e) => {
    var val = e.target.value;
    this.setState({
      sms_enable: val,
      isSaved: false,
    });
  };

  clickRegister = () => {
    this.setState({ goRegister: true });
  };

  componentDidMount() {
    this.setState({
      emptyDepositAlert: false,
      emptyPeopleAlert: false,
      invalid_people_input: false,
      invalid_deposit_input: false,
    });
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
    $.ajax({
      url: window.domain + "/dashboard/booking_settings",
      method: "GET",
      xhrFields: { withCredentials: true },
    })
      .done(
        function (data) {
          this.setState(data);
        }.bind(this)
      )
      .fail(function (xhr) {
        handleError(xhr);
      });

    $.ajax({
      url: window.domain + "/dashboard/booking_settings/spg_data",
      method: "GET",
      xhrFields: { withCredentials: true },
    })
      .done(
        function (data) {
          this.setState(data);
          this.setState({ init: true });
        }.bind(this)
      )
      .fail(function (xhr) {
        handleError(xhr);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    $('[data-toggle="tooltip"]').tooltip();
  }

  handleEnable = () => {
    this.setState((prevState) => ({
      deposit_required: !prevState.deposit_required,
    }));
  };

  onChange = (e, msg) => {
    var name = e.target.name;
    var val = e.target.value;
    if (e.target.type === "checkbox") {
      val = e.target.checked;
    }
    var result = {};
    result[name] = val;
    if (name === "deposit_people_required" && val.length > 0) {
      result["emptyPeopleAlert"] = false;
    }

    if (name === "deposit" && val.length > 0) {
      result["emptyDepositAlert"] = false;
    }

    if (name === "deposit_people_required" && val >= 0) {
      result["invalid_people_input"] = false;
    }

    if (name === "deposit" && val >= 0) {
      result["invalid_deposit_input"] = false;
    }

    this.setState(result, () => this.setState({ isSaved: false }));

    // customize the error message for wrong input format
    if (msg) {
      this.checkInput(e, msg);
    }
  };

  handleSubmit = () => {
    if (
      this.state.deposit_required &&
      (this.state.deposit_people_required === "" ||
        this.state.deposit_people_required === null ||
        this.state.deposit_people_required === undefined ||
        this.state.deposit_people_required.length === 0)
    ) {
      this.setState({
        emptyPeopleAlert: true,
      });
    } else if (
      this.state.deposit_required &&
      (this.state.deposit === "" ||
        this.state.deposit === null ||
        this.state.deposit === undefined ||
        this.state.deposit.length === 0)
    ) {
      this.setState({
        emptyDepositAlert: true,
      });
    } else if (
      this.state.deposit_required &&
      this.state.deposit_people_required <= 0
    ) {
      this.setState({
        invalid_people_input: true,
      });
    } else if (this.state.deposit_required && this.state.deposit <= 0) {
      this.setState({
        invalid_deposit_input: true,
      });
    } else if (this.state.google_reservation && this.state.deposit_required) {
      this.setState({ showGoogleReservationRequiredCheckModal: true });
    } else {
      this.submit();
    }
  };

  submit = () => {
    const { t } = this.props;
    this.setState({
      sending: true,
      showGoogleReservationRequiredCheckModal: false,
    });

    $.ajax({
      url: window.domain + "/dashboard/booking_settings",
      data: {
        booking_settings: {
          verify_required: this.state.verify_required,
          deposit: this.state.deposit,
          deposit_required: this.state.deposit_required,
          verify_max_time: this.state.verify_max_time,
          pay_max_time: this.state.pay_max_time,
          booking_editable: this.state.booking_editable,
          spg_merchant_id: this.state.spg_merchant_id,
          spg_hash_key: this.state.spg_hash_key,
          spg_hash_iv: this.state.spg_hash_iv,
          deposit_people_required: this.state.deposit_people_required,
        },
      },
      method: "PATCH",
      xhrFields: { withCredentials: true },
    })
      .done((data) => {
        this.setState({ isSaved: true, sending: false });
        window.app.alert.setMessage(t("status.saved"), "done");
      })
      .fail(function (xhr) {
        this.setState({ sending: false });
        window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
        handleError(xhr);
      });
  };

  submit_register = (e) => {
    const { t } = this.props;
    e.preventDefault();
    $.ajax({
      url: window.domain + "/dashboard/booking_settings/apply_newebpay",
      data: {
        newebpay_register_data: {
          MemberUnified: this.state.MemberUnified,
          ManagerID: this.state.manager_id_type + ";" + this.state.manager_id,
          MemberName: this.state.MemberName,
          MemberPhone: this.state.MemberPhone,
          ManagerName: this.state.ManagerName,
          ManagerNameE: this.state.ManagerNameE,
          LoginAccount: this.state.LoginAccount,
          ManagerMobile: this.state.ManagerMobile,
          ManagerEmail: this.state.ManagerEmail,
          MerchantName: this.state.MerchantName,
          MerchantNameE: this.state.MerchantNameE,
          MerchantWebURL: config.booking_domain + "/" + this.state.shop.name,
          MerchantAddrCity: this.state.MerchantAddrCity,
          MerchantAddrArea: this.state.MerchantAddrArea,
          MerchantAddrCode: this.state.MerchantAddrCode,
          MerchantAddr: this.state.MerchantAddr,
          NationalE: this.state.NationalE,
          CityE: this.state.CityE,
          MerchantDesc: this.state.MerchantDesc,
          MerchantType: this.state.MerchantType,
          BusinessType: this.state.BusinessType,
          BankCode: this.state.BankCode,
          SubBankCode: this.state.SubBankCode,
          BankAccount: this.state.BankAccount,
          RepresentName: this.state.RepresentName, // new
          IDCardDate: this.state.IDCardDate,
          IDCardPlace: this.state.IDCardPlace,
          IDFrom: this.state.IDFrom,
          Date: this.state.Date,
          CapitalAmount: this.state.CapitalAmount,
          IncorporationDate: this.state.IncorporationDate,
          CompanyAddress: this.state.CompanyAddress,
          MemberAddress: this.state.MemberAddress,
          DisputeMail: this.state.DisputeMail,
          MerchantEmail: this.state.MerchantEmail,
          MCType: this.state.MCType,
          MerchantEnAddr: this.state.MerchantEnAddr,
        },
      },
      method: "POST",
      xhrFields: { withCredentials: true },
    })
      .done((data) => {
        this.setState({ isSaved: true });
        window.app.alert.setMessage(
          t("advancedPayments.createSuccessful"),
          "done"
        );
        this.setState({ goRegister: false });
        this.setState({ register_success: true });
        this.setState(data);
      })
      .fail(function (xhr) {
        window.app.alert.setMessage(
          xhr.responseJSON.errors,
          "m-alert m-alert-error"
        );
        handleError(xhr);
      });
  };

  checkInput(e, msg) {
    e.target.setCustomValidity("");
    if (!e.target.validity.valid) {
      e.target.setCustomValidity(msg);
    }
  }

  handleChange = (data) => {
    let keys_array = Object.keys(data);
    let result = {};

    keys_array.forEach((element) => {
      const value = data[element];

      if (element === "MCType") {
        const key = Object.keys(MCTypes).find((k) => MCTypes[k] === value);
        result[element] = key || value;
      } else if (element === "MerchantType") {
        const key = Object.keys(MerchantTypes).find(
          (k) => MerchantTypes[k] === value
        );
        result[element] = key || value;
      } else if (element === "BusinessType") {
        result[element] = value.split("-")[0];
      } else if (element === "CityE") {
        result[element] = value.split("(")[0];
      } else {
        result[element] = value;
      }
    });
    this.setState(result, () => this.setState({ isSaved: false }));
  };

  findFullBusinessText = (code) => {
    return businessType.find((item) => item.startsWith(code + "-")) || "";
  };

  findFullTWEnText = (city) => {
    return twCity.find((item) => item.startsWith(city + "(")) || "";
  };

  renderPendingView() {
    const { t } = this.props;
    const { merchant_verified, register_success } = this.state;
    if (!merchant_verified || register_success) {
      return (
        <div className="setting-container">
          <div style={{ flexGrow: 1 }}>
            <h3>{t("advancedPayments.title")}</h3>
            <h5>{t("advancedPayments.description")}</h5>
          </div>
          <hr />
          <div className="settingTbl" style={{ marginLeft: 0 }}>
            <div className="settingTblRow">
              <div
                className="rangeTitle"
                style={{
                  marginTop: 44,
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h4 style={{ color: "#3fba87", fontWeight: "bold" }}>
                  {t("advancedPayments.registrationSuccessful")}
                </h4>
                <h4>{t("advancedPayments.pendingReview")}</h4>
              </div>
            </div>

            <div
              className="settingTblRow "
              style={{ marginTop: 40, marginBottom: 8 }}
            >
              <div className="setting-list-title">
                {t("advancedPayments.pending.instructionsForFinancial")}
              </div>
            </div>

            <div className="settingTblRow" style={{ marginBottom: 20 }}>
              <div
                className="setting-list-content"
                style={{ paddingLeft: 0, color: "#000000" }}
              >
                <ul>
                  <li>
                    {t("advancedPayments.pending.instructionsForFinancial_1")}
                  </li>
                  <li>
                    {t("advancedPayments.pending.instructionsForFinancial_2")}
                  </li>
                  <li>
                    {t("advancedPayments.pending.instructionsForFinancial_3")}
                  </li>
                  <li>
                    {t("advancedPayments.pending.instructionsForFinancial_4")}
                  </li>
                </ul>
              </div>
            </div>

            <div className="settingTblRow " style={{ marginBottom: 8 }}>
              <div className="setting-list-title">
                {t("advancedPayments.pending.howToLoginNewebPay")}
              </div>
            </div>

            <div className="settingTblRow" style={{ marginBottom: 20 }}>
              <div
                className="setting-list-content"
                style={{ paddingLeft: 0, color: "#000000" }}
              >
                <ul>
                  <li>{t("advancedPayments.pending.howToLoginNewebPay_1")}</li>
                  <li>{t("advancedPayments.pending.howToLoginNewebPay_2")} </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { t } = this.props;
    const { spg_merchant_id, spg_hash_key, spg_hash_iv, merchant_verified } =
      this.state;

    if (
      this.state.init &&
      (spg_merchant_id === null ||
        spg_merchant_id === "" ||
        spg_hash_key === null ||
        spg_hash_key === "" ||
        spg_hash_iv === null ||
        spg_hash_iv === "")
    ) {
      if (this.state.goRegister) {
        return (
          <div className="setting-container">
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <h3>{t("advancedPayments.title")}</h3>
                <h5>{t("advancedPayments.description")}</h5>
              </div>
            </div>
            <hr style={{ borderTop: "1px solid rgb(51, 51, 51, 0.3)" }} />
            <div className="settingTblRow">
              <img
                style={{ width: 26, height: 30 }}
                src={require("../../images/pen_icon.png")}
                alt=""
              />
              <div
                style={{
                  fontSize: 24,
                  display: "inline-Block",
                  marginLeft: 24,
                  marginBottom: 40,
                }}
              >
                {t(
                  "advancedPayments.register.registerNewebPayEnterpriseMember"
                )}
              </div>
            </div>
            <form
              id="myform"
              className="settingTbl"
              style={{ marginLeft: 50, marginTop: 0 }}
              onSubmit={this.submit_register}
            >
              <div className="settingTblRow">
                <div className="header">
                  <Tooltip
                    placement="bottom"
                    overlayClassName="setting-tooltip"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    title={t(
                      "advancedPayments.register.unifiedBusinessNumber_tooltip"
                    )}
                  >
                    {t("advancedPayments.register.unifiedBusinessNumber")}{" "}
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="MemberUnified"
                    onChange={this.onChange}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.representName")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    maxLength="60"
                    name="RepresentName"
                    onChange={this.onChange}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header col-sm-4" style={{ padding: 0 }}>
                  <Tooltip
                    placement="bottom"
                    overlayClassName="setting-tooltip"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    title={t(
                      "advancedPayments.register.companyIDNumber_tooltip"
                    )}
                  >
                    {t("advancedPayments.register.companyIDNumber")}{" "}
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </div>
                <div
                  className="col-sm-8"
                  style={{ paddingLeft: 0, display: "flex" }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      className="radio"
                      style={{
                        marginRight: "33px",
                        marginTop: 5,
                        fontSize: 18,
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          name="manager_id_type"
                          onClick={(e) => this.onChange(e)}
                          required
                          value="1"
                        />{" "}
                        {t("advancedPayments.register.localCitizenship")}
                      </label>
                    </div>
                    <div
                      className="radio"
                      style={{
                        marginRight: "33px",
                        marginTop: 5,
                        fontSize: 18,
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          name="manager_id_type"
                          onClick={(e) => this.onChange(e)}
                          value="2"
                        />{" "}
                        {t("advancedPayments.register.residencePermitNumber")}
                      </label>
                    </div>
                    <div
                      className="radio"
                      style={{
                        marginRight: "33px",
                        marginTop: 5,
                        fontSize: 18,
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          name="manager_id_type"
                          onClick={(e) => this.onChange(e)}
                          value="3"
                        />{" "}
                        {t("advancedPayments.register.taxIdentificationNumber")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header"></div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="manager_id"
                    onChange={this.onChange}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.idCarDate")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="IDCardDate"
                    placeholder={`${t(
                      "advancedPayments.register.example"
                    )}0950101`}
                    onChange={(e) => this.onChange(e, e.target.placeholder)}
                    type="text"
                    pattern="^\d{7}$"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.idCarPlace")}
                </div>
                <div className="text">
                  <div style={{ fontSize: "16px" }} className="form-inline">
                    <TranslatedDropdown
                      data={twCityId}
                      handleChange={this.handleChange}
                      selectName="IDCardPlace"
                      selectValue={this.state.IDCardPlace}
                    />
                  </div>
                </div>
              </div>
              <div className="settingTblRow">
                <div
                  className="header col-sm-4"
                  style={{ padding: 0 }}
                  data-toggle="tooltip"
                >
                  {t("advancedPayments.register.idFrom")}
                </div>
                <div className="col-sm-8" style={{ paddingLeft: 0 }}>
                  <div style={{ display: "flex" }}>
                    <div
                      className="radio"
                      style={{
                        marginRight: "33px",
                        marginTop: 5,
                        fontSize: 18,
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          name="IDFrom"
                          onClick={(e) => this.onChange(e)}
                          required
                          value={1}
                        />
                        {t("advancedPayments.register.firstIssuance")}
                      </label>
                    </div>
                    <div
                      className="radio"
                      style={{
                        marginRight: "33px",
                        marginTop: 5,
                        fontSize: 18,
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          name="IDFrom"
                          onClick={(e) => this.onChange(e)}
                          value={2}
                        />
                        {t("advancedPayments.register.reissuance")}
                      </label>
                    </div>
                    <div
                      className="radio"
                      style={{
                        marginRight: "33px",
                        marginTop: 5,
                        fontSize: 18,
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          name="IDFrom"
                          onClick={(e) => this.onChange(e)}
                          value={3}
                        />
                        {t("advancedPayments.register.replacement")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.birthDate")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="Date"
                    placeholder={`${t(
                      "advancedPayments.register.example"
                    )}19901231`}
                    onChange={this.onChange}
                    type="text"
                    pattern="^\d{8}$"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  <Tooltip
                    placement="bottom"
                    overlayClassName="setting-tooltip"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    title={t("advancedPayments.register.memberName_tooltip")}
                  >
                    {t("advancedPayments.register.memberName")}{" "}
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    maxLength="60"
                    name="MemberName"
                    onChange={this.onChange}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div
                  className="header"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={t(
                    "advancedPayments.register.pls_provideTheInformationAsRegisteredCompany"
                  )}
                >
                  {t("advancedPayments.register.capitalAmount")}
                  <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    maxLength="60"
                    name="CapitalAmount"
                    onChange={this.onChange}
                    type="text"
                    pattern="^\d*$"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div
                  className="header"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={t(
                    "advancedPayments.register.pls_provideTheInformationAsRegisteredCompany"
                  )}
                >
                  {t("advancedPayments.register.incorporationDate")}
                  <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="IncorporationDate"
                    placeholder={`${t(
                      "advancedPayments.register.example"
                    )}20190101`}
                    onChange={this.onChange}
                    type="text"
                    pattern="^\d{8}$"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div
                  className="header"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={t("advancedPayments.register.companyAddress_tooltip")}
                >
                  {t("advancedPayments.register.companyAddress")}
                  <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="CompanyAddress"
                    placeholder={t(
                      "advancedPayments.register.placeholder_companyAddress"
                    )}
                    onChange={(e) => this.onChange(e, e.target.placeholder)}
                    type="text"
                    pattern="^[\u4e00-\u9fa5\d-]*$"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.memberPhone")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="MemberPhone"
                    placeholder={t(
                      "advancedPayments.register.placeholder_memberPhone"
                    )}
                    onChange={(e) => this.onChange(e, e.target.placeholder)}
                    type="text"
                    pattern="\d+-\d+"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  <Tooltip
                    placement="bottom"
                    overlayClassName="setting-tooltip"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    title={t(
                      "advancedPayments.register.administratorName_tooltip"
                    )}
                  >
                    {t("advancedPayments.register.administratorName")}{" "}
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="ManagerName"
                    onChange={this.onChange}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.administratorNameEN")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="ManagerNameE"
                    placeholder={t(
                      "advancedPayments.register.placeholder_administratorNameEn"
                    )}
                    onChange={(e) => this.onChange(e, e.target.placeholder)}
                    type="text"
                    pattern="[a-zA-Z,. ]+"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  <Tooltip
                    placement="bottom"
                    overlayClassName="setting-tooltip"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    title={t(
                      "advancedPayments.register.administratorLoginAccount_tooltip"
                    )}
                  >
                    {t("advancedPayments.register.administratorLoginAccount")}{" "}
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="LoginAccount"
                    placeholder={t(
                      "advancedPayments.register.placeholder_administratorLoginAccount"
                    )}
                    onChange={(e) => this.onChange(e, e.target.placeholder)}
                    type="text"
                    pattern="[0-9a-zA-Z_.@]+"
                    maxLength="20"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.administratorMobile")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="ManagerMobile"
                    placeholder={t(
                      "advancedPayments.register.placeholder_administratorMobile"
                    )}
                    onChange={(e) => this.onChange(e, e.target.placeholder)}
                    type="text"
                    pattern="[0-9]{10}"
                    maxLength="10"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.memberAddress")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="MemberAddress"
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.administratorEmail")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="ManagerEmail"
                    onChange={this.onChange}
                    type="email"
                    maxLength="40"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.disputeMail")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="DisputeMail"
                    onChange={this.onChange}
                    type="email"
                    required
                  />
                </div>
              </div>
              <div>
                <hr
                  style={{
                    borderTop: "1px solid rgb(51, 51, 51, 0.3)",
                    marginTop: 52,
                    marginBottom: 52,
                  }}
                />
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.merchantName")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="MerchantName"
                    onChange={this.onChange}
                    type="text"
                    maxLength="20"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.merchantNameEN")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="MerchantNameE"
                    onChange={this.onChange}
                    type="text"
                    maxLength="100"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.merchantAddress")}
                </div>
                <div className="text">
                  <TranslatedTWZipCode
                    handleChangeCounty={this.handleChange}
                    handleChangeDistrict={this.handleChange}
                    handleChangeZipcode={this.handleChange}
                    countyValue={this.state.county}
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header"></div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="MerchantAddr"
                    onChange={this.onChange}
                    type="text"
                    required
                  />
                </div>
              </div>

              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.merchantRegisterCountryEN")}
                </div>
                <div className="text ">
                  <div className="col-sm-3" style={{ padding: 0, width: 114 }}>
                    <select
                      name="NationalE"
                      className="form-control input-lg"
                      style={{ padding: 10 }}
                      value={this.state.NationalE ? this.state.NationalE : ""}
                    >
                      <option value="Taiwan">Taiwan</option>
                    </select>
                  </div>
                  <div className="col-sm-6" style={{ marginLeft: 3 }}>
                    <TranslatedDropdown
                      data={twCity}
                      handleChange={this.handleChange}
                      selectName="CityE"
                      selectValue={this.findFullTWEnText(this.state.CityE)}
                    />
                  </div>
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.merchantEnAddr")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="MerchantEnAddr"
                    onChange={(e) => this.onChange(e, e.target.placeholder)}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.merchantType")}
                </div>
                <div className="text">
                  <div style={{ fontSize: "16px" }} className="form-inline">
                    <TranslatedDropdown
                      data={["1.實體商品", "2.服務", "3.虛擬商品", "4.票券"]}
                      handleChange={this.handleChange}
                      selectName="MerchantType"
                      selectValue={this.state.MerchantType}
                    />
                  </div>
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.businessType")}
                </div>
                <div className="text">
                  <div style={{ fontSize: "16px" }} className="form-inline">
                    <TranslatedDropdown
                      data={businessType}
                      handleChange={this.handleChange}
                      selectName="BusinessType"
                      s
                      selectValue={this.findFullBusinessText(
                        this.state.BusinessType
                      )}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.mcType")}
                </div>
                <div className="text">
                  <div style={{ fontSize: "16px" }} className="form-inline">
                    <TranslatedDropdown
                      data={[
                        "網路商店",
                        "實體商店(無刷卡機)",
                        "ezAIO 卡機商店",
                      ]}
                      handleChange={this.handleChange}
                      selectName="MCType"
                      selectValue={MCTypes[this.state.MCType]}
                    />
                  </div>
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.merchantDescription")}
                </div>

                <div className="text">
                  <textarea
                    className="form-control input-lg"
                    style={{ height: 168 }}
                    name="MerchantDesc"
                    placeholder={t(
                      "advancedPayments.register.placeholder_merchantDescription"
                    )}
                    onChange={this.onChange}
                    type="text"
                    required
                    maxLength="255"
                    resize="vertical"
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div
                  className="header"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={t("advancedPayments.register.merchantEmail_tooltip")}
                >
                  {t("advancedPayments.register.merchantEmail")}
                  <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="MerchantEmail"
                    placeholder={t(
                      "advancedPayments.register.placeholder_merchantEmail"
                    )}
                    onChange={(e) => this.onChange(e, e.target.placeholder)}
                    type="text"
                    pattern="^([0-9a-zA-Z_.+-]+@[0-9a-zA-Z-]+\.[a-zA-Z.]+)(,\s*[0-9a-zA-Z_.+-]+@[0-9a-zA-Z-]+\.[a-zA-Z.]+)*$"
                    required
                  />
                </div>
              </div>
              <div>
                <hr
                  style={{
                    borderTop: "1px solid rgb(51, 51, 51, 0.3)",
                    marginTop: 52,
                    marginBottom: 52,
                  }}
                />
              </div>
              <div className="settingTblRow" style={{ width: "100%" }}>
                <div className="header ">
                  {t("advancedPayments.register.bankAccountGuidelines")}
                </div>
                <div className="text" style={{ paddingLeft: 0 }}>
                  <ul style={{ fontSize: "16", paddingLeft: "20" }}>
                    <li>
                      {t("advancedPayments.register.bankAccountGuidelines_1")}
                    </li>
                    <li>
                      {t("advancedPayments.register.bankAccountGuidelines_2")}
                    </li>
                    <li>
                      <Trans i18nKey="settings:advancedPayments.register.bankAccountGuidelines_3">
                        驗證作業約需
                        <span style={{ color: "#3fba87" }}>五個工作天。</span>
                      </Trans>
                    </li>
                    <li>
                      {t("advancedPayments.register.bankAccountGuidelines_4")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.bankCode")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="BankCode"
                    onChange={this.onChange}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.subBankCode")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="SubBankCode"
                    onChange={this.onChange}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.nameOfBankAccount")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="BankAccount"
                    value={this.state.MemberName}
                    disabled
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.register.bankAccount")}
                </div>
                <div className="text">
                  <input
                    className="form-control input-lg"
                    name="BankAccount"
                    onChange={this.onChange}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "left", marginTop: 70 }}
              >
                <input
                  className="button-common button-primary"
                  style={{ width: 170, height: 48 }}
                  type="submit"
                  value={t("advancedPayments.register.register")}
                />
              </div>
            </form>
          </div>
        );
      }

      if (this.state.register_success) {
        return this.renderPendingView();
      }

      return (
        <div className="setting-container">
          <div style={{ flexGrow: 1 }}>
            <h3>{t("advancedPayments.title")}</h3>
            <h5>{t("advancedPayments.description")}</h5>
          </div>
          <hr />

          <div
            style={{
              fontSize: "24px",
              color: "#575757",
              letterSpacing: "1px",
              display: "flex",
              marginTop: 80,
            }}
          >
            {t("advancedPayments.startUsing")}
            <br />
            {t("advancedPayments.startUsing_content")}
          </div>
          <div
            style={{
              fontSize: "14px",
              color: "#575757",
              letterSpacing: "1px",
              marginTop: "10px",
              display: "flex",
            }}
          >
            {t("advancedPayments.personalMemberRegistration_description")}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <button
              className="button-common button-primary"
              onClick={this.clickRegister}
            >
              {t("advancedPayments.register.title")}
            </button>
            <br />
            <hr />
          </div>
        </div>
      );
    }

    if (this.state.init && !merchant_verified) {
      return this.renderPendingView();
    }

    if (this.state.init) {
      return (
        <>
          <div className="setting-container">
            {this.state.sending && (
              <div className="lightBoxLayer">
                <h4>{t("status.saving")}</h4>
              </div>
            )}
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <h3>{t("advancedPayments.title")}</h3>
                <h5>{t("advancedPayments.description")}</h5>
              </div>
            </div>
            <hr />
            <div className="settingTbl" style={{ marginLeft: 0 }}>
              <div className="settingTblRow">
                <div className="header">{t("advancedPayments.storeID")} </div>
                <div className="text"> {spg_merchant_id} </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  {t("advancedPayments.enableAdvancedPayments")}
                </div>
                <div className="text">
                  <Switch
                    onChange={this.handleEnable}
                    checked={
                      this.state.deposit_required &&
                      this.state.merchant_verified
                    }
                    disabled={!this.state.merchant_verified}
                  />
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header">
                  <Tooltip
                    placement="bottom"
                    overlayClassName="setting-tooltip"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    title={t("advancedPayments.condition_tooltip")}
                  >
                    {t("advancedPayments.condition")}{" "}
                    <i className="fa fa-question-circle-o" />
                  </Tooltip>
                </div>
                <div className="text">
                  <Trans i18nKey="settings:advancedPayments.paymentRequiredForEachPerson">
                    可設定
                    <input
                      onChange={this.onChange}
                      defaultValue={this.state.deposit_people_required}
                      disabled={
                        !this.state.deposit_required ||
                        !this.state.merchant_verified
                      }
                      name="deposit_people_required"
                      type="number"
                      min="1"
                      style={
                        this.state.emptyPeopleAlert ||
                        this.state.invalid_people_input
                          ? {
                              width: "114px",
                              display: "inline-block",
                              borderColor: "#CE3636",
                            }
                          : { width: "114px", display: "inline-block" }
                      }
                      className="form-control input-lg"
                    />
                    人以上（含），每人
                    <input
                      onChange={this.onChange}
                      defaultValue={this.state.deposit}
                      disabled={
                        !this.state.deposit_required ||
                        !this.state.merchant_verified
                      }
                      name="deposit"
                      type="number"
                      min="1"
                      style={
                        this.state.emptyDepositAlert ||
                        this.state.invalid_deposit_input
                          ? {
                              width: "114px",
                              display: "inline-block",
                              borderColor: "#CE3636",
                            }
                          : { width: "114px", display: "inline-block" }
                      }
                      className="form-control input-lg"
                    />
                    元
                  </Trans>
                  {this.state.emptyDepositAlert ||
                  this.state.emptyPeopleAlert ? (
                    <div style={{ color: "#CE3636", fontSize: "16px" }}>
                      {t("advancedPayments.pls_cannotBeEmpty")}
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.invalid_people_input ||
                  this.state.invalid_deposit_input ? (
                    <div style={{ color: "#CE3636", fontSize: "16px" }}>
                      {t("advancedPayments.pls_greaterThan1")}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="block_notice">
                <div className="title">{t("advancedPayments.notes")}</div>
                <ul>
                  <li>{t("advancedPayments.notes_1")}</li>
                  <li>{t("advancedPayments.notes_2")}</li>
                  <li>{t("advancedPayments.notes_3")}</li>
                  <li>
                    {t("advancedPayments.notes_4")}
                    <ul>
                      <li>{t("advancedPayments.notes_4-1")}</li>
                      <li>{t("advancedPayments.notes_4-2")}</li>
                    </ul>
                  </li>
                  <li>{t("advancedPayments.notes_5")}</li>
                  <li>{t("advancedPayments.notes_6")}</li>
                </ul>
              </div>
            </div>
          </div>
          {merchant_verified && (
            <SettingsSaveButton handleSaved={this.handleSubmit} showDivider />
          )}
          {this.state.showGoogleReservationRequiredCheckModal && (
            <GoogleReservationRequiredCheckModal
              depositRequired={this.state.deposit_required}
              setGoogleReservationRequiredCheckModal={() =>
                this.setState({
                  showGoogleReservationRequiredCheckModal: false,
                })
              }
              submit={this.submit}
            />
          )}
        </>
      );
    } else {
      return <div className="pageLoading">{t("status.loading")}</div>;
    }
  }
}

class TWZipCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      county: "",
      counties: Object.keys(zipData),
      district: "",
      districts: [],
      zipcode: "",
    };
  }

  static propTypes = {
    countyFieldName: PropTypes.string,
    countyValue: PropTypes.string,
    districtFieldName: PropTypes.string,
    districtValue: PropTypes.string,
    handleChangeCounty: PropTypes.func,
    handleChangeDistrict: PropTypes.func,
    handleChangeZipcode: PropTypes.func,
    zipcodeFieldName: PropTypes.string,
    zipcodeValue: PropTypes.string,
  };

  static defaultProps = {
    countyFieldName: "MerchantAddrCity",
    countyValue: "",
    districtFieldName: "MerchantAddrArea",
    districtValue: "",
    handleChangeCounty: undefined,
    handleChangeDistrict: undefined,
    handleChangeZipcode: undefined,
    zipcodeFieldName: "MerchantAddrCode",
    zipcodeValue: "",
  };

  componentDidMount() {
    const { countyValue, districtValue } = this.props;
    const counties = Object.keys(zipData);
    const county = countyValue === "" ? "" : countyValue;
    let districts = ["--"];
    const district = districtValue === "" ? "" : districtValue;
    let zipcode = "";
    this.setState({
      county,
      counties,
      district,
      districts,
      zipcode,
    });
  }

  changeCounty = (county) => {
    const districts = Object.keys(zipData[county]).map((d) => d, []);
    const { handleChangeCounty } = this.props;
    this.setState(
      {
        county,
        district: districts[0],
        zipcode: zipData[county][districts[0]],
        districts,
      },
      () => {
        if (typeof handleChangeCounty === "function") {
          handleChangeCounty({
            MerchantAddrCity: this.state.county,
            MerchantAddrArea: this.state.district,
            MerchantAddrCode: this.state.zipcode,
          });
        }
      }
    );
  };

  changeDistrict = (district) => {
    const zipcode = zipData[this.state.county][[district][0]];
    const { handleChangeDistrict } = this.props;

    this.setState(
      {
        district,
        zipcode,
      },
      () => {
        if (typeof handleChangeDistrict === "function") {
          handleChangeDistrict({
            MerchantAddrArea: this.state.district,
            MerchantAddrCode: this.state.zipcode,
          });
        }
      }
    );
  };

  render() {
    const {
      countyFieldName,
      districtFieldName,
      zipcodeFieldName,
      zipcodePlaceholder,
    } = this.props;

    const { counties, county, districts, district, zipcode } = this.state;

    return (
      <div>
        <tr>
          <td>
            <div style={{ marginRight: 10 }}>
              <TranslatedCounty
                fieldName={countyFieldName}
                className=""
                data={counties}
                value={county}
                changeCounty={this.changeCounty}
              />
            </div>
          </td>
          <td>
            <div style={{ marginRight: 10 }}>
              <TranslatedDistrict
                fieldName={districtFieldName}
                className=""
                data={districts}
                value={district}
                changeDistrict={this.changeDistrict}
              />
            </div>
          </td>
          <div>
            <TranslatedZipCode
              name={zipcodeFieldName}
              className=""
              value={zipcode}
              placeholder={zipcodePlaceholder}
            />
          </div>
        </tr>
      </div>
    );
  }
}

class County extends React.Component {
  static propTypes = {
    changeCounty: PropTypes.func,
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    fieldName: PropTypes.string,
  };

  onChange = (e) => {
    this.props.changeCounty(e.target.value);
  };

  render() {
    const { data, fieldName, value, t } = this.props;

    const counties = data.map((v) => (
      <option key={v} value={v}>
        {v}
      </option>
    ));
    return (
      <select
        name={fieldName}
        style={{ padding: 10 }}
        className="form-control input-lg"
        onChange={this.onChange}
        value={value}
        required
      >
        <option value="" disabled>
          {t("advancedPayments.register.city")}
        </option>
        {counties}
      </select>
    );
  }
}

class District extends React.Component {
  static propTypes = {
    changeDistrict: PropTypes.func,
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    fieldName: PropTypes.string,
  };

  onChange = (e) => {
    const currentDistrict = e.target.value;
    if (currentDistrict !== "--") {
      this.props.changeDistrict(currentDistrict);
    }
  };

  render() {
    const { data, fieldName, t, value } = this.props;

    const districts = data.map((v) => (
      <option key={v} value={v}>
        {v}
      </option>
    ));

    return (
      <select
        name={fieldName}
        style={{ padding: 10 }}
        className="form-control input-lg"
        onChange={this.onChange}
        value={value}
        require
      >
        <option value="" disabled>
          {t("advancedPayments.register.area")}
        </option>
        {districts}
      </select>
    );
  }
}

class ZipCode extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    fieldName: PropTypes.string,
    placeholder: PropTypes.string,
  };

  render() {
    const { t } = this.props;
    return (
      <input
        type="text"
        className="form-control input-lg"
        style={{ width: 150 }}
        name={this.props.fieldName}
        value={this.props.value}
        placeholder={t("advancedPayments.register.zipCode")}
        maxLength="3"
        disabled
      />
    );
  }
}

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  static propTypes = {
    handleChangeCounty: PropTypes.func,
    selectName: PropTypes.string,
    selectValue: PropTypes.string,
  };

  static defaultProps = {
    data: [],
    handleChangeCounty: undefined,
    selectName: "",
    selectValue: "",
  };

  onChange = (e) => {
    const keyName = e.target.name;
    const currentValue = e.target.value;
    this.props.handleChange({ [keyName]: currentValue });
  };

  render() {
    const { data, t, selectValue } = this.props;

    const options = data.map((v) => <option value={v}>{v}</option>);
    const value = selectValue === "" ? "" : selectValue;

    return (
      <select
        name={this.props.selectName}
        className="form-control input-lg"
        onChange={this.onChange}
        value={value}
        required
      >
        <option value="" disabled>
          {t("advancedPayments.register.select")}
        </option>
        {options}
      </select>
    );
  }
}

const TranslatedTWZipCode = withTranslation("settings")(TWZipCode);
const TranslatedCounty = withTranslation("settings")(County);
const TranslatedDistrict = withTranslation("settings")(District);
const TranslatedZipCode = withTranslation("settings")(ZipCode);
const TranslatedDropdown = withTranslation("settings")(Dropdown);
export default withTranslation("settings")(BookingSettingPayment);
