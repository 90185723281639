import React from 'react'
import { Space } from 'antd';
import { withTranslation } from 'react-i18next';

var createReactClass = require('create-react-class');
export default withTranslation('settings')(createReactClass({
  renderLogo() {
    if(this.props.imagePreviewUrl) {
      return this.props.imagePreviewUrl;
    } else if(this.props.data.logo) {
      return this.props.data.logo;
    } else {
      return require("../../images/menuUser/Profile_Picture.svg");
    }
  },
  addShopDefaultSrc(e) {
		e.onerror = null;
		e.target.src = require('../../images/menuUser/Profile_Picture.svg');
	},
  render() {
    const { t } = this.props;
    return (
      <div id="shopForm">
        <div className="shopForm shopForm_edit">
          <div className="shopForm_block">
            <div className='shopForm_info_logo_block'>
              <div className="form-group">
                <img
                  className="shopForm_info_logo"
                  width="100"
                  src={this.renderLogo()}
                  onError={this.addShopDefaultSrc}
                  alt=""
                />
                <div>
                  <label htmlFor="upload" className="shopForm_info_logo_change">{t('accountSettings.uploadPhoto')}</label>
                  <input
                    id="upload"
                    onChange={this.props.onImageChange}
                    className="file-upload__input"
                    type="file"
                    name="file-upload"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </div>
              </div>
            </div>

            <div className='shopForm_info_block'>
              <div className="shopForm_info">
                <div className="shopForm_info_title">
                  {t('accountSettings.merchantName')}
                </div>
                <input
                  type="text" 
                  className="form-control shopForm_info_input"
                  placeholder={t('accountSettings.merchantName')}
                  name="display_name_update"
                  value={this.props.updateData.display_name_update}
                  onChange={this.props.onInputChange}
                />
              </div>
              <div className="shopForm_info">
                <div className="shopForm_info_title">
                  {t('accountSettings.branch')}
                </div>
                <input
                  type="text" 
                  className="form-control shopForm_info_input"
                  placeholder={t('accountSettings.branch')}
                  name="branch_update"
                  value={this.props.updateData.branch_update}
                  onChange={this.props.onInputChange}
                />
              </div>
              <div className="shopForm_info">
                <div className="shopForm_info_title">
                  {t('accountSettings.phone')}
                </div>
                <input
                  type="text" 
                  className="form-control shopForm_info_input"
                  placeholder={t('accountSettings.phone')}
                  name="phone_number_update"
                  value={this.props.updateData.phone_number_update}
                  onChange={this.props.onInputChange}
                />
              </div>
              <div className="shopForm_info">
                <div className="shopForm_info_title">
                  {t('accountSettings.address')}
                </div>
                <input
                  type="text" 
                  className="form-control shopForm_info_input"
                  placeholder={t('accountSettings.address')}
                  name="address_update"
                  value={this.props.updateData.address_update}
                  onChange={this.props.onInputChange}
                />
              </div>
              <div className="shopForm_info">
                <div className="shopForm_info_title">
                  {t('accountSettings.website')}
                </div>
                <input
                  type="text" 
                  className="form-control shopForm_info_input"
                  placeholder={t('accountSettings.website')}
                  name="website_url_update"
                  value={this.props.updateData.website_url_update}
                  onChange={this.props.onInputChange}
                />
              </div>
              <Space size={8} align='end' className="text-right">
                <button className="button-common button-secondary" onClick={this.props.cancelEdit}>
                  {t('cancel')}
                </button>
                <button className="button-common button-primary" type="submit" onClick={this.props.onSubmit}>
                  {t('save')}
                </button>
              </Space>
            </div>
          </div>
        </div>
      </div>
    );
  }
}));
