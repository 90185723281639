import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";

import { time, tableBookingNum } from "../utils/data";

//reducer
import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";

export const TableBookingDateTime = ({ closeTableBooking, tableBookingStep3 }) => {
  const { t, i18n } = useTranslation("bookingSystem");
  const { CommomSettingState } = useContext(CommomSettingContext);
  const { isToday, date, hasSeletedBookingTime, moduleSelectedBookingTime } =
    CommomSettingState;

  const [displayTime, setDisplayTime] = useState("");
  const [SeatedTimePanelCount, setSeatedTimePanelCount] = useState(0);
  const [timeCell, setTimeCell] = useState(["-", "-", ":", "-", "-"]);

  useEffect(() => {
    let bookingTime_date = null;
    if (isToday && !hasSeletedBookingTime) {
      //當日沒有選時間：現在時間
      const bookingTime_min = moment().minute();
      const diff = 5 - (bookingTime_min % 5);
      bookingTime_date = moment().add(diff, "m").format("HH:mm");
    } else if (!isToday && !hasSeletedBookingTime) {
      //非當日沒有選時間：00:00
      bookingTime_date = "00:00";
    } else {
      //有選時間
      bookingTime_date = time[moduleSelectedBookingTime];
    }

    setTimeCell(bookingTime_date.split(""));
  }, [hasSeletedBookingTime, moduleSelectedBookingTime, isToday]);

  useEffect(() => {
    const time = timeCell.join("");
    setDisplayTime(time);
  }, [timeCell]);

  const timeClick = (keyboard) => {
    let newTime = displayTime.split("");

    if (SeatedTimePanelCount === 0) {
      newTime = ["-", "-", ":", "-", "-"];
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(1);
    } else if (SeatedTimePanelCount === 1) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(3);
    } else if (SeatedTimePanelCount === 3) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(4);
    } else if (SeatedTimePanelCount === 4) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(0);
    }

    setTimeCell(newTime);
  };

  const timeDelete = () => {
    let newTime = displayTime.split("");

    if (newTime[0] === "-") return;

    if (SeatedTimePanelCount === 0) {
      newTime[4] = "-";
      setSeatedTimePanelCount(4);
    } else if (SeatedTimePanelCount === 4) {
      newTime[3] = "-";
      setSeatedTimePanelCount(3);
    } else if (SeatedTimePanelCount === 3) {
      newTime[1] = "-";
      setSeatedTimePanelCount(1);
    } else if (SeatedTimePanelCount === 1) {
      newTime[0] = "-";
      setSeatedTimePanelCount(0);
    }

    setTimeCell(newTime);
  };

  const timeCheck = () => {
    const displayTime_hour = parseInt(displayTime.substring(0, 2), 10),
      displayTime_min = parseInt(displayTime.substring(3), 10);

    if (displayTime_hour >= 24 || displayTime_min > 59) {
      return window.app.alert.setMessage(
        t("tableMap.pls_enterCorrectTimeFormat"),
        "error"
      );
    }

    if (displayTime_min % 5 !== 0) {
      return window.app.alert.setMessage(
        t("settings:bookingTimesCommon.pls_minimumTimeUnit"),
        "error"
      );
    }

    const time = moment(new Date(date + " " + displayTime));

    tableBookingStep3(time);
  };

  return (
    <Modal
      title={null}
      visible
      width={300}
      destroyOnClose
      centered
      footer={null}
      maskClosable={false}
      onCancel={closeTableBooking}
      closeIcon={<div className="TableBookingNumPadClose" />}
      className="TableBookingNumPadWrap"
    >
      <div
        className="TableBookingPanel TableBookingDateTime"
        data-lang={i18n.language}
      >
        <div className="TableBookingNumPad__header">
          <p>{date}</p>
          {displayTime}
        </div>

        <div className="TableBookingNumPad__body">
          {tableBookingNum.map((num) => {
            if (num === "delete") {
              return (
                <button
                  key={num}
                  className="numpad-delete"
                  onClick={() => timeDelete(num)}
                >
                  <img
                    className="numPad_del"
                    src={require("../../../images/newModule/delete.svg")}
                    alt="delete"
                  />
                </button>
              );
            } else if (num === "check") {
              return (
                <button
                  key={num}
                  className="numpad-check"
                  onClick={() => timeCheck()}
                >
                  {t("settings:ok")}
                </button>
              );
            } else {
              return (
                <button key={num} className="" onClick={() => timeClick(num)}>
                  {num}
                </button>
              );
            }
          })}
        </div>
      </div>
    </Modal>
  );
};
