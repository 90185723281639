import React from "react";
import { Space } from "antd";
import { useTranslation } from "react-i18next";

import alertIcon from "../../images/icon-alertRound.svg";

function InsufficientCreditsAlert() {
  const { t } = useTranslation("settings");

  return (
    <Space
      size={6}
      align="start"
      className="text-title color-alert"
      style={{ marginBottom: "32px" }}
    >
      <img className="icon-normal" src={alertIcon} alt="" />
      {t("notificationCommon.insufficientSMSCreditsAlert")}
    </Space>
  );
}

export default InsufficientCreditsAlert;
