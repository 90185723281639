import React from "react";
import { Space } from "antd";
import { ScanOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const MenuCouponHeader = ({ addCoupon, scanCoupon }) => {
	const { t } = useTranslation('couponSystem');
  return (
    <div className="menuCouponHeader">
      <h2 className="system-title">{t('couponOverview.title')}</h2>
      <div className="menuCouponHeader__button">
				<Space size={8}>
					<button className="button-round-icon " onClick={scanCoupon}>
						<ScanOutlined style={{ color: "#676767" }} />
					</button>
					<button className="button-round-big button-primary" onClick={addCoupon}>
						<div className="icon-sm plus-icon" />
						<span className="button-txt">{t('couponOverview.title')}</span>
					</button>
				</Space>
      </div>
    </div>
  );
};

export default MenuCouponHeader;
