import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

let count = 0;

const TimeKeyboard = ({ timePickerSetting, closeTimeCell, timeCellSubmit }) => {
	const { t } = useTranslation('settings');
	const [ time, setTime ] = useState(timePickerSetting);

	const timeKeyboardCell = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, t('reset'), 0, t('ok') ];
	let KEYBOARD_RESET = t('reset'),
		KEYBOARD_CONFIRM = t('ok');
	
	const handleClose = () => {
		count = 0;
		closeTimeCell();
	};

	const handleTimeClick = (keyboard) => {
		let newTime = time.split('');
		if (count === 0) {
			newTime = [ '-', '-', ':', '-', '-' ];
			newTime[count] = keyboard;
			count = 1;
		} else if (count === 1) {
			newTime[count] = keyboard;
			count = 3;
		} else if (count === 3) {
			newTime[count] = keyboard;
			count = 4;
		} else if (count === 4) {
			newTime[count] = keyboard;
			count = 0;
		}

		setTime(newTime.join(''));
	};

	const handleReSetTime = () => {
		const reset = [ '-', '-', ':', '-', '-' ];
		count = 0;
		setTime(reset.join(''));
	};

	const handleSubmit = () => {
		let check = true;
		let hour = parseInt(time.substr(0, 2), 10),
			min = parseInt(time.substr(3), 10);
		let multiple_five = min % 5;

		if (count !== 0 || time[0] === '-') {
			window.app.alert.setMessage(t('bookingTimesCommon.pls_enterCompleteTime'), 'tip');
			check = false;
			return;
		}

		if (hour > 23) {
			window.app.alert.setMessage(t('bookingTimesCommon.pls_invalidTimeFormat'), 'error');
			check = false;
			return;
		} else if (min > 59) {
			window.app.alert.setMessage(t('bookingTimesCommon.pls_invalidTimeFormat'), 'error');
			check = false;
			return;
		} else if (min <= 59 && multiple_five !== 0) {
			window.app.alert.setMessage(t('bookingTimesCommon.pls_minimumTimeUnit'), 'error');
			check = false;
			return;
		}

		if (check) {
      timeCellSubmit(time);
		}
	};

	const renderTimeKeyboardCell = () => {
		return timeKeyboardCell.map((keyboard, id) => {
			if (keyboard === KEYBOARD_RESET) {
				return (
					<div key={id} className="tKeyborad-body-cell" onClick={() => handleReSetTime()}>
						{keyboard}
					</div>
				);
			} else if (keyboard === KEYBOARD_CONFIRM) {
				return (
					<div key={id} className="tKeyborad-body-cell" onClick={() => handleSubmit()}>
						{keyboard}
					</div>
				);
			} else {
				return (
					<div key={id} className="tKeyborad-body-cell" onClick={() => handleTimeClick(keyboard)}>
						{keyboard}
					</div>
				);
			}
		});
	};

	return (
		<div className="n-timeCellWrapper">
			<div className="n-timeCellLayout" />

			<div className="tKeyborad">
				<div className="tKeyborad-header">
					{time}
					<span className="tKeyboard-del" onClick={() => handleClose()} />
				</div>
				<div className="tKeyborad-body">{renderTimeKeyboardCell()}</div>
			</div>
		</div>
	);
};

export default TimeKeyboard;
