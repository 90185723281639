import React, { useState, useCallback } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import CheckModal from "../popup/CheckModal";

import seated from "../../images/queue/btn-seated.svg";
import cancel from "../../images/queue/btn-cancel.svg";
import speaker from "../../images/queue/speaker-icon.svg";
import disabledSpeaker from "../../images/queue/speaker-disabled-icon.svg";
import message from "../../images/queue/message-icon.svg";
import sentMessage from "../../images/queue/sentMessage-icon.svg";
import seatIn from "../../images/queue/btn-seatIn-icon.svg";

import QueuePanelAPI from "./api/QueuePanelAPI";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const checkQueueNumber = (
  queueNumber,
  showConfirmModal,
  callback,
  groupName,
  waitingRecords
) => {
  if (!waitingRecords?.length) {
    callback();
    return;
  }

  const sameGroupRecords = waitingRecords.filter(
    (record) => record.group_name === groupName
  );

  const minWaitingNumber = Math.min(
    ...sameGroupRecords.map((record) => record.waiting_number)
  );

  if (queueNumber !== minWaitingNumber) {
    showConfirmModal();
    return;
  }

  callback();
};

// 報到
const QueueActionSeated = ({
  queueNumber,
  changeListItemToSeated,
  seatedLoading,
  lastSeatedNumber,
  groupName,
  waitingRecords,
}) => {
  const { t } = useTranslation('queueSystem');
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  return (
    <>
      <button
        className="queueActionButton"
        onClick={
          !lastSeatedNumber
            ? changeListItemToSeated
            : () => {
                checkQueueNumber(
                  queueNumber,
                  () => setShowConfirmModal(true),
                  changeListItemToSeated,
                  groupName,
                  waitingRecords
                );
              }
        }
        disabled={seatedLoading}
      >
        <img src={seated} style={seatedLoading ? { opacity: 0 } : {}} alt="" />
        <Spin
          indicator={antIcon}
          spinning={seatedLoading}
          style={{
            position: "absolute",
            left: "12px",
            top: "12px",
            color: "#3FBA87",
          }}
        />
      </button>
      {showConfirmModal && (
        <CheckModal
          title={t("popup.checkInConfirmation")}
          content={t("popup.checkInConfirmation_content", { number: queueNumber })}
          submit={() => {
            changeListItemToSeated();
            setShowConfirmModal(false);
          }}
          cancel={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};

// 過號
const QueueActionCancel = ({
  queueNumber,
  changeListItemToStandby,
  standbyLoading,
  position = 12,
  lastSeatedNumber,
  groupName,
  waitingRecords,
}) => {
  const { t } = useTranslation('queueSystem');
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  return (
    <>
      <button
        className="queueActionButton"
        onClick={
          !lastSeatedNumber
            ? changeListItemToStandby
            : () => {
                checkQueueNumber(
                  queueNumber,
                  () => setShowConfirmModal(true),
                  changeListItemToStandby,
                  groupName,
                  waitingRecords
                );
              }
        }
      >
        <img src={cancel} style={standbyLoading ? { opacity: 0 } : {}} alt="" />
        <Spin
          indicator={antIcon}
          spinning={standbyLoading}
          style={{
            position: "absolute",
            left: `${position}px`,
            top: `${position}px`,
            color: "#F6A622",
          }}
        />
      </button>
      {showConfirmModal && (
        <CheckModal
          title={t("popup.missedConfirmation")}
          content={t("popup.missedConfirmation_content", { number: queueNumber })}
          submit={() => {
            changeListItemToStandby();
            setShowConfirmModal(false);
          }}
          cancel={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};

// 叫號
const QueueActionCall = ({
  id,
  number,
  groupName,
  waitingRecords,
  lastSeatedNumber,
  refreshAction,
}) => {
  const { t } = useTranslation('queueSystem');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  
  const callNumber = useCallback(() => {
    const API = new QueuePanelAPI();

    setIsButtonDisabled(true);
    API.callQueueNumber(id)
      .then(() => {
        refreshAction();
      })
      .catch((err) => console.log('call queue number error', err))
      .finally(() => {
        setTimeout(() => setIsButtonDisabled(false), 5000);
      });
  }, [id, refreshAction]);

  return (
    <>
      <button
        className="queueActionButton"
        onClick={
          !lastSeatedNumber
            ? callNumber
            : () => {
                checkQueueNumber(
                  number,
                  () => setShowConfirmModal(true),
                  callNumber,
                  groupName,
                  waitingRecords
                );
              }
        }
        disabled={isButtonDisabled}
      >
        <img src={isButtonDisabled ? disabledSpeaker : speaker} alt="" />
      </button>
      {showConfirmModal && (
        <CheckModal
          title={t("popup.callConfirmation")}
          content={t("popup.callConfirmation_content", { number: number })}
          submit={() => {
            callNumber();
            setShowConfirmModal(false);
          }}
          cancel={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};

const QueueActionSeatIn = ({
  queueNumber,
  queueSeated,
  lastSeatedNumber,
  groupName,
  waitingRecords,
}) => {
  const { t } = useTranslation('queueSystem');
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  return (
    <>
      <button
        className="queueActionButton queueActionBtn-check"
        onClick={
          !lastSeatedNumber
            ? queueSeated
            : () => {
                checkQueueNumber(
                  queueNumber,
                  () => setShowConfirmModal(true),
                  queueSeated,
                  groupName,
                  waitingRecords
                );
              }
        }
      >
        <img src={seatIn} alt="" />
      </button>
      {showConfirmModal && (
        <CheckModal
          title={t("popup.checkInConfirmation")}
          content={t("popup.checkInConfirmation_content", { number: queueNumber })}
          submit={() => {
            queueSeated();
            setShowConfirmModal(false);
          }}
          cancel={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};

const QueueActionMessage = ({
  sendNotification,
  notificationLoading,
  hideAction,
  position = 12,
  isNotified,
}) => {
  return (
    <button
      className="queueActionButton"
      onClick={sendNotification}
      disabled={hideAction || notificationLoading}
    >
      <img
        src={isNotified ? sentMessage : message}
        style={notificationLoading ? { opacity: 0 } : {}}
        alt=""
      />
      <Spin
        indicator={antIcon}
        spinning={notificationLoading}
        style={{
          position: "absolute",
          left: `${position}px`,
          top: `${position}px`,
          color: "#4A90E2",
        }}
      />
    </button>
  );
};

export {
  QueueActionSeated,
  QueueActionCancel,
  QueueActionCall,
  QueueActionSeatIn,
  QueueActionMessage,
};
