import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router";
import QueueSystemAPI from "../queue_system_settings/QueueSystemAPI";
import $ from "jquery";
import { browserHistory } from "react-router";
import { useTranslation } from 'react-i18next';

const QueueIcon = () => {
	const { t } = useTranslation();
  const [activated, setActivated] = useState(true);
  const [asking, setAsking] = useState(true);

  const systemAPI = useRef(null);

  useEffect(() => {
    systemAPI.current = new QueueSystemAPI();

    systemAPI.current
      .getQueueSettings()
      .then((data) => {
        if (data.display_name === null || data.display_name === undefined) {
          setActivated(false);
        }
      })
      .catch((error) => {
        // Handle error if needed
      });
  }, []);

  const checkQueueSetting = () => {
    if (asking) {
      $("#startQueueModal").modal("toggle");
      setAsking(false);
    }
  };

  const doSome = () => {
    systemAPI.current.createQueueSettings((data) => {
      if (data !== undefined) {
        // console.log("開始使用{t('common:queue')}！");
      }
    });
    browserHistory.push("/dashboard/queue_system");
  };

  const doCancel = () => {
    setAsking(true);
  };

  return activated ? (
    <div className="dashboard-item">
      <Link to="/dashboard/queue_system" role="button">
        <div className="dashboard-thumbnail">
          <img alt="queue" src={require("../../images/dashboard/queue.svg")} />
					<div className="dashboard-system-description text-body">
            {t('dashboard:queueSystemDescription')}
          </div>
          <div className="horizontal-between">
            <h3 className="heading-3-responsive">{t('common:queue')}</h3>
            <div className="doubleArrowRight-icon icon-normal" />
          </div>
        </div>
      </Link>
    </div>
  ) : (
    <div className="dashboard-item" onClick={checkQueueSetting}>
      <Link to="" role="button">
        <div className="dashboard-thumbnail">
          <img alt="queue" src={require("../../images/dashboard/queue.svg")} />
					<div className="dashboard-system-description text-body">
            {t('dashboard:queueSystemDescription')}
          </div>
          <div className="horizontal-between">
            <h3 className="heading-3-responsive">{t('common:system.queue')}</h3>
            <div className="doubleArrowRight-icon icon-normal" />
          </div>
        </div>
      </Link>
      <div
        className="modal fade"
        id="startQueueModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="closeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content queue-modal-content">
            <h5 className="queue-modal-title" id="closeModalLabel">
              開始使用{t('common:queue')}
            </h5>
            <div className="queue-modal-body">是否開始使用{t('common:queue')}？</div>
            <div className="queue-modal-footer">
              <button
                type="button"
                className="queue-btn-primary"
                data-dismiss="modal"
                onClick={doSome}
              >
                確認
              </button>
              <button
                type="button"
                className="queue-btn-normal"
                data-dismiss="modal"
                onClick={doCancel}
              >
                取消
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueueIcon;
