import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { browserHistory } from "react-router";
import { useTranslation } from "react-i18next";

import BookingSystemApi from "../../booking_settings/BookingSystemApi";
import CheckModal from "../../popup/CheckModal";
import { bookingStatus, useInterval } from "../../new_module/utils/data";

const BookingAPI = new BookingSystemApi();

const BookingStatus = ({ handleUpdateStatus, booking }) => {
  const { t } = useTranslation("bookingSystem");
  const [currentTime, setCurrentTime] = useState(moment());
  const today = moment().format("YYYY/MM/DD");
  const date = moment(
    new Date(browserHistory.getCurrentLocation().query.date)
  ).format("YYYY-MM-DD");
  const isToday = today === date;
  const pre = moment(new Date(booking.booking_datetime))
    .add(-60, "m")
    .format("YYYY/MM/DD HH:mm");
  const tomorrow = moment(new Date(today))
    .add(1, "d")
    .format("YYYY/MM/DD HH:mm");

  const [delay, setDelay] = useState(isToday ? 1000 : null);
  const [bookingStatusBtn, setBookingStatusBtn] = useState(3); //1: 前一天 2:當天時間>預約60分鐘 3:當天時間<=預約60分鐘
  const [showWarning, setShowWarning] = useState(false);
  const [warningTales, setWarningTales] = useState([]);

  const findStatus = useCallback(() => {
    if (currentTime.isAfter(new Date(date), "date")) {
      //過去: 前一天
      //待確認 確認預約 取消預約 完成預約
      //不顯示 finish show
      setBookingStatusBtn(1);
    } else if (currentTime.isBefore(new Date(pre))) {
      //當天時間>預約60分鐘
      //待確認 確認預約 已到店 取消預約
      setBookingStatusBtn(2);
    } else if (
      currentTime.isBetween(new Date(pre), new Date(tomorrow), null, "[)")
    ) {
      //當天時間<=預約60分鐘
      //待確認 確認預約 已到店 取消預約 未到店 已入座 完成消費
      setBookingStatusBtn(3);
    }
  }, [currentTime, date, pre, tomorrow]);

  useEffect(() => {
    if (isToday) {
      setDelay(1000);
    } else {
      setDelay(null);
    }
    findStatus();
  }, [isToday, findStatus]);

  useInterval(() => {
    setCurrentTime(moment());
  }, delay);

  const seatedWarning = useCallback(() => {
    BookingAPI.checkSeated(booking.id).then((data) => {
      if (data.overlay_table.length !== 0) {
        setShowWarning(true);
        setWarningTales(data.overlay_table);
      } else {
        handleUpdateStatus("seated");
      }
    });
  }, [booking, handleUpdateStatus]);

  const cancelWarning = () => {
    setShowWarning(false);
    setWarningTales([]);
  };

  const confirmWarning = () => {
    handleUpdateStatus("seated");
    setShowWarning(false);
    setWarningTales([]);
  };

  const isHiddenDropdown =
    booking.status === "finish" ||
    booking.status === "no_show" ||
    booking.status === "cancel";

  const statusStyle = () => {
    let statusClass = "";
    if (booking.status) {
      switch (booking["status"]) {
        case "unconfirmed":
          statusClass = "res-pending";
          break;
        case "confirmed":
          statusClass = "res-success";
          break;
        case "cancel":
          statusClass = "res-cancel";
          break;
        case "finish":
          statusClass = "res-completed";
          break;
        case "no_show":
          statusClass = "res-noShow";
          break;
        case "show":
          statusClass = "res-arrived";
          break;
        case "seated":
          statusClass = "res-seated";
          break;
        default:
          statusClass = "";
      }
    }
    return statusClass;
  };

  const renderStatus = () => {
    const excludedStatuses = booking.pos_sync_required
    ? ["unconfirmed", "confirmed", "show", "seated", "no_show"]
    : [];
    const filteredStatus = bookingStatus.filter((b) => {
      if (bookingStatusBtn === 1) {
        return !["show", "no_show", "seated"].includes(b.status);
      }
      return true;
    });

    return (
      <ul
        className="dropdownMenu-booking-status dropdown-menu fix-dropdown-menu"
        aria-labelledby="status"
      >
        {filteredStatus.map((b) => {
          const isActive = booking.status === b.status;
          const statusClassName = `${b.status + "Status"} ${
            isActive ? "active" : ""
          } btn-effect`;

          const handleClick =
            b.status === "seated"
              ? seatedWarning
              : () => handleUpdateStatus(b.status);

          if (
            booking.status === "seated" &&
            excludedStatuses.includes(b.status)
          ) {
            return null;
          }

          return (
            <li
              key={b.status}
              className={statusClassName}
              onClick={handleClick}
            >
              <span>{t(`status.${b.status}`)}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <button
        className={`button-bookingStatus__dropDown ${statusStyle()}`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        disabled={isHiddenDropdown}
      >
        <span>{t(`status.${booking.status}`)}</span>
        {!isHiddenDropdown && <span className="icon-sm triangle-icon" />}
      </button>
      {renderStatus()}

      {showWarning && (
        <CheckModal
          title={t("popup.overlapping_title")}
          content={t("popup.overlapping_content", {
            tables: warningTales.join("、"),
          })}
          submit={confirmWarning}
          cancel={cancelWarning}
        />
      )}
    </>
  );
};

export default BookingStatus;
