import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, Space } from "antd";
import { browserHistory } from "react-router";

import { deleteCrmCustomer } from "../../api/CrmCustomerApi";

const CrmCustomerDeleteModal = ({
  id,
  setShowCustomerDeleteModal,
  handleEditCustomer,
}) => {
  const { t } = useTranslation("customer");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await deleteCrmCustomer(id);
      const url = window.location.pathname;
      const parent_url = url.split("/").slice(0, -1).join("/");

      handleEditCustomer();
      browserHistory.push(parent_url);
      window.app.alert.setMessage(t('popup.deleteCustomer_success'), "done");
    } catch (error) {
      window.app.alert.setMessage(error.response.message, "error");
    }
  };

  return (
    <Modal
      title={t('popup.deleteCustomer_title')}
      visible
      destroyOnClose
      width={374}
      centered
      closable={false}
      className="modal-base modal-sm"
      footer={
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={() => setShowCustomerDeleteModal(false)}
          >
            {t("settings:cancel")}
          </button>
          <button
            className="button-common button-primary"
            onClick={(e) => handleSubmit(e)}
          >
            {t("settings:delete")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {t('popup.deleteCustomer_content')}
    </Modal>
  );
};

export default CrmCustomerDeleteModal;
