import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { browserHistory } from "react-router";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import { withTranslation } from "react-i18next";

import BookingCalendar from "./BookingCalendar/BookingCalendar";
import BookingListView from "./BookingList/BookingListView";
import CrmCustomerCreateModal from "../crm/crmCustomer/crmCustomerPopup/CrmCustomerCreateModal";
import NewAddBookingView from "../AddNewBooking/NewAddBooking";

import { handleError } from "../../libs/handler";
import {
  handleWebsocketEventRegistry,
  cancelWebsocketSubscription,
} from "../../libs/handler";

import {
  handleAnnouncementListModal,
  updateDayAnnouncements,
  announcementFetchMonth,
  resetAnnouncementList,
} from "../../actions/announcementAction";

import BookingSystemApi from "../booking_settings/BookingSystemApi";

import DateModeSwitcher from "../commons/DateModeSwitcher";
import BookingModeAction from "./BookingModeAction";

const QUESTION = "question";

class BookingPanel extends Component {
  constructor(props) {
    super(props);
    if (
      this.props.location.query.mode == null ||
      this.props.location.query.date == null
    ) {
      browserHistory.push({
        pathname: "/dashboard/bookings",
        query: {
          date: new moment().format("YYYY-MM-DD"),
          mode: "list",
        },
      });
    }
    var mode = this.props.location.query.mode;
    if (mode === undefined) mode = "list";
    var queryDate = this.props.location.query.date;

    const isToday = moment().format("YYYY-MM-DD");
    const shortCutBtnBool = queryDate === isToday ? true : false;

    this.state = {
      bookings: [],
      filter: { date: queryDate },
      tlFilter: {},
      selectedBooking: {
        phone_number: "",
      },
      month: new moment(),
      monthCount: {},
      monthEffectiveCount: {},
      monthUnconfirmedCount: {},
      monthIneffectiveCount: {},
      monthAttendance: {},
      mode: mode,
      changeDateListenerInit: false,
      warnings: [],
      tables: [],
      disableDates: [],
      isDisableDateTxt: "",
      initBookings: [],
      reRender: false,
      isToday: isToday,
      show: shortCutBtnBool,
      newSelected: {},
      isHiddenNewBookingAddView: true,
      modalType: "addNew",
      diningTime: 0,
      serviceTimeOptionEnabled: false,
      customQ: [],
      customQAns: {},
      tempCustomQAns: {},
      autoTable: false,
      tableMapSetting: false,
      loading: false,
      activeBookingListStatusTab: "confirmed", // 預設：confirmed 確認預約
      spg_hash_iv: null,
      spg_hash_key: null,
      spg_merchant_id: null,
      merchant_verified: "",
      showAddCustomerModal: false,
      shop: {
        msg_point: 0,
      },
			pos_enabled: false,
			pos_settings_matched: false,
    };

    this.isDisableDate = this.isDisableDate.bind(this);
    this.isToday = this.isToday.bind(this);
    this.handleBookingListStatusTab =
    this.handleBookingListStatusTab.bind(this);

    this.latestRequestId = 0;
  }

	componentDidUpdate(prevProps) {
		let prev_mode = prevProps.location.query.mode,
			prev_date = prevProps.location.query.date;
		let history_query_mode = this.props.location.query.mode,
			history_query_date = this.props.location.query.date;

		if (
			prev_mode !== history_query_mode ||
			prev_date !== history_query_date
		) {
			this.setState({ mode: history_query_mode, filter: { date: history_query_date } }, () => {
				this.setState({ bookings: [], loading: true });
				this.updateData();
				this.isToday(history_query_date);

				if (history_query_mode === "calendar")
          this.props.announcementFetchMonth(
            moment(this.props.location.query.date).format("YYYY-MM")
          );
			});
		}
	}

  componentDidMount() {
    this.API = new BookingSystemApi();

    this.updateData();
    this.getCustomQuestion();
    this.getCreditEnabled();

    handleWebsocketEventRegistry(
      "notify",
      function (data) {
        setTimeout(
          function () {
            this.updateData();
          }.bind(this),
          3000
        );
      }.bind(this)
    );

		handleWebsocketEventRegistry(
			'refresh_booking_list',
			function() {
        const requestId = ++this.latestRequestId;

				setTimeout(
					function() {
						if (requestId === this.latestRequestId) this.updateData();
					}.bind(this),
					3000
				);
			}.bind(this)
		);

		this.getBookingSetting();
  }

  componentWillUnmount() {
    cancelWebsocketSubscription(["notify"]);
    cancelWebsocketSubscription(["refresh_booking_list_status_changed"]);
    this.props.resetAnnouncementList();
  }

  getCreditEnabled = () => {
    $.ajax({
      url: window.domain + "/dashboard/booking_settings/spg_data",
      method: "GET",
      xhrFields: { withCredentials: true },
    })
      .done(
        function (data) {
          this.setState({ merchant_verified: data.merchant_verified });
        }.bind(this)
      )
      .fail(function (xhr) {
        handleError(xhr);
      });
  };

  getCustomQuestion = () => {
    let { customQAns } = this.state;
    this.API.getQuestions().then((data) => {
      data.map((q) => {
        if (q.question_type === QUESTION) {
          customQAns[q.id] = "";
        } else {
          customQAns[q.id] = {};
        }
        return true;
      });
      this.setState({
        customQ: data,
        customQAns,
        tempCustomQAns: JSON.stringify(customQAns),
      });
    });
  };

  getBookings = (queryDate) => {
    if (queryDate === undefined) queryDate = this.props.location.query.date;

    this.API.getBookingsByDate(queryDate)
      .then((data) => {
        data.map((b) => {
          b.seatings = JSON.parse(b.seating_json);
          return b;
        });
        this.setState({
          bookings: data,
          initBookings: data,
          loading: false,
        });
      })
      .catch((error) => {
        handleError(error);
      });
  };

	getBookingSetting = () => {
    this.API.getBookingSetting()
      .then((data) => {
        this.setState(data);
        this.setState({
          init: true,
          disableDates: data.disable_dates,
          diningTime: data.dining_time,
          autoTable: data.auto_table_distribution,
          tableMapSetting: data.canvas_enabled,
          serviceTimeOptionEnabled: data.service_time_option_enabled,
          spg_hash_iv: data.spg_hash_iv,
          spg_hash_key: data.spg_hash_key,
          spg_merchant_id: data.spg_merchant_id,
        });
        this.isDisableDate(this.props.location.query.date);
      })
      .catch((error) => {
        handleError(error);
      });
	};

  getMonthEffectiveCount = () => {
    var month = moment(this.props.location.query.date, "YYYY-MM-DD").format(
      "YYYY/MM"
    );

    this.API.getMonthStatus(month, "effective")
      .then((data) => {
        var totalEffective = 0;
        for (var key in data) {
          totalEffective += data[key];
        }
        this.setState({
          monthEffectiveCount: data,
          monthTotalEffectiveCount: totalEffective,
        });
      })
      .catch((error) => {
        handleError(error);
      });
  };

  getMonthUnconfirmedCount = () => {
    var month = moment(this.props.location.query.date, "YYYY-MM-DD").format(
      "YYYY/MM"
    );

    this.API.getMonthStatus(month, "unconfirmed")
      .then((data) => {
        this.setState({
          monthUnconfirmedCount: data,
        });
      })
      .catch((error) => {
        handleError(error);
      });
  };

  getMonthIneffectiveCount = () => {
    var month = moment(this.props.location.query.date, "YYYY-MM-DD").format(
      "YYYY/MM"
    );

    this.API.getMonthStatus(month, "ineffective")
      .then((data) => {
        var totalIneffective = 0;
        for (var key in data) {
          totalIneffective += data[key];
        }
        this.setState({
          monthIneffectiveCount: data,
          monthTotalIneffectiveCount: totalIneffective,
        });
      })
      .catch((error) => {
        handleError(error);
      });
  };

  getMonthAttendance = () => {
    var month = moment(this.props.location.query.date, "YYYY-MM-DD").format(
      "YYYY/MM"
    );

    this.API.getMonthStatus(month, "attendance")
      .then((data) => {
        this.setState({
          monthAttendance: data,
        });
        var totalAttendance = 0;
        for (var key in data) {
          totalAttendance += data[key];
        }
        this.setState({
          monthAttendance: data,
          monthTotalAttendance: totalAttendance,
        });
      })
      .catch((error) => {
        handleError(error);
      });
  };

  updateData = () => {
    let mode = this.props.location.query.mode;
    if (mode === "calendar") {
      this.getMonthEffectiveCount();
      this.getMonthUnconfirmedCount();
      this.getMonthIneffectiveCount();
      this.getMonthAttendance();
    } else {
      this.getBookings();
    }

		if(this.state.pos_enabled) this.getBookingSetting();
	};

  updateAvailableTable = () => {
    this.refs.availableTableList.getAvailableTable(
      this.props.location.query.date
    );
  };

  showAddCustomerModal = (booking) => {
    this.setState({
      selectedBooking: booking,
      showAddCustomerModal: true,
    });
  };

  setStatus = (booking, newStatus, callback, status_time) => {
    let customerName = booking.last_name,
      customerGender = booking.gender_desc;
    let { date } = this.props.location.query;
    const { t } = this.props;

    const newState = fakeNewStatus({
      bookings: this.state.initBookings,
      tables: this.state.tables,
      booking,
      newStatus,
    });

    this.setState({
      initBookings: newState.newBookings,
      bookings: newState.newBookings,
      tables: newState.newTables,
    });

    if (newStatus === "confirmed") {
      window.app.alert.setMessage(
        customerName + customerGender + " " + t('status.confirmed'),
        "done"
      );
    } else if (newStatus === "no_show") {
      window.app.alert.setMessage(
        customerName + customerGender + " " + t('status.no_show'),
        "done"
      );
    } else if (newStatus === "show") {
      window.app.alert.setMessage(
        customerName + customerGender + " " + t('status.show'),
        "done"
      );
    } else if (newStatus === "seated") {
      window.app.alert.setMessage(
        customerName + customerGender + " " + t('status.seated'),
        "done"
      );
    } else if (newStatus === "finish") {
      window.app.alert.setMessage(
        customerName + customerGender + " " + t('status.finish'),
        "done"
      );
    } else {
      window.app.alert.setMessage(t('updateSuccess'), "done");
    }

    this.API.setBookingStatus(booking.id, newStatus, date, status_time)
      .then((data) => {
        if (callback && typeof callback === "function") callback();
      })
      .catch((error) => {
        const errorMsg = error.responseJSON?.errors || t("settings:status.pls_tryAgainLater");
        handleError(error);
        window.app.alert.setMessage(errorMsg, "error");
      });
  };
  addCustomerCallback = () => {
    this.getBookings();
  };
  changeViewMode = (mode) => {
    this.setState({ mode: mode }, () => {
      browserHistory.push({
        pathname: "/dashboard/bookings",
        query: {
          date: this.props.location.query.date,
          mode: mode,
        },
      });
      this.isDisableDate(this.props.location.query.date);
    });
  };

  handleBookingListStatusTab = (status) => {
    this.setState({ activeBookingListStatusTab: status });
  };

  renderBookingView = () => {
    switch (this.state.mode) {
      case "list":
        return (
          <BookingListView
            scrollToActiveCode={this.props.location.state}
            shortCutBtn={this.state.show}
            bookings={this.state.bookings}
            setStatus={this.setStatus}
            showAddCustomerModal={this.showAddCustomerModal}
            showNewAddBookingModal={this.showNewAddBookingModal}
            getBookings={this.getBookings}
            customQ={this.state.customQ}
            loading={this.state.loading}
            updateData={this.getBookings}
            handleBookingListStatusTab={this.handleBookingListStatusTab}
            activeBookingListStatusTab={this.state.activeBookingListStatusTab}
            serviceTimeOptionEnabled={this.state.serviceTimeOptionEnabled}
          />
        );
      case "calendar":
      default:
        return (
          <BookingCalendar
            renderIsToday={this.isToday}
            renderDisableDate={this.isDisableDate}
            disableDates={this.state.disableDates}
            monthEffectiveCount={this.state.monthEffectiveCount}
            monthUnconfirmedCount={this.state.monthUnconfirmedCount}
            monthIneffectiveCount={this.state.monthIneffectiveCount}
            monthAttendance={this.state.monthAttendance}
            selected={moment(this.props.location.query.date, "YYYY-MM-DD")}
            changeViewMode={this.changeViewMode}
            monthTotalEffectiveCount={this.state.monthTotalEffectiveCount}
            monthTotalAttendance={this.state.monthTotalAttendance}
          />
        );
    }
  };

  calPrevious = () => {
    let search = window.location.search.substring(1);
    let params = JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
    if (this.state.mode === "calendar") {
      var month = moment(this.props.location.query.date, "YYYY-MM-DD");
      month.add(-1, "M");
      browserHistory.push({
        pathname: "/dashboard/bookings",
        query: {
          date: month.format("YYYY-MM-DD"),
          mode: "calendar",
        },
      });
    } else {
      this.setState({ bookings: [], loading: true });

      var date = moment(this.props.location.query.date);
      date.add(-1, "days");
      browserHistory.push({
        pathname: "/dashboard/bookings",
        query: {
          date: date.format("YYYY-MM-DD"),
          mode: params.mode,
        },
      });
      this.isDisableDate(date.format("YYYY-MM-DD"));
    }
    this.setState({ activeBookingListStatusTab: "confirmed" });
  };

  calNext = () => {
    let search = window.location.search.substring(1);
    let params = JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
    if (this.state.mode === "calendar") {
      var month = moment(this.props.location.query.date, "YYYY-MM-DD");
      month.add(1, "M");
      browserHistory.push({
        pathname: "/dashboard/bookings",
        query: {
          date: month.format("YYYY-MM-DD"),
          mode: "calendar",
        },
      });
    } else {
      this.setState({ bookings: [], loading: true });

      var date = moment(this.props.location.query.date);
      date.add(1, "days");
      browserHistory.push({
        pathname: "/dashboard/bookings",
        query: {
          date: date.format("YYYY-MM-DD"),
          mode: params.mode,
        },
      });
      this.isDisableDate(date.format("YYYY-MM-DD"));
    }
    this.setState({ activeBookingListStatusTab: "confirmed" });
  };

  changeUrlDate = (date) => {
    let search = window.location.search.substring(1);
    let params = JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
    let queryDate = this.props.location.query.date;

    if(date === queryDate) return;
    this.setState({ bookings: [], loading: true });
    browserHistory.push({
      pathname: "/dashboard/bookings",
      query: {
        date: date,
        mode: params.mode,
      },
    });
    this.isDisableDate(date);
    this.setState({ activeBookingListStatusTab: "confirmed" });
  }

  handlePicker = (date) => {
    if (this.state.mode === "calendar") {
      browserHistory.push({
        pathname: "/dashboard/bookings",
        query: {
          date: date.format("YYYY-MM-DD"),
          mode: "calendar",
        },
      });
    } else {
      browserHistory.push({
        pathname: "/dashboard/bookings",
        query: {
          date: date.format("YYYY-MM-DD"),
          mode: this.props.location.query.mode,
        },
      });
    }
  };

  calSelect = (day) => {
    this.setState({ selected: day.date });
    this.forceUpdate();
  };

  isDisableDate = (filterDate) => {
    let { disableDates } = this.state;
    let txt;
    disableDates.sort();

    for (let date = 0; date < disableDates.length; date++) {
      if (disableDates[date] === filterDate) {
        txt = "本日為不可預約日";
        this.setState({
          isDisableDateTxt: txt,
        });
        return txt;
      } else {
        txt = "";
        this.setState({
          isDisableDateTxt: txt,
        });
      }
    }
  };

  isToday(queryDate) {
    let { isToday } = this.state;
    if (queryDate === isToday) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }

  showNewAddBookingModal = (booking, type) => {
    let bodyEle = document.getElementsByTagName("body");
    if (type === "editor") {
      bodyEle[0].classList.add("modal-open");
      this.setState({
        isHiddenNewBookingAddView: false,
        newSelected: booking,
        modalType: "editor",
      });
    } else {
      bodyEle[0].classList.add("modal-open");
      this.setState({
        isHiddenNewBookingAddView: false,
        modalType: "addNew",
      });
    }
  };

  hideNewAddBookingModal = () => {
    let { tempCustomQAns } = this.state;
    let bodyEle = document.getElementsByTagName("body");
    bodyEle[0].classList.remove("modal-open");
    this.setState({
      isHiddenNewBookingAddView: true,
      customQAns: JSON.parse(tempCustomQAns),
    });
  };

  render() {
    let { shop } = this.state;
    return (
      <div className="bg-white system-layout-content">
        <DateModeSwitcher
          mode={this.state.mode}
          calPrevious={this.calPrevious}
          calNext={this.calNext}
          handlePicker={this.handlePicker}
					isDudooEnable={this.state.pos_enabled}
					isDudooSync={this.state.pos_settings_matched}
          changeUrlDate={this.changeUrlDate}
        />
        <div className="system-layout-with-date system-container">
          <div id="calendar" />
          <BookingModeAction
            mode={this.state.mode}
            monthTotalEffectiveCount={this.state.monthTotalEffectiveCount}
            monthTotalAttendance={this.state.monthTotalAttendance}
            smsCredits={shop.msg_point}
            showNewAddBookingModal={this.showNewAddBookingModal}
          />
          {this.renderBookingView()}
        </div>

        {this.state.showAddCustomerModal && (
          <CrmCustomerCreateModal
            selectedBooking={this.state.selectedBooking}
            load={this.addCustomerCallback}
            cancelPopup={() => this.setState({ showAddCustomerModal: false })}
          />
        )}

        {!this.state.isHiddenNewBookingAddView && (
          <NewAddBookingView
            autoTable={this.state.autoTable}
            hideNewAddBookingModal={this.hideNewAddBookingModal}
            updateData={this.updateData}
            modalType={this.state.modalType}
            date={this.props.location.query.date}
            diningTime={this.state.diningTime}
            newSelected={this.state.newSelected}
            customQAns={this.state.customQAns}
            customQ={this.state.customQ}
            msgPoint={shop.msg_point}
            spg_hash_iv={this.state.spg_hash_iv}
            spg_hash_key={this.state.spg_hash_key}
            spg_merchant_id={this.state.spg_merchant_id}
            merchant_verified={this.state.merchant_verified}
          />
        )}
        <div id="modalPortal" />
      </div>
    );
  }
}

const fakeNewStatus = ({ bookings, tables, booking, newStatus }) => {
  const newBookings = _.cloneDeep(bookings);
  const newTables = _.cloneDeep(tables);
  const parseStatusTime = JSON.parse(booking.status_time);
  const bookingStatusObj = {
    unconfirmed: "待確認",
    confirmed: "確認預約",
    show: "已到店",
    seated: "已入座",
    finish: "完成消費",
    no_show: "未到店",
    cancel: "取消預約",
  };

  if (newStatus === "seated") {
    const bookingTime_min = moment().minute();
    const diff = 5 - (bookingTime_min % 5);
    const seatedTime = moment().add(diff, "m").format("HH:mm");
    parseStatusTime[newStatus] = seatedTime;
  } else {
    parseStatusTime[newStatus] = moment().format("HH:mm");
  }

  newBookings.forEach((findBooking) => {
    if (findBooking.id === booking.id) {
      findBooking.status = newStatus;
      findBooking.status_t = bookingStatusObj[newStatus];
      findBooking.status_time = JSON.stringify(parseStatusTime);
    }
  });

  newTables.forEach((table) => {
    table.timeline.forEach((findBooking) => {
      if (findBooking.id === booking.id) {
        findBooking.status = newStatus;
        findBooking.status_t = bookingStatusObj[newStatus];
        findBooking.status_time = JSON.stringify(parseStatusTime);
      }
    });
  });

  return { newBookings, newTables };
};

const mapStateToProps = (state) => ({
  announcementListReducer: state.announcementListReducer,
  addAnnouncementReducer: state.addAnnouncementReducer,
});

const mapDispatchToProps = (dispatch) => ({
  handleAnnouncementListModal: bindActionCreators(
    handleAnnouncementListModal,
    dispatch
  ),
  updateDayAnnouncements: bindActionCreators(updateDayAnnouncements, dispatch),
  announcementFetchMonth: bindActionCreators(announcementFetchMonth, dispatch),
  resetAnnouncementList: bindActionCreators(resetAnnouncementList, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("bookingSystem")(BookingPanel));
