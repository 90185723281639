import React from 'react';
import { browserHistory } from 'react-router';
import { Modal } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import './menuAppIntro.scss';

const itunes_link = 'https://itunes.apple.com/tw/app/menu%E7%BE%8E%E9%A3%9F%E8%AA%8C-%E7%BE%8E%E9%A3%9F%E8%A8%98%E9%8C%84-%E4%BD%A0%E6%88%91%E5%88%86%E4%BA%AB/id976520817?mt=8';
const googleStore_link = 'https://play.google.com/store/apps/details?id=com.findlife.menu&hl=zh_TW';

export default ({ visible, handleMenuAppIntroModal, source }) => {
  const { t, i18n } = useTranslation('settings');

	const goDownload_iOS = () => {
		window.open(itunes_link, '_blank')
	};

	const goDownload_Android = () => {
		window.open(googleStore_link, '_blank')
	};
	
	const goToBindMenuUser = () => {
    browserHistory.push({
      pathname: '/dashboard/menu_user',
      state: {
        source
      }
    });
  };

	return (
		<Modal
			destroyOnClose
			centered
			visible={visible}
			wrapClassName="menuAppIntroModal"
			footer={null}
			title={
				<div>
					{t('accountSettings.menu.additionalInformation')}
					<div className='btn_close' onClick={handleMenuAppIntroModal}></div>
				</div>
			}
			onCancel={handleMenuAppIntroModal}
			transitionName="ant-move-down"
		>
			<div className="menuAppIntroModalBody">
				<div className="menuAppIntro_block_1">
					<img src={require('../../../images/menuUser/Web_MENUXMENULogo.svg')} alt="" />
					<div>{t('accountSettings.menuIntro.text_1')}</div>
					<div>{t('accountSettings.menuIntro.text_2')}</div>
					<img src={require('../../../images/menuUser/Web_AboutMENU_CoverPhoto.svg')} alt="" />
				</div>

				<div className="menuAppIntro_block_2">
					<img src={require('../../../images/menuCoupon/menuAppIcon.png')} alt="" />
					{
						i18n.language === 'zh'
						? (
							<>
								<div>MENU 美食誌</div>
								<div>
									不只是美食社群 App
									<br />
									<strong>MENU 美食誌</strong> 讓您與顧客串連在一起
								</div>
							</>
						) : <div>{t('accountSettings.menuIntro.menu')}</div>
					}
				</div>

				<div className="menuAppIntro_block_3">
					<div className="menuAppIntro_block_title">
						{t('accountSettings.menuIntro.text_3')}
					</div>
					<div className="menuAppIntro_box">
						<div className="menuAppIntro_box_img">
							<img src={require('../../../images/menuUser/Web_AboutMENU_VoucherPage.svg')} alt="" />
						</div>
						<div className="menuAppIntro_box_content">
							<div>{t('accountSettings.menuIntro.text_4')}</div>
							<div>
								<Trans i18nKey="settings:accountSettings.menuIntro.text_5">
									您可以將您在 <strong>MENU 店＋</strong> 優惠券專區建立好的優惠內容上架至 <strong>MENU 美食誌</strong>，分享你的最新優惠內容。
								</Trans>
							</div>
						</div>
					</div>
					<div className="menuAppIntro_box">
						<div className="menuAppIntro_box_content">
							<div>{t('accountSettings.menuIntro.text_6')}</div>
							<div>
								<Trans i18nKey="settings:accountSettings.menuIntro.text_7">
									現在，你可以使用 <strong>店＋ CRM 功能</strong>，精準篩選您想推播的指定客群。傳送圖片、優惠券以及文字內容給你的粉絲。
								</Trans>
							</div>
						</div>
						<div className="menuAppIntro_box_img">
							<img src={require('../../../images/menuUser/Web_AboutMENU_MENUMessagePage.svg')} alt="" />
						</div>
					</div>
					<div className="menuAppIntro_box">
						<div className="menuAppIntro_box_img">
							<img src={require('../../../images/menuUser/Web_AboutMENU_RestaurantPage.svg')} alt="" />
						</div>
						<div className="menuAppIntro_box_content">
							<div>{t('accountSettings.menuIntro.text_8')}</div>
							<div>
								<Trans i18nKey="settings:accountSettings.menuIntro.text_9">
									您可以在 <strong>MENU 店＋</strong> 後台更新您的餐廳資訊，最新的資訊將同步至 <strong>MENU 美食誌</strong> 的餐廳頁面。
								</Trans>
							</div>
						</div>
					</div>
					<div className="menuAppIntro_box">
						<div className="menuAppIntro_box_content">
							<div>{t('accountSettings.menuIntro.text_10')}</div>
							<div>
								<Trans i18nKey="settings:accountSettings.menuIntro.text_11">
									開始為你的餐廳建立 <strong>MENU 美食誌帳號</strong> 吧！
								</Trans>
								<br />
								{t('accountSettings.menuIntro.text_12')}
							</div>
						</div>
						<div className="menuAppIntro_box_img">
							<img src={require('../../../images/menuUser/Web_AboutMENU_ProfilePage.svg')} alt="" />
						</div>
					</div>
				</div>

				<div className="menuAppIntro_block_4">
					<img src={require('../../../images/menuCoupon/menuAppIcon.png')} alt="" />
					{
						i18n.language === 'zh'
						? (
							<>
								<div>立即下載 MENU 美食誌</div>
								<div>
									不只是美食 App，MENU 美食誌
									<br />
									讓您與顧客串連在一起
								</div>
							</>
							)
						: <div>Download now</div>
					}
					<img src={require('../../../images/menuUser/Web_AboutMENU_DownloadAppQRCode.svg')} alt="" />
					<div className='intro__text-5'>
						<Trans i18nKey="settings:accountSettings.menuIntro.scanQRCode">
							掃描 QR Code，<strong>下載 MENU 美食誌</strong>
						</Trans>
					</div>
					<div>
						<button className="download_btn" onClick={goDownload_iOS}>
							{t('accountSettings.menuIntro.iOS')}
						</button>
						<button className="download_btn" onClick={goDownload_Android}>
							{t('accountSettings.menuIntro.android')}
						</button>
					</div>
					<img className='img__7' src={require('../../../images/menuUser/Web_MENUXMENULogo.svg')} alt="" />
					{
						i18n.language === 'zh'
						? (
								<>
									<div className='intro__text-8'>綁定 MENU 美食誌</div>
									<div>現在開始與顧客建立關係</div>
								</>
							)
						: <div className='intro__text-8'>Connect with MENU</div>
					}
					<button className="bind_btn" onClick={() => goToBindMenuUser()}>{t('accountSettings.menu.connect')}</button>
				</div>
			</div>
		</Modal>
	);
};
