import React from 'react';
import { Link } from 'react-router';

import logo from '../../images/menuShop_logo.svg';
import '../../sass/PageFooter.scss';

const menuUrl = 'https://menutaiwan.com/tw';
const menuBlogUrl = 'https://findlife.com.tw/menu/blog/';
const menuFacebookUrl = 'https://www.facebook.com/MENUTaiwan/';
const menuInstagramUrl = 'https://www.instagram.com/menutaiwan';

const PageFooter = () => {
	return (
    <footer>
      <div className="main_content">
        <div className="info">
          <img src={logo} alt="logo" />
        </div>
        <div className="content">
          <div className="feature">
            <h5>產品特色</h5>
            <ul>
              <li>
                <Link to="/case">應用場景</Link>
              </li>
              <li>
                <Link to="/booking_system">預約系統</Link>
              </li>
              <li>
                <Link to="/queue_system">排隊系統</Link>
              </li>
              <li>
                <Link to="/menu_coupon">優惠券系統</Link>
              </li>
              <li>
                <Link to="/crm_system">顧客管理系統</Link>
              </li>
              <li>
                <Link to="/announcement_system">公告系統</Link>
              </li>
            </ul>
          </div>
          <div className="menu">
            <h5>MENU 美食誌</h5>
            <div>
              <a href={menuUrl} target="_blank" rel="noopener noreferrer">
                <div className="menu_logo" />
              </a>
              <a href={menuBlogUrl} target="_blank" rel="noopener noreferrer">
                <div className="blog" />
              </a>
            </div>
            <div>
              <a
                href={menuFacebookUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="facebook" />
              </a>
              <a
                href={menuInstagramUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="instagram" />
              </a>
            </div>
          </div>
          <div className="contactUs">
            <h5>聯絡我們</h5>
            <div className="contactUs_block">
              <img src={require("../../images/page_footer/Phone.svg")} alt="" />
              <div>
                +886-02-25569075
                <br />
                <div style={{ fontSize: "12px" }}>
                  （週一至週五09:00-18:00）
                </div>
              </div>
            </div>
            <div className="contactUs_block">
              <img src={require("../../images/page_footer/Email.svg")} alt="" />
              <a href="mailto:support@findlife.com.tw">
                support@findlife.com.tw
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="company">
        找活股份有限公司版權所有
        <br />
        <span className="copyright">
          <span role="img" aria-label="copyright">
            ©️
          </span>
          2016 FindLife Lnc. All rights reserved.
        </span>
        <span className="tos">
          <Link to="/privacyPolicy">隱私權條款</Link>
          <span>|</span>
          <Link to="/termsOfService">服務條款</Link>
        </span>
      </div>
    </footer>
  );
};

export default PageFooter;
