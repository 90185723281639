import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAlertMessage } from '../../actions/flashMessage';



class FlashMessage extends Component {
  constructor(props){
    super(props);
    this.setMessage = this.setMessage.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setAlertMessage("", ""));
    //暫時設成全域，其他地方還沒改
    window.app.alert = this;
  }

  setMessage(message, type) {
    const { dispatch } = this.props;
    //清除內容使CSS動畫重新啟動
    dispatch(setAlertMessage("", ""));
    setTimeout(function(){
      dispatch(setAlertMessage(message, type));
    }, 100);
  }

  render() {
    const { message, messageType } = this.props;
    if(message !== "") {
      return (
        <div ref={this.props.forwardRef} className={'m-alert m-alert-'+messageType} role="alert">
          <span className="sr-only"></span>
          {message}
        </div>
      );
    } else {
      return <div/>;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
		message: state.flashMessage.message,
		messageType: state.flashMessage.messageType
	};
};
// const mapDispatchToProps = (dispatch, ownProps) => {
//   return {
//     setMessage1: (message, type) => {
//       //清除內容使CSS動畫重新啟動
//       dispatch(setAlertMessage("", ""));
//       setTimeout(function(){
//         dispatch(setAlertMessage(message, type));
//       }.bind(this), 100);
//     }
//   }
// }
export default connect(mapStateToProps)(FlashMessage);
