// 星期
export const dayList = ["日", "一", "二", "三", "四", "五", "六"];
export const dayListFull = [
  "星期日",
  "星期一",
  "星期二",
  "星期三",
  "星期四",
  "星期五",
  "星期六",
];
export const dayListAbbrevEN = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];
export const dayListFullEN = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// 月份
export const monthAbbrevEN = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// 數字
export const chineseNumerals = [
  "〇",
  "一",
  "二",
  "三",
  "四",
  "五",
  "六",
  "七",
  "八",
  "九",
];
export const numerals = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

// 時間
export const hourList = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];
export const hourArray = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
export const minList = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
export const minArray = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
];
export const bookingTimeRangeOptions = [5, 10, 15, 20, 30, 45, 60];
export const bookingIntervalTime = [5, 10, 15, 20, 30, 50, 60];

// 預約狀態
export const bookingStatus = {
  unconfirmed: "待審核",
  confirmed: "確認預約",
  cancel: "取消預約",
  show: "已到店",
  no_show: "未到店",
  seated: "已入座",
  finish: "結帳完成",
};
export const bookingStatusEN = {
  unconfirmed: "Pending",
  confirmed: "Confirmed",
  cancel: "Canceled",
  show: "Arrived",
  no_show: "No Show",
  seated: "Seated",
  finish: "Finished",
};
export const bookingStatuses = [
  "unconfirmed",
  "confirmed",
  "cancel",
  "show",
  "no_show",
  "seated",
  "finish"
];

// 預約操作狀態
export const OUTCOME_TYPES = {
  TYPE_1: 'tableRes_1', // 請選擇桌位
  TYPE_2: 'tableRes_2', // 已選桌位
  TYPE_3: 'tableRes_3', // 該時段找不到可安排的桌位,
};

export const BOOKING_TIME_TYPES = {
  TYPE_1: "bookingTimeTxt_1", // 請先選擇預約人數
  TYPE_2: "bookingTimeTxt_2", // 請選擇預約時間
  TYPE_3: "bookingTimeTxt_3", // 無可預約的時間
};

export const SEARCHING = "searching";

// 排隊狀態
export const queueStatus = {
  waiting: "等待中",
  standby: "過號",
  canceled: "取消",
};
export const queueStatusEN = {
  waiting: "Waiting",
  standby: "Missed",
  canceled: "Canceled",
};
