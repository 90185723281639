import React, { useState } from "react";
import { Space } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

import verifiedIcon from "../../images/icon_verified.svg";
import syncIcon from "../../images/sync-icon.svg";
import unsyncIcon from "../../images/unsync-icon.svg";

import { bookingRetryDuDooSync } from "../../api/posSync";

import "../../sass/dudooPosSync.scss";

const DudooSyncDisplay = ({ mode, booking, updateData }) => {
  const { t } = useTranslation("bookingSystem");
  const [isRetrying, setIsRetrying] = useState(false);
  const spaceSize = mode === "list" ? 8 : 4;

  const SYNC_MESSAGES = {
    FAIL: "無法建立Dudoo預約.",
    SEATS_MISMATCH: "座位設定與肚肚不一致",
  };

  const isSyncFailed = (message) =>
    [SYNC_MESSAGES.FAIL, SYNC_MESSAGES.SEATS_MISMATCH].includes(message);

  const handleRetry = async () => {
    setIsRetrying(true);

    try {
      const { data } = await bookingRetryDuDooSync(booking.id);
      const isSuccess = !isSyncFailed(data.message);

      if (isSuccess) {
        updateData();
      }

      window.app.alert.setMessage(
        isSuccess
          ? t("dudoo.pls_syncedSuccessfully")
          : t("dudoo.pls_syncedFailed"),
        isSuccess ? "done" : "error"
      );
    } catch (error) {
      window.app.alert.setMessage(t("dudoo.pls_syncedFailed"), "error");
    } finally {
      setIsRetrying(false);
    }
  };

  const renderDudooSyncTime = () => {
    const syncTime = booking?.pos_sync_info?.time
      ? moment(booking.pos_sync_info.time).format("YYYY/MM/DD HH:mm")
      : null;

    if (!booking.pos_sync_failed) {
      return (
        <Space size={spaceSize} className="syncTime success_text">
          {t("dudoo.syncedWithDudoo")}
          {syncTime}
          <img src={verifiedIcon} alt="" />
        </Space>
      );
    } else {
      return (
        <Space size={spaceSize}>
          <div>
            <div className="syncTime error_text">
              {t("dudoo.failToSyncWithDudoo")}
              {syncTime}
            </div>
          </div>
          {booking.status !== "cancel" && (
            <button
              className="button-dudoo-retry"
              onClick={handleRetry}
              disabled={isRetrying}
            >
              <div className={`icon-retry ${isRetrying ? "loading" : ""}`} />
            </button>
          )}
        </Space>
      );
    }
  };

  if (!booking.pos_sync_required) return null;
  return <div className="dudooSyncBlock">{renderDudooSyncTime()}</div>;
};

const DudooSyncStatus = ({ isDudooSync = false }) => {
  const { t } = useTranslation("bookingSystem");
  return (
    <div className="dudooSyncStatusFlag">
      <Space
        className={`dudooSyncStatus ${
          isDudooSync ? "dudooSyncStatus-success" : "dudooSyncStatus-fail"
        }`}
        size={4}
      >
        <img src={isDudooSync ? syncIcon : unsyncIcon} alt="" />
        {isDudooSync ? t("dudoo.synchronizing") : t("dudoo.inconsistent")}
      </Space>
    </div>
  );
};

export { DudooSyncDisplay, DudooSyncStatus };
