import React, { useState, useContext } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { useInterval } from "./hooks/useInterval";
import { BaseSettingContext } from "../new_module/NewModuleReducer/BaseSettingReducer";
import { queueStatus, queueStatusEN } from "../../utils/constants";

export default ({ queue, toList }) => {
  const { t, i18n } = useTranslation("queueSystem");
  const { BaseSettingState } = useContext(BaseSettingContext);
  const { timeChecking } = BaseSettingState;

  const getEstimatedWaitingTime = (waitingGroups) => {
    let timeCheckingConditions = [];
    try {
      timeCheckingConditions = JSON.parse(timeChecking);
    } catch (e) {
      // console.log('Parse condition failed');
    }

    if (
      Array.isArray(timeCheckingConditions) &&
      timeCheckingConditions.length > 1
    ) {
      timeCheckingConditions.sort(
        (first, second) => first.count - second.count
      );
    }

    let estimatedWaitingTime = 0;

    for (let i = 0; i < timeCheckingConditions.length; i++) {
      const element = timeCheckingConditions[i];
      if (waitingGroups <= element.count) {
        if (i === 0) {
          if (waitingGroups === element.count) {
            estimatedWaitingTime = element.time;
          } else {
            estimatedWaitingTime = t("item.less") + element.time;
          }
        } else {
          const prevElement = timeCheckingConditions[i - 1];
          estimatedWaitingTime = prevElement.time;
        }
        break;
      } else {
        const prevElement = timeCheckingConditions[i];
        estimatedWaitingTime = prevElement.time;
      }
    }

    return estimatedWaitingTime;
  };

  return (
    <div
      className="bookingCard queueCard"
      onClick={() =>
        toList(moment(queue.created_at).format("YYYY-MM-DD"), queue)
      }
    >
      <div className="queueCardTop">
        <span className={`statusTag statusTag-${queue.status}`}>
          {i18n.language === "zh"
            ? queueStatus[queue.status]
            : queueStatusEN[queue.status]}
        </span>
        <span className="groupName">{queue.group_name}</span>
      </div>
      <div className="queueCardContent">
        <div className="infoFlex">
          <div className="infoLeft">
            <h2>
              <span>
                {queue.gender && i18n.language !== "zh"
                  ? t(`option.${queue.gender}`)
                  : null}
              </span>
              {queue.name}
              <span>
                {queue.gender && i18n.language === "zh"
                  ? t(`option.${queue.gender}`)
                  : null}
              </span>
            </h2>
            <div className="attendance">
              <span>{queue.people_count}</span>
              <span>{t("item.person")}</span>
            </div>
          </div>
          <div className="infoRight infoRight-waiting">
            {queue.waiting_number}
          </div>
        </div>
      </div>
      <div className="bottom">
        <div>{queue.phone}</div>
        <div className="extra">
          <div>
            {t("item.ahead")} {queue.waiting_groups}
            {i18n.language === "zh" ? "組" : ""}
          </div>
          <div className="code">
            {t("item.est")} {getEstimatedWaitingTime(queue.waiting_groups)}{" "}
            {t("item.minutes")}
            <WaitingTimeCount queue={queue} />
          </div>
        </div>
      </div>
    </div>
  );
};

const WaitingTimeCount = ({ queue }) => {
  const { t } = useTranslation("queueSystem");
  const [currentStamp, setCurrentStamp] = useState(moment());
  const [interval] = useState(queue.waiting_time === null ? 1000 : null);

  useInterval(() => {
    setCurrentStamp(moment());
  }, interval);

  return (
    <span className="waitingTimeCount">
      {t("item.waited")}
      <span className="queueStatus-alert">
        {queue.waiting_time === null
          ? currentStamp.diff(moment(queue.created_at), "minutes")
          : queue.waiting_time}
      </span>
      {t("item.minutes")}
    </span>
  );
};
