import React from "react";
import moment from "moment";
import { withTranslation, useTranslation } from "react-i18next";
import {
  dayList,
  dayListAbbrevEN,
  hourArray,
  minArray,
} from "../../../utils/constants";

import AnnouncementCard from "../../announcement/AnnouncementCard";

class Datepicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeYear: this.props.tempBookingDate.slice(0, 4),
      activeMonth: this.props.tempBookingDate.slice(5, 7),
      tempBookingDate: this.props.tempBookingDate,
      tempBookingTimeHour: this.props.tempBookingTimeHour,
      tempBookingTimeMin: this.props.tempBookingTimeMin,
      availableTime: this.props.availableTime,
      loadingTime: this.props.loadingTime,
      filterCombinationTables: this.props.filterCombinationTables,
    };

    this.createRows = this.createRows.bind(this);
    this.handlePreMonth = this.handlePreMonth.bind(this);
    this.handleNextMonth = this.handleNextMonth.bind(this);
    this.renderBookingTimeDisplay = this.renderBookingTimeDisplay.bind(this);
  }

  componentDidMount() {
    this.props.updateDayAnnouncements(this.props.date);
  }

  componentDidUpdate(prevProps, preState) {
    const {
      tempBookingDate,
      tempBookingTimeHour,
      tempBookingTimeMin,
      availableTime,
      filterCombinationTables,
    } = this.props;

    if (
      tempBookingDate !== preState.tempBookingDate ||
      tempBookingTimeHour !== preState.tempBookingTimeHour ||
      tempBookingTimeMin !== preState.tempBookingTimeMin ||
      availableTime !== preState.availableTime
    ) {
      this.setState({
        tempBookingDate,
        tempBookingTimeHour,
        tempBookingTimeMin,
        availableTime,
      });
    }

    if (filterCombinationTables !== preState.filterCombinationTables) {
      this.setState({
        filterCombinationTables,
      });
    }
  }

  componentWillUnmount() {
    this.props.updateDayAnnouncements(this.props.date);
  }

  handlePreMonth() {
    let { activeYear, activeMonth } = this.state;

    activeYear = parseInt(activeYear, 10);
    activeMonth = parseInt(activeMonth, 10);

    if (activeMonth === 1) {
      activeYear -= 1;
      activeMonth = 12;
    } else {
      activeMonth -= 1;
    }

    this.setState({ activeYear: activeYear, activeMonth: activeMonth });
  }

  handleNextMonth() {
    let { activeYear, activeMonth } = this.state;

    activeYear = parseInt(activeYear, 10);
    activeMonth = parseInt(activeMonth, 10);

    if (activeMonth === 12) {
      activeYear += 1;
      activeMonth = 1;
    } else {
      activeMonth += 1;
    }

    this.setState({ activeYear: activeYear, activeMonth: activeMonth });
  }

  createRows(year, month) {
    let createTable = [];

    year = parseInt(year, 10);
    month = parseInt(month, 10);

    const firstday = new Date(year, month - 1, 1),
      dayOfWeek = firstday.getDay(),
      days_per_month = new Date(year, month, 0).getDate();

    const days_per_lastMonth = new Date(year, month - 1, 0).getDate();
    let chosenDateStyle = "";

    const str_nums = Math.ceil((dayOfWeek + days_per_month) / 7);

    const { tempBookingDate } = this.state;

    const handleChooseDate = (e, cell) => {
      const date = moment(cell).format("YYYY-MM-DD");
      this.props.chooseDate(e, cell);
      this.props.updateDayAnnouncements(date);
    };

    for (let i = 0; i < str_nums; i++) {
      let tableTr = [];
      for (let j = 0; j < 7; j++) {
        let dayIndex = 7 * i + j;
        let date = dayIndex - dayOfWeek + 1;
        let cell;
        let notCurrentMonth;
        let notcurrentYear = year,
          notcurrentMonth = month,
          notcurrentDay;

        if (date <= 0) {
          date = date + days_per_lastMonth;
          notCurrentMonth = "notCurrentMonth";

          if (notcurrentMonth === 1) {
            notcurrentMonth = 12;
            notcurrentYear -= 1;
          } else {
            notcurrentMonth -= 1;
          }
        } else if (date > days_per_month) {
          date = date - days_per_month;
          notCurrentMonth = "notCurrentMonth";

          if (notcurrentMonth === 12) {
            notcurrentMonth = 1;
            notcurrentYear += 1;
          } else {
            notcurrentMonth += 1;
          }
        } else {
          date = dayIndex - dayOfWeek + 1;
          notCurrentMonth = "";
        }

        if (notcurrentMonth < 10 && notcurrentMonth > 0) {
          notcurrentMonth = "0" + notcurrentMonth;
        }

        notcurrentDay = date;

        if (notcurrentDay < 10 && notcurrentDay > 0) {
          notcurrentDay = "0" + notcurrentDay;
        }

        cell = notcurrentYear + "/" + notcurrentMonth + "/" + notcurrentDay;

        if (tempBookingDate === cell) {
          chosenDateStyle = "active";
        } else {
          chosenDateStyle = "";
        }

        tableTr.push(
          <div key={cell} className={["tableCell", notCurrentMonth].join(" ")}>
            <span
              className={chosenDateStyle}
              onClick={(e) => handleChooseDate(e, cell)}
            >
              {date}
            </span>
          </div>
        );
      }

      createTable.push(
        <div key={i} className="tableRow tableCellRow">
          {tableTr}
        </div>
      );
    }

    return createTable;
  }

  renderBookingTimeDisplay() {
    const { t } = this.props;
    const { availableTime } = this.state;

    if (Object.keys(availableTime).length !== 0) {
      return (
        <p>
          {this.state.tempBookingTimeHour} : {this.state.tempBookingTimeMin}
          {this.renderNoCombinationSeatWarning()}
        </p>
      );
    } else {
      return (
        <p className="text-danger">
          {t("addBooking.noBookingTimeAvailableToday")}
        </p>
      );
    }
  }

  renderNoCombinationSeatWarning() {
    const { t } = this.props;
    const {
      filterCombinationTables,
      tempBookingDate,
      tempBookingTimeHour,
      tempBookingTimeMin,
    } = this.state;
    const filterDateTime = `${tempBookingDate} ${tempBookingTimeHour}:${tempBookingTimeMin}`;

    if (
      tempBookingTimeHour !== "" &&
      tempBookingTimeMin !== "" &&
      filterCombinationTables[filterDateTime] === undefined
    ) {
      return <span>{t("addBooking.noTableAvailableAtTheTime")}</span>;
    }
  }

  renderDateAnnouncement() {
    const { t, i18n } = this.props;
    const dayAnnouncements = this.props.dayAnnouncements;
    const date = moment(new Date(this.props.tempBookingDate)).format(
      "YYYY-MM-DD"
    );
    const day = new Date(this.props.tempBookingDate).getDay();

    return (
      <div className="announcementWrapper">
        <div className="title_date">
          {date} （
          {`${i18n.language === "zh" ? dayList[day] : dayListAbbrevEN[day]}`}）
        </div>
        {dayAnnouncements.length > 0 &&
          dayAnnouncements.map((item, idx) => {
            return (
              <AnnouncementCard
                data={dayAnnouncements}
                item={item}
                idx={idx}
                key={item.id}
                onlyRead={true}
                date={date}
              />
            );
          })}
        {dayAnnouncements.length === 0 ? (
          <div className="announcement_empty">
            {t("bulletinSystem:NoAnnouncementsForTheDay")}
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const { loadingTime, t } = this.props;
    const {
      activeYear,
      activeMonth,
      tempBookingTimeHour,
      tempBookingTimeMin,
      availableTime,
    } = this.state;

    return (
      <div className="datePicerWrapper">
        <div className="datePicker">
          <div className="datePickerContent">
            <div className="dateWrapper">
              <div className="dateWrapper-calendar">
                {this.props.loadingTime ? (
                  <div className="timePickerLoad" />
                ) : null}
                <Month
                  key={activeYear + activeMonth}
                  createRows={this.createRows(activeYear, activeMonth)}
                  handleNextMonth={this.handleNextMonth}
                  handlePreMonth={this.handlePreMonth}
                  showYear={parseInt(activeYear, 10)}
                  showMonth={parseInt(activeMonth, 10)}
                  updateDayAnnouncements={this.props.updateDayAnnouncements}
                />
              </div>
              <div className="dateWrapper-time">
                <div className="displayTime">
                  {this.renderBookingTimeDisplay()}
                </div>
                <Time
                  availableTime={availableTime}
                  tempBookingTimeHour={tempBookingTimeHour}
                  tempBookingTimeMin={tempBookingTimeMin}
                  handleTimeClick={this.props.handleTimeClick}
                />
              </div>
            </div>
            {this.renderDateAnnouncement()}
          </div>
          <div className="wrapperFooter">
            <button
              className="button-common button-secondary"
              disabled={loadingTime}
              onClick={() => this.props.closeDatepicker()}
            >
              {t("settings:cancel")}
            </button>
            <button
              className="button-common button-primary"
              disabled={loadingTime}
              onClick={() => this.props.datepickerSubmit()}
            >
              {t("settings:ok")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const Month = ({
  showYear,
  showMonth,
  createRows,
  handlePreMonth,
  handleNextMonth,
}) => {
  const { i18n } = useTranslation();
  const days = i18n.language === "zh" ? dayList : dayListAbbrevEN;
  return (
    <div className="calendarTable">
      <div className="tableMonth">
        <span onClick={() => handlePreMonth()}>
          <i className="fa fa-chevron-left" aria-hidden="true" />
        </span>
        {showYear}-{showMonth}
        <span onClick={() => handleNextMonth()}>
          <i className="fa fa-chevron-right" aria-hidden="true" />
        </span>
      </div>

      <div className="tableRow tableRowTitle">
        {days.map((day) => {
          return (
            <div key={day} className="tableCell">
              {day}
            </div>
          );
        })}
      </div>

      <div className="monthCell">{createRows}</div>
    </div>
  );
};

const Time = ({
  tempBookingTimeHour,
  tempBookingTimeMin,
  availableTime,
  handleTimeClick,
}) => {
  const { t } = useTranslation("bookingSystem");
  const renderTimeCell = () => {
    //hour 選到的時間
    //availableTime 能選的時間

    return hourArray.map((item) => {
      if (!availableTime[item]) {
        if (item === tempBookingTimeHour) {
          return (
            <div
              key={item}
              className="timeArea-cell active"
              onClick={() => handleTimeClick("hour", item)}
            >
              {item}
            </div>
          );
        } else {
          return (
            <div
              key={item}
              className="timeArea-cell disable"
              onClick={() => handleTimeClick("hour", item)}
            >
              {item}
            </div>
          );
        }
      } else if (item === tempBookingTimeHour) {
        return (
          <div
            key={item}
            className="timeArea-cell active"
            onClick={() => handleTimeClick("hour", item)}
          >
            {item}
          </div>
        );
      } else if (availableTime[item]) {
        return (
          <div
            key={item}
            className="timeArea-cell"
            onClick={() => handleTimeClick("hour", item)}
          >
            {item}
          </div>
        );
      }

      return null;
    });
  };

  const renderTimeMin = () => {
    const min = availableTime[tempBookingTimeHour];

    return minArray.map((item) => {
      if (tempBookingTimeHour !== "" && min && min.indexOf(item) === -1) {
        if (item === tempBookingTimeMin) {
          return (
            <div
              key={item}
              className="timeArea-cell active"
              onClick={() => handleTimeClick("min", item)}
            >
              {item}
            </div>
          );
        } else {
          return (
            <div
              key={item}
              className="timeArea-cell disable"
              onClick={() => handleTimeClick("min", item)}
            >
              {item}
            </div>
          );
        }
      } else if (
        tempBookingTimeHour !== "" &&
        !min &&
        item !== tempBookingTimeMin
      ) {
        return (
          <div
            key={item}
            className="timeArea-cell disable"
            onClick={() => handleTimeClick("min", item)}
          >
            {item}
          </div>
        );
      } else if (tempBookingTimeHour !== "" && item === tempBookingTimeMin) {
        return (
          <div
            key={item}
            className="timeArea-cell active"
            onClick={() => handleTimeClick("min", item)}
          >
            {item}
          </div>
        );
      } else if (tempBookingTimeHour === "" && !min) {
        return (
          <div
            key={item}
            className="timeArea-cell disable"
            style={{ cursor: "default" }}
          >
            {item}
          </div>
        );
      } else {
        return (
          <div
            key={item}
            className="timeArea-cell"
            onClick={() => handleTimeClick("min", item)}
          >
            {item}
          </div>
        );
      }
    });
  };

  return (
    <div className="timArea">
      <div className="timArea-hour">
        <div className="timeArea-title">
          <span>{t("time.hour_2")}</span>
        </div>
        {renderTimeCell()}
      </div>
      <div className="timArea-hour timeArea-min">
        <div className="timeArea-title">
          <span>{t("time.minute_2")}</span>
        </div>
        {renderTimeMin()}
      </div>
    </div>
  );
};

export default withTranslation("bookingSystem")(Datepicker);
