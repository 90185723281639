import React, { useState } from 'react';
import { Link } from 'react-router';
import { Space } from 'antd';

import MenuAppIntroModal from '../menu_user/MenuAppIntro';
import MenuUserDeleteModal from '../menu_user/MenuUserDelete'
import { useTranslation } from 'react-i18next';
import { unbindMenuUser } from '../menu_user/MenuUserApi';

const MenuUserInfo = ({ menuUserInfo, getMenuUserBindStatus }) => {
  const { t } = useTranslation('settings');
  const [ visible, setVisible ] = useState(false);
  const [ checkDelete, setCheckDelete ] = useState(false);


  const handleMenuAppIntroModal = () => {
    const menuAppIntroModalVisible = !visible;
    setVisible(menuAppIntroModalVisible)
  };

  const handleCheckDeleteModal = () => {
    const checkDeleteModalVisible = !checkDelete;
    setCheckDelete(checkDeleteModalVisible)
  };

  const deleteMenuUser = () => {
    unbindMenuUser()
      .then(res => {
        getMenuUserBindStatus();
        setCheckDelete(false);
      }).catch(err => {
          console.log(err);
      })
  }

  const renderMenuAccount = () => {
    if(menuUserInfo.displayName === '' && menuUserInfo.menuUserId === '') {
      return (
        <div className="menuAccountInfoBlock_content">
          <div className="menuAccountInfoBlock_content_why" 
            onClick={handleMenuAppIntroModal}>
            {t('accountSettings.menu.additionalInformation')}
          </div>
          <Link to={'/dashboard/menu_user'}>
            <button>{t('accountSettings.menu.connect')}</button>
					</Link>
        </div>
      )
    } else {
      return (
      <div className="menuAccountInfoBlock_info">
        <div className="menuAccountInfoBlock_logo">
          { menuUserInfo.thumbnailURL ? 
            <img src={menuUserInfo.thumbnailURL} alt="" /> : ''
          }
          </div>
        <div className="menuAccountInfoBlock_info_right">
          <div className="menuAccountInfoBlock_info_title">
            {t('accountSettings.menu.menu_userName')}
          </div>
          <div className="menuAccountInfoBlock_info_content">
            { menuUserInfo.displayName }
          </div>
          <div className="menuAccountInfoBlock_info_title">
            {t('accountSettings.menu.menu_ID')}
          </div>
          <div className="menuAccountInfoBlock_info_content">
            { menuUserInfo.menuUserId }
          </div>
          <div className="text-right">
            <button onClick={handleCheckDeleteModal}>{t('accountSettings.menu.disconnect')}</button>
          </div>
        </div>
      </div>
      )
    }
  };

	return (
		<div className="menuAccountInfoBlock">
      <Space direction='vertical' size={8}>
        <div className="heading-3-responsive">
          {t('accountSettings.menu.subTitle')}
        </div>
        <div className="text-body color-subtitle">
          {t('accountSettings.menu.description')}
        </div>
      </Space>
			{ renderMenuAccount() }
      <MenuAppIntroModal 
        visible={visible}
        handleMenuAppIntroModal={handleMenuAppIntroModal}
      />
      <MenuUserDeleteModal
        checkDelete={checkDelete}
        handleCheckDelete={handleCheckDeleteModal}
        deleteMenuUser={deleteMenuUser}
      />
		</div>
	);
};

export default MenuUserInfo;

