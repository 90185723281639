import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation, Trans } from "react-i18next";

import TableCell from "./TableCell";
import NotEnoughWarning from "./NotEnoughWarning";

//tableGroup 區域選項
//tables 所有桌子
//tempSeating 所選擇的桌子
//availableTables 該時段有空位的桌子
//chosenTableGroup 所選擇桌子所屬的區域

const initTableGroup = (tables) => {
  const newTableGroup = { all: [] };

  tables.forEach((tableInfo) => {
    const group = tableInfo.group;
    const allGroup = newTableGroup["all"];
    const groupIndex = allGroup
      .map((groupName) => groupName.group)
      .indexOf(group);

    if (groupIndex === -1) {
      let groupTable = {
        group,
        tables: [],
      };
      groupTable.tables.push(tableInfo);
      allGroup.push(groupTable);
    } else {
      allGroup[groupIndex].tables.push(tableInfo);
    }
  });

  return newTableGroup;
};

const TablePicker = ({
  tablePickerInit,
  defaultTableGroupTxt: propsDefaultTableGroupTxt,
  newSelectedId,
  bookingAttend,
  seating,
  chosenTableGroup: propsChosenTableGroup,
  availableTables: propsAvailableTables,
  tables: propsTables,
  handleTablePicker,
  tablePickerSubmit,
}) => {
  const { t } = useTranslation("bookingSystem");
  const [init, setInit] = useState(false);
  const [tables, setTables] = useState(propsTables);
  const [tableGroup, setTableGroup] = useState(
    initTableGroup(tables, "setTableGroup")
  );
  const [defaultTableGroupTxt, setDefaultTableGroupTxt] = useState(
    propsDefaultTableGroupTxt
  );
  const [tempSeating, setTempSeating] = useState(seating);
  const [availableTables, setAvailableTables] = useState(propsAvailableTables);
  const [chosenTableGroup, setChosenTableGroup] = useState(
    propsChosenTableGroup
  );
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setInit(true);
  }, []);

  useEffect(() => {
    setTables(propsTables);
    setTableGroup(initTableGroup(propsTables));
  }, [propsTables]);

  useEffect(() => {
    setAvailableTables(propsAvailableTables);
  }, [propsAvailableTables]);

  useEffect(() => {
    let res;

    //檢查座位是否還在：比較table_setting_id & idx
    for (let i = 0; i < seating.length; i++) {
      res = tables.findIndex((seat) => {
        return (
          seating[i].idx === seat.idx &&
          seating[i].table_setting_id === seat.table_setting_id
        );
      });

      if (res === -1) {
        break;
      }
    }

    if (res === -1 || res === undefined || res === null) {
      setTempSeating([]);
    } else {
      setTempSeating(seating);
    }
  }, [seating, tables]);

  useEffect(() => {
    setDefaultTableGroupTxt(propsDefaultTableGroupTxt);
  }, [propsDefaultTableGroupTxt]);

  useEffect(() => {
    setChosenTableGroup(propsChosenTableGroup);
  }, [propsChosenTableGroup]);

  const renderChosenTable = () => {
    const maxCount = tempSeating.reduce((prev, cur) => prev + cur.max_seat, 0);
    const bookingAttendNum = parseInt(bookingAttend, 10);
    const tableCount = tempSeating.length;
    const attendanceCount = bookingAttendNum - maxCount;
    const short = attendanceCount;

    if (tempSeating.length === 0 || maxCount < bookingAttendNum) {
      return (
        <h2 className="text-center">
          <Trans
            i18nKey="bookingSystem:addBooking.partySizeNotEnoughMessage"
            values={{
              attend: bookingAttend,
              tableCount: tempSeating.length,
              short: attendanceCount,
            }}
          >
            <span className="bookingAttendance">
              預約人數{" "}
              <span className="attendanceStyle">{{ bookingAttend }}</span> 人，
            </span>
            已選中 {{ tableCount }} 張桌， 尚不足{" "}
            <span className="notEnoughStyle">{{ short }}</span> 人
          </Trans>
        </h2>
      );
    } else if (maxCount >= bookingAttendNum) {
      return (
        <h2 className="text-center full">
          <Trans
            i18nKey="bookingSystem:addBooking.partySizeFullMessage"
            values={{
              attend: bookingAttend,
              tableCount: tempSeating.length,
              maxCount: maxCount,
            }}
          >
            <span className="bookingAttendance">
              預約人數{" "}
              <span className="attendanceStyle">{{ bookingAttend }}</span> 人，
            </span>
            已選中 {{ tableCount }} 張桌， 最多容納{" "}
            <span className="attendanceStyle">{{ maxCount }}</span> 人
          </Trans>
        </h2>
      );
    }
  };

  const handleTableGroupChange = (e) => {
    const tableGroupTxt = e.target.value;
    setDefaultTableGroupTxt(tableGroupTxt);
  };

  const handleTableCellClick = (table) => {
    let tableGroup = [];
    const newTempSeating = _.cloneDeep(tempSeating);

    const tableIndex = newTempSeating.findIndex(
      (e) => e.table_number === table.table_number
    );

    if (tableIndex > -1) {
      // 移除選擇的座位
      newTempSeating.splice(tableIndex, 1);
    } else {
      // 增加選擇的做位
      newTempSeating.push(table);
    }

    newTempSeating.forEach((seat) => tableGroup.push(seat.group));

    setTempSeating(newTempSeating);
    setChosenTableGroup(_.uniq(tableGroup));
  };

  const renderTableCell = () => {
    if (defaultTableGroupTxt === "all") {
      return tableGroup["all"].map((group) => {
        return (
          <TableCell
            newSelectedId={newSelectedId}
            key={group.group}
            groupTitle={group.group}
            tableInfo={group.tables}
            tempSeating={tempSeating}
            availableTables={availableTables}
            handleTableCellClick={handleTableCellClick}
          />
        );
      });
    } else {
      const allTableGroups = _.cloneDeep(tableGroup["all"]);
      const groupIndex = allTableGroups
        .map((group) => group.group)
        .indexOf(defaultTableGroupTxt);

      return (
        <TableCell
          newSelectedId={newSelectedId}
          key={defaultTableGroupTxt}
          groupTitle={allTableGroups[groupIndex].group}
          tempSeating={tempSeating}
          availableTables={availableTables}
          tableInfo={allTableGroups[groupIndex].tables}
          handleTableCellClick={handleTableCellClick}
        />
      );
    }
  };

  const check = () => {
    const maxCount = tempSeating.reduce((prev, cur) => prev + cur.max_seat, 0);

    if (tempSeating.length !== 0 && maxCount < parseInt(bookingAttend, 10)) {
      setShowWarning(true);
    } else {
      tempSeating.map((seat) => delete seat["disable"]);

      tablePickerSubmit(tempSeating, chosenTableGroup);
    }
  };

  const cancelWarning = () => {
    setShowWarning(false);
  };

  const checkWarning = () => {
    setShowWarning(false);
    tablePickerSubmit(tempSeating, chosenTableGroup);
  };

  const renderTableNote = () => {
    const tables = tempSeating.map((t) => {
      if (t.disable === "disable") return t.table_number;
      return null;
    });
    const newTables = tables.filter(Boolean);

    if (newTables.length !== 0)
      return (
        <div className="text-center tableNote">
          {t("addBooking.tableHasBookingNotice", {
            tables: newTables.join(", "),
          })}
        </div>
      );
  };

  if (!init || !tablePickerInit) {
    return (
      <div className="tablePickerWrapper">
        <div className="addModalLoading" />
      </div>
    );
  }

  return (
    <div className="tablePickerWrapper">
      <section className="tableSeleciton">
        <section className="tableSelected">{renderChosenTable()}</section>
        <section className="showTable">
          <select
            value={defaultTableGroupTxt}
            onChange={(e) => handleTableGroupChange(e)}
          >
            <option key="all" value={"all"}>
              {t("addBooking.tableAll")}
            </option>
            {tableGroup["all"].map((item) => (
              <option key={item.group} value={item.group}>
                {item.group}
              </option>
            ))}
          </select>

          {renderTableNote()}

          <div className="tableRes">{renderTableCell()}</div>
        </section>
        <div className="wrapperFooter">
          <button
            className="button-common button-secondary"
            onClick={() => handleTablePicker("close")}
          >
            {t("settings:cancel")}
          </button>
          <button
            className="button-common button-primary"
            onClick={() => check()}
          >
            {t("settings:save")}
          </button>
        </div>
      </section>
      {showWarning && (
        <NotEnoughWarning
          bookingAttend={bookingAttend}
          tempSeating={tempSeating}
          cancelWarning={cancelWarning}
          checkWarning={checkWarning}
        />
      )}
    </div>
  );
};

export default TablePicker;
