import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import QueueSystemAPI from "./QueueSystemAPI";
import {
  QueueInsertText,
  QueueInsertKey,
} from "../notification_settings/InsertText";
import SettingsSaveButton from "../commons/SettingsSaveButton";

import NotificationSettingBlock from "../notification_settings/NotificationSettingBlock";
import InsufficientCreditsAlert from "../notification_settings/InsufficientCreditsAlert";
import NotificationEditPopup from "../notification_settings/NotificationEditPopup";

const QueueSettingNotification = ({ router, route }) => {
  const { t } = useTranslation("settings");
  const [init, setInit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [credits, setCredits] = useState(0);
  const [shopName, setShopName] = useState("");

  const [createdSettings, setCreatedSettings] = useState({});
  const [reminderSettings, setReminderSettings] = useState({});

  const [tempCreatedSettings, setTempCreatedSettings] = useState({});
  const [tempReminderSettings, setTempReminderSettings] = useState({});

  const [showSuccessModal, setShowCreatedModal] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showSmsTextareaError, setShowSmsTextareaError] = useState(false);

  const mappings = {
    [QueueInsertKey.SHORTEN_URL]: QueueInsertText.SHORTEN_URL,
  };

  const defaultSmsQueueSuccessMsg =
    `您已於${shopName}成功登記排隊，查詢號碼或叫號進度請至*/排隊結果頁/*`;
  const defaultSmsQueueReminderMsg =
    `您於${shopName}的排隊即將到號，請前往候位區等待叫號`;

  const routerWillLeave = useCallback(() => {
    if (!isSaved) return t("leaveNotice");
  }, [isSaved, t]);

  useEffect(() => {
    router.setRouteLeaveHook(route, routerWillLeave);
  }, [router, route, routerWillLeave]);

  useEffect(() => {
    const getQueueSettings = async () => {
      try {
        const api = new QueueSystemAPI();
        const data = await api.getQueueSettings();
        setCredits(data.msg_point);
        setShopName(data.display_name);
        setInit(true);
      } catch (err) {
        console.log("error", err);
        window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
      }
    };

    getQueueSettings();
  }, [t]);

  useEffect(() => {
    const getNotification = async () => {
      try {
        const api = new QueueSystemAPI();
        const data = await api.getNotification();
        setCreatedSettings(data.line_up_created);
        setReminderSettings(data.line_up_reminder);
        setTempCreatedSettings(data.line_up_created);
        setTempReminderSettings(data.line_up_reminder);
      } catch (err) {
        console.log("getNotification error", err);
      }
    };

    getNotification();
  }, []);

  // 將顯示內容轉換為指定碼
  const displayToCode = (msg) => {
    return msg.replace(/\*\/排隊結果頁\/\*/g, "MNUSHOP_SHORTEN_URL "); // 最後的空格防止有英文文案被誤認為是網址的一部分
  };

  // 將指定碼轉換為顯示內容
  const convertToDisplay = (message) => {
    return message
      .replace(/MNUSHOP_SHORTEN_URL /g, mappings["MNUSHOP_SHORTEN_URL"])
      .replace(/MNUSHOP_SHORTEN_URL/g, mappings["MNUSHOP_SHORTEN_URL"]);
  };

  // 更新啟用狀態
  const handleNotificationEnable = (category, type, checked) => {
    const setState =
      type === "line_up_success" ? setCreatedSettings : setReminderSettings;

    if (category === "email") {
      setState((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          online: checked,
          offline: checked,
        },
      }));
    } else if (category === "sms") {
      setState((prev) => ({
        ...prev,
        sms: {
          ...prev.sms,
          online: checked,
          offline: checked,
        },
      }));
    } else if (category === "line") {
      setState((prev) => ({
        ...prev,
        line_oa: {
          ...prev.line_oa,
          online: checked,
          offline: checked,
        },
      }));
    }

    setIsSaved(false);
    setShowSmsTextareaError(false);
  };

  const handleEmailMsg = (e) => {
    const type = e.target.name;
    const value = e.target.value;

    if (type === "line_up_success") {
      setCreatedSettings((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          template: value,
        },
      }));
    } else {
      setReminderSettings((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          template: value,
        },
      }));
    }

    setIsSaved(false);
  };

  const handleSmsMsg = (e) => {
    const type = e.target.name;
    const value = e.target.value;
    const encodedValue = displayToCode(value);

    if (type === "line_up_success") {
      setCreatedSettings((prev) => ({
        ...prev,
        sms: {
          ...prev.sms,
          template: encodedValue,
        },
      }));
    } else {
      setReminderSettings((prev) => ({
        ...prev,
        sms: {
          ...prev.sms,
          template: encodedValue,
        },
      }));
    }

    setIsSaved(false);
    setShowSmsTextareaError(false);
  };

  const closeAllPopup = () => {
    setShowCreatedModal(false);
    setShowReminderModal(false);
    setShowSmsTextareaError(false);
  };

  const cancelPopup = useCallback(() => {
    setCreatedSettings(tempCreatedSettings);
    setReminderSettings(tempReminderSettings);
    closeAllPopup();
  }, [tempCreatedSettings, tempReminderSettings]);

  const submitPopup = useCallback(
    (type) => {
      const checkSmsContent = (settings) => {
        const { online, offline, template } = settings.sms;
        if ((online || offline) && (!template || !template.trim())) {
          return false;
        }
        return true;
      };

      if (type === "line_up_success") {
        if (!checkSmsContent(createdSettings)) {
          setShowSmsTextareaError(true);
          return;
        }
        setTempCreatedSettings(createdSettings);
      } else {
        if (!checkSmsContent(reminderSettings)) {
          setShowSmsTextareaError(true);
          return;
        }
        setTempReminderSettings(reminderSettings);
      }

      closeAllPopup();
    },
    [createdSettings, reminderSettings]
  );

  const submit = useCallback(async () => {
    try {
      const api = new QueueSystemAPI();
      const notificationSettings = {
        queue_settings: {
          notification_settings: {
            line_up_created: createdSettings,
            line_up_reminder: reminderSettings,
          },
        },
      };
      await api.updateNotification(notificationSettings);
      window.app.alert.setMessage(t("status.saved"), "done");
      setSaving(false);
      setIsSaved(true);
    } catch (err) {
      window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
    }
  }, [t, createdSettings, reminderSettings]);

  const shouldShowInsufficientCreditsAlert = useMemo(() => {
    const checkCredits = (setting) => {
      if (!setting) return false;
      
      const smsStatus = setting.sms?.online || setting.sms?.offline;
      const lineOaStatus = setting.line_oa?.online || setting.line_oa?.offline;
      
      return smsStatus || lineOaStatus;
    };

    return checkCredits(createdSettings) || checkCredits(reminderSettings);
  }, [createdSettings, reminderSettings]);

  if (!init) return <div className="pageLoading">{t("status.loading")}</div>;
  return (
    <>
      <div className="setting-container">
        {saving && (
          <div className="lightBoxLayer">
            <h4>{t("status.saving")}</h4>
          </div>
        )}
        <div style={{ marginBottom: "24px" }}>
          <h3>{t("queueNotification.title")}</h3>
          <h5>{t("queueNotification.description")}</h5>
        </div>
        <hr />
        <div className="setting-block">
          {credits < 1 && shouldShowInsufficientCreditsAlert && (
            <InsufficientCreditsAlert />
          )}

          <NotificationSettingBlock
            titleKey="queueSuccessNotification"
            type="queue"
            emailMsg={createdSettings.email}
            smsMsg={createdSettings.sms}
            lineMsg={createdSettings.line_oa}
            credits={credits}
            showModal={() => setShowCreatedModal(true)}
          />
          <hr />
          <NotificationSettingBlock
            titleKey="queueReminderNotification"
            type="queue"
            emailMsg={reminderSettings.email}
            smsMsg={reminderSettings.sms}
            lineMsg={reminderSettings.line_oa}
            credits={credits}
            showModal={() => setShowReminderModal(true)}
          />
        </div>
      </div>
      <SettingsSaveButton handleSaved={submit} showDivider />

      {showSuccessModal && (
        <NotificationEditPopup
          title={t("queueNotification.queueSuccessNotification")}
          description={t("queueNotification.queueSuccessNotificationDes")}
          type="line_up_success"
          credits={credits}
          emailMsg={createdSettings.email}
          smsMsg={createdSettings.sms}
          lineMsg={createdSettings.line_oa}
          handleEmailMsg={handleEmailMsg}
          handleSmsMsg={handleSmsMsg}
          handleNotificationEnable={handleNotificationEnable}
          cancelPopup={() => cancelPopup("line_up_success")}
          submit={() => submitPopup("line_up_success")}
          replaceVariablesWithDisplay={convertToDisplay}
          shopName={shopName}
          defaultMsg={defaultSmsQueueSuccessMsg}
          showSmsTextareaError={showSmsTextareaError}
        />
      )}

      {showReminderModal && (
        <NotificationEditPopup
          title={t("queueNotification.queueReminderNotification")}
          description={t("queueNotification.queueReminderNotificationDes")}
          type="line_up_remind"
          credits={credits}
          emailMsg={reminderSettings.email}
          smsMsg={reminderSettings.sms}
          lineMsg={reminderSettings.line_oa}
          handleEmailMsg={handleEmailMsg}
          handleSmsMsg={handleSmsMsg}
          handleNotificationEnable={handleNotificationEnable}
          cancelPopup={() => cancelPopup("line_up_remind")}
          submit={() => submitPopup("line_up_remind")}
          replaceVariablesWithDisplay={convertToDisplay}
          shopName={shopName}
          defaultMsg={defaultSmsQueueReminderMsg}
          showSmsTextareaError={showSmsTextareaError}
        />
      )}
    </>
  );
};

export default QueueSettingNotification;
