import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const timeKeyboardCell = [1, 2, 3, 4, 5, 6, 7, 8, 9, "reset", 0, "check"];

const Numpad = ({ children, check, close, activeTime = "00:00" }) => {
  const { t } = useTranslation("settings");
  const [displayTime, setDisplayTime] = useState("");
  const [time, setTime] = useState(activeTime.split(""));
  const [count, setCount] = useState(0);

  useEffect(() => {
    const newTime = time.join("");
    setDisplayTime(newTime);
  }, [time]);

  const handleReSetTime = () => {
    const reset = ["-", "-", ":", "-", "-"];
    setTime(reset);
    setCount(0);
  };

  const handleTimeClick = (keyboard) => {
    console.log("count---", count);
    let newTime = displayTime.split("");
    if (count === 0) {
      newTime = ["-", "-", ":", "-", "-"];
      newTime[count] = keyboard;
      // count = 1;
      setCount(1);
    } else if (count === 1) {
      newTime[count] = keyboard;
      // count = 3;
      setCount(3);
    } else if (count === 3) {
      newTime[count] = keyboard;
      // count = 4;
      setCount(4);
    } else if (count === 4) {
      newTime[count] = keyboard;
      // count = 0;
      setCount(0);
    }

    setTime(newTime);
  };

  const handleSubmit = () => {
    const hour = parseInt(displayTime.substr(0, 2), 10),
      min = parseInt(displayTime.substr(3), 10);
    const multiple_five = min % 5;

    if (count !== 0) {
      window.app.alert.setMessage(
        t("bookingTimesCommon.pls_enterCompleteTime"),
        "tip"
      );
      return;
    }

    if (hour > 23) {
      window.app.alert.setMessage(
        t("bookingTimesCommon.pls_invalidTimeFormat"),
        "error"
      );
      return;
    }

    if (min > 59) {
      window.app.alert.setMessage(
        t("bookingTimesCommon.pls_invalidTimeFormat"),
        "error"
      );
      return;
    }

    if (min <= 59 && multiple_five !== 0) {
      window.app.alert.setMessage(
        t("bookingTimesCommon.pls_minimumTimeUnit"),
        "error"
      );
      return;
    }

    check(displayTime);
  };

  const renderNumpadCell = () => {
    return timeKeyboardCell.map((keyboard, id) => {
      if (keyboard === "reset") {
        return (
          <div
            key={id}
            className="tKeyborad-body-cell"
            onClick={() => handleReSetTime()}
          >
            {t("reset")}
          </div>
        );
      } else if (keyboard === "check") {
        return (
          <div
            key={id}
            className="tKeyborad-body-cell"
            onClick={() => handleSubmit()}
          >
            {t("ok")}
          </div>
        );
      } else {
        return (
          <div
            key={id}
            className="tKeyborad-body-cell"
            onClick={() => handleTimeClick(keyboard)}
          >
            {keyboard}
          </div>
        );
      }
    });
  };

  const handleClose = () => {
    close();
  };
  return (
    <div className="n-timeCellWrapper">
      <div className="n-timeCellLayout" />

      <div className="tKeyborad">
        <div className="tKeyborad-header">
          {displayTime}
          <span className="tKeyboard-del" onClick={() => handleClose()} />
          {children}
        </div>
        <div className="tKeyborad-body">{renderNumpadCell()}</div>
      </div>
    </div>
  );
};

export default Numpad;
