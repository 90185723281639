import React, { useState, Fragment, useRef, useEffect } from "react";
import { Modal, Form, Input, Button, Spin, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import BookingLists from "./BookingLists";
import QueueLists from "./QueueLists";

import useBookingSubmit from "./hooks/useBookingSubmit";
import useNewModuleSubmit from "./hooks/useNewModuleSubmit";

import "../../sass/booking-search.scss";

const { Item } = Form;
const { TabPane } = Tabs;
export default ({ searchType = "booking", iconSize = 20 }) => {
  const { t } = useTranslation("bookingSystem");
  const [form] = Form.useForm();
  const { validateFields } = form;

  const inputRef = useRef();
  const [visible, setVisible] = useState(false);
  const [init, setInit] = useState(false);
  const {
    BookingLoading,
    bookingSearchRes,
    setBookingSearchRes,
    BookingSubmit,
  } = useBookingSubmit(setInit);
  const {
    newModuleLoading,
    newModuleSearchRes,
    setNewModuleSearchRes,
    newModuleSubmit,
  } = useNewModuleSubmit(setInit);

  useEffect(() => {
    if (visible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [visible]);

  const tabsProps =
    searchType === "booking"
      ? { renderTabBar: () => <div />, defaultActiveKey: "booking" }
      : {
          defaultActiveKey:
            newModuleSearchRes["queue_records"] &&
            newModuleSearchRes["queue_records"]["today"].length !== 0
              ? "queue"
              : "booking",
          tabBarStyle: {
            backgroundColor: "rgba(118, 118, 128, 0.12)",
            marginTop: 10,
            marginBottom: 0,
            padding: 2,
            borderRadius: 6,
          },
        };

  const handleSubmit = () => {
    validateFields().then(({ searchInput }) => {
      inputRef.current.blur();
      if (searchType === "booking") BookingSubmit(searchInput);
      if (searchType === "newModule") newModuleSubmit(searchInput);
    });
  };

  const reset = () => {
    setInit(false);
    setVisible(false);
    setBookingSearchRes({
      today: [],
      future: [],
      past: [],
    });
    setNewModuleSearchRes({});
  };

  return (
    <Fragment>
      <button
        className="action-display-button"
        onClick={() => setVisible(true)}
      >
        <SearchOutlined style={{ fontSize: iconSize, color: "#676767" }} />
      </button>

      {visible && (
        <Modal
          destroyOnClose
          visible={visible}
          wrapClassName="bookingSearchModal"
          footer={null}
          onCancel={reset}
          style={{ top: 50 }}
        >
          <Form
            form={form}
            className="bookingSearchInput"
            onFinish={handleSubmit}
          >
            <Item>
              <Item
                noStyle
                name={["searchInput"]}
                initialValue={""}
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: t("search.error"),
                  },
                ]}
              >
                <Input
                  ref={inputRef}
                  className="searchInput"
                  placeholder={t("search.placeholder")}
                  allowClear
                  onPressEnter={handleSubmit}
                />
              </Item>
              <Button className="searchIcon" htmlType="submit">
                <SearchOutlined style={{ fontSize: 20 }} />
              </Button>
            </Item>

            {init && (
              <Fragment>
                {BookingLoading || newModuleLoading ? (
                  <Spin />
                ) : (
                  <Tabs {...tabsProps}>
                    <TabPane tab={t('search.booking')} key="booking">
                      <div className="searchRes">
                        <BookingLists
                          searchType={searchType}
                          bookingSearchRes={
                            searchType === "booking"
                              ? bookingSearchRes
                              : newModuleSearchRes["bookings"]
                          }
                          reset={reset}
                        />
                      </div>
                    </TabPane>
                    {searchType === "newModule" && (
                      <TabPane tab={t('search.queueing')} key="queue">
                        <div className="searchRes">
                          <QueueLists
                            searchType={searchType}
                            bookingSearchRes={
                              newModuleSearchRes["queue_records"]["today"]
                            }
                            reset={reset}
                          />
                        </div>
                      </TabPane>
                    )}
                  </Tabs>
                )}
              </Fragment>
            )}
          </Form>
        </Modal>
      )}
    </Fragment>
  );
};
