import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from "i18next-intervalplural-postprocessor";

import commonEN from "./locales/en/common.json";
import bookingSystemEN from "./locales/en/bookingSystem.json";
import couponSystemEN from "./locales/en/couponSystem.json";
import queueSystemEN from "./locales/en/queueSystem.json";
import bulletinSystemEN from "./locales/en/bulletinSystem.json";
import settingsEN from "./locales/en/settings.json";
import timeEN from "./locales/en/time.json";
import dashboardEN from "./locales/en/dashboard.json";
import customerEN from "./locales/en/customer.json";
import messagesEN from "./locales/en/messages.json";

import commonZH from "./locales/zh/common.json";
import bookingSystemZH from "./locales/zh/bookingSystem.json";
import couponSystemZH from "./locales/zh/couponSystem.json";
import queueSystemZH from "./locales/zh/queueSystem.json";
import bulletinSystemZH from "./locales/zh/bulletinSystem.json";
import settingsZH from "./locales/zh/settings.json";
import timeZH from "./locales/zh/time.json";
import dashboardZH from "./locales/zh/dashboard.json";
import customerZH from "./locales/zh/customer.json";
import messagesZH from "./locales/zh/messages.json";

const resources = {
  en: {
    common: commonEN,
    bookingSystem: bookingSystemEN,
    couponSystem: couponSystemEN,
    queueSystem: queueSystemEN,
    bulletinSystem: bulletinSystemEN,
    settings: settingsEN,
    time: timeEN,
    dashboard: dashboardEN,
    customer: customerEN,
    messages: messagesEN,
  },
  zh: {
    common: commonZH,
    bookingSystem: bookingSystemZH,
    couponSystem: couponSystemZH,
    queueSystem: queueSystemZH,
    bulletinSystem: bulletinSystemZH,
    settings: settingsZH,
    time: timeZH,
    dashboard: dashboardZH,
    customer: customerZH,
    messages: messagesZH,
  },
};

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .use(LanguageDetector)
  .init({
    resources,
    lng: localStorage.getItem('userLanguage') || "zh",
    fallbackLng: "zh",
    ns: [
      "common",
      "time",
      "settings",
      "couponSystem",
      "bookingSystem",
      "queueSystem",
      "bulletingSystem",
      "dashboard",
      "customer",
      "messages",
    ],
    defaultNS: "common",
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
