export const BookingInsertText = {
  SHORTEN_URL: "*/預約結果頁/*",
  BOOKING_DATETIME: "*/預約時間/*",
};

export const BookingInsertKey = {
  SHORTEN_URL: "MNUSHOP_SHORTEN_URL",
  BOOKING_DATETIME: "MNUSHOP_BOOKING_DATETIME",
};

export const QueueInsertText = {
  SHORTEN_URL: "*/排隊結果頁/*",
};

export const QueueInsertKey = {
  SHORTEN_URL: "MNUSHOP_SHORTEN_URL",
};
