import React, { useState, useEffect } from "react";
import $ from "jquery";
import { useSelector } from "react-redux";
import { browserHistory } from "react-router";
import { config } from "../../utils/config";
import { handleError } from "../../libs/handler";
import { useTranslation } from "react-i18next";

import UserNav from "./UserNav";
import UserProfile from "./UserProfile";
import UserPassword from "./UserPassword";
import ConfirmModal from "../popup/ConfirmModal";
import { Menu, Dropdown } from "antd";

const passwordErrorInit = {
  oldPasswordEmpty: false,
  oldPasswordWrong: false,
  newPasswordLength: false,
  confirmPasswordLength: false,
  passwordValidate: false,
};

const UserProfileBox = ({
  profile_url = "/dashboard/users/profile",
  url = "/dashboard/users/",
  reset_url = "/dashboard/users/update_password",
}) => {
  const { t } = useTranslation("settings");
  const [data, setData] = useState({
    avatar: "",
    name: "",
    job_title: "",
    user_id: "",
  });
  const [displayData, setDisplayData] = useState({
    avatar: "",
    name: "",
    job_title: "",
    user_id: "",
  });
  const [activeTab, setActiveTab] = useState("profile");
  const [showError, setShowError] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [passwordError, setPasswordError] = useState(passwordErrorInit);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const { user_id } = user;
  const superAdminId = "findlifeadmin";

  useEffect(() => {
    const load = () => {
      window.app.alert.setMessage(t('status.loading'), "info");
  
      $.ajax({
        url: config.domain + profile_url,
        dataType: "json",
        xhrFields: { withCredentials: true },
        success: (data) => {
          window.app.alert.setMessage(t('status.loadingComplete'), "done");
          setData(data.user);
          setDisplayData(data.user);
        },
        error: (xhr) => {
          window.app.alert.setMessage(xhr.responseJSON.error, "error");
          handleError(xhr);
        },
      });
    };

    load();
  }, [t, profile_url]);

  useEffect(() => {
    if (user_id === superAdminId) {
      browserHistory.push({
        pathname: "/dashboard",
      });
    }
  }, [user_id]);

  const handleSubmit = () => {
    let newData = { user: data };

    if (data.name.trim() === "") {
      setShowError(true);
      return window.app.alert.setMessage(t('userPanel.pls_enterUserName'), "error");
    }

    $.ajax({
      url: config.domain + url + "/" + data.id,
      dataType: "json",
      data: newData,
      type: "PUT",
      xhrFields: { withCredentials: true },
      success: function () {
        setDisplayData(data);
        setShowError(false);
        window.app.alert.setMessage(t('status.saved'), "done");
      },
      error: function (xhr, status, err) {
        window.app.alert.setMessage(data.message, "error");
      },
    });
  };

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleInputPassword = (e) => {
    const { name, value } = e.target;
    let copyPasswordError = Object.assign({}, passwordError);

    if (name === "old_password") {
      setOldPassword(value);
      copyPasswordError.oldPasswordEmpty = false;
      copyPasswordError.oldPasswordWrong = false;
    }
    if (name === "new_password") {
      setNewPassword(value);
      copyPasswordError.newPasswordLength = false;
    }
    if (name === "new_password_confirm") {
      setNewPasswordConfirm(value);
      copyPasswordError.confirmPasswordLength = false;
    }

    copyPasswordError.passwordValidate = false;
    setPasswordError(copyPasswordError);
  };

  const handleResetPassword = () => {
    let copyPasswordError = Object.assign({}, passwordError);
    let isDisabled = false;
    let minLength = 4;

    if (oldPassword.length === 0) {
      copyPasswordError.oldPasswordEmpty = true;
      isDisabled = true;
    }
    if (newPassword.length < minLength) {
      copyPasswordError.newPasswordLength = true;
      isDisabled = true;
    }
    if (newPasswordConfirm.length < minLength) {
      copyPasswordError.confirmPasswordLength = true;
      isDisabled = true;
    }
    if (isDisabled) {
      return setPasswordError(copyPasswordError);
    }

    $.ajax({
      url: window.domain + reset_url,
      dataType: "json",
      data: {
        old_password: oldPassword,
        new_password: newPassword,
        new_password_confirm: newPasswordConfirm,
      },
      type: "PUT",
      xhrFields: { withCredentials: true },
      success: function (data) {
        setPasswordError(passwordErrorInit);
        setOldPassword("");
        setNewPassword("");
        setNewPasswordConfirm("");
        setShowConfirmModal(true);
      },
      error: function (xhr, status, err) {
        const oldPasswordError = "舊密碼錯誤";
        let copyPasswordError = Object.assign({}, passwordError);

        if (xhr.responseJSON.message === oldPasswordError) {
          copyPasswordError.oldPasswordWrong = true;
          setPasswordError(copyPasswordError);
        } else  {
          copyPasswordError.passwordValidate = true;
          setPasswordError(copyPasswordError);
          window.app.alert.setMessage(t('userPanel.pls_newPasswordsNotMatch'), "error");
        }
      },
    });
  };

  const handleConfirm = () => {
    setShowConfirmModal(false);
    browserHistory.push({
      pathname: "/login",
    });
  };

  const handleTab = (value) => {
    setActiveTab(value);
    setData(displayData);
    setOldPassword("");
    setNewPassword("");
    setNewPasswordConfirm("");
    setShowError(false);
    setPasswordError(passwordErrorInit);
  };

  const handleMenuClick = (e) => {
    const tab = e.key;
    handleTab(tab);
  };

  const items = [
    {
      label: t('userPanel.userProfile'),
      className: activeTab === "profile" ? "active" : "",
      key: "profile",
    },
    {
      label: t('userPanel.changePasswords'),
      className: activeTab === "password" ? "active" : "",
      key: "password",
    },
  ];

  const menuProps = (
    <Menu onClick={handleMenuClick}>
      {items.map(item => (
        <Menu.Item key={item.key} className={item.className}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="system-layout userProfileBox newAllSetting">
      <UserNav handleTab={handleTab} activeTab={activeTab} />
      <div className="newAllSettingDisplay">
        <div className="setting-container">
          <div className="userProfileBox-nav">
            <h2 className="heading-2-responsive">{t("userPanel.title")}</h2>
            <Dropdown
              trigger={["click"]}
              overlay={menuProps}
              overlayClassName="dropdown-mode"
              placement="bottomLeft"
              arrow
              transitionName="ant-fade"
            >
              <button className="dropdown-mode-button">
                {activeTab === "profile"
                  ? t("userPanel.userProfile")
                  : t("userPanel.changePasswords")}
                <div className="icon-normal arrowDown-icon" />
              </button>
            </Dropdown>
          </div>
          {activeTab === "profile" ? (
            <UserProfile
              userId={data.user_id}
              name={data.name}
              jobTitle={data.job_title}
              showError={showError}
              handleInputChange={handleInputChange}
              submit={handleSubmit}
            />
          ) : (
            <UserPassword
              oldPassword={oldPassword}
              newPassword={newPassword}
              newPasswordConfirm={newPasswordConfirm}
              handleInputChange={handleInputPassword}
              submit={handleResetPassword}
              passwordError={passwordError}
            />
          )}
        </div>
      </div>
      {showConfirmModal && (
        <ConfirmModal
          title={t("userPanel.passwordsChangedConfirmation")}
          content={t("userPanel.passwordsChangedConfirmation_content")}
          submit={handleConfirm}
        />
      )}
    </div>
  );
};

export default UserProfileBox;