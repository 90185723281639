import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Space } from "antd";

import Tab from "./AnnouncementHeaderTab";
import Filter from "./AnnouncementHeaderFilter";
import ExpandSearchInput from "../../commons/ExpandSearchInput";

import { setAllOfficialAnnouncementDisplay } from "../api/OfficialAnnouncementApi";

import {
  handleSearchList,
  resetSearch,
  handleHeaderFilter,
  announcementSelected,
  announcementSettingEnded,
  showAnnouncementAddModal,
  clearAnnouncement,
} from "../../../actions/announcementAction";

const AnnouncementHeader = () => {
  const { t } = useTranslation("bulletinSystem");
  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const {
    page,
    role,
    currentTab,
    selected,
    currentFilter,
    sortBy,
    order,
    displayAnnouncements,
  } = announcementListReducer;

  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const searchInputRef = useRef(null);

  const statusSelectList = [
    { title: t("list.allDisplayOnceDaily"), action: "once" },
    { title: t("list.allNeverShowAgain"), action: "never" },
  ];

  useEffect(() => {
    dispatch(handleSearchList(searchText));
  }, [dispatch, searchText]);

  const addAnnouncement = () => {
    dispatch(clearAnnouncement());
    dispatch(showAnnouncementAddModal());
  };

  const cancelSearch = () => {
    setSearchText("");
    dispatch(resetSearch());
    dispatch(handleHeaderFilter(currentFilter, 1));
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const handleAction = (item) => {
    dispatch(announcementSettingEnded(item));
  };

  const handleSelectAll = () => {
    let newSelected = _.cloneDeep(selected);

    if (newSelected.length === displayAnnouncements.length) {
      newSelected = [];
    } else {
      newSelected = [];
      displayAnnouncements.map((item) => newSelected.push(item.id));
    }
    dispatch(announcementSelected(newSelected));
  };

  const handleStatusChange = async (item) => {
    try {
      await setAllOfficialAnnouncementDisplay({ display_mode: item });
      dispatch(handleHeaderFilter(currentFilter, page));
    } catch (err) {
      console.log(
        "change all official announcements display mode err ----------",
        err
      );
    }
  };

  const handleSort = (type) => {
    let newOrder = null;

    if (!order || sortBy !== type) {
      newOrder = "desc";
    } else if (order === "desc") {
      newOrder = "asc";
    } else {
      newOrder = null;
    }

    dispatch(handleHeaderFilter(currentFilter, page, type, newOrder));
  };

  const renderStartTimeSort = () => {
    if (sortBy === "start_time" && order === "desc") {
      return <span className="active">▼</span>;
    } else if (sortBy === "start_time" && order === "asc") {
      return <span className="active">▲</span>;
    } else {
      return <span>▼</span>;
    }
  };

  const renderEndTimeSort = () => {
    if (sortBy === "end_time" && order === "desc") {
      return <span className="active">▼</span>;
    } else if (sortBy === "end_time" && order === "asc") {
      return <span className="active">▲</span>;
    } else {
      return <span>▼</span>;
    }
  };

  const renderStatusSelectList = () => {
    return statusSelectList.map((item) => {
      return (
        <li key={item.action} onClick={() => handleStatusChange(item.action)}>
          {item.title}
        </li>
      );
    });
  };

  const renderAction = () => {
    let btnStyle = `announcementHeader_action ${
      selected.length !== 0 ? "btn_effective" : "btn_disabled"
    }`;
    let disabled = selected.length === 0 ? true : false;

    if (
      currentTab === "shop" ||
      (currentTab === "official" && role === "official")
    ) {
      return (
        <div className={btnStyle}>
          <input
            type="checkbox"
            className="announcementHeader_action_check"
            checked={selected.length === displayAnnouncements.length}
            onChange={() => handleSelectAll()}
          />
          <div className="announcementHeader_action_right">
            <div className="announcementHeader_action-btn">
              <button
                disabled={disabled}
                onClick={() => handleAction("deleted")}
              >
                <Space size={4}>
                  <span className="icon-normal delete-mask-icon" />
                  <span>{t("list.delete")}</span>
                </Space>
              </button>
              {currentFilter !== "finished" && (
                <button
                  disabled={disabled}
                  onClick={() => handleAction("finished")}
                >
                  <Space size={4}>
                    <span className="icon-normal calendar-mask-icon" />
                    <span>{t("list.setAsExpired")}</span>
                  </Space>
                </button>
              )}
            </div>
            <div className="announcementHeader_action_sort">
              <div className="start" onClick={() => handleSort("start_time")}>
                {renderStartTimeSort()}
                {t("list.startDate_2")}
              </div>
              <div className="end" onClick={() => handleSort("end_time")}>
                {renderEndTimeSort()}
                {t("list.endDate_2")}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="announcementHeader_action announcementHeader_action_small">
          <div
            className="statusCardDropDown"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={`${
                currentFilter === "finished" ? "editDropdown_disabled" : ""
              } editDropdown`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              disabled={currentFilter === "finished"}
            >
              {t("list.popupOnHomepage")}
              <span className="dropdownIcon" />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <ul>{renderStatusSelectList()}</ul>
            </div>
          </div>
          <div className="announcementHeader_action_sort">
            <div className="start" onClick={() => handleSort("start_time")}>
              {renderStartTimeSort()}
              {t("list.startDate_2")}
            </div>
            <div className="end" onClick={() => handleSort("end_time")}>
              {renderEndTimeSort()}
              {t("list.endDate_2")}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="announcementHeader">
        <h2 className="heading-2-responsive">{t("list.bulletin")}</h2>
        <div className="announcementHeader-tab">
          <Tab />
        </div>
        <Space size={8} className="actionBtn">
          <div className="action-display-button">
            <ExpandSearchInput
              ref={searchInputRef}
              handleInput={handleSearch}
              placeholder={t("list.placeholder_search")}
              clearResult={cancelSearch}
            />
          </div>
          <button
            onClick={addAnnouncement}
            type="button"
            className="button-round-big button-primary"
          >
            <img
              className="crossImg_add"
              src={require("../../../images/icon_cross.svg")}
              alt=""
            />
            <span style={{ verticalAlign: "middle" }}>
              {t("editAnnouncement.announcement")}
            </span>
          </button>
        </Space>
      </div>
      <Filter />
      {renderAction()}
    </>
  );
};

export default AnnouncementHeader;
