import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Modal, Space } from "antd";
import { useTranslation } from "react-i18next";

import {
  announcementFetchAll,
  handleHeaderFilter,
  announcementOfficialFetch,
  announcementFetchMonth,
  updateDayAnnouncements,
  announcementSelected,
  announcementSettingCancel,
  clearAnnouncement,
  handleSearchList,
} from "../../../actions/announcementAction";

import {
  deleteAnnouncement,
  updateAnnouncement,
  bulkDeleteAnnouncement,
  bulkFinishedAnnouncement,
} from "../api/AnnouncementApi";
import {
  updateOfficialAnnouncement,
  deleteOfficialAnnouncement,
  bulkDeleteOfficialAnnouncement,
  bulkFinishedOfficialAnnouncement,
} from "../api/OfficialAnnouncementApi";

const AnnouncementCancelModal = () => {
  const { t } = useTranslation("bulletinSystem");
  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const {
    role,
    currentDate,
    currentFilter,
    selected,
    settingType,
    showAnnouncementListModal,
    searchListTxt,
  } = announcementListReducer;
  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const {
    id,
    category,
    title,
    content,
    startDateTime,
    endDateTime,
    showEnabled,
    officialShowEnabled,
  } = announcementInfo;
  const dispatch = useDispatch();

  const cancelPopup = () => {
    dispatch(announcementSettingCancel());
  };

  const handleDeleted = async () => {
    // 多選
    if (!id && selected.length !== 0) {
      try {
        await bulkDeleteAnnouncement({ id: selected });

        dispatch(announcementSelected([]));
        dispatch(announcementFetchAll());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage(t("popup.deleteSuccess"), "done");

        if (searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt));
        }
      } catch (err) {
        console.log("bulk deleted announcements err ----------", err);
      }
    } else {
      try {
        await deleteAnnouncement(id);

        dispatch(announcementFetchAll());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage(t("popup.deleteSuccess"), "done");

        if (showAnnouncementListModal) {
          dispatch(updateDayAnnouncements(currentDate));
          dispatch(announcementFetchMonth(currentDate));
        }

        if (searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt));
        }
      } catch (err) {
        console.log("deleted announcements err ----------", err);
      }
    }
  };

  const handleOfficialDeleted = async () => {
    if (!id && selected.length !== 0) {
      try {
        await bulkDeleteOfficialAnnouncement({ id: selected });

        dispatch(announcementSelected([]));
        dispatch(announcementOfficialFetch());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage(t("popup.deleteSuccess"), "done");

        if (searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt));
        }
      } catch (err) {
        console.log("bulk deleted official announcements err ----------", err);
      }
    } else {
      try {
        await deleteOfficialAnnouncement(id);

        dispatch(announcementOfficialFetch());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage(t("popup.deleteSuccess"), "done");

        if (searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt));
        }
      } catch (err) {
        console.log("deleted official announcement err ----------", err);
      }
    }
  };

  const handleFinished = async () => {
    let announcement = {
      title: title,
      content: content,
      category: category,
      finished: true,
      start_time: moment(startDateTime),
      end_time: moment(endDateTime),
      show_enabled: showEnabled,
    };

    if (!id && selected.length !== 0) {
      try {
        await bulkFinishedAnnouncement({ id: selected });
        dispatch(announcementSelected([]));
        dispatch(announcementFetchAll());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage(t("popup.finishSuccess"), "done");

        if (searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt));
        }
      } catch (err) {
        console.log("bulk finished announcements err ----------", err);
      }
    } else {
      try {
        await updateAnnouncement(id, announcement);
        dispatch(announcementFetchAll());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage(t("popup.finishSuccess"), "done");

        if (showAnnouncementListModal) {
          dispatch(updateDayAnnouncements(currentDate));
          dispatch(announcementFetchMonth(currentDate));
        }

        if (searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt));
        }
      } catch (err) {
        console.log("finished announcements err ----------", err);
      }
    }
  };

  const handleOfficialFinished = async () => {
    let announcement = {
      title: title,
      content: content,
      category: "regular",
      start_time: moment(startDateTime),
      end_time: moment(endDateTime),
      finished: true,
      enabled: officialShowEnabled,
    };

    if (!id && selected.length !== 0) {
      try {
        await bulkFinishedOfficialAnnouncement({ id: selected });

        dispatch(announcementSelected([]));
        dispatch(announcementOfficialFetch());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage(t("popup.finishSuccess"), "done");

        if (searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt));
        }
      } catch (err) {
        console.log("bulk finished official announcements err ----------", err);
      }
    } else {
      try {
        await updateOfficialAnnouncement(id, announcement);

        dispatch(announcementOfficialFetch());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage(t("popup.finishSuccess"), "done");

        if (searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt));
        }
      } catch (err) {
        console.log("finished official announcements err ----------", err);
      }
    }
  };

  const renderBtn = () => {
    if (settingType === "deleted") {
      return (
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={() => cancelPopup()}
          >
            {t("settings:cancel")}
          </button>
          <button
            className="button-common button-primary"
            onClick={role === "shop" ? handleDeleted : handleOfficialDeleted}
          >
            {t("list.delete")}
          </button>
        </Space>
      );
    } else {
      return (
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={() => cancelPopup()}
          >
            {t("settings:cancel")}
          </button>
          <button
            className="button-common button-primary"
            onClick={role === "shop" ? handleFinished : handleOfficialFinished}
          >
            {t("settings:ok")}
          </button>
        </Space>
      );
    }
  };

  const renderContent = () => {
    const selectedText = t("popup.selectNum", { number: selected.length });

    if (settingType === "deleted") {
      return (
        <div>
          {!id && selectedText}
          {t("popup.delete_content")}
        </div>
      );
    } else {
      return (
        <div>
          {!id && selectedText}
          {t("popup.finish_content")}
        </div>
      );
    }
  };

  return (
    <Modal
      title={
        settingType === "deleted"
          ? t("popup.delete_title")
          : t("popup.finish_title")
      }
      visible
      destroyOnClose
      centered
      width={374}
      closable={false}
      className="modal-base modal-sm"
      onCancel={cancelPopup}
      footer={renderBtn()}
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {renderContent()}
    </Modal>
  );
};

export default AnnouncementCancelModal;
