import React from "react";
import { Space, Divider } from "antd";
import { useTranslation } from "react-i18next";

import verifiedIcon from "../../images/icon_verified.svg";
import disproveIcon from "../../images/icon-disprove.svg";
import alertIcon from "../../images/icon-alertRound.svg";

function NotificationTypeStatus({ type, email, sms, line, credits }) {
  const { t } = useTranslation("settings");

  const getStatus = (label, isEnabled, hasCredits) => {
    if (!isEnabled) return { icon: disproveIcon, className: "color-intro" };
    if (label !== "email" && !hasCredits)
      return { icon: alertIcon, className: "color-subtitle" };
    return { icon: verifiedIcon, className: "color-subtitle" };
  };

  const NotificationItem = ({ label, isEnabled }) => {
    const { icon, className } = getStatus(label, isEnabled, credits > 0);
    return (
      <Space size={4} align="center" className={`text-body ${className}`}>
        <img src={icon} alt="" />
        {label}
      </Space>
    );
  };

  return (
    <Space size={8} className="notification-status">
      <NotificationItem
        label="email"
        isEnabled={email?.online || email?.offline}
      />
      <Divider type="vertical" />
      <NotificationItem
        label={t("notificationCommon.sms")}
        isEnabled={sms?.online || sms?.offline}
      />
      <Divider type="vertical" />
      <NotificationItem
        label="LINE"
        isEnabled={line?.online || line?.offline}
      />
    </Space>
  );
}

export default NotificationTypeStatus;
