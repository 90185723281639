import React from "react";

import "../../sass/PageSlogan.scss";

const PageSlogan = () => {
  return (
    <div className="pageSlogan">
      <img src={require("../../images/pageIdx/logo2_r.png")} alt="" />
      <h1>輕鬆管理店家大小事，就找MENU店 +</h1>
    </div>
  );
};

export default PageSlogan;