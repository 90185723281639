import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { browserHistory } from "react-router";
import { Space } from "antd";

import { updateDayAnnouncements } from "../../../actions/announcementAction";

const MonthTableRowCell = ({
  queryDate,
  date,
  vacantStyle,
  bookingCount,
  bookingAttendanceCount,
  isToday,
  unconfirmedCount,
  changeUrlDate,
}) => {
  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const { monthAnnouncements } = announcementListReducer;
  const effectAnnouncement = monthAnnouncements[queryDate];
  const dispatch = useDispatch();

  const urlDate = browserHistory.getCurrentLocation().query.date;
  const selectDate = !isToday && urlDate === queryDate ? "selectDate" : "";

  const selectedDate = () => {
    changeUrlDate(queryDate);
    dispatch(updateDayAnnouncements(queryDate));
  };

  return (
    <div
      className={`monthTableRow__cell btn-effect ${vacantStyle} ${isToday} ${selectDate}`}
      onClick={() => selectedDate()}
    >
      <span className="date">{date}</span>
      {effectAnnouncement && effectAnnouncement.length > 0 ? (
        <div className="icon-round">
          <span className="bulletin-remind-icon icon-sm" />
        </div>
      ) : null}
      <Space
        size={2}
        direction="vertical"
        align="start"
        className="totalAttendance"
      >
        {bookingCount ? (
          <Space size={2} className="bookingSystem">
            <div className="reservation-icon icon-sm" />
            {bookingCount}
          </Space>
        ) : null}
        {bookingAttendanceCount ? (
          <Space size={2} className="bookingAttendanceCount">
            <div className="account-icon icon-sm" />
            {bookingAttendanceCount}
          </Space>
        ) : null}
      </Space>
      {unconfirmedCount ? <span className="unconfiremedStyle" /> : null}
    </div>
  );
};

export default MonthTableRowCell;
