import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import { useTranslation } from 'react-i18next';
import { Modal, Switch, DatePicker, Space } from 'antd';
import _ from 'lodash';
import classNames from 'classnames';
import TimeKeyboard from '../utils/timeKeyboard';
import { bookingTimeRangeOptions, hourList, minList } from '../../../utils/constants';

const dateFormatList = [ 'YYYY/MM/DD', 'YYYY/MM/DD' ];

const DateConfigModal = ({
	status,
	editSetting,
	closeEditor,
	updateSettings,
	serviceTimeOptionEnabled
}) => {
	const { t, i18n } = useTranslation('settings');
	const [ setting, setSetting ] = useState(editSetting);
	const [ visible, setVisible ] = useState(true);
	const [ errorTime, setErrorTime ] = useState([]);
	const [ toggleTimePicker, setToggleTimePicker ] = useState(false);
	const [ timeRangeId, setTimeRangeId ] = useState(null);
	const [ timePickerSetting, setTimePickerSetting ] = useState(null);
	const [ num, setNum ] = useState(0);

	const handleDateChangeFrom = (date) => {
		const newSetting = _.cloneDeep(setting);
		
		newSetting.start_date = date ? date.format('YYYY/MM/DD') : null;

		setSetting(newSetting);
	};

	const handleDateChangeTo = (date) => {
		const newSetting = _.cloneDeep(setting);

		newSetting.end_date = date ? date.format('YYYY/MM/DD') : null;

		setSetting(newSetting);
	};

	const handleEnableChangeAction = (enable) => {
		const newSetting = _.cloneDeep(setting);

		newSetting['setting_temp']['enable'] = enable;

		setSetting(newSetting);
	};

	const handleDiningTimeModeChange = (type) => {
		const newSetting = _.cloneDeep(setting);

		newSetting.service_time_setting[0].mode = type;

		setSetting(newSetting);
	};

	const handleTimeRangeChange = (type, id, time) => {
		const newErrorTime = _.cloneDeep(errorTime);
		const removeIndex = newErrorTime.indexOf(id);

		if (type === 'toTime') {
			setNum(1);
		} else {
			setNum(0);
		}

		if (removeIndex !== -1) newErrorTime.splice(removeIndex, 1);

		setTimeRangeId(id);
		openNestTimePicker(time);
		setErrorTime(newErrorTime);
	};

	const handleTimeRangeDelete = (index) => {
		let newSetting = _.cloneDeep(setting);
		newSetting.setting_temp.setting2.splice(index, 1);
		setSetting(newSetting);
	};

	const handleAddTimeRangeAction = () => {
		let newSetting = _.cloneDeep(setting);
		newSetting.setting_temp.setting2.push([ '00:00', '00:30' ]);
		setSetting(newSetting);
	};

	const handleTimeCellDelete = (idx) => {
		const newSetting = _.cloneDeep(setting);
		newSetting.setting_temp.setting3.splice(idx, 1);
		setSetting(newSetting);
	};

	const openNestTimePicker = (time) => {
		setTimePickerSetting(time);
		setToggleTimePicker(true);
	};

	const renderEnableTime_2 = () => {
		if (parseInt(setting.setting_temp.enable, 10) === 2) {
			let idx = 1;
			return (
				<div>
					<button className="button-tertiary"onClick={() => handleAddTimeRangeAction()}>
						+{t('bookingTimesCommon.addPeriod')}
					</button>
					{setting.setting_temp.setting2.map((range, id) => {
						const error_index = errorTime.indexOf(id);
						let errorStyle = false;
						if (error_index !== -1) errorStyle = true;
						return (
							<div key={id} className="setting_temp2">
								<span className='time-name time-text'>{t('bookingTimesCommon.period')}{idx ++}</span>
								<div className='horizontal-center'>
									<span className='time-text'>{t('bookingTimesCommon.from')}</span>
									<div
										className={classNames('time-select', {
											errorStyle: errorStyle
										})}
										onClick={() => handleTimeRangeChange('fromTime', id, range[0])}
									>
										{range[0]}
									</div>
									<span className='time-text'>{t('bookingTimesCommon.to')}</span>
									<div
										className={classNames('time-select', {
											errorStyle: errorStyle
										})}
										onClick={() => handleTimeRangeChange('toTime', id, range[1])}
									>
										{range[1]}
									</div>
								</div>
								<button className="msLink2r" onClick={() => handleTimeRangeDelete(id)}>
									<i className="fa fa-trash-o" aria-hidden="true" />
								</button>
							</div>
						);
					})}
				</div>
			);
		}
	};

	const renderEnableTime_3 = () => {
		if (parseInt(setting.setting_temp.enable, 10) === 3) {
			return (
				<div>
					<button className="button-tertiary"onClick={() => openNestTimePicker('00:00')}>
						+{t('bookingTimesCommon.addPeriod')}
					</button>
					<div className="setting_temp3__time">
						{setting.setting_temp.setting3.map((time, idx) => {
							return (
								<div key={idx} className="setting_temp3__time__cell">
									<span className="setting_temp3">
										{time}
										<span className="msLink2r" onClick={() => handleTimeCellDelete(idx)} />
									</span>
								</div>
							);
						})}
					</div>
				</div>
			);
		}
	}

	const closeNestModalSetting = useCallback(
		(newTime) => {
			let newSetting = _.cloneDeep(setting);
			newSetting.setting_temp.setting2[timeRangeId][num] = newTime;
			setSetting(newSetting);
		},
		[ num, setting, timeRangeId ]
	);

	const closeNaddTimeCell = useCallback(
		(newTime) => {
			let newSetting = _.cloneDeep(setting);
			newSetting.setting_temp.setting3.push(newTime);
			setSetting(newSetting);
		},
		[ setting ]
	);

	const timeCellSubmit = useCallback(
		(newTime) => {
			if (setting.setting_temp.enable === 2) {
				closeNestModalSetting(newTime);
			} else if (setting.setting_temp.enable === 3) {
				closeNaddTimeCell(newTime);
			}

			setTimePickerSetting(null);
			setTimeRangeId(null);
			setToggleTimePicker(false);
		},
		[ closeNaddTimeCell, closeNestModalSetting, setting.setting_temp.enable ]
	);

	const hadleBookingTimeInterval = (interval) => {
		const newSetting = _.cloneDeep(setting);
		newSetting['booking_time_interval'] = interval;
		setSetting(newSetting);
	};

	const renderBookingTimeRange = () => {
		if (parseInt(setting.setting_temp.enable, 10) === 2) {
			return (
				<div className="row" style={{ marginTop: '24px'}}>
					<div className="col-sm-4 dateConfigModal_select_title">
						{t('bookingTimesCommon.timeSlotsInterval')}
					</div>

					<div className="col-sm-8 p-0">
						<select
							className="diningTime__select dateConfigModal_select_margin"
							value={setting.booking_time_interval}
							onChange={(e) => hadleBookingTimeInterval(parseInt(e.target.value, 10))}
						>
							{bookingTimeRangeOptions.map((time) => {
								return <option key={time}>{time}</option>;
							})}
						</select>
						<span className="diningTime__txt">{t('time:minute')}</span>
					</div>
				</div>
			);
		}
	};

	const handledDiningTimeInterval = (interval)=>{
		let newSetting = _.cloneDeep(setting)

		newSetting.service_time_setting[0].fixed.unit = interval
		setSetting(newSetting)
	}

	const renderBookingTimeRange_fixed = () => {
		if(setting.service_time_setting[0].mode === 'fixed') {
			return (
				<div className="row" style={{marginBottom: '0px', marginTop: '24px'}}>
					<div className="col-sm-4 dateConfigModal_select_title">
						{t('bookingTimesCommon.timeDurationIntervals')}
					</div>
					<div className="col-sm-8 p-0">
						<select
							className="diningTime__select dateConfigModal_select_margin"
							value={setting.service_time_setting[0].fixed.unit}
							onChange={(e) => handledDiningTimeInterval(parseInt(e.target.value, 10))}
						>
							{bookingTimeRangeOptions.map((time) => {
								return <option key={time}>{time}</option>;
							})}
						</select>
						<span className="diningTime__txt">{t('time:minute')}</span>
					</div>
				</div>
			);
		}
	};

	const renderDiningTimeOptions = () => {
		return (
			<div>
				<div className="col-sm-12 p-0">
					<div className="radio bsTestRadio">
						<label style={{ marginBottom: 0 }}>
							<input
								type="radio"
								name="optionsRadios2"
								onChange={(e) => handleDiningTimeModeChange('user_setting')}
								id="optionsRadios3"
								value="4"
								checked={setting.service_time_setting[0].mode === 'user_setting'}
							/>
							<span style={{marginLeft: '20px'}}>{` ${t('bookingTimesCommon.customServiceDurationOptions')}`}</span>
						</label>
					</div>
					<div className="col-sm-12 p-0">{renderModalDiningTime_userSetting()}</div>
						<div className="col-sm-12 p-0">
							<div className="radio bsTestRadio">
								<label>
									<input
										type="radio"
										name="optionsRadios2"
										onChange={(e) => handleDiningTimeModeChange('fixed')}
										id="optionsRadios4"
										value="5"
										className='setting-radio-with-note-position'
										checked={setting.service_time_setting[0].mode === 'fixed'}
									/>
									<span style={{marginLeft: '20px'}}>{` ${t('bookingTimesCommon.regularIntervalsOfTimeDurationOptions')}`}</span>
									<div className="dateConfigModal_subtitle setting-radio-subtitle-position">{t('bookingTimesCommon.regularIntervalsOfTimeDurationOptions_note')}</div>
								</label>
							</div>
							<div className="col-sm-12 p-0">{renderModalDiningTime_fixed()}</div>
							<div className="col-sm-12 p-0">{renderBookingTimeRange_fixed()}</div>
						</div>
					</div>
			</div>
		)
	}

	const handleDiningTimeChangeAction = (newTime) => {
		const newSetting = _.cloneDeep(setting);
		newSetting.dining_time = newTime;
		setSetting(newSetting);
	};
		
	const addUpdateDiningTime = (type, e) => {
		const { dining_time } = setting;
		let add_dining_time_hour = Math.floor(parseInt(dining_time, 10) / 60),
		add_dining_time_min = parseInt(dining_time, 10) % 60;
		let val = e.target.value;
		let new_dining_time = 0;
		
		if (type === 'hour') {
			if (add_dining_time_min === 0 && parseInt(val, 10) === 0) {
				window.app.alert.setMessage(t('bookingTimesCommon.atLeast5mins'), 'error');
			} else {
				new_dining_time = parseInt(val, 10) * 60 + parseInt(add_dining_time_min, 10);
				handleDiningTimeChangeAction(new_dining_time);
			}
		} else if (type === 'min') {
			if (parseInt(add_dining_time_hour, 10) === 0 && parseInt(val, 10) === 0) {
				window.app.alert.setMessage(t('bookingTimesCommon.atLeast5mins'), 'error');
			} else {
				new_dining_time = parseInt(add_dining_time_hour, 10) * 60 + parseInt(val, 10);
				handleDiningTimeChangeAction(new_dining_time);
			}
		}
	};

	const renderFixedDiningTime = () => {
		let dining_time_hour = Math.floor(parseInt(setting.dining_time, 10) / 60)
		let dining_time_min = Math.floor(parseInt(setting.dining_time, 10) % 60)

		return (
			<div className="col-sm-12 p-0 fixedDiningTime" style={{ marginTop: '24px' }}>
				<select
					className="fixedDiningTime__select"
					value={dining_time_hour}
					onChange={(e) => addUpdateDiningTime('hour', e)}
				>
					{hourList.map((h) => {
						return (
							<option key={h} value={h}>
								{h}
							</option>
						);
					})}
				</select>
				<span className="diningTime__txt">{t('time:hour')}</span>
				<select
					className="fixedDiningTime__select"
					value={dining_time_min}
					onChange={(e) => addUpdateDiningTime('min', e)}
				>
					{minList.map((m) => {
						return (
							<option key={m} value={m}>
								{m}
							</option>
							);
						})}
					</select>
					<span className="diningTime__txt">{t('time:minute')}</span>
			</div>
		)
	}

	const handleDiningTimeAction_userSetting=(newTime)=>{
		const newSetting= _.cloneDeep(setting)
		newSetting.service_time_setting[0].user_setting.service_time_setting = newTime
		setSetting(newSetting)
	}

	const handleDiningTimeUpdate = (type, id, e) => {
		let val = e.target.value;
		let new_dining_time = 0;
		let diningTime = _.cloneDeep(setting.service_time_setting[0].user_setting.service_time_setting);

		if (type === "hour") {
			if (Math.floor(parseInt(diningTime[id].service_time, 10) % 60) === 0 && parseInt(val, 10) === 0) {
				window.app.alert.setMessage(t('bookingTimesCommon.atLeast5mins'), "error");
			} else {
				new_dining_time =
					parseInt(val, 10) * 60 + parseInt(Math.floor(parseInt(diningTime[id].service_time, 10) % 60), 10);
				diningTime[id].service_time = new_dining_time;
				handleDiningTimeAction_userSetting(diningTime);
			}
		} else if (type === "min") {
			if (
				parseInt(Math.floor(parseInt(diningTime[id].service_time, 10) / 60), 10) === 0 &&
				parseInt(val, 10) === 0
			) {
				window.app.alert.setMessage(t('bookingTimesCommon.atLeast5mins'), "error");
			} else {
				new_dining_time =
					parseInt(Math.floor(parseInt(diningTime[id].service_time, 10) / 60), 10) * 60 + parseInt(val, 10);
				diningTime[id].service_time = new_dining_time;
				handleDiningTimeAction_userSetting(diningTime);
			}
		}
	}

	const handleDiningTimeNameUpdate = (id, e) => {
		let newSetting = _.cloneDeep(setting);
		let val = e.target.value;
		const diningTimeNameValid = document.querySelectorAll('.diningTime_option_name')[id]

		if(val.length > 0) {
			diningTimeNameValid.style.borderColor = '#c8c8c8';
			diningTimeNameValid.style.outlineColor = '#1890ff';
		} else {
			diningTimeNameValid.style.borderColor = '#CE4949';
			diningTimeNameValid.style.outlineColor = '#CE4949';
		}
		newSetting.service_time_setting[0].user_setting.service_time_setting[id].name = val;
		setSetting(newSetting);
	}

	const handleDiningTimeShowTime = (e) => {
		let newSetting = _.cloneDeep(setting);

		newSetting.service_time_setting[0].user_setting.show_name = !setting.service_time_setting[0].user_setting.show_name;
		setSetting(newSetting);
	}

	const handleDiningTimeDelete = (index) => {
		let newSetting = _.cloneDeep(setting);

		newSetting.service_time_setting[0].user_setting.service_time_setting.splice(index, 1);
		setSetting(newSetting);
	};

	const handleAddDiningTimeAction = () => {
		let newSetting = _.cloneDeep(setting);

		newSetting.service_time_setting[0].user_setting.service_time_setting.push({name: '選項名稱', service_time: '30'});

		setSetting(newSetting);
	};

	const renderModalDiningTime_userSetting = () => {
		let idx = 1;

		if(setting.service_time_setting[0].mode === 'user_setting') {
			return (
				<div className="diningTime">
					<div className="diningTime_name_switch_block">
						<div className="diningTime_name_switch">
							<div className="diningTime_name_title">{t('bookingTimesCommon.customizeOptionNames')}</div>
							<Switch
								checked={setting.service_time_setting[0].user_setting.show_name}
								onChange={() => handleDiningTimeShowTime()}
							/>
						</div>
						<div className="diningTime_name_txt">{t('bookingTimesCommon.customizeOptionNames_tooltip')}</div>
					</div>
					<button
						style={{ marginTop: '24px' }}
						className="button-tertiary"
						onClick={() => handleAddDiningTimeAction()}
					>
						+{t('bookingTimesCommon.addOption')}
					</button>
					{setting.service_time_setting[0].user_setting.service_time_setting.map((range, id) => {
						return (
							<div key={id} className="diningTime_option">
								<div className="diningTime_option_index">{t('bookingTimesCommon.option')}{idx ++}</div>
								{
									setting.service_time_setting[0].user_setting.show_name === true ? (
										<div style={{marginTop: '8px', marginBottom: '-8px'}}>
											<input
												className="diningTime_option_name"
												type="text"
												value={setting.service_time_setting[0].user_setting.service_time_setting[id].name}
												onChange={(e) => handleDiningTimeNameUpdate(id, e)}
												maxLength="20"
												placeholder={t('bookingTimesCommon.placeholder_enterOptionName')}
												/>
											{
												setting.service_time_setting[0].user_setting.service_time_setting.length > 1 ? (
												<button 
													className="msLink2r msLink2r_userSetting"
													onClick={() => handleDiningTimeDelete(id)}
												>
													<i className="fa fa-trash-o" aria-hidden="true"></i>
												</button>
												) : null
											}
										</div>
									) : null
								}
								<div className='diningTime__select_option'>
									<div>
										<select
											className="diningTime__select_userSetting"
											value={Math.floor(parseInt(setting.service_time_setting[0].user_setting.service_time_setting[id].service_time, 10) / 60)}
											onChange={(e) => handleDiningTimeUpdate('hour', id, e)}
										>
											{hourList.map((h) => {
												return (
													<option key={h} value={h}>
														{h}
													</option>
												);
											})}
										</select>
										<span className="diningTime__txt">{t('time:hour')}</span>
										<select
											className="diningTime__select_userSetting"
											value={Math.floor(parseInt(setting.service_time_setting[0].user_setting.service_time_setting[id].service_time, 10) % 60)}
											onChange={(e) => handleDiningTimeUpdate('min', id, e)}
										>
											{minList.map((m) => {
												return (
													<option key={m} value={m}>
														{m}
													</option>
												);
											})}
										</select>
										<span className="diningTime__txt">{t('time:minute')}</span>

									</div>
									{
										(setting.service_time_setting[0].user_setting.show_name !== true) && (setting.service_time_setting[0].user_setting.service_time_setting.length > 1) ? (
										<button 
											className="msLink2r"
											onClick={() => handleDiningTimeDelete(id)}
										>
											<i className="fa fa-trash-o" aria-hidden="true"></i>
										</button>
										) : null
									}

								</div>
							</div>
						)
					})}
				</div>
			);
		}
	};

	const handleDiningTimeAction_fixed=(type, newTime)=>{
		const newSetting= _.cloneDeep(setting)
		if(type === 'max') {
			newSetting.service_time_setting[0].fixed.max = newTime
			setSetting(newSetting)
		} else if (type === 'min') {
			newSetting.service_time_setting[0].fixed.min = newTime
			setSetting(newSetting)
		}
	}

	const handleDiningTimeFixed = (type_1, type_2, e) => {
		let val = e.target.value;
		let new_dining_time = 0;

		// 從最短服務時間
		if (type_1 === 'min') {
			let add_dining_time_hour = Math.floor(parseInt(setting.service_time_setting[0].fixed.min, 10) / 60),
			add_dining_time_min = parseInt(setting.service_time_setting[0].fixed.min, 10) % 60;

			if (type_2 === "hour") {
				if (add_dining_time_min === 0 && parseInt(val, 10) === 0) {
					window.app.alert.setMessage(t('bookingTimesCommon.atLeast5mins'), "error");
				} else {
					new_dining_time =
						parseInt(val, 10) * 60 + parseInt(add_dining_time_min, 10);
						handleDiningTimeAction_fixed('min', new_dining_time);
				}
			} else if (type_2 === "min") {
				if (
					parseInt(add_dining_time_hour, 10) === 0 &&
					parseInt(val, 10) === 0
				) {
					window.app.alert.setMessage(t('bookingTimesCommon.atLeast5mins'), "error");
				} else {
					new_dining_time =
						parseInt(add_dining_time_hour, 10) * 60 + parseInt(val, 10);
						handleDiningTimeAction_fixed('min', new_dining_time);
				}
			}
		} 
		// 到最長服務時間
		else if (type_1 === 'max') {
			let add_dining_time_hour = Math.floor(parseInt(setting.service_time_setting[0].fixed.max, 10) / 60),
			add_dining_time_min = parseInt(setting.service_time_setting[0].fixed.max, 10) % 60;

			if (type_2 === "hour") {
				if (add_dining_time_min === 0 && parseInt(val, 10) === 0) {
					window.app.alert.setMessage(t('bookingTimesCommon.atLeast5mins'), "error");
				} else {
					new_dining_time =
						parseInt(val, 10) * 60 + parseInt(add_dining_time_min, 10);
						handleDiningTimeAction_fixed('max', new_dining_time);
				}
			} else if (type_2 === "min") {
				if (
					parseInt(add_dining_time_hour, 10) === 0 &&
					parseInt(val, 10) === 0
				) {
					window.app.alert.setMessage(t('bookingTimesCommon.atLeast5mins'), "error");
				} else {
					new_dining_time =
						parseInt(add_dining_time_hour, 10) * 60 + parseInt(val, 10);
						handleDiningTimeAction_fixed('max', new_dining_time);
				}
			}
		}
	}

	const renderModalDiningTime_fixed = () => {
		let min_dining_time_hour = Math.floor(parseInt(setting.service_time_setting[0].fixed.min, 10) / 60)
		let min_dining_time_min = Math.floor(parseInt(setting.service_time_setting[0].fixed.min, 10) % 60)
		let max_dining_time_hour = Math.floor(parseInt(setting.service_time_setting[0].fixed.max, 10) / 60)
		let max_dining_time_min = Math.floor(parseInt(setting.service_time_setting[0].fixed.max, 10) % 60)

		if(setting.service_time_setting[0].mode === 'fixed') {
			return (
				<div className="diningTime">
					<div>
						<span className="diningTime_option diningTime_range">{t('bookingTimesCommon.minimum')}</span>
						<select
							className="diningTime__select"
							value={min_dining_time_hour}
							onChange={(e) => handleDiningTimeFixed('min','hour', e)}
						>
							{hourList.map((h) => {
								return (
									<option key={h} value={h}>
										{h}
									</option>
								);
							})}
						</select>
						<span className="diningTime__txt">{t('time:hour')}</span>
						<select
							className="diningTime__select"
							value={min_dining_time_min}
							onChange={(e) => handleDiningTimeFixed('min','min', e)}
						>
							{minList.map((m) => {
								return (
									<option key={m} value={m}>
										{m}
									</option>
								);
							})}
						</select>
						<span className="diningTime__txt">{t('time:minute')}</span>
					</div>
					<div>
						<span className="diningTime_option diningTime_range">{t('bookingTimesCommon.maximum')}</span>
						<select
							className="diningTime__select"
							value={max_dining_time_hour}
							onChange={(e) => handleDiningTimeFixed('max','hour', e)}
						>
							{hourList.map((h) => {
								return (
									<option key={h} value={h}>
										{h}
									</option>
								);
							})}
						</select>
						<span className="diningTime__txt">{t('time:hour')}</span>
						<select
							className="diningTime__select"
							value={max_dining_time_min}
							onChange={(e) => handleDiningTimeFixed('max','min', e)}
						>
							{minList.map((m) => {
								return (
									<option key={m} value={m}>
										{m}
									</option>
								);
							})}
						</select>
						<span className="diningTime__txt">{t('time:minute')}</span>
					</div>
				</div>
			);
		}
	};

	const handleMaxPeopleChangeAction = (people) => {
		let newSetting = _.cloneDeep(setting);
		newSetting.max_people_at_a_time = people;
		setSetting(newSetting);
	};

	const maxPeople = useMemo(() => {
		let people = [];
		for (let i = 1; i <= 300; i++) {
			people.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}
		return people;
	}, []);

	const submitModal = () => {

		if(!setting.start_date || !setting.end_date){
			window.app.alert.setMessage(t('bookingTimesCommon.pls_selectAtLeastOneBusinessDay'), 'tip');
			return;
		}

		if(moment(setting.end_date, 'YYYY/MM/DD').isBefore(moment(setting.start_date, 'YYYY/MM/DD'))){
			window.app.alert.setMessage(t('specialOperatingDates.pls_invalidBusinessDateRange'), 'tip');
			return;
		}

		if (parseInt(setting.setting_temp.enable, 10) === 2) {
			const rangeTimeLength = setting.setting_temp.setting2.length;

			if (rangeTimeLength === 0) {
				window.app.alert.setMessage(t('bookingTimesCommon.pls_addAtLeast1timeSlot'), 'tip');
				return;
			}
		}

		if (parseInt(setting.setting_temp.enable, 10) === 3) {
			const rangeTimeLength = setting.setting_temp.setting3.length;

			if (rangeTimeLength === 0) {
				window.app.alert.setMessage(t('bookingTimesCommon.pls_addAtLeast1timeSlot'), 'tip');
				return;
			}
		}

		const newErrorTime = [];
		setting.setting_temp.setting2.map((r, index) => {
			if (r[0] > r[1]) {
				window.app.alert.setMessage(t('bookingTimesCommon.pls_startTimeCannotBeLaterEndTime'), 'tip');
				newErrorTime.push(index);
			}
			return true;
		});

		if (newErrorTime.length !== 0) {
			window.app.alert.setMessage(t('bookingTimesCommon.pls_startTimeCannotBeLaterEndTime'), 'tip');
			setErrorTime(newErrorTime);
			return;
		}

		// 服務時間 - 固定時段檢查
		if(setting.service_time_setting[0].mode === 'fixed') {
			if(setting.service_time_setting[0].fixed.min > setting.service_time_setting[0].fixed.max){
				window.app.alert.setMessage(t('bookingTimesCommon.pls_minCannotBeGreaterMax'), 'tip');
				return;
			}

			if((setting.service_time_setting[0].fixed.max - setting.service_time_setting[0].fixed.min) < setting.service_time_setting[0].fixed.unit){
				window.app.alert.setMessage(t('bookingTimesCommon.pls_serviceDurationInterval'), 'tip');
				return;
			}
		}

		// 服務時間 - 自訂時段檢查
		if(setting.service_time_setting[0].mode === 'user_setting') {
			if(setting.service_time_setting[0].user_setting.show_name) {
				for (let i=0; i<setting.service_time_setting[0].user_setting.service_time_setting.length-1; i++) {
					for (let j=i+1; j<setting.service_time_setting[0].user_setting.service_time_setting.length;j++) {
						if(setting.service_time_setting[0].user_setting.service_time_setting[i].name === setting.service_time_setting[0].user_setting.service_time_setting[j].name) {
							window.app.alert.setMessage(t('bookingTimesCommon.pls_duplicateServiceDurationOptionName', { X: i+1, Y: j+1 }), 'tip');
					return;
						}
					}
				}

				for(let i=0; i<setting.service_time_setting[0].user_setting.service_time_setting.length; i++) {
					if(setting.service_time_setting[0].user_setting.service_time_setting[i].name.trim().length === 0) {
						window.app.alert.setMessage(t('bookingTimesCommon.pls_enterNameOfServiceDuration', { X: i+1 }), 'tip');
						return;
					}
				}
			} else {
				for (let i=0; i<setting.service_time_setting[0].user_setting.service_time_setting.length-1; i++) {
					for (let j=i+1; j<setting.service_time_setting[0].user_setting.service_time_setting.length;j++) {
						if(setting.service_time_setting[0].user_setting.service_time_setting[i].service_time === setting.service_time_setting[0].user_setting.service_time_setting[j].service_time) {
							window.app.alert.setMessage(t('bookingTimesCommon.pls_duplicateServiceDurationOptionLength', { X: i+1, Y: j+1 }), 'tip');
					return;
						}
					}
				}
			}
		}

		updateSettings(setting);
	};

	return (
		<Modal
			className="dateConfigModal modal-base modal-xl setting-diningTime"
			centered
			destroyOnClose
			maskClosable={false}
			closable={false}
			title={status === 'add' ? t('bookingTimesCommon.addBookingTime') : t('bookingTimesCommon.editBookingTime')}
			visible={visible}
			width={640}
			wrapClassName="availableTimeSetting"
			bodyStyle={{ fontSize: 18 }}
			onCancel={() => {
				setVisible(false);
				closeEditor();
			}}
			footer={
				<Space size={10}>
					<button className="button-common button-secondary" onClick={closeEditor}>{t('cancel')}</button>
					<button className="button-common button-primary" onClick={submitModal}>{t('save')}</button>
				</Space>
			}
			transitionName="ant-move-down"
		>
			<div>
				<div className="row">
					<div className="col-sm-12 dateConfigModal_title">{t('bookingTimesCommon.selectOpenBusinessDays')}</div>

					<div className="col-sm-12 form-inline p-0">
						<DatePicker
							style={{marginTop: '16px'}}
							className="date-picker-normal"
							popupClassName='date-picker-popup'
							defaultValue={
								setting.start_date ? moment(setting.start_date, dateFormatList) : null
							}
							onChange={handleDateChangeFrom}
							placeholder={t('time:datePicker.start')}
							locale={i18n.language === 'zh' ? locale : null}
						/>
						{' '}-{' '}
						<DatePicker
							className="date-picker-normal"
							popupClassName='date-picker-popup'
							defaultValue={
								setting.end_date ? moment(setting.end_date, dateFormatList) : null
							}
							onChange={handleDateChangeTo}
							placeholder={t('time:datePicker.end')}
							locale={i18n.language === 'zh' ? locale : null}
						/>
					</div>
				</div>

				<div className="row form-inline">
					<div className="col-sm-12 dateConfigModal_title">{t('bookingTimesCommon.bookingTimeSlots')}</div>
					<div className="col-sm-12 dateConfigModal_subtitle">{t('bookingTimesCommon.bookingTimeSlots_note')}</div>
					<div className="col-sm-12 p-0">
						<div className="radio bsTestRadio">
							<label>
								<input
									type="radio"
									name="optionsRadios1"
									onChange={(e) => handleEnableChangeAction(parseInt(e.target.value, 10))}
									id="optionsRadios1"
									value="2"
									className='setting-radio-with-note-position'
									checked={setting.setting_temp.enable === 2}
								/>
								<span style={{marginLeft: '20px'}}>{` ${t('bookingTimesCommon.fixedInterval')}`}</span>
								<div className="dateConfigModal_subtitle setting-radio-subtitle-position">{t('bookingTimesCommon.fixedInterval_note')}</div>
							</label>
						</div>
						{renderEnableTime_2()}
						{renderBookingTimeRange()}
					</div>
					<div className="col-sm-12 p-0">
						<div className="radio bsTestRadio">
							<label>
								<input
									type="radio"
									name="optionsRadios1"
									onChange={(e) => handleEnableChangeAction(parseInt(e.target.value, 10))}
									id="optionsRadios2"
									className='setting-radio-with-note-position'
									value="3"
									checked={setting.setting_temp.enable === 3}
								/>
								<span style={{marginLeft: '20px'}}>{` ${t('bookingTimesCommon.customTimeSlots')}`}</span>
								<div className="dateConfigModal_subtitle setting-radio-subtitle-position">{t('bookingTimesCommon.customTimeSlots_note')}</div>
							</label>
						</div>
						{renderEnableTime_3()}
					</div>
				</div>
				<hr/>
				<div className="row form-inline">
					<div className="col-sm-12 dateConfigModal_title">{t('bookingTimesCommon.serviceDuration')}</div>
					<div className="col-sm-12 dateConfigModal_subtitle">{t('bookingTimesCommon.serviceDuration_tooltip')}</div>
					{ serviceTimeOptionEnabled ? renderDiningTimeOptions() : renderFixedDiningTime()}
				</div>
				<hr/>
				<div className="row" style={{margin: '0px'}}>
					<div className="col-sm-12 dateConfigModal_title">
						{t('bookingTimesCommon.concurrentBookingLimit')}
					</div>
					<div className="col-sm-12 p-0">
						<select
							style={{ width: '104px', marginTop: '16px' }}
							name="max_people_at_a_time"
							value={setting.max_people_at_a_time}
							onChange={(e) => handleMaxPeopleChangeAction(parseInt(e.target.value, 10))}
							className="form-control input-lg"
						>
							<option value={0}>{t('bookingTimesCommon.unlimited')}</option>
							{maxPeople}
						</select>
					</div>
				</div>
			</div>

			{toggleTimePicker && (
				<TimeKeyboard
					timePickerSetting={timePickerSetting}
					timeCellSubmit={timeCellSubmit}
					closeTimeCell={() => {
						setTimePickerSetting(null);
						setToggleTimePicker(false);
					}}
				/>
			)}
		</Modal>
	);
};

export default DateConfigModal;
