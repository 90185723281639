import React from 'react';
import { Modal, Space } from 'antd';
import { useTranslation } from 'react-i18next';

const googleReservationMinBookingDay = 30;

const GoogleReservationRequiredCheckModal = ({
  enable = true,
  verifyRequired = false,
  phoneVerification = false,
  maxBookingTimeDay = googleReservationMinBookingDay,
  areaSetting = false,
  serviceTimeOptionEnabled = false,
  depositRequired = false,
  address = false,
  setGoogleReservationRequiredCheckModal,
  setShowHasNotEnoughPointNotice,
  showHasNotEnoughPointsNotice,
  submit
}) => {
  const { t } = useTranslation('settings');
	const cancelPopup = () => {
    if(areaSetting) {
      return setGoogleReservationRequiredCheckModal();
    } else {
      return setGoogleReservationRequiredCheckModal(false);
    }
  };

  const handleSubmit = () => {
    if(showHasNotEnoughPointsNotice) {
      cancelPopup();
      setShowHasNotEnoughPointNotice(true);
    } else {
      submit();
    }
  };

	return (
    <Modal
      title={t("reserveWithGoogleSettings.updateConfirmation")}
      centered
      visible
      destroyOnClose
      width={374}
      closable={false}
      className="modal-base modal-sm bookingSettingPopup__check"
      footer={
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={cancelPopup}
          >
            {t("cancel")}
          </button>
          <button
            className="button-common button-primary"
            onClick={handleSubmit}
          >
            {t("ok")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {t("reserveWithGoogleSettings.updateConfirmation_content")}

      <ul className="error_text" style={{ margin: "12px 0 0", paddingLeft: '20px' }}>
        {address && (
          <li>{t("reserveWithGoogleSettings.addressCannotBeBlank")}</li>
        )}
        {!enable && (
          <li>{t("reserveWithGoogleSettings.enableOnlineBookingEnabled")}</li>
        )}
        {maxBookingTimeDay < 30 && (
          <li>{t("reserveWithGoogleSettings.maximumBookingHorizonGreater")}</li>
        )}
        {verifyRequired && (
          <li>
            {t("reserveWithGoogleSettings.requireBookingApprovalDisabled")}
          </li>
        )}
        {phoneVerification && (
          <li>
            {t("reserveWithGoogleSettings.enablePhoneVerificationDisabled")}
          </li>
        )}
        {areaSetting && (
          <li>{t("reserveWithGoogleSettings.enableServiceOptionsDisabled")}</li>
        )}
        {serviceTimeOptionEnabled && (
          <li>
            {t(
              "reserveWithGoogleSettings.enableMultipleServiceDurationOptionsDisabled"
            )}
          </li>
        )}
        {depositRequired && (
          <li>
            {t("reserveWithGoogleSettings.enableAdvancedPaymentDisabled")}
          </li>
        )}
      </ul>
    </Modal>
  );
}

export default GoogleReservationRequiredCheckModal