import React, { useCallback, useContext } from "react";
import { Modal, Space } from "antd";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../../crmContext/MessageContext";

import {
  getCrmMessagesSms,
  deleteCrmSms,
  cancelCrmSmsSchedule,
} from "../../api/CrmMessageSmsApi";
import {
  getCrmMessagesMenuTalk,
  deleteCrmMenuTalk,
  cancelCrmMenuTalkSchedule,
} from "../../api/CrmMessageMenuTalkApi";

const DeleteMessagePopup = () => {
  const { t } = useTranslation("messages");
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { deleteType, messageId, messageType } = MessageState;

  const getCrmMessageList = async () => {
    try {
      const smsMessagesResult = await getCrmMessagesSms(),
        smsMessages = smsMessagesResult.data.crm_sms_body;
      const menuTalkMessagesResult = await getCrmMessagesMenuTalk(),
        menuTalkMessages = menuTalkMessagesResult.data.crm_menu_body;

      for (let i = 0; i < smsMessages.length; i++) {
        smsMessages[i].type = "sms";
      }

      for (let j = 0; j < menuTalkMessages.length; j++) {
        menuTalkMessages[j].type = "menuTalk";
      }

      const allMessages = smsMessages.concat(menuTalkMessages);

      MessageStateDispatch({
        type: "setInitMessages",
        originMessages: allMessages,
      });
      MessageStateDispatch({ type: "setStatusMessagesNum" });
    } catch (err) {
      console.log(err);
    }
  };

  const cancelSmsSchedule = async () => {
    if (messageType === "sms") {
      try {
        await cancelCrmSmsSchedule(messageId);
        getCrmMessageList();
        cancelPopup();
      } catch (err) {
        console.log("cancel crm sms schedule err ----------", err);
      }
    } else {
      try {
        await cancelCrmMenuTalkSchedule(messageId);
        getCrmMessageList();
        cancelPopup();
      } catch (err) {
        console.log("cancel crm menuTalk schedule err ----------", err);
      }
    }
  };

  const deleteSms = async () => {
    if (messageType === "sms") {
      try {
        await deleteCrmSms(messageId);
        getCrmMessageList();
        cancelPopup();
      } catch (err) {
        console.log("delete crm sms err ----------", err);
      }
    } else {
      try {
        await deleteCrmMenuTalk(messageId);
        getCrmMessageList();
        cancelPopup();
      } catch (err) {
        console.log("delete crm menuTalk err ----------", err);
      }
    }
  };

  const cancelPopup = useCallback(() => {
    MessageStateDispatch({ type: "cancelPopup" });
  }, [MessageStateDispatch]);

  const renderDeleteBtn = () => {
    if (deleteType === "delete") {
      return (
        <button className="button-common button-primary" onClick={deleteSms}>
          {t("popup.delete")}
        </button>
      );
    } else {
      return (
        <button
          className="button-common button-primary"
          onClick={cancelSmsSchedule}
        >
          {t("popup.unschedule")}
        </button>
      );
    }
  };

  const renderContent = () => {
    if (deleteType === "delete") {
      return <div>{t("popup.deleteMessage_content")}</div>;
    } else {
      return <div>{t("popup.unscheduleMessage_content")}</div>;
    }
  };

  return (
    <Modal
      title={
        deleteType === "delete"
          ? t("popup.deleteMessage_title")
          : t("popup.unscheduleMessage_title")
      }
      visible
      destroyOnClose
      width={374}
      centered
      closable={false}
      className="modal-base modal-sm"
      footer={
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={() => cancelPopup()}
          >
            {t("settings:cancel")}
          </button>
          {renderDeleteBtn()}
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {renderContent()}
    </Modal>
  );
};

export default DeleteMessagePopup;
