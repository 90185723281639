import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Space } from "antd";
import { useTranslation } from "react-i18next";

import {
  showAnnouncementAddModal,
  announcementSettingCancel,
  clearAnnouncement,
} from "../../../actions/announcementAction";

const AnnouncementCancelModal = () => {
  const { t } = useTranslation("bulletinSystem");
  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const { id, showPreviewAnnouncementModal } = announcementInfo;
  const dispatch = useDispatch();

  const cancelPopup = () => {
    dispatch(announcementSettingCancel());
  };

  const submit = () => {
    // reload data
    if (showPreviewAnnouncementModal) {
      dispatch(showAnnouncementAddModal());
      dispatch(announcementSettingCancel());
    } else {
      dispatch(clearAnnouncement());
      dispatch(announcementSettingCancel());
    }
  };

  return (
    <Modal
      title={t("popup.cancel_title", { type: id ? t("edit_2") : t("add_2") })}
      visible
      destroyOnClose
      centered
      width={374}
      closable={false}
      className="modal-base modal-sm"
      onCancel={cancelPopup}
      footer={
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={() => cancelPopup()}
          >
            {t("settings:cancel")}
          </button>
          <button
            className="button-common button-primary"
            onClick={() => submit()}
          >
            {t("settings:ok")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {t("popup.cancel_content")}
    </Modal>
  );
};

export default AnnouncementCancelModal;
