import React, { useCallback, useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { browserHistory } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";

import MessageEmpty from "./crmMessage/CrmMessageEmpty";
import Message from "./crmMessage/Message";

import SelectMessagePath from "./crmMessage/crmMessagePopup/SelectMessagePathPopup";
import MenuAppIntroModal from "../shops/menu_user/MenuAppIntro";
import MessageNotification from "./crmMessage/CrmMessageNotification";
import EditDateTimeModal from "./crmMessage/crmMessagePopup/EditDateTimeModal";
import DeletePopup from "./crmMessage/crmMessagePopup/DeleteMessagePopup";
import PreviewSmsPopup from "./crmMessage/crmMessagePopup/PreviewMessagePopup";
import PreviewMenuTalkPopup from "./crmMessage/crmMessageAdd/crmMessageAddPopup/PreviewMenuTalkPopup";

import { MessageContext } from "./crmContext/MessageContext";
import { MessageAddStateContext } from "./crmContext/MessageAddContext";
import { CustomerContext } from "./crmContext/CustomerContext";

import { getCrmMessagesSms, filterCustomers } from "./api/CrmMessageSmsApi";
import {
  getCrmMessagesMenuTalk,
  filterFollowers,
} from "./api/CrmMessageMenuTalkApi";
import { getMenuUserInfo } from "../shops/menu_user/MenuUserApi";

import MenuCouponApi from "../menu_coupon/MenuCouponApi";
const menuCouponAPI = new MenuCouponApi();

const CrmMessageBox = () => {
  const auth = useSelector((state) => state.auth);
  const { name } = auth.shop;
  const { MessageAddDispatch } = useContext(MessageAddStateContext);
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const {
    loading,
    messageFilter,
    messageSort,
    messageStatus,
    messages,
    previewInfo,
    showNotification,
    showMenuAppIntroModal,
    showEditDateTimeModal,
    showDeletePopup,
  } = MessageState;
  const { CustomerStateDispatch } = useContext(CustomerContext);

  const [redirect] = useState(
    browserHistory.getCurrentLocation().state
      ? browserHistory.getCurrentLocation().state.source
      : ""
  );

  useEffect(() => {
    // 從顧客列表進入新增 menu 私訊
    if (redirect === "send_menuTalk") {
      setTimeout(() => {
        MessageStateDispatch({
          type: "switchMode",
          mode: "addMessage",
          editType: "menuTalk",
        });
      }, 0);
    }
  }, [MessageStateDispatch, redirect]);

  useEffect(() => {
    const getMenuUserBindStatus = async () => {
      try {
        const { data } = await getMenuUserInfo();

        if (data.menu_user.bind_menu) {
          CustomerStateDispatch({
            type: "getMenuUserBindStatus",
            menuUserBindStatus: true,
            menuUserAvatar: data.menu_user.thumbnailURL,
          });
        } else {
          CustomerStateDispatch({
            type: "getMenuUserBindStatus",
            menuUserBindStatus: false,
            menuUserAvatar: "",
          });
        }
      } catch (err) {
        console.log("get menu user bind status err ----------", err);
        CustomerStateDispatch({
          type: "getMenuUserBindStatus",
          menuUserBindStatus: false,
          menuUserAvatar: "",
        });
      }
    };

    const getCrmMessageList = async () => {
      try {
        MessageStateDispatch({ type: "setLoading", loading: true });

        const smsMessagesResult = await getCrmMessagesSms(),
          smsMessages = smsMessagesResult.data.crm_sms_body;
        const menuTalkMessagesResult = await getCrmMessagesMenuTalk(),
          menuTalkMessages = menuTalkMessagesResult.data.crm_menu_body;

        for (let i = 0; i < smsMessages.length; i++) {
          smsMessages[i].type = "sms";
        }

        for (let j = 0; j < menuTalkMessages.length; j++) {
          menuTalkMessages[j].type = "menuTalk";
        }

        const allMessages = smsMessages.concat(menuTalkMessages);
        MessageStateDispatch({
          type: "setInitMessages",
          originMessages: allMessages,
        });
        MessageStateDispatch({ type: "setStatusMessagesNum" });
        MessageStateDispatch({ type: "setMessageSort", messageSort });
        MessageStateDispatch({ type: "setMessageFilter", messageFilter });
      } catch (err) {
        console.log(err);
      }
    };

    const getAllCustomersLength = async () => {
      try {
        const { data } = await filterCustomers();
        MessageAddDispatch({
          type: "getAllCustomersLength",
          allCustomers: data.customers.length,
        });
      } catch (err) {
        console.log("get all customers length err", err);
      }
    };

    const getAllFollowersLength = async () => {
      try {
        const { data } = await filterFollowers();
        MessageAddDispatch({
          type: "getAllFollowersLength",
          allFollowers: data.followers.length,
        });
      } catch (err) {
        console.log("get all followers length err", err);
      }
    };

    // get enable coupons
    const getCouponList = async () => {
      try {
        const list = await menuCouponAPI.getCoupon(name);
        MessageAddDispatch({ type: "getEnableCoupons", list });
      } catch (err) {
        console.log("get coupon list err-----", err);
      }
    };

    getCrmMessageList();
    getAllCustomersLength();
    getAllFollowersLength();
    getCouponList();
    getMenuUserBindStatus();
  }, [
    CustomerStateDispatch,
    MessageAddDispatch,
    MessageStateDispatch,
    messageFilter,
    messageSort,
    name,
  ]);

  const cancelModal = useCallback(() => {
    MessageStateDispatch({ type: "closeMenuAppIntroModal" });
  }, [MessageStateDispatch]);

  const renderMessage = useCallback(() => {
    if (loading) {
      return (
        <div className="crmMessageLoading">
          <LoadingOutlined style={{ fontSize: 30 }} />
          <p>Loading ...</p>
        </div>
      );
    }

    if (messages.length === 0) {
      return <MessageEmpty messageStatus={messageStatus} />;
    } else {
      return (
        <div className="crmMessageList">
          {messages.map((message, index) => {
            return <Message key={index} message={message} />;
          })}
        </div>
      );
    }
  }, [messageStatus, messages, loading]);

  return (
    <div className="crmMessageBox_container">
      {renderMessage()}

      <SelectMessagePath />
      <MenuAppIntroModal
        visible={showMenuAppIntroModal}
        handleMenuAppIntroModal={cancelModal}
        source="crm_msg_menuTalk"
      />
      {showNotification && <MessageNotification />}
      {showEditDateTimeModal && <EditDateTimeModal />}
      {showDeletePopup && <DeletePopup />}
      {previewInfo.type === "sms" && <PreviewSmsPopup />}
      {previewInfo.type === "menuTalk" && <PreviewMenuTalkPopup />}
    </div>
  );
};

export default CrmMessageBox;
