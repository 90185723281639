import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { browserHistory } from "react-router";
import BookingSystemApi from "../BookingSystemApi";
import { checkDudooTableSync } from "../../../api/posSync";
import "../../../stylesheets/tableMap.css";
import { useTranslation } from "react-i18next";
import SettingsSaveButton from "../../commons/SettingsSaveButton";
import ConfirmModal from "../../popup/ConfirmModal";
import { Switch } from "antd";

let seatCount = 0,
  canvasSeatCount = 0;

const API = new BookingSystemApi();

const BookingSettingTableMap = (props) => {
  const { t } = useTranslation("settings");
  const DUDOO_SEATS_NOT_MATCHED_TITLE = t(
    "dudooPosSettings.popup.tableMappingInconsistent_title"
  );
  const DUDOO_SEATS_NOT_MATCHED_CONTENT = t(
    "dudooPosSettings.popup.tableMappingInconsistent_content"
  );
  const DUDOO_TABLEMAP_DISABLED_TITLE = t(
    "dudooPosSettings.popup.disabledMap_title"
  );
  const DUDOO_TABLEMAP_DISABLED_CONTENT = t(
    "dudooPosSettings.popup.disabledMap_content"
  );

  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [sending, setSending] = useState(false);
  const [tableMapList, setTableMapList] = useState([]);
  const [newTabName, setNewTabName] = useState("");
  const [tempEditTabName, setTempEditTabName] = useState("");
  const [editTabIndex, setEditTabIndex] = useState(null);
  const [tableMapSetting, setTableMapSetting] = useState(false);
  const [settingDisable, setSettingDisable] = useState(false);
  const [dudooSyncEnabled, setDudooSyncEnabled] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState(
    DUDOO_SEATS_NOT_MATCHED_TITLE
  );
  const [confirmModalContent, setConfirmModalContent] = useState(
    DUDOO_SEATS_NOT_MATCHED_CONTENT
  );

  useEffect(() => {
    props.router.setRouteLeaveHook(props.route, () => {
      if (!isSaved) return t("leaveNotice");
    });
  }, [isSaved, props, t]);

  useEffect(() => {
    const state = browserHistory.getCurrentLocation().state || {};
    const { showConfirmModal } = state;

    if (showConfirmModal && dudooSyncEnabled) {
      setShowConfirmModal(true);
    }
  }, [dudooSyncEnabled]);

  const getSeatsAndCanvas = useCallback(async () => {
    try {
      seatCount = 0;
      canvasSeatCount = 0;

      await API.getBookingSettingSeats().then((data) => {
        // console.log('getBookingSettingSeats data---', data);
        data.results.map((seat) => {
          seatCount = seatCount + seat.quantity;
          return null;
        });
      });

      await API.getTableMap("").then((data) => {
        setTableMapList(data.table_map_list);
        data.table_map_list.map((canvas) => {
          canvasSeatCount = canvasSeatCount + canvas.table.length;
          return null;
        });
      });

      let canvasEnabled = false,
        settingDisableSetting = false;

      const res = await API.getBookingSetting();
      canvasEnabled = res.canvas_enabled;
      setDudooSyncEnabled(res.pos_enabled);

      if (canvasSeatCount !== seatCount) {
        settingDisableSetting = true;
        canvasEnabled = false;
      }

      setSettingDisable(settingDisableSetting);
      setTableMapSetting(canvasEnabled);
      setLoading(false);
    } catch (err) {
      window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
    }
  }, [t]);

  useEffect(() => {
    getSeatsAndCanvas();
  }, [getSeatsAndCanvas]);

  const toggleTableMapSetting = () => {
    setTableMapSetting((old) => !old);
    setIsSaved(false);
  };

  const enterEditMode = (tabName, index) => {
    setTempEditTabName(tabName);
    setEditTabIndex(index);
  };

  const editTabName = (e) => {
    let txt = e.target.value;
    setTempEditTabName(txt);
  };

  const editModeCancel = () => {
    setTempEditTabName("");
    setEditTabIndex(null);
  };

  const editModeCheck = (index) => {
    const newTableMapList = _.cloneDeep(tableMapList);

    if (tempEditTabName.trim() === "") {
      window.app.alert.setMessage(
        t("tableMap.pls_nameOfTableMapCannotEmpty"),
        "tip"
      );
      return;
    }
    newTableMapList[index].name = tempEditTabName;
    setTableMapList(newTableMapList);
    setTempEditTabName("");
    setEditTabIndex(null);
    setIsSaved(false);
  };

  const deleteTab = (index) => {
    const newTableMapList = _.cloneDeep(tableMapList);

    newTableMapList.splice(index, 1);
    canvasSeatCount = 0;
    newTableMapList.map((canvas) => {
      canvasSeatCount = canvasSeatCount + canvas.table.length;
      return null;
    });

    if (canvasSeatCount !== seatCount) {
      setSettingDisable(true);
      setTableMapSetting(false);
    }

    setTableMapList(newTableMapList);
    setIsSaved(false);
  };

  const enterTableMap = (tableName) => {
    setIsSaved(true);
    browserHistory.push({
      pathname: "/dashboard/setting/tablemap_setting/tablemap",
      state: {
        activeTab: tableName,
        tabsInfo: tableMapList,
        tableMapSetting,
      },
    });
  };

  const renderTableMapList = () => {
    return tableMapList.map((tab, index) => {
      let hide = index === editTabIndex ? "" : "hide";
      let hideName = index === editTabIndex ? "hide" : "";
      return (
        <div key={index} className="tableMap__list">
          <div className="tableMap__list__title">
            <span className={`${hideName}`}>
              {tab.name}
              <i
                className="editIcon"
                onClick={() => enterEditMode(tab.name, index)}
              />
            </span>
            <div className={`editMode ${hide}`}>
              <div className="editMode__input">
                <input
                  type="text"
                  value={tempEditTabName}
                  placeholder={t("tableMap.pls_enterNameOfTableMap")}
                  onChange={(e) => editTabName(e)}
                />
              </div>
              <div className="editMode__btns eidtBtns">
                <button
                  className="btn btn-cancel"
                  onClick={() => editModeCancel()}
                >
                  {t("cancel")}
                </button>
                <button
                  className="btn btn-check"
                  onClick={() => editModeCheck(index)}
                >
                  {t("confirm")}
                </button>
              </div>
            </div>
          </div>
          <div className="horizontal-center">
            <div className="tableMap__list__edit delete-icon">
              <i
                className="fa fa-trash"
                aria-hidden="true"
                onClick={() => deleteTab(index)}
              />
            </div>
            <div className="tableMap__list__entry action-enter">
              <span onClick={() => enterTableMap(tab.name)}>
                <div>{t("tableMap.editTableMap")}</div>
                <i className="fa fa-chevron-right" aria-hidden="true" />
              </span>
            </div>
          </div>
        </div>
      );
    });
  };

  const cancel = () => {
    let tableMapAdd = document.getElementById("tableMap__add");
    tableMapAdd.classList.add("hide");
    setNewTabName("");
  };

  const check = () => {
    const newTableMapList = _.cloneDeep(tableMapList);

    let newTab = {
      table: [],
      ele: [],
    };
    let duplicated = false;

    newTableMapList.map((tab) => {
      if (tab.name.trim() === newTabName.trim()) duplicated = true;
      return null;
    });

    if (newTabName.trim() === "") {
      window.app.alert.setMessage(
        t("tableMap.pls_nameOfTableMapCannotEmpty"),
        "tip"
      );
      return;
    }

    if (duplicated) {
      window.app.alert.setMessage(
        t("tableMap.pls_nameOfTableMapMustBeUnique"),
        "tip"
      );
      return;
    } else {
      newTab.name = newTabName.trim();
      newTableMapList.push(newTab);

      setTableMapList(newTableMapList);
      setIsSaved(false);

      cancel();
    }
  };

  const addTableMap = () => {
    let tableMapAdd = document.getElementById("tableMap__add");

    if (tableMapList.length >= 5) {
      window.app.alert.setMessage(t("tableMap.pls_onlyCreate5TableMap"), "tip");
      return;
    }

    tableMapAdd.classList.remove("hide");
  };

  const submit = () => {
    setSending(true);
    submitSetting();
  };

  const submitSetting = async () => {
    let canvas_id = [];
    let bookingSetting = JSON.stringify({
      booking_settings: {
        canvas_enabled: tableMapSetting,
      },
    });

    tableMapList.map((canvas) => {
      if (canvas.id) canvas_id.push(canvas.id);
      return null;
    });

    try {
      await API.updateTableMap(tableMapList, canvas_id).then((data) => {
        setTableMapList(data.canvas);
      });

      await API.updateBookingSetting(bookingSetting);

      setSending(false);
      setIsSaved(true);

      if (dudooSyncEnabled) {
        try {
          await checkDudooTableSync();

          if (!tableMapSetting) {
            setShowConfirmModal(true);
            setConfirmModalTitle(DUDOO_TABLEMAP_DISABLED_TITLE);
            setConfirmModalContent(DUDOO_TABLEMAP_DISABLED_CONTENT);
          }
        } catch (error) {
          setShowConfirmModal(true);
          setConfirmModalTitle(DUDOO_SEATS_NOT_MATCHED_TITLE);
          setConfirmModalContent(DUDOO_SEATS_NOT_MATCHED_CONTENT);
        }
      }

      window.app.alert.setMessage(t("status.saved"), "done");
    } catch (err) {
      window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
      setSending(false);
    }
  };

  const noteHide = !settingDisable ? "hide" : "";
  if (loading) return <div className="pageLoading">{t("status.loading")}</div>;
  return (
    <>
      <div className="setting-container">
        {sending && (
          <div className="lightBoxLayer">
            <h4>{t("status.saving")}</h4>
          </div>
        )}
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <h3>{t("tableMap.title")}</h3>
            <h5>{t("tableMap.description")}</h5>
          </div>
        </div>

        <hr />

        <div className="tableMap">
          <div className="tableMap_setting_switch settingTbl">
            <div className="settingTblRow fix-settingTblRow">
              <div className="header">{t("tableMap.enableTableMaps")}</div>
              <div>
                <Switch
                  onChange={toggleTableMapSetting}
                  checked={tableMapSetting}
                  disabled={settingDisable}
                />
                {settingDisable ? (
                  <p className={`note ${noteHide}`}>
                    {t("tableMap.pls_tablePlacedOnTableMap")}
                  </p>
                ) : dudooSyncEnabled && !tableMapSetting ? (
                  <p className="note">
                    {t("dudooPosSettings.notice_disableTableMap")}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="tableMap__wrap">
            <div>
              {renderTableMapList()}

              <div id="tableMap__add" className="hide">
                <div className="tableMap__add__title">
                  <input
                    type="text"
                    placeholder={t("tableMap.pls_enterNameOfTableMap")}
                    value={newTabName}
                    onChange={(e) => setNewTabName(e.target.value)}
                  />
                </div>
                <div className="tableMap__add__btns eidtBtns">
                  <button className="btn btn-cancel" onClick={() => cancel()}>
                    {t("cancel")}
                  </button>
                  <button className="btn btn-check" onClick={() => check()}>
                    {t("confirm")}
                  </button>
                </div>
              </div>
            </div>
            <div className="tableMap__lis tableMap__button">
              <button onClick={() => addTableMap()}>
                + {t("tableMap.addTableMap")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <SettingsSaveButton handleSaved={submit} showDivider />
      {showConfirmModal && (
        <ConfirmModal
          title={confirmModalTitle}
          content={confirmModalContent}
          submit={() => {
            setShowConfirmModal(false);
          }}
        />
      )}
    </>
  );
};

export default BookingSettingTableMap;
