import React, { useMemo, useCallback } from "react";
import { useDrag } from "react-dnd";
import moment from "moment";
import { Space } from "antd";

const scale = 48;
const statusColor = {
  confirmed: "#61B087",
  unconfirmed: "#FF991F",
  show: "#4B9AC3",
  seated: "#647E96",
  noShow: "#DCDCDC",
  cancel: "#DCDCDC",
  finish: "#DEE6E3",
};

export default ({
  date,
  setBookingListInfo,
  booking,
  tableName,
  startDrag,
  bookingIndex,
  setSelectBookings,
}) => {
  const BookingID = `${booking.id}_${tableName}`;
  const [{ opacity }, drag] = useDrag({
    item: { id: BookingID, type: "bookingCell" },
    begin() {
      startDrag({ booking, tableName, bookingIndex });
    },
    collect: (monitor) => {
      return {
        opacity: monitor.isDragging() ? 0.4 : 1,
      };
    },
  });

  const style = useMemo(() => {
    const time = moment(new Date(booking["service_start_time"]));
    const duration =
      moment.duration(time.diff(new Date(date))).asHours() * 2 * scale + 1;

    return {
      left: duration,
      width: (booking.dining_time / 30) * scale - 2,
      backgroundColor: statusColor[booking.status],
      opacity,
      // 添加效能優化的 CSS 屬性
      willChange: "transform, opacity",
      transform: "translateZ(0)", // 啟用 GPU 加速
      pointerEvents: opacity < 1 ? "none" : "auto", // 拖曳時禁用指針事件
    };
  }, [booking, date, opacity]);

  const shouldRenderTime = parseInt(booking.dining_time, 10) >= 30;
  const shouldRenderName = parseInt(booking.dining_time, 10) >= 31;
  const shouldRenderPhone = parseInt(booking.dining_time, 10) >= 31;
  const shouldRenderAttendance = parseInt(booking.dining_time, 10) >= 60;

  const content = useMemo(
    () => (
      <div className="horizontal-between">
        <Space direction="vertical" size={0}>
          <Space size={4} className="bookingCell-header">
            {shouldRenderTime && (
              <span className="bookingTime">{booking.time}</span>
            )}
            {shouldRenderName && (
              <span className="bookingName">{booking.last_name}</span>
            )}
          </Space>
          {shouldRenderPhone && (
            <span className="bookingPhone">{booking.phone_number}</span>
          )}
        </Space>
        {shouldRenderAttendance && (
          <span className="bookingAttendance">
            {booking.attendance}
            <div className="icon-sm account-icon" />
          </span>
        )}
      </div>
    ),
    [
      booking.time,
      booking.last_name,
      booking.phone_number,
      booking.attendance,
      shouldRenderTime,
      shouldRenderName,
      shouldRenderPhone,
      shouldRenderAttendance,
    ]
  );

  const handleClick = useCallback((e) => {
    setBookingListInfo(e, booking);
    setSelectBookings(booking);
  }, [booking, setBookingListInfo, setSelectBookings]);

  return (
    <div
      ref={drag}
      style={{
        ...style,
        opacity,
      }}
      className={`timelineBookingCell ${
        booking.status === "finish" ? "color-subtitle" : ""
      }`}
      onClick={handleClick}
    >
      {content}
    </div>
  );
};
