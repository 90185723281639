import React, { useRef } from "react";
import { Space, Modal, Switch, Tooltip } from "antd";
import { useTranslation, Trans } from "react-i18next";
import {
  BookingInsertText,
  BookingInsertKey,
  QueueInsertText,
  QueueInsertKey,
} from "./InsertText";

const BookingMsgInsertButton = ({ type, shopName, disabled, onInsertText }) => {
  const { t } = useTranslation("settings");
  return (
    <div className="notificationMsgInsert">
      <button
        className="button-secondary button-setting"
        disabled={disabled}
        onClick={() => onInsertText(shopName)}
      >
        <Space size={4}>
          <div className="icon-sm plus-icon" />
          {t("notificationCommon.merchantName")}
        </Space>
      </button>
      {type === "booking" && (
        <button
          className="button-secondary button-setting"
          disabled={disabled}
          onClick={() => onInsertText(BookingInsertText.BOOKING_DATETIME)}
        >
          <Space size={4}>
            <div className="icon-sm plus-icon" />
            {t("notificationCommon.bookingTime")}
          </Space>
        </button>
      )}
      <button
        className="button-secondary button-setting"
        disabled={disabled}
        onClick={() =>
          type === "booking"
            ? onInsertText(BookingInsertText.SHORTEN_URL)
            : onInsertText(QueueInsertText.SHORTEN_URL)
        }
      >
        <Space size={4}>
          <div className="icon-sm plus-icon" />
          {type === "booking"
            ? t("notificationCommon.bookingStatusPage")
            : t("notificationCommon.queueStatusPage")}
        </Space>
      </button>
    </div>
  );
};

const NotificationOption = ({
  title,
  tooltip,
  type,
  category,
  credits,
  children,
  enabled,
  online,
  offline,
  handleNotificationEnable,
  handleSourceEnable,
}) => {
  const { t, i18n } = useTranslation("settings");
  const disabled = enabled ? "" : "disabled";
  const showCredits = credits !== undefined && credits !== null;

  return (
    <div
      className={`${disabled} ${
        category !== "line" ? "notification-box box-shadow" : ""
      }`}
    >
      <Space size={8} align="start">
        <div className="header btn-text-lg color-title">
          {title}
          {tooltip && (
            <Tooltip
              placement="bottom"
              overlayClassName="setting-tooltip"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              title={tooltip}
            >
              <i className="fa fa-question-circle-o" />
            </Tooltip>
          )}
        </div>
        <div className="content">
          <div className="text-caption color-primary notification-credits">
            <div>
              <Switch
                checked={enabled}
                onChange={(checked) => {
                  if (category === "email") {
                    handleNotificationEnable(category, type, checked);
                  } else {
                    if (type !== "booking_created") {
                      handleNotificationEnable(category, type, checked);
                    } else {
                      handleSourceEnable(category, type, "all");
                    }
                  }
                }}
              />
              {showCredits &&
                `${t("notificationCredits.remainingCredits")}：${credits}`}
            </div>
            {enabled &&
              showCredits &&
              ((category === "sms" && credits < 2) ||
                (category === "line" && credits < 1)) && (
                <span className="text-caption color-alert">
                  {t("notificationCommon.pls_topUpCredits")}
                </span>
              )}
          </div>
        </div>
      </Space>
      {type === "booking_created" && (
        <div
          className={`notification-from ${
            i18n.language === "en" ? "from-en" : ""
          }`}
        >
          <div className="header" />
          <div className="content">
            {category === "email" && (
              <div className="notificationOption-checkbox content text-body color-subtitle">
                <Trans
                  i18nKey="settings:bookingNotification.emailBookingCreateIntro"
                  components={{
                    span: <span className="btn-text-md color-title" />,
                  }}
                />
              </div>
            )}
            {(category === "sms" || category === "line") && (
              <div className="notificationOption-checkbox content text-body color-subtitle">
                <Trans
                  i18nKey="settings:bookingNotification.smsBookingCreatedOption"
                  components={{
                    span: <span className="btn-text-md color-title" />,
                    onlineLabel: (
                      <label className="checkbox-base">
                        <input
                          type="checkbox"
                          checked={online}
                          disabled={!enabled}
                          onChange={() =>
                            handleSourceEnable(category, type, "online")
                          }
                        />
                        <span className="btn-text-md">{t("bookingNotification.online")}</span>
                      </label>
                    ),
                    offlineLabel: (
                      <label className="checkbox-base">
                        <input
                          type="checkbox"
                          checked={offline}
                          disabled={!enabled}
                          onChange={() =>
                            handleSourceEnable(category, type, "offline")
                          }
                        />
                        <span className="btn-text-md">{t("bookingNotification.manual")}</span>
                      </label>
                    ),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {category !== "line" && (
        <div className="notification-from">
          <div className="header" />
          <div className="content">{children}</div>
        </div>
      )}
    </div>
  );
};

function NotificationEditPopup({
  title,
  description,
  type,
  cancelPopup,
  submit,
  credits,
  emailMsg,
  smsMsg,
  lineMsg,
  handleEmailMsg,
  handleSmsMsg,
  handleNotificationEnable,
  handleSourceEnable,
  replaceVariablesWithDisplay,
  shopName,
  defaultMsg,
  showSmsTextareaError,
}) {
  const { t } = useTranslation("settings");
  const emailIntros = {
    booking_created: t("bookingNotification.emailCreatedNote"),
    booking_canceled: t("bookingNotification.emailCanceledNote"),
  };
  const SmsIntros = {
    booking_created: t("bookingNotification.smsCreatedNote"),
    booking_canceled: t("bookingNotification.smsCanceledNote"),
  };
  const emailIntro = emailIntros[type] || "";
  const smsIntro = SmsIntros[type] || "";

  const smsTextareaRef = useRef(null);
  const isBooking = type.includes("booking"); // 判斷是預約還是排隊

  const handleInsertText = (text) => {
    const textarea = smsTextareaRef.current;
    if (!textarea || !handleSmsMsg) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // 獲取 textarea 當前顯示的文字
    const displayValue = textarea.value;

    // 在光標位置插入文字
    const newDisplayValue =
      displayValue.substring(0, start) + text + displayValue.substring(end);

    // 更新 textarea 的顯示值
    textarea.value = newDisplayValue;

    const event = {
      target: {
        name: type,
        value: newDisplayValue,
      },
    };

    handleSmsMsg(event);

    // 設置新的光標位置
    setTimeout(() => {
      textarea.focus();
      textarea.setSelectionRange(start + text.length, start + text.length);
    }, 0);
  };

  const renderNumberOfCharacters = () => {
    const displayMsg = replaceVariablesWithDisplay(smsMsg.template);

    // 根據 type 選擇對應的 InsertKey 和 InsertText
    const insertKey = isBooking ? BookingInsertKey : QueueInsertKey;
    const insertText = isBooking ? BookingInsertText : QueueInsertText;

    // 計算不同類型的變數插入次數
    const insertDisplayBookingDateTimeNum =
      displayMsg.split(BookingInsertText.BOOKING_DATETIME).length - 1;
    const insertDisplayShortenUrlNum =
      displayMsg.split(insertText.SHORTEN_URL).length - 1;

    // 計算長度差異
    const diffDisplayBookingDateTimeLength = 11 - 8; // 實際時間與 */預約時間/* 相差
    const diffDisplayShortenUrlLength = 20 - 9; // 實際 url 與 */預約結果頁/* 相差

    let numberOfCharacters = 0;
    let letters = 0;

    numberOfCharacters =
      displayMsg.length +
      (isBooking
        ? insertDisplayBookingDateTimeNum * diffDisplayBookingDateTimeLength
        : 0) +
      insertDisplayShortenUrlNum * diffDisplayShortenUrlLength;

    // 如果最後的內容是 SHORTEN_URL，則減 1
    if (
      smsMsg.template.substring(
        smsMsg.template.length - (insertKey.SHORTEN_URL.length + 1)
      ) ===
        insertKey.SHORTEN_URL + " " ||
      smsMsg.template.substring(
        smsMsg.template.length - (insertText.SHORTEN_URL.length + 1)
      ) ===
        insertText.SHORTEN_URL + " "
    ) {
      numberOfCharacters--;
    }

    // 計算訊息數
    if (numberOfCharacters <= 70) {
      letters = 1;
    } else if (numberOfCharacters <= 134) {
      letters = 2;  
    } else if (numberOfCharacters <= 201) {
      letters = 3;
    } else if (numberOfCharacters <= 268) {
      letters = 4;
    } else if (numberOfCharacters <= 335) {
      letters = 5;
    } else {
      letters = Math.ceil(numberOfCharacters / 67); // 每 67 字為一封
    }

    return (
      <div className="countText">
        {t("notificationCommon.estimated")}
        {numberOfCharacters}
        {t("notificationCommon.characters")} / {letters}
        {t("notificationCommon.messages")}
      </div>
    );
  };

  return (
    <Modal
      title={title}
      visible
      centered
      className="modal-base modal-xl notificationEditPopup"
      destroyOnClose
      width={640}
      closable={false}
      footer={
        <Space size={10}>
          <button
            className="button-common button-secondary"
            onClick={cancelPopup}
          >
            {t("settings:cancel")}
          </button>
          <button className="button-common button-primary" onClick={submit}>
            {t("settings:ok")}
          </button>
        </Space>
      }
      maskClosable={false}
    >
      <Space direction="vertical" size={32} className="w-full">
        <div className="text-body color-subtitle">{description}</div>

        <NotificationOption
          title={t("notificationCommon.sendEmail")}
          type={type}
          category="email"
          enabled={emailMsg.online || emailMsg.offline}
          handleNotificationEnable={handleNotificationEnable}
        >
          {emailIntro && (
            <div
              className="text-caption color-intro"
              style={{ marginBottom: "10px" }}
            >
              {emailIntro}
            </div>
          )}
          <Space direction="vertical" size={6}>
            <div className="btn-text-md color-title">
              {t("notificationCommon.emailNoteTitle")}
            </div>
            <textarea
              name={type}
              className="input-base"
              value={emailMsg.template}
              disabled={!emailMsg.online && !emailMsg.offline}
              onChange={handleEmailMsg}
              placeholder={
                type === "booking_canceled"
                  ? t("bookingNotification.placeholder_emailCanceledNote")
                  : isBooking
                  ? t("bookingNotification.placeholder_emailCreatedNote")
                  : t("queueNotification.placeholder_emailNote")
              }
            />
          </Space>
        </NotificationOption>
        <NotificationOption
          title={t("notificationCommon.sendSMS")}
          tooltip={t("notificationCommon.sendSMS_tooltip")}
          credits={credits}
          type={type}
          category="sms"
          enabled={smsMsg.online || smsMsg.offline}
          online={smsMsg.online}
          offline={smsMsg.offline}
          handleNotificationEnable={handleNotificationEnable}
          handleSourceEnable={handleSourceEnable}
        >
          {smsIntro && (
            <div
              className="text-caption color-intro"
              style={{ marginBottom: "10px" }}
            >
              {smsIntro}
            </div>
          )}
          <Space direction="vertical" size={6}>
            <div className="btn-text-md color-title">
              {t("notificationCommon.smsContent")}
            </div>
            <Space direction="vertical" size={4}>
              <BookingMsgInsertButton
                type={isBooking ? "booking" : "queue"}
                shopName={shopName}
                disabled={!smsMsg.online && !smsMsg.offline}
                onInsertText={handleInsertText}
              />
              <Space direction="vertical" size={2}>
                <div
                  className={`sms-textarea ${
                    showSmsTextareaError ? "sms-textarea_error" : ""
                  }`}
                >
                  <textarea
                    name={type}
                    ref={smsTextareaRef}
                    disabled={!smsMsg.online && !smsMsg.offline}
                    className="input-base"
                    value={replaceVariablesWithDisplay(smsMsg.template)}
                    onChange={handleSmsMsg}
                    placeholder={replaceVariablesWithDisplay(defaultMsg)}
                  />
                  <div className="text-body color-disabled">
                    {renderNumberOfCharacters()}
                  </div>
                </div>
                {showSmsTextareaError && (
                  <div className="text-small color-alert">請輸入簡訊內容</div>
                )}
              </Space>
            </Space>
          </Space>
        </NotificationOption>
        <NotificationOption
          title={t("notificationCommon.sendLine")}
          tooltip={t("notificationCommon.sendLine_tooltip")}
          credits={credits}
          type={type}
          category="line"
          enabled={lineMsg.online || lineMsg.offline}
          online={lineMsg.online}
          offline={lineMsg.offline}
          handleNotificationEnable={handleNotificationEnable}
          handleSourceEnable={handleSourceEnable}
        />
      </Space>
    </Modal>
  );
}

export default NotificationEditPopup;
