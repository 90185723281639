import React from "react";
import Month from "../new_module/Calendar/Month";
import { Portal } from '../../components/new_module/utils/Portal';
import { useTranslation } from "react-i18next";

import { monthAbbrevEN} from "../../utils/constants"; 

function BookingCalendar({
  queryYear,
  queryMonth,
  handlePreMonth,
  handleNextMonth,
  closeCalender,
  monthBookingTotalEffectiveCount,
  monthBookingTotalAttendance,
  monthBookingEffectiveCount,
  monthBookingAttendance,
  monthBookingUnconfirmedCount,
  changeUrlDate,
}) {
  const { i18n } = useTranslation();
  const monthIndex = parseInt(queryMonth, 10) - 1;
  return (
    <Portal>
      <div className="newModuleCalendar">
        <div className="newModuleCalendar-mask" onClick={closeCalender} />
        <div className="newModuleCalendarWrap">
          <div className="newModuleCalendarTable">
            <div className="newModuleCalendar__header">
              <button
                className="calendarBtn calendarBtn-prev"
                onClick={handlePreMonth}
              />
              <div className="heading-4 color-title">
                {i18n.language === "zh"
                  ? `${queryMonth}月`
                  : monthAbbrevEN[monthIndex]}{" "}
                {queryYear}
              </div>
              <button
                className="calendarBtn calendarBtn-next"
                onClick={handleNextMonth}
              />
            </div>
            <div className="newModuleCalendarTable__body">
              {
                <Month
                  year={queryYear}
                  month={queryMonth}
                  monthBookingUnconfirmedCount={monthBookingUnconfirmedCount}
                  monthBookingEffectiveCount={monthBookingEffectiveCount}
                  monthBookingAttendance={monthBookingAttendance}
                  changeUrlDate={changeUrlDate}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default BookingCalendar;
