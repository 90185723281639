import React, { useContext } from "react";
import { browserHistory } from "react-router";
import { Modal, Space } from "antd";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../../../crmContext/MessageContext";
import { MessageAddStateContext } from "../../../crmContext/MessageAddContext";

const ReturnCheckModal = () => {
  const { t } = useTranslation("messages");
  const { MessageStateDispatch } = useContext(MessageContext);
  const { MessageAddDispatch } = useContext(MessageAddStateContext);

  const returnToMsgList = () => {
    browserHistory.push({
      pathname: "/dashboard/messages",
    });

    MessageStateDispatch({
      type: "switchMode",
      mode: "messageList",
      editType: "",
    });
    MessageAddDispatch({ type: "setIsSaved", isSaved: true });
    MessageAddDispatch({ type: "cancelPopup" });
    MessageAddDispatch({ type: "cleanMessageSetting" });
  };

  const cancelPopup = () => {
    MessageAddDispatch({ type: "cancelPopup" });
  };

  return (
    <Modal
      title={t("popup.editExist_title")}
      visible
      destroyOnClose
      width={374}
      centered
      closable={false}
      className="modal-base modal-sm"
      footer={
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={cancelPopup}
          >
            {t("settings:cancel")}
          </button>
          <button
            className="button-common button-primary"
            onClick={returnToMsgList}
          >
            {t("settings:ok")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {t("popup.editExist_content")}
    </Modal>
  );
};

export default ReturnCheckModal;
