import moment from "moment";
import i18n from "i18next";

export function getCurrentDateString(separator) {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  if (separator !== undefined) {
    return yyyy + separator + mm + separator + dd;
  } else {
    return yyyy + "-" + mm + "-" + dd;
  }
}

export function getCurrentChineseDay() {
  let today = new Date();

  if (moment(today).weekday() === 0) {
    return "星期日";
  } else if (moment(today).weekday() === 1) {
    return "星期一";
  } else if (moment(today).weekday() === 2) {
    return "星期二";
  } else if (moment(today).weekday() === 3) {
    return "星期三";
  } else if (moment(today).weekday() === 4) {
    return "星期四";
  } else if (moment(today).weekday() === 5) {
    return "星期五";
  } else if (moment(today).weekday() === 6) {
    return "星期六";
  }
}

export function getEstimatedWaitingTime(time_checking, groupCount, translate = true) {
  let timeCheckingConditions = [];
  try {
    timeCheckingConditions = JSON.parse(time_checking);
  } catch (e) {
    console.log("Parse condition failed");
  }

  if (
    Array.isArray(timeCheckingConditions) &&
    timeCheckingConditions.length > 1
  ) {
    timeCheckingConditions.sort((first, second) => {
      return first.count - second.count;
    });
  }

  let estimatedWaitingTime = 0;

  for (let i = 0; i < timeCheckingConditions.length; i++) {
    let element = timeCheckingConditions[i];
    if (groupCount <= element.count) {
      if (i === 0) {
        if (groupCount === element.count) {
          estimatedWaitingTime = element.time;
        } else {
          estimatedWaitingTime = `${translate ? i18n.t("queueSystem:item.less") : "少於"}` + element.time;
        }
      } else {
        let prevElement = timeCheckingConditions[i - 1];
        estimatedWaitingTime = prevElement.time;
      }
      break;
    } else {
      let prevElement = timeCheckingConditions[i];
      estimatedWaitingTime = prevElement.time;
    }
  }

  return estimatedWaitingTime;
}
