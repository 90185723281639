import React, { useEffect, useContext } from "react";
import { browserHistory } from "react-router";
import _ from "lodash";
import { EyeFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../../crmContext/MessageContext";
import { MessageAddStateContext } from "../../crmContext/MessageAddContext";

import { createCrmSms, updateCrmSms } from "../../api/CrmMessageSmsApi";
import {
  createCrmMenuTalk,
  updateCrmMenuTalk,
} from "../../api/CrmMessageMenuTalkApi";

const CrmMessageAddHeader = () => {
  const { t } = useTranslation("messages");
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(
    MessageAddStateContext
  );
  const { isSaved, messageSetting } = MessageAddState;
  const {
    id,
    title,
    content,
    filterTag,
    scheduleTime,
    customers,
    followers,
    filterConditions,
    estimatedMsgPoints,
    menuTalkContent,
    uploadImg,
  } = messageSetting;
  const {
    dateAfter,
    dateBefore,
    bookingMin,
    bookingMax,
    gender,
    tags,
    exchangeMin,
    exchangeMax,
    fetchMin,
    fetchMax,
  } = filterConditions;

  useEffect(() => {
    const testBtn = document.getElementsByClassName("btn_test")[0];
    const draftBtn = document.getElementsByClassName("btn_draft")[0];
    let emptyContent = false;

    for (let i = 0; i < menuTalkContent.length; i++) {
      if (
        menuTalkContent[i].type === "text" &&
        menuTalkContent[i].text.trim() === ""
      ) {
        emptyContent = true;
      }
    }

    if (editType === "sms") {
      if (content.trim() === "") {
        testBtn.disabled = true;
        testBtn.classList.add("disabled");
      } else {
        testBtn.disabled = false;
        testBtn.classList.remove("disabled");
      }

      if (title.trim() !== "" || content.trim() !== "") {
        draftBtn.disabled = false;
        draftBtn.classList.remove("disabled");
      } else {
        draftBtn.disabled = true;
        draftBtn.classList.add("disabled");
      }
    } else if (editType === "menuTalk") {
      if (menuTalkContent.length === 0 || emptyContent) {
        testBtn.disabled = true;
        testBtn.classList.add("disabled");
      } else {
        testBtn.disabled = false;
        testBtn.classList.remove("disabled");
      }

      if (
        title.trim() !== "" ||
        (menuTalkContent.length > 0 && !emptyContent)
      ) {
        draftBtn.disabled = false;
        draftBtn.classList.remove("disabled");
      } else {
        draftBtn.disabled = true;
        draftBtn.classList.add("disabled");
      }
    }
  }, [editType, title, content, menuTalkContent]);

  const showSendTestMessageModal = () => {
    MessageAddDispatch({ type: "showSendTestMessageModal" });
  };

  const showPreviewPopup = () => {
    MessageAddDispatch({ type: "showPreviewPopup" });
  };

  const showReturnCheckModal = () => {
    if (isSaved) {
      browserHistory.push({
        pathname: "/dashboard/messages",
      });

      MessageStateDispatch({
        type: "switchMode",
        mode: "messageList",
        editType: "",
      });
    } else {
      MessageAddDispatch({ type: "showReturnCheckModal" });
    }
  };

  const saveDraft = async () => {
    if (editType === "sms") {
      const crmSmsContent = {
        title: title.trim() !== "" ? title.trim() : "未命名的簡訊",
        content: content.trim(),
        filter_tag: filterTag,
        schedule_time: scheduleTime,
        customers: customers,
        status: "draft",
        filter_conditions: {
          date_after: dateAfter ? dateAfter : null,
          date_before: dateBefore ? dateBefore : null,
          booking_min: bookingMin ? bookingMin : null,
          booking_max: bookingMax ? bookingMax : null,
          gender: gender ? gender : null,
          customer_tags: tags.length !== 0 ? tags : null,
        },
        estimated_msg_points: estimatedMsgPoints,
      };

      if (!id) {
        try {
          await createCrmSms(crmSmsContent);

          // 儲存進入草稿
          MessageAddDispatch({ type: "cleanMessageSetting" });
          MessageStateDispatch({ type: "switchMode", mode: "messageList" });
          MessageStateDispatch({
            type: "switchMessageStatus",
            messageStatus: "sent",
          });
          MessageStateDispatch({
            type: "setMessageNotification",
            notificationType: "draft",
            msgType: "sms",
          });
        } catch (err) {
          if (
            err.response.data.message === "Content Includes Invalid Character."
          ) {
            return MessageAddDispatch({
              type: "checkMessage",
              error: ["content_invalid"],
            });
          }

          console.log("save crm sms message to draft err", err);
        }
      } else {
        try {
          await updateCrmSms(id, crmSmsContent);

          // 儲存進入草稿
          MessageAddDispatch({ type: "cleanMessageSetting" });
          MessageStateDispatch({ type: "switchMode", mode: "messageList" });
          MessageStateDispatch({
            type: "switchMessageStatus",
            messageStatus: "sent",
          });
          MessageStateDispatch({
            type: "setMessageNotification",
            notificationType: "draft",
            msgType: "sms",
          });
        } catch (err) {
          console.log("save crm sms message to draft err", err);
        }
      }
    } else if (editType === "menuTalk") {
      const newMenuTalkContent = _.cloneDeep(menuTalkContent);

      for (let i = 0; i < newMenuTalkContent.length; i++) {
        if (newMenuTalkContent[i].type === "new_image") {
          delete newMenuTalkContent[i].url;
        }
      }

      const fd = new FormData();
      const crmMenuTalkContent = {
        title:
          title.trim() !== ""
            ? title.trim()
            : t("sendMenuTalk.unnamedTitle"),
        contents: newMenuTalkContent,
        filter_tag: filterTag,
        status: "draft",
        schedule_time: scheduleTime,
        followers: followers,
        filter_conditions: {
          exchange_min: exchangeMin ? exchangeMin : null,
          exchange_max: exchangeMax ? exchangeMax : null,
          fetch_min: fetchMin ? fetchMin : null,
          fetch_max: fetchMax ? fetchMax : null,
          gender: gender ? gender : null,
        },
      };

      if (uploadImg.length !== 0) {
        uploadImg.forEach((ele) => {
          fd.append("upload_imgs[]", ele);
        });
      }

      fd.append("crm_menu_body", JSON.stringify(crmMenuTalkContent));

      if (!id) {
        try {
          await createCrmMenuTalk(fd);

          // 清除從顧客列表進入新增 menu 私訊的 state
          browserHistory.push({
            pathname: "/dashboard/messages",
          });

          // 儲存進入草稿
          MessageAddDispatch({ type: "cleanMessageSetting" });
          MessageStateDispatch({ type: "switchMode", mode: "messageList" });
          MessageStateDispatch({
            type: "switchMessageStatus",
            messageStatus: "sent",
          });
          MessageStateDispatch({
            type: "setMessageNotification",
            notificationType: "draft",
            msgType: "menuTalk",
          });
        } catch (err) {
          console.log("save crm menuTalk message to draft err", err);
        }
      } else {
        try {
          await updateCrmMenuTalk(id, fd);

          // 儲存進入草稿
          MessageAddDispatch({ type: "cleanMessageSetting" });
          MessageStateDispatch({ type: "switchMode", mode: "messageList" });
          MessageStateDispatch({
            type: "switchMessageStatus",
            messageStatus: "sent",
          });
          MessageStateDispatch({
            type: "setMessageNotification",
            notificationType: "draft",
            msgType: "menuTalk",
          });
        } catch (err) {
          console.log("update crm menuTalk message to draft err", err);
        }
      }
    }
  };

  const renderTitle = () => {
    if (editType === "sms") {
      return (
        <span>
          <img
            src={require("../../../../images/crm/path_message_icon.svg")}
            alt=""
          />
          建立簡訊內容
        </span>
      );
    } else if (editType === "menuTalk") {
      return (
        <span>
          <img
            src={require("../../../../images/crm/path_menuTalk_icon.svg")}
            alt=""
            onClick={showReturnCheckModal}
          />
          {t("sendMenuTalk.title")}
        </span>
      );
    }
  };

  const renderActionBtn = () => {
    if (editType === "sms") {
      return (
        <button
          style={{ marginRight: "24px" }}
          className="btn_test"
          onClick={() => showSendTestMessageModal()}
        >
          <div className="test_icon"></div>
          傳送測試
        </button>
      );
    } else if (editType === "menuTalk") {
      return (
        <button
          style={{ marginRight: "24px" }}
          className="btn_test"
          onClick={() => showPreviewPopup()}
        >
          <EyeFilled />
          {t("sendMenuTalk.preview")}
        </button>
      );
    }
  };

  return (
    <div className="crmMessageAddHeader">
      {renderTitle()}
      <div>
        {renderActionBtn()}
        <button className="btn_draft" onClick={() => saveDraft()}>
          <div className="draft_icon"></div>
          {t("sendMenuTalk.draft")}
        </button>
      </div>
    </div>
  );
};

export default CrmMessageAddHeader;
