import React from "react";
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const ConfirmModal = ({
  title,
  content,
  submit,
  submitText=''
}) => {
  const { t } = useTranslation('settings');
  return (
    <Modal
      title={title}
      visible
      destroyOnClose
      width={374}
      closable={false}
      centered
      className="modal-base modal-sm"
      footer={
        <button
          className="button-common button-primary"
          onClick={submit}
        >
          { submitText ? submitText : t('ok')}
        </button>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {content}
    </Modal>
  )
}

export default ConfirmModal;