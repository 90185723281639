import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { handleAnnouncementListModal } from "../../../actions/announcementAction";
import { monthAbbrevEN } from "../../../utils/constants";
import { Space } from "antd";

export default (props) => {
  const { i18n } = useTranslation("time");
  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const { monthAnnouncements } = announcementListReducer;
  const dispatch = useDispatch();

  var days = [],
    date = props.date,
    month = props.month;

  const disableDates = props.disableDates; //不可預約日期

  for (let i = 0; i < 7; i++) {
    let day = {
      year: date.format("YYYY"),
      name: date.format("dd").substring(0, 1),
      number: date.date(),
      isCurrentMonth: date.month() === month.month(),
      isToday: date.isSame(new Date(), "day"),
      date,
    };

    let monthNumber = parseInt(date.month() + 1, 10);
    let dayNumber = day.number;

    //day.number 幾號 date.month()+1 月份 day.year 年份
    if (dayNumber > 0 && dayNumber < 10) {
      dayNumber = "0" + dayNumber;
    }

    if (monthNumber > 0 && monthNumber < 10) {
      monthNumber = "0" + monthNumber;
    }

    const cell = day.year + "-" + monthNumber + "-" + dayNumber;
    const disableDateStyle = disableDates[cell] === 1 ? true : false; ///不可預約日期style
    const nationalWeek = i === 0 || i === 6 ? true : false; ///六日style
    const renderTodayNumber =
      day.number === 1 && !day.isToday
        ? i18n.language === "zh"
          ? `${date.month() + 1}月${day.number}日`
          : `${monthAbbrevEN[date.month()]}. ${day.number}`
        : day.number; ///每月1號＆＆today
    const effectiveCount = props.monthEffectiveCount[date.format("YYYYMMDD")];
    const unconfirmedCount =
      props.monthUnconfirmedCount[date.format("YYYYMMDD")];
    const attendance = props.monthAttendance[date.format("YYYYMMDD")];

    const effectAnnouncement = monthAnnouncements[date.format("YYYY-MM-DD")];

    const showAnnouncementDayListModal = (e) => {
      e.stopPropagation();
      dispatch(
        handleAnnouncementListModal(
          "day",
          moment(day.date).format("YYYY-MM-DD")
        )
      );
    };

    const renderAnnouncement = () => {
      if (effectAnnouncement && effectAnnouncement.length > 0) {
        return (
          <div className="announcement_have">
            <Space
              size={4}
              className="announcementAction"
              onClick={(e) => showAnnouncementDayListModal(e)}
            >
              <div className="icon-round">
                <div className="bulletin-remind-icon" />
              </div>
              <div className="announcementCount">
                {effectAnnouncement.length}
              </div>
            </Space>
            <div className="bulletin-null-icon icon-xs announcementView" />
          </div>
        );
      }
    };

    days.push(
      <div
        key={day.date.toString()}
        className={
          "day-container" +
          (day.isToday ? " today" : "") +
          (day.isCurrentMonth ? "" : " different-month") +
          (day.date.isSame(props.selected) ? " selected-n" : "") +
          (disableDateStyle ? " disableDateStyle" : "") +
          (nationalWeek ? " holidayStyle" : "")
        }
        onClick={props.select.bind(null, day)}
      >
        <span className={"day" + (day.isToday ? " today" : "")}>
          <span className="day_web">{renderTodayNumber}</span>
          <span className="day_mobile">{day.number}</span>
        </span>
        {unconfirmedCount !== undefined && (
          <div
            className="unconfiremedStyle"
            style={{ color: "#F5A623", position: "absolute" }}
          />
        )}
        {renderAnnouncement()}
        <Space
          size={2}
          direction="vertical"
          className="date-info"
          align="start"
        >
          {effectiveCount !== undefined && (
            <Space
              className={`dayEffectiveCount ${
                unconfirmedCount !== undefined ? "unconfirmedCount" : ""
              }`}
              size={4}
            >
              <div className="icon-round">
                <div className="reservation-icon icon-sm" />
              </div>
              {effectiveCount}
            </Space>
          )}
          {attendance !== undefined && (
            <Space className="dayAttendance" size={4}>
              <div className="icon-round">
                <div className="account-icon icon-sm" />
              </div>
              {attendance}
            </Space>
          )}
        </Space>
      </div>
    );
    date = date.clone();
    date.add(1, "d");
  }
  return (
    <div className="week" key={days[0].toString()}>
      {days}
    </div>
  );
};
