import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import './shopFormNewModal.scss';

export default ({ data, onInputChange, onImageChange, imagePreviewUrl, onFormSwitch, onSubmit}) => {
  const { t } = useTranslation('settings');
  const renderLogo = () => {
    if(imagePreviewUrl) {
      return imagePreviewUrl;
    } else if(data.logo) {
      return data.logo;
    } else {
      return require("../../images/menuUser/Profile_Picture.svg");
    }
  };

  const addShopDefaultSrc = (e) => {
		e.onerror = null;
		e.target.src = require('../../images/menuUser/Profile_Picture.svg');
  };

	return (
		<Modal
			destroyOnClose
			centered
			visible
			wrapClassName="shopNewModal"
      title={t('accountSettings.menu.createMerchantLocationOnMENU')}
      footer={
        <button onClick={onSubmit}>{t('accountSettings.menu.create')}</button>
      }
      onCancel={onFormSwitch}
      transitionName="ant-move-down"
		>
			<div className="shopNewModalBody">
        <div className="logo">
          <img
            className="shopForm_info_logo"
            src={renderLogo()}
            onError={(e) => addShopDefaultSrc(e)}
            alt=''
          />
          <div>
            <label htmlFor="upload" className="shopForm_info_logo_change">{t('accountSettings.uploadPhoto')}</label>
            <input
              id="upload"
              onChange={onImageChange}
              className="file-upload__input"
              type="file"
              name="file-upload"
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
        </div>
        <div className="info">
          <div className="info_block">
            <div className="info_block_title">{t('accountSettings.merchantName')}</div>
            <input
              type="text" 
              className="form-control shopForm_info_input"
              placeholder={t('accountSettings.merchantName')}
              name="display_name"
              value={data.display_name || ''}
              onChange={onInputChange}
            />
          </div>
          <div className="info_block">
            <div className="info_block_title">{t('accountSettings.branch')}</div>
            <input
              type="text" 
              className="form-control shopForm_info_input"
              placeholder={t('accountSettings.branch')}
              name="branch"
              value={data.branch || ''}
              onChange={onInputChange}
            />
          </div>
          <div className="info_block">
            <div className="info_block_title">{t('accountSettings.phone')}</div>
            <input
              type="text" 
              className="form-control shopForm_info_input"
              placeholder={t('accountSettings.phone')}
              name="phone_number"
              value={data.phone_number || ''}
              onChange={onInputChange}
            />
          </div>
          <div className="info_block">
            <div className="info_block_title">{t('accountSettings.address')}</div>
            <input
              type="text" 
              className="form-control shopForm_info_input"
              placeholder={t('accountSettings.address')}
              name="address"
              value={data.address || ''}
              onChange={onInputChange}
            />
          </div>
          <div className="info_block">
            <div className="info_block_title">{t('accountSettings.website')}</div>
            <input
              type="text" 
              className="form-control shopForm_info_input"
              placeholder={t('accountSettings.website')}
              name="website_url"
              value={data.website_url || ''}
              onChange={onInputChange}
            />
          </div>
        </div>
      </div>
		</Modal>
	);
};
