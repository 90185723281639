import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import Datetime from "react-datetime";
import moment from "moment";
import { CalendarOutlined } from "@ant-design/icons";

import { MessageContext } from "../../crmContext/MessageContext";
import { MessageAddStateContext } from "../../crmContext/MessageAddContext";

const MessagePublishSection = ({ children }) => {
  return (
    <section className="crmMessageAdd_block">
      {React.Children.map(children, (child) => child)}
    </section>
  );
};

const PublishSectionTitle = () => {
  const { t } = useTranslation("messages");
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState } = useContext(MessageAddStateContext);
  const { error } = MessageAddState;

  const errorText =
    editType === "sms"
      ? "請選擇傳送簡訊排程時間。"
      : t("sendMenuTalk.campaignSettings.pls_wrongScheduleTime");

  return (
    <Fragment>
      <h3>{t("sendMenuTalk.campaignSettings.launchTime")}</h3>
      {error.includes("dateTime") ? (
        <div className="error_text">{errorText}</div>
      ) : null}
    </Fragment>
  );
};

const ScheduleTime = () => {
  const { t } = useTranslation("messages");
  const { MessageAddState, MessageAddDispatch } = useContext(
    MessageAddStateContext
  );
  const { messageSetting } = MessageAddState;
  const { status, scheduleTime } = messageSetting;

  const handleMessageStatus = (type) => {
    MessageAddDispatch({ type: "setMessageStatus", status: type });
  };

  const renderDateTime = () => {
    let inputProps = {
      placeholder: t("sendMenuTalk.campaignSettings.scheduled"),
    };

    return (
      <div className="datetime">
        <Datetime
          inputProps={inputProps}
          value={scheduleTime}
          onChange={(datetime) => {
            MessageAddDispatch({
              type: "setScheduleTime",
              scheduleTime: datetime,
            });
          }}
          dateFormat="YYYY-MM-DD"
          timeFormat="HH:mm"
          isValidDate={(currentDate) => {
            return currentDate.isAfter(moment().subtract(1, "day"));
          }}
        />
        <CalendarOutlined />
      </div>
    );
  };

  return (
    <Fragment>
      <div className="crmMessageAdd_radio" style={{ marginTop: "24px" }}>
        <label>
          <input
            type="radio"
            name="sendTime"
            onChange={() => handleMessageStatus("real_time")}
            checked={status === "real_time"}
          />
          {t("sendMenuTalk.campaignSettings.sendNow")}
        </label>
      </div>
      <div className="crmMessageAdd_radio" style={{ marginTop: "16px" }}>
        <label>
          <input
            type="radio"
            name="sendTime"
            onChange={() => handleMessageStatus("scheduled")}
            checked={status === "scheduled"}
          />
          {t("sendMenuTalk.campaignSettings.scheduled")}
        </label>
      </div>
      {status === "scheduled" ? renderDateTime() : null}
    </Fragment>
  );
};

MessagePublishSection.Title = PublishSectionTitle;
MessagePublishSection.Schedule = ScheduleTime;

export default MessagePublishSection;
