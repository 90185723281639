import React, { Fragment, useCallback, useContext, useEffect } from "react";
import moment from "moment";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { CustomerContext } from "../../../crmContext/CustomerContext";
import { MessageContext } from "../../../crmContext/MessageContext";
import { MessageAddStateContext } from "../../../crmContext/MessageAddContext";

const PreviewMenuTalkPopup = () => {
  const { t, i18n } = useTranslation("messages");
  const { CustomerState } = useContext(CustomerContext);
  const { menuUserAvatar, shopName } = CustomerState;
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { previewInfo } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(
    MessageAddStateContext
  );
  const { messageSetting, showPreviewPopup, originCouponLists } =
    MessageAddState;
  const { status, menuTalkContent, scheduleTime } = messageSetting;

  useEffect(() => {
    // 編輯時預覽 modal 沒有限制最小高度
    const previewModalHeight =
      document.getElementsByClassName("ant-modal-body")[0];

    if (showPreviewPopup && previewModalHeight) {
      previewModalHeight.style.minHeight = 0;
    }
  }, [showPreviewPopup]);

  const renderDate = () => {
    if (showPreviewPopup) {
      // 編輯時預覽
      if (
        status === "" ||
        status === "real_time" ||
        status === "draft" ||
        (status === "scheduled" && !scheduleTime)
      ) {
        const date =
          i18n.language === "zh"
            ? moment(new Date()).format("YYYY年MM月DD日")
            : moment(new Date()).format("YYYY.MM.DD");
        return date;
      } else if (status === "scheduled" && scheduleTime) {
        const date =
          i18n.language === "zh"
            ? moment(new Date(scheduleTime)).format("YYYY年MM月DD日")
            : moment(new Date()).format("YYYY.MM.DD");
        return date;
      }
    } else if (previewInfo.type === "menuTalk") {
      // 訊息列表預覽
      const date =
        i18n.language === "zh"
          ? moment(new Date(previewInfo.schedule_time)).format("YYYY年MM月DD日")
          : moment(new Date(previewInfo.schedule_time)).format("YYYY.MM.DD");
      return date;
    }
  };

  const renderTime = () => {
    if (showPreviewPopup) {
      // 編輯時預覽
      if (
        status === "" ||
        status === "real_time" ||
        status === "draft" ||
        (status === "scheduled" && !scheduleTime)
      ) {
        const hour = new Date().getHours();
        const minutes = new Date().getMinutes();
        let nowTime = "";
        let newMinutes = "";

        if (minutes === 0) {
          newMinutes = "00";
        } else if (minutes < 10) {
          newMinutes = `0${minutes}`;
        } else {
          newMinutes = minutes;
        }

        if (hour >= 12) {
          nowTime = `${t("sendMenuTalk.coupon.pm")}${
            hour === 12 ? hour : hour - 12
          }:${newMinutes}`;
        } else {
          nowTime = `${t("sendMenuTalk.coupon.am")}${
            hour === 0 ? "0" : hour
          }:${newMinutes}`;
        }

        return nowTime;
      } else if (status === "scheduled" && scheduleTime) {
        const hour = new Date(scheduleTime).getHours();
        const minutes = new Date(scheduleTime).getMinutes();
        let setTime = "";
        let newMinutes = "";

        if (minutes === 0) {
          newMinutes = "00";
        } else if (minutes < 10) {
          newMinutes = `0${minutes}`;
        } else {
          newMinutes = minutes;
        }

        if (hour >= 12) {
          setTime = `${t("sendMenuTalk.coupon.pm")}${
            hour === 12 ? hour : hour - 12
          }:${newMinutes}`;
        } else {
          setTime = `${t("sendMenuTalk.coupon.am")}${
            hour === 0 ? "0" : hour
          }:${newMinutes}`;
        }

        return setTime;
      }
    } else if (previewInfo.type === "menuTalk") {
      // 訊息列表預覽
      const hour = new Date(previewInfo.schedule_time).getHours();
      const minutes = new Date(previewInfo.schedule_time).getMinutes();
      let setTime = "";
      let newMinutes = "";

      if (minutes === 0) {
        newMinutes = "00";
      } else if (minutes < 10) {
        newMinutes = `0${minutes}`;
      } else {
        newMinutes = minutes;
      }

      if (hour >= 12) {
        setTime = `${t("sendMenuTalk.coupon.pm")}${
          hour === 12 ? hour : hour - 12
        }:${newMinutes}`;
      } else {
        setTime = `${t("sendMenuTalk.coupon.am")}${
          hour === 0 ? "0" : hour
        }:${newMinutes}`;
      }

      return setTime;
    }
  };

  const renderContent = () => {
    if (showPreviewPopup) {
      return menuTalkContent.map((item) => {
        let voucherInfo = "";
        let endDateTime = "";

        if (item.type === "voucher") {
          const couponIndex = originCouponLists
            .map((coupon) => coupon.id)
            .indexOf(item.voucherId);

          voucherInfo = originCouponLists[couponIndex];
          endDateTime =
            i18n.language === "zh"
              ? moment(new Date(voucherInfo.endDateTime)).format(
                  "YYYY年MM月DD日HH:mm"
                )
              : moment(new Date(voucherInfo.endDateTime)).format(
                  "YYYY.MM.DD HH:mm"
                );
        }

        return (
          <Fragment>
            {item.type === "text" && (
              <div className="msg">
                <div className="text">{item.text}</div>
                <div className="time">{renderTime()}</div>
              </div>
            )}
            {(item.type === "new_image" || item.type === "image") && (
              <div className="msg">
                <div className="img">
                  <img src={item.url} alt="" />
                </div>
                <div className="time">{renderTime()}</div>
              </div>
            )}
            {item.type === "voucher" && (
              <div className="msg">
                <div className="coupon">
                  <div className="coupon_thumbnail">
                    {voucherInfo.thumbnailURL !== "" && (
                      <img src={voucherInfo.thumbnailURL} alt="" />
                    )}
                  </div>
                  <div className="coupon_content">
                    <div className="coupon_content_title">
                      <div>{voucherInfo.title}</div>
                      <div>{voucherInfo.item}</div>
                    </div>
                    <div className="coupon_content_time">
                      <div>{shopName}</div>
                      <div>
                        {t("sendMenuTalk.coupon.dateExpired", {
                          date: endDateTime,
                        })}
                      </div>
                    </div>
                    <button>{t("sendMenuTalk.coupon.viewCoupon")}</button>
                  </div>
                </div>
                <div className="time">{renderTime()}</div>
              </div>
            )}
          </Fragment>
        );
      });
    } else if (previewInfo.type === "menuTalk") {
      const previewContent = JSON.parse(previewInfo.contents);
      return previewContent.map((item, index) => {
        let voucherInfo = "";
        let endDateTime = "";

        if (item.type === "voucher") {
          const couponIndex = originCouponLists
            .map((coupon) => coupon.id)
            .indexOf(item.voucherId);

          voucherInfo = originCouponLists[couponIndex];
          endDateTime =
            i18n.language === "zh"
              ? moment(new Date(voucherInfo.endDateTime)).format(
                  "YYYY年MM月DD日HH:mm"
                )
              : moment(new Date(voucherInfo.endDateTime)).format(
                  "YYYY.MM.DD HH:mm"
                );
        }

        return (
          <Fragment key={index}>
            {item.type === "text" && (
              <div className="msg">
                <div className="text">{item.text}</div>
                <div className="time">{renderTime()}</div>
              </div>
            )}
            {item.type === "image" && (
              <div className="msg">
                <div className="img">
                  <img src={item.url} alt="" />
                </div>
                <div className="time">{renderTime()}</div>
              </div>
            )}
            {item.type === "voucher" && (
              <div className="msg">
                <div className="coupon">
                  <div className="coupon_thumbnail">
                    {voucherInfo.thumbnailURL !== "" && (
                      <img src={voucherInfo.thumbnailURL} alt="" />
                    )}
                  </div>
                  <div className="coupon_content">
                    <div className="coupon_content_title">
                      <div>{voucherInfo.title}</div>
                      <div>{voucherInfo.item}</div>
                    </div>
                    <div className="coupon_content_time">
                      <div>{shopName}</div>
                      <div>
                        {t("sendMenuTalk.coupon.dateExpired", {
                          date: endDateTime,
                        })}
                      </div>
                    </div>
                    <button>{t("sendMenuTalk.coupon.viewCoupon")}</button>
                  </div>
                </div>
                <div className="time">{renderTime()}</div>
              </div>
            )}
          </Fragment>
        );
      });
    }
  };

  const renderSentDateTime = () => {
    const dateTime =
      i18n.language === "zh"
        ? moment(new Date(previewInfo.schedule_time)).format(
            "YYYY年MM月DD日 HH:mm:ss"
          )
        : moment(new Date(previewInfo.schedule_time)).format(
            "YYYY.MM.DD HH:mm:ss"
          );
    return dateTime;
  };

  const cancelPopup = useCallback(() => {
    MessageStateDispatch({ type: "cancelPopup" });
    MessageAddDispatch({ type: "cancelPopup" });
  }, [MessageStateDispatch, MessageAddDispatch]);

  return (
    <Modal
      title={
        previewInfo.type === "menuTalk"
          ? previewInfo.title
          : t("sendMenuTalk.preview")
      }
      visible
      centered
      destroyOnClose
      width={423}
      className="modal-base modal-sm previewMenuTalkPopup"
      onCancel={cancelPopup}
      footer={null}
      transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className="previewBlock">
        <div className="dateTime">{renderDate()}</div>
        <div className="main">
          <div className="avatar">
            {menuUserAvatar ? <img src={menuUserAvatar} alt="" /> : ""}
          </div>
          <div className="content">{renderContent()}</div>
        </div>
      </div>

      {previewInfo.type === "menuTalk" && (
        <Fragment>
          <hr />
          <div className="block">
            {previewInfo.schedule_time && (
              <div className="text">
                {t("message.dateSent")}
                {renderSentDateTime()}
              </div>
            )}
            {previewInfo.filter_tag !== "" && (
              <div className="text">
                {t("message.audience")}
                {previewInfo.filter_tag}
              </div>
            )}
            <div className="tags">
              <div className="tag type">
                {t("sendMenuTalk.coupon.menuMessage")}
              </div>
              <div className="tag">
                {t("message.count", {
                  count: previewInfo.sending_counts,
                })}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Modal>
  );
};

export default PreviewMenuTalkPopup;
