/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { Link, browserHistory } from "react-router";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Menu, Button, Dropdown, notification } from "antd";

import {
  handleWebsocketConnection,
  handleWebsocketEventRegistry,
  cancelWebsocketSubscription,
} from "../../libs/handler";
import FlashMessage from "./FlashMessage";
import { handleError } from "../../libs/handler";
import { config } from "../../utils/config";
import { switchUser } from "../../actions/auth";
import { Logo, LogoWithName } from "./Logo";
import { createSettingsItem } from "../commons/SettingsItem";
import "../../stylesheets/allSetting.css";
import MenuCouponApi from "../menu_coupon/MenuCouponApi";

const menuCouponAPI = new MenuCouponApi();
const superAdminId = "findlifeadmin";

const openNotification = (title, message, backgroundColor, borderColor) => {
  notification.config({
    placement: "bottomLeft",
    top: 100,
  });

  notification.open({
    message: title,
    description: message,
    style: {
      background: backgroundColor,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: borderColor,
    },
  });
};

var createReactClass = require("create-react-class");
window.$ = $;
const Navbar = createReactClass({
  getInitialState() {
    this.alert = React.createRef();
    this.dashboardNavRef = React.createRef();
    return {
      shop_name: "",
      shop_corp_name: "",
      collapsed: false,
      menuKey: "",
      previousMenuKey: "",
      dropdownVisible: false,
      isSettingsExpand: false,
      showReport: false,
      windowWidth: window.innerWidth,
    };
  },
  getUser() {
    $.ajax({
      url: config.domain + this.props.profile_url,
      dataType: "json",
      xhrFields: { withCredentials: true },
    })
      .done((data) => {
        window.app.current_user = data.user;
        window.app.shop = data.user.shop;
        this.setState({
          shop_name: data.user.shop_name,
          shop_corp_name: data.user.shop_corp_name,
          init: true,
        });
      })
      .fail(function (xhr) {
        handleError(xhr);
      });
  },
  handleLogout() {
    $.ajax({
      url: config.domain + this.props.url,
      type: "DELETE",
      xhrFields: { withCredentials: true },
      success: function (data) {
        browserHistory.push(data.redirect_url);
      },
      error: function (xhr) {
        handleError(xhr);
      },
    });
  },
  async getShowReport() {
    try {
      const data = await menuCouponAPI.getShops();
      this.setState({ showReport: data.shop.show_report });
    } catch (err) {
      console.log("get shop name err-----", err);
    }
  },
  getDefaultProps() {
    var alertComp = <FlashMessage />;
    return {
      url: "/auth/sign_out.json",
      profile_url: "/dashboard/users/profile.json",
      alert: alertComp,
    };
  },
  componentDidMount: function () {
    handleWebsocketConnection();
    this.forceUpdate();
    this.getUser();
    this.getShowReport();
    document.addEventListener("click", this.handleClickOutside);
    window.addEventListener("resize", this.resizeScreen);
    window.addEventListener("scroll", this.handleScroll);

    handleWebsocketEventRegistry("queue_notify", function (data) {
      openNotification(
        data.message_title,
        data.group_title + " : " + data.name + data.message,
        data.background_color,
        data.border_color
      );
    });
  },
  handleScroll() {
    if (this.dashboardNavRef) {
      if (window.pageYOffset > 48) {
        this.dashboardNavRef.current.classList.add("dashboard-nav-hidden");
      } else {
        this.dashboardNavRef.current.classList.remove("dashboard-nav-hidden");
      }
    }
  },
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("resize", this.resizeScreen);
    window.removeEventListener("scroll", this.handleScroll);
    cancelWebsocketSubscription(["queue_notify"]);
  },
  handleClickOutside(e) {
    if (
      !e.target.closest(".ant-dropdown") &&
      !e.target.closest(".account-item") &&
      !e.target.closest(".ant-dropdown-menu-title-content") &&
      !e.target.closest(".ant-dropdown-menu-item")
    ) {
      this.closeDropdownMenu();
    }
  },
  resizeScreen() {
    this.setState({ windowWidth: window.innerWidth });
  },
  closeDropdownMenu() {
    this.setState({ dropdownVisible: false });
  },
  handleChangeUser(e, user_id) {
    if (user_id !== this.props.user.id)
      this.props.dispatch(switchUser(`${user_id}`));

    this.setState({
      menuKey: e.key,
      dropdownVisible: true,
    });
  },
  toggleCollapsed() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  },
  toggleSettingsExpand() {
    if (this.state.windowWidth > 767) {
      browserHistory.push("/dashboard/setting");
    } else {
      this.setState({
        isSettingsExpand: !this.state.isSettingsExpand,
      });
    }
  },
  handleMenuClick(e) {
    if (e.key === "shopName" && this.props.switchable_users.length === 0)
      return;
    if (
      e.key === "info" &&
      (!this.props.user.first_time_login_password ||
        this.props.user.user_id !== superAdminId)
    ) {
      browserHistory.push("/dashboard/users/profile");
    }
    if (e.key === "logout") this.handleLogout();

    if (e.key === "language") {
      const newLanguage = localStorage.getItem("userLanguage") === "zh" ? "en" : "zh";
      this.props.i18n.changeLanguage(newLanguage);
      localStorage.setItem('userLanguage', newLanguage);
    }

    this.setState({
      menuKey: e.key,
      dropdownVisible: e.key === "info" || e.key === "logout" ? false : true,
    });
  },
  toSettingItem(path) {
    browserHistory.push({
      pathname: path,
    });
    this.setState({ collapsed: false });
  },
  render() {
    if (this.state.init !== true) return null;
    const { t, switchable_users, shop } = this.props;
    const settingsItem = createSettingsItem(t, { ns: "settings" });
    const { pathname } = window.location;
    const queryMode = browserHistory.getCurrentLocation().query.mode;
    const isSettingActive = pathname.startsWith("/dashboard/setting");
    const mainMenu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item
          key="shopName"
          className={`dashboard-dropdown__title ${
            switchable_users.length <= 0 ? "menuItem-default" : ""
          }`}
        >
          <div>
            <div className="dashboard-dropdown__shopName">
              {shop.display_name}
            </div>
            <div className="dashboard-dropdown__branch">{shop.branch}</div>
          </div>
          {switchable_users.length > 0 && (
            <div className="dashboard-dropdown__icon arrowRight-icon" />
          )}
        </Menu.Item>

        <Menu.Item key="account" className="menuItem-default">
          <div>
            <div className="dashboard-dropdown__account">{shop.name}</div>
            <div className="dashboard-dropdown__account">{shop.corp_name}</div>
          </div>
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="language">
          <div className="dashboard-dropdown__icon language-icon" />
          {localStorage.getItem("userLanguage") === "zh"
            ? "English (US)"
            : "繁體中文"}
        </Menu.Item>

        <Menu.Item key="info">
          <div className="dashboard-dropdown__icon user-icon" />
          {t("accountSettings")}
        </Menu.Item>

        <Menu.Item key="logout">
          <div className="dashboard-dropdown__icon logout-icon" />
          {t("logout")}
        </Menu.Item>
      </Menu>
    );
    const branchAccountMenu = (
      <Menu>
        <Menu.Item key="return" onClick={this.handleMenuClick}>
          <div className="dashboard-dropdown__icon return-icon" />
          {t("back")}
        </Menu.Item>

        <Menu.Divider />

        {switchable_users.length !== 0 &&
          switchable_users.map((u) => (
            <Menu.Item
              key={`shop_${u.user_id}`}
              className={u.user_id === this.props.user.id ? "selected" : ""}
              onClick={(e) => this.handleChangeUser(e, u.user_id)}
            >
              <div className="dashboard-dropdown__icon check-icon" />
              {u.shop_name}
            </Menu.Item>
          ))}
      </Menu>
    );
    const settingMenu = (
      <Menu mode="inline" className="setting__menu">
        <Menu.Item key="1" className="account-item">
          <Dropdown
            trigger={["click"]}
            overlay={
              this.state.menuKey === "shopName" ? branchAccountMenu : mainMenu
            }
            visible={this.state.dropdownVisible}
            overlayClassName="dashboard-nav_dropdown"
            placement="bottomRight"
            arrow
            transitionName="ant-fade"
          >
            <Button
              type="text"
              onClick={() =>
                this.setState({
                  dropdownVisible: !this.state.dropdownVisible,
                  menuKey: "",
                })
              }
            >
              <div className="account-arrow-icon" />
            </Button>
          </Dropdown>
        </Menu.Item>
      </Menu>
    );
    const today = moment().format("YYYY-MM-DD");

    return (
      <div
        ref={this.dashboardNavRef}
        className={`dashboard-layout ${this.state.collapsed ? "nav-open" : ""}`}
      >
        <div
          className="dashboard-nav__collapse_mask"
          onClick={this.toggleCollapsed}
        />
        <header>
          <div className="dashboard-nav__collapse">
            <div className="collapse-block">
              <Button
                type="text"
                className="collapse-btn"
                onClick={this.toggleCollapsed}
              >
                <div className="hamburger-icon icon-normal" />
              </Button>
              <Logo isLinkEnabled showTestText />
            </div>
            {this.state.windowWidth < 769 && settingMenu}
          </div>
          <nav className="dashboard-nav">
            <div className="dashboard-nav__header">
              <Button
                type="text"
                className="collapse-btn"
                onClick={this.toggleCollapsed}
              >
                <div className="hamburger-icon icon-normal" />
              </Button>
              <LogoWithName isLinkEnabled callback={this.toggleCollapsed} />
            </div>
            <div className="dashboard-nav__menu">
              <div className="vertical-center">
                {this.props.user.first_time_login_password ? (
                  <Logo isLinkEnabled={false} showTestText={false} />
                ) : (
                  <Logo isLinkEnabled showTestText />
                )}
                <Menu
                  mode="inline"
                  onClick={(e) => {
                    e.domEvent.currentTarget.blur();
                  }}
                >
                  <Menu.Item
                    key="1"
                    className={
                      queryMode === "list" ||
                      queryMode === "timeline" ||
                      queryMode === "tablemap"
                        ? "active"
                        : ""
                    }
                    disabled={this.props.user.first_time_login_password}
                    onClick={() => {
                      browserHistory.push({
                        pathname: "/dashboard/new_module",
                        query: {
                          date: today,
                          mode: "timeline",
                        },
                      });
                      this.setState({ collapsed: false });
                    }}
                  >
                    <div className="dashboard-nav__icon booking-icon" />
                    {t("bookings")}
                  </Menu.Item>

                  <Menu.Item
                    key="2"
                    className={
                      pathname === "/dashboard/queue_system" ? "active" : ""
                    }
                    disabled={this.props.user.first_time_login_password}
                    onClick={() => {
                      browserHistory.push("/dashboard/queue_system");
                      this.setState({ collapsed: false });
                    }}
                  >
                    <div className="dashboard-nav__icon queue-icon" />
                    {t("queue")}
                  </Menu.Item>

                  <Menu.Item
                    key="3"
                    className={queryMode === "calendar" ? "active" : ""}
                    disabled={this.props.user.first_time_login_password}
                    onClick={() => {
                      browserHistory.push({
                        pathname: "/dashboard/bookings",
                        search: `?date=${today}&mode=calendar`,
                      });
                      this.setState({ collapsed: false });
                    }}
                  >
                    <div className="dashboard-nav__icon calendar-icon" />
                    {t("calendar")}
                  </Menu.Item>

                  <Menu.Item
                    key="4"
                    className={
                      pathname === "/dashboard/customers" ? "active" : ""
                    }
                    disabled={this.props.user.first_time_login_password}
                    onClick={() => {
                      browserHistory.push("/dashboard/customers");
                      this.setState({ collapsed: false });
                    }}
                  >
                    <div className="dashboard-nav__icon customer-icon" />
                    {t("customers")}
                  </Menu.Item>

                  <Menu.Item
                    key="5"
                    className={
                      pathname === "/dashboard/messages" ? "active" : ""
                    }
                    disabled={this.props.user.first_time_login_password}
                    onClick={() => {
                      browserHistory.push("/dashboard/messages");
                      this.setState({ collapsed: false });
                    }}
                  >
                    <div className="dashboard-nav__icon message-icon" />
                    {t("push")}
                  </Menu.Item>

                  <Menu.Item
                    key="6"
                    className={
                      pathname === "/dashboard/announcement" ? "active" : ""
                    }
                    disabled={this.props.user.first_time_login_password}
                    onClick={() => {
                      browserHistory.push("/dashboard/announcement");
                      this.setState({ collapsed: false });
                    }}
                  >
                    <div className="dashboard-nav__icon announcement-icon" />
                    {t("bulletin")}
                  </Menu.Item>

                  <Menu.Item
                    key="7"
                    className={
                      pathname === "/dashboard/menu_coupon" ? "active" : ""
                    }
                    disabled={this.props.user.first_time_login_password}
                    onClick={() => {
                      browserHistory.push("/dashboard/menu_coupon");
                      this.setState({ collapsed: false });
                    }}
                  >
                    <div className="dashboard-nav__icon coupon-icon" />
                    {t("coupon")}
                  </Menu.Item>

                  <Menu.Item
                    key="8"
                    className={isSettingActive ? "active" : ""}
                    disabled={this.props.user.first_time_login_password}
                    onClick={this.toggleSettingsExpand}
                  >
                    <div className="dashboard-nav__icon setting-icon" />
                    {t("settings")}
                    {this.state.isSettingsExpand ? (
                      <div className="dashboard-nav__icon arrowUp-icon" />
                    ) : (
                      <div className="dashboard-nav__icon arrowDown-icon" />
                    )}
                  </Menu.Item>
                </Menu>
                <div className="dashboard-nav__settingsMenu settingMenu">
                  {this.state.isSettingsExpand && (
                    <>
                      {settingsItem.map((page, index) => {
                        let pageBlockIcon = "pageBlockIcon_" + index;
                        return (
                          <div className="pageBlock" key={index}>
                            <h2 className={pageBlockIcon}>{page.title}</h2>
                            <ul>
                              {page.subPage.map((item, index) => {
                                let active = pathname === item.path;
                                return (
                                  <li
                                    key={index}
                                    className={`pageBlock__item ${
                                      active ? "active" : ""
                                    }`}
                                    onClick={() =>
                                      this.toSettingItem(item.path)
                                    }
                                  >
                                    {item.title}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      })}
                      <div className="pageBlock_single">
                        <Link to={"/dashboard/setting/users"}>
                          <h2 className="pageBlockIcon_5">
                            {t("settings:userSettings.title")}
                          </h2>
                        </Link>
                      </div>
                      {this.state.showReport && (
                        <div className="pageBlock_single">
                          <Link to={"/dashboard/setting/report"}>
                            <h2 className="pageBlockIcon_5">匯出數據報表</h2>
                          </Link>
                        </div>
                      )}
                      {config.env !== "production" && (
                        <div className="pageBlock">
                          <Link to={"/dashboard/setting/advanced"}>
                            <h2 className="pageBlockIcon_5">
                              進階設定(Dev Only)
                            </h2>
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {this.state.windowWidth > 768 && settingMenu}
            </div>
          </nav>
        </header>
        <main>
          <FlashMessage forwardRef={this.alert} />
          {React.cloneElement(this.props.children, { alert: this.refs.alert })}
        </main>
      </div>
    );
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    shop: state.auth.shop,
    switchable_users: state.auth.switchable_users,
  };
};

export default connect(mapStateToProps)(
  withTranslation(["common", "settings"])(Navbar)
);
