import React, { Fragment, useCallback, useContext } from "react";
import { createPortal } from "react-dom";
import MenuCouponAddHeader from "./MenuCouponAddHeader";
import MenuCouponPopup from "../MenuCouponPopup/MenuCouponPopup";
import MenuCropper from "./MenuCropper";
import { CouponAddStateContext } from "../MenuCouponContext/MenuCouponAddContext";
import MenuCouponUploadImg from "./MenuCouponUploadImg";
import CouponInfoSection from "./CouponInfoSection";
import CouponApplyTypeSection from "./CouponApplyTypeSection";
import CouponAttentionSection from "./CouponAttentionSection";
import CouponPasswordSection from "./CouponPasswordSection";
import Expire from "./Expire";
import Publish from "./Publish";
import CancelAddPopup from "./CancelAddPopup";

const MenuCouponAdd = ({ closeAddCoupon }) => {
  const { CouponAddState, CouponAddDispatch } = useContext(
    CouponAddStateContext
  );
  const { showCropper, showCancelPopup } = CouponAddState;

  const closeCropper = useCallback(() => {
    CouponAddDispatch({ type: "setShowCropper", showCropper: false });
  }, [CouponAddDispatch]);

  const closeAddCouponView = () => {
    CouponAddDispatch({ type: "setCloseAddCouponView" });
  };

  const confirmPopup = useCallback(() => {
    CouponAddDispatch({ type: "setClearState" });
    closeAddCoupon();
  }, [CouponAddDispatch, closeAddCoupon]);

  const cancelPopup = useCallback(() => {
    CouponAddDispatch({ type: "setCancelPopup" });
  }, [CouponAddDispatch]);

  const saveDraft = useCallback(() => {
    CouponAddDispatch({ type: "setClearState" });
    closeAddCoupon();
  }, [CouponAddDispatch, closeAddCoupon]);

  return (
    <Fragment>
      <MenuCouponAddHeader />

      <div className="couponEdit">
        <button className="goBackBtn" onClick={closeAddCouponView} />

        <MenuCouponUploadImg>
          <MenuCouponUploadImg.Title />
          <div className="uploadImg">
            <MenuCouponUploadImg.Landscape />
            <MenuCouponUploadImg.Portrait />
          </div>
          <MenuCouponUploadImg.Description />
        </MenuCouponUploadImg>

        <CouponInfoSection>
          <CouponInfoSection.Title />
          <CouponInfoSection.CouponTitle />
          <CouponInfoSection.CouponContent />
        </CouponInfoSection>

        <CouponApplyTypeSection>
          <CouponApplyTypeSection.Title />
          <CouponApplyTypeSection.Limited />
          <CouponApplyTypeSection.Unlimited />
        </CouponApplyTypeSection>

        <CouponAttentionSection>
          <CouponAttentionSection.Title />
          <CouponAttentionSection.Content />
        </CouponAttentionSection>

        <CouponPasswordSection>
          <CouponPasswordSection.Title />
          <CouponPasswordSection.Password />
        </CouponPasswordSection>

        <Expire>
          <Expire.Title />
          <Expire.Content />
        </Expire>

        <Publish>
          <Publish.Title />
          <Publish.Datetime />
          <Publish.Btn />
        </Publish>
      </div>

      {showCropper && (
        <Portal>
          <MenuCouponPopup>
            <MenuCropper closeCropper={closeCropper} />
          </MenuCouponPopup>
        </Portal>
      )}

      {showCancelPopup && (
        <Portal>
          <MenuCouponPopup>
            <CancelAddPopup
              confirmPopup={confirmPopup}
              cancelPopup={cancelPopup}
              saveDraft={saveDraft}
            />
          </MenuCouponPopup>
        </Portal>
      )}
    </Fragment>
  );
};

const Portal = ({ children }) => {
  return createPortal(children, document.getElementById("modalPortal"));
};

export default MenuCouponAdd;
