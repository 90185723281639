import React, {
  useCallback,
  useState,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { browserHistory } from "react-router";
import { useTranslation } from "react-i18next";

import CrmMessageAdd from "./crmMessage/crmMessageAdd/CrmMessageAdd";
import CrmConfirmMessage from "./crmMessage/crmMessageAdd/CrmConfirmMessage";

import Toolbar from "./commons/CrmToolbar";
import Tab from "./commons/CrmTab";
import CustomerFilterBar from "./commons/CrmCustomerFilterBar";

import {
  CustomerContext,
  CustomerReducer,
  customerState,
} from "./crmContext/CustomerContext";
import {
  MessageContext,
  MessageReducer,
  messageState,
} from "./crmContext/MessageContext";
import {
  MessageAddStateContext,
  MessageAddReducer,
  messageAddInitState,
} from "./crmContext/MessageAddContext";

const Crm = (props) => {
  const { t } = useTranslation("customer");
  const [CustomerState, CustomerStateDispatch] = useReducer(
    CustomerReducer,
    customerState
  );
  const { showCustomerFilterBar, customerFilterNum, searchCustomerMode } =
    CustomerState;
  const [MessageState, MessageStateDispatch] = useReducer(
    MessageReducer,
    messageState
  );
  const { mode } = MessageState;
  const [MessageAddState, MessageAddDispatch] = useReducer(
    MessageAddReducer,
    messageAddInitState
  );
  const { isSaved } = MessageAddState;

  const [currentPath, setCurrentPath] = useState("customerList");
  const mainRef = useRef(null);
  const pathname = browserHistory
    .getCurrentLocation()
    .pathname.replace("/dashboard/", "");

  const routerWillLeave = useCallback(() => {
    if (mode === "addMessage" || mode === "confirmMessage") {
      if (!isSaved) return t("messages:popup.editExist_content");
    }
  }, [mode, isSaved, t]);

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    props.router.setRouteLeaveHook(props.route, routerWillLeave);
  }, [props, routerWillLeave]);

  useEffect(() => {
    if (currentPath === "customers") {
      MessageStateDispatch({
        type: "switchMode",
        mode: "customerList",
        editType: "",
      });
    } else if (currentPath === "messages") {
      MessageStateDispatch({
        type: "switchMode",
        mode: "messageList",
        editType: "",
      });
    } else {
      MessageStateDispatch({
        type: "switchMode",
        mode: "crmSettings",
        editType: "",
      });
    }
    setCurrentPath(pathname);
  }, [currentPath, pathname]);

  useEffect(() => {
    if (mode !== "customerList") {
      resetCustomerFilter();
      CustomerStateDispatch({ type: "setCustomerFilterBar", show: false });
    }
  }, [mode]);

  const renderActiveMode = useCallback(() => {
    if (mode === "addMessage") {
      return <CrmMessageAdd />;
    } else if (mode === "confirmMessage") {
      return <CrmConfirmMessage />;
    } else {
      return props.children;
    }
  }, [mode, props]);

  const resetCustomerFilter = () => {
    CustomerStateDispatch({ type: "resetCustomerFilter" });
  };

  const renderResetCustomerFilterBar = () => {
    return (
      <div
        className={`crmCustomer_filter_reset ${
          showCustomerFilterBar ? "sticky_lower" : ""
        }`}
      >
        <div onClick={resetCustomerFilter}>
          <CloseCircleFilled style={{ color: "#EF6868" }} />
          {t("filterBar.clearAll")}
        </div>
      </div>
    );
  };

  return (
    <CustomerContext.Provider value={{ CustomerState, CustomerStateDispatch }}>
      <MessageContext.Provider value={{ MessageState, MessageStateDispatch }}>
        <MessageAddStateContext.Provider
          value={{ MessageAddState, MessageAddDispatch }}
        >
          <div ref={mainRef} className="crmWrap system-layout">
            {mode === "customerList" || mode === "messageList" ? (
              <Toolbar />
            ) : null}
            {(mode === "customerList" && !searchCustomerMode) ||
            mode === "messageList" ? (
              <Tab />
            ) : null}
            {mode === "customerList" && showCustomerFilterBar ? (
              <CustomerFilterBar />
            ) : null}
            {mode === "customerList" && customerFilterNum !== 0
              ? renderResetCustomerFilterBar()
              : null}

            {renderActiveMode()}
          </div>
        </MessageAddStateContext.Provider>
      </MessageContext.Provider>
    </CustomerContext.Provider>
  );
};

export default Crm;
