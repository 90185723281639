import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CouponAddStateContext } from "../MenuCouponContext/MenuCouponAddContext";

const CouponPasswordSection = ({ children }) => {
  return (
    <section className="couponAttention">
      {React.Children.map(children, (child) => child)}
    </section>
  );
};

const CouponSectionTitle = () => {
  const { t } = useTranslation("couponSystem");
  return <h2>{t("addCoupon.redeemPasswords")}</h2>;
};

const CouponPasswordTxt = () => {
  const { t } = useTranslation("couponSystem");
  const { CouponAddState, CouponAddDispatch } = useContext(
    CouponAddStateContext
  );
  const { couponSetting, checkSetting } = CouponAddState;
  const { confirmCode } = couponSetting;
  const errorStyle = checkSetting && confirmCode === "" ? "errorStyle" : "";

  const handlePassword = (e) => {
    const reg = /^\d+$/;
    const txt = e.target.value;
    const regTest = reg.test(txt);
    if (txt !== "" && !regTest) {
      return window.app.alert.setMessage(
        t("addCoupon.pls_enterDigits"),
        "error"
      );
    }
    CouponAddDispatch({ type: "setPassword", confirmCode: txt });
  };

  return (
    <div className={`${errorStyle}`}>
      <input
        type="tel"
        placeholder={t('addCoupon.enter4Digits')}
        maxLength="4"
        value={confirmCode}
        onChange={(e) => handlePassword(e)}
      />
      {errorStyle !== "" && (
        <p className="errorTxt">{t("addCoupon.pls_requiredField")}</p>
      )}
    </div>
  );
};

CouponPasswordSection.Title = CouponSectionTitle;
CouponPasswordSection.Password = CouponPasswordTxt;

export default CouponPasswordSection;
