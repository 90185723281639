import React from 'react'
import $ from 'jquery'
import CallToolbar from './CallToolbar'
import CallList from './CallList'
// import CustomerForm from '../customers/CustomerForm'
import CallModalDelete from './CallModalDelete'
import { handleError } from '../../libs/handler'
var createReactClass = require('create-react-class');
export default createReactClass({
  load() {
    $.ajax({
      url: window.domain + this.props.url,
      dataType: 'json',
      data: this.refs.toolbar.state,
      xhrFields: { withCredentials: true },
      success: function(data) {
        this.setState({data: data.calls})
      }.bind(this),
      error: function(xhr) {
        handleError(xhr);
      }
    });
  },
  handleSubmit(url, data, type, msg, alert) {
    $.ajax({
      url: window.domain + url,
      dataType: 'json',
      data: data,
      type: type,
      xhrFields: { withCredentials: true },
      success: function(res) {
        this.props.alert.setMessage(res.message, alert)
        this.load();
      }.bind(this),
      error: function(xhr) {
        this.props.alert.setMessage(xhr.responseJSON.message, 'error')
      }.bind(this)
    });
  },
  handleTempChange(value) {
    this.setState({temp: value});
  },
  getDefaultProps() {
    return {
      url: '/dashboard/calls'
    };
  },
  getInitialState() {
    return {
      data: [],
      page: 1,
      per: 10,
      page_num: 0,
      temp: {}
    }
  },
  componentDidMount() {
    this.load();
    window.app.refresh_callbox = this.load;
  },
  componentWillUnmount() {
    delete window.app.refresh_callbox;
  },
  handleAddClick() {
    this.refs.form.show();
  },
  render() {
    var call_lists = this.state.data.map(function(call_list) {
      for (var k in call_list) {
        if(call_list.hasOwnProperty(k)){
          return <CallList key={k} 
                         header={k} 
                         data={call_list[k]} 
                         onTempChange={this.handleTempChange}
                         onAddClick={this.handleAddClick}
                         onSubmit={this.handleSubmit} />
        }
      }
    }.bind(this));
    return (
      <div>
        <div className="container container-shadow">
          <div className="func-title">
            <img
              src={require("../../images/component/f_phonecall.svg")}
              alt=""
            />
            <h1>來電系統</h1>
            <div className="phonecall">
              <a
                href={window.app.shop.setup_pack_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                下載電話盒安裝包
              </a>
            </div>
          </div>
          <div className="main">
            <CallToolbar
              ref="toolbar"
              onInputChange={this.load}
              alert={this.props.alert}
            />
            {call_lists}
          </div>
        </div>
        {/* <CustomerForm data={this.state.temp} alert={this.props.alert} onSubmitCallback={this.load} ref='form' /> */}
        <CallModalDelete data={this.state.temp} onSubmit={this.handleSubmit} />
      </div>
    );
  }
});
