import React, { useContext } from "react";
import { browserHistory } from "react-router";

import { CommomSettingContext } from "../new_module/NewModuleReducer/CommomReuducer";
import { ListStateContext } from "../new_module/NewModuleReducer/ListReducer";

import Empty from "./Empty";
import QueueCard from "./QueueCard";

export default ({ searchType, bookingSearchRes, reset }) => {
  const { CommomSettingDispatch } = useContext(CommomSettingContext);
  const { ListStateDispatch } = useContext(ListStateContext);

  const bookingLength = Object.values(bookingSearchRes).reduce(
    (res, booking) => (res += booking.length),
    0
  );

  const toList = (toDate, booking) => {
    const {
      query: { date },
    } = browserHistory.getCurrentLocation();
    const IsToday = date === toDate;

    reset();

    if (IsToday && searchType === "newModule") {
      ListStateDispatch({ type: "setActiveSystem", systemTab: "queue" });
      ListStateDispatch({ type: "searchQueue", booking });
    }

    if (!IsToday && searchType === "newModule") {
      CommomSettingDispatch({ type: "changeUrlDate", queryDate: toDate });
      ListStateDispatch({ type: "setActiveSystem", systemTab: "queue" });
      ListStateDispatch({ type: "searchQueue", booking });
    }
  };

  if (bookingLength === 0) return <Empty />;
  return (
    <div className="bookingSection">
      {bookingSearchRes.map((queue) => (
        <QueueCard key={queue.id} queue={queue} toList={toList} />
      ))}
    </div>
  );
};
