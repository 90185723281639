import React, { Fragment, useState, useContext } from "react";
import moment from "moment";
import _ from "lodash";
import Datetime from "react-datetime";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { CouponAddStateContext } from "../MenuCouponContext/MenuCouponAddContext";
import { CouponStateContext } from "../MenuCouponContext/MenuCouponContext";

import MenuCouponApi from "../MenuCouponApi";

const menuCouponAPI = new MenuCouponApi();

const Publish = ({ children }) => {
  return (
    <section className="couponLaunchDate">
      {React.Children.map(children, (child) => child)}
    </section>
  );
};

const PublishTitle = () => {
  const { t } = useTranslation("couponSystem");
  return <h2>{t("addCoupon.releaseTime")}</h2>;
};

const PublishDatetime = () => {
  const { t } = useTranslation("couponSystem");
  const { CouponAddState, CouponAddDispatch } = useContext(
    CouponAddStateContext
  );
  const { couponSetting, originStatus } = CouponAddState;
  const { status, publishDateTime } = couponSetting;

  const { CouponState } = useContext(CouponStateContext);
  const { editType } = CouponState;

  const handleLaunchType = (publishType) => {
    CouponAddDispatch({ type: "setLaunchType", publishType });
  };

  const renderPublishType = () => {
    if (
      editType === "edit" &&
      (originStatus === "enable" || originStatus === "disable")
    ) {
      return <p>{t("addCoupon.released")}</p>;
    }

    return (
      <Fragment>
        <div className="timePickerWrapLayout hide" />

        <div className="couponRow">
          <input
            type="radio"
            name="couponLaunchDate"
            id="couponLaunchDate_1"
            checked={status === "enable" ? true : false}
            onChange={() => handleLaunchType("enable")}
          />
          <label htmlFor="couponLaunchDate_1">
            <span>{t("addCoupon.immediately")}</span>
          </label>
        </div>
        <div className="couponRow">
          <input
            type="radio"
            name="couponLaunchDate"
            id="couponLaunchDate_2"
            checked={status === "draft" ? true : false}
            onChange={() => handleLaunchType("draft")}
          />
          <label htmlFor="couponLaunchDate_2">
            <span>{t("saveAsDraft")}</span>
          </label>
        </div>

        <div className="couponRow">
          <input
            type="radio"
            name="couponLaunchDate"
            id="couponLaunchDate_3"
            checked={status === "standby" ? true : false}
            onChange={() => handleLaunchType("standby")}
          />
          <label
            htmlFor="couponLaunchDate_3"
            className="couponLaunchDatePicker"
          >
            <span>{t("addCoupon.scheduledOn")}</span>
            <div className="dateWrap">
              <Datetime
                className="datetimePicker"
                value={publishDateTime}
                onChange={(datetime) => {
                  CouponAddDispatch({
                    type: "setPublishDateTime",
                    publishDateTime: datetime,
                  });
                }}
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm"
                inputProps={{
                  readOnly: true,
                  disabled:
                    editType === "edit" &&
                    (originStatus === "enable" || originStatus === "disable"),
                }}
                isValidDate={(currentDate) => {
                  return currentDate.isAfter(moment().subtract(1, "day"));
                }}
              />
            </div>
          </label>
        </div>
      </Fragment>
    );
  };

  return renderPublishType();
};

const PublishBtn = () => {
  const { t } = useTranslation('couponSystem');
  const auth = useSelector((state) => state.auth);
  const { name } = auth.shop;

  const { CouponAddState, CouponAddDispatch } = useContext(
    CouponAddStateContext
  );
  const { couponSetting, originStatus } = CouponAddState;
  const { CouponDispatch } = useContext(CouponStateContext);

  const { CouponState, getCouponList } = useContext(CouponStateContext);
  const { editType } = CouponState;

  const [showLoading, setShowLoading] = useState(false);

  const submit = (e) => {
    const {
      status,
      id,
      coverImgFile,
      coverURL,
      thumbnailImgFile,
      thumbnailURL,
      title,
      endDateTime,
      confirmCode,
      countLimited,
      voucherCount,
      sentCount,
      publishDateTime,
    } = couponSetting;

    const $btn = e.target;

    //草稿: 標題必填
    if (status === "draft") {
      if (title.trim() === "") {
        window.app.alert.setMessage(t('addCoupon.pls_enterTitle'), "error");
        return;
      }
    }

    //立即上架&自行排定
    if (status !== "draft") {
      CouponAddDispatch({ type: "checkSetting" });

      const applyCountNumber = parseInt(voucherCount, 10);

      if (thumbnailURL === "" && thumbnailImgFile === null) {
        window.app.alert.setMessage(t('addCoupon.pls_uploadImages'), "error");
        return;
      }

      if (coverImgFile === null && coverURL === "") {
        window.app.alert.setMessage(t('addCoupon.pls_uploadImages'), "error");
        return;
      }

      if (title.trim() === "") {
        window.app.alert.setMessage(t('addCoupon.pls_enterTitle'), "error");
        return;
      }

      if (endDateTime === "") {
        window.app.alert.setMessage(t('addCoupon.pls_setUpCampaignDuration'), "error");
        return;
      }

      if (confirmCode === "") {
        window.app.alert.setMessage(t('addCoupon.pls_setUpPassword'), "error");
        return;
      }

      if (confirmCode.length !== 4) {
        window.app.alert.setMessage(t('addCoupon.pls_passwordShouldConsist'), "error");
        return;
      }

      if (countLimited && applyCountNumber < 1) {
        window.app.alert.setMessage(t('addCoupon.pls_enterNumberOfCoupons'), "error");
        return;
      }

      if (countLimited && applyCountNumber > 10000) {
        window.app.alert.setMessage(t('addCoupon.pls_maximumOfCoupon'), "error");
        return;
      }

      const nowDateTime = new Date();

      if (status === "enable") {
        //新增：若立即上架: 活動結束時間>現在時間
        if (new Date(endDateTime) < nowDateTime) {
          window.app.alert.setMessage(t('addCoupon.pls_endTimeError'), "error");
          return;
        }
      }

      if (status === "standby") {
        //若為排定發布日期: 發布日期<兌換開始時間 && 發布時間>現在時間

        if (publishDateTime === "") {
          window.app.alert.setMessage(t('addCoupon.pls_setReleaseTime'), "error");
          return;
        }

        if (nowDateTime > new Date(publishDateTime)) {
          window.app.alert.setMessage(
            t('addCoupon.pls_releaseTimeError'),
            "error"
          );
          return;
        }

        if (endDateTime < new Date(publishDateTime)) {
          window.app.alert.setMessage(
            t('addCoupon.pls_scheduledTimeError'),
            "error"
          );
          return;
        }
      }
    }

    if (id && countLimited === 1 && parseInt(voucherCount, 10) < sentCount) {
      window.app.alert.setMessage(
        t('addCoupon.pls_couponOfferedNumberError'),
        "error"
      );
      return;
    }

    $btn.setAttribute("disabled", true);

    setShowLoading(true);
    if (editType === "add")
      addNewCouponAPI(couponSetting, originStatus, name, $btn);
    if (editType === "edit")
      editCouponAPI(couponSetting, originStatus, name, $btn);
  };

  const editCouponAPI = async (couponSetting, originStatus, name, $btn) => {
    const newCouponSetting = _.cloneDeep(couponSetting);
    try {
      const editCoupon = await menuCouponAPI.updateCoupon(
        setNewCoupon(newCouponSetting, originStatus, name)
      );
      $btn.removeAttribute("disabled");
      setShowLoading(false);
      getCouponList();
      CouponDispatch({ type: "addNewCouponSetting" });
      if (originStatus !== "enable")
        CouponDispatch({ type: "showSharepopup", listInfo: editCoupon });
      //新增成功後清除資料
      CouponAddDispatch({ type: "setClearState" });
    } catch (err) {
      console.log("postCoupon 編輯coupon err---", err);
      window.app.alert.setMessage(t('settings:status.pls_tryAgainLater'), "error");
      $btn.removeAttribute("disabled");
      setShowLoading(false);
    }
  };

  const addNewCouponAPI = async (couponSetting, originStatus, name, $btn) => {
    const newCouponSetting = _.cloneDeep(couponSetting);
    try {
      const newCoupon = await menuCouponAPI.addCoupon(
        setNewCoupon(newCouponSetting, originStatus, name)
      );
      $btn.removeAttribute("disabled");
      setShowLoading(false);
      getCouponList();
      CouponDispatch({ type: "addNewCouponSetting" });
      CouponDispatch({ type: "showSharepopup", listInfo: newCoupon });
      //新增成功後清除資料
      CouponAddDispatch({ type: "setClearState" });
    } catch (err) {
      console.log("postCoupon 新增coupon err---", err);
      window.app.alert.setMessage(t('settings:status.pls_tryAgainLater'), "error");
      $btn.removeAttribute("disabled");
      setShowLoading(false);
    }
  };

  const renderSubmitButton = () => {
    return (
      <button className="submitButton" onClick={(e) => submit(e)}>
        {t('save')}
      </button>
    );
  };

  return (
    <Fragment>
      <div className="text-right">{renderSubmitButton()}</div>
      {showLoading && (
        <Modal
          destroyOnClose
          visible={showLoading}
          zIndex="1035"
          width={200}
          footer={null}
          closable={false}
          maskClosable={false}
          bodyStyle={{
            padding: 10,
            textAlign: "center",
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          <LoadingOutlined style={{ fontSize: 30 }} />
          <p style={{ margin: "5px 0 0 0" }}>Loading ...</p>
        </Modal>
      )}
    </Fragment>
  );
};

const setNewCoupon = (newCouponSetting, originStatus, shopName) => {
  const {
    id,
    status,
    voucherCount,
    coverImgFile,
    thumbnailImgFile,
    countLimited,
    title,
    item,
    endDateTime,
    description,
    confirmCode,
    publishDateTime,
  } = newCouponSetting;

  let fd = new FormData();

  // 新增/編輯: status為草稿的話publishTime/startDateTime帶null
  if (status === "draft") {
    fd.append("publishDateTime", null);
    fd.append("startDateTime", null);
  }

  // 新增：status為立即上架的話publishTime/startDateTime帶現在日期
  if (!Boolean(id) && status === "enable") {
    let todayDatetime = new Date().toISOString();
    fd.append("publishDateTime", todayDatetime);
    fd.append("startDateTime", todayDatetime);
  }

  //編輯：從草稿或排定變成立即上架publishTime/startDateTime帶現在日期
  if (Boolean(id) && originStatus !== "enable" && status === "enable") {
    let todayDatetime = new Date().toISOString();
    fd.append("publishDateTime", todayDatetime);
    fd.append("startDateTime", todayDatetime);
  }

  // 新增/編輯:status為排定上架publishTime/startDateTime帶輸入的日期
  if (status === "standby") {
    fd.append("publishDateTime", publishDateTime.toISOString());
    fd.append("startDateTime", publishDateTime.toISOString());
  }

  if (id) {
    fd.append("id", id);
  }
  fd.append("status", status);
  fd.append("title", title);
  fd.append("item", item);
  fd.append("countLimited", countLimited);
  fd.append("voucherCount", parseInt(voucherCount, 10));
  fd.append("description", description);
  fd.append("confirmCode", confirmCode);
  fd.append("shopUserId", shopName);

  if (endDateTime !== "") {
    //若有設定結束時間才需要帶
    fd.append("endDateTime", endDateTime.toISOString());
  }

  if (thumbnailImgFile !== null && typeof thumbnailImgFile !== "string") {
    //若有設定才需要帶
    fd.append("thumbnail", thumbnailImgFile);
  }

  if (coverImgFile !== null && typeof coverImgFile !== "string") {
    //若有設定才需要帶
    fd.append("cover", coverImgFile);
  }

  return fd;
};

Publish.Title = PublishTitle;
Publish.Datetime = PublishDatetime;
Publish.Btn = PublishBtn;

export default Publish;
