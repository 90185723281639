import React, { useState, useEffect, useContext, Fragment } from "react";
import { createPortal } from "react-dom";
import { Modal } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { CouponStateContext } from "./MenuCouponContext/MenuCouponContext";
import MenuCouponPopup from "./MenuCouponPopup/MenuCouponPopup";
import DeleteCouponPopup from "./MenuCouponPopup/DeleteCouponPopup";
import PauseCouponPopup from "./MenuCouponPopup/PauseCouponPopup";
import PasswordPopup from "./MenuCouponPopup/PasswordPopup";
import CancelPausePopup from "./MenuCouponPopup/CancelPausePopup";
import PreviewPopup from "./MenuCouponPopup/Preview";
import SharePopup from "./MenuCouponPopup/SharePopup";
import MenuCouponListCard from "./ListCard";

const MenuCouponList = () => {
  const { t } = useTranslation("couponSystem");
  const { CouponState, CouponDispatch } = useContext(CouponStateContext);
  const {
    couponLists,
    activeCategory,
    showDeletePopup,
    showPausePopup,
    showPasswordPopup,
    showCancelPausePopup,
    showPreviewPopup,
    showSharePopup,
  } = CouponState;
  const [filterCouponLists, setFilterCouponLists] = useState(couponLists);

  useEffect(() => {
    if (activeCategory !== "all") {
      const filterLists = couponLists.filter(
        (list) => list.status === activeCategory
      );
      setFilterCouponLists(filterLists);
    } else {
      const newLists = _.cloneDeep(couponLists);
      setFilterCouponLists(newLists);
    }
  }, [couponLists, activeCategory]);

  return (
    <Fragment>
      <div className="menuCouponList">
        {filterCouponLists.map((list, index) => {
          return (
            <MenuCouponListCard key={index}>
              <MenuCouponListCard.Img
                status={list.status}
                thumbnailURL={list.thumbnailURL}
              />
              <MenuCouponListCard.Content list={list} />
            </MenuCouponListCard>
          );
        })}
      </div>
      {showDeletePopup && (
        <Portal>
          <MenuCouponPopup>
            <DeleteCouponPopup />
          </MenuCouponPopup>
        </Portal>
      )}

      {showPausePopup && (
        <Portal>
          <MenuCouponPopup>
            <PauseCouponPopup />
          </MenuCouponPopup>
        </Portal>
      )}

      {showPasswordPopup && (
        <Portal>
          <MenuCouponPopup>
            <PasswordPopup />
          </MenuCouponPopup>
        </Portal>
      )}

      {showCancelPausePopup && (
        <Portal>
          <MenuCouponPopup>
            <CancelPausePopup />
          </MenuCouponPopup>
        </Portal>
      )}

      {showPreviewPopup && (
        <Modal
          className="previewPopup"
          destroyOnClose
          title={t("previewContent.title")}
          visible={showPreviewPopup}
          footer={null}
          onCancel={() => {
            CouponDispatch({ type: "cancelPopup" });
          }}
        >
          <PreviewPopup />
        </Modal>
      )}

      {showSharePopup && (
        <Modal
          className="sharePopup"
          destroyOnClose
          title={t("popup.shareCoupon")}
          width={580}
          visible={showSharePopup}
          footer={null}
          onCancel={() => {
            CouponDispatch({ type: "cancelPopup" });
          }}
        >
          <SharePopup />
        </Modal>
      )}
    </Fragment>
  );
};

const Portal = ({ children }) => {
  return createPortal(children, document.getElementById("modalPortal"));
};

export default MenuCouponList;
