import React from "react";
import { useTranslation } from "react-i18next";

const NoBookingsView = ({ mode = "list" }) => {
  const { t } = useTranslation("bookingSystem");
  return (
    <div
      className={`noBookingsView h-full vertical-center ${
        mode !== "list" ? "noBookingsView-sm" : ""
      }`}
    >
      <img src={require("../../images/bookingList-default.svg")} alt="" />
      <div className="text-title color-subtitle">{t('noBookingsContent')}</div>
    </div>
  );
};

export default NoBookingsView;
