import { useState, useEffect } from "react";

const useScreenSize = (mobileBreakpoint = 768) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < mobileBreakpoint
  );
  const [showNumpad, setShowNumpad] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      setScreenWidth(currentWidth);
      setIsMobile(currentWidth <= mobileBreakpoint);
      setShowNumpad()
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [mobileBreakpoint]);

  return { screenWidth, isMobile, showNumpad };
}

export default useScreenSize;
