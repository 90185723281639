import React, { useEffect } from "react";
import { browserHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import AnnouncementListModal from "../announcement/announcementPopup/AnnouncementListModal";
import AddAnnouncementModal from "../announcement/announcementPopup/AnnouncementAddModal";
import AnnouncementCheckModal from "../announcement/announcementPopup/AnnouncementDeleteModal";
import AnnouncementCancelModal from "../announcement/announcementPopup/AnnouncementCancelModal";
import {
  handleAnnouncementListModal,
  updateDayAnnouncements,
  announcementOfficialFetch,
  announcementFetchMonth,
  // resetAnnouncementList,
} from "../../actions/announcementAction";

import {
  handleWebsocketEventRegistry,
  cancelWebsocketSubscription,
} from "../../libs/handler";

function BulletinIcon({ mode = "day" }) {
  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const {
    dayAnnouncements,
    monthAnnouncements,
    showAnnouncementListModal,
    showAnnouncementDeleteModal,
    showAnnouncementCancelModal,
  } = announcementListReducer;
  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const { showAddAnnouncementModal } = announcementInfo;
  const dispatch = useDispatch();

  let urlDate = browserHistory.getCurrentLocation().query.date;
  let queryDate = urlDate
    ? moment(new Date(urlDate)).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");

  useEffect(() => {
    handleWebsocketEventRegistry("sync_announcements", () => {
      setTimeout(() => {
        dispatch(updateDayAnnouncements(queryDate));
        dispatch(announcementFetchMonth(moment(urlDate).format("YYYY-MM")));
      }, 3000);
    });

    return () => cancelWebsocketSubscription(["sync_announcements"]);
  }, [dispatch, queryDate, urlDate]);

  useEffect(() => {
    dispatch(updateDayAnnouncements(queryDate));
    dispatch(announcementOfficialFetch());
  }, [dispatch, queryDate]);

  const renderIcon = () => {
    let displayIcon = "null";
    let size = "normal";

    if (mode === "month") {
      const isEmpty = Object.values(monthAnnouncements).every(arr => Array.isArray(arr) && arr.length === 0);
      displayIcon = isEmpty ? "null" : "remind";
      size = displayIcon === "remind" ? "sm" : "normal";

      return (
        <button onClick={() => dispatch(handleAnnouncementListModal("month"))}>
          <div className={`bulletin-${displayIcon}-icon icon-${size}`} />
        </button>
      );
    } else {
      displayIcon = dayAnnouncements.length === 0 ? "null" : "remind";
      size = displayIcon === "remind" ? "sm" : "normal";

      return (
        <button
          onClick={() =>
            dispatch(handleAnnouncementListModal("day", queryDate))
          }
        >
          <div className={`bulletin-${displayIcon}-icon icon-${size}`} />
        </button>
      );
    }
  };

  return (
    <div className="action-display-button">
      {renderIcon()}
      {showAnnouncementListModal && <AnnouncementListModal />}
      {showAddAnnouncementModal && <AddAnnouncementModal />}
      {showAnnouncementDeleteModal && <AnnouncementCheckModal />}
      {showAnnouncementCancelModal && <AnnouncementCancelModal />}
    </div>
  );
}

export default BulletinIcon;
