import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Table, Select, Pagination, Menu, Dropdown } from "antd";
import {
  EditOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  MoreOutlined,
  DownOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import {
  handleWebsocketEventRegistry,
  cancelWebsocketSubscription,
} from "../../libs/handler";
import { dayList, dayListAbbrevEN } from "../../utils/constants";

import PreviewModal from "./announcementPopup/AnnouncementPreviewModal";
import CheckModal from "./announcementPopup/AnnouncementDeleteModal";
import CancelModal from "./announcementPopup/AnnouncementCancelModal";

import {
  announcementFetchAll,
  announcementOfficialFetch,
  handleHeaderFilter,
  announcementSetting,
  announcementSelected,
  showAnnouncementAddModal,
  showAnnouncementPreviewModal,
} from "../../actions/announcementAction";

import {
  updateOfficialAnnouncementDisplay,
  setAllOfficialAnnouncementDisplay,
} from "../announcement/api/OfficialAnnouncementApi";

const { Option } = Select;
const statusCount = {
  all: 0,
  regular: 1,
  long_term: 2,
  finished: 3,
};
const officialStatusCount = {
  all: 0,
  regular: 1,
  finished: 2,
};

const AnnouncementListView = () => {
  const { t, i18n } = useTranslation("bulletinSystem");
  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const { showPreviewAnnouncementModal } = announcementInfo;

  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const {
    page,
    sortBy,
    order,
    displayAnnouncements,
    role,
    selected,
    currentTab,
    currentFilter,
    searchMode,
    statusAnnouncementNum,
    showAnnouncementDeleteModal,
    showAnnouncementCancelModal,
  } = announcementListReducer;

  const dispatch = useDispatch();

  const statusTxt = {
    regular: t("list.tab.inProgress"),
    long_term: t("list.tab.persistent"),
    finished: t("list.tab.expired"),
  };
  const statusSelectList = [
    { title: t("list.displayOnceDaily"), action: "once" },
    { title: t("list.neverShowAgain"), action: "never" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(announcementSelected([]));
    dispatch(announcementFetchAll());
    dispatch(handleHeaderFilter(currentFilter, page));
    dispatch(announcementOfficialFetch());
  }, [dispatch, currentFilter, page]);

  useEffect(() => {
    // 公告: 建立/更新/過期/刪除 -> update 店家公告
    handleWebsocketEventRegistry("sync_announcements", () => {
      setTimeout(() => {
        dispatch(handleHeaderFilter(currentFilter, page));
      }, 3000);
    });

    return () => {
      cancelWebsocketSubscription(["sync_announcements"]);
    };
  }, [dispatch, currentFilter, page]);

  const handlePage = (page) => {
    dispatch(handleHeaderFilter(currentFilter, page, sortBy, order));
    dispatch(announcementSelected([]));
  };

  const handleSort = (type) => {
    let newOrder = null;

    if (!order || sortBy !== type) {
      newOrder = "desc";
    } else if (order === "desc") {
      newOrder = "asc";
    } else {
      newOrder = null;
    }

    dispatch(handleHeaderFilter(currentFilter, page, type, newOrder));
  };

  const handleStatusChange = async (type, item, id) => {
    if (type === "all") {
      try {
        await setAllOfficialAnnouncementDisplay({ display_mode: item });
        dispatch(handleHeaderFilter(currentFilter, page));
      } catch (err) {
        console.log(
          "change all official announcements display mode err ----------",
          err
        );
      }
    } else {
      handleShowFrequency(item, id);
    }
  };

  const renderStatusSelectList = (type, id) => {
    return statusSelectList.map((item) => {
      return (
        <li
          key={item.action}
          onClick={() => handleStatusChange(type, item.action, id)}
        >
          {item.title}
        </li>
      );
    });
  };

  const renderStatusTitle = () => {
    if (currentTab === "official" && role !== "official") {
      let disabled = false;
      if (currentFilter === "finished" || displayAnnouncements.length === 0) {
        disabled = true;
      }

      return (
        <Dropdown
          className={`statusDropDown ${disabled ? "select_disabled" : ""}`}
          overlay={
            <Menu>
              <Menu.Item
                key="0"
                onClick={() => handleStatusChange("all", "once")}
              >
                {t("list.allDisplayOnceDaily")}
              </Menu.Item>
              <Menu.Item
                key="1"
                onClick={() => handleStatusChange("all", "never")}
              >
                {t("list.allNeverShowAgain")}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          disabled={disabled}
          overlayClassName="statusCardDropDown_menu"
        >
          <button
            className="ant-dropdown-link button-init"
            onClick={(e) => e.preventDefault()}
          >
            {t("list.popupOnHomepage")}
            <DownOutlined />
          </button>
        </Dropdown>
      );
    } else {
      return <div>{t("list.tab.title")}</div>;
    }
  };

  const columns = [
    {
      title: t("list.title"),
      dataIndex: "title",
      key: "title",
      width: "30%",
      ellipsis: true,
      className: "titleColumns",
      render: (_, item) => <div>{item.title}</div>,
    },
    {
      key: "start_time",
      title: () => {
        const renderIcon = () => {
          if (sortBy === "start_time" && order === "desc") {
            return <CaretDownOutlined style={{ color: "#3FBA88" }} />;
          } else if (sortBy === "start_time" && order === "asc") {
            return <CaretUpOutlined style={{ color: "#3FBA88" }} />;
          } else {
            return <CaretDownOutlined />;
          }
        };

        return (
          <div className="th_dateTime" onClick={() => handleSort("start_time")}>
            {t("list.startDate")}
            {displayAnnouncements.length > 0 ? renderIcon() : null}
          </div>
        );
      },
      dataIndex: "start_time",
      render: (_, item) => {
        const days = i18n.language === "zh" ? dayList : dayListAbbrevEN;
        let day_start = new Date(item.start_time).getDay();
        let dateTime =
          moment(item.start_time).format("YYYY-MM-DD") +
          "（" +
          days[day_start] +
          "）" +
          moment(item.start_time).format("HH:mm");

        return <div>{dateTime}</div>;
      },
    },
    {
      key: "end_time",
      title: () => {
        const renderIcon = () => {
          if (sortBy === "end_time" && order === "desc") {
            return <CaretDownOutlined style={{ color: "#3FBA88" }} />;
          } else if (sortBy === "end_time" && order === "asc") {
            return <CaretUpOutlined style={{ color: "#3FBA88" }} />;
          } else {
            return <CaretDownOutlined />;
          }
        };

        return (
          <div
            className={`th_dateTime ${
              currentFilter === "long_term" ? "th_dateTime_disabled" : ""
            }`}
            onClick={() => handleSort("end_time")}
          >
            {t("list.endDate")}
            {displayAnnouncements.length > 0 && currentFilter !== "long_term"
              ? renderIcon()
              : null}
          </div>
        );
      },
      dataIndex: "end_time",
      render: (_, item) => {
        const days = i18n.language === "zh" ? dayList : dayListAbbrevEN;
        let day_end = new Date(item.end_time).getDay();
        let dateTime = "";

        if (item.end_time && (item.category === "regular" || item.finished)) {
          dateTime =
            moment(item.end_time).format("YYYY-MM-DD") +
            "（" +
            days[day_end] +
            "）" +
            moment(item.end_time).format("HH:mm");
        } else {
          dateTime = null;
        }

        return <div>{dateTime}</div>;
      },
    },
    {
      title: renderStatusTitle(),
      dataIndex: "status",
      key: "status",
      width: `${currentTab === "shop" ? "15%" : "20%"}`,
      className: "statusColumns",
      render: (_, item) => {
        if (
          currentTab === "shop" ||
          (currentTab === "official" && role === "official")
        ) {
          if (role === "shop") {
            return (
              <div>
                {item.finished
                  ? t("list.tab.expired")
                  : statusTxt[item.category]}
              </div>
            );
          } else {
            return (
              <div>
                {item.finished ? t("list.tab.expired") : t("list.tab.regular")}
              </div>
            );
          }
        } else {
          return (
            <Select
              value={
                item.official_announcement_settings
                  ? item.official_announcement_settings.display_mode
                  : "once"
              }
              style={{ width: 150 }}
              onClick={(e) => e.stopPropagation()}
              onChange={(value) => handleShowFrequency(value, item.id)}
              disabled={item.finished}
              className={item.finished ? "select_disabled" : ""}
            >
              {statusSelectList.map((status) => {
                return (
                  <Option key={status.action} value={status.action}>
                    {status.title}
                  </Option>
                );
              })}
            </Select>
          );
        }
      },
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      className: `${
        !(role === "shop" && currentTab === "official")
          ? "editColumns"
          : "editColumns_none"
      }`,
      render: (_, item) => {
        if (!(role === "shop" && currentTab === "official")) {
          return (
            <button
              className="icon_edit button-round"
              onClick={(e) => handleEditAnnouncement(e, item)}
            >
              <EditOutlined style={{ fontSize: "18px", color: "#676767" }} />
            </button>
          );
        }
      },
    },
  ];

  const previewAnnouncement = (item) => {
    dispatch(announcementSetting(item));
    dispatch(showAnnouncementPreviewModal());
  };

  const handleEditAnnouncement = (e, item) => {
    e.stopPropagation();
    dispatch(announcementSetting(item));
    dispatch(showAnnouncementAddModal());
  };

  const rowSelection = {
    selectedRowKeys: selected,
    onChange: (selectedRowKeys, selectedRows) => {
      let selects = [];

      selectedRows.forEach((item) => {
        selects.push(item.id);
      });

      dispatch(announcementSelected(selects));
    },
  };

  const handleSelect = (item) => {
    let newSelected = _.cloneDeep(selected);

    let idx = newSelected.indexOf(item.id);
    if (idx === -1) {
      newSelected.push(item.id);
    } else {
      newSelected.splice(idx, 1);
    }

    dispatch(announcementSelected(newSelected));
  };

  const handleShowFrequency = async (value, id) => {
    let setting = {
      id: id,
      display_mode: value,
    };

    try {
      await updateOfficialAnnouncementDisplay(setting);
      dispatch(handleHeaderFilter(currentFilter, page));
    } catch (err) {
      console.log("set official announcement frequency err ----------", err);
    }
  };

  const renderRowSelection = () => {
    if (currentTab === "official" && role !== "official") {
      return null;
    } else {
      return {
        type: "checkbox",
        ...rowSelection,
      };
    }
  };

  const renderAnnouncementList = () => {
    if (displayAnnouncements.length > 0) {
      return (
        <div className="announcementItemList">
          {displayAnnouncements.map((item) => {
            return (
              <AnnouncementItem
                item={item}
                key={item.id}
                role={role}
                currentTab={currentTab}
                selected={selected}
                handleEditAnnouncement={handleEditAnnouncement}
                previewAnnouncement={previewAnnouncement}
                handleSelect={handleSelect}
                renderStatusSelectList={renderStatusSelectList}
              />
            );
          })}
        </div>
      );
    } else {
      if (searchMode) {
        return (
          <div className="announcementItemList">
            <div className="listEmpty">{t("list.searchNoResult")}</div>
          </div>
        );
      }

      return (
        <div className="announcementItemList">
          <div className="listEmpty">
            <div className="title">{t("list.empty_content_1")}</div>
            <div className="content">
              <Trans
                i18nKey="bulletinSystem:list.empty_content_2"
                components={{
                  br: <br />,
                }}
              />
              <div>{t("list.empty_content_3")}</div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="announcementList">
      <Table
        className="announcementList_table"
        rowClassName="announcementList_row"
        rowSelection={renderRowSelection()}
        columns={columns}
        rowKey="id"
        dataSource={displayAnnouncements}
        locale={{
          emptyText: searchMode ? (
            <div className="listEmpty">{t("list.searchNoResult")}</div>
          ) : (
            <div className="listEmpty">
              <div className="title">{t("list.empty_content_1")}</div>
              <div style={{ marginTop: "24px" }}>
                <Trans
                  i18nKey="bulletinSystem:list.empty_content_2"
                  components={{
                    br: <br />,
                  }}
                />
                <div>{t("list.empty_content_3")}</div>
              </div>
            </div>
          ),
          triggerDesc: "從新到舊",
          triggerAsc: "從舊到新",
          cancelSort: "取消排序",
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              previewAnnouncement(record);
            }, // click row
          };
        }}
        pagination={false}
      />

      {/* < 768px */}
      {renderAnnouncementList()}

      {!searchMode && (
        <Pagination
          className="announcement_pagination"
          current={page}
          onChange={(page) => handlePage(page)}
          total={
            currentTab === "shop"
              ? statusAnnouncementNum[statusCount[currentFilter]]
              : statusAnnouncementNum[officialStatusCount[currentFilter]]
          }
        />
      )}

      {showPreviewAnnouncementModal && (
        <PreviewModal isDashboard={false} currentTab={currentTab} />
      )}

      {showAnnouncementDeleteModal && <CheckModal />}
      {showAnnouncementCancelModal && <CancelModal />}
    </div>
  );
};

const AnnouncementItem = ({
  item,
  role,
  selected,
  currentTab,
  handleEditAnnouncement,
  previewAnnouncement,
  handleSelect,
  renderStatusSelectList,
}) => {
  const { t, i18n } = useTranslation("bulletinSystem");
  const statusTxt = {
    regular: t("list.tab.inProgress"),
    long_term: t("list.tab.persistent"),
    finished: t("list.tab.expired"),
  };
  const displayModeTxt = {
    once: t("list.displayOnceDaily"),
    never: t("list.neverShowAgain"),
  };

  const renderDateTime = () => {
    const days = i18n.language === "zh" ? dayList : dayListAbbrevEN;
    let day_start = new Date(item.start_time).getDay();
    let day_end = new Date(item.end_time).getDay();
    let startTime =
      moment(item.start_time).format("YYYY-MM-DD") +
      "（" +
      days[day_start] +
      "）" +
      moment(item.start_time).format("HH:mm");
    let endTime =
      moment(item.end_time).format("YYYY-MM-DD") +
      "（" +
      days[day_end] +
      "）" +
      moment(item.end_time).format("HH:mm");

    return (
      <div>
        <span className={!item.end_time ? "top" : ""}>{startTime}</span>
        {item.end_time && " -"}
        {item.end_time && <div>{endTime}</div>}
      </div>
    );
  };

  const renderStatus = () => {
    const displayMode = item.official_announcement_settings
      ? item.official_announcement_settings.display_mode
      : "once";

    if (item.finished) {
      return t("list.tab.expired");
    }

    if (currentTab === "shop") {
      return statusTxt[item.category];
    } else {
      return displayModeTxt[displayMode];
    }
  };

  return (
    <div className="announcementItem">
      <div className="announcementItem_info">
        {currentTab === "shop" ||
        (currentTab === "official" && role === "official") ? (
          <input
            type="checkbox"
            checked={selected.indexOf(item.id) !== -1}
            onChange={() => handleSelect(item)}
          />
        ) : null}
        <div
          className="announcementItem_content"
          onClick={() => previewAnnouncement(item)}
        >
          <div className="title">{item.title}</div>
          <div className="status">{renderStatus()}</div>
          <div className="dateTime">{renderDateTime()}</div>
        </div>
      </div>
      {currentTab === "official" && role !== "official" ? (
        <div
          className="statusCardDropDown"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className={`${
              item.finished ? "editDropdown_disabled" : ""
            } editDropdown`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            disabled={item.finished}
          >
            <MoreOutlined />
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <ul>{renderStatusSelectList("", item.id)}</ul>
          </div>
        </div>
      ) : (
        <div
          className="icon_edit"
          onClick={(e) => handleEditAnnouncement(e, item)}
        >
          <EditOutlined style={{ fontSize: "18px", color: "#676767" }} />
        </div>
      )}
    </div>
  );
};

export default AnnouncementListView;
