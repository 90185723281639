import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

export default ({ conflictTables, setConflictTables }) => {
  const { t } = useTranslation("bookingSystem");
  return (
    <Modal
      destroyOnClose
      width={374}
      closable={false}
      centered
      visible={conflictTables.length !== 0}
      className="modal-base modal-sm"
      footer={
        <button
          className="button-common button-primary"
          onClick={() => {
            setConflictTables([]);
          }}
        >
          {t("settings:ok")}
        </button>
      }
      title={t("popup.tooManyCustomer_title")}
      onOk={() => setConflictTables([])}
      onCancel={() => setConflictTables([])}
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {t("popup.tooManyCustomer_content", {
        tables: conflictTables.join("、"),
      })}
    </Modal>
  );
};
