import React, { useState } from 'react';
import $ from 'jquery';
import { browserHistory } from "react-router";
import ConfirmModal from '../popup/ConfirmModal';

import '../../sass/userSettings.scss';

const confirmPopupText = {
	title: '密碼變更成功',
	content: '密碼變更成功，請以新密碼重新登入。'
};

const UserChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleSubmit = (e) => {
    let minLength = 4;
    e.preventDefault();

    if(newPassword.length < minLength) setNewPasswordError(true);
    if(confirmNewPassword.length < minLength) setConfirmNewPasswordError(true);
    if(newPassword.length < minLength || confirmNewPassword.length < minLength) return;

    $.ajax({
      url: window.domain + '/dashboard/users/update_password',
      dataType: 'json',
      data: {
        first_time_update: true,
        new_password: newPassword,
        new_password_confirm: confirmNewPassword
      },
      type: 'PUT',
      xhrFields: { withCredentials: true },
      success: function(data) {
        // 清空表單
        setNewPassword('');
        setConfirmNewPassword('');
        setShowConfirmModal(true);
      },
      error: function(xhr, status, err) {
        window.app.alert.setMessage(xhr.responseJSON.message, 'error');
      }
    })
  };

  const handleConfirm = () => {
    setShowConfirmModal(false);
    browserHistory.push({
      pathname: '/login'
    });
  };

  const handleButtonEnabled = (newPassword, confirmPassword) => {
    if(newPassword && confirmPassword) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const validateInput = (input) => {
    const regex = /^[a-zA-Z0-9]*$/;
    return regex.test(input);
  };

  const handleNewPassword = (e) => {
    let value = e.target.value;

    if(value.length === 0 || validateInput(value)) {
      setNewPassword(value);
      setNewPasswordError(false);
      handleButtonEnabled(value, confirmNewPassword);
    }
  };

  const handleConfirmPassword = (e) => {
    let value = e.target.value;

    if(value.length === 0 || validateInput(value)) {
      setConfirmNewPassword(value);
      setConfirmNewPasswordError(false);
      handleButtonEnabled(newPassword, value);
    }
  };

  return (
    <div className='userChangePassword'>
      <div className='title'>
        歡迎使用
        <img src={require('../../images/pageIdx/logo2_r.png')} alt="" />
      </div>
      <form className='form_password' onSubmit={handleSubmit}>
        <div className='subtitle'>請先完成您的員工密碼設定</div>
        <div className='input_newPassword'>
          <label htmlFor="newPassword">請輸入新密碼</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={handleNewPassword}
            className={`${newPasswordError ? 'input_error' : ''}`}
            placeholder='請輸入至少4個字元'
          />
          {newPasswordError && <div className='text_error'>密碼長度不可少於4個字元</div>}
        </div>
        <div className='input_confirmPassword'>
          <label htmlFor="confirmNewPassword">再次輸入新密碼</label>
          <input
            type="password"
            id="confirmNewPassword"
            value={confirmNewPassword}
            onChange={handleConfirmPassword}
            className={`${confirmNewPasswordError ? 'input_error' : ''}`}
            placeholder='請輸入至少4個字元'
          />
          {confirmNewPasswordError && <div className='text_error'>密碼長度不可少於4個字元</div>}
        </div>
        <button
          className='btn_submit'
          disabled={isDisabled}
          type="submit"
        >
          儲存密碼
        </button>
      </form>
      {showConfirmModal &&
        <ConfirmModal
          title={confirmPopupText.title}
          content={confirmPopupText.content}
          submit={handleConfirm}
        />
      }
    </div>
  )
}

export default UserChangePassword