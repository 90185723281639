import React, { useCallback } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import MonthTableRowCell from "./MonthTableRowCell";

const Month = ({
  year,
  month,
  monthBookingEffectiveCount,
  monthBookingAttendance,
  monthBookingUnconfirmedCount,
  changeUrlDate,
}) => {
  const { t } = useTranslation("time");
  const today = moment(new Date()).format("YYYY/MM/DD");

  // console.log('monthBookingUnconfirmedCount---', monthBookingUnconfirmedCount);
  const createRows = useCallback(() => {
    let createTable = [];
    const firstday = new Date(year, month - 1, 1), //查詢月份的第{t("mon")}天
      dayOfWeek = firstday.getDay(), //第{t("mon")}天星期幾 0:Sun 1:Mon 2:Tues
      days_per_month = new Date(year, month, 0).getDate(); //當月天數
    const days_per_lastMonth = new Date(year, month - 1, 0).getDate(); //上個月天數
    const str_nums = Math.ceil((dayOfWeek + days_per_month) / 7); //{t("sun")}曆需要幾行
    let vacantStyle = "vacant";

    for (let i = 0; i < str_nums; i++) {
      let tableTr = [];
      for (let j = 0; j < 7; j++) {
        const dayIndex = 7 * i + j; //建立索引, 從0開始
        let date = dayIndex - dayOfWeek + 1;
        let dateKey = dayIndex - dayOfWeek + 1;
        let date_month = month;
        let cellYear = year;

        if (date <= 0) {
          // 前一個月
          vacantStyle = "monthTableRow__cell-vacant";
          date = date + days_per_lastMonth;
          dateKey = dateKey + days_per_lastMonth;
          date_month = month - 1 === 0 ? 12 : month - 1;
          cellYear = month - 1 === 0 ? year - 1 : year;
        } else if (date > days_per_month) {
          // 下一個月
          vacantStyle = "monthTableRow__cell-vacant";
          date = date - days_per_month;
          dateKey = dateKey - days_per_month;
          date_month = month + 1 > 12 ? 1 : month + 1;
          cellYear = month + 1 > 12 ? year + 1 : year;
        } else {
          // 當月
          date = dayIndex - dayOfWeek + 1;
          vacantStyle = "";
        }

        if (dateKey < 10 && dateKey > 0) {
          dateKey = "0" + dateKey;
        }

        if (
          date_month < 10 &&
          date_month > 0 &&
          date_month.toString().length < 2
        ) {
          date_month = "0" + date_month;
        }

        const cell = String(cellYear) + String(date_month) + String(dateKey);
        const isToday =
          `${cellYear}/${date_month}/${dateKey}` === today
            ? "monthTableRow__cell-active"
            : "";
        const bookingCount = monthBookingEffectiveCount[cell];
        const bookingAttendanceCount = monthBookingAttendance[cell];
        const unconfirmedCount = monthBookingUnconfirmedCount[cell]
          ? true
          : false;

        // console.log("cell---", cell)

        tableTr.push(
          <MonthTableRowCell
            key={`${cellYear}-${date_month}-${dateKey}`}
            queryDate={`${cellYear}-${date_month}-${dateKey}`}
            date={date}
            vacantStyle={vacantStyle}
            bookingCount={bookingCount}
            bookingAttendanceCount={bookingAttendanceCount}
            isToday={isToday}
            unconfirmedCount={unconfirmedCount}
            changeUrlDate={changeUrlDate}
          />
        );
      }

      createTable.push(
        <div key={i} className="monthTableRow">
          {tableTr}
        </div>
      );
    }

    return createTable;
  }, [
    year,
    month,
    today,
    monthBookingEffectiveCount,
    monthBookingAttendance,
    monthBookingUnconfirmedCount,
    changeUrlDate,
  ]);

  return (
    <div className="monthTable">
      <div className="monthTableRow monthTableTitleRow">
        <div className="monthTableRow__cell text-title color-title">
          {t("sun")}
        </div>
        <div className="monthTableRow__cell text-title color-title">
          {t("mon")}
        </div>
        <div className="monthTableRow__cell text-title color-title">
          {t("tue")}
        </div>
        <div className="monthTableRow__cell text-title color-title">
          {t("wed")}
        </div>
        <div className="monthTableRow__cell text-title color-title">
          {t("thu")}
        </div>
        <div className="monthTableRow__cell text-title color-title">
          {t("fri")}
        </div>
        <div className="monthTableRow__cell text-title color-title">
          {t("sat")}
        </div>
      </div>

      {createRows()}
    </div>
  );
};

export default Month;
