import React, { useState, useCallback, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";

const SelectedTableContainer = ({ removeSelectedTableToseated }) => {
  const { CommomSettingState } = useContext(CommomSettingContext);
  const { selectedBookingTableData } = CommomSettingState;
  const [table, setTable] = useState([]);

  useEffect(() => {
    setTable(selectedBookingTableData);
  }, [selectedBookingTableData]);

  useEffect(() => {
    const scrollParent =
      document.getElementsByClassName("queueSelectedTable")[0];

    if (scrollParent) {
      scrollParent.addEventListener("scroll", handleScrollHorizon);

      const s_scrollWidth = scrollParent.scrollWidth;
      const s_width = scrollParent.offsetWidth;
      const prev = document.getElementById("prevBtn");
      const next = document.getElementById("nextBtn");

      prev.style.display = "none";
      scrollParent.scrollLeft = 0;

      if (s_scrollWidth <= s_width) {
        next.style.display = "none";
      } else {
        next.style.display = "block";
      }
    }

    return () => {
      scrollParent.removeEventListener("scroll", handleScrollHorizon);
    };
  }, []);

  const handleScrollHorizon = () => {
    const scrollParent =
      document.getElementsByClassName("queueSelectedTable")[0];
    const scrollLeft = scrollParent.scrollLeft;
    const r_width = scrollParent.offsetWidth;
    const scrollWidth = scrollParent.scrollWidth;

    const prev = document.getElementById("prevBtn");
    const next = document.getElementById("nextBtn");

    if (scrollLeft === 0) {
      prev.style.display = "none";
      next.style.display = "block";
    } else if (scrollLeft === scrollWidth - r_width) {
      prev.style.display = "block";
      next.style.display = "none";
    } else {
      prev.style.display = "block";
      next.style.display = "block";
    }
  };

  const nextClick = () => {
    const recommend = document.getElementsByClassName("queueSelectedTable")[0];
    const next = document.getElementById("nextBtn");
    const prev = document.getElementById("prevBtn");
    const scrollLeft = recommend.scrollLeft;
    const r_width = recommend.offsetWidth;
    const scrollWidth = recommend.scrollWidth;

    if (scrollLeft < scrollWidth - r_width) {
      recommend.scrollLeft = scrollLeft + 50;
      prev.style.display = "block";
    } else {
      next.style.display = "none";
    }
  };

  const prevClick = () => {
    const recommend = document.getElementsByClassName("queueSelectedTable")[0];
    const scrollLeft = recommend.scrollLeft;
    const prev = document.getElementById("prevBtn");
    const next = document.getElementById("nextBtn");

    if (scrollLeft > 0) {
      recommend.scrollLeft = scrollLeft - 50;
      next.style.display = "block";
    } else {
      prev.style.display = "none";
    }
  };

  const renderTable = useCallback(() => {
    return table.map((t) => {
      return (
        <SelectedTable
          key={t.name}
          t={t}
          removeSelectedTableToseated={removeSelectedTableToseated}
        />
      );
    });
  }, [removeSelectedTableToseated, table]);

  return (
    <div className="queueSelectedTableWrap">
      <div
        id="prevBtn"
        className="selectedTableArrow"
        onClick={() => prevClick()}
      />
      <div
        id="nextBtn"
        className="selectedTableArrow"
        onClick={() => nextClick()}
      />
      <div className="queueSelectedTable">{renderTable()}</div>
    </div>
  );
};

const SelectedTable = ({ t, removeSelectedTableToseated }) => {
  return (
    <div className="queueTable">
      <button onClick={() => removeSelectedTableToseated(t)} />
      <p>{t.canvas_name}</p>
      <p>{t.name}</p>
    </div>
  );
};

const SeatedSuccess = ({ seatedSuccessData }) => {
  const { t } = useTranslation("bookingSystem");
  return (
    <div className="SeatedSuccess">
      <span>
        {seatedSuccessData} {t("newModule.success")}
      </span>
    </div>
  );
};

export { SelectedTableContainer, SeatedSuccess };
