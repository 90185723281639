import React, { useEffect, useState } from "react";
import { Link, browserHistory } from "react-router";
import { config } from "../../utils/config";
import { createSettingsItem } from "../commons/SettingsItem";
import { useTranslation } from "react-i18next";
import "../../stylesheets/allSetting.css";
import MenuCouponApi from "../menu_coupon/MenuCouponApi";

const menuCouponAPI = new MenuCouponApi();

const AllSettingSideBar = ({ children }) => {
  const { t } = useTranslation(["common", "time"]);
  const [showReport, setShowReport] = useState(false);
  const { pathname } = window.location;
  const settingsItem = createSettingsItem(t, { ns: "settings" });

  useEffect(() => {
    let isMounted = true;
    const getShowReport = async () => {
      try {
        const data = await menuCouponAPI.getShops();
        if (isMounted) {
          setShowReport(data.shop.show_report);
        }
      } catch (err) {
        console.log("get shop name err-----", err);
      }
    };

    getShowReport();

    return () => {
      isMounted = false;
    };
  }, []);

  const renderReport = () => {
    // 於特定店家後台才會顯示
    if (showReport) {
      return (
        <div className="pageBlock_single">
          <Link to={"/dashboard/setting/report"}>
            <h2 className="pageBlockIcon_5">匯出數據報表</h2>
          </Link>
        </div>
      );
    }
  };

  const renderAdvanced = () => {
    if (config.env !== "production") {
      return (
        <div className="pageBlock">
          <Link to={"/dashboard/setting/advanced"}>
            <h2 className="pageBlockIcon_5">進階設定(Dev Only)</h2>
          </Link>
        </div>
      );
    }
  };

  const toSettingItem = (path) => {
    browserHistory.push({
      pathname: path,
    });
  };

  return (
    <div className="newAllSetting">
      <aside className="newAllSettingSideBar">
        <div className="newAllSettingSideBarWrap">
          <div className="newAllSettingSideBar__pages settingMenu">
            {settingsItem.map((page, index) => {
              let pageBlockIcon = "pageBlockIcon_" + index;
              return (
                <div className="pageBlock" key={index}>
                  <h2 className={pageBlockIcon}>{page.title}</h2>
                  <ul>
                    {page.subPage.map((item, index) => {
                      let active = pathname === item.path;
                      return (
                        <li
                          key={index}
                          className={`pageBlock__item ${
                            active ? "active" : ""
                          }`}
                          onClick={() => toSettingItem(item.path)}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
            <div className="pageBlock_single">
              <Link to={"/dashboard/setting/users"}>
                <h2 className="pageBlockIcon_5">
                  {t("settings:userSettings.title")}
                </h2>
              </Link>
            </div>
            {renderReport()}
            {renderAdvanced()}
          </div>
        </div>
      </aside>
      <div className="m-applicationMenu__layout" />
      <div className="newAllSettingDisplay">
        <div className="reservation-settings setting-layout">{children}</div>
      </div>
    </div>
  );
};

export default AllSettingSideBar;
