import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { SignUpStateContext } from "../MenuCouponContext/MenuCouponSignUpContext";
import MenuCouponApi from "../MenuCouponApi";

const menuCouponAPI = new MenuCouponApi();

const SignUpStepOne = () => {
  const { t } = useTranslation("couponSystem");
  const dispatch = useDispatch();
  const { SignUpState, SignUpStateDispatch } = useContext(SignUpStateContext);
  const { shopName, shopBranch, tel, address, category } = SignUpState.shopInfo;
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const txt = e.target.value;
    const name = e.target.getAttribute("name");

    SignUpStateDispatch({ type: "setShopInfo", name, txt });
  };

  const chooseCategory = (type) => {
    SignUpStateDispatch({ type: "setShopInfo", name: "category", txt: type });
  };

  const renderCategory = () => {
    const categoryType = [
      t("startCouponManagement.merchantCategory.food"),
      t("startCouponManagement.merchantCategory.travel"),
      t("startCouponManagement.merchantCategory.beauty"),
      t("startCouponManagement.merchantCategory.fashion"),
      t("startCouponManagement.merchantCategory.entertainment"),
    ];
    return categoryType.map((type) => {
      const active = type === category ? "active" : "";
      return (
        <label
          key={type}
          className={`${active}`}
          onClick={() => chooseCategory(type)}
        >
          {type}
        </label>
      );
    });
  };

  const nextStep = async () => {
    try {
      setLoading(true);
      await menuCouponAPI.updateShopInfo({
        shop: {
          display_name: shopName,
          address,
          phone_number: tel,
          branch: shopBranch,
          category,
        },
      });
      setLoading(false);
      dispatch({ type: "COUPON_BINDIND" });
			SignUpStateDispatch({ type: 'setMenuShopUser', menuShopUser: true });
    } catch (err) {
      console.log("nextStep err----", err);
      window.app.alert.setMessage(t("startCouponManagement.pls_bind"), "error");
      setLoading(false);
    }
  };

  const renderSetShopInfo = () => {
    return (
      <div>
        <h2 className="text-center">
          {t("startCouponManagement.merchantInformation")}
        </h2>

        <div className="shopInfoRow">
          <p className="title">{t("startCouponManagement.merchantName")}</p>
          <div>
            <input
              type="text"
              placeholder={t("startCouponManagement.placeholder_merchantName")}
              value={shopName}
              name="shopName"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="shopInfoRow">
          <p className="title">{t("startCouponManagement.branchName")}</p>
          <div>
            <input
              type="text"
              placeholder={t("startCouponManagement.placeholder_branchName")}
              value={shopBranch}
              name="shopBranch"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="shopInfoRow">
          <p className="title">{t("startCouponManagement.phoneNumber")}</p>
          <div>
            <input
              type="tel"
              placeholder={t("startCouponManagement.placeholder_phoneNumber")}
              value={tel}
              name="tel"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="shopInfoRow">
          <p className="title">{t("startCouponManagement.address")}</p>
          <div>
            <input
              type="text"
              placeholder={t("startCouponManagement.placeholder_address")}
              value={address}
              name="address"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
    );
  };

  const disable =
    shopName.trim() === "" || address.trim() === "" || category === "";

  return (
    <div className="signUpStep_1">
      <h1 className="text-center">{t("startCouponManagement.title")}</h1>

      <div className="shopInfo">
        {renderSetShopInfo()}
        <div>
          <h2 className="text-center">
            {t("startCouponManagement.merchantCategory.title")}
          </h2>
          <div className="categoryLable">{renderCategory()}</div>
        </div>

        <div className="text-center">
          <button
            disabled={disable}
            className="greenThemeBtn"
            onClick={() => nextStep()}
          >
            {t("startCouponManagement.next")}
          </button>
        </div>
      </div>
      {loading && (
        <Modal
          destroyOnClose
          visible={loading}
          zIndex="1035"
          width={200}
          footer={null}
          closable={false}
          maskClosable={false}
          bodyStyle={{
            padding: 10,
            textAlign: "center",
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          <LoadingOutlined style={{ fontSize: 30 }} />
          <p style={{ margin: "5px 0 0 0" }}>Loading ...</p>
        </Modal>
      )}
    </div>
  );
};

export default SignUpStepOne;
