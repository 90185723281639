import React, { useState, useCallback, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import $ from "jquery";
import { Switch } from "antd";
import { handleError } from "../../../libs/handler";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { ServiceTag, Questionnaire, Quantity } from "./Items";
import { EMAIL_REGEX, DIGITS_ONLY_REGEX } from "../../../utils/regex";

//reducer
import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";

import BlockedWarning from "../../AddNewBooking/items/BlockedWarning";

import BookingSystemApi from "../../booking_settings/BookingSystemApi";

const bookingSettingAPI = new BookingSystemApi();

const QUANTITY = "quantity",
  TAG = "tag",
  QUESTION = "question";

export const TableBookingCheckInfo = ({
  selectedBookingTableData,
  bookingStartTime,
  bookingLeaveTime,
  tableBookingAttendance,
  customQ,
  customQAns,
  isToday,
  tableBookingSubmit,
  smsNotificationSettings,
  emailNotificationSettings,
  spg_hash_iv,
  spg_hash_key,
  spg_merchant_id,
  merchant_verified,
  isBookingLoading,
  setIsBookingLoading,
}) => {
  const { t } = useTranslation("bookingSystem");
  const { setUuid } = useContext(CommomSettingContext);

  const [disabledButton, setDisabledButton] = useState(false);
  const [fillIn, setFillIn] = useState(false);
  const [syncCustomerInfo, setSyncCustomerInfo] = useState({
    name: "",
    phone: "",
    gender: "female",
    email: "",
    shopMemo: "",
    customer_tags: [],
    customer_note: "",
    onSiteCheck: false,
  });
  const [syncCustomQ, setSyncCustomQ] = useState({});
  const [customerInfoPanelMaxHeight, setCustomerInfoPanelMaxHeight] =
    useState("");

  const [depositMode, setDepositMode] = useState(false);
  const [depositSettings, setDepositSettings] = useState({
    notification: {
      email: false,
      sms: false,
    },
  });
  const [deposit, setDeposit] = useState("");
  const [depositError, setDepositError] = useState(false);
  const [msgPoint, setMsgPoint] = useState(0);
  const [blacklists, setBlacklists] = useState([]);
  const [isBlacklist, setIsBlacklist] = useState(false);
  const [showBlockedWarning, setShowBlockedWarning] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState(false);

  useEffect(() => {
    getMsgPoint();
    getBlacklists();
  }, []);

  useEffect(() => {
    setDepositSettings({
      notification: {
        email: emailNotificationSettings.booking_created.enabled,
        sms: smsNotificationSettings.booking_deposit.offline,
      },
    });
  }, [emailNotificationSettings, smsNotificationSettings]);

  const getMsgPoint = () => {
    $.ajax({
      url: window.domain + "/dashboard/booking_settings",
      method: "GET",
      xhrFields: { withCredentials: true },
    })
      .done(
        function (data) {
          setMsgPoint(data.shop.msg_point);
        }
      )
      .fail(function (xhr) {
        handleError(xhr);
      });
  };

  const getBlacklists = () => {
    bookingSettingAPI
      .getBookingSetting()
      .then((data) => {
        const { blacklists } = data;
        setBlacklists(blacklists);
      })
      .catch((error) => {
        console.log("new module add booking get blacklists error", error);
      });
  };

  const toFillIn = () => {
    setFillIn(true);
  };

  const cancelCustomerInfo = () => {
    setFillIn(false);
  };

  const bookingSubmit = (onSite, OnSiteSubmit, $btn) => {
    const phoneNumberInput = document.getElementsByClassName(
      "customerInfo_phoneNumber"
    )[0];
    const nameInput = document.getElementsByClassName("customerInfo_name")[0];
    const emailInput = document.getElementsByClassName("customerInfo_email")[0];
    const depositInput = document.getElementsByClassName(
      "customerInfo_deposit"
    )[0];

    const today = new Date();
    const todayTime = today.getTime();
    today.setTime(todayTime + 30 * 1000 * 60); // 自建訂金預約至少要在當下時間的 30 分鐘後才可建立

    let depositNotificationError = false;
    let isEmailError = false;

    if (onSite && !OnSiteSubmit) {
      // 防止勾選現場顧客，清空姓名
      if (syncCustomerInfo.name.trim() === "") {
        nameInput.classList.add("empty");
        nameInput.placeholder = t("addBooking.placeholder_enterContent");
        window.app.alert.setMessage(
          t("addBooking.pls_enterCustomerInfo"),
          "error"
        );
        return;
      }
    }

    // 有填信箱但格式錯誤
    if (!OnSiteSubmit) {
      if (
        syncCustomerInfo.email !== "" &&
        !EMAIL_REGEX.test(syncCustomerInfo.email)
      ) {
        emailInput.classList.add("empty");
        window.app.alert.setMessage(t("addBooking.pls_validEmail"), "error");
        isEmailError = true;
        setEmailFormatError(true);
      } else {
        emailInput.classList.remove("empty");
        isEmailError = false;
        setEmailFormatError(false);
      }
    }

    if (!onSite) {
      //無勾選現場顧客需檢查姓名及手機
      if (syncCustomerInfo.name.trim() === "") {
        nameInput.classList.add("empty");
        nameInput.placeholder = t("addBooking.placeholder_enterContent");
        window.app.alert.setMessage(
          t("addBooking.pls_enterCustomerInfo"),
          "error"
        );
      } else {
        nameInput.classList.remove("empty");
        nameInput.placeholder = "";
      }

      if (
        syncCustomerInfo.phone.trim() === "" ||
        !DIGITS_ONLY_REGEX.test(syncCustomerInfo.phone.trim())
      ) {
        phoneNumberInput.classList.add("empty");
        phoneNumberInput.placeholder = t("addBooking.placeholder_enterContent");
        window.app.alert.setMessage(
          t("addBooking.pls_enterCustomerInfo"),
          "error"
        );
      } else {
        phoneNumberInput.classList.remove("empty");
        phoneNumberInput.placeholder = "";
      }

      if (depositMode) {
        if (
          deposit.trim() === "" ||
          Number(deposit) === 0 ||
          !DIGITS_ONLY_REGEX.test(deposit.trim())
        ) {
          depositInput.classList.add("empty");
          depositInput.placeholder = t("addBooking.placeholder_enterContent");
          window.app.alert.setMessage(
            t("addBooking.pls_enterCustomerInfo"),
            "error"
          );
        } else {
          depositInput.classList.remove("empty");
          depositInput.placeholder = "";
        }

        if (
          (depositSettings.notification.email &&
            syncCustomerInfo.email.trim() === "") ||
          (depositSettings.notification.email &&
            !EMAIL_REGEX.test(syncCustomerInfo.email))
        ) {
          emailInput.classList.add("empty");
          emailInput.placeholder = t("addBooking.placeholder_enterContent");
          window.app.alert.setMessage(
            t("addBooking.pls_enterCustomerInfo"),
            "error"
          );
        } else {
          emailInput.classList.remove("empty");
          emailInput.placeholder = "";
        }

        // 1. 點數大於 0, sms 和 email 都未選 2. 點數小於等於 0 && 未選 email 通知
        if (
          (!depositSettings.notification.sms &&
            !depositSettings.notification.email) ||
          (msgPoint <= 0 && !depositSettings.notification.email)
        ) {
          depositNotificationError = true;
          setDepositError(true);
        } else {
          depositNotificationError = false;
          setDepositError(false);
        }

        if (new Date(bookingStartTime) < today) {
          return window.app.alert.setMessage(
            t("addBooking.pls_depositTime"),
            "error"
          );
        }

        if (
          nameInput.classList.contains("empty") ||
          phoneNumberInput.classList.contains("empty") ||
          depositInput.classList.contains("empty") ||
          depositNotificationError
        ) {
          return;
        }

        if (
          depositSettings.notification.email &&
          emailInput.classList.contains("empty")
        ) {
          return;
        }
      } else {
        if (
          nameInput.classList.contains("empty") ||
          phoneNumberInput.classList.contains("empty")
        ) {
          return;
        }
      }
    }

    if (isEmailError) return;

    setDisabledButton(true);

    const diningTime = moment
      .duration(
        moment(new Date(bookingLeaveTime)).diff(new Date(bookingStartTime))
      )
      .asMinutes();
    const newSeat = JSON.parse(JSON.stringify(selectedBookingTableData));
    let dataSubmit = {};
    const customerInfoData = {
      customer: {
        last_name: OnSiteSubmit
          ? t("addBooking.onSiteCustomer")
          : syncCustomerInfo.name,
        phone_number: syncCustomerInfo.phone.trim(),
        gender: syncCustomerInfo.gender,
        email: syncCustomerInfo.email,
        tel_number: "",
      },
    };

    newSeat.forEach((t) => {
      t["max_seat"] = t.max_attendance;
      t["min_seat"] = t.min_attendance;
      t["table_number"] = t.name;

      delete t["max_attendance"];
      delete t["min_attendance"];
      delete t["name"];
      delete t["canvas_name"];
      delete t["canvas_space_id"];
      delete t["created_at"];
      delete t["height"];
      delete t["nextBooking"];
      delete t["positionX"];
      delete t["positionY"];
      delete t["shop_id"];
      delete t["table_type"];
      delete t["timeline"];
      delete t["updated_at"];
      delete t["width"];
    });

    //set uuid
    const eventId = uuidv4();
    setUuid(eventId);
    dataSubmit["event_id"] = eventId;

    dataSubmit["last_name"] = OnSiteSubmit
      ? t("addBooking.onSiteCustomer")
      : syncCustomerInfo.name;
    dataSubmit["email"] = syncCustomerInfo.email;
    dataSubmit["phone_number"] = syncCustomerInfo.phone.trim();
    dataSubmit["gender"] = OnSiteSubmit ? "other" : syncCustomerInfo.gender;
    dataSubmit["booking_datetime"] = bookingStartTime;
    dataSubmit["attendance"] = tableBookingAttendance;
    dataSubmit["dining_time"] = diningTime;
    dataSubmit["seating_json"] = JSON.stringify(newSeat);
    dataSubmit["shop_memo"] = syncCustomerInfo.shopMemo;
    dataSubmit["customized_questions"] = JSON.stringify(syncCustomQ);
    dataSubmit["on_site"] = onSite;
    // deposit
    dataSubmit["deposit_mode"] = depositMode;
    dataSubmit["deposit_mode_settings"] = JSON.stringify(depositSettings);
    dataSubmit["deposit"] = Number(deposit);

    setIsBookingLoading(true);
    tableBookingSubmit({ customerInfoData, dataSubmit, btn: $btn });
  };

  useEffect(() => {
    setCustomerInfoPanelMaxHeight(fillIn ? "customerInfoPanelMaxHeight" : "");
  }, [fillIn]);

  const doubleCheckSubmit = () => {
    const nameInput = document.getElementsByClassName("customerInfo_name")[0];

    if (isBlacklist) {
      //無勾選現場顧客需檢查姓名
      if (!syncCustomerInfo.onSite) {
        if (syncCustomerInfo.name.trim() === "") {
          nameInput.classList.add("empty");
          nameInput.placeholder = t("addBooking.placeholder_enterContent");
          return window.app.alert.setMessage(
            t("addBooking.pls_enterCustomerInfo"),
            "error"
          );
        } else {
          nameInput.classList.remove("empty");
          nameInput.placeholder = "";
        }
      }

      setShowBlockedWarning(true);
    } else {
      bookingSubmit(syncCustomerInfo.onSite, false);
    }
  };

  return (
    <div className="seatedPanel TableBookingCheckInfoPanel">
      {isBookingLoading && (
        <div className="modal_loading">
          <LoadingOutlined />
        </div>
      )}
      <div className="TableBookingCheckInfoPanel__header">
        <i className="fa fa-calendar-check-o" aria-hidden="true" />
        <span>{t("tableMap.createBooking")}</span>
      </div>
      <div
        className={`seatedPanel__body TableBookingCheckInfoPanel__body ${customerInfoPanelMaxHeight}`}
      >
        <div className="checkInfoPanelForm">
          <div>{t("timeline.bookingDate")}</div>
          <div>
            <p>{bookingStartTime}</p>
            <p>{t("tableMap.to")}</p>
            <p>{bookingLeaveTime}</p>
          </div>
        </div>

        <div className="checkInfoPanelForm">
          <div>{t("tableMap.partySize")}</div>
          <div>
            <span>{tableBookingAttendance}</span>
          </div>
        </div>

				{fillIn && (
					<CustomerInfo
						customQ={customQ}
						customQAns={customQAns}
						setSyncCustomerInfo={setSyncCustomerInfo}
						setSyncCustomQ={setSyncCustomQ}
						msgPoint={msgPoint}
						depositMode={depositMode}
						depositSettings={depositSettings}
						deposit={deposit}
						setDepositMode={setDepositMode}
						setDepositSettings={setDepositSettings}
						setDeposit={setDeposit}
						depositError={depositError}
						bookingStartTime={bookingStartTime}
						spg_hash_iv={spg_hash_iv}
						spg_hash_key={spg_hash_key}
						spg_merchant_id={spg_merchant_id}
						merchant_verified={merchant_verified}
						blacklists={blacklists}
						isBlacklist={isBlacklist}
						setIsBlacklist={setIsBlacklist}
						emailFormatError={emailFormatError}
					/>
				)}
			</div>

      {!fillIn && (
        <div
          className={`seatedPanel__bottom TableBookingCheckInfoPanel__bottom`}
        >
          {isToday && (
            <div>
              <button
                className="seatedBtn"
                onClick={(e) => bookingSubmit(true, true, e.target)}
              >
                {t("tableMap.seatedDirectly")}
              </button>
            </div>
          )}
          <div>
            <button className="checkInfo" onClick={() => toFillIn()}>
              {t("tableMap.fillInfo")}
            </button>
          </div>
        </div>
      )}

      {fillIn && (
        <div className="customerInfoPanel__bottom">
          <div>
            <button
              disabled={disabledButton}
              className="button-common button-secondary"
              onClick={() => cancelCustomerInfo()}
            >
              {t("settings:cancel")}
            </button>
          </div>

          <div>
            <button
              disabled={disabledButton}
              className="button-common button-primary"
              onClick={() => doubleCheckSubmit()}
            >
              {t("tableMap.createBooking")}
            </button>
          </div>
        </div>
      )}

      {showBlockedWarning && (
        <BlockedWarning
          isLoading={isBookingLoading}
          addBookingSubmit={() => bookingSubmit(syncCustomerInfo.onSite, false)}
          cancelBlockedWarning={() => setShowBlockedWarning(false)}
        />
      )}

      <div id="portal" />
    </div>
  );
};

const CustomerInfo = ({
  customQ,
  customQAns,
  setSyncCustomerInfo,
  setSyncCustomQ,
  msgPoint,
  depositMode,
  depositSettings,
  deposit,
  setDepositMode,
  setDepositSettings,
  setDeposit,
  depositError,
  bookingStartTime,
  spg_hash_iv,
  spg_hash_key,
  spg_merchant_id,
  merchant_verified,
  blacklists,
  isBlacklist,
  setIsBlacklist,
  emailFormatError,
}) => {
  const { t } = useTranslation("bookingSystem");
  const [customerInfo, setCustomerInfo] = useState({
    name: "",
    phone: "",
    gender: "female",
    email: "",
    shopMemo: "",
    customer_tags: [],
    customer_note: "",
    onSite: false,
  });
  const [bookingCustomQAns, setBookingCustomQAns] = useState(
    JSON.parse(JSON.stringify(customQAns))
  );
  let options = {}; //for quantity options

  const phone = useCallback(() => {
    $(".phoneNumber").bind("typeahead:selected", function (obj, data) {
      const phoneNumberInput = document.getElementsByClassName(
        "customerInfo_phoneNumber"
      )[0];

      if (data.email === null) {
        data.email = "";
      }

      const newCustomerInfo = {
        ...customerInfo,
        name: data.last_name,
        phone: data.phone_number,
        email: data.email,
        gender: data.gender,
        customer_tags: data.customer_tags,
        customer_note: data.note,
      };

      // 手機號碼是否在封鎖名單
      let idx = blacklists.indexOf(data.phone_number);

      if (idx !== -1) {
        setIsBlacklist(true);
        phoneNumberInput.classList.add("empty");
      } else {
        setIsBlacklist(false);
        phoneNumberInput.classList.remove("empty");
      }

      setSyncCustomerInfo(newCustomerInfo);
      setCustomerInfo(newCustomerInfo);
    });
  }, [customerInfo, setSyncCustomerInfo, blacklists, setIsBlacklist]);

  useEffect(() => {
    phone();
  }, [phone]);

  useEffect(() => {
		const phoneInit = () => {
			$(".phoneNumber").typeahead(
				{
					hint: false,
					highlight: true,
					minLength: 1,
				},
				{
					name: "brands",
					display: "phone_number",
					source: function (query, syncResults, asyncResults) {
						$.ajax({
							url: window.domain + "/dashboard/customers/query",
							dataType: "json",
							data: { query: query },
							xhrFields: { withCredentials: true },
							success: function (data) {
								asyncResults(data.customers);
							},
						});
					},
					templates: {
						empty: [
							'<div class="empty-message">',
							t("addBooking.noResultFound"),
							"</div>",
						].join("\n"),
						suggestion: function (context) {
							// 該號碼是否為黑名單
							const checkBlocked = blacklists.includes(context.phone_number);
							const blockedIcon = checkBlocked
								? '<span class="icon_blocked" />'
								: "";
	
							return (
								"<div><div><strong>" +
								context.phone_number +
								"</strong> – " +
								context.last_name +
								"</div>" +
								blockedIcon +
								"</div>"
							);
						},
					},
				}
			);
		};

    phoneInit();
  }, [blacklists, t]);

  const handleName = (e) => {
    const nameInput = document.getElementsByClassName("customerInfo_name")[0];
    const name = e.target.value;
    const newCustomerInfo = Object.assign({}, customerInfo, {
      ...customerInfo,
      customer_note: "",
      customer_tags: [],
      name,
    });
    setCustomerInfo(newCustomerInfo);
    setSyncCustomerInfo(newCustomerInfo);
    nameInput.classList.remove("empty");
  };

  const switchGender = (gender) => {
    const newCustomerInfo = Object.assign({}, customerInfo, {
      ...customerInfo,
      gender,
    });

    setCustomerInfo(newCustomerInfo);
    setSyncCustomerInfo(newCustomerInfo);
  };

  const handleEmailChange = (e) => {
    const emailInput = document.getElementsByClassName("customerInfo_email")[0];
    const email = e.target.value;

    const newCustomerInfo = Object.assign({}, customerInfo, {
      ...customerInfo,
      email,
    });

    emailInput.classList.remove("empty");
    setCustomerInfo(newCustomerInfo);
    setSyncCustomerInfo(newCustomerInfo);
  };

  const handleShopMemo = (e) => {
    const shopMemo = e.target.value;

    const newCustomerInfo = Object.assign({}, customerInfo, {
      ...customerInfo,
      shopMemo,
    });
    setCustomerInfo(newCustomerInfo);
    setSyncCustomerInfo(newCustomerInfo);
  };

  const renderGender = () => {
    const { gender } = customerInfo;
    const maleActive = gender === "male" ? "active" : "";
    const femaleActive = gender === "female" ? "active" : "";

    return (
      <div className="genderSwitcher">
        <span className={maleActive} onClick={() => switchGender("male")}>
          {t("item.male")}
        </span>
        <span className={femaleActive} onClick={() => switchGender("female")}>
          {t("item.female")}
        </span>
      </div>
    );
  };

  const handleTagChange = (id, multiple, optionId) => {
    if (multiple) {
      //複選
      if (bookingCustomQAns[id]) {
        if (bookingCustomQAns[id][optionId]) {
          delete bookingCustomQAns[id][optionId];
        } else {
          bookingCustomQAns[id][optionId] = -1;
        }
      } else {
        bookingCustomQAns[id] = {};
        bookingCustomQAns[id][optionId] = -1;
      }
    } else {
      //單選
      if (bookingCustomQAns[id] && bookingCustomQAns[id][optionId] === -1) {
        bookingCustomQAns[id] = {};
      } else {
        bookingCustomQAns[id] = {};
        bookingCustomQAns[id][optionId] = -1;
      }
    }

    const newCustomQAns = Object.assign({}, bookingCustomQAns);

    setBookingCustomQAns(newCustomQAns);
    setSyncCustomQ(newCustomQAns);
  };

  const handleQuantityChange = (id, e, multiple, optionId) => {
    const value = parseInt(e.target.value, 10);

    if (multiple) {
      //複選
      if (bookingCustomQAns[id]) {
        if (value === 0) {
          delete bookingCustomQAns[id][optionId];
        } else {
          bookingCustomQAns[id][optionId] = value;
        }
      } else {
        bookingCustomQAns[id] = {};
        bookingCustomQAns[id][optionId] = value;
      }
    } else {
      //單選
      bookingCustomQAns[id] = {};
      bookingCustomQAns[id][optionId] = value;
    }

    const newCustomQAns = Object.assign({}, bookingCustomQAns);

    setBookingCustomQAns(newCustomQAns);
    setSyncCustomQ(newCustomQAns);
  };

  const handleQuestionChange = (id, e) => {
    bookingCustomQAns[id] = e.target.value;

    const newCustomQAns = Object.assign({}, bookingCustomQAns);

    setBookingCustomQAns(newCustomQAns);
    setSyncCustomQ(newCustomQAns);
  };

  const handleTelChange = (phone) => {
    const phoneNumberInput = document.getElementsByClassName(
      "customerInfo_phoneNumber"
    )[0];
    const newCustomerInfo = Object.assign({}, customerInfo, {
      ...customerInfo,
      customer_note: "",
      customer_tags: [],
      phone,
    });

    $(".phoneNumber").typeahead("val", phone);
    $(".phoneNumber").typeahead("open");

    phoneNumberInput.classList.remove("empty");

    // 手機號碼是否在封鎖名單
    if (blacklists.length !== 0) {
      let phoneNumber = phone.toString();
      let idx = blacklists.indexOf(phoneNumber);

      if (idx !== -1) {
        setIsBlacklist(true);
        phoneNumberInput.classList.add("empty");
      } else {
        setIsBlacklist(false);
        phoneNumberInput.classList.remove("empty");
      }
    }

    setCustomerInfo(newCustomerInfo);
    setSyncCustomerInfo(newCustomerInfo);
  };

  const handleOnSite = () => {
    const newCustomerInfo = Object.assign({}, customerInfo, {
      ...customerInfo,
      onSite: !customerInfo.onSite,
      name:
        customerInfo.name.trim() !== ""
          ? customerInfo.name
          : t("addBooking.onSiteCustomer"),
    });

    setDepositMode(false);
    setCustomerInfo(newCustomerInfo);
    setSyncCustomerInfo(newCustomerInfo);
  };

  // 訂金金額
  const handleDeposit = (e) => {
    const depositInput = document.getElementsByClassName(
      "customerInfo_deposit"
    )[0];
    const value = e.target.value;

    setDeposit(value);
    depositInput.classList.remove("empty");
  };

  const handleDepositSettings = (type) => {
    if (type === "sms") {
      const newDepositSettings = Object.assign({}, depositSettings, {
        notification: {
          ...depositSettings.notification,
          sms: !depositSettings.notification.sms,
        },
      });

      setDepositSettings(newDepositSettings);
    } else {
      const newDepositSettings = Object.assign({}, depositSettings, {
        notification: {
          ...depositSettings.notification,
          email: !depositSettings.notification.email,
        },
      });

      setDepositSettings(newDepositSettings);
    }
  };

  const renderCustomerQ = () => {
    return customQ.map((q) => {
      let ans = {};
      if (q.is_available && q.question_type === QUANTITY) {
        if (!options[q.id]) {
          let numberOption = JSON.parse(q.content);
          options[q.id] = {};
          Object.keys(numberOption).map((o) => {
            let range = [];
            range[0] = 0;
            for (let i = numberOption[o].min; i <= numberOption[o].max; i++) {
              range.push(i);
            }
            options[q.id][o] = range;
            return true;
          });
        }

        if (options[q.id] && Object.keys(bookingCustomQAns).length !== 0) {
          if (bookingCustomQAns[q.id]) {
            ans = bookingCustomQAns[q.id];

            Object.keys(bookingCustomQAns[q.id]).map((a) => {
              if (!options[q.id][a]) {
                delete ans[a];
              } else {
                const index = options[q.id][a].indexOf(parseInt(ans[a], 10));

                if (index === -1) {
                  options[q.id][a].splice(1, 0, parseInt(ans[a], 10));
                }
              }
              return true;
            });
          } else {
            ans = {};
          }
        }

        return (
          <Quantity
            key={q.id}
            ele={q}
            options={options[q.id]}
            customQAns={ans}
            handleQuantityChange={handleQuantityChange}
          />
        );
      } else if (q.is_available && q.question_type === TAG) {
        if (bookingCustomQAns[q.id]) {
          ans = bookingCustomQAns[q.id];
        } else {
          ans = {};
        }
        return (
          <ServiceTag
            key={q.id}
            ele={q}
            customQAns={ans}
            handleTagChange={handleTagChange}
          />
        );
      } else if (q.is_available && q.question_type === QUESTION) {
        if (bookingCustomQAns[q.id]) {
          ans = bookingCustomQAns[q.id];
        } else {
          ans = "";
        }
        return (
          <Questionnaire
            key={q.id}
            ele={q}
            customQAns={ans}
            handleQuestionChange={handleQuestionChange}
          />
        );
      }
      return true;
    });
  };

  const renderCloseCustomQ = () => {
    const index = customQ.findIndex((q) => q.is_available === false);

    if (index !== -1) {
      return (
        <React.Fragment>
          <h4 className="customerInfoPanel_Title">
            {t("addBooking.hiddenQuestions")}
          </h4>
          {customQ.map((q) => {
            let ans = {};
            if (!q.is_available && q.question_type === QUANTITY) {
              if (!options[q.id]) {
                const numberOption = JSON.parse(q.content);
                options[q.id] = {};

                Object.keys(numberOption).forEach((o) => {
                  let range = [];
                  range[0] = 0;
                  for (
                    let i = numberOption[o].min;
                    i <= numberOption[o].max;
                    i++
                  ) {
                    range.push(i);
                  }
                  options[q.id][o] = range;
                });
              }

              if (
                options[q.id] &&
                Object.keys(bookingCustomQAns).length !== 0
              ) {
                if (bookingCustomQAns[q.id]) {
                  ans = bookingCustomQAns[q.id];

                  Object.keys(bookingCustomQAns[q.id]).forEach((a) => {
                    if (!options[q.id][a]) {
                      delete ans[a];
                    } else {
                      const index = options[q.id][a].indexOf(
                        parseInt(ans[a], 10)
                      );

                      if (index === -1) {
                        options[q.id][a].splice(1, 0, parseInt(ans[a], 10));
                      }
                    }
                  });
                } else {
                  ans = {};
                }
              }

              return (
                <Quantity
                  key={q.id}
                  ele={q}
                  options={options[q.id]}
                  customQAns={ans}
                  handleQuantityChange={handleQuantityChange}
                />
              );
            } else if (!q.is_available && q.question_type === TAG) {
              if (bookingCustomQAns[q.id]) {
                ans = bookingCustomQAns[q.id];
              } else {
                ans = {};
              }
              return (
                <ServiceTag
                  key={q.id}
                  ele={q}
                  customQAns={ans}
                  handleTagChange={handleTagChange}
                />
              );
            } else if (!q.is_available && q.question_type === QUESTION) {
              if (bookingCustomQAns[q.id]) {
                ans = bookingCustomQAns[q.id];
              } else {
                ans = "";
              }
              return (
                <Questionnaire
                  key={q.id}
                  ele={q}
                  customQAns={ans}
                  handleQuestionChange={handleQuestionChange}
                />
              );
            }
            return true;
          })}
        </React.Fragment>
      );
    }
  };

  const renderCustomerTags = () => {
    const { customer_tags, customer_note } = customerInfo;

    const noteWrap =
      customer_note !== null &&
      customer_note !== undefined &&
      customer_note.trim() !== "" ? (
        <p className="customer_tags_note">{customer_note}</p>
      ) : null;
    const blockedTag = blacklists.includes(customerInfo.phone) ? (
      <span className="c-blocked">{t("tags.blocked")}</span>
    ) : null;

    if (
      (customer_note !== null &&
        customer_note !== undefined &&
        customer_note.trim() !== "") ||
      customer_tags.length !== 0 ||
      blacklists.includes(customerInfo.phone)
    ) {
      return (
        <div className="customerInfoRow">
          <div className="customer_tags">
            {blacklists.includes(customerInfo.phone) && blockedTag}
            {customer_tags.length !== 0 &&
              customer_tags.map((tag, index) => (
                <span key={index}>{tag.description}</span>
              ))}
          </div>
          {noteWrap}
        </div>
      );
    }
  };

  const renderDeposit = () => {
    if (depositMode) {
      return (
        <div className="checkBlock">
          <input
            type="text"
            className="customerInfo_deposit"
            value={deposit}
            onChange={(e) => handleDeposit(e)}
          />
          <label className="msgLabel">
            <input
              type="checkbox"
              checked={msgPoint > 0 ? depositSettings.notification.sms : false}
              disabled={msgPoint <= 0}
              onChange={() => {
                handleDepositSettings("sms");
              }}
            />
            <span>{t("addBooking.sendSMSNotification")}</span>
          </label>
          {msgPoint > 0 ? (
            <div className="text_point">
              {t("addBooking.remainingSMSCredit", { point: msgPoint })}
            </div>
          ) : (
            <div className="text_point">{t("addBooking.pls_SMSCredit")}</div>
          )}
          <label className="msgLabel">
            <input
              type="checkbox"
              checked={depositSettings.notification.email}
              onChange={() => {
                handleDepositSettings("email");
              }}
            />
            <span>{t("addBooking.sendEmailNotification")}</span>
          </label>
          {depositError && (
            <div className="text_error">
              {t("addBooking.pls_selectNotification")}
            </div>
          )}
        </div>
      );
    }
  };

	const renderDepositBlock = () => {
		const today = new Date();
		let disabled = false;
	
		// disabled: 1. 現場顧客 2. 預約時間小於當前 3. 後台訂金設定未啟用
		if(
			customerInfo.onSite || new Date(bookingStartTime) < today || 
			spg_merchant_id === '' || spg_merchant_id === null ||
			spg_hash_key === '' || spg_hash_key === null ||
			spg_hash_iv === '' || spg_hash_iv === null ||
			!merchant_verified
		) {
			disabled = true;
		}

    return (
      <div className="customerInfoRow depositBlock">
        <div className="depositBlock_switch">
          <p className="title">{t("addBooking.advancedPaymentsRequired")}</p>
          <Switch
            checked={depositMode}
            onChange={() => {
              setDepositMode(!depositMode);
            }}
            disabled={disabled}
          />
        </div>
        <div className="text_note">
          {t("addBooking.advancedPaymentsRequired_note")}
        </div>
        {renderDeposit()}
      </div>
    );
  };

  return (
    <div className="customerInfoPanel">
      <div>
        <h4 className="customerInfoPanel_Title">
          {t("tableMap.customerInfo")}
        </h4>
        <div className="customerInfoRow">
          <p className="customerInfoRow__title">
            <label className="onSiteLabel">
              <input
                type="checkbox"
                checked={customerInfo.onSite}
                onChange={() => handleOnSite()}
              />
              <span>{t("addBooking.onSiteCustomer")}</span>
            </label>
          </p>
          <div className="customerInfoRow__content">
            <p className="onSiteTxt">{t("addBooking.onSiteCustomer_note")}</p>
          </div>
        </div>
        <div className="customerInfoRow">
          <p className="customerInfoRow__title">{t("addBooking.phone")}</p>

          <div className="customerInfoRow__content phoneNumberContent">
            <input
              type="tel"
              className="phoneNumber customerInfo_phoneNumber"
              value={customerInfo.phone}
              onChange={(e) => handleTelChange(e.target.value)}
            />
          </div>
          {isBlacklist ? (
            <div className="text_error">{t("addBooking.isBlockedPhone")}</div>
          ) : null}
        </div>
        <div className="customerInfoRow">
          <p className="customerInfoRow__title">{t("tableMap.customerName")}</p>

          <div className="customerInfoRow__content">
            <div className="customerInfoRow__content-name">
              <div className="contentInput">
                <input
                  type="text"
                  className="customerInfo_name"
                  value={customerInfo.name}
                  onChange={(e) => handleName(e)}
                />
              </div>
              <div className="contentInputRadio">{renderGender()}</div>
            </div>
          </div>
        </div>

        {renderCustomerTags()}

        <div className="customerInfoRow">
          <p className="customerInfoRow__title">Email</p>

          <div className="customerInfoRow__content">
            <input
              type="text"
              className="customerInfo_email"
              value={customerInfo.email}
              onChange={(e) => handleEmailChange(e)}
            />
          </div>
          {emailFormatError ? (
            <div className="text_error">{t("addBooking.pls_validEmail")}</div>
          ) : null}
        </div>

        <div className="customerInfoRow">
          <p className="customerInfoRow__title">{t("addBooking.remark")}</p>

          <div className="customerInfoRow__content">
            <textarea
              value={customerInfo.shopMemo}
              onChange={(e) => handleShopMemo(e)}
            />
          </div>
        </div>

        {renderDepositBlock()}
      </div>

      <div>
        <h4 className="customerInfoPanel_Title">
          {t("tableMap.questionAndNote")}
        </h4>

        <div>
          {renderCustomerQ()}
          {renderCloseCustomQ()}
        </div>
      </div>
    </div>
  );
};
