import React from 'react'
import { useTranslation } from "react-i18next";

function UserNav({ activeTab, handleTab }) {
  const { t } = useTranslation("settings");
  return (
    <div className="newAllSettingSideBar">
      <div className="newAllSettingSideBar__pages settingMenu">
        <div className="pageBlock">
          <h2 className="pageBlockIcon_6">{t("userPanel.title")}</h2>
          <ul>
            <li
              onClick={() => handleTab("profile")}
              className={`pageBlock__item ${
                activeTab === "profile" ? "active" : ""
              }`}
            >
              {t("userPanel.userProfile")}
            </li>
            <li
              onClick={() => handleTab("password")}
              className={`pageBlock__item ${
                activeTab === "password" ? "active" : ""
              }`}
            >
              {t("userPanel.changePasswords")}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default UserNav