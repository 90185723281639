import React, { Component } from "react";
import { Switch } from "antd";
import { handleError } from "../../libs/handler";
import QueueSystemAPI from "./QueueSystemAPI";
import { config } from "../../utils/config";
import $ from "jquery";
import { withTranslation } from "react-i18next";
import SettingsSaveButton from "../commons/SettingsSaveButton";

import "./queueSetting.scss";

class QueueSettingNotification extends Component {
  state = {
    notificationText: "",
    autoNotify: false,
    smsNotify: false,
    smsRemainCount: 0,
    buyMsgPoint: 0,
    isSaved: true,
    cursorPosition: 0,
    displayName: "",
    branch: "",
    // success 成功, remind 即將到號
    emailLineUpSuccessEnabled: true,
    emailLineUpSuccessMsg: "",
    emailLineUpSuccessNote: "",
    emailLineUpRemindEnabled: true,
    emailLineUpRemindMsg: "",
    emailLineUpRemindNote: "",
    smsLineUpSuccessEnabled: false,
    smsLineUpSuccessMsg: "",
    smsLineUpRemindEnabled: false,
    smsLineUpRemindMsg: "",
    // default text
    emailDefaultSuccessMsg: "",
    emailDefaultRemindMsg: "",
    smsDefaultSuccessMsg: "",
    smsDefaultRemindMsg: "",
    shortenUrl: "MNUSHOP_SHORTEN_URL",
    showUrl: "*/排隊連結/*",
    insertTextarea: "success", // 檢查是否為欲嵌入的輸入框
    apParams: undefined,
    selectedPointsOption: null,
    customPoints: null,
    showCustomPointsError: false,
  };

  routerWillLeave = (nextLocation) => {
    const { t } = this.props;
    if (!this.state.isSaved) return t("leaveNotice");
  };

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
    this.API = new QueueSystemAPI();
    this.API.getQueueSettings().then(
      (data) => {
        const shortenUrl = this.state.shortenUrl;
        const showUrl = this.state.showUrl;
        const regShortenUrl = /MNUSHOP_SHORTEN_URL/g;
        this.setState({
          notificationText: data["notification_msg"],
          autoNotify: data["auto_notify"],
          smsNotify: data["sms_enable"],
          smsRemainCount: data["msg_point"],
          displayName: data["display_name"],
          branch: data["branch"],
          // 通知文案 & 開關
          emailLineUpSuccessEnabled:
            data.email_notification_settings.line_up_success["enabled"],
          emailLineUpSuccessMsg:
            data.email_notification_settings.line_up_success["msg"],
          emailLineUpSuccessNote:
            data.email_notification_settings.line_up_success["note"],
          emailLineUpRemindEnabled:
            data.email_notification_settings.line_up_remind["enabled"],
          emailLineUpRemindMsg:
            data.email_notification_settings.line_up_remind["msg"],
          emailLineUpRemindNote:
            data.email_notification_settings.line_up_remind["note"],
          smsLineUpSuccessEnabled:
            data.sms_notification_settings.line_up_success["enabled"],
          smsLineUpSuccessMsg: data.sms_notification_settings.line_up_success[
            "msg"
          ].replace(regShortenUrl, showUrl),
          smsLineUpRemindEnabled:
            data.sms_notification_settings.line_up_remind["enabled"],
          smsLineUpRemindMsg: data.sms_notification_settings.line_up_remind[
            "msg"
          ].replace(regShortenUrl, showUrl),
          // default text
          emailDefaultSuccessMsg:
            data.default_text.email_notification_settings[
              "line_up_success_msg"
            ],
          emailDefaultRemindMsg:
            data.default_text.email_notification_settings["reminder_msg"],
          smsDefaultSuccessMsg: data.default_text.sms_notification_settings[
            "line_up_success_msg"
          ].replace(shortenUrl, showUrl),
          smsDefaultRemindMsg: data.default_text.sms_notification_settings[
            "remind_msg"
          ].replace(shortenUrl, showUrl),
        });
      },
      (error) => {}
    );
  }

  buyMsgPoint = (e, isCustom = false) => {
    let value = e.target.value;

    if (parseInt(value, 10) < 100 || isNaN(value)) {
      this.setState({ apParams: undefined });
    }

    // 自訂
    if (isCustom) this.setState({ customPoints: parseInt(value, 10) });
    if (value === "custom" || isCustom) {
      this.setState({ selectedPointsOption: "custom" });
    } else {
      this.setState({ selectedPointsOption: parseInt(value, 10) });
    }

    this.setState({ showCustomPointsError: false });

    $("#apForm").html("");

    $.ajax({
      url: window.domain + "/dashboard/booking_settings/buy_msg_point",
      method: "POST",
      data: {
        amount: value === "custom" ? this.state.customPoints : value,
        category: "booking",
      },
      xhrFields: { withCredentials: true },
    })
      .done(
        function (data) {
          $("#apForm").html("");
          //debugger
          for (let k in data) {
            if (data.hasOwnProperty(k))
              $("#apForm").append(
                '<input name="' + k + '" type="hidden" value="' + data[k] + '">'
              );
          }
          this.setState({ apParams: data });
        }.bind(this)
      )
      .fail(function (xhr) {
        handleError(xhr);
      });
  };

  submitBuyMsgPoint = () => {
    const { t } = this.props;
    // 自訂點數小於 100
    if (
      this.state.selectedPointsOption === "custom" &&
      (this.state.customPoints < 100 || !this.state.customPoints)
    )
      return this.setState({ showCustomPointsError: true });

    if (this.state.apParams !== undefined && this.state.selectedPointsOption) {
      document.getElementById("apForm").submit();

      this.setState({
        selectedPointsOption: null,
        customPoints: null,
        showCustomPointsError: true,
        apParams: undefined,
      });
    } else {
      return window.app.alert.setMessage(
        t("notificationCommon.pls_AtLeast100Credits"),
        "tip"
      );
    }
  };

  renderCustomPointsInput = () => {
    const { t } = this.props;
    const handleKeyPress = (e) => {
      // 只能輸入數字
      const pattern = /^[0-9\b]+$/;
      const inputChar = String.fromCharCode(e.charCode);

      if (!pattern.test(inputChar)) {
        e.preventDefault();
      }
    };

    return (
      <div
        className={this.state.showCustomPointsError ? "custom_input-error" : ""}
      >
        <input
          type="number"
          value={this.state.customPoints || ""}
          placeholder="100"
          onChange={(e) => this.buyMsgPoint(e, true)}
          onKeyPress={handleKeyPress}
        />
        {this.state.showCustomPointsError && (
          <div>{t("notificationCommon.pls_AtLeast100Credits")}</div>
        )}
      </div>
    );
  };

  // {t('notificationCommon.embedMerchantName')}
  handleInsertShopName = (type) => {
    const smsLineUpSuccessMsg = this.state.smsLineUpSuccessMsg;
    const smsLineUpRemindMsg = this.state.smsLineUpRemindMsg;
    const shopName = this.state.displayName;
    const branch = this.state.branch;

    const cursorPosition = this.state.cursorPosition;
    const newCursorPosition = cursorPosition + shopName.length + branch.length;
    const lastPosition_success = this.state.smsLineUpSuccessMsg.length;
    const lastPosition_remind = this.state.smsLineUpRemindMsg.length;

    if (type === "success") {
      if (this.state.insertTextarea !== "success") {
        // 若"點擊按鈕的輸入框"和"insertTextarea"不同，則嵌入在最後面的位置
        this.setState({
          smsLineUpSuccessMsg:
            smsLineUpSuccessMsg.slice(0, lastPosition_success) +
            shopName +
            branch +
            smsLineUpSuccessMsg.slice(lastPosition_success),
          cursorPosition: lastPosition_remind + shopName.length + branch.length,
        });
      } else {
        this.setState({
          smsLineUpSuccessMsg:
            smsLineUpSuccessMsg.slice(0, cursorPosition) +
            shopName +
            branch +
            smsLineUpSuccessMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition,
        });
      }
    } else if (type === "remind") {
      if (this.state.insertTextarea !== "remind") {
        this.setState({
          smsLineUpRemindMsg:
            smsLineUpRemindMsg.slice(0, lastPosition_remind) +
            shopName +
            branch +
            smsLineUpRemindMsg.slice(lastPosition_remind),
          cursorPosition: lastPosition_remind + shopName.length + branch.length,
        });
      } else {
        this.setState({
          smsLineUpRemindMsg:
            smsLineUpRemindMsg.slice(0, cursorPosition) +
            shopName +
            branch +
            smsLineUpRemindMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition,
        });
      }
    }
  };

  // 嵌入排隊進度連結
  handleInsertUrl = (type) => {
    const smsLineUpSuccessMsg = this.state.smsLineUpSuccessMsg;
    const smsLineUpRemindMsg = this.state.smsLineUpRemindMsg;
    const showUrl = this.state.showUrl;

    const cursorPosition = this.state.cursorPosition;
    const newCursorPosition = cursorPosition + showUrl.length;
    const lastPosition_success = this.state.smsLineUpSuccessMsg.length;
    const lastPosition_remind = this.state.smsLineUpRemindMsg.length;

    if (type === "success") {
      if (this.state.insertTextarea !== "success") {
        // 若"點擊按鈕的輸入框"和"insertTextarea"不同，則嵌入在最後面的位置
        this.setState({
          smsLineUpSuccessMsg:
            smsLineUpSuccessMsg.slice(0, lastPosition_success) +
            showUrl +
            smsLineUpSuccessMsg.slice(lastPosition_success),
          cursorPosition: lastPosition_success + showUrl.length,
        });
      } else {
        this.setState({
          smsLineUpSuccessMsg:
            smsLineUpSuccessMsg.slice(0, cursorPosition) +
            showUrl +
            smsLineUpSuccessMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition,
        });
      }
    } else if (type === "remind") {
      if (this.state.insertTextarea !== "remind") {
        this.setState({
          smsLineUpRemindMsg:
            smsLineUpRemindMsg.slice(0, lastPosition_remind) +
            showUrl +
            smsLineUpRemindMsg.slice(lastPosition_remind),
          cursorPosition: lastPosition_remind + showUrl.length,
        });
      } else {
        this.setState({
          smsLineUpRemindMsg:
            smsLineUpRemindMsg.slice(0, cursorPosition) +
            showUrl +
            smsLineUpRemindMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition,
        });
      }
    }
  };

  handleCursorPosition = (type) => {
    let cursorPosition = document.getElementById(
      "smsLineUp_" + type
    ).selectionStart;
    this.setState({
      cursorPosition: cursorPosition,
      insertTextarea: type,
    });
  };

  renderEmailSuccessText = () => {
    const { t } = this.props;
    if (this.state.emailLineUpSuccessEnabled) {
      return (
        <div className="emailTextBlock">
          <div className="blockTitle blockTitle_margin">
            {t("queueNotification.notificationContent")}
          </div>
          <div className="intro_text">
            {t("queueNotification.notificationContent_note")}
          </div>
          <textarea
            name="note"
            className="form-control emailTextarea"
            value={this.state.emailLineUpSuccessMsg}
            placeholder={this.state.emailDefaultSuccessMsg}
            onChange={(e) => {
              this.setState({
                emailLineUpSuccessMsg: e.target.value,
                isSaved: false,
              });
            }}
          />
          <div className="blockTitle">
            {t("queueNotification.additionalNotes")}
          </div>
          <textarea
            name="note"
            className="form-control emailTextarea"
            value={this.state.emailLineUpSuccessNote}
            placeholder={t("queueNotification.placeholder_additionalNotes")}
            onChange={(e) => {
              this.setState({
                emailLineUpSuccessNote: e.target.value,
                isSaved: false,
              });
            }}
          />
        </div>
      );
    }
  };

  renderEmailRemindText = () => {
    const { t } = this.props;
    if (this.state.emailLineUpRemindEnabled) {
      return (
        <div className="emailTextBlock">
          <div className="blockTitle blockTitle_margin">
            {t("queueNotification.notificationContent")}
          </div>
          <div className="intro_text">
            {t("queueNotification.notificationContent_note")}
          </div>
          <textarea
            name="note"
            className="form-control emailTextarea"
            value={this.state.emailLineUpRemindMsg}
            placeholder={this.state.emailDefaultRemindMsg}
            onChange={(e) => {
              this.setState({
                emailLineUpRemindMsg: e.target.value,
                isSaved: false,
              });
            }}
          />
          <div className="blockTitle">
            {t("queueNotification.additionalNotes")}
          </div>
          <textarea
            name="note"
            className="form-control emailTextarea"
            value={this.state.emailLineUpRemindNote}
            placeholder={t("queueNotification.placeholder_additionalNotes")}
            onChange={(e) => {
              this.setState({
                emailLineUpRemindNote: e.target.value,
                isSaved: false,
              });
            }}
          />
        </div>
      );
    }
  };

  renderNumberOfCharacters = (type) => {
    const { t } = this.props;
    const shortenUrlLength = this.state.shortenUrl.length;
    const showUrl = this.state.showUrl;
    const showUrlLength = this.state.showUrl.length;

    if (type === "success") {
      let insertShortenUrlTimes =
        this.state.smsLineUpSuccessMsg.split(showUrl).length - 1;
      let defaultMsgLength =
        this.state.smsDefaultSuccessMsg.length -
        showUrlLength +
        shortenUrlLength;
      let successMsgLength =
        this.state.smsLineUpSuccessMsg.length +
        insertShortenUrlTimes * (shortenUrlLength - showUrlLength);

      if (this.state.smsLineUpSuccessMsg.length === 0) {
        let letters = Math.ceil(defaultMsgLength / 75);
        return (
          <div className="countText">
            {t("notificationCommon.estimated")}
            {defaultMsgLength}
            {t("notificationCommon.characters")} / {letters}
            {t("notificationCommon.messages")}
          </div>
        );
      } else {
        let letters = Math.ceil(successMsgLength / 75);
        return (
          <div className="countText">
            {t("notificationCommon.estimated")}
            {successMsgLength}
            {t("notificationCommon.characters")} / {letters}
            {t("notificationCommon.messages")}
          </div>
        );
      }
    } else {
      let insertShortenUrlTimes =
        this.state.smsLineUpRemindMsg.split(showUrl).length - 1;
      let defaultMsgLength =
        this.state.smsDefaultRemindMsg.length -
        showUrlLength +
        shortenUrlLength;
      let remindMsgLength =
        this.state.smsLineUpRemindMsg.length +
        insertShortenUrlTimes * (shortenUrlLength - showUrlLength);

      if (this.state.smsLineUpRemindMsg.length === 0) {
        let letters = Math.ceil(defaultMsgLength / 75);
        return (
          <div className="countText">
            {t("notificationCommon.estimated")}
            {defaultMsgLength}
            {t("notificationCommon.characters")} / {letters}
            {t("notificationCommon.messages")}
          </div>
        );
      } else {
        let letters = Math.ceil(remindMsgLength / 75);
        return (
          <div className="countText">
            {t("notificationCommon.estimated")}
            {remindMsgLength}
            {t("notificationCommon.characters")} / {letters}
            {t("notificationCommon.messages")}
          </div>
        );
      }
    }
  };

  renderSmsSuccessText = () => {
    const { t } = this.props;
    if (this.state.smsLineUpSuccessEnabled) {
      return (
        <div className="smsTextBlock">
          <button onClick={() => this.handleInsertShopName("success")}>
            {t("notificationCommon.embedMerchantName")}
          </button>
          <button onClick={() => this.handleInsertUrl("success")}>
            {t("notificationCommon.embedQueueConfirmationPageURL")}
          </button>
          <div className="smsTextareaBox">
            <textarea
              id="smsLineUp_success"
              name="note"
              className="form-control smsTextarea"
              value={this.state.smsLineUpSuccessMsg}
              placeholder={this.state.smsDefaultSuccessMsg}
              onChange={(e) => {
                this.setState({
                  smsLineUpSuccessMsg: e.target.value,
                  isSaved: false,
                });
              }}
              onClick={() => this.handleCursorPosition("success")}
              onKeyUp={() => this.handleCursorPosition("success")}
            />
            {this.renderNumberOfCharacters("success")}
          </div>
        </div>
      );
    }
  };

  renderSmsRemindText = () => {
    const { t } = this.props;
    if (this.state.smsLineUpRemindEnabled) {
      return (
        <div className="smsTextBlock">
          <button onClick={() => this.handleInsertShopName("remind")}>
            {t("notificationCommon.embedMerchantName")}
          </button>
          <button onClick={() => this.handleInsertUrl("remind")}>
            {t("notificationCommon.embedQueueConfirmationPageURL")}
          </button>
          <div className="smsTextareaBox">
            <textarea
              id="smsLineUp_remind"
              name="note"
              className="form-control smsTextarea"
              value={this.state.smsLineUpRemindMsg}
              placeholder={this.state.smsDefaultRemindMsg}
              onChange={(e) => {
                this.setState({
                  smsLineUpRemindMsg: e.target.value,
                  isSaved: false,
                });
              }}
              onClick={() => this.handleCursorPosition("remind")}
              onKeyUp={() => this.handleCursorPosition("remind")}
            />
            {this.renderNumberOfCharacters("remind")}
          </div>
        </div>
      );
    }
  };

  updateMsg = () => {
    this.API.getQueueSettings().then(
      (data) => {
        const showUrl = this.state.showUrl;
        const regShortenUrl = /MNUSHOP_SHORTEN_URL/g;

        // 更新文案
        this.setState({
          emailLineUpSuccessMsg:
            data.email_notification_settings.line_up_success["msg"],
          emailLineUpRemindMsg:
            data.email_notification_settings.line_up_remind["msg"],
          smsLineUpSuccessMsg: data.sms_notification_settings.line_up_success[
            "msg"
          ].replace(regShortenUrl, showUrl),
          smsLineUpRemindMsg: data.sms_notification_settings.line_up_remind[
            "msg"
          ].replace(regShortenUrl, showUrl),
        });
      },
      (error) => {}
    );
  };

  handleSave = () => {
    const { t } = this.props;
    const shortenUrl = this.state.shortenUrl;
    const showUrl = this.state.showUrl;
    const regShowUrl = /\*\/排隊連結\/\*/g;

    const emailLineUpSuccessMsg = this.state.emailLineUpSuccessMsg.trim();
    const emailLineUpRemindMsg = this.state.emailLineUpRemindMsg.trim();
    const emailDefaultSuccessMsg = this.state.emailDefaultSuccessMsg;
    const emailDefaultRemindMsg = this.state.emailDefaultRemindMsg;

    const smsLineUpSuccessMsg = this.state.smsLineUpSuccessMsg
      .trim()
      .replace(regShowUrl, shortenUrl);
    const smsLineUpRemindMsg = this.state.smsLineUpRemindMsg
      .trim()
      .replace(regShowUrl, shortenUrl);
    const smsDefaultSuccessMsg = this.state.smsDefaultSuccessMsg.replace(
      showUrl,
      shortenUrl
    );
    const smsDefaultRemindMsg = this.state.smsDefaultRemindMsg.replace(
      showUrl,
      shortenUrl
    );

    let newEmailLineUpSuccessMsg = null;
    let newEmailLineUpRemindMsg = null;
    let newSmsLineUpSuccessMsg = null;
    let newSmsLineUpRemindMsg = null;

    // 檢查文案是否為空，空的話則填入預設值，否則更新內容
    if (emailLineUpSuccessMsg !== "") {
      newEmailLineUpSuccessMsg = true;
    } else {
      newEmailLineUpSuccessMsg = false;
    }

    if (emailLineUpRemindMsg !== "") {
      newEmailLineUpRemindMsg = true;
    } else {
      newEmailLineUpRemindMsg = false;
    }

    if (smsLineUpSuccessMsg !== "") {
      newSmsLineUpSuccessMsg = true;
    } else {
      newSmsLineUpSuccessMsg = false;
    }

    if (smsLineUpRemindMsg !== "") {
      newSmsLineUpRemindMsg = true;
    } else {
      newSmsLineUpRemindMsg = false;
    }

    const emailNotificationSettings = JSON.stringify({
      line_up_success: {
        enabled: this.state.emailLineUpSuccessEnabled,
        msg: newEmailLineUpSuccessMsg
          ? emailLineUpSuccessMsg
          : emailDefaultSuccessMsg,
        note: this.state.emailLineUpSuccessNote,
      },
      line_up_remind: {
        enabled: this.state.emailLineUpRemindEnabled,
        msg: newEmailLineUpRemindMsg
          ? emailLineUpRemindMsg
          : emailDefaultRemindMsg,
        note: this.state.emailLineUpRemindNote,
      },
    });

    const smsNotificationSettings = JSON.stringify({
      line_up_success: {
        enabled: this.state.smsLineUpSuccessEnabled,
        msg: newSmsLineUpSuccessMsg
          ? smsLineUpSuccessMsg
          : smsDefaultSuccessMsg,
      },
      line_up_remind: {
        enabled: this.state.smsLineUpRemindEnabled,
        msg: newSmsLineUpRemindMsg ? smsLineUpRemindMsg : smsDefaultRemindMsg,
      },
    });

    this.API.updateQueueSettings({
      notification_msg: this.state.notificationText,
      auto_notify: this.state.autoNotify,
      sms_enable: this.state.smsNotify,
      email_notification_settings: emailNotificationSettings,
      sms_notification_settings: smsNotificationSettings,
    }).then(
      (data) => {
        window.app.alert.setMessage(t("status.saved"), "done");
        this.setState({
          isSaved: true,
        });
        this.updateMsg();
      },
      (error) => {
        window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
      }
    );
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="setting-container queueSettingNotification">
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <h3>{t("queueNotification.title")}</h3>
              <h5 style={{ marginBottom: "0" }}>
                {t("queueNotification.description")}
              </h5>
            </div>
          </div>

          <hr />

          <div className="emailNotificationBlock">
            <h4>{t("queueNotification.emailNotificationSetting")}</h4>
            <div className="rowBlock">
              <div className="rowBlock_text">
                {t("queueNotification.queueRegistrationNotification")}
              </div>
              <Switch
                checked={this.state.emailLineUpSuccessEnabled}
                onChange={() => {
                  this.setState({
                    emailLineUpSuccessEnabled:
                      !this.state.emailLineUpSuccessEnabled,
                  });
                }}
              />
            </div>
            <div className="intro_text">
              {t("queueNotification.queueRegistrationNotification_note")}
            </div>
            {this.renderEmailSuccessText()}
            <div className="rowBlock" style={{ marginTop: "48px" }}>
              <div className="rowBlock_text">
                {t("queueNotification.queueApproachingNotification")}
              </div>
              <Switch
                checked={this.state.emailLineUpRemindEnabled}
                onChange={() => {
                  this.setState({
                    emailLineUpRemindEnabled:
                      !this.state.emailLineUpRemindEnabled,
                  });
                }}
              />
            </div>
            <div className="intro_text">
              {t("queueNotification.queueApproachingNotification_note")}
            </div>
            {this.renderEmailRemindText()}
          </div>
          <hr />
          <div className="smsNotificationBlock">
            <h4>{t("queueNotification.smsNotificationSetting")}</h4>
            <div className="rowBlock">
              <div className="rowBlock_text">
                {t("queueNotification.queueRegistrationNotification")}
              </div>
              <Switch
                checked={this.state.smsLineUpSuccessEnabled}
                onChange={(e) => {
                  if (this.state.smsRemainCount !== 0) {
                    this.setState({
                      smsLineUpSuccessEnabled:
                        !this.state.smsLineUpSuccessEnabled,
                      isSaved: false,
                    });
                  } else {
                    this.setState({
                      smsLineUpSuccessEnabled: false,
                    });
                  }
                }}
              />
            </div>
            <div className="intro_text">
              {t("queueNotification.queueRegistrationNotificationSMS_note")}
            </div>
            {this.renderSmsSuccessText()}
            <div className="rowBlock" style={{ marginTop: "48px" }}>
              <div className="rowBlock_text">
                {t("queueNotification.queueApproachingNotification")}
              </div>
              <Switch
                checked={this.state.smsLineUpRemindEnabled}
                onChange={(e) => {
                  if (this.state.smsRemainCount !== 0) {
                    this.setState({
                      smsLineUpRemindEnabled:
                        !this.state.smsLineUpRemindEnabled,
                      isSaved: false,
                    });
                  } else {
                    this.setState({
                      smsLineUpRemindEnabled: false,
                    });
                  }
                }}
              />
            </div>
            <div className="intro_text">
              {t("queueNotification.queueApproachingNotificationSMS_note")}
            </div>
            {this.renderSmsRemindText()}
          </div>
          <hr />
          <div className="buyMsgPointBlock">
            <h4>{t("smsCredits.smsCredits")}</h4>
            <div className="rowBlock">
              <div className="rowBlock_text">
                {t("smsCredits.remainingSMSCredits")}
              </div>
              <div className="rowBlock_text">{this.state.smsRemainCount}</div>
            </div>
            <div className="intro_text">
              {t("smsCredits.remainingSMSCredits_note")}
            </div>
            <div className="subtitle">{t("smsCredits.topUp")}</div>
            <div className="intro_text">{t("smsCredits.topUp_note")}</div>
            <div className="radioBox">
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={100}
                  checked={this.state.selectedPointsOption === 100}
                  onChange={(e) => this.buyMsgPoint(e)}
                />
                100
              </label>
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={200}
                  checked={this.state.selectedPointsOption === 200}
                  onChange={(e) => this.buyMsgPoint(e)}
                />
                200
              </label>
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={300}
                  checked={this.state.selectedPointsOption === 300}
                  onChange={(e) => this.buyMsgPoint(e)}
                />
                300
              </label>
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={400}
                  checked={this.state.selectedPointsOption === 400}
                  onChange={(e) => this.buyMsgPoint(e)}
                />
                400
              </label>
              <div className="custom_input">
                <label>
                  <input
                    type="radio"
                    name="selectPoints"
                    value={"custom"}
                    checked={this.state.selectedPointsOption === "custom"}
                    onChange={(e) => this.buyMsgPoint(e)}
                  />
                  {t("smsCredits.custom")}
                </label>
                {this.state.selectedPointsOption === "custom"
                  ? this.renderCustomPointsInput()
                  : null}
              </div>
            </div>
            <button
              type="button"
              onClick={this.submitBuyMsgPoint}
              className="btn btn-default btn-lg msg_btn"
            >
              {t("smsCredits.topUp_2")}
            </button>
          </div>
          <form
            action={config.allpay_checkout}
            id="apForm"
            method="post"
            target="_blank"
          ></form>
        </div>
        <SettingsSaveButton handleSaved={this.handleSave} showDivider />
      </>
    );
  }
}

export default withTranslation("settings")(QueueSettingNotification);