import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import CheckModal from '../../popup/CheckModal';

const Table = ({ table, removeTable, editTable, groups, tables }) => {
	const { t } = useTranslation('settings');
	const [ tableName, setTableName ] = useState(table.table_number);
	const [ editTableName, setEditTableName ] = useState(table.table_number === '' ? true : false);
	const [ showDeleteModal, setShowDeleteModal ] = useState(false);
	const tableRef = useRef();

	useEffect(
		() => {
			if (editTableName && tableRef.current) tableRef.current.focus();
		},
		[ editTableName ]
	);

	const edit = () => {
		setEditTableName(true);
	};

	const done = () => {
		const flattenTableNumbers = _.flatten(
			groups.map(group => 
				group.table_objs?.filter(obj => obj.available === true).map(obj => obj.table_number) || []
			)
		);
		const tablesNumber = tables.filter((t) => t.available === true && t.idx !== table.idx).map((table) => table.table_number);
		const concatTableNumbers = _.concat(flattenTableNumbers, tablesNumber);
		const findIndex = concatTableNumbers.indexOf(tableName.trim());

		if (findIndex !== -1) {
			return window.app.alert.setMessage(t('tableSettings.pls_tableNameMustBeUnique'), 'error');
		}

		if (tableName.trim() === '') {
			return window.app.alert.setMessage(t('tableSettings.pls_tableNameCannotBeEmpty'), 'error');
		}

		setEditTableName(false);
		editTable(tableName.trim());
	};

	//編輯
	if (editTableName) {
		return (
			<div className="tableInput">
				<div>
					<input
						ref={tableRef}
						type="text"
						autoFocus={true}
						value={tableName}
						onChange={(e) => setTableName(e.target.value.trim())}
					/>
				</div>

				<button className="addBtn" onClick={() => done()}>
					<i className="fa fa-check" aria-hidden="true" />
				</button>
				<button
					className="cancelBtn"
					onClick={() => {
						setEditTableName(false);
						setTableName(table.table_number);
					}}
				>
					<i className="fa fa-times" aria-hidden="true" />
				</button>
			</div>
		);
	}

	return (
		<div className="tableInfo">
			<div onClick={edit}>{tableName}</div>
			<button onClick={() => setShowDeleteModal(true)}>
				<i className="fa fa-trash-o" aria-hidden="true" />
			</button>

			{showDeleteModal &&
				<CheckModal
					title={t('tableSettings.deleteConfirmation')}
					content={t('tableSettings.deleteConfirmation_content')}
					submit={() => {
						removeTable();
						setShowDeleteModal(false);
					}}
					cancel={() => setShowDeleteModal(false)}
				/>
			}
		</div>
	);
};

export default Table;
