import React from "react";

const keyboard = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "clear",
  "0",
  "delete",
];

const NumPad = React.forwardRef(
  ({ phoneStyle = "", handleKeyin, numpadBlur }, ref) => {
    return (
      <div
        ref={ref}
        tabIndex="0"
        className={"numPad numPadWrap" + phoneStyle}
        onBlur={() => numpadBlur()}
      >
        {keyboard.map((cell, index) => {
          if (cell === "delete") {
            return (
              <div
                key={index}
                className="numPadCell"
                onClick={() => handleKeyin(cell)}
              >
                <img
                  className="numPad_del"
                  src={require("../../../images/delete_black_icon.svg")}
                  alt="delete"
                />
              </div>
            );
          } else if (cell === "clear") {
            return (
              <div
                key={index}
                className="numPadCell"
                onClick={() => handleKeyin(cell)}
              >
                <svg
                  className="numPad_clear"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20"
                  height="25"
                  viewBox="0 0 32 32"
                  style={{ fill: "#000000", height: "100%" }}
                >
                  <g id="surface1">
                    <path d="M 8.71875 7.28125 L 7.28125 8.71875 L 14.5625 16 L 7.28125 23.28125 L 8.71875 24.71875 L 16 17.4375 L 23.28125 24.71875 L 24.71875 23.28125 L 17.4375 16 L 24.71875 8.71875 L 23.28125 7.28125 L 16 14.5625 Z " />
                  </g>
                </svg>
              </div>
            );
          } else {
            return (
              <div
                key={index}
                className="numPadCell"
                onClick={() => handleKeyin(cell)}
              >
                {cell}
              </div>
            );
          }
        })}
      </div>
    );
  }
);

export default NumPad;
