import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { Link, browserHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import $ from "jquery";
import moment from "moment";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import DateModeSwitcher from "../commons/DateModeSwitcher";
import BookingModeAction from "../bookings/BookingModeAction";
import NewAddBookingView from "../AddNewBooking/NewAddBooking";
import NewModuleTableMap from "./NewModuleTableMap";
import NewModuleTimeline from "./NewModuleTimeline";
import List from "./NewModuleList";
import { BookingTags } from "./Booking/booking";
import DoubleBookingModal from "./TableBooking/DoubleBooking";

import NewModuleQueueGroup from "./Queue/QueueGroup";
import { QueueTags, QueueGroups } from "./Queue/Queue";

import BookingSystemApi from "../booking_settings/BookingSystemApi";
import QueuePanelAPI from "../queue_system/api/QueuePanelAPI";

import {
  handleWebsocketEventRegistry,
  cancelWebsocketSubscription,
} from "../../libs/handler";
import { systemTabs } from "./utils/data";
import { Portal } from "./utils/Portal";

import { useSetFakeTimelineStatus } from "./hooks/useSetFakeTimelineStatus";

import {
  BaseSettingContext,
  BaseSettingReducer,
  baseSetting,
} from "./NewModuleReducer/BaseSettingReducer";
import {
  CommomSettingContext,
  CommomSettingReducer,
  commomSetting,
} from "./NewModuleReducer/CommomReuducer";
import {
  ListStateContext,
  ListStateReducer,
  listState,
} from "./NewModuleReducer/ListReducer";

import { moveCanvasClass } from "./NewModuleComponent/NewModuleTools";

//queue state:end pause (需開始新時段) normal
const API = new BookingSystemApi();
const queueAPI = new QueuePanelAPI();

const NewModule = () => {
  const { t } = useTranslation("bookingSystem");
  const [BaseSettingState, BaseSettingDispatch] = useReducer(
    BaseSettingReducer,
    baseSetting
  );
  const {
    tempCustomQAns,
    showCanvas,
    autoTable,
    diningTime,
    customQ,
    timeChecking,
    // canvasWidth,
  } = BaseSettingState;

  const [CommomSettingState, CommomSettingDispatch] = useReducer(
    CommomSettingReducer,
    commomSetting
  );
  const { mode, todayDate, date, isToday, customQAns, activeCanvasTab } =
    CommomSettingState;

  const [ListReducerState, ListStateDispatch] = useReducer(
    ListStateReducer,
    listState
  );
  const {
    activeSystem,
    originBookings,
    activeBookingStatusTab,
    queueGroup,
    queueState,
    activeQueueStatusTab,
    queueList,
    currentTag,
  } = ListReducerState;

  const scaleRatio = 1;
  // const [scaleRatio, setScaleRatio] = useState(1);
  const [modalType, setModalType] = useState("addNew");
  const [showAddBookingView, setShowAddBookingView] = useState(false);
  const [newSelected, setNewSelected] = useState({});

  const dispatch = useDispatch();

  // CanvasTableBookingInfo
  const [bookingListInfoVisible, setBookingListInfoVisible] = useState(false);
  const [tableBooking, setTableBooking] = useState(false); //點桌子建立預約

  // 自建預約 response 重疊座位
  const [overlayTables, setOverlayTables] = useState([]);

  //queue
  const [showQueueGroupPanel, setShowQueueGroupPanel] = useState(false);

  //入座
  const [loading, setLoading] = useState(false);

  //timeline
  const [timelineTables, setTimelineTables] = useState([]);
  const [showTimelineSeat, setShowTimelineSeat] = useState(false);
  const [selectSeat, setSelectSeat] = useState({});
  const [isClickSeatAdd, setIsClickSeatAdd] = useState(false);

  //uuid
  const [uuid, setUuid] = useState("");

  // 服務時間開放多選
  const [serviceTimeOptionEnabled, setServiceTimeOptionEnabled] =
    useState(false);

  // 點數
  const [msgPoint, setMsgPoint] = useState(0);
  const [smsNotificationSettings, setSmsNotificationSettings] = useState({});
  const [emailNotificationSettings, setEmailNotificationSettings] = useState(
    {}
  );
  const [spgHashIv, setSpgHashIv] = useState(null);
  const [spgHashKey, setSpgHashKey] = useState(null);
  const [spgMerchantId, setSpgMerchantId] = useState(null);
  const [merchantVerified, setMerchantVerified] = useState("");

  const [isListExpanded, setIsListExpanded] = useState(false);
  const [posEnabled, setPosEnabled] = useState(false);
  const [posSettingsMatched, setPosSettingsMatched] = useState(false);

  const latestRequestIdRef = useRef(0);

  const [emailLineUpRemindEnabled, setEmailLineUpRemindEnabled] =
    useState(false);
  const [smsLineUpRemindEnabled, setSmsLineUpRemindEnabled] = useState(false);

  const setFakeTimelineStatus = useSetFakeTimelineStatus();
  const urlMode = browserHistory.getCurrentLocation().query.mode;
  const urlDate = browserHistory.getCurrentLocation().query.date;

  //如果url沒有日期就抓今天日期
  useEffect(() => {
    if (!urlDate) {
      browserHistory.push({
        pathname: "/dashboard/new_module",
        query: {
          date: moment().format("YYYY-MM-DD"),
        },
      });

      CommomSettingDispatch({
        type: "setDate",
        date: moment().format("YYYY/MM/DD"),
      });
    } else {
      CommomSettingDispatch({
        type: "setDate",
        date: moment(urlDate).format("YYYY/MM/DD"),
      });
    }
  }, [urlDate]);

  useEffect(() => {
    CommomSettingDispatch({
      type: "setMode",
      mode: urlMode || "timeline",
    });
  }, [urlMode]);

  useEffect(() => {
    const dashboardLayout =
      document.getElementsByClassName("dashboard-layout")[0];
    if (!dashboardLayout) return;
    if (showAddBookingView) {
      dashboardLayout.style.overflow = "hidden";
    } else {
      dashboardLayout.style.overflow = "";
    }
  }, [showAddBookingView]);

  // const resizeScreen = useCallback(() => {
  //   let timer;
  //   if (timer) clearTimeout(timer);
  //   timer = setTimeout(() => {
  //     let w_w = window.innerWidth;
  //     if (w_w >= 500) {
  //       const $systemListWidth = 290;
  //       const asideWidth = 60;
  //       const actual_w_w = window.innerWidth - $systemListWidth - asideWidth;

  //       const scaleRatio =
  //         JSON.parse((actual_w_w / canvasWidth).toFixed(2)) > 1
  //           ? 1
  //           : JSON.parse((actual_w_w / canvasWidth).toFixed(2));

  //       setScaleRatio(scaleRatio);
  //     }
  //   }, 200);
  // }, [canvasWidth]);

  //booking setting & queue setting
  const newModuleSetting = useCallback(async () => {
    try {
      const { booking_setting, queue_setting, customized_questions } =
        await API.newModuleSetting();
      const { email_notification_settings, sms_notification_settings } =
        queue_setting;

      BaseSettingDispatch({
        type: "setBaseSetting",
        booking_setting,
        queue_setting,
        customized_questions,
      });
      setEmailLineUpRemindEnabled(
        JSON.parse(email_notification_settings).line_up_remind["enabled"]
      );
      setSmsLineUpRemindEnabled(
        JSON.parse(sms_notification_settings).line_up_remind["enabled"]
      );
    } catch (err) {
      // console.log('newModuleSetting-----', err);
    }
  }, []);

  //booking setting 服務時間開放多選 -> 服務時間顯示
  const getBookingSetting = useCallback(async () => {
    try {
      const data = await API.getBookingSetting();
      const {
        service_time_option_enabled,
        sms_notification_settings,
        email_notification_settings,
        shop,
        spg_hash_iv,
        spg_hash_key,
        spg_merchant_id,
        pos_enabled,
        pos_settings_matched,
      } = data;

      setMsgPoint(shop.msg_point);
      setServiceTimeOptionEnabled(service_time_option_enabled);
      setSmsNotificationSettings(sms_notification_settings);
      setEmailNotificationSettings(email_notification_settings);
      setSpgHashIv(spg_hash_iv);
      setSpgHashKey(spg_hash_key);
      setSpgMerchantId(spg_merchant_id);
      setPosEnabled(pos_enabled);
      setPosSettingsMatched(pos_settings_matched);
    } catch (err) {
      // console.log('newModule getBookingSetting err---', err);
    }
  }, []);

  //booking list & canvas & timeline
  const newModuleBooking = useCallback(async () => {
    try {
      const urlDate = browserHistory.getCurrentLocation().query.date;
      const urlDateFormat = moment(new Date(urlDate)).format("YYYY/MM/DD");

      if (urlDateFormat !== date) return;

      const { bookings, table_map_list, timeline } = await API.newModuleBooking(
        date
      );

      //booking
      ListStateDispatch({ type: "setInitBookings", bookings });

      //canvas
      CommomSettingDispatch({
        type: "setInitCanvasSetting",
        tableMapList: table_map_list,
      });

      //timeline
      setTimelineTables(timeline.tables);

      setLoading(false);
      window.app.alert.setMessage("", "done");

      if (posEnabled) getBookingSetting();
    } catch (err) {
      // console.log('Booking-----', err);
    }
  }, [date, posEnabled, getBookingSetting]);

  //queue group & queue list
  const newModuleQueue = useCallback(async () => {
    try {
      const urlDate = browserHistory.getCurrentLocation().query.date;
      const urlDateFormat = moment(new Date(urlDate)).format("YYYY/MM/DD");

      if (urlDateFormat !== date) return;

      const { queue_groups, queue_records } = await API.newModuleQueue(date);

      ListStateDispatch({
        type: "setInitQueueList",
        queueRecords: queue_records,
        queueQroups: queue_groups,
      });
    } catch (err) {
      // console.log('newModuleQueue err---', err);
    }
  }, [date]);

  //排隊開啟新時段
  const updateQueueSystemState = useCallback(async (state) => {
    let update = false;
    try {
      if (state !== "") {
        const eventId = uuidv4();
        setUuid(eventId);
        update = await queueAPI.updateQueueGroupStatus(state, eventId);
      }
    } catch (error) {
      // console.log('updateQueueSystemState error---', error);
    }
    return update;
  }, []);

  // 付款通知
  const getCreditEnabled = useCallback(() => {
    $.ajax({
      url: window.domain + "/dashboard/booking_settings/spg_data",
      method: "GET",
      xhrFields: { withCredentials: true },
    })
      .done(function (data) {
        setMerchantVerified(data.merchant_verified);
      })
      .fail(function (xhr) {
        console.log("get credit enabled error ----------");
      });
  }, []);

  //websocket
  useEffect(() => {
    //修改座位、桌位圖
    handleWebsocketEventRegistry("sync_canvas", () => {
      setTimeout(() => {
        // console.log("---修改座位、桌位圖----")
        newModuleBooking();
      }, 3000);
    });

    //有新預約、修改預約
    handleWebsocketEventRegistry("refresh_booking_list", () => {
      const requestId = ++latestRequestIdRef.current;
      setTimeout(() => {
        if (requestId === latestRequestIdRef.current) newModuleBooking();
      }, 3000);
    });

    //排隊：新增/修改/改變狀態: update排隊列表
    handleWebsocketEventRegistry("sync_frontend", (data) => {
      setTimeout(() => {
        if (data.event_id !== uuid) {
          newModuleQueue();
        }
      }, 3000);
    });

    //排隊：新增/修改/改變狀態: update排隊組別列表
    handleWebsocketEventRegistry("sync_queue_groups", (data) => {
      setTimeout(() => {
        if (data.event_id !== uuid) {
          newModuleQueue();
        }
      }, 3000);
    });

    return () => {
      cancelWebsocketSubscription(["sync_canvas"]);
      // cancelWebsocketSubscription([ 'notify' ]);
      cancelWebsocketSubscription(["refresh_booking_list"]);
      cancelWebsocketSubscription(["sync_frontend"]);
      cancelWebsocketSubscription(["sync_queue_groups"]);
      cancelWebsocketSubscription(["sync_announcements"]);
    };
  }, [
    activeCanvasTab,
    activeSystem,
    activeQueueStatusTab,
    newModuleBooking,
    newModuleQueue,
    uuid,
    dispatch,
  ]);

  useEffect(() => {
    window.app.alert.setMessage(t("settings:status.loading"), "done");
    newModuleSetting();
  }, [newModuleSetting, t]);

  useEffect(() => {
    setLoading(true);
    newModuleBooking();
    newModuleQueue();
  }, [newModuleBooking, newModuleQueue]);

  useEffect(() => {
    getBookingSetting();
    getCreditEnabled();
  }, [getBookingSetting, getCreditEnabled]);

  //init booking list status dropdown
  useEffect(() => {
    $(".bookingStatus-dropdown").popover({
      html: true,
      trigger: "click",
      container: ".bookingList__card__status",
    });
  }, []);

  // useEffect(() => {
  //   resizeScreen();
  //   window.addEventListener("resize", resizeScreen);

  //   return () => {
  //     window.removeEventListener("resize", resizeScreen);
  //   };
  // }, [resizeScreen]);

  //排隊入座後更新booking&queue
  const updateAfterSeated = useCallback(() => {
    newModuleBooking();
    newModuleQueue();
  }, [newModuleBooking, newModuleQueue]);

  //新增預約後更新
  const updateBooking = useCallback(() => {
    newModuleBooking();
  }, [newModuleBooking]);

  const updateBookingAndSetting = useCallback(() => {
    newModuleBooking();
    getBookingSetting();
  }, [newModuleBooking, getBookingSetting]);

  const switchSystemTab = useCallback(
    (systemTab) => {
      ListStateDispatch({ type: "switchSystemTab", isToday, systemTab });
    },
    [isToday]
  );

  const setStatus = useCallback(
    (booking, newStatus) => {
      const customerName = booking.last_name,
        customerGender = booking.gender_desc;
      const status_time = moment().format("HH:mm");
      const date = moment(booking.booking_datetime).format("YYYY-MM-DD");

      ListStateDispatch({ type: "setStatusFake", booking, newStatus }); //列表
      CommomSettingDispatch({ type: "setStatusFake", booking, newStatus }); //canvas

      const newTimelineTables = setFakeTimelineStatus({
        state: timelineTables,
        booking,
        newStatus,
      }); //timeline
      setTimelineTables(newTimelineTables);

      if (newStatus === "confirmed") {
        window.app.alert.setMessage(
          customerName + customerGender + " " + t("status.confirmed"),
          "done"
        );
      } else if (newStatus === "no_show") {
        window.app.alert.setMessage(
          customerName + customerGender + " " + t("status.no_show"),
          "done"
        );
      } else if (newStatus === "show") {
        window.app.alert.setMessage(
          customerName + customerGender + " " + t("status.show"),
          "done"
        );
      } else if (newStatus === "seated") {
        window.app.alert.setMessage(
          customerName + customerGender + " " + t("status.seated"),
          "done"
        );
      } else if (newStatus === "finish") {
        window.app.alert.setMessage(
          customerName + customerGender + " " + t("status.finish"),
          "done"
        );
      } else {
        window.app.alert.setMessage(t("updateSuccess"), "done");
      }

      //uuid
      const eventId = uuidv4();
      setUuid(eventId);

      API.setBookingStatus(booking.id, newStatus, date, status_time, eventId)
        .then(() => {
          newModuleBooking();
        })
        .catch(() => {
          window.app.alert.setMessage(
            t("settings:status.pls_tryAgainLater"),
            "error"
          );
        });
    },
    [newModuleBooking, setFakeTimelineStatus, timelineTables, t]
  );

  const changeBookingStatusTab = (tab) => {
    ListStateDispatch({
      type: "setActiveBookingStatusTab",
      activeBookingStatusTab: tab,
    });
  };

  const changeQueueStatusTab = useCallback((tab) => {
    ListStateDispatch({
      type: "changeQueueStatusTab",
      activeQueueStatusTab: tab,
    });
  }, []);

  const filterQueueListByGroup = useCallback((groupName) => {
    ListStateDispatch({ type: "setActiveGroupTag", activeGroupTag: groupName });
  }, []);

  const updateQueueSystem = useCallback(
    async (status) => {
      try {
        await updateQueueSystemState(status);
        await newModuleQueue();
      } catch (err) {}
    },
    [newModuleQueue, updateQueueSystemState]
  );

  //開始點擊桌子建立預約模式
  const startTableBooking = useCallback(() => {
    moveCanvasClass();

    setTableBooking(true);
    setBookingListInfoVisible(false);
    CommomSettingDispatch({ type: "setBookingCardSeats", seats: [] });
  }, [CommomSettingDispatch, setBookingListInfoVisible, setTableBooking]);

  const addNewBooking = useCallback(() => {
    setModalType("addNew");
    setShowAddBookingView(true);
  }, [setModalType, setShowAddBookingView]);

  const editBooking = useCallback((booking) => {
    setNewSelected(booking);
    setModalType("editor");
    setShowAddBookingView(true);
    CommomSettingDispatch({
      type: "setSelectedBookingTableData",
      selectedBookingTableData: [],
    });
  }, []);

  const renderSystemTag = useCallback(() => {
    if (activeSystem === "booking") {
      return (
        <BookingTags
          activeBookingStatusTab={activeBookingStatusTab}
          changeBookingStatusTab={changeBookingStatusTab}
          expandedSystemList={expandedSystemList}
          bookings={originBookings}
        />
      );
    } else {
      const today = moment(new Date(todayDate)),
        queryDate = moment(new Date(date));

      const isPassedDay = queryDate.isBefore(today);
      if (
        isToday &&
        !isPassedDay &&
        queueState !== "end" &&
        queueGroup.length !== 0
      ) {
        //當日：結束時段不顯示tag
        return <QueueTags changeQueueStatusTab={changeQueueStatusTab} />;
      } else if (!isToday && isPassedDay) {
        //昨日不限狀態顯示tag
        return <QueueTags changeQueueStatusTab={changeQueueStatusTab} />;
      }
    }
  }, [
    activeSystem,
    activeBookingStatusTab,
    originBookings,
    todayDate,
    date,
    isToday,
    queueState,
    queueGroup.length,
    changeQueueStatusTab,
  ]);

  const renderQueueGroups = useCallback(() => {
    if (activeSystem === "queue") {
      const today = moment(new Date(todayDate)),
        queryDate = moment(new Date(date));
      const isPassedDay = queryDate.isBefore(today);

      if (queueGroup.length !== 0) {
        if (isToday && !isPassedDay && queueState === "end") {
          //當日且結束時段不顯示
          return;
        } else if (!isToday && !isPassedDay) {
          //明日且結束時段不顯示
          return;
        }

        let tagContentsObject = {};
        let newQueueList = queueList[activeQueueStatusTab];

        if (isToday) {
          newQueueList = newQueueList.filter(
            (record) => record.tag === currentTag
          );
        }

        newQueueList.forEach((record) => {
          if (tagContentsObject[record.group_name] === undefined) {
            tagContentsObject[record.group_name] = 1;
          } else {
            const currentCount = tagContentsObject[record.group_name];
            tagContentsObject[record.group_name] = currentCount + 1;
          }
        });

        return (
          <QueueGroups
            queueList={newQueueList}
            tagContentsObject={tagContentsObject}
            filterQueueListByGroup={filterQueueListByGroup}
            expandedSystemList={expandedSystemList}
          />
        );
      }
    }
  }, [
    activeSystem,
    todayDate,
    date,
    queueGroup.length,
    isToday,
    queueState,
    queueList,
    activeQueueStatusTab,
    filterQueueListByGroup,
    currentTag,
  ]);

  const hideNewAddBookingModal = () => {
    setShowAddBookingView(false);

    setTimeout(() => {
      setShowAddBookingView(false);
      setSelectSeat({});
      setIsClickSeatAdd(false);
      CommomSettingDispatch({
        type: "setCustomQAns",
        customQAns: JSON.parse(JSON.stringify(tempCustomQAns)),
      });
    }, 0);
  };

  const openQueueGroupPanel = useCallback(() => {
    setShowQueueGroupPanel(true);
  }, [setShowQueueGroupPanel]);

  const closeQueueGroupPanel = () => {
    setShowQueueGroupPanel(false);
  };

  const startQueueTimeline = (queue) => {
    setShowTimelineSeat(true);
    CommomSettingDispatch({ type: "setQueueSeatedData", queue });
  };

  const closeTimelineSeat = () => {
    setShowTimelineSeat(false);
    CommomSettingDispatch({ type: "setQueueSeatedData", queue: {} });
  };

  const setActiveSystemToBooking = useCallback(() => {
    ListStateDispatch({ type: "setActiveSystem", systemTab: "booking" });
  }, []);

  const handleClickSeatAddBooking = useCallback(
    (date, time, tableInfo) => {
      const bookingDateTime = date + " " + time;
      const { timeline, combinable, name, ...seat } = tableInfo;
      setSelectSeat({
        dateTime: bookingDateTime,
        ...seat,
      });

      setIsClickSeatAdd(true);
      addNewBooking();
    },
    [addNewBooking]
  );

  const renderActiveMode = useCallback(() => {
    if (mode === "tablemap") {
      if (showCanvas) {
        return (
          <NewModuleTableMap
            scaleRatio={scaleRatio}
            activeSystem={activeSystem}
            updateAfterSeated={updateAfterSeated}
            updateBooking={updateBooking}
            setLoading={setLoading}
            tableBooking={tableBooking}
            setTableBooking={setTableBooking}
            setActiveSystemToBooking={setActiveSystemToBooking}
            bookingListInfoVisible={bookingListInfoVisible}
            setBookingListInfoVisible={setBookingListInfoVisible}
            smsNotificationSettings={smsNotificationSettings}
            emailNotificationSettings={emailNotificationSettings}
            spg_hash_iv={spgHashIv}
            spg_hash_key={spgHashKey}
            spg_merchant_id={spgMerchantId}
            merchant_verified={merchantVerified}
            handleClickSeatAddBooking={handleClickSeatAddBooking}
          />
        );
      } else {
        return <CanvasNotEnable />;
      }
    }
    if (mode === "timeline") {
      return (
        <NewModuleTimeline
          handleClickSeatAddBooking={handleClickSeatAddBooking}
          timelineTables={timelineTables}
          showTimelineSeat={showTimelineSeat}
          closeTimelineSeat={closeTimelineSeat}
          updateAfterSeated={updateAfterSeated}
          newModuleBooking={newModuleBooking}
        />
      );
    }
  }, [
    activeSystem,
    bookingListInfoVisible,
    mode,
    newModuleBooking,
    scaleRatio,
    setActiveSystemToBooking,
    showCanvas,
    showTimelineSeat,
    timelineTables,
    updateAfterSeated,
    updateBooking,
    merchantVerified,
    emailNotificationSettings,
    smsNotificationSettings,
    spgHashIv,
    spgHashKey,
    spgMerchantId,
    tableBooking,
    handleClickSeatAddBooking,
  ]);

  const systemTabMemo = useCallback(() => {
    return Object.keys(systemTabs).map((systemTab) => {
      const active =
        systemTab === activeSystem
          ? `systemList__tools__tabs-active ${activeSystem}-active`
          : "";

      return (
        <li
          key={systemTab}
          className={active}
          onClick={() => {
            switchSystemTab(systemTab);
            expandedSystemList(true);
          }}
        >
          {t(`newModule.${systemTab}`)}
        </li>
      );
    });
  }, [activeSystem, switchSystemTab, t]);

  const BaseSettingStateMemo = useMemo(
    () => BaseSettingState,
    [BaseSettingState]
  );

  const prevDay = () => {
    const prev = moment(new Date(date)).add(-1, "d").format("YYYY-MM-DD");
    CommomSettingDispatch({ type: "changeUrlDate", queryDate: prev, mode });
    ListStateDispatch({ type: "setActiveQueueStatusTab", date: prev });
  };

  const nextDay = () => {
    const next = moment(new Date(date)).add(1, "d").format("YYYY-MM-DD");
    CommomSettingDispatch({ type: "changeUrlDate", queryDate: next, mode });
    ListStateDispatch({ type: "setActiveQueueStatusTab", date: next });
  };

  const changeUrlDate = (queryDate) => {
    CommomSettingDispatch({ type: "changeUrlDate", queryDate, mode });
    ListStateDispatch({ type: "setActiveQueueStatusTab", date: queryDate });
  };

  const expandedSystemList = (value) => {
    if (typeof value === "boolean") {
      setIsListExpanded(value);
    } else {
      setIsListExpanded((prev) => !prev);
    }
  };

  return (
    <BaseSettingContext.Provider
      value={{ BaseSettingState: BaseSettingStateMemo }}
    >
      <CommomSettingContext.Provider
        value={{
          CommomSettingState,
          CommomSettingDispatch,
          setStatus,
          editBooking,
          setUuid,
          updateBookingAndSetting,
          posEnabled,
        }}
      >
        <ListStateContext.Provider
          value={{ ListReducerState, ListStateDispatch }}
        >
          <DateModeSwitcher
            mode={mode}
            calPrevious={prevDay}
            calNext={nextDay}
            handlePicker={changeUrlDate}
            isDudooEnable={posEnabled}
            isDudooSync={posSettingsMatched}
            changeUrlDate={changeUrlDate}
          />
          <BookingModeAction
            mode={mode}
            smsCredits={msgPoint}
            startTableBooking={startTableBooking}
            showNewAddBookingModal={addNewBooking}
            openQueueGroupPanel={openQueueGroupPanel}
            isQueueButtonEnabled={
              isToday && queueGroup.length !== 0 && queueState !== "end"
            }
            searchType="newModule"
          />
          <div className="newModule__main__display">
            {renderActiveMode()}
            {loading ? <Loading /> : null}

            <div
              className={`newModule__main__display__list systemList ${
                isListExpanded ? "list-expanded" : ""
              }`}
            >
              <div className="systemList__tools">
                <ul className="systemList__tools__tabs">
                  {systemTabMemo()}
                  <button
                    className={`icon-normal ${
                      isListExpanded
                        ? "circleArrowDown-icon"
                        : "circleArrowUp-icon"
                    }`}
                    onClick={expandedSystemList}
                  />
                </ul>
                {renderSystemTag()}
                {renderQueueGroups()}
              </div>
              <div className="systemList__list">
                <List
                  activeSystem={activeSystem}
                  showCanvas={showCanvas}
                  updateQueueSystem={updateQueueSystem}
                  updateQueueRecord={newModuleQueue}
                  startQueueTimeline={startQueueTimeline}
                  serviceTimeOptionEnabled={serviceTimeOptionEnabled}
                  smsLineUpRemindEnabled={smsLineUpRemindEnabled}
                  emailLineUpRemindEnabled={emailLineUpRemindEnabled}
                />
              </div>
            </div>
          </div>

          {/* 自建預約 */}
          {showAddBookingView && (
            <Portal>
              <NewAddBookingView
                autoTable={autoTable}
                hideNewAddBookingModal={hideNewAddBookingModal}
                updateData={() => {
                  updateBooking(activeCanvasTab);
                  setBookingListInfoVisible(false);
                }}
                modalType={modalType}
                date={date}
                diningTime={diningTime}
                newSelected={newSelected}
                customQAns={customQAns}
                customQ={customQ}
                setUuid={(eventId) => setUuid(eventId)}
                setOverlayTables={setOverlayTables}
                msgPoint={msgPoint}
                smsNotificationSettings={smsNotificationSettings}
                emailNotificationSettings={emailNotificationSettings}
                spg_hash_iv={spgHashIv}
                spg_hash_key={spgHashKey}
                spg_merchant_id={spgMerchantId}
                merchant_verified={merchantVerified}
                selectSeat={selectSeat}
                isClickSeatAdd={isClickSeatAdd}
              />
            </Portal>
          )}

          {/* 排隊組別 */}
          {showQueueGroupPanel && (
            <NewModuleQueueGroup
              mode={mode}
              timeChecking={timeChecking}
              closeQueueGroupPanel={closeQueueGroupPanel}
              queueGroup={queueGroup}
              createQueueRecord={newModuleQueue}
              updateQueueSystem={updateQueueSystem}
              setShowQueueGroupPanel={setShowQueueGroupPanel}
            />
          )}

          {!overlayTables.length ? null : (
            <DoubleBookingModal
              conflictTables={overlayTables}
              setConflictTables={setOverlayTables}
            />
          )}

          <div id="canvasSeated" />
        </ListStateContext.Provider>
      </CommomSettingContext.Provider>
    </BaseSettingContext.Provider>
  );
};

const CanvasNotEnable = () => {
  const { t } = useTranslation("bookingSystem");
  return (
    <div className="newModule__main__display__chart">
      <div className="CanvasNotEnable">
        <p>{t("tableMap.canvasMapNotEnabled")}</p>
        <p>
          {t("queueSystem:option.go")}
          <Link
            to={{
              pathname: "/dashboard/setting/tablemap_setting",
            }}
          >
            {t("queueSystem:option.setting")}
          </Link>
        </p>
      </div>
    </div>
  );
};

const Loading = () => (
  <div className="newModuleLoading">
    <Spin
      indicator={
        <LoadingOutlined type="loading" style={{ fontSize: 48 }} spin />
      }
      spinning={true}
      style={{
        color: "#C8C8C8",
      }}
    />
  </div>
);

export default NewModule;
