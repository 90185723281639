import React from "react";
import { useTranslation } from "react-i18next";

import { bookingStatusTabJson } from "../utils/data";
import useScrollableArrows from "../../../hooks/useScrollableArrows";

export const BookingTags = ({
  activeBookingStatusTab,
  changeBookingStatusTab,
  expandedSystemList,
  bookings,
}) => {
  const { t } = useTranslation("bookingSystem");
  const { scrollRef, showLeftArrow, showRightArrow } =
    useScrollableArrows(bookings);
  const statusTabWidth = 84;
  const scrollAmount = statusTabWidth * 2;

  const handleLeftArrowClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  const handleRightArrowClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className="bookingSystem__status-bar scrollable-tabs">
      {showLeftArrow && (
        <div className="scroll-left-arrow" onClick={handleLeftArrowClick}>
          <div className="arrowLeft-icon-dark" />
        </div>
      )}
      <ul ref={scrollRef} className="bookingSystem__status">
        {Object.keys(bookingStatusTabJson).map((status) => {
          const isActive =
            bookingStatusTabJson[status] ===
            bookingStatusTabJson[activeBookingStatusTab];
          const activeClass = isActive ? "bookingSystem__status-active" : "";

          const statusBookings = bookings.filter(
            (booking) => booking.status === status
          );

          return (
            <li
              key={status}
              id={status}
              className={`bookingSystem__status-item bookingSystem__status-${status} ${activeClass} btn-effect`}
              onClick={() => {
                changeBookingStatusTab(status);
                expandedSystemList(true);
              }}
            >
              {t(`status.${status}`)}
              <div>{statusBookings.length}</div>
            </li>
          );
        })}
      </ul>
      {showRightArrow && (
        <div className="scroll-right-arrow " onClick={handleRightArrowClick}>
          <div className="arrowRight-icon-dark" />
        </div>
      )}
    </div>
  );
};

export const EmptyBookingList = () => {
  const { t } = useTranslation("bookingSystem");
  return (
    <div className="bookingListEmpty">
      <img
        src={require("../../../images/bookingList-default.svg")}
        alt="bookingListEmpty"
      />
      <div className="text-title color-subtitle">{t("noBookingsContent")}</div>
    </div>
  );
};
