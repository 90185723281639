import React, { useState, useEffect } from "react";
import { tableBookingNum } from "../utils/data";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";

export const TableBookingNumPad = ({ closeTableBooking, setBookingTime }) => {
  const { t, i18n } = useTranslation("bookingSystem");
  const [attendance, setAttendance] = useState([0]);
  const [displayAttendance, setDisplayAttendance] = useState("0");
  const [attendanceActiveStyle, setAttendanceActiveStyle] = useState("");

  useEffect(() => {
    const attendanceNumber = parseInt(attendance.join(""), 10);
    if (attendanceNumber > 0) {
      setAttendanceActiveStyle("attendanceActive");
    } else {
      setAttendanceActiveStyle("");
    }

    setDisplayAttendance(attendance.join(""));
  }, [attendance]);

  const keyIn = (number) => {
    let newAttendance = [...attendance];
    const attendanceNumber = parseInt(newAttendance.join(), 10);

    if (attendanceNumber === 0) {
      setAttendance([number]);
    } else {
      newAttendance.push(number);
      setAttendance(newAttendance);
    }
  };

  const deleteNum = () => {
    let newAttendance = [...attendance];
    newAttendance.pop();

    if (newAttendance.length === 0) {
      newAttendance = [0];
    }

    setAttendance(newAttendance);
  };

  const checkAttendance = (attendance) => {
    if (attendance <= 0) {
      return window.app.alert.setMessage(
        t("queueSystem:option.pls_enteredPeople"),
        "error"
      );
    }

    setBookingTime(attendance);
  };

  return (
    <Modal
      title={null}
      visible
      width={300}
      destroyOnClose
      centered
      footer={null}
      transitionName="ant-move-down"
      maskClosable={false}
      onCancel={closeTableBooking}
      closeIcon={<div className="TableBookingNumPadClose" />}
      className="TableBookingNumPadWrap"
    >
      <div
        className="TableBookingPanel TableBookingNumPad"
        data-lang={i18n.language}
      >
        <div className="TableBookingNumPad__header">
          <span className={attendanceActiveStyle} data-lang={i18n.language}>
            {displayAttendance}
          </span>
        </div>

        <div className="TableBookingNumPad__body">
          {tableBookingNum.map((number) => {
            if (number === "delete") {
              return (
                <button
                  key={number}
                  className="numpad-delete"
                  onClick={() => deleteNum()}
                >
                  <img
                    className="numPad_del"
                    src={require("../../../images/newModule/delete.svg")}
                    alt="delete"
                  />
                </button>
              );
            } else if (number === "check") {
              return (
                <button
                  key={number}
                  className="numpad-check"
                  onClick={() =>
                    checkAttendance(parseInt(attendance.join(""), 10))
                  }
                >
                  {t("settings:ok")}
                </button>
              );
            } else {
              return (
                <button key={number} onClick={() => keyIn(number)}>
                  {number}
                </button>
              );
            }
          })}
        </div>
      </div>
    </Modal>
  );
};
