import React, { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';

import {
	BSettingAreaFetching,
	switchAreaSetting,
	changeAreaSettingTitle,
	addNewAreaBlock,
	clearBSAreaState,
	setAreaOption,
	checkAreaWarning,
	cancelAreaWarning,
	BSettingAreaSubmit,
	BSareaSettingCheck,
	handleGoogleReservationRequiredModal
} from '../../../actions/bookingSettingAreaAction';
import AreaModal from './BookingSettingAreaModal';
import AreaBlock from './AreaBlock';
import GoogleReservationRequiredCheckModal from '../bookingSettingsPopup/GoogleReservationRequiredCheckModal';
import SettingsSaveButton from '../../commons/SettingsSaveButton';
import CheckModal from '../../popup/CheckModal';

const BookingSettingArea = (props) => {
	const { t } = useTranslation('settings');
	const [ isSaved, setIsSaved ] = useState(true);
	const dispatch = useDispatch();
	const BSareaSetting = useSelector((state) => state.BookingSettingAreaReducer);
	const {
		areaSetting,
		areaSettingTitle,
		sending,
		isHiddenModal,
		tables,
		space_groups,
		isHiddenWarning,
		serviceTimeOptionEnabled,
		showGoogleReservationRequiredCheckModal
	} = BSareaSetting;

	const routerWillLeave = useCallback(
		() => {
			if (!isSaved) return t('leaveNotice');
		},
		[ isSaved, t ]
	);

	useEffect(
		() => {
			props.router.setRouteLeaveHook(props.route, routerWillLeave);
		},
		[ props, routerWillLeave ]
	);

	useEffect(
		() => {
			dispatch(BSettingAreaFetching());

			return () => {
				dispatch(clearBSAreaState());
			};
		},
		[ dispatch ]
	);

	const toggleAreaSetting = useCallback(
		() => {
			dispatch(switchAreaSetting());
			setIsSaved(false)
		},
		[ dispatch ]
	);

	const handleAreaSettingTitle = (e) => {
		const txt = e.target.value;
		dispatch(changeAreaSettingTitle(txt));
		setIsSaved(false)
	};

	const addNew = () => {
		dispatch(addNewAreaBlock());
	};

	const showWarning = useCallback(
		(clickType, id) => {
			dispatch(setAreaOption(clickType, id));
			setIsSaved(false)
		},
		[ dispatch ]
	);

	const renderAreaBlock = useCallback(
		() => {
			return space_groups.map((group, index) => {
				let groupName = [];

				group.table_settings.map((groupId) => {
					tables.map((table) => {
						if (groupId === table.id) {
							groupName.push(table);
						}
						return tables;
					});
					return group.table_settings;
				});

				const groupNameGroup = _.unionBy(groupName, 'group');

				return (
					<AreaBlock key={index}>
						<AreaBlock.Header
							group={group}
							groupName={groupNameGroup}
							index={index}
							showWarning={showWarning}
						/>
						<AreaBlock.Body
							groupName={groupNameGroup}
							bookingTimeSetting={group.time_settings}
							payment={group.payment_settings}
							serviceTimeOptionEnabled={serviceTimeOptionEnabled}
						/>
					</AreaBlock>
				);
			});
		},
		[ showWarning, space_groups, tables, serviceTimeOptionEnabled ]
	);

	const handleGoogleReservationCheckModal = (value) => {
		dispatch(handleGoogleReservationRequiredModal(value))
	}

	const handleSubmit = () => {
		dispatch(BSettingAreaSubmit());
		setIsSaved(true);
	};

	const submit = () => {
		dispatch(BSareaSettingCheck());
		setIsSaved(true);
	};

	const setSaved=useCallback(()=>{
		setIsSaved(false)
	}, []);

	const cancelWarning = () => {
		dispatch(cancelAreaWarning());
	};

	const checkWarning = () => {
		dispatch(checkAreaWarning());
	};

	return (
    <>
      <div id="area" className="setting-container">
        {sending && (
          <div className="lightBoxLayer">
            <h4>{t("status.saving")}</h4>
          </div>
        )}
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <h3>{t("serviceOptionSetting.title")}</h3>
            <h5>{t("serviceOptionSetting.description")}</h5>
          </div>
        </div>
        <hr />
        <div className="settingBlock">
          <div className="areaSettingItem settingBlock__item">
            <h5>{t("serviceOptionSetting.enableServiceOptions")}</h5>
            <div>
              <Switch onChange={toggleAreaSetting} checked={areaSetting} />
            </div>
          </div>
          <div className="areaSettingItem settingBlock__title">
            <h5>{t("serviceOptionSetting.serviceOptionTitle")}</h5>
            <div>
              <input
                type="text"
                disabled={!areaSetting}
                value={areaSettingTitle}
                placeholder={t(
                  "serviceOptionSetting.placeholder_serviceOptionTitle"
                )}
                onChange={(e) => handleAreaSettingTitle(e)}
                maxLength={10}
              />
            </div>
          </div>
        </div>
        <div className="areaOption">
          <div className="areaOption__title">
            <h3>
              {t("serviceOptionSetting.serviceOptions")}
              {!areaSetting && (
                <span>({t("serviceOptionSetting.disabled")})</span>
              )}
            </h3>
            <div className="areaOption__title__button">
              <button onClick={() => addNew()}>
                + {t("serviceOptionSetting.addServiceOption")}
              </button>
            </div>
          </div>
          {renderAreaBlock()}
        </div>

        {!isHiddenModal && (
          <AreaModal
            setSaved={setSaved}
            serviceTimeOptionEnabled={serviceTimeOptionEnabled}
          />
        )}
        {!isHiddenWarning && (
          <CheckModal
            title={t("serviceOptionSetting.updateConfirmation")}
            content={t("serviceOptionSetting.updateConfirmation_content")}
            submit={checkWarning}
            cancel={cancelWarning}
          />
        )}
        {showGoogleReservationRequiredCheckModal && (
          <GoogleReservationRequiredCheckModal
            areaSetting={areaSetting}
            setGoogleReservationRequiredCheckModal={() =>
              handleGoogleReservationCheckModal(false)
            }
            submit={handleSubmit}
          />
        )}
      </div>
      <SettingsSaveButton handleSaved={submit} showDivider />
    </>
  );
};


export default BookingSettingArea;
