import React, { useContext } from "react";
import Datetime from "react-datetime";
import { useTranslation } from "react-i18next";
import { CouponAddStateContext } from "../MenuCouponContext/MenuCouponAddContext";
import moment from "moment";
import "react-datetime/css/react-datetime.css";

var yesterday = moment().subtract(1, "day");
const Expire = ({ children }) => {
  return (
    <section className="couponInfo">
      {React.Children.map(children, (child) => child)}
    </section>
  );
};

const ExpireTitle = () => {
  const { t } = useTranslation("couponSystem");
  return <h2>{t("addCoupon.duration")}</h2>;
};

const ExpireContent = () => {
  const { t } = useTranslation("couponSystem");
  const { CouponAddState, CouponAddDispatch } = useContext(
    CouponAddStateContext
  );
  const { couponSetting, checkSetting } = CouponAddState;
  const { endDateTime } = couponSetting;

  const endErrorStyle = checkSetting && endDateTime === "" ? "errorStyle" : "";

  const todayEndTime = moment().hours(23).minutes(59).seconds(0);

  const defaultDateTime = () => {
    if (endDateTime === "") {
      CouponAddDispatch({ type: "setEndDateTime", endDateTime: todayEndTime });
    }
  };

  return (
    <div className={`expiredDate ${endErrorStyle}`}>
      <span className="title">{t("addCoupon.fromTheDateReleasedUntil")}</span>
      <div>
        <Datetime
          className="datetimePicker"
          value={endDateTime}
          onOpen={() => defaultDateTime()}
          onChange={(datetime) => {
            CouponAddDispatch({
              type: "setEndDateTime",
              endDateTime: datetime,
            });
          }}
          dateFormat="YYYY-MM-DD"
          timeFormat="HH:mm"
          inputProps={{
            readOnly: true,
          }}
          isValidDate={(currentDate) => {
            return currentDate.isAfter(yesterday);
          }}
        />
      </div>
    </div>
  );
};

Expire.Title = ExpireTitle;
Expire.Content = ExpireContent;

export default Expire;
