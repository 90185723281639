import React, { useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import _ from 'lodash';
import { Switch, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
	changeSettingWeek,
	checkNumpadTime,
	changeDiningTimeFixed,
	changeDiningTimeIntervalTime,
	changeDiningTimeUserSetting,
	cancelSettingEdit,
	saveSettingEdit,
	changeEnable,
	changeFixedDiningTime,
	changeDiningTimeMode,
	addTimeRange,
	addDiningTimeRange,
	diningTimeShowTime,
	updateDiningTimeName,
	addTime,
	removeTime,
	changeIntervalTime,
	delTimeRange,
	delDiningTimeRange
} from '../../../actions/bookingSettingAreaAction';
import Numpad from './Numpad';
import { dayList, dayListFull, dayListFullEN, bookingIntervalTime, hourList, minList } from '../../../utils/constants';

const SettingEditor = ({ children }) => {
	return <div className="settingEditor">{React.Children.map(children, (child) => child)}</div>;
};

const SettingEditorTitle = () => {
	const { t } = useTranslation('settings');
	const dispatch = useDispatch();

	const cancelEdit = () => {
		const addNewWeekSettingBtn = document.getElementById('addNewWeekSettingBtn');
		addNewWeekSettingBtn.style.display = 'block';

		dispatch(cancelSettingEdit());
	};

	const saveEdit = () => {
		dispatch(saveSettingEdit());
	};

	return (
		<div className="settingEditorTop settingRow" style={{marginTop: '0', marginBottom: '32px'}}>
			<div className="settingRow__content">
				<p className="settingRow__Title">{t('serviceOptionSetting.bookingTimeSetting')}</p>
			</div>
			<div className="settingRow__button">
				<Space size={8}>
					<button className="button-common button-secondary" onClick={() => cancelEdit()}>
						{t('cancel')}
					</button>
					<button className="button-common button-primary" onClick={() => saveEdit()}>
						{t('confirm')}
					</button>
				</Space>
			</div>
		</div>
	);
};

const SettingEditorWeek = ({ activeOption }) => {
	const { t, i18n } = useTranslation('settings');
	const dispatch = useDispatch();
	const BSareaModalState = useSelector((state) => state.BookingSettingAreaModalReducer);
	const {usedWeeks} = BSareaModalState;

	const handleChoose = (option) => {
		dispatch(changeSettingWeek(option));
	};

	const renderOption = () => {
		return dayListFull.map((option, index) => {
			const active = _.includes(activeOption, option);
			const disable=_.includes(usedWeeks, index)

			return (
				<div
					key={option}
					className={classNames('groups_table', { active, disable })}
					onClick={() => handleChoose(option)}
				>
					{
						i18n.language === 'zh' 
						? `星期${dayList[index]}`
						: `${dayListFullEN[index]}`
					}
				</div>
			);
		});
	};

	return (
		<section>
			<div className="sectionTitle">{t('bookingTimesCommon.openBusinessDays')}</div>
			<div className="sectionContent weeksWrap">{renderOption()}</div>
		</section>
	);
};

const SettingEditorEnable_2 = ({ enable }) => {
	const { t } = useTranslation('settings');
	const dispatch = useDispatch();
	const handleEnable = (enable) => {
		dispatch(changeEnable(enable));
	};

	return (
		<section>
			<div className="sectionTitle" style={{marginBottom: '0'}}>{t('bookingTimesCommon.bookingTimeSlots')}</div>
			<div className="sectionSubtitle">{t('bookingTimesCommon.bookingTimeSlots_note')}</div>
			<div className="enableOption row">
				<label className="col-sm-12 enable_option_marginTop">
					<input type="radio" name="enable" checked={enable === 2} onChange={() => handleEnable(2)} />
					<span className="option_title">{t('bookingTimesCommon.fixedInterval')}</span>
					<div className="option_subtitle">{t('bookingTimesCommon.fixedInterval_note')}</div>
				</label>
			</div>
		</section>
	);
};

const SettingEditorEnable_3 = ({ enable }) => {
	const { t } = useTranslation('settings');
	const dispatch = useDispatch();
	const handleEnable = (enable) => {
		dispatch(changeEnable(enable));
	};

	return (
		<div className="enableOption row" style={{height: '72px'}}>
			<label  className="col-sm-12">
				<input type="radio" name="enable" checked={enable === 3} onChange={() => handleEnable(3)} />
				<span className="option_title">{t('bookingTimesCommon.customTimeSlots')}</span>
				<div className="option_subtitle">{t('bookingTimesCommon.customTimeSlots_note')}</div>
			</label>
		</div>
	);
};

const SettingEditorTime = ({ activeOption }) => {
	const { t } = useTranslation('settings');
	const dispatch = useDispatch();
	const [ showNumpad, setShowNumpad ] = useState(false);

	const handleRemoveTime = (option) => {
		dispatch(removeTime(option))
	};
	const renderOption = () => {
		return activeOption.map((option) => {
			return (
				<div key={option} className="groups_table active">
					<button className="removeTimeButton" onClick={() => handleRemoveTime(option)} />
					{option}
				</div>
			);
		});
	};

	const handleAddTime = () => {
		setShowNumpad(true);
	};

	const closeNumpad = useCallback(() => {
		setShowNumpad(false);
	}, []);

	const checkNumpad = useCallback(
		(displayTime) => {
			dispatch(addTime(displayTime)).then(() => {
				setShowNumpad(false);
			});
		},
		[ dispatch ]
	);

	return (
		<section>
			<div>
				<button className="button-tertiary" style={{marginTop: '0'}} onClick={() => handleAddTime()}>+ {t('bookingTimesCommon.addPeriod')}</button>
			</div>
			<div className="sectionContent" id="availableTime">
				{renderOption()}
			</div>
			{showNumpad && (
				<Portal>
					<Numpad close={closeNumpad} check={checkNumpad} />
				</Portal>
			)}
		</section>
	);
};

const SettingEditorTimeRange = ({ activeTimeRange }) => {
	const { t } = useTranslation('settings');
	const dispatch = useDispatch();
	const { errorTime } = useSelector((state) => state.BookingSettingAreaModalReducer);
	const [ showNumpad, setShowNumpad ] = useState(false);
	const [ timeRangeIndex, setTimeRangeIndex ] = useState(null);
	const [ timeIndex, setTimeIndex ] = useState(null);
	const [ activeTime, setActiveTime ] = useState('');

	const handleTimeRange = (range, timeIndex, index) => {
		setActiveTime(range[timeIndex]);
		setTimeRangeIndex(index);
		setTimeIndex(timeIndex);
		setShowNumpad(true);
	};

	const handleDelTimeRange = (index)=>{
		dispatch(delTimeRange(index))
	}

	const renderTimeRange = () => {
		return activeTimeRange.map((range, index) => {
			let errorIndex = errorTime.indexOf(index);

			const errorStyle = errorIndex !== -1 ? 'errorStyle' : '';

			return (
				<div key={index} className="settingRow timeRange">
					<div className={classNames('settingRow__content horizontal-between', { errorStyle })}>
						<div>
							{t('bookingTimesCommon.from')}<span className="timeRangeCell" onClick={() => handleTimeRange(range, 0, index)}>
								{range[0]}
							</span>{t('bookingTimesCommon.to')}<span className="timeRangeCell" onClick={() => handleTimeRange(range, 1, index)}>
								{range[1]}
							</span>
						</div>
						<button className="diningTime_areaDelButton" onClick={()=> handleDelTimeRange(index)}/>
					</div>
					<div className="settingRow__button">
						<p className="settingRow__Title">{t('bookingTimesCommon.period')}{index + 1}</p>
					</div>
				</div>
			);
		});
	};

	const closeNumpad = useCallback(() => {
		setShowNumpad(false);
	}, []);

	const checkNumpad = useCallback(
		(displayTime) => {
			dispatch(checkNumpadTime({ displayTime, timeRangeIndex, timeIndex })).then(() => {
				setShowNumpad(false);
			});
		},
		[ dispatch, timeIndex, timeRangeIndex ]
	);

	const handleAddTimeRange = () => {
		dispatch(addTimeRange());
	};

	return (
		<section>
			<div>
				<button className="button-tertiary" onClick={() => handleAddTimeRange()}>+ {t('bookingTimesCommon.addPeriod')}</button>
			</div>
			<div className="sectionContent">{renderTimeRange()}</div>
			{showNumpad && (
				<Portal>
					<Numpad close={closeNumpad} check={checkNumpad} activeTime={activeTime} />
				</Portal>
			)}
		</section>
	);
};

const Portal = ({ children }) => {
	return createPortal(children, document.getElementById('areaModalNumpad'));
};

const SettingEditorInterval = ({ interval }) => {
	const { t } = useTranslation('settings');
	const dispatch = useDispatch();

	const handleIntervalTime = (e) => {
		const time = e.target.value;
		dispatch(changeIntervalTime(parseInt(time, 10)));
	};

	const renderTimeInterval = () => {
		return bookingIntervalTime.map((time) => <option key={time}>{time}</option>);
	};
	return (
		<section style={{marginBottom: '0'}}>
			<div className="row">
				<div className="sectionTitle col-sm-4 sectionTitle_2">{t('bookingTimesCommon.timeSlotsInterval')}</div>
				<div className="sectionContent col-sm-8 sectionContent_2">
					<select className="diningTime__select" value={interval} onChange={(e) => handleIntervalTime(e)}>
						{renderTimeInterval()}
					</select>
					<span>{t('time:minute')}</span>
				</div>
			</div>
		</section>
	);
};

const SettingEditorDiningTime = ({ fixedDiningTime, diningTimeOptions, serviceTimeOptionEnabled }) => {
	const { t } = useTranslation('settings');
	const dispatch = useDispatch();

	const handleFixedDiningTime = (type, e ) => {
		const time = e.target.value;
		dispatch(changeFixedDiningTime({ timeType: type, time }));
	};

	const handleDiningTimeMode = (mode) => {
		dispatch(changeDiningTimeMode(mode));
	};

	const handleDiningTimeNameUpdate = (id, e) => {
		const name = e.target.value;
		dispatch(updateDiningTimeName({ id, name}))
	};

	const handleDiningTime_userSetting = (type, id, e) => {
		const time = e.target.value;
		dispatch(changeDiningTimeUserSetting({ timeType: type, id, time }));
	};

	const handleDiningTimeShowTime = () => {
		dispatch(diningTimeShowTime())
	};

	const handleAddDiningTimeRange = () => {
		dispatch(addDiningTimeRange())
	};

	const handleDelDiningTimeRange = (index)=>{
		dispatch(delDiningTimeRange(index))
	};

	const handleDiningTime_fixed = (rangeType, type, e) => {
		const time = e.target.value;
		dispatch(changeDiningTimeFixed({ rangeType:rangeType, timeType: type, time }));
	};

	const renderModalDiningTime_userSetting = () => {
		let idx = 1;

		if (diningTimeOptions.mode === 'user_setting') {
			return (
			<div className="sectionContent diningTime">
				<div className="diningTime_name_switch_block">
					<div className="diningTime_name_switch">
						<div className="diningTime_name_title">{t('bookingTimesCommon.customizeOptionNames')}</div>
						<Switch
							checked={diningTimeOptions.user_setting.show_name}
							onChange={() => handleDiningTimeShowTime()}
						/>
					</div>
					<div className="diningTime_name_txt">{t('bookingTimesCommon.customizeOptionNames_tooltip')}</div>
				</div>
				<div>
					<button
					className="button-tertiary"
					style={{marginBottom: '24px'}}
					onClick={() => handleAddDiningTimeRange()}
					>
						+ {t('bookingTimesCommon.addOption')}
					</button>
				</div>
				{diningTimeOptions.user_setting.service_time_setting.map((range, id) => {
					return (
						<div key={id} className="diningTime_option">
							<div className="diningTime_index">{t('bookingTimesCommon.option')}{idx ++}</div>
							{
								diningTimeOptions.user_setting.show_name === true ? (
									<div style={{marginBottom: '8px'}}>
										<input
											className="diningTime_option_name"
											type="text"
											value={diningTimeOptions.user_setting.service_time_setting[id].name}
											onChange={(e) => handleDiningTimeNameUpdate(id, e)}
											maxLength="20"
											placeholder={t('bookingTimesCommon.placeholder_enterOptionName')}
											/>
										{
											diningTimeOptions.user_setting.service_time_setting.length > 1 ? (
												<button 
													className="diningTime_areaDelButton"
													onClick={()=> handleDelDiningTimeRange(id)}
												/>
											) : null
										}
									</div>
								) : null
							}
							<div className='diningTime__select_option'>
								<div>
									<select 
										className="diningTime__select_userSetting"
										value={Math.floor(parseInt(diningTimeOptions.user_setting.service_time_setting[id].service_time, 10) / 60)}
										onChange={(e) => handleDiningTime_userSetting('hour', id, e)}
									>
										{hourList.map((hour) => (
											<option key={hour} value={hour}>
												{hour}
											</option>
										))}
									</select>
									<span className="diningTime__txt">{t('time:hour')}</span>
									<select
										className="diningTime__select_userSetting"
										value={Math.floor(parseInt(diningTimeOptions.user_setting.service_time_setting[id].service_time, 10) % 60)}
										onChange={(e) => handleDiningTime_userSetting('min', id, e)}
										>
										{minList.map((m) => (
											<option key={m} value={m}>
												{m}
											</option>
										))}
									</select>
									<span className="diningTime__txt">{t('time:minute')}</span>
								</div>
								{
									(diningTimeOptions.user_setting.show_name !== true) &&
									(diningTimeOptions.user_setting.service_time_setting.length > 1)? (
										<button
										className="diningTime_areaDelButton"
										onClick={()=> handleDelDiningTimeRange(id)}
									/>
									) : null
								}
							</div>
						</div>
					)
				})}
			</div>
			)
		}
	}

	const renderModalDiningTime_fixed = () => {
		let min_dining_time_hour = Math.floor(parseInt(diningTimeOptions.fixed.min, 10) / 60)
		let min_dining_time_min = Math.floor(parseInt(diningTimeOptions.fixed.min, 10) % 60)
		let max_dining_time_hour = Math.floor(parseInt(diningTimeOptions.fixed.max, 10) / 60)
		let max_dining_time_min = Math.floor(parseInt(diningTimeOptions.fixed.max, 10) % 60)

		if (diningTimeOptions.mode === 'fixed') {
			return (
			<div className="sectionContent diningTime" style={{marginTop: '32px'}}>
				<div className="diningTime_content">
					<span className="diningTime_option diningTime_range">{t('bookingTimesCommon.minimum')}</span>
					<select
						className="diningTime__select"
						value={min_dining_time_hour}
						onChange={(e) => handleDiningTime_fixed('min','hour', e)}
					>
						{hourList.map((hour) => (
							<option key={hour} value={hour}>
								{hour}
							</option>
						))}
					</select>
					<span className="diningTime__txt">{t('time:hour')}</span>
					<select
						className="diningTime__select"
						value={min_dining_time_min}
						onChange={(e) => handleDiningTime_fixed('min' ,'min', e)}
					>
						{minList.map((m) => (
							<option key={m} value={m}>
								{m}
							</option>
						))}
					</select>
					<span className="diningTime__txt">{t('time:minute')}</span>
				</div>
				<div className="diningTime_content">
					<span className="diningTime_option diningTime_range">{t('bookingTimesCommon.maximum')}</span>
					<select
						className="diningTime__select"
						value={max_dining_time_hour}
						onChange={(e) => handleDiningTime_fixed('max','hour', e)}
					>
						{hourList.map((hour) => (
							<option key={hour} value={hour}>
								{hour}
							</option>
						))}
					</select>
					<span className="diningTime__txt">{t('time:hour')}</span>
					<select
						className="diningTime__select"
						value={max_dining_time_min}
						onChange={(e) => handleDiningTime_fixed('max','min', e)}
					>
						{minList.map((m) => (
							<option key={m} value={m}>
								{m}
							</option>
						))}
					</select>
					<span className="diningTime__txt">{t('time:minute')}</span>
				</div>
			</div>
			)
		}
	}

	const renderTimeInterval_fixed = () => {
		const handleDiningTimeIntervalTime = (e) => {
			const time = e.target.value;
			dispatch(changeDiningTimeIntervalTime(parseInt(time, 10)));
		};

		if(diningTimeOptions.mode === 'fixed') {
			return(
				<div className="row diningTime_interval" style={{marginBottom: '0px', marginTop: '24px'}}>
				<div className="col-sm-4 diningTime_select_title">
						{t('bookingTimesCommon.timeDurationIntervals')}
				</div>
				<div className="col-sm-8 p-0 diningTime_select_margin">
					<select
						className="diningTime__select"
						value={diningTimeOptions.fixed.unit}
						onChange={(e) => handleDiningTimeIntervalTime(e)}
					>
						{bookingIntervalTime.map((time) => {
							return <option key={time}>{time}</option>;
						})}
					</select>
					<span className="diningTime__txt">{t('time:minute')}</span>
				</div>
			</div>
			)
		}
	}
	
	const renderDiningTimeOptions = () => {
		return (
			<div>
				<div className="enableOption row">
					<label className="col-sm-12 enable_option_marginTop">
						<input type="radio" name="enable_2" checked={diningTimeOptions.mode === 'user_setting'} onChange={() => handleDiningTimeMode('user_setting')} />
						<span className="option_title">{t('bookingTimesCommon.customServiceDurationOptions')}</span>
					</label>
				</div>
				{ renderModalDiningTime_userSetting() }
				<div className="enableOption row">
					<label className="col-sm-12 enable_option_marginTop">
						<input type="radio" name="enable_2" checked={diningTimeOptions.mode === 'fixed'} onChange={() => handleDiningTimeMode('fixed')} />
						<span className="option_title">{t('bookingTimesCommon.regularIntervalsOfTimeDurationOptions')}</span>
						<div className="option_subtitle">{t('bookingTimesCommon.regularIntervalsOfTimeDurationOptions_note')}</div>
					</label>
				</div>
				{ renderModalDiningTime_fixed() }
				{ renderTimeInterval_fixed() }
			</div>
		)
	}

	const renderFixedDiningTime = () => {
		let dining_time_hour = Math.floor(parseInt(fixedDiningTime, 10) / 60)
		let dining_time_min = Math.floor(parseInt(fixedDiningTime, 10) % 60)

		return (
			<div className="fixedDiningTime" style={{ marginTop: '24px' }}>
				<select 
					className="diningTime__select"
					value={dining_time_hour}
					onChange={(e) => handleFixedDiningTime('hour', e)}
				>
					{hourList.map((hour) => (
						<option key={hour} value={hour}>
							{hour}
						</option>
					))}
				</select>
				<span className="diningTime__txt">{t('time:hour')}</span>
				<select
					className="diningTime__select"
					value={dining_time_min}
					onChange={(e) => handleFixedDiningTime('min', e)}
				>
					{minList.map((m) => (
						<option key={m} value={m}>
							{m}
						</option>
					))}
				</select>
				<span className="diningTime__txt">{t('time:minute')}</span>
			</div>
		)
	}

	return (
		<section>
			<div className="sectionTitle" style={{marginBottom: '0'}}>{t('bookingTimesCommon.serviceDuration')}</div>
			<div className="sectionSubtitle">{t('bookingTimesCommon.serviceDuration_tooltip')}</div>
			{serviceTimeOptionEnabled ? renderDiningTimeOptions() : renderFixedDiningTime() }
		</section>
	);
};


SettingEditor.Title = SettingEditorTitle;
SettingEditor.Time = SettingEditorTime;
SettingEditor.Week = SettingEditorWeek;
SettingEditor.Enable_2 = SettingEditorEnable_2;
SettingEditor.Enable_3 = SettingEditorEnable_3;
SettingEditor.TimeRange = SettingEditorTimeRange;
SettingEditor.Interval = SettingEditorInterval;
SettingEditor.DiningTime = SettingEditorDiningTime;

export default SettingEditor;
