import React, { Component } from "react";
import { Switch } from "antd";
import { withTranslation } from "react-i18next";
class QueueOptionSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchStatus: true,
      currentState: "normal",
      isAvailable: props.optionParam.status === "start",
    };
  }

  componentDidMount() {
    this.setState({
      currentState: this.props.state,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.state === "editing" &&
      this.state.currentState !== "editing" &&
      this.state.currentState !== undefined
    ) {
      this.setState({
        currentState: this.props.state,
        isAvailable: this.props.optionParam.status === "start",
      });
    } else if (prevProps.state !== this.props.state) {
      this.setState({
        currentState: this.props.state,
      });
    }

    if (prevProps.optionParam.status !== this.props.optionParam.status) {
      this.setState({
        isAvailable: this.props.optionParam.status === "start",
      });
    }
  }

  switchOnChange = (active) => {
    const { t, isOptionEditing, optionParam, updateSelectStatus } = this.props;
    if (!isOptionEditing) return;

    if (this.props.optionParam["auto_pause"] === false) {
      const newStatus = active ? "start" : "pause";

      // 使用函數式更新確保狀態正確
      this.setState(
        (prevState) => ({
          isAvailable: active,
        }),
        () => {
          updateSelectStatus(optionParam.id, newStatus);
        }
      );
    } else {
      if (active === true) {
        alert(t("select.pls_limitHasBeenReachedBack"));
      }
      this.setState({
        isAvailable: false,
      });
    }
  };

  render() {
    const { t } = this.props;
    let selectContent;

    switch (this.state.currentState) {
      case "normal":
        selectContent = (
          <div
            className="queue-option-select"
            onClick={this.props.selectAction}
          >
            <div className="queue-option-select-title">
              <h3>{this.props.optionParam.title}</h3>
              <p>
                {t("select.groupsInQueue")}{" "}
                {this.props.optionParam.current_waiting_groups}{" "}
              </p>
            </div>
            <div className="queue-option-select-number">
              <p>{this.props.optionParam.next_number}</p>
            </div>
          </div>
        );
        break;
      case "pause":
        selectContent = (
          <div
            className="queue-option-select"
            onClick={this.props.selectAction}
          >
            <div className="queue-option-select-title pauseTitle">
              <h3>{this.props.optionParam.title}</h3>
              <p>
                {t("select.groupsInQueue")}{" "}
                {this.props.optionParam.current_waiting_groups}{" "}
              </p>
            </div>
            <img
              className="pause-icon"
              src={require("../../images/queue/pause_button.png")}
              alt=""
            />
          </div>
        );
        break;
      case "autopause":
        selectContent = (
          <div
            className="queue-option-select"
            onClick={this.props.selectAction}
          >
            <div className="queue-option-select-title pauseTitle">
              <h3>{this.props.optionParam.title}</h3>
              <p>
                {t("select.groupsHasLimit")}
                {this.props.optionParam.current_waiting_groups}{" "}
              </p>
            </div>
            <img
              className="pause-icon"
              src={require("../../images/queue/pause_button.png")}
              alt=""
            />
          </div>
        );
        break;
      case "unavailable":
        selectContent = (
          <div className="queue-option-select">
            <div className="queue-option-select-title unavailableTitle">
              <h3>{this.props.optionParam.title}</h3>
              <p>
                {t("select.groupsInQueue")}{" "}
                {this.props.optionParam.current_waiting_groups}{" "}
              </p>
            </div>
            <div className="queue-option-select-number unavailable">
              <p>{t("select.groupsDisabled")}</p>
            </div>
          </div>
        );
        break;
      case "editing":
        selectContent = (
          <div className="queue-option-select">
            <div className="queue-option-select-title editTitle">
              <h3>{this.props.optionParam.title}</h3>
              <p>
                {t("select.groupsInQueue")}{" "}
                {this.props.optionParam.current_waiting_groups}{" "}
              </p>
            </div>
            <div className="queue-option-select-number">
              <div className="queue-option-switch-container">
                <Switch
                  className="queue-option-switch"
                  style={{ width: 30 }}
                  checked={this.state.isAvailable}
                  onChange={this.switchOnChange}
                />
              </div>
              <p>{this.props.optionParam.next_number}</p>
            </div>
          </div>
        );
        break;
      default:
        selectContent = null;
    }
    return selectContent;
  }
}

export default withTranslation("queueSystem")(QueueOptionSelect);
