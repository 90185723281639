import React, { Component } from "react";
import QueueSystemAPI from "../queue_system_settings/QueueSystemAPI";
import { Tooltip, Modal, Space } from "antd";
import { withTranslation } from "react-i18next";

class QueueSettingGroupType extends Component {
  state = {
    groupTypes: [],
    isModalVisible: false,
    modalGroupType: null,
  };

  constructor(props) {
    super(props);
    this.addNewGroupType = this.addNewGroupType.bind(this);
    this.editGroupType = this.editGroupType.bind(this);
    this.deleteGroupType = this.deleteGroupType.bind(this);
  }

  componentDidMount() {
    this.API = new QueueSystemAPI();
    this.loadGroupContent();
  }

  loadGroupContent = () => {
    this.API.getListedQueueGroup((data) => {
      if (data !== undefined) {
        this.setState({
          groupTypes: data,
        });
      }
    });
  };

  closeModal = () => {
    this.loadGroupContent();
    this.setState({ isModalVisible: false, modalGroupType: null });
  };

  addNewGroupType = () => {
    this.setState({ isModalVisible: true, modalGroupType: null });
  };

  editGroupType = (groupType) => {
    this.setState({ isModalVisible: true, modalGroupType: groupType });
  };

  deleteGroupType = (idx) => {
    this.API.deleteQueueGroup(idx, (data) => {
      if (data["id"] !== undefined) {
        let currentGroupTypes = this.state.groupTypes;
        currentGroupTypes = currentGroupTypes.filter((element) => {
          return element.id !== data["id"];
        });
        this.setState({
          groupTypes: currentGroupTypes,
        });
      }
    });
  };

  render() {
    const { t } = this.props;
    let groupTypes = this.state.groupTypes.map((groupType, idx) => {
      return (
        <div key={idx}>
          <div className="rangeTitle">
            <img
              src={require("../../images/queue/queue_group_item.png")}
              alt=""
            />
            <h4 style={{ width: "50%" }}>{groupType.title}</h4>
            <div>
              <button
                className="msLink"
                onClick={() => {
                  this.editGroupType(groupType);
                }}
              >
                {t("edit")}
              </button>
              <button
                className={
                  groupType.status !== "end" ? "msLink disabled" : "msLink"
                }
                onClick={() => {
                  if (groupType.status !== "end") {
                    alert(t("queueTypes.pls_cannotDelete"));
                  } else {
                    this.deleteGroupType(groupType.id);
                  }
                }}
              >
                {t("delete")}
              </button>
            </div>
          </div>
          <div className="settingTbl">
            <div className="settingTblRow">
              <div className="header">
                {t("queueTypes.theRangeOfPeoplePerGroup")}
              </div>
              <div className="text">
                {groupType.min_people_count}~{groupType.max_people_count}
              </div>
            </div>
            <div className="settingTblRow">
              <div className="header">{t("queueTypes.maximumLimitGroups")}</div>
              <div className="text">{groupType.count_limit}</div>
            </div>
            <div className="settingTblRow">
              <div className="header">{t("queueTypes.queueStartNumber")}</div>
              <div className="text">{groupType.begin_number}</div>
            </div>
          </div>
          <hr />
        </div>
      );
    });

    return (
      <div className="setting-container">
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <h3>{t("queueTypes.title")}</h3>
            <h5>{t("queueTypes.description")}</h5>
          </div>
        </div>
        <hr />
        {groupTypes}
        {this.state.isModalVisible && (
          <TranslatedGroupTypeModal
            groupType={this.state.modalGroupType}
            closeHandler={this.closeModal}
          />
        )}
        <button
          className="msBtn"
          style={{ marginRight: "12px" }}
          onClick={() => this.addNewGroupType()}
        >
          + {t("queueTypes.createQueueTypes_2")}
        </button>
        <hr />
      </div>
    );
  }
}

class GroupTypeModal extends Component {
  state = {
    title: "",
    status: "",
    countLimit: 99,
    beginNumber: 1,
    minPeopleCount: 1,
    maxPeopleCount: 2,
    selectedGroups: [],
    timeGroups: [],
    id: -1,
  };

	componentDidMount() {
    this.API = new QueueSystemAPI();
		const { groupType } = this.props;
		if (groupType) {
			this.setState({
				status: groupType.status || "",
				title: groupType.title || "",
				countLimit: groupType.count_limit || 99,
				beginNumber: groupType.begin_number || 1,
				minPeopleCount: groupType.min_people_count || 1,
				maxPeopleCount: groupType.max_people_count || 2,
				id: groupType.id || -1,
			});
		}
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.groupType !== this.props.groupType
    ) {
      const { groupType } = this.props;
      if (groupType !== null) {
        this.setState({
          status: groupType.status,
          title: groupType.title,
          countLimit: groupType.count_limit,
          beginNumber: groupType.begin_number,
          minPeopleCount: groupType.min_people_count,
          maxPeopleCount: groupType.max_people_count,
          id: groupType.id,
        });
      } else {
        this.setState({
          title: "",
          countLimit: 99,
          beginNumber: 1,
          minPeopleCount: 1,
          maxPeopleCount: 2,
          id: -1,
        });
      }
    }
  }

  updateGroupType = (e) => {
    const { t } = this.props;
    if (this.state.title === "") {
      alert(t("queueTypes.pls_nameRequired"));
    }

    if (this.state.id === -1) {
      this.API.createQueueGroup(
        {
          title: this.state.title,
          available: true,
          max_people_count: Math.max(
            this.state.maxPeopleCount,
            this.state.minPeopleCount
          ),
          min_people_count: Math.min(
            this.state.maxPeopleCount,
            this.state.minPeopleCount
          ),
          count_limit: this.state.countLimit,
          begin_number: this.state.beginNumber,
        },
        (data) => {
          if (data !== undefined) {
            this.props.closeHandler();
          }
        }
      );
    } else {
      this.API.editQueueGroup(
        {
          id: this.state.id,
          title: this.state.title,
          available: true,
          max_people_count: Math.max(
            this.state.maxPeopleCount,
            this.state.minPeopleCount
          ),
          min_people_count: Math.min(
            this.state.maxPeopleCount,
            this.state.minPeopleCount
          ),
          count_limit: this.state.countLimit,
          begin_number: this.state.beginNumber,
        },
        (data) => {
          if (data !== undefined) {
            this.props.closeHandler();
          }
        }
      );
    }
  };

  inputChange = (e) => {
    if (e.target.value.length <= 20) {
      this.setState({
        title: e.target.value,
      });
    }
  };

  toggleTimePicker = (selectedIndexPath) => {
    this.setState((prevState) => ({
      selectedIndexPath: selectedIndexPath,
      timePickerOpened: !prevState.timePickerOpened,
    }));
  };

  render() {
    const { t } = this.props;
    return (
      <Modal
        title={
          this.state.id === -1
            ? t("queueTypes.createQueueTypes")
            : t("queueTypes.editQueueTypes")
        }
        visible
        destroyOnClose
        width={640}
        closable={false}
        centered
        className="modal-base modal-xl cqModal"
        footer={
          <Space size={10}>
            <button
              className="button-common button-secondary"
              onClick={() => this.props.closeHandler()}
            >
              {t("cancel")}
            </button>
            <button
              onClick={() => {
                this.updateGroupType();
              }}
              className="button-common button-primary"
            >
              {t("save")}
            </button>
          </Space>
        }
        transitionName="ant-move-down"
        maskClosable={false}
      >
        <div className="modalRow">
          <div className="modalRow__title">{t("queueTypes.queueType")}</div>
          <div className="modalRow__value">
            <div className="text">
              <input
                ref={(ref) => (this.titleRef = ref)}
                className="form-control input-lg"
                style={{ width: "100%", display: "inline" }}
                onChange={this.inputChange}
                value={this.state.title}
                placeholder={t("queueTypes.placeholder_enterQueueType")}
                disabled={
                  (this.state.status === "start" ||
                    this.state.status === "pause") &&
                  this.state.id !== -1
                }
              />
            </div>
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">
            <Tooltip
              placement="bottom"
              overlayClassName="setting-tooltip"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              title={t("queueTypes.theRangeOfPeoplePerGroup_tooltip")}
            >
              {t("queueTypes.theRangeOfPeoplePerGroup")}
              <i className="fa fa-question-circle-o" />
            </Tooltip>
          </div>
          <div className="modalRow__value">
            <div className="text">
              <input
                ref={(ref) => (this.minRef = ref)}
                className="form-control input-lg"
                type="number"
                style={{
                  width: "12%",
                  display: "inline",
                  marginRight: "12px",
                  paddingLeft: "10px",
                  paddingRight: "6px",
                }}
                disabled={
                  (this.state.status === "start" ||
                    this.state.status === "pause") &&
                  this.state.id !== -1
                }
                onChange={(e) => {
                  if (this.minRef.value < 1) {
                    this.setState({
                      minPeopleCount: 1,
                    });
                  } else {
                    this.setState({
                      minPeopleCount: e.target.value,
                    });
                  }
                }}
                value={this.state.minPeopleCount}
              />
              ~
              <input
                ref={(ref) => (this.maxRef = ref)}
                className="form-control input-lg"
                type="number"
                style={{
                  width: "12%",
                  display: "inline",
                  marginLeft: "12px",
                  paddingLeft: "10px",
                  paddingRight: "6px",
                }}
                disabled={
                  (this.state.status === "start" ||
                    this.state.status === "pause") &&
                  this.state.id !== -1
                }
                onChange={(e) => {
                  if (this.maxRef.value > 99) {
                    this.setState({
                      maxPeopleCount: 99,
                    });
                  } else {
                    this.setState({
                      maxPeopleCount: e.target.value,
                    });
                  }
                }}
                value={this.state.maxPeopleCount}
              />
            </div>
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">
            <Tooltip
              placement="bottom"
              overlayClassName="setting-tooltip"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              title={t("queueTypes.maximumLimitGroups_tooltip")}
            >
              {t("queueTypes.maximumLimitGroups")}
              <i className="fa fa-question-circle-o" />
            </Tooltip>
          </div>
          <div className="modalRow__value">
            <div className="text">
              <input
                ref={(ref) => (this.titleRef = ref)}
                className="form-control input-lg"
                type="number"
                placeholder="100"
                style={{ width: "30%", display: "inline" }}
                onChange={(e) => {
                  this.setState({
                    countLimit: e.target.value,
                  });
                }}
                value={this.state.countLimit}
              />
            </div>
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">
            <Tooltip
              placement="bottom"
              overlayClassName="setting-tooltip"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              title={t("queueTypes.queueStartNumber_tooltip")}
            >
              {t("queueTypes.queueStartNumber")}
              <i className="fa fa-question-circle-o" />
            </Tooltip>
          </div>
          <div className="modalRow__value">
            <div className="text">
              <input
                ref={(ref) => (this.titleRef = ref)}
                className="form-control input-lg"
                type="number"
                placeholder="100"
                style={{ width: "30%", display: "inline" }}
                disabled={
                  (this.state.status === "start" ||
                    this.state.status === "pause") &&
                  this.state.id !== -1
                }
                onChange={(e) => {
                  this.setState({
                    beginNumber: e.target.value,
                  });
                }}
                value={this.state.beginNumber}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const TranslatedGroupTypeModal = withTranslation("settings")(GroupTypeModal);
export default withTranslation("settings")(QueueSettingGroupType);
