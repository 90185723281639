import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Modal, DatePicker, Space } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import {
  announcementFetchAll,
  handleHeaderFilter,
  announcementFetchMonth,
  announcementOfficialFetch,
  updateDayAnnouncements,
  showAnnouncementCancelModal,
  showAnnouncementAddModal,
  announcementChange,
  clearAnnouncement,
  handleSearchList,
} from "../../../actions/announcementAction";

import { createAnnouncement, updateAnnouncement } from "../api/AnnouncementApi";
import {
  createOfficialAnnouncement,
  updateOfficialAnnouncement,
} from "../api/OfficialAnnouncementApi";

const { RangePicker } = DatePicker;

const AnnouncementAddModal = () => {
  const { t } = useTranslation("bulletinSystem");
  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const {
    id,
    category,
    title,
    content,
    startDateTime,
    endDateTime,
    finished,
    showEnabled,
    officialShowEnabled,
  } = announcementInfo;

  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const {
    role,
    currentDate,
    currentFilter,
    searchListTxt,
    showAnnouncementListModal,
  } = announcementListReducer;

  const dispatch = useDispatch();
  const [isSaved, setIsSaved] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const titleInput = document.getElementsByClassName("announcementTitle")[0];
    const contentInput = document.getElementsByClassName(
      "announcementContent"
    )[0];

    if (title.length > 0) {
      titleInput.classList.remove("input_error");
    }

    if (content.length > 0) {
      contentInput.classList.remove("input_error");
    }
  }, [title, content]);

  useEffect(() => {
    // 關閉 mobile 原生鍵盤
    const startTimePicker =
      document.getElementsByClassName("ant-picker-input")[0];
    const endTimePicker =
      document.getElementsByClassName("ant-picker-input")[1];

    if (startTimePicker && endTimePicker) {
      const startTimeInput = startTimePicker.children[0];
      const endTimeInput = endTimePicker.children[0];

      startTimeInput.setAttribute("inputmode", "none");
      endTimeInput.setAttribute("inputmode", "none");
    }
  }, [category]);

  const cancelPopup = () => {
    if (finished || isSaved) {
      dispatch(showAnnouncementAddModal());
    } else {
      dispatch(showAnnouncementCancelModal());
    }
  };

  const range = (start, end) => {
    const result = [];

    for (let i = start; i < end; i++) {
      result.push(i);
    }

    return result;
  };

  const disabledDate = (current) => {
    // Can not select days before today
    const time = moment().endOf("day").subtract(1, "days");
    return current && current < time;
  };

  const disabledRangeTime = (_, type) => {
    const today = moment().date();
    const selectedDate = moment(_).date();
    const hour = moment().hour();
    const min = moment().minute();

    if (type === "end") {
      return {
        disabledHours: () => {
          if (today === selectedDate) {
            return range(0, hour);
          }
        },
        disabledMinutes: (selectedHour) => {
          if (today === selectedDate && selectedHour === hour) {
            return range(0, min);
          }
        },
      };
    }

    return {
      disabledHours: null,
      disabledMinutes: null,
      disabledSeconds: null,
    };
  };

  const handleChange = (e) => {
    let type = e.target.name;
    let value = e.target.value;

    if (type === "finished") {
      dispatch(announcementChange(type, false));
      dispatch(announcementChange("startDateTime", ""));
      dispatch(announcementChange("endDateTime", ""));
    } else if (type === "showEnabled") {
      dispatch(announcementChange(type, !showEnabled));
    } else if (type === "officialShowEnabled") {
      dispatch(announcementChange(type, !officialShowEnabled));
    } else {
      dispatch(announcementChange(type, value));
    }

    setIsSaved(false);
  };

  const handleDateTime = (value, dateString) => {
    dispatch(announcementChange("startDateTime", dateString[0]));
    dispatch(announcementChange("endDateTime", dateString[1]));
  };

  const submit = async () => {
    const titleInput = document.getElementsByClassName("announcementTitle")[0];
    const contentInput = document.getElementsByClassName(
      "announcementContent"
    )[0];
    const timeInput = document.getElementsByClassName("announcementTime")[0];

    if (
      category === "regular" &&
      moment(startDateTime) > moment(endDateTime) &&
      !finished
    ) {
      return window.app.alert.setMessage(
        t("editAnnouncement.pls_startTimeGreaterEndTime"),
        "error"
      );
    }

    if (category === "regular" && moment(endDateTime) < moment() && !finished) {
      return window.app.alert.setMessage(
        t("editAnnouncement.pls_startTimeLessCurrent"),
        "error"
      );
    }

    if (title.trim().length === 0) {
      titleInput.classList.add("input_error");
      window.app.alert.setMessage(
        t("editAnnouncement.pls_enterTitle"),
        "error"
      );
    } else {
      titleInput.classList.remove("input_error");
    }

    if (content.trim().length === 0) {
      contentInput.classList.add("input_error");
      window.app.alert.setMessage(
        t("editAnnouncement.pls_enterContent"),
        "error"
      );
    } else {
      contentInput.classList.remove("input_error");
    }

    if (category === "regular") {
      if (!startDateTime || !endDateTime) {
        timeInput.classList.add("input_error");
        window.app.alert.setMessage(
          t("editAnnouncement.pls_selectTime"),
          "error"
        );
      } else {
        timeInput.classList.remove("input_error");
      }
    }

    if (
      title.trim().length === 0 ||
      content.trim().length === 0 ||
      (category === "regular" && !startDateTime) ||
      (category === "regular" && !endDateTime)
    ) {
      return;
    }

    let announcement = {
      title: title,
      content: content,
      category: category,
      start_time: moment(startDateTime),
      end_time: moment(endDateTime),
      show_enabled: showEnabled,
      finished: finished,
    };

    let officialAnnouncement = {
      title: title,
      content: content,
      category: "regular",
      start_time: moment(startDateTime),
      end_time: moment(endDateTime),
      enabled: officialShowEnabled,
      finished: finished,
    };

    if (id) {
      if (role === "shop") {
        try {
          setLoading(true);
          await updateAnnouncement(id, announcement);
          dispatch(showAnnouncementAddModal());
          dispatch(announcementFetchAll());
          dispatch(handleHeaderFilter(currentFilter));
          window.app.alert.setMessage(
            t("editAnnouncement.editSuccess"),
            "done"
          );

          if (showAnnouncementListModal) {
            dispatch(updateDayAnnouncements(currentDate));
            dispatch(announcementFetchMonth());
          }

          if (searchListTxt.length > 0) {
            dispatch(handleSearchList(searchListTxt));
          }
        } catch (err) {
          console.log("update announcement err ----------", err);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          await updateOfficialAnnouncement(id, officialAnnouncement);
          dispatch(showAnnouncementAddModal());
          dispatch(announcementOfficialFetch());
          dispatch(handleHeaderFilter(currentFilter));
          window.app.alert.setMessage(
            t("editAnnouncement.editSuccess"),
            "done"
          );

          if (searchListTxt.length > 0) {
            dispatch(handleSearchList(searchListTxt));
          }
        } catch (err) {
          console.log("update official announcement err ----------", err);
        } finally {
          setLoading(false);
        }
      }
    } else {
      if (role === "shop") {
        try {
          setLoading(true);
          await createAnnouncement(announcement);
          dispatch(clearAnnouncement());
          dispatch(announcementFetchAll());
          dispatch(handleHeaderFilter(currentFilter));
          window.app.alert.setMessage(t("editAnnouncement.addSuccess"), "done");

          if (showAnnouncementListModal) {
            dispatch(updateDayAnnouncements(currentDate));
            dispatch(announcementFetchMonth());
          }

          if (searchListTxt.length > 0) {
            dispatch(handleSearchList(searchListTxt));
          }
        } catch (err) {
          console.log("create announcement err ----------", err);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          await createOfficialAnnouncement(officialAnnouncement);
          dispatch(clearAnnouncement());
          dispatch(announcementOfficialFetch());
          dispatch(handleHeaderFilter(currentFilter));
          window.app.alert.setMessage(t("editAnnouncement.addSuccess"), "done");

          if (searchListTxt.length > 0) {
            dispatch(handleSearchList(searchListTxt));
          }
        } catch (err) {
          console.log("create official announcement err ----------", err);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const renderFinishedEdit = () => {
    return (
      <div className="announcementFinished">
        <div>{t("editAnnouncement.notice_reedit")}</div>
        <button
          className="button-common button-primary"
          name="finished"
          onClick={(e) => handleChange(e)}
        >
          {t("editAnnouncement.reedit")}
        </button>
      </div>
    );
  };

  const renderType = () => {
    if (role === "shop") {
      return (
        <div className="announcement_flex">
          <label>
            <input
              type="radio"
              name="category"
              value="regular"
              checked={category === "regular"}
              onChange={(e) => handleChange(e)}
              disabled={finished}
            />
            {t("editAnnouncement.status.normal")}
          </label>
          <div>
            <label>
              <input
                type="radio"
                name="category"
                value="long_term"
                checked={category === "long_term"}
                onChange={(e) => handleChange(e)}
                disabled={finished}
              />
              {t("editAnnouncement.status.persistent")}
            </label>
            <div style={{ fontSize: "12px", fontWeight: "400" }}>
              {t("editAnnouncement.notice_persistent")}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="announcement_flex">
          <label>
            <input type="radio" defaultChecked />
            {t("editAnnouncement.status.system")}
          </label>
        </div>
      );
    }
  };

  const renderDateTime = () => {
    if (category === "regular") {
      return (
        <RangePicker
          className="announcementTime range-picker"
          format="YYYY-MM-DD HH:mm"
          value={
            startDateTime && endDateTime
              ? [moment(startDateTime), moment(endDateTime)]
              : null
          }
          showTime={{
            hideDisabledOptions: false,
            format: "HH:mm",
            defaultValue: [moment("00:00", "HH:mm"), moment("23:59", "HH:mm")],
          }}
          placeholder={[
            t("editAnnouncement.startDate"),
            t("editAnnouncement.endDate"),
          ]}
          onChange={handleDateTime}
          disabledDate={disabledDate}
          disabledTime={disabledRangeTime}
          disabled={finished}
        />
      );
    } else {
      return (
        <div className="dateTime_disabled">
          {t("editAnnouncement.dateNoLimit")}
          <CalendarOutlined />
        </div>
      );
    }
  };

  const renderDisplayCheck = () => {
    if (role === "shop" && category !== "long_term") {
      return (
        <div className="announcement_row announcement_check">
          <label>
            <input
              type="checkbox"
              name="showEnabled"
              value={showEnabled}
              checked={showEnabled}
              onChange={(e) => handleChange(e)}
              disabled={finished}
            />
            {t("editAnnouncement.onMainPage")}
          </label>
        </div>
      );
    }

    if (role === "official") {
      return (
        <div className="announcement_flex official_radio">
          <label>
            <input
              type="radio"
              name="officialShowEnabled"
              value={true}
              checked={officialShowEnabled}
              onChange={(e) => handleChange(e)}
            />
            {t("editAnnouncement.publish")}
          </label>
          <div>
            <label>
              <input
                type="radio"
                name="officialShowEnabled"
                value={false}
                checked={!officialShowEnabled}
                onChange={(e) => handleChange(e)}
              />
              {t("editAnnouncement.hide")}
            </label>
            <div style={{ fontSize: "12px", fontWeight: "400" }}>
              {t("editAnnouncement.note_hide")}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Modal
      title={t("popup.editAnnouncement_title", {
        type: id ? t("edit") : t("add"),
      })}
      visible
      centered
      className={`modal-base modal-xl announcementAddModal ${
        finished ? "addModal_finished" : ""
      }`}
      destroyOnClose
      width={640}
      closable={false}
      footer={
        <Space size={10}>
          <button
            className="button-common button-secondary"
            onClick={cancelPopup}
          >
            {t("settings:cancel")}
          </button>
          <button
            className="button-common button-primary"
            onClick={submit}
            disabled={(finished && isSaved) || loading}
          >
            {id ? t("settings:save") : t("settings:add")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {finished && renderFinishedEdit()}
      <div className="announcement_row">
        <h4>{t("editAnnouncement.type")}</h4>
        {renderType()}
      </div>

      <div className="announcement_row">
        <h4>{t("editAnnouncement.duration")}</h4>
        {renderDateTime()}
      </div>

      <div className="announcement_row">
        <h4>{t("editAnnouncement.title")}</h4>
        <input
          className="announcementTitle"
          type="text"
          name="title"
          value={title}
          placeholder={t("editAnnouncement.pls_enterTitle")}
          maxLength={30}
          onChange={(e) => handleChange(e)}
          disabled={finished}
        />
      </div>

      <div className="announcement_row">
        <h4>{t("editAnnouncement.content")}</h4>
        <textarea
          className="announcementContent"
          value={content}
          name="content"
          placeholder={t("editAnnouncement.pls_enterContent")}
          onChange={(e) => handleChange(e)}
          disabled={finished}
        />
      </div>

      {renderDisplayCheck()}
    </Modal>
  );
};

export default AnnouncementAddModal;
