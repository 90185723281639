import React, { useMemo } from "react";
import { useDrop } from "react-dnd";
import _ from "lodash";

import { time } from "../utils/data";

import BookingCell from "./BookingCell";

export default ({
  date,
  tableInfo,
  droppedTable,
  showCanvasTableBookingInfo,
  DraggingEnd,
  setBookingListInfo,
  startDrag,
  dragTableName,
  dragBooking,
  setSelectBookings,
  tableIndex,
}) => {
  const isClickable = !showCanvasTableBookingInfo;
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "bookingCell",
    canDrop: () => {
      const dropSeatIndex = dragBooking.seats
        .map((seat) => {
          return seat.table_number;
        })
        .indexOf(tableInfo.table_number);
      return dropSeatIndex === -1;
    },
    drop: () => {
      if (dragTableName === tableInfo.table_number) return;

      DraggingEnd({ tableInfo });
      // console.log('tableName-----------', tableInfo);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const spaceCells = React.useMemo(() => {
    return _.range(55).map((cell, index) => {
      const hour = time[cell] ? time[cell] : "00:00";
      const canClick = isClickable && Number(index) < 48;
      return (
        <div
          className={`spaceCell ${canClick ? "btn-effect" : ""}`}
          key={index}
          data-time={hour}
          data-index={index}
          data-table-index={tableIndex}
        />
      );
    });
  }, [isClickable, tableIndex]);

  const bookingCells = useMemo(() => {
    const newTimeline = _.sortBy(tableInfo.timeline, ["service_start_time"]);
    return newTimeline.map((booking, index) => (
      <BookingCell
        key={index}
        bookingIndex={index}
        date={date}
        booking={booking}
        tableName={tableInfo.table_number}
        setBookingListInfo={setBookingListInfo}
        startDrag={startDrag}
        setSelectBookings={setSelectBookings}
      />
    ));
  }, [
    date,
    tableInfo.timeline,
    tableInfo.table_number,
    setBookingListInfo,
    startDrag,
    setSelectBookings,
  ]);

  const backgroundColor = useMemo(() => {
    if (tableInfo.table_number === droppedTable.table_number) {
      return "rgba(0, 100, 0, .5)";
    }
    if (isOver) {
      return canDrop ? "rgba(0, 100, 0, .5)" : "#fff";
    }
    return "#fff";
  }, [isOver, canDrop, tableInfo.table_number, droppedTable.table_number]);

  return (
    <div
      ref={drop}
      style={{ backgroundColor }}
      className={`timelineBookingRow`}
    >
      {spaceCells}
      {bookingCells}
    </div>
  );
};
