import React, { useContext, Fragment } from "react";
import { CouponStateContext } from "../MenuCouponContext/MenuCouponContext";
import { CouponAddStateContext } from "../MenuCouponContext/MenuCouponAddContext";
import PreviewPopup from "../MenuCouponPopup/Preview";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

const MenuCouponAddHeader = () => {
  const { t } = useTranslation("couponSystem");
  const { CouponState, CouponDispatch } = useContext(CouponStateContext);
  const { showPreviewPopup } = CouponState;
  const { CouponAddState } = useContext(CouponAddStateContext);

  const showPreview = () => {
    const {
      coverURL,
      countLimited,
      description,
      thumbnailURL,
      title,
      voucherCount,
      item,
      endDateTime,
      publishDateTime,
    } = CouponAddState.couponSetting;

    const previewInfo = {
      coverURL,
      countLimited,
      description,
      thumbnailURL,
      title,
      exchangedCount: 0,
      voucherCount: parseInt(voucherCount, 10),
      item,
      endDateTime,
      publishDateTime,
    };

    CouponDispatch({ type: "setPreview", previewInfo });
  };

  return (
    <Fragment>
      <div className="menuCouponHeaderBar">
        <div className="menuCouponHeader">
          <h2 className="system-title">{t("addCoupon.title")}</h2>
          <div className="menuCouponHeader__button">
            <button
              className="button-round-big button-primary"
              onClick={() => showPreview()}
            >
              {t("preview")}
            </button>
          </div>
        </div>
      </div>
      {showPreviewPopup && (
        <Modal
          className="previewPopup"
          destroyOnClose
          title={t("previewContent.title")}
          visible={showPreviewPopup}
          footer={null}
          onCancel={() => {
            CouponDispatch({ type: "cancelPopup" });
          }}
        >
          <PreviewPopup />
        </Modal>
      )}
    </Fragment>
  );
};

export default MenuCouponAddHeader;
