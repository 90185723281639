import React from "react";
import { browserHistory } from "react-router";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { getTagsTranslationKey } from "../utils/data";

const CrmCustomerItem = ({ data }) => {
  const { t } = useTranslation("customer");

  const toCustomerRecordPage = () => {
    const searchParams = window.location.search;

    browserHistory.push({
      pathname: `${window.location.pathname}/${data.id}`,
      state: {
        customerList: searchParams ?? "",
      },
    });
  };

  const renderGender = () => {
    if (data.gender_display === "男") {
      return t("info.male");
    } else if (data.gender_display === "女") {
      return t("info.female");
    } else {
      return t("info.other");
    }
  };

  const renderPhone = () => {
    if (data.phone_number.length > 10) {
      return (
        <Tooltip
          placement="bottom"
          title={data.phone_number}
          trigger="click"
          color="#333"
          arrowPointAtCenter
          overlayClassName="c_item_tooltip"
          getPopupContainer={(triggerNode) => triggerNode.parentNode} // scroll position fixed
        >
          <div className="c_phone" onClick={(e) => e.stopPropagation()}>
            {data.phone_number}
          </div>
        </Tooltip>
      );
    } else {
      return <div className="c_phone">{data.phone_number}</div>;
    }
  };

  const renderPhoneVerification = () => {
    if (data.phone_verification) {
      return (
        <Tooltip
          placement="bottom"
          title={t("info.validatePhone")}
          trigger="hover"
          color="#333"
          arrowPointAtCenter
          overlayClassName="c_item_tooltip"
          getPopupContainer={(triggerNode) => triggerNode.parentNode} // scroll position fixed
        >
          <div className="c_verified">
            <img
              src={require("../../../images/crm/verified_icon.svg")}
              alt=""
            />
          </div>
        </Tooltip>
      );
    } else {
      return <div className="c_verified" />;
    }
  };

  const renderTags = () => {
    return (
      <>
        {data.blacklisted && (
          <div className="c_tag c-blocked">{t("tags.blocked")}</div>
        )}
        {data.customer_tags.length !== 0 &&
          data.customer_tags.map((tag) => {
            return (
              <div key={tag.id} className="c_tag">
                {t(getTagsTranslationKey(tag.description))}
              </div>
            );
          })}
      </>
    );
  };

  return (
    <div className="crmCustomerItem" onClick={toCustomerRecordPage}>
      <div className="c_customer">
        <div className="c_fullName">
          <div className="c_name">{data.last_name}</div>
          <div className="c_gender">{renderGender()}</div>
        </div>
        <div className="c_info">
          {renderPhone()}
          {renderPhoneVerification()}
          <Tooltip
            placement="bottom"
            title={renderTags()}
            trigger="click"
            color="#333"
            arrowPointAtCenter
            overlayClassName="c_tags_tooltip"
            getPopupContainer={(triggerNode) => triggerNode.parentNode} // scroll position fixed
          >
            <div
              className="c_tags"
              onClick={(e) =>
                data.customer_tags.length !== 0 && e.stopPropagation()
              }
            >
              {renderTags()}
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="c_record">
        <div>
          {t("list.bookingCount")}
          <span>{data.booking_counts}</span>
        </div>
        <div>
          {t("list.queueCount")}
          <span>{data.queue_records_counts}</span>
        </div>
      </div>
    </div>
  );
};

export default CrmCustomerItem;
