import React, { Component } from "react";
import QueueSystemAPI from "./QueueSystemAPI";
import { withTranslation, Trans } from "react-i18next";
import { Tooltip, Switch } from "antd";
import SettingsSaveButton from "../commons/SettingsSaveButton";

class QueueSettingTime extends Component {
  state = {
    showWaitingGroup: false,
    showWaitingTime: false,
    timeCheckConditions: [],
    isSaved: true,
  };

  addTimeCheckCondition = () => {
    let timeCheckConditions = this.state.timeCheckConditions;
    const { t } = this.props;

    if (timeCheckConditions.length < 5) {
      timeCheckConditions.push({
        count: 0,
        time: 0,
      });
      this.setState({
        timeCheckConditions: timeCheckConditions,
        isSaved: false,
      });
    } else {
      alert(t("estWaitingTime.pls_limitUpTo5"));
    }
  };

  routerWillLeave = (nextLocation) => {
    const { t } = this.props;
    if (!this.state.isSaved) return t("leaveNotice");
  };

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
    this.API = new QueueSystemAPI();
    this.API.getQueueSettings().then(
      (data) => {
        let timeCheckingConditions = [];
        try {
          timeCheckingConditions = JSON.parse(data["time_checking"]);
        } catch (e) {
          console.log("Parse condition failed");
        }

        this.setState({
          showWaitingGroup: data["show_waiting_group"],
          showWaitingTime: data["show_waiting_time"],
          timeCheckConditions: timeCheckingConditions,
        });
      },
      (error) => {}
    );
  }

  submit = () => {
    const { t } = this.props;
    let hasConflict = false;
    let hasDuplicate = false;
    let firstConflictIndex = 0;
    let secondConflictIndex = 0;
    let duplicates = [];

    const counts = {};
    const conditions = this.state.timeCheckConditions;
    for (let i = 0; i < conditions.length; i++) {
      const count = conditions[i].count;
      if (counts[count] !== undefined) {
        hasDuplicate = true;
        duplicates.push(counts[count]);
        duplicates.push(i);
        break;
      }
      counts[count] = i;
    }

    conditions.forEach((condition, index) => {
      if (!hasConflict) {
        firstConflictIndex = index;
        conditions.forEach((innerCondition, innerIndex) => {
          if (!hasConflict) {
            secondConflictIndex = innerIndex;
            if (
              innerCondition.count < condition.count &&
              innerCondition.time > condition.time
            ) {
              hasConflict = true;
            }
          }
        });
      }
    });

    if (hasConflict) {
      return alert(
        t("estWaitingTime.pls_conflict", {
          X: firstConflictIndex + 1,
          Y: secondConflictIndex + 1,
        })
      );
    }

    if (hasDuplicate) {
      return alert(
        t("estWaitingTime.pls_duplicate", {
          X: duplicates[0] + 1,
          Y: duplicates[1] + 1,
        })
      );
    }

    if (!hasConflict) {
      this.API.updateQueueSettings({
        show_waiting_group: this.state.showWaitingGroup,
        show_waiting_time: this.state.showWaitingTime,
        time_checking: JSON.stringify(this.state.timeCheckConditions),
      }).then(
        (data) => {
          window.app.alert.setMessage(t("status.saved"), "done");
          this.setState({
            isSaved: true,
          });
        },
        (error) => {
          window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
        }
      );
    }
  };

  render() {
    const { t } = this.props;
    let timeCheckConditions = [];
    this.state.timeCheckConditions.forEach((element, index) => {
      timeCheckConditions.push(
        <div key={index} className="setting-queue-time-conditions">
          <div className="setting-queue-time">
            <Trans i18nKey="settings:estWaitingTime.groupAndWaitingTime">
              <div>
                當等待組數 {">"}
                <input
                  className="form-control input-lg"
                  value={element.count}
                  onChange={(e) => {
                    let conditions = this.state.timeCheckConditions;
                    conditions[[index]].count = isNaN(
                      parseInt(e.target.value, 10)
                    )
                      ? 0
                      : parseInt(e.target.value, 10);
                    this.setState({
                      timeCheckConditions: conditions,
                      isSaved: false,
                    });
                  }}
                />
                組，
              </div>
              <div>
                等待時間為
                <input
                  className="form-control input-lg"
                  value={element.time}
                  onChange={(e) => {
                    let conditions = this.state.timeCheckConditions;
                    conditions[[index]].time = isNaN(
                      parseInt(e.target.value, 10)
                    )
                      ? 0
                      : parseInt(e.target.value, 10);
                    this.setState({
                      timeCheckConditions: conditions,
                      isSaved: false,
                    });
                  }}
                />
                分鐘
              </div>
            </Trans>
          </div>
          <button
            className="setting-delete-button"
            onClick={(e) => {
              let timeCheckConditions = this.state.timeCheckConditions;
              timeCheckConditions.splice(index, 1);
              this.setState({
                timeCheckConditions: timeCheckConditions,
                isSaved: false,
              });
            }}
          >
            <img src={require("../../images/queue/icon_delete.png")} alt="" />
            <p>{t("delete")}</p>
          </button>
        </div>
      );
    });

    return (
      <>
        <div className="setting-container">
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <h3>{t("estWaitingTime.title")}</h3>
              <h5>{t("estWaitingTime.description")}</h5>
            </div>
          </div>
          <hr />
          <div className="settingTbl" style={{ marginLeft: 0 }}>
            <div className="settingTblRow">
              <div className="header">
                <Tooltip
                  placement="bottom"
                  overlayClassName="setting-tooltip"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={t("estWaitingTime.displayGroupsAhead_tooltip")}
                >
                  {t("estWaitingTime.displayGroupsAhead")}
                  <i className="fa fa-question-circle-o" />
                </Tooltip>
              </div>
              <div className="text">
                <Switch
                  onChange={(checked) => {
                    this.setState({
                      showWaitingGroup: checked,
                    });
                  }}
                  checked={this.state.showWaitingGroup}
                />
              </div>
            </div>
            <br />
            <div className="settingTblRow">
              <div className="header">
                <Tooltip
                  placement="bottom"
                  overlayClassName="setting-tooltip"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={t("estWaitingTime.showEstWaitingTime_tooltip")}
                >
                  {t("estWaitingTime.showEstWaitingTime")}
                  <i className="fa fa-question-circle-o" />
                </Tooltip>
              </div>
              <div className="text">
                <Switch
                  onChange={(checked) => {
                    if (this.state.timeCheckConditions.length > 0) {
                      this.setState({
                        showWaitingTime: checked,
                        isSaved: false,
                      });
                    } else {
                      this.setState({
                        showWaitingTime: false,
                      });
                    }
                  }}
                  checked={this.state.showWaitingTime}
                />
              </div>
            </div>
            <br />
            <div className="settingTblRow">
              <div className="header">{t("estWaitingTime.rules")}</div>
              <div className="text">
                {timeCheckConditions}
                <button className="msBtn" onClick={this.addTimeCheckCondition}>
                  ＋ {t("estWaitingTime.rules")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <SettingsSaveButton handleSaved={this.submit} showDivider />
      </>
    );
  }
}

export default withTranslation("settings")(QueueSettingTime);
