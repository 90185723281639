import React, { useState, useCallback, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Space, Modal } from "antd";
import moment from "moment";
import _ from "lodash";

import { Table } from "./Table";

export const TablePicker = ({
  date,
  current,
  timelineTables,
  closeTimelineSeat,
  queueSeatedData,
  checkTable,
}) => {
  const { t } = useTranslation("bookingSystem");
  const [tableGroup, setTableGroup] = useState({});
  const [activeGroup, setActiveGroup] = useState("all");
  const [chosenTable, setChosenTable] = useState([]);

  useEffect(() => {
    let groupTables = {};

    timelineTables.forEach((t) => {
      const group = t.group;
      if (!groupTables.hasOwnProperty(group)) {
        groupTables[group] = [];
        groupTables[group].push(t);
      } else {
        groupTables[group].push(t);
      }
    });

    setTableGroup(groupTables);
  }, [timelineTables]);

  const changeGroup = (e) => {
    const tableGroupTxt = e.target.value;

    setActiveGroup(tableGroupTxt);
  };

  const selectTable = useCallback(
    (table) => {
      const newTable = _.cloneDeep(chosenTable);
      const findTableIndex = newTable.findIndex(
        (t) => t.table_number === table.table_number
      );

      if (findTableIndex === -1) {
        newTable.push(table);
      } else {
        newTable.splice(findTableIndex, 1);
      }

      setChosenTable(newTable);
    },
    [chosenTable]
  );

  const renderTableGroups = useCallback(() => {
    if (activeGroup === "all") {
      return Object.keys(tableGroup).map((group) => {
        return (
          <section key={group}>
            <div className="groupName">{group}</div>

            <div className="groupTableWrap">
              {tableGroup[group].map((table) => {
                const findTable = chosenTable.findIndex(
                  (t) => t.table_number === table.table_number
                );

                const newTimeline = _.sortBy(
                  table.timeline,
                  "service_start_time"
                ).filter((booking) => {
                  const bookingDate = moment(
                    new Date(booking.service_start_time)
                  ).format("YYYY/MM/DD");
                  const newDate = moment(new Date(date)).format("YYYY/MM/DD");
                  return bookingDate === newDate && booking.status !== "finish";
                });

                return (
                  <Table
                    key={table.table_number}
                    table={table}
                    timeline={newTimeline}
                    findTable={findTable}
                    current={current}
                    selectTable={selectTable}
                  />
                );
              })}
            </div>
          </section>
        );
      });
    } else {
      return (
        <section>
          <div className="groupName">{activeGroup}</div>

          <div className="groupTableWrap">
            {tableGroup[activeGroup].map((table) => {
              const findTable = chosenTable.findIndex(
                (t) => t.table_number === table.table_number
              );

              const newTimeline = _.sortBy(
                table.timeline,
                "booking_datetime"
              ).filter((booking) => {
                const bookingDate = moment(new Date(booking.date)).format(
                  "YYYY/MM/DD"
                );
                const newDate = moment(new Date(date)).format("YYYY/MM/DD");
                return bookingDate === newDate;
              });

              return (
                <Table
                  key={table.table_number}
                  table={table}
                  timeline={newTimeline}
                  findTable={findTable}
                  current={current}
                  selectTable={selectTable}
                />
              );
            })}
          </div>
        </section>
      );
    }
  }, [tableGroup, activeGroup, chosenTable, current, date, selectTable]);

  const renderGroupSelect = () => {
    return (
      <select onChange={(e) => changeGroup(e)}>
        <option key="all" value="all">
          {t("queueSystem:status.all")}
        </option>
        {Object.keys(tableGroup).map((group) => (
          <option key={group} value={group}>
            {group}
          </option>
        ))}
      </select>
    );
  };

  const renderQueueAttendance = useCallback(() => {
    const seatMaxAttendance = chosenTable.reduce(
      (prev, cur) => prev + cur.max_seat,
      0
    );

    const chosenTableStyle = chosenTable.length > 0 ? "chosenTableStyle" : "";
    return (
      <Trans
        i18nKey="bookingSystem:timeline.queueTablePicker"
        values={{
          peopleCount: queueSeatedData.people_count,
          tableCount: chosenTable.length,
          maxCapacity: seatMaxAttendance,
        }}
        components={{
          countStyle: (
            <span className={`attendanceStyle ${chosenTableStyle}`} />
          ),
        }}
      />
    );
  }, [chosenTable, queueSeatedData]);

  return (
    <Modal
      className="modal-base modal-xl timelineSeatPanel-modal"
      destroyOnClose
      centered
      maskClosable={false}
      closable={false}
      width={640}
      title={
        <Space direction="vertical" size={4}>
          {renderQueueAttendance()}
          <div className="text-small color-primary">
            {t("timeline.bookingDate")}
            {current}
          </div>
        </Space>
      }
      visible
      footer={
        <Space size={10}>
          <button
            className="button-common button-secondary"
            onClick={closeTimelineSeat}
          >
            {t("settings:cancel")}
          </button>
          <button
            disabled={chosenTable.length === 0}
            className="button-common button-primary"
            onClick={() => checkTable(chosenTable)}
          >
            {t("settings:ok")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
    >
      <div className="timelineSeatPanel__body">
        {renderGroupSelect()}
        {renderTableGroups()}
      </div>
    </Modal>
  );
};
