import React from 'react';
import SettingsSaveButton from '../commons/SettingsSaveButton';
import { useTranslation } from "react-i18next";

function UserProfile({
  userId,
  name,
  jobTitle,
  showError,
  handleInputChange,
  submit
}) {
  const { t } = useTranslation("settings");
  return (
    <div className='userBLock'>
      <div className='title'>{t('userPanel.userProfile')}</div>
      <div className='row__input'>
        <div className="subtitle">{t('userPanel.account')}</div>
        <input type="text" value={userId} disabled />
      </div>
      <div className='row__input'>
        <div className="subtitle">{t('userPanel.name')}*</div>
        <input
          type="text"
          name="name"
          className={`${showError ? 'input_error' : ''}`}
          placeholder={t('userPanel.placeholder_name')}
          value={name}
          onChange={handleInputChange}
        />
        { showError && <div className='text_error'>{t('userPanel.pls_userNameEmpty')}</div> }
      </div>
      <div className='row__input'>
        <div className="subtitle">{t('userPanel.jobTitle')}</div>
        <input
          type="text"
          name="job_title"
          placeholder={t('userPanel.placeholder_jobTitle')}
          value={jobTitle}
          onChange={handleInputChange}
        />
      </div>
      <SettingsSaveButton handleSaved={submit} showDivider/>
    </div>
  )
}

export default UserProfile