import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { CouponStateContext } from "../MenuCouponContext/MenuCouponContext";
import moment from "moment";
import { useTranslation } from "react-i18next";

const PreviewPopup = () => {
  const { t } = useTranslation("couponSystem");
  const auth = useSelector((state) => state.auth);

  const { CouponState } = useContext(CouponStateContext);
  const { previewInfo } = CouponState;

  const renderPreviewCardImg = () => {
    if (previewInfo.thumbnailURL !== "") {
      return (
        <div
          className="previewCardImg defaulImg"
          style={{ backgroundImage: `url(${previewInfo.thumbnailURL})` }}
        />
      );
    }

    return <div className="previewCardImg defaulImg" />;
  };

  const renderAppCouponImg = () => {
    if (previewInfo.coverURL !== "") {
      return (
        <div className="previewCardImg defaulImg">
          <img src={previewInfo.coverURL} alt="" />
        </div>
      );
    }

    return <div className="previewCardImg defaulImg" />;
  };

  const renderEndTime = () => {
    if (previewInfo.endDateTime && previewInfo.publishDateTime)
      return (
        <p>
          {moment(new Date(previewInfo.endDateTime)).format("YYYY-MM-DD HH:mm")}
        </p>
      );
  };

  const renderAttention = () => {
    if (previewInfo.description) {
      return <div className="description">{previewInfo.description}</div>;
    }
  };

  const renderApplyType = () => {
    if (previewInfo.countLimited === 1) {
      return (
        <div className="block">
          <div className="applyCount">
            <div className="applyCountRest">
              <p>{t('previewContent.remaining')}</p>
              <span>
                {previewInfo.voucherCount - previewInfo.exchangedCount}
              </span>
            </div>
            <div className="applyCountTaken">
              <p>{t('previewContent.redeemed')}</p>
              <span>{previewInfo.exchangedCount}</span>
            </div>
          </div>
          <p className="note">
            {t('previewContent.redeemNotice')}
          </p>
        </div>
      );
    }
  };

  return (
    <div className="previewPopup">
      <div className="menuCouponPopupCard__body">
        <div>
          <h3>{t("previewContent.card")}</h3>
          <div className="previewCard">
            {renderPreviewCardImg()}

            <div className="previewCardContent">
              <h2>{previewInfo.title}</h2>
              <div>
                <p>{previewInfo.item}</p>
                <p>{auth.shop.display_name}</p>
                {renderEndTime()}
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3>{t("previewContent.content")}</h3>
          <div className="appPreview">
            <div className="appPreviewContent">
              <h3>{previewInfo.title}</h3>

              <div>
                <div className="previewCoupon">
                  {renderAppCouponImg()}
                  <div>
                    <p className="storeName">{auth.shop.display_name}</p>

                    <h2>{previewInfo.title}</h2>

                    <div className="block">
                      <p className="previewCouponTitle">
                        {t("previewContent.couponContent")}
                      </p>
                      <p>{previewInfo.item}</p>
                    </div>

                    <div className="block">
                      <p className="previewCouponTitle">
                        {t("previewContent.expirationDate")}
                      </p>
                      {renderEndTime()}
                    </div>
                    {renderApplyType()}
                    <div className="attentionWrap">
                      <div className="block">
                        <p className="previewCouponTitle">
                          {t("previewContent.officialReminder")}
                        </p>
                        <ol>
                          <li>{t("previewContent.officialContent_1")}</li>
                          <li>{t("previewContent.officialContent_2")}</li>
                          <li>{t("previewContent.officialContent_3")}</li>
                        </ol>
                      </div>
                      <div className="block">
                        <p className="previewCouponTitle">
                          {t("previewContent.notificationOfCoupon")}
                        </p>
                        {renderAttention()}
                      </div>
                    </div>
                    <div className="storeInfo">
                      <p className="previewCouponTitle">
                        {t("previewContent.merchantInformation")}
                      </p>
                      <p className="name">{auth.shop.display_name}</p>
                      <p className="address">{auth.shop.address}</p>
                      <p className="tel">{auth.shop.phone_number}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPopup;
