import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Modal, DatePicker } from "antd";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { CustomerContext } from "../../crmContext/CustomerContext";
import {
  createCrmCustomer,
  updateCrmCustomer,
  updateCrmCustomerTags,
  getCrmCustomerTags,
} from "../../api/CrmCustomerApi";
import useBlockedList from "../../utils/useBlockedList";
import ConfirmBlockedCustomerModal from "./ConfirmBlockedCustomerModal";
import CheckModal from "../../../popup/CheckModal";
import { EMAIL_REGEX, DIGITS_ONLY_REGEX } from "../../../../utils/regex";
import { getTagsTranslationKey } from "../../utils/data";

const defaultPhonePlaceholder = "0912345678";

const CrmCustomerEditorModal = ({ load, setShowCustomerDeleteModal }) => {
  const { t } = useTranslation("customer");
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const { customerInfo, tags, isSaved, showCancelEditModal } = CustomerState;
  const {
    id,
    last_name,
    gender,
    birthday,
    phone_number,
    company,
    company_id,
    company_tele,
    email,
    note,
    tags_checked,
  } = customerInfo;

  const [data, setData] = useState({
    last_name,
    gender,
    birthday,
    phone_number,
    email,
    note,
    company,
    company_id,
    company_tele,
  });
  const [displayChecked, setDisplayChecked] = useState(tags_checked);
  // const [ displayTags, setDisplayTags ] = useState([]);
  // const [ searchTag, setSearchTag ] = useState(''); // 搜尋標記

  const [isBlocked, setIsBlocked] = useState(false);
  const [blockedList, setBlockedList] = useState([]);
  const [blockedNumberError, setBlockedNumberError] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [showConfirmBlockedListModal, setShowConfirmBlockedListModal] =
    useState(false);

  const customerNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const emailRef = useRef(null);

  const { response } = useBlockedList();

  useEffect(() => {
    const getTags = async () => {
      try {
        const { data } = await getCrmCustomerTags();
        // let tagsOptions = [];
        // data.customer_tags.map((tag) => {
        //   tagsOptions.push({
        //     label: tag.description,
        //     value: `${tag.id}`
        //   })
        // })
        // setDisplayTags(tagsOptions);
        CustomerStateDispatch({ type: "getTags", tags: data.customer_tags });
      } catch (err) {
        console.log("get tags err ----------", err);
      }
    };

    getTags();
  }, [CustomerStateDispatch]);

  useEffect(() => {
    if (response !== null) {
      setBlockedList(response);

      if (id && response.includes(phone_number)) {
        setIsBlocked(true);
      }
    }
  }, [response, id, phone_number]);

  // create tag style
  // useEffect(() => {
  //   const dropdownOption = document.getElementsByClassName('ant-select-item-option-content')[0];
  //   let allTags = displayChecked.concat(displayTags.map((tag) => tag.label));
  //   let tagIdx = allTags.indexOf(searchTag);

  //   if(dropdownOption) dropdownOption.classList.remove('create_tag');

  //   if(searchTag.length !== 0 && tagIdx === -1) {
  //     dropdownOption.classList.add('create_tag');
  //   }
  // }, [ displayChecked, displayChecked, searchTag ]);

  const cancelPopup = () => {
    if (isSaved) {
      CustomerStateDispatch({ type: "cancelPopup" });

      if (!id) CustomerStateDispatch({ type: "cleanCustomerInfo" });
    } else {
      CustomerStateDispatch({ type: "setCancelEditModal", show: true });
    }
  };

  const validateData = () => {
    const errorPhonePlaceholder = t(
      "bookingSystem:addBooking.placeholder_phone"
    );

    const setError = (field, ref, newPlaceholder, message) => {
      error.push(field);
      ref.current.classList.add("input_error_empty");
      if (newPlaceholder) ref.current.placeholder = newPlaceholder;
      if (message) window.app.alert.setMessage(message, "error");
    };

    const clearError = (field, ref, placeholder) => {
      const index = error.indexOf(field);
      if (index !== -1) error.splice(index, 1);
      ref.current.classList.remove("input_error_empty");
      if (placeholder) ref.current.placeholder = placeholder;
    };

    let error = [];

    if (data.last_name.trim() === "") {
      setError(
        "last_name",
        customerNameRef,
        "",
        t("editCustomerPopup.pls_enterNameAndPhone")
      );
    } else {
      clearError("last_name", customerNameRef, "");
    }

    if (data.phone_number.trim() === "") {
      setError(
        "phone_number",
        phoneNumberRef,
        errorPhonePlaceholder,
        t("editCustomerPopup.pls_enterNameAndPhone")
      );
    } else {
      clearError("phone_number", phoneNumberRef, defaultPhonePlaceholder);
    }

    if (data.email.trim() !== "" && !EMAIL_REGEX.test(data.email)) {
      setError("email", emailRef, "", "");
      setEmailFormatError(true);
    } else {
      clearError("email", emailRef, "");
      setEmailFormatError(false);
    }

    if (error.length !== 0) return;
    if (
      blockedNumberError ||
      (isBlocked && phone_number !== data.phone_number)
    ) {
      return setShowConfirmBlockedListModal(true);
    }

    submit();
  };

  const submit = async () => {
    if (id) {
      try {
        await updateCrmCustomer(id, data);
        await tagsSubmit(id, "edit");
        CustomerStateDispatch({ type: "cancelPopup" });
        CustomerStateDispatch({ type: "cleanCustomerInfo" });
        CustomerStateDispatch({ type: "setIsSaved", isSaved: true });

        load(false);
      } catch (error) {
        window.app.alert.setMessage(error.response.data.message, "error");
      }
    } else {
      try {
        const result = await createCrmCustomer(data);
        await tagsSubmit(result.data.meta, "create");
        CustomerStateDispatch({ type: "cancelPopup" });
        CustomerStateDispatch({ type: "cleanCustomerInfo" });
        CustomerStateDispatch({ type: "setIsSaved", isSaved: true });

        load(false);
      } catch (error) {
        if (error.response.status === 422) {
          window.app.alert.setMessage(
            t("editCustomerPopup.pls_customerAlreadyExist"),
            "error"
          );
        } else {
          window.app.alert.setMessage(error.response.data.message, "error");
        }
      }
    }
  };

  const tagsSubmit = async (id, type) => {
    let msg =
      type === "create"
        ? t("editCustomerPopup.addSuccess")
        : t("editCustomerPopup.editSuccess");
    const checkedTags = {
      checked: displayChecked,
      id: id,
    };

    try {
      await updateCrmCustomerTags(checkedTags).then((res) =>
        window.app.alert.setMessage(msg, "done")
      );

      if (typeof load !== "undefined") {
        load(false);
      }

      CustomerStateDispatch({ type: "cancelPopup" });
      CustomerStateDispatch({ type: "cleanCustomerInfo" });
      CustomerStateDispatch({ type: "setIsSaved", isSaved: true });
    } catch (err) {
      window.app.alert.setMessage(err.response.data.message, "error");
    }
  };

  const handleTagChange = useCallback((id) => {
    setDisplayChecked((prevChecked) => {
      if (prevChecked.includes(id)) {
        return prevChecked.filter((tagId) => tagId !== id);
      } else {
        return [...prevChecked, id];
      }
    });

    CustomerStateDispatch({ type: "setIsSaved", isSaved: false });
  }, [CustomerStateDispatch]);

  const renderTags = () => {
    return tags.map((tag) => {
      return (
        <label key={tag.id}>
          <input
            type="checkbox"
            value={tag.id}
            checked={displayChecked.includes(tag.id)}
            onChange={() => handleTagChange(tag.id)}
          />
          <span>{t(getTagsTranslationKey(tag.description))}</span>
        </label>
      );
    });
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const isNumericInput =
      value.trim().length > 0 ? DIGITS_ONLY_REGEX.test(value.trim()) : true;

    let newData = _.cloneDeep(data);

    if (name === "last_name")
      customerNameRef.current.classList.remove("input_error_empty");
    if (name === "email")
      emailRef.current.classList.remove("input_error_empty");
    if (
      (name === "phone_number" || name === "company_tele") &&
      !isNumericInput
    ) {
      return;
    }

    if (name === "phone_number") {
      const isBlockedNumber = blockedList.includes(value);

      if (isBlockedNumber) {
        setBlockedNumberError(true);
        phoneNumberRef.current.classList.add("input_error_empty");
      } else {
        setBlockedNumberError(false);
        phoneNumberRef.current.classList.remove("input_error_empty");
        phoneNumberRef.current.placeholder = defaultPhonePlaceholder;
      }
    }

    newData[name] = value;
    setData(newData);
    CustomerStateDispatch({ type: "setIsSaved", isSaved: false });
  };

  const renderDatePicker = () => {
    const disabledDate = (current) => {
      // Can not select days after today
      const time = moment().endOf("day").subtract(1, "days");
      return current && current > time;
    };

    const handleDateChange = (date, dateString) => {
      let newData = _.cloneDeep(data);
      newData["birthday"] = dateString;
      setData(newData);
      CustomerStateDispatch({ type: "setIsSaved", isSaved: false });
    };

    return (
      <div className="customer_datepicker">
        <DatePicker
          showToday={false}
          defaultValue={data.birthday ? moment(data.birthday) : null}
          dateFormat="YYYY-MM-DD"
          disabledDate={disabledDate}
          onChange={handleDateChange}
          placeholder="YYYY-MM-DD"
          inputReadOnly
        />
      </div>
    );
  };

  return (
    <Modal
      title={
        id
          ? t("editCustomerPopup.editCustomer")
          : t("editCustomerPopup.addCustomer")
      }
      visible
      centered
      closable={false}
      className="crmCustomerEditorModal"
      footer={
        <div className="btn-action">
          {id && (
            <button
              type="button"
              className="btn-delete"
              onClick={() => setShowCustomerDeleteModal(true)}
            >
              {t("editCustomerPopup.deleteCustomer")}
            </button>
          )}
          <div>
            <button
              type="button"
              className="button-common button-secondary"
              onClick={cancelPopup}
            >
              {t("settings:cancel")}
            </button>
            <button
              type="button"
              className="button-common button-primary"
              onClick={validateData}
            >
              {t("settings:save")}
            </button>
          </div>
        </div>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className="wrap">
        <div className="customer_basic">
          <h4>
            {id
              ? t("editCustomerPopup.editCustomer")
              : t("editCustomerPopup.addCustomer")}
          </h4>
          <div className="customer_row">
            <div className="title">{t("editCustomerPopup.name")}</div>
            <input
              type="text"
              ref={customerNameRef}
              name="last_name"
              placeholder={t("bookingSystem:addBooking.placeholder_name")}
              value={data.last_name}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="customer_row">
            <div className="title">{t("editCustomerPopup.gender")}</div>
            <select
              name="gender"
              value={data.gender}
              onChange={(e) => handleInputChange(e)}
              placeholder=""
            >
              <option value="other">{t("editCustomerPopup.other")}</option>
              <option value="male">{t("editCustomerPopup.male")}</option>
              <option value="female">{t("editCustomerPopup.female")}</option>
            </select>
          </div>
          <div className="customer_row">
            <div className="title">{t("editCustomerPopup.phone")}</div>
            <input
              type="text"
              ref={phoneNumberRef}
              name="phone_number"
              placeholder="0912345678"
              value={data.phone_number}
              onChange={(e) => handleInputChange(e)}
            />
            {blockedNumberError && (
              <div className="text text_error">
                {t("bookingSystem:addBooking.isBlockedPhone")}
              </div>
            )}
            {isBlocked && (
              <div className="text text_notice">
                {t("editCustomerPopup.notice_existBlockedNumber")}
              </div>
            )}
          </div>
          <div className="customer_row">
            <div className="title">Email</div>
            <input
              type="text"
              ref={emailRef}
              name="email"
              placeholder="abc@example.com"
              value={data.email}
              onChange={(e) => handleInputChange(e)}
            />
            {emailFormatError && (
              <div className="text text_error">
                {t("bookingSystem:addBooking.pls_validEmail")}
              </div>
            )}
          </div>
          <div className="customer_row customer_row_checkbox">
            <div className="title">{t("info.tags")}</div>
            {renderTags()}
          </div>
          {/* <div className='customer_row'>
            <div className='title'>快速標記</div>
            <Space
              style={{
                width: '100%',
              }}
              direction="vertical"
            >
              <Select
                mode="tags"
                optionFilterProp="label"
                className='customer_select_tags'
                style={{
                  width: '100%',
                }}
                placeholder="可自訂標記將顧客快速分類"
                notFoundContent='目前尚無任何標記'
                value={displayChecked}
                onSearch={(e) => setSearchTag(e)}
                options={displayTags}
                onChange={handleTagChange}
                dropdownClassName='customer_select_tags_dropdown'
              />
            </Space>
            <div className='text'>可選擇既有標記或快速建立標記；如需修改或刪除標記，請至「設定」。</div>
          </div> */}
          <hr />
        </div>
        <div className="customer_advanced">
          <h5>{t("editCustomerPopup.advancedOptional")}</h5>
          <div className="customer_row">
            <div className="title">{t("info.birth")}</div>
            {renderDatePicker()}
          </div>
          <div className="customer_row">
            <div className="title">{t("info.companyName")}</div>
            <input
              type="text"
              name="company"
              placeholder="MENU店+"
              onChange={(e) => handleInputChange(e)}
              value={data.company || ""}
            />
          </div>
          <div className="customer_row">
            <div className="title">{t("info.companyPhone")}</div>
            <input
              type="text"
              name="company_tele"
              placeholder="0212345678"
              onChange={(e) => handleInputChange(e)}
              value={data.company_tele || ""}
            />
          </div>
          <div className="customer_row">
            <div className="title">{t("info.texId")}</div>
            <input
              type="text"
              name="company_id"
              placeholder="66668888"
              onChange={(e) => handleInputChange(e)}
              value={data.company_id || ""}
            />
          </div>
          <div className="customer_row">
            <div className="title">{t("info.remarks")}</div>
            <textarea
              name="note"
              placeholder={t("editCustomerPopup.placeholder_remarks")}
              onChange={(e) => handleInputChange(e)}
              value={data.note}
            />
          </div>
        </div>
      </div>

      {showCancelEditModal && <CustomerEditCancelPopup />}
      {showConfirmBlockedListModal && (
        <ConfirmBlockedCustomerModal
          type={id ? "edit" : "create"}
          isBlocked={isBlocked}
          setShowConfirmBlockedListModal={setShowConfirmBlockedListModal}
          submit={submit}
        />
      )}
    </Modal>
  );
};

const CustomerEditCancelPopup = () => {
  const { t } = useTranslation("customer");
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const { customerInfo } = CustomerState;
  const { id } = customerInfo;

  const continueEdit = () => {
    CustomerStateDispatch({ type: "setCancelEditModal", show: false });
  };

  const handleSubmit = () => {
    CustomerStateDispatch({ type: "setIsSaved", isSaved: true });
    CustomerStateDispatch({ type: "cancelPopup" });
  };

  return (
    <CheckModal
      title={`${
        id ? t("popup.discardEdit_title") : t("popup.discardAdd_title")
      }`}
      content={
        id
          ? t("popup.discardEditCustomer_content")
          : t("popup.discardAddCustomer_content")
      }
      submit={handleSubmit}
      cancel={continueEdit}
    />
  );
};

export default CrmCustomerEditorModal;
