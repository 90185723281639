import React, { Fragment, useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  // customerSortOption,
  messageFilterOption,
  messageSortOption,
  messageStatusTab,
} from "../utils/data";

import { MessageContext } from "../crmContext/MessageContext";
import { CustomerContext } from "../crmContext/CustomerContext";

import { Select, Space } from "antd";
const { Option } = Select;

const CrmTab = () => {
  const { t } = useTranslation("customer");
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const {
    // customerSort,
    showCustomerFilterBar,
    customerFilterNum,
    // filterCustomers,
    listCount,
  } = CustomerState;
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const {
    mode,
    messageFilter,
    messageSort,
    messageStatus,
    searchInputText,
    searchMode,
  } = MessageState;

  useEffect(() => {
    MessageStateDispatch({
      type: "switchMessageStatus",
      messageStatus: "sent",
      searchMode: false,
    });
  }, [MessageStateDispatch, mode]);

  const handleMessageFilter = useCallback(
    (value) => {
      let index = Object.values(messageFilterOption).indexOf(value);
      let filter = Object.keys(messageFilterOption)[index];

      if (filter === messageFilter) return;

      if (searchInputText.length > 0 && searchMode) {
        MessageStateDispatch({
          type: "switchMessageStatus",
          messageStatus,
          searchMode: true,
        });
      } else {
        MessageStateDispatch({
          type: "switchMessageStatus",
          messageStatus,
          searchMode: false,
        });
      }

      MessageStateDispatch({ type: "setMessageFilter", messageFilter: filter });
      MessageStateDispatch({ type: "setStatusMessagesNum" });
    },
    [
      messageFilter,
      messageStatus,
      searchInputText,
      searchMode,
      MessageStateDispatch,
    ]
  );

  const handleMessageSort = useCallback(
    (value) => {
      let index = Object.values(messageSortOption).indexOf(value);
      let sort = Object.keys(messageSortOption)[index];

      if (sort === messageSort) return;

      if (searchInputText.length > 0 && searchMode) {
        MessageStateDispatch({ type: "setSearchResSort", messageSort: sort });
        MessageStateDispatch({
          type: "switchMessageStatus",
          messageStatus,
          searchMode: true,
        });
      } else {
        MessageStateDispatch({ type: "setMessageSort", messageSort: sort });
        MessageStateDispatch({
          type: "switchMessageStatus",
          messageStatus,
          searchMode: false,
        });
      }

      MessageStateDispatch({ type: "setMessageFilter", messageFilter });
    },
    [
      messageSort,
      messageStatus,
      messageFilter,
      searchInputText,
      searchMode,
      MessageStateDispatch,
    ]
  );

  const handleMessageType = useCallback(
    (value) => {
      if (value === messageStatus) return;

      if (searchInputText.length > 0 && searchMode) {
        MessageStateDispatch({
          type: "switchMessageStatus",
          messageStatus: value,
          searchMode: true,
        });
      } else {
        MessageStateDispatch({
          type: "switchMessageStatus",
          messageStatus: value,
          searchMode: false,
        });
      }

      MessageStateDispatch({ type: "setMessageFilter", messageFilter });
    },
    [
      messageStatus,
      messageFilter,
      searchInputText,
      searchMode,
      MessageStateDispatch,
    ]
  );

  const handleCustomerFilterBar = () => {
    let value = !showCustomerFilterBar;

    CustomerStateDispatch({ type: "setCustomerFilterBar", show: value });
    CustomerStateDispatch({
      type: "setSearchCustomerText",
      searchCustomerText: "",
    });
    CustomerStateDispatch({
      type: "setSearchCustomerResult",
      searchCustomers: [],
      searchCustomerMode: false,
      listPage: 1,
      listCount: customerFilterNum.length === 0 ? null : listCount,
    });
  };

  // const handleCustomerSort = (value) => {
  //   let index = Object.values(customerSortOption).indexOf(value);
  //   let sort = Object.keys(customerSortOption)[index];

  // 	if (sort === customerSort) return;

  //   // if(searchCustomerText.length > 0 && searchCustomerMode) {
  //   //   CustomerStateDispatch({ type: 'setSearchResSort', customerSort: sort });
  //   // } else {
  //   //   CustomerStateDispatch({ type: 'setCustomerSort', customerSort: sort });
  //   // }

  //   // 補 filter

  //   CustomerStateDispatch({ type: 'setCustomerSort', customerSort: sort });
  // }

  const renderTab = () => {
    if (mode === "customerList") {
      return (
        <div className="crmCustomer_tab">
          <div className="block_filter">
            <button
              className={`btn_filter btn-effect ${
                showCustomerFilterBar
                  ? "active"
                  : customerFilterNum !== 0
                  ? "expand"
                  : ""
              }`}
              onClick={handleCustomerFilterBar}
            >
              <Space size={4}>
                {customerFilterNum !== 0 ? (
                  <span className="btn_filter_num">{customerFilterNum}</span>
                ) : showCustomerFilterBar ? (
                  <div className="icon-normal filter-icon_green" />
                ) : (
                  <div className="icon-normal filter-icon" />
                )}
                <span className="btn_filter_txt">{t("filterBar.filter")}</span>
              </Space>
            </button>

            {customerFilterNum !== 0 ? (
              <span className="res_filter">
                {t("filterBar.result", { count: listCount })}
              </span>
            ) : null}
          </div>
        </div>
      );
    } else {
      return (
        <div className="crmMessage_tab">
          <div className="msgSelect">
            <Select
              bordered={false}
              defaultValue={messageFilterOption[messageFilter]}
              onChange={(value) => handleMessageFilter(value)}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {Object.keys(messageFilterOption).map((option, index) => {
                return (
                  <Option key={index} value={messageFilterOption[option]}>
                    {t(`messages:list.${option}`)}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="crmMessage_type_tab">
            <div className="dateModeSwitcher-tab">
              {Object.keys(messageStatusTab).map((option, index) => {
                return (
                  <div
                    key={index}
                    className={option === messageStatus ? "active" : ""}
                    onClick={() => handleMessageType(option)}
                  >
                    {t(`messages:list.status.${option}`)}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="msgSelect">
            <Select
              bordered={false}
              defaultValue={messageSortOption[messageSort]}
              onChange={(value) => handleMessageSort(value)}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {Object.keys(messageSortOption).map((option, index) => {
                return (
                  <Option key={index} value={messageSortOption[option]}>
                    {t(`messages:list.sort.${option}`)}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
      );
    }
  };

  return <Fragment>{renderTab()}</Fragment>;
};

export default CrmTab;
