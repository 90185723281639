import React from 'react';
import $ from 'jquery';
import { browserHistory } from 'react-router';
import { config } from '../../utils/config'
import { withTranslation } from 'react-i18next';
import { Space } from 'antd';

import ShopFormBind from './ShopFormBind';
import ShopFormEdit from './ShopFormEdit';
import MenuUserInfo from './menu_user/MenuUserInfo';
import ShopFormNewModal from './ShopFormNewModal';
import { handleError } from '../../libs/handler';

import { getMenuUserInfo } from './menu_user/MenuUserApi';
import BookingSystemApi from '../booking_settings/BookingSystemApi';

import GoogleReservationRequiredCheckModal from '../booking_settings/bookingSettingsPopup/GoogleReservationRequiredCheckModal';

const API = new BookingSystemApi();

var createReactClass = require('create-react-class');
export default withTranslation('settings')(createReactClass({
	load(disableMessage) {
		const { t } = this.props;
		if (typeof window.app.alert !== 'undefined' && !disableMessage) {
			window.app.alert.setMessage(t('status.loading'), 'info');
		}
		$.ajax({
			url: window.domain + this.props.url,
			dataType: 'json',
			xhrFields: { withCredentials: true },
			success: function(data) {
				this.setState({ data: data.shop, loading: false });

				this.setState({
					updateData: {
						display_name_update: data.shop.display_name,
						branch_update: data.shop.branch,
						phone_number_update: data.shop.phone_number,
						address_update: data.shop.address,
						website_url_update: data.shop.website_url
					}
				});

				if(!disableMessage) window.app.alert.setMessage(t('status.loadingComplete'), 'done');
			}.bind(this),
			error: function(xhr) {
				handleError(xhr);
			}
		});
	},
	getBookingSettings() {
		API.getBookingSetting()
			.then(res => {
				const { google_reservation } = res;
				this.setState({
					google_reservation: google_reservation
				})
			})
			.catch((error) => console.log('shop getBookingSetting() error', error))
	},
	getMenuUserBindStatus() {
		getMenuUserInfo()
			.then(res => {
				const { data } = res;

				if(data.menu_user.bind_menu) {
					this.setState({
						menuUserInfo: {
							displayName: data.menu_user.name,
							thumbnailURL: data.menu_user.thumbnailURL,
							menuUserId: data.menu_user.menu_user_id
						}
					})
				} else {
					this.setState({
						menuUserInfo: {
							displayName: '',
							thumbnailURL: '',
							menuUserId: ''
						}
					})
				}
			})
			.catch(err => {
				this.setState({
					menuUserInfo: {
						displayName: '',
						thumbnailURL: '',
						menuUserId: ''
					}
				})
				console.log('getMenuUserInfo() error', err)
			})
	},
	getDefaultProps() {
		return {
			url: '/dashboard/shops',
			upload_url: '/dashboard/upload',
			remove_url: '/dashboard/remove'
		};
	},
	getInitialState() {
		return {
			data: {
				display_name: '',
				branch: '',
				phone_number: '',
				address: '',
				website_url:''
			},
			updateData: {
				display_name_update: '',
				branch_update: '',
				phone_number_update: '',
				address_update: '',
				website_url_update:''
			},
			select: {
				objectId: '',
				name: '',
				branch: '',
				PhoneNumber: '',
				address: '',
				website_url: ''
			},
			query: [],
			empty: false,
			renderNew: false,
			loading: true,
			redirectPage:  browserHistory.getCurrentLocation().state? browserHistory.getCurrentLocation().state.source : '',
			shopBindModalVisible: false,
			editShopInfo: false,
			menuUserInfo: {
				displayName: '',
				thumbnailURL: '',
				menuUserId: ''
			},
			google_reservation: false,
			showGoogleReservationRequiredCheckModal: false
		};
	},
	componentDidMount() {
		console.log("browserHistory.getCurrentLocation()---", browserHistory.getCurrentLocation())

		this.load();
		this.getMenuUserBindStatus();
		this.getBookingSettings();
	},
	onDrop(files) {
		const { t } = this.props;
		window.app.alert.setMessage(t('status.uploading'), 'info');
		var data = new FormData();
		$.each(files, function(key, value) {
			data.append(key, value);
		});
		data.append('type', 'logo');
		$.ajax({
			url: window.domain + this.props.upload_url,
			dataType: 'json',
			type: 'POST',
			data: data,
			processData: false,
			contentType: false,
			xhrFields: { withCredentials: true },
			success: function(data) {
				window.app.alert.setMessage(data.message, 'done');
				window.location.reload();
			},
			error: function(xhr, status, err) {
				window.app.alert.setMessage(xhr.responseJSON.message, 'error');
			}
		});
	},
	handleRemove() {
		window.app.alert.setMessage('移除中...', 'tip');
		$.ajax({
			url: window.domain + this.props.remove_url,
			dataType: 'json',
			data: { type: 'logo' },
			type: 'POST',
			xhrFields: { withCredentials: true },
			success: function(data) {
				window.app.alert.setMessage(data.message, 'tip');
			},
			error: function(xhr, status, err) {
				window.app.alert.setMessage(xhr.responseJSON.message, 'error');
			}
		});
	},
	handleInputChange(e) {
		var new_state = this.state;
		new_state['updateData'][e.target.name] = e.target.value;
		this.setState(new_state);
	},
	handleNewInputChange(e) {
		var new_state = this.state;
		new_state['data'][e.target.name] = e.target.value;
		this.setState(new_state);
	},
	handleQuery(data) {
		$.ajax({
			url: window.domain + this.props.url + '/query',
			dataType: 'json',
			data: { query: data },
			xhrFields: { withCredentials: true }
		})
			.done(
				function(data) {
					if (data.results.length > 0) {
						this.setState({ query: data.results, empty: false });
					} else {
						this.setState({ empty: true });
					}
				}.bind(this)
			)
			.fail(function(xhr) {
				console.log(xhr);
			});
	},
	handleSelect(data) {
		$.ajax({
			url: window.domain + this.props.url + '/select',
			dataType: 'json',
			data: { object_id: data },
			xhrFields: { withCredentials: true }
		})
			.done(
				function(data) {
					this.setState({ query: [], select: data.select, shopBindModalVisible: true });
				}.bind(this)
			)
			.fail(function(xhr) {
				console.log(xhr);
			});
	},
	handleCancelSelect() {
		this.setState({ query: [], select: {}, shopBindModalVisible: false });
	},
	handleImageChange(e) {
		const { t } = this.props;
		e.preventDefault();

		let reader = new FileReader();
		let file = e.target.files[0];
		if (file.size > 2097152) {
			window.app.alert.setMessage(t('maximumFileSize2MB_2', {number: 2}), 'error');
		} else {
			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result
				});
			};

			reader.readAsDataURL(file);
		}
	},
	handleSync(data) {
		$.ajax({
			url: window.domain + this.props.url + '/sync',
			dataType: 'json',
			data: { object_id: data },
			type: 'POST',
			xhrFields: { withCredentials: true }
		})
			.done(
				function(data) {
					this.load();
				}.bind(this)
			)
			.fail(function(xhr) {
				console.log(xhr);
			});
	},
	handleFormSwitch() {
		var renderNew = this.state.renderNew;
		this.setState({ renderNew: !renderNew });
	},
	checkSubmit() {
		if(this.state.google_reservation && this.state.updateData.address_update.trim() === '') {
			return this.setState({
				showGoogleReservationRequiredCheckModal: true
			})
		} else {
			this.handleSubmit();
		}
	},
	handleSubmit(newShop) {
		const { t } = this.props;
		window.app.alert.setMessage(t('status.updating'), 'info');
		this.setState({
			showGoogleReservationRequiredCheckModal: false
		})

		var fd = new FormData();
		if (this.state.file) {
			fd.append('shop[shop_logo]', this.state.file);
		}
		
		if(newShop === 'newShop') {
			fd.append('shop[display_name]', this.state.data.display_name || '');
			fd.append('shop[address]', this.state.data.address || '');
			fd.append('shop[phone_number]', this.state.data.phone_number || '');
			fd.append('shop[branch]', this.state.data.branch || '');
			fd.append('shop[website_url]', this.state.data.website_url || '');
		} else {
			fd.append('shop[display_name]', this.state.updateData.display_name_update|| '');
			fd.append('shop[address]', this.state.updateData.address_update || '');
			fd.append('shop[phone_number]', this.state.updateData.phone_number_update || '');
			fd.append('shop[branch]', this.state.updateData.branch_update || '');
			fd.append('shop[website_url]', this.state.updateData.website_url_update || '');
		}

		$.ajax({
			url: window.domain + this.props.url + '/' + this.state.data.id,
			dataType: 'json',
			data: fd,
			type: 'PATCH',
			processData: false, // Don't process the files
			contentType: false,
			xhrFields: { withCredentials: true },
			success: function(data) {
				window.app.alert.setMessage(data.message, 'done');

				this.setState({ editShopInfo: false, renderNew: false });
				this.load(true);

				if (newShop && this.state.redirectPage === 'coupon') {
					browserHistory.push({
						pathname: '/dashboard/menu_coupon'
					});
				}
			}.bind(this),
			error: function(xhr, status, err) {
				window.app.alert.setMessage(xhr.responseJSON.message, 'error');
			}
		});
	},
	handleEditShopInfo() {
		this.setState({ editShopInfo: true });
	},
	cancelEditShopInfo() {
		// 關閉編輯
		this.setState({ editShopInfo: false });
		// 還原輸入
		this.setState({ 
			updateData: {
				display_name_update: this.state.data.display_name,
				branch_update: this.state.data.branch,
				phone_number_update: this.state.data.phone_number,
				address_update: this.state.data.address,
				website_url_update: this.state.data.website_url
			}
		});
		// 清空 logo
		this.setState({
			file: '',
			imagePreviewUrl: ''
		});
	},
	addShopDefaultSrc(e) {
		e.onerror = null;
		e.target.src = require('../../images/menuUser/Profile_Picture.svg');
	},
	renderShopInfo() {
		const { t } = this.props;

		if (this.state.loading) {
			return <div />;
		} else {
			if(this.state.editShopInfo) {
				return (
					<ShopFormEdit
						data={this.state.data}
						updateData={this.state.updateData}
						onDrop={this.onDrop}
						onRemove={this.handleRemove}
						onInputChange={this.handleInputChange}
						onSubmit={this.checkSubmit}
						onImageChange={this.handleImageChange}
						file={this.state.file}
						imagePreviewUrl={this.state.imagePreviewUrl}
						cancelEdit={this.cancelEditShopInfo}
					/>
				);
			} else {
				return (
					<div className="shopForm">
						<div className="shopForm_block">
							<div className='shopForm_info_logo_block'>
								<img
									className="shopForm_info_logo"
									src={this.state.data.logo}
									onError={this.addShopDefaultSrc}
									alt=''
								/>
							</div>
	
							<div className='shopForm_info_block'>
								<div className="shopForm_info">
									<div className="shopForm_info_title shopForm_onlyView">{t('accountSettings.merchantName')}</div>
									<div className="shopForm_info_content">{ this.state.data.display_name || '-' }</div>
								</div>
								<div className="shopForm_info">
									<div className="shopForm_info_title shopForm_onlyView">{t('accountSettings.branch')}</div>
									<div className="shopForm_info_content">{ this.state.data.branch || '-' }</div>
								</div>
								<div className="shopForm_info">
									<div className="shopForm_info_title shopForm_onlyView">{t('accountSettings.phone')}</div>
									<div className="shopForm_info_content">{ this.state.data.phone_number || '-' }</div>
								</div>
								<div className="shopForm_info">
									<div className="shopForm_info_title shopForm_onlyView">{t('accountSettings.address')}</div>
									<div className="shopForm_info_content">{ this.state.data.address || '-' }</div>
								</div>
								<div className="shopForm_info">
									<div className="shopForm_info_title shopForm_onlyView">{t('accountSettings.website')}</div>
									<div className="shopForm_info_content">{ this.state.data.website_url || '-' }</div>
								</div>
							</div>
						</div>
					</div>
				);
			};
		}
	},
	renderShopForm() {
		const { t } = this.props;
		const shopId = this.state.data.parse_obj_id;
    const shopPage = config.restaurant_domain + '/' + shopId;

    const goShopPage = () => {
      window.open(shopPage, '_blank')
    };

		if (this.state.loading) {
			return <div />;
		} else {
			if (this.state.data.parse_obj_id) {
				return (
					<div className="menuRestaurantsLink">
						<div className="menuRestaurantsLink_title">{t('accountSettings.menu.linkOfMENU')}</div>
						<div className="menuRestaurantsLink_content">
							<div>{ shopPage }</div>
							<button onClick={goShopPage}>
								<span>{t('accountSettings.menu.open')}</span>
								<i className="fa fa-external-link" aria-hidden="true"></i>
							</button>
						</div>
					</div>
				);
			} else {
				if (this.state.renderNew) {
					return (
						<ShopFormNewModal
							data={this.state.data}
							onInputChange={this.handleNewInputChange}
							onFormSwitch={this.handleFormSwitch}
							onSubmit={() => {
								this.handleSubmit('newShop');
							}}
							onImageChange={this.handleImageChange}
							imagePreviewUrl={this.state.imagePreviewUrl}
						/>
					);
				} else {
					return (
						<ShopFormBind
							data={this.state.data}
							select={this.state.select}
							query={this.state.query}
							empty={this.state.empty}
							shopBindModalVisible={this.state.shopBindModalVisible}
							onQuery={this.handleQuery}
							onSelect={this.handleSelect}
							onCancelSelect={this.handleCancelSelect}
							onFormSwitch={this.handleFormSwitch}
							onSync={this.handleSync}
						/>
					);
				}
			}
		}
	},
	render() {
		const { t } = this.props;
		return (
      <>
        <div className="shopBox setting-container">
          <div className="shopBox_header">
            <div className="shopBox_title">
              <h3>{t("accountSettings.subTitle")}</h3>
              <h5>{t("accountSettings.description")}</h5>
            </div>
            <button>
              <span onClick={this.handleEditShopInfo}>{t("edit")}</span>
              <img src={require("../../images/menuUser/icon_pen.svg")} alt="" />
            </button>
          </div>
          <div className="shopInfo">
            <div>{this.renderShopInfo()}</div>
          </div>
          <hr />
          <div className="menuAccountSetting">
            <div className="shopForm_Title">
              <img
                src={require("../../images/menuCoupon/menuAppIcon.png")}
                alt=""
              />
              <div className="heading-2-responsive">
                {t("accountSettings.menu.title")}
              </div>
            </div>
            <div className="menuAccountCover">
              <img
                src={require("../../images/menuUser/Web_MENU_Cover.svg")}
                alt=""
              />
            </div>
            <MenuUserInfo
              menuUserInfo={this.state.menuUserInfo}
              getMenuUserBindStatus={this.getMenuUserBindStatus}
            />
          </div>
          <div className="menuRestaurantsSetting">
            <Space direction="vertical" size={8} className="shopBind_title">
              <div className="heading-3-responsive">
                {t("accountSettings.menu.linkToMENU")}
              </div>
              <div className="text-body color-subtitle">
                {t("accountSettings.menu.linkToMENU_note")}
              </div>
            </Space>
            <div>{this.renderShopForm()}</div>
          </div>
        </div>

        {this.state.showGoogleReservationRequiredCheckModal && (
          <GoogleReservationRequiredCheckModal
            address={
              this.state.updateData &&
              this.state.updateData.address_update.trim() === ""
            }
            setGoogleReservationRequiredCheckModal={() =>
              this.setState({ showGoogleReservationRequiredCheckModal: false })
            }
            submit={this.handleSubmit}
          />
        )}
      </>
    );
	}
}));
