import React, { useState, useCallback, useContext } from "react";
import _ from "lodash";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../../../crmContext/MessageContext";
import { MessageAddStateContext } from "../../../crmContext/MessageAddContext";

import EnableMenuCouponCard from "../EnableMenuCouponCard";

const InsertMenuCouponModal = () => {
  const { t } = useTranslation("messages");
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(
    MessageAddStateContext
  );
  const { couponLists, insertMenuCoupon, insertCoupons, messageSetting } =
    MessageAddState;
  const { content, cursorPosition, couponInfo, menuTalkContent } =
    messageSetting;
  const [error, setError] = useState(false);

  const confirm = useCallback(() => {
    if (couponLists.length === 0) {
      return MessageAddDispatch({ type: "cancelPopup" });
    }

    if (
      insertMenuCoupon === 0 ||
      insertMenuCoupon === undefined ||
      insertMenuCoupon === null
    ) {
      setError(true);
    } else {
      if (editType === "sms") {
        const menuCouponUrl = `https://menutaiwan.com/tw/coupon/${insertMenuCoupon}`;
        const insertContent =
          content.slice(0, cursorPosition) +
          menuCouponUrl +
          content.slice(cursorPosition);
        const newCursorPosition = cursorPosition + menuCouponUrl.length;

        MessageAddDispatch({
          type: "setMessageContent",
          content: insertContent,
        });
        MessageAddDispatch({
          type: "setSmsContentCursorPosition",
          cursorPosition: newCursorPosition,
        });
        MessageAddDispatch({
          type: "setInsertMenuCoupon",
          insertMenuCoupon: 0,
          couponInfo: {},
        });
        MessageAddDispatch({ type: "cancelPopup" });
      } else if (editType === "menuTalk" && couponInfo) {
        const newMenuTalkContent = _.cloneDeep(menuTalkContent);
        const newInsertCoupons = _.cloneDeep(insertCoupons);
        let coupon = {};

        coupon.type = "voucher";
        coupon.voucherId = couponInfo.id;
        coupon.endDateTime = couponInfo.endDateTime;
        newMenuTalkContent.push(coupon);
        newInsertCoupons.push(coupon);

        MessageAddDispatch({
          type: "setMenuTalkContent",
          menuTalkContent: newMenuTalkContent,
        });
        MessageAddDispatch({
          type: "setMenuTalkInsertCoupons",
          insertCoupons: newInsertCoupons,
        });
        MessageAddDispatch({
          type: "setInsertMenuCoupon",
          insertMenuCoupon: 0,
          couponInfo: {},
        });
        MessageAddDispatch({ type: "cancelPopup" });
      } else {
        setError(true);
      }
    }
  }, [
    MessageAddDispatch,
    insertMenuCoupon,
    couponLists,
    editType,
    content,
    couponInfo,
    cursorPosition,
    insertCoupons,
    menuTalkContent,
  ]);

  const cancelPopup = useCallback(() => {
    MessageAddDispatch({ type: "cancelPopup" });
    MessageAddDispatch({ type: "setInsertMenuCoupon", insertMenuCoupon: 0 });
  }, [MessageAddDispatch]);

  const renderCoupon = () => {
    if (couponLists.length !== 0) {
      return couponLists.map((coupon, index) => {
        return (
          <EnableMenuCouponCard
            id={coupon.id}
            key={index}
            coupon={coupon}
            onlyView={false}
          >
            <EnableMenuCouponCard.Img thumbnailURL={coupon.thumbnailURL} />
            <EnableMenuCouponCard.Content coupon={coupon} />
          </EnableMenuCouponCard>
        );
      });
    } else {
      return (
        <div className="coupons_empty">
          {t("sendMenuTalk.campaignSettings.noCouponFound")}
        </div>
      );
    }
  };

  return (
    <Modal
      title={t("sendMenuTalk.campaignSettings.shareCoupons")}
      visible
      destroyOnClose
      width={400}
      centered
      className="modal-base modal-sm insertMenuCouponModal"
      onCancel={cancelPopup}
      footer={
        <button
          className="button-common button-primary"
          onClick={() => confirm()}
        >
          {t("settings:confirm_2")}
        </button>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {error ? (
        <div className="text_error">
          {t("sendMenuTalk.campaignSettings.pls_chooseCoupon")}
        </div>
      ) : null}
      {renderCoupon()}
    </Modal>
  );
};

export default InsertMenuCouponModal;
