import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import _ from "lodash";
import Reorder, { reorder } from "react-reorder";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import "../../../stylesheets/fix-braftEditor.css";
import { useTranslation, Trans } from "react-i18next";
import { shopSplitLists } from "../../../shopLists/shopSplitLists";
import { Modal, Tooltip, Switch } from "antd";

import { handleError } from "../../../libs/handler";
import { config } from "../../../utils/config";
import BookingSystemApi from "../BookingSystemApi";
import GoogleReservationRequiredCheckModal from "../bookingSettingsPopup/GoogleReservationRequiredCheckModal";
import SettingsSaveButton from "../../commons/SettingsSaveButton";

import "./enable.scss";

const SubmitPhoneVerificationModal = ({
  setShowHasNotEnoughPointNotice,
  submit,
}) => {
  const cancelPopup = () => setShowHasNotEnoughPointNotice(false);
  return (
    <Modal
      title="確定儲存嗎？"
      centered
      visible
      className="submitPhoneVerificationModal"
      onCancel={cancelPopup}
      footer={
        <>
          <button className="btn_cancel" onClick={cancelPopup}>
            取消
          </button>
          <button className="btn_save" onClick={submit}>
            確認
          </button>
        </>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      您已開啟電話認證功能，但您的認證簡訊點數餘額不足，顧客將無法進行線上預約。確定儲存嗎？
    </Modal>
  );
};

const editorHooks = {
  "toggle-link": ({ href, target }) => {
    href = href.indexOf("http") === 0 ? href : `http://${href}`;
    return { href, target };
  },
};

const editorControls = [
  {
    key: "text-color",
    title: "顏色",
  },
  {
    key: "bold",
    title: "粗體",
  },
  {
    key: "italic",
    title: "斜體",
  },
  {
    key: "underline",
    title: "底線",
  },
  {
    key: "link",
    title: "連結",
  },
  {
    key: "hr",
    title: "水平線",
  },
];

const BookingSettingAPI = new BookingSystemApi();

const switchSettingInit = {
  emailRequired: true,
  phoneLengthLimit: false,
  phoneVerification: false,
  verifyRequired: true,
  enable: true,
  contentShareEnabled: true,
  autoTable: false,
};

const selectSettingInit = {
  minAttendance: 0,
  maxAttendance: 0,
  maxMultipleSeats: 0,
  maxBookingTimeDay: 0,
  minBookingTimeDay: 0,
  minBookingTimeHour: 0,
  minUpdateTimeDay: 0,
  minUpdateTimeHour: 0,
};

const shopNoteTextareaStyle = {
  width: "100%",
  maxWidth: "506px",
  height: "104px",
  padding: "16px",
  fontSize: "16px",
};

const BookingSettingEnable = (props) => {
  const { t } = useTranslation("settings");
  const [sending, setSending] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [init, setInit] = useState(false);
  const [shopName, setShopName] = useState("");
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [menuPreviewUrls, setMenuPreviewUrls] = useState([]);
  const [menuFiles, setMenuFiles] = useState([]);
  const [backgroundImg, setBackgroundImg] = useState("");
  const [editorState, setEditorState] = useState(null);
  const [outputHTML, setOutputHTML] = useState(null);
  const [showContentShareEnabled, setShowContentShareEnabled] = useState(false);
  const [maxCombinationSeats, setMaxCombinationSeats] = useState(0);
  const [switchSetting, setSwitchSetting] = useState(switchSettingInit);
  const [selectSetting, setSelectSetting] = useState(selectSettingInit);
  const [autoAssignMultipleSeats, setAutoAssignMultipleSeats] = useState(false);
  const [shopNote, setShopNote] = useState("");

  const [validateMsgPoint, setValidateMsgPoint] = useState(0);
  const [validateMsgPointNoticeNum, setValidateMsgPointNoticeNum] = useState(1);
  const [apParams, setApParams] = useState(undefined);
  const [selectedPointsOption, setSelectedPointsOption] = useState(null);
  const [customPoints, setCustomPoints] = useState(null);
  const [showHasNotEnoughPointsNotice, setShowHasNotEnoughPointNotice] =
    useState(false);
  const [showCustomPointsError, setShowCustomPointsError] = useState(false);

  const [googleReservation, setGoogleReservation] = useState(false);
  const [
    showGoogleReservationRequiredCheckModal,
    setGoogleReservationRequiredCheckModal,
  ] = useState(false);

  const auth = useSelector((state) => state.auth);
  const { shop } = auth;

  const routerWillLeave = useCallback(() => {
    if (!isSaved) return t("leaveNotice");
  }, [isSaved, t]);

  useEffect(() => {
    props.router.setRouteLeaveHook(props.route, routerWillLeave);
  }, [props, routerWillLeave]);

  useEffect(() => {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, [init]);

  useEffect(() => {
    BookingSettingAPI.getBookingSetting()
      .then((data) => {
        // console.log('BookingSettingAPI----', data);
        const {
          shop,
          enable,
          background_img,
          note,
          auto_table_distribution,
          content_share_enabled,
          booking_menu_imgs,
          phone_length_limit,
          min_attendance,
          max_attendance,
          max_combination_seats,
          max_multiple_seats,
          email_required,
          verify_required,
          max_booking_time_day,
          min_booking_time_day,
          min_booking_time_hour,
          min_update_time_day,
          min_update_time_hour,
          auto_assign_multiple_seats,
          shop_note,
          phone_verification,
          shop_notification_settings,
          google_reservation,
        } = data;

        const updateSwitchSetting = {
          emailRequired: email_required,
          phoneLengthLimit: phone_length_limit,
          phoneVerification: phone_verification,
          verifyRequired: verify_required,
          enable,
          contentShareEnabled: content_share_enabled,
          autoTable: auto_table_distribution,
        };

        const updateSelectSetting = {
          minAttendance: min_attendance,
          maxAttendance: max_attendance,
          maxMultipleSeats: max_multiple_seats,
          maxBookingTimeDay: max_booking_time_day,
          minBookingTimeDay: min_booking_time_day,
          minBookingTimeHour: min_booking_time_hour,
          minUpdateTimeDay: min_update_time_day,
          minUpdateTimeHour: min_update_time_hour,
        };

        setShopName(shop.name);
        setSwitchSetting(updateSwitchSetting);
        setSelectSetting(updateSelectSetting);
        setBackgroundImg(background_img);
        setEditorState(BraftEditor.createEditorState(note));
        setOutputHTML(note);
        setMenuPreviewUrls(booking_menu_imgs);
        setShowContentShareEnabled(shop.parse_obj_id);
        setMaxCombinationSeats(max_combination_seats);
        setAutoAssignMultipleSeats(auto_assign_multiple_seats);
        setShopNote(shop_note);
        setValidateMsgPoint(shop.phone_verification_msg_points);
        setValidateMsgPointNoticeNum(
          shop_notification_settings.phone_verification.notification_threshold
        );
        setGoogleReservation(google_reservation);

        setInit(true);
      })
      .catch((error) => {
        handleError(error);
        console.log("booking setting enable error", error);
      });
  }, []);

  const handleSwitch = (name) => {
    const newSwitchSetting = Object.assign({}, switchSetting);
    const res = !switchSetting[name];

    if (name === "verifyRequired" && res === true) {
      newSwitchSetting["emailRequired"] = true;
    }

    if (name === "phoneVerification" && res === true) {
      newSwitchSetting["phoneLengthLimit"] = true;
    }

    if (name === "phoneLengthLimit" && switchSetting.phoneVerification) return;

    newSwitchSetting[name] = res;

    setSwitchSetting(newSwitchSetting);
    setIsSaved(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
    const newSelectSetting = Object.assign({}, selectSetting);

    newSelectSetting[name] = value;
    setSelectSetting(newSelectSetting);
    setIsSaved(false);
  };

  const _handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file.size > 2097152) {
      window.app.alert.setMessage(
        t("maximumFileSize2MB_2", { number: 2 }),
        "error"
      );
    } else {
      reader.onloadend = () => {
        setFile(file);
        setImagePreviewUrl(reader.result);
        setIsSaved(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    setOutputHTML(editorState.toHTML());
  };

  // const submitContent = () => {};

  const onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    const reorderRes = reorder(menuPreviewUrls, previousIndex, nextIndex);

    setMenuPreviewUrls(reorderRes);
    setIsSaved(false);
  };

  const delMenuImg = (imgIndex, file) => {
    const newMenuPreviewUrls = _.cloneDeep(menuPreviewUrls);
    const newMenuFiles = _.cloneDeep(menuFiles);

    newMenuPreviewUrls.splice(imgIndex, 1);

    if (!file.id) {
      newMenuFiles.forEach((item, index) => {
        if (item["fileImgOrder"] === file["fileImgOrder"]) {
          newMenuFiles.splice(index, 1);
        }
      });
    }

    if (newMenuPreviewUrls.length === 0) {
      setMenuPreviewUrls([]);
      setMenuFiles([]);
    } else {
      setMenuPreviewUrls(newMenuPreviewUrls);
    }
    setIsSaved(false);
  };

  const _handleMenuImageChange = (e) => {
    e.preventDefault();
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      let file = files[i];
      if (file.size > 2097152) {
        window.app.alert.setMessage(
          t("maximumFileSize2MB_2", { number: 2 }),
          "error"
        );
      } else {
        reader.onloadend = () => {
          const newMenuPreviewUrls = _.cloneDeep(menuPreviewUrls);
          const newMenuFiles = _.cloneDeep(menuFiles);

          const time = new Date(),
            timeStamp = time.getTime().toString();

          if (newMenuPreviewUrls.length >= 30) {
            window.app.alert.setMessage(
              t("imageCommon.imageCommon", { numbers: 30 }),
              "error"
            );
          } else {
            newMenuPreviewUrls.push({
              url: reader.result,
              fileImgOrder: timeStamp,
            });
            file.fileImgOrder = timeStamp;
            newMenuFiles.push(file);

            setMenuPreviewUrls(newMenuPreviewUrls);
            setMenuFiles(newMenuFiles);
            setIsSaved(false);
          }
        };
      }
      reader.readAsDataURL(file);
    }
  };

  const handleShopNote = (e) => {
    const shopNote = e.target.value;

    setShopNote(shopNote);
    setIsSaved(false);
  };

  const renderCombinationSeats = () => {
    let options = [];
    for (let i = 1; i <= maxCombinationSeats; i++) {
      if (i === 1) {
        options.push(
          <option key={i} value={i}>
            {i}({t("onlineBookingSettings.noSplit")})
          </option>
        );
      } else {
        options.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
    }
    return (
      <Trans i18nKey="settings:onlineBookingSettings.maximumTablesForABooking">
        最多
        <select
          className="form-control input-lg"
          style={{ width: "100px" }}
          value={selectSetting.maxMultipleSeats}
          onChange={handleChange}
          name="maxMultipleSeats"
        >
          {options}
        </select>
        張
      </Trans>
    );
  };

  const renderBookingPage = () => {
    const shopSplit = shopSplitLists.indexOf(shop.id);
    let domain = "";

    if (config.env === "production" && shopSplit !== -1) {
      domain = config.booking_split_domain;
    } else {
      domain = config.booking_domain;
    }

    return (
      <div className="settingTbl">
        <a
          style={{ fontSize: "18px", display: "inline-block" }}
          className="msLink"
          href={domain + "/" + shopName}
        >
          {domain}/{shopName}
        </a>
      </div>
    );
  };

  const buyMsgPoint = (e, isCustom = false) => {
    let value = e.target.value;

    if (parseInt(value, 10) < 100 || isNaN(value)) {
      setApParams(undefined);
    }

    // 自訂
    if (isCustom) setCustomPoints(parseInt(value, 10));
    if (value === "custom" || isCustom) {
      setSelectedPointsOption("custom");
    } else {
      setSelectedPointsOption(parseInt(value, 10));
    }

    setShowCustomPointsError(false);

    $("#apForm").html("");

    $.ajax({
      url: window.domain + "/dashboard/booking_settings/buy_msg_point",
      method: "POST",
      data: {
        amount: value === "custom" ? customPoints : value,
        category: "phone_verification",
      },
      xhrFields: { withCredentials: true },
    })
      .done(function (data) {
        $("#apForm").html("");
        //debugger
        for (let k in data) {
          if (data.hasOwnProperty(k))
            $("#apForm").append(
              '<input name="' + k + '" type="hidden" value="' + data[k] + '">'
            );
        }
        setApParams(data);
      })
      .fail(function (xhr) {
        handleError(xhr);
      });
  };

  const submitBuyMsgPoint = () => {
    // 自訂點數小於 100
    if (
      selectedPointsOption === "custom" &&
      (customPoints < 100 || !customPoints)
    )
      return setShowCustomPointsError(true);

    if (apParams !== undefined && selectedPointsOption) {
      document.getElementById("apForm").submit();

      setApParams(undefined);
      setSelectedPointsOption(null);
      setCustomPoints(null);
      setShowCustomPointsError(false);
    } else {
      return window.app.alert.setMessage("請先選擇欲購買項目", "tip");
    }
  };

  const handleValidateMsgPointNoticeNum = (e) => {
    let value = e.target.value;
    setValidateMsgPointNoticeNum(value);
  };

  const submit = () => {
    const {
      minUpdateTimeDay,
      minUpdateTimeHour,
      minBookingTimeDay,
      minBookingTimeHour,
      maxBookingTimeDay,
      maxMultipleSeats,
      minAttendance,
      maxAttendance,
    } = selectSetting;
    const {
      emailRequired,
      phoneLengthLimit,
      phoneVerification,
      verifyRequired,
      enable,
      contentShareEnabled,
      autoTable,
    } = switchSetting;

    setSending(true);

    let fd = new FormData();
    let shopNotificationSettings = {
      phone_verification: {
        enabled: true,
        notification_threshold: validateMsgPointNoticeNum
          ? validateMsgPointNoticeNum
          : 1,
      },
    };

    fd.append("booking_settings[max_multiple_seats]", maxMultipleSeats);
    fd.append("booking_settings[enable]", enable);
    fd.append("booking_settings[email_required]", emailRequired);
    fd.append(
      "booking_settings[auto_assign_multiple_seats]",
      autoAssignMultipleSeats
    );
    fd.append("booking_settings[note]", outputHTML);
    fd.append(
      "booking_settings[max_booking_time]",
      maxBookingTimeDay * 24 * 60 * 60
    );
    fd.append(
      "booking_settings[min_booking_time]",
      minBookingTimeDay * 24 * 60 * 60 + minBookingTimeHour * 60 * 60
    );
    fd.append(
      "booking_settings[min_update_time]",
      minUpdateTimeDay * 24 * 60 * 60 + minUpdateTimeHour * 60 * 60
    );
    fd.append("booking_settings[max_attendance]", maxAttendance);
    fd.append("booking_settings[min_attendance]", minAttendance);
    fd.append("booking_settings[verify_required]", verifyRequired);
    fd.append("booking_settings[auto_table_distribution]", autoTable);
    fd.append("booking_settings[phone_length_limit]", phoneLengthLimit);
    fd.append("booking_settings[content_share_enabled]", contentShareEnabled);
    fd.append("booking_settings[shop_note]", shopNote ? shopNote : "");

    fd.append("booking_settings[phone_verification]", phoneVerification);
    fd.append(
      "booking_settings[shop_notification_settings]",
      JSON.stringify(shopNotificationSettings)
    );

    if (file) {
      fd.append("booking_settings[background_img]", file);
    }

    if (
      typeof validateMsgPointNoticeNum !== "number" &&
      validateMsgPointNoticeNum.length === 0
    )
      setValidateMsgPointNoticeNum(1);

    const settingSubmit = async () => {
      try {
        await updateBookingMenuImage(menuFiles); // update menu imgs
        await BookingSettingAPI.updateBookingSettingWithBgImg(fd);

        window.app.alert.setMessage(t("status.saved"), "done");
        setIsSaved(true);
        setSending(false);
      } catch (err) {
        window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
        setSending(false);
      }
    };

    settingSubmit();
    setShowHasNotEnoughPointNotice(false);
    setGoogleReservationRequiredCheckModal(false);
  };

  const updateBookingMenuImage = (files) => {
    let menuImgFd = new FormData();
    const updateBookingMenuUrls = _.cloneDeep(menuPreviewUrls);

    //新增圖片
    if (files.length !== 0) {
      updateBookingMenuUrls.forEach((item) => {
        if (item.hasOwnProperty("fileImgOrder")) {
          files.forEach((ele) => {
            if (ele.fileImgOrder === item.fileImgOrder) {
              menuImgFd.append("booking_settings[upload_imgs][]", ele);
            }
          });
          Object.keys(item).forEach((k) => delete item[k]);
        }
      });
    }

    //異動
    menuImgFd.append(
      "booking_settings[booking_menu_imgs]",
      JSON.stringify(updateBookingMenuUrls)
    );

    BookingSettingAPI.menuSetting(menuImgFd);
  };

  const handleSaved = () => {
    const {
      minUpdateTimeDay,
      minUpdateTimeHour,
      minBookingTimeDay,
      minBookingTimeHour,
      maxBookingTimeDay,
    } = selectSetting;

    const min_update_time =
      minUpdateTimeDay * 24 * 60 * 60 + minUpdateTimeHour * 60 * 60;
    const min_booking_time =
      minBookingTimeDay * 24 * 60 * 60 + minBookingTimeHour * 60 * 60;
    const max_booking_time = maxBookingTimeDay * 24 * 60 * 60;

    if (min_update_time > min_booking_time) {
      window.app.alert.setMessage(
        t("onlineBookingSettings.pls_deadlineMustNotBeLaterMinimum"),
        "error"
      );
      return;
    }

    if (maxBookingTimeDay === 0) {
      window.app.alert.setMessage(
        t("onlineBookingSettings.pls_maximumAtLeast1day"),
        "error"
      );
      return;
    }

    if (max_booking_time <= min_booking_time) {
      window.app.alert.setMessage(
        t("onlineBookingSettings.pls_maximumMustNotBeLaterMinimum"),
        "error"
      );
      return;
    }

    if (
      googleReservation &&
      (!switchSetting.enable ||
        switchSetting.verifyRequired ||
        switchSetting.phoneVerification ||
        maxBookingTimeDay < 30)
    ) {
      setGoogleReservationRequiredCheckModal(true);
      return;
    }

    if (validateMsgPoint <= 0 && switchSetting.phoneVerification) {
      setShowHasNotEnoughPointNotice(true);
      return;
    }

    submit();
  };

  const handleKeyPress = (e) => {
    // 只能輸入數字
    const pattern = /^[0-9\b]+$/;
    const inputChar = String.fromCharCode(e.charCode);

    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  };

  const renderCustomPointsInput = () => {
    return (
      <div className={showCustomPointsError ? "custom_input-error" : ""}>
        <input
          type="number"
          value={customPoints || ""}
          placeholder="100"
          onChange={(e) => buyMsgPoint(e, true)}
          onKeyPress={handleKeyPress}
        />
        {showCustomPointsError && <div>單次儲值至少需100點</div>}
      </div>
    );
  };

  if (!init) return <div className="pageLoading">{t("status.loading")}</div>;
  return (
    <>
      <div className="setting-container">
        {sending && (
          <div className="lightBoxLayer">
            <h4>{t("status.saving")}</h4>
          </div>
        )}
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <h3>{t("onlineBookingSettings.title")}</h3>
            <h5>{t("onlineBookingSettings.description")}</h5>
          </div>
        </div>

        <hr />

        <div className="rangeTitle">
          <img src={require("../../../images/homepage.svg")} alt="homepage" />
          <h4>
            {t("onlineBookingSettings.onlineBookingPageURL")}
            <div>{t("onlineBookingSettings.onlineBookingPageURL_note")}</div>
          </h4>
        </div>

        {renderBookingPage()}

        <br />
        <br />
        <div className="rangeTitle">
          <img src={require("../../../images/clock.svg")} alt="clock" />
          <h4>{t("onlineBookingSettings.onlineBookingSetting")}</h4>
        </div>

        <div className="settingTbl">
          <div className="settingTblRow fix-settingTblRow">
            <div className="header fix-header">
              {t("onlineBookingSettings.enableOnlineBooking")}
            </div>
            <div className="text fix-text">
              <Switch
                onChange={() => handleSwitch("enable")}
                checked={switchSetting.enable}
              />
            </div>
          </div>

          <div className="settingTblRow fix-settingTblRow m-fix-settingTblRow">
            <div className="header fix-header">
              {t("imageCommon.pageBanner")}
            </div>
            <div className="text fix-text">
              <p className="help-block">
                <img
                  src={imagePreviewUrl || backgroundImg}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "230px",
                  }}
                  alt={t("imageCommon.pageBanner")}
                />
              </p>

              <div className="file-upload fix-file-upload">
                <div>
                  <p>{t("imageCommon.recommendedSize1170X300")}</p>
                  <p>{t("maximumFileSize2MB_1", { number: 2 })}</p>
                </div>
                <div>
                  <label htmlFor="upload" className="msBtn">
                    ＋ {t("imageCommon.upload")}
                  </label>
                  <input
                    id="upload"
                    onChange={(e) => _handleImageChange(e)}
                    className="file-upload__input"
                    type="file"
                    name="file-upload"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="settingTblRow fix-settingTblRow m-fix-settingTblRow">
            <div className="header fix-header">
              {t("onlineBookingSettings.bookingInformation")}
            </div>
            <div className="text fix-text">
              <BraftEditor
                hooks={editorHooks}
                controls={editorControls}
                value={editorState}
                onChange={handleEditorChange}
                // onSave={submitContent}
              />
            </div>
          </div>

          <div className="settingTblRow fix-settingTblRow m-fix-settingTblRow">
            <div className="header fix-header">
              <span>{t("imageCommon.uploadMenu")}</span>
              <Tooltip
                placement="bottom"
                overlayClassName="setting-tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                title={t(
                  "onlineBookingSettings.imageWillShowOnCustomerBookingPage"
                )}
              >
                <i className="fa fa-question-circle-o" />
              </Tooltip>
            </div>
            <div className="text fix-text">
              <div>
                {
                  <Reorder
                    reorderId="my-list"
                    reorderGroup="reorder-group"
                    onReorder={onReorder}
                    disabled={false}
                    placeholder={<div className="menuDivStylePlaceHolder" />}
                  >
                    {menuPreviewUrls.map((item, index) => (
                      <div key={index} className="menuDivStyle">
                        <button
                          className="menuDel"
                          onClick={() => delMenuImg(index, item)}
                        />
                        <img src={item.url} className="menuStyle" alt="" />
                      </div>
                    ))}
                  </Reorder>
                }
              </div>
              <div
                className="file-upload"
                style={{ display: "block", width: "100%" }}
              >
                <span>
                  {t("imageCommon.uploadImagesAndMaximumSize", { numbers: 30 })}
                </span>
                <label htmlFor="upload-menu" className="msBtn fix-upload-menu">
                  + {t("imageCommon.upload")}
                </label>
                <input
                  id="upload-menu"
                  multiple
                  onChange={_handleMenuImageChange}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  className="file-upload__input"
                  type="file"
                  name="file-upload-menu"
                  accept="image/x-png,image/gif,image/jpeg"
                />
              </div>
            </div>
          </div>

          {showContentShareEnabled && (
            <div className="settingTblRow fix-settingTblRow">
              <div className="header fix-header">
                {t("onlineBookingSettings.showActivitiesFromMENUApp")}
                <Tooltip
                  placement="bottom"
                  overlayClassName="setting-tooltip"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={t(
                    "onlineBookingSettings.showActivitiesFromMENUApp_tooltip"
                  )}
                >
                  <i className="fa fa-question-circle-o" />
                </Tooltip>
              </div>
              <div className="text fix-text">
                <Switch
                  onChange={() => handleSwitch("contentShareEnabled")}
                  checked={switchSetting.contentShareEnabled}
                />
              </div>
            </div>
          )}

          <div className="settingTblRow fix-settingTblRow">
            <div className="header">
              {t("onlineBookingSettings.partySize")}
              <Tooltip
                placement="bottom"
                overlayClassName="setting-tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                title={t("onlineBookingSettings.partySize_tooltip")}
              >
                <i className="fa fa-question-circle-o" />
              </Tooltip>
            </div>
            <div className="text fix-text">
              <div style={{ fontSize: "16px" }} className="form-inline">
                {t("min")}{" "}
                <select
                  className="form-control input-lg"
                  name="minAttendance"
                  onChange={handleChange}
                  value={selectSetting.minAttendance}
                  style={{ width: "100px" }}
                >
                  {_.range(
                    1,
                    parseInt(selectSetting.maxAttendance, 10) + 1
                  ).map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>{" "}
                {t("max")}{" "}
                <select
                  className="form-control input-lg"
                  name="maxAttendance"
                  onChange={handleChange}
                  value={selectSetting.maxAttendance}
                  style={{ width: "100px" }}
                >
                  {_.range(
                    parseInt(selectSetting.minAttendance, 10),
                    99 + 1
                  ).map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>
                {"  "}
              </div>
            </div>
          </div>

          <div className="settingTblRow fix-settingTblRow">
            <div className="header">
              {t("onlineBookingSettings.enableSplitTables")}
              <Tooltip
                placement="bottom"
                overlayClassName="setting-tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                title={t("onlineBookingSettings.enableSplitTables_tooltip")}
              >
                <i className="fa fa-question-circle-o" />
              </Tooltip>
            </div>
            <div className="text">
              <div className="form-inline">{renderCombinationSeats()}</div>
            </div>
          </div>

          <div className="settingTblRow fix-settingTblRow">
            <div className="header">
              {t("onlineBookingSettings.emailRequired")}
              <Tooltip
                placement="bottom"
                overlayClassName="setting-tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                title={t("onlineBookingSettings.emailRequired_tooltip")}
              >
                <i className="fa fa-question-circle-o" />
              </Tooltip>
            </div>
            <div className="text">
              <Switch
                onChange={() => handleSwitch("emailRequired")}
                checked={switchSetting.emailRequired}
                disabled={switchSetting.verifyRequired}
              />
            </div>
          </div>

          <div className="settingTblRow fix-settingTblRow">
            <div className="header">
              {t("onlineBookingSettings.limitPhoneCharacter")}
              <Tooltip
                placement="bottom"
                overlayClassName="setting-tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                title={t("onlineBookingSettings.limitPhoneCharacter_tooltip")}
              >
                <i className="fa fa-question-circle-o" />
              </Tooltip>
            </div>
            <div className="text">
              <Switch
                onChange={() => handleSwitch("phoneLengthLimit")}
                checked={switchSetting.phoneLengthLimit}
              />
            </div>
          </div>

          <div className="settingTblRow fix-settingTblRow">
            <div className="header">
              {t("onlineBookingSettings.confirmationDetails")}
            </div>
            <div className="text">
              <textarea
                className="form-control"
                style={shopNoteTextareaStyle}
                placeholder={t(
                  "onlineBookingSettings.confirmationDetails_placeholder"
                )}
                onChange={handleShopNote}
                value={shopNote}
              />
            </div>
          </div>

          <br />
          <br />
          <hr />
          <br />
          <br />

          <div className="settingTblRow  fix-settingTblRow">
            <div className="header">
              {t("onlineBookingSettings.maximumBookingHorizon")}
              <Tooltip
                placement="bottom"
                overlayClassName="setting-tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                title={t("onlineBookingSettings.maximumBookingHorizon_tooltip")}
              >
                <i className="fa fa-question-circle-o" />
              </Tooltip>
            </div>
            <div className="text fix-text">
              <div style={{ fontSize: "16px" }} className="form-inline">
                <input
                  type="tel"
                  className="form-control input-lg"
                  value={selectSetting.maxBookingTimeDay.toString()}
                  name="maxBookingTimeDay"
                  onChange={handleChange}
                  placeholder="0"
                />{" "}
                {t("time:day")}
              </div>
            </div>
          </div>
          <div className="settingTblRow fix-settingTblRow">
            <div className="header">
              {t("onlineBookingSettings.minimumBookingHorizon")}
              <Tooltip
                placement="bottom"
                overlayClassName="setting-tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                title={t("onlineBookingSettings.minimumBookingHorizon_tooltip")}
              >
                <i className="fa fa-question-circle-o" />
              </Tooltip>
            </div>
            <div className="text">
              <div style={{ fontSize: "16px" }} className="form-inline">
                <select
                  className="form-control input-lg"
                  name="minBookingTimeDay"
                  onChange={handleChange}
                  value={selectSetting.minBookingTimeDay}
                  style={{ width: "100px" }}
                >
                  {_.range(8).map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>{" "}
                {t("time:day")}{" "}
                <select
                  className="form-control input-lg"
                  name="minBookingTimeHour"
                  onChange={handleChange}
                  value={selectSetting.minBookingTimeHour}
                  style={{ width: "100px" }}
                >
                  {_.range(24).map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>{" "}
                {t("time:hour")}
              </div>
            </div>
          </div>
          <div className="settingTblRow fix-settingTblRow">
            <div className="header">
              {t("onlineBookingSettings.bookingEditingDeadline")}
              <Tooltip
                placement="bottom"
                overlayClassName="setting-tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                title={t(
                  "onlineBookingSettings.bookingEditingDeadline_tooltip"
                )}
              >
                <i className="fa fa-question-circle-o" />
              </Tooltip>
            </div>
            <div className="text fix-text">
              <div style={{ fontSize: "16px" }} className="form-inline">
                <select
                  className="form-control input-lg"
                  name="minUpdateTimeDay"
                  onChange={handleChange}
                  value={selectSetting.minUpdateTimeDay}
                  style={{ width: "100px" }}
                >
                  {_.range(8).map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>{" "}
                {t("time:day")}{" "}
                <select
                  className="form-control input-lg"
                  name="minUpdateTimeHour"
                  onChange={handleChange}
                  value={selectSetting.minUpdateTimeHour}
                  style={{ width: "100px" }}
                >
                  {_.range(24).map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>{" "}
                {t("time:hour")}
              </div>
            </div>
          </div>
          <div className="settingTblRow fix-settingTblRow">
            <div className="header fix-header">
              {t("onlineBookingSettings.requireBookingApproval")}
              <Tooltip
                placement="bottom"
                overlayClassName="setting-tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                title={t(
                  "onlineBookingSettings.requireBookingApproval_tooltip"
                )}
              >
                <i className="fa fa-question-circle-o" />
              </Tooltip>
            </div>
            <div className="text fix-text">
              <Switch
                onChange={() => handleSwitch("verifyRequired")}
                checked={switchSetting.verifyRequired}
              />
            </div>
          </div>
          <div className="settingTblRow fix-settingTblRow">
            <div className="header fix-header">
              {t("onlineBookingSettings.autoAssignTable")}
              <Tooltip
                placement="bottom"
                overlayClassName="setting-tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                title={t("onlineBookingSettings.autoAssignTable_tooltip")}
              >
                <i className="fa fa-question-circle-o" />
              </Tooltip>
            </div>
            <div className="text fix-text">
              <Switch
                onChange={() => handleSwitch("autoTable")}
                checked={switchSetting.autoTable}
              />
            </div>
          </div>
        </div>

        <div style={{ display: "none" }}>
          <div className="rangeTitle">
            <img src={require("../../../images/phone.svg")} alt="phone" />
            <h4>
              電話認證設定
              <div
                style={{
                  fontSize: "16px",
                  color: "#676767",
                  letterSpacing: "1px",
                  marginTop: "10px",
                }}
              >
                開啟後，顧客需先收取簡訊認證碼，並進行電話認證，才能完成線上預約
              </div>
            </h4>
          </div>

          <div className="settingTbl">
            <div className="settingTblRow fix-settingTblRow phoneVerificationRow">
              <div className="header">啟用狀態</div>
              <div className="text fix-text">
                <Switch
                  onChange={() => handleSwitch("phoneVerification")}
                  checked={switchSetting.phoneVerification}
                />
                {switchSetting.phoneVerification && validateMsgPoint <= 0 ? (
                  <div className="text_error">
                    簡訊點數餘額不足，請於下方購買簡訊點數
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <hr />

          <div className="phoneVerificationBlock">
            <div className="title">認證簡訊點數</div>
            <div className="rowBlock">
              <div className="rowBlock_text">認證簡訊點數餘額</div>
              <div className="rowBlock_text">{validateMsgPoint}</div>
            </div>
            <div className="intro_text" style={{ marginBottom: "24px" }}>
              每封簡訊需消耗2點，當點數歸零，預約系統將關閉線上預約服務
            </div>
            <div className="subtitle">購買點數</div>
            <div className="intro_text">
              單次儲值至少需100點；儲值需額外支付10%營業稅與交易手續費
            </div>
            <div className="radioBox">
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={100}
                  checked={selectedPointsOption === 100}
                  onChange={(e) => buyMsgPoint(e)}
                />
                100
              </label>
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={200}
                  checked={selectedPointsOption === 200}
                  onChange={(e) => buyMsgPoint(e)}
                />
                200
              </label>
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={300}
                  checked={selectedPointsOption === 300}
                  onChange={(e) => buyMsgPoint(e)}
                />
                300
              </label>
              <label>
                <input
                  type="radio"
                  name="selectPoints"
                  value={400}
                  checked={selectedPointsOption === 400}
                  onChange={(e) => buyMsgPoint(e)}
                />
                400
              </label>
              <div className="custom_input">
                <label>
                  <input
                    type="radio"
                    name="selectPoints"
                    value={"custom"}
                    checked={selectedPointsOption === "custom"}
                    onChange={(e) => buyMsgPoint(e)}
                  />
                  自訂購買點數
                </label>
                {selectedPointsOption === "custom"
                  ? renderCustomPointsInput()
                  : null}
              </div>
            </div>
            <button
              type="button"
              onClick={submitBuyMsgPoint}
              className="btn btn-default btn-lg msg_btn"
            >
              購買簡訊點數
            </button>
          </div>

          <hr />

          <div className="phoneVerificationBlock">
            <div className="subtitle">簡訊點數餘額不足提醒通知</div>
            <div className="intro_text">
              可至「通知提醒設定」中設定欲回報的email及LINE
            </div>
            <div className="noticePoints_input">
              當點數餘額不足或小於
              <input
                type="number"
                value={validateMsgPointNoticeNum}
                onChange={handleValidateMsgPointNoticeNum}
                onKeyPress={handleKeyPress}
                placeholder="1"
              />
              ，系統將發送通知提醒。
            </div>
          </div>
          <hr />
        </div>

        <form
          action={config.allpay_checkout}
          id="apForm"
          method="post"
          target="_blank"
        ></form>

        {showHasNotEnoughPointsNotice && (
          <SubmitPhoneVerificationModal
            setShowHasNotEnoughPointNotice={setShowHasNotEnoughPointNotice}
            submit={submit}
          />
        )}

        {showGoogleReservationRequiredCheckModal && (
          <GoogleReservationRequiredCheckModal
            enable={switchSetting.enable}
            verifyRequired={switchSetting.verifyRequired}
            phoneVerification={switchSetting.phoneVerification}
            maxBookingTimeDay={selectSetting.maxBookingTimeDay}
            setGoogleReservationRequiredCheckModal={
              setGoogleReservationRequiredCheckModal
            }
            setShowHasNotEnoughPointNotice={setShowHasNotEnoughPointNotice}
            showHasNotEnoughPointsNotice={
              validateMsgPoint <= 0 && switchSetting.phoneVerification
            }
            submit={submit}
          />
        )}
      </div>
      <SettingsSaveButton handleSaved={handleSaved} showDivider />
    </>
  );
};

export default BookingSettingEnable;
