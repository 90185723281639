import React, { useCallback } from "react";
import { Text, Rect, Circle, Group, Shape } from "react-konva";
import moment from "moment";
import { useTranslation } from "react-i18next";

import * as colorStyle from "./contant";

const {
  $tableStatusEmptyColor,
  $status_unconfirmed,
  $status_confirmed,
  $status_show,
  $status_seated,
  $status_check,
  $status_noShow,
  $status_cancel,
  $progressBaseBarColor,
  $canvasAddBookingBtnColor,
  $canvasCancelBookingBtnColor,
  $canvasTableNameColor_white,
  $canvasTableAttendanceColor_white,
  $tableNextBookingTxtColor_gray,
  $progressBarAlertColor,
  $tableBorderColor_normal,
  $progressBarNormalColor,
  $canvasTableNameColor,
  $canvasTableAttendanceColor,
  $tableNextBookingColor_normal,
  $tableNextBookingTxtColor_white,
  $selectedColor,
  $selectedTableColor,
  $forceBookingBackgroundColor,
  $forceBookingBorderColor,
  $forceBookingProgressBackgroundColor,
  $forceBookingAttendanceTxtColor,
  $forceBookingAttendanceTxtColor_selected,
} = colorStyle;

const tableStatus = (status) => {
  switch (status) {
    case "unconfirmed":
      return $status_unconfirmed;
    case "confirmed":
      return $status_confirmed;
    case "show":
      return $status_show;
    case "seated":
      return $status_seated;
    case "finish":
      return $status_check;
    case "no_show":
      return $status_noShow;
    case "cancel":
      return $status_cancel;
    default:
      return $tableStatusEmptyColor;
  }
};

export const TableInfo = ({
  shapeProps,
  canvasTableNameColor,
  canvasTableAttendanceColor,
}) => {
  const { t } = useTranslation("bookingSystem");

  if (shapeProps.table_type === "circle") {
    return (
      <Shape
        preventDefault={false}
        sceneFunc={function sceneFunc(context) {
          //table name
          context.font =
            "bold 14px PingFang TC,STHeiti Light,Microsoft JhengHei,Helvetica,Arial";
          const text_1 = shapeProps.name;
          const text_w_1 = context.measureText(text_1).width / 2;
          context.fillStyle = canvasTableNameColor;
          context.fillText(text_1, 0 - text_w_1, -20);

          //table attendance
          context.font =
            "12px PingFang TC,STHeiti Light,Microsoft JhengHei,Helvetica,Arial";
          const text_2 = `(${shapeProps.min_attendance}-${
            shapeProps.max_attendance
          }${t("item.person_2")})`;
          const text_w_2 = context.measureText(text_2).width / 2;
          context.fillStyle = canvasTableAttendanceColor;
          context.fillText(text_2, 0 - text_w_2, -3);
        }}
      />
    );
  } else {
    return (
      <Shape
        preventDefault={false}
        sceneFunc={function sceneFunc(context) {
          const baseX = 5,
            baseY = 20;

          // table name
          context.font =
            "bold 14px PingFang TC,STHeiti Light,Microsoft JhengHei,Helvetica,Arial";
          const text_1 = shapeProps.name;
          context.fillStyle = canvasTableNameColor;
          context.fillText(text_1, baseX + 5, baseY);

          //table attendance
          context.font =
            "12px PingFang TC,STHeiti Light,Microsoft JhengHei,Helvetica,Arial";
          const text_2 = `(${shapeProps.min_attendance}-${
            shapeProps.max_attendance
          }${t("item.person_2")})`;
          context.fillStyle = canvasTableAttendanceColor;
          context.fillText(text_2, baseX + 5, baseY + 18);
        }}
      />
    );
  }
};

export const ProgressBar = ({
  shapeProps,
  progressBarVisible,
  progressBar,
  progressBarColor,
}) => {
  let progressBarWidth = shapeProps.width - 10,
    halfProgressBarWidth = progressBarWidth / 2;
  let groupY = shapeProps.height - 15,
    groupX = shapeProps.width / 2 - halfProgressBarWidth;
  let RectHeight = 12,
    RectX = 0,
    RectY = 0;

  if (shapeProps.table_type === "circle") {
    progressBarWidth = shapeProps.width;
    halfProgressBarWidth = 0;
    groupY = shapeProps.height / 2 - 8;
    groupX = -(shapeProps.width / 2);
    RectHeight = 8;
    RectX = 2;
    RectY = 2;
  }

  return (
    <Group
      preventDefault={false}
      visible={progressBarVisible}
      x={groupX}
      y={groupY}
    >
      <Rect
        width={progressBarWidth}
        height={12}
        fill={$progressBaseBarColor}
        cornerRadius={6}
      />
      <Rect
        width={progressBar}
        height={RectHeight}
        x={RectX}
        y={RectY}
        fill={progressBarColor}
        cornerRadius={6}
      />
    </Group>
  );
};

export const Alert = ({ shapeProps, diningTimerAlertVisible }) => {
  let shapeConfig = {};
  let groupConfig = {};

  if (shapeProps.table_type === "circle") {
    shapeConfig = {
      x: -8,
      y: -8,
      width: 16,
      height: 16,
    };

    groupConfig = {
      x: shapeProps.width / 2 - 10,
      y: -(shapeProps.height / 2 - 15),
    };
  } else {
    shapeConfig = {
      width: 30,
      height: 30,
    };

    groupConfig = {
      x: shapeProps.width - 30,
      y: 0,
    };
  }

  const renderAlertWrap = useCallback(() => {
    if (shapeProps.table_type === "circle") {
      return <Circle width={30} height={30} fill={"#ef6868"} />;
    } else {
      return (
        <Rect
          width={30}
          height={30}
          fill={"#ef6868"}
          cornerRadius={[0, 8, 0, 8]}
        />
      );
    }
  }, [shapeProps]);

  return (
    <Group
      preventDefault={false}
      visible={diningTimerAlertVisible}
      {...groupConfig}
    >
      {renderAlertWrap()}

      <Text
        preventDefault={false}
        text={"\uf0f3"}
        fontFamily="FontAwesome"
        fill={"white"}
        fontSize={13}
        align="center"
        verticalAlign="middle"
        {...shapeConfig}
      />
    </Group>
  );
};

export const NextTableBooking = ({
  shapeProps,
  nextTableBookingVisible,
  tableNextBookingColor,
  nextTableBookingTime,
  tableNextBookingTxtColor,
  tableNextBookingBorderColor,
}) => {
  let groupConfig = {};
  let rectConfig = {};

  if (shapeProps.table_type === "circle") {
    groupConfig = {
      x: -(shapeProps.width / 2),
      y: 33,
    };
    rectConfig = {
      cornerRadius: 8,
      strokeWidth: 1,
      stroke: tableNextBookingBorderColor,
    };
  } else {
    groupConfig = {
      x: 0,
      y: shapeProps.height - 20,
    };
    rectConfig = {
      cornerRadius: [0, 0, 8, 8],
    };
  }

  return (
    <Group visible={nextTableBookingVisible} {...groupConfig}>
      <Rect
        width={shapeProps.width}
        height={20}
        fill={tableNextBookingColor}
        {...rectConfig}
      />
      <Text
        preventDefault={false}
        width={shapeProps.width}
        height={20}
        text={nextTableBookingTime}
        align="center"
        verticalAlign="middle"
        fontSize={14}
        fill={tableNextBookingTxtColor}
      />
    </Group>
  );
};

export const AddBookingBtn = ({ shapeProps, addBookingBtnVisible }) => {
  let groupConfig = {};
  let textConfig = {};

  if (shapeProps.table_type === "circle") {
    groupConfig = {
      x: shapeProps.width / 2 - 10,
      y: -(shapeProps.height / 2 - 15),
    };

    textConfig = {
      width: 16,
      height: 16,
      x: -8,
      y: -8,
    };
  } else {
    groupConfig = {
      x: shapeProps.width - 30,
      y: 0,
    };

    textConfig = {
      width: 30,
      height: 30,
    };
  }

  const renderBtnWrap = useCallback(() => {
    if (shapeProps.table_type === "circle") {
      return (
        <Circle
          width={30}
          height={30}
          fill={$canvasAddBookingBtnColor}
          preventDefault={false}
        />
      );
    } else {
      return (
        <Rect
          width={30}
          height={30}
          fill={$canvasAddBookingBtnColor}
          cornerRadius={[0, 8, 0, 8]}
          preventDefault={false}
        />
      );
    }
  }, [shapeProps]);

  return (
    <Group visible={addBookingBtnVisible} {...groupConfig}>
      {renderBtnWrap()}
      <Text
        preventDefault={false}
        text={"\uf067"}
        fontFamily="FontAwesome"
        fontSize={13}
        fill={"white"}
        align="center"
        verticalAlign="middle"
        {...textConfig}
      />
    </Group>
  );
};

export const TableSelected = ({ shapeProps, tableSelectedVisible }) => {
  let groupConfig = {};
  let textConfig = {};

  if (shapeProps.table_type === "circle") {
    groupConfig = {
      x: shapeProps.width / 2 - 10,
      y: -(shapeProps.height / 2 - 15),
    };

    textConfig = {
      width: 16,
      height: 16,
      x: -8,
      y: -8,
    };
  } else {
    groupConfig = {
      x: shapeProps.width - 30,
      y: 0,
    };

    textConfig = {
      width: 30,
      height: 30,
    };
  }

  const renderTableWrap = useCallback(() => {
    if (shapeProps.table_type === "circle") {
      return (
        <Circle
          width={30}
          height={30}
          fill={$canvasCancelBookingBtnColor}
          preventDefault={false}
        />
      );
    } else {
      return (
        <Rect
          width={30}
          height={30}
          fill={$canvasCancelBookingBtnColor}
          cornerRadius={[0, 8, 0, 8]}
          preventDefault={false}
        />
      );
    }
  }, [shapeProps]);

  return (
    <Group visible={tableSelectedVisible} {...groupConfig}>
      {renderTableWrap()}
      <Text
        preventDefault={false}
        text={"\uf00d"}
        fontFamily="FontAwesome"
        fontSize={13}
        fill={"white"}
        align="center"
        verticalAlign="middle"
        {...textConfig}
      />
    </Group>
  );
};

export const SameBookingCount = ({
  shapeProps,
  sameBookingCount,
  swapMoreSeatMode,
  isSelected,
}) => {
	const { t } = useTranslation("bookingSystem");
  const text_2 = `${t("addBooking.groupOfCustomer", {
    groups: sameBookingCount,
  })}`;

  if (shapeProps.table_type === "circle") {
    return (
      <Shape
        preventDefault={false}
        sceneFunc={function sceneFunc(context) {
          context.font =
            "12px PingFang TC,STHeiti Light,Microsoft JhengHei,Helvetica,Arial";
          const text_w_2 = context.measureText(text_2).width / 2;
          if (swapMoreSeatMode && isSelected) {
            context.fillStyle = $forceBookingAttendanceTxtColor_selected;
          } else {
            context.fillStyle = $forceBookingAttendanceTxtColor;
          }
          context.fillText(text_2, 0 - text_w_2, 20);
        }}
      />
    );
  } else {
    return (
      <Shape
        preventDefault={false}
        sceneFunc={function sceneFunc(context) {
          const baseX = 5,
            baseY = 20;
          context.font =
            "10px PingFang TC,STHeiti Light,Microsoft JhengHei,Helvetica,Arial";
          context.fillStyle = $forceBookingAttendanceTxtColor;
          context.fillText(text_2, baseX + 5, baseY + 40);
        }}
      />
    );
  }
};

export const BookingCountIcon = ({ shapeProps, bookingCount, status }) => {
  const shapeConfig = {
    x: -8,
    y: -8,
    width: 16,
    height: 16,
  };
  let groupConfig = {};

  if (shapeProps.table_type === "circle") {
    groupConfig = {
      x: shapeProps.width / 2 - 10,
      y: -(shapeProps.height / 2 - 15),
    };
  } else {
    groupConfig = {
      x: shapeProps.width,
      y: 0,
    };
  }

  return (
    <Group preventDefault={false} {...groupConfig}>
      <Circle
        width={30}
        height={30}
        stroke={"#fff"}
        strokeWidth={2}
        fill={tableStatus(status)}
      />

      <Text
        preventDefault={false}
        text={bookingCount}
        fill={"white"}
        fontSize={15}
        align="center"
        verticalAlign="middle"
        {...shapeConfig}
      />
    </Group>
  );
};

export const displayCanvasTable = ({
  currentTimestamp,
  activeSystem,
  queueSeatedData,
  tableBooking,
  tableBookingStep,
  swapMode,
  swapMoreSeatMode,
  timeline,
  isSwapSeat,
  isSelected,
  progressBarWidth,
  setTableStatusColor,
  setTableBorderColor,
  setCanvasTableNameColor,
  setCanvasTableAttendanceColor,
  setDiningTimerAlertVisible,
  setProgressBarVisible,
  setNextTableBookingVisible,
  setOpacity,
  setAddBookingBtnVisible,
  setTableSelectedVisible,
  setTableNextBookingColor,
  setTableNextBookingTxtColor,
  setNextTableBookingTime,
  setProgressBarColor,
  setProgressBar,
  setSwappedBooking,
  setTableNextBookingBorderColor,
}) => {
  if (timeline.length === 0) {
    // 該桌次無任何預約
    setTableStatusColor($tableStatusEmptyColor);
    setTableBorderColor($tableBorderColor_normal);
    setCanvasTableNameColor($canvasTableNameColor);
    setCanvasTableAttendanceColor($canvasTableAttendanceColor);

    setDiningTimerAlertVisible(false);
    setProgressBarVisible(false);
    setNextTableBookingVisible(false);

    setOpacity(1);

    if (activeSystem === "queue" && Object.keys(queueSeatedData).length !== 0) {
      //排隊入座模式
      setAddBookingBtnVisible(true);
    } else if (tableBooking && tableBookingStep === 3) {
      //選桌子建立預約
      setAddBookingBtnVisible(true);
    } else {
      //正常模式
      setAddBookingBtnVisible(false);
      setTableSelectedVisible(false);
    }

    if (swapMode || swapMoreSeatMode) {
      setSwappedBooking({ seated: {}, swap: {} });
    }
  } else {
    const firstBookingTimestamp = moment(timeline[0].service_start_time);
    const bookingDiff = moment.duration(
        firstBookingTimestamp.diff(currentTimestamp)
      ),
      durationDiff = bookingDiff.asMinutes();

    if (currentTimestamp < firstBookingTimestamp) {
      // 未到第一筆預約時間

      // console.log('----未到第一筆預約時間----', timeline[0]);

      // 一般顯示
      if (!tableBooking && tableBookingStep !== 3) {
        if (durationDiff <= 5) {
          setAddBookingBtnVisible(false);
          setTableSelectedVisible(false);

          setTableStatusColor(tableStatus(timeline[0].status));
          setTableBorderColor(tableStatus(timeline[0].status));
          setCanvasTableNameColor($canvasTableNameColor_white);
          setCanvasTableAttendanceColor($canvasTableAttendanceColor_white);
          setTableNextBookingColor($tableNextBookingColor_normal);
          setTableNextBookingTxtColor($tableNextBookingTxtColor_white);

          //預約狀態：入座，不顯示鈴鐺
          if (timeline[0].status === "seated") {
            setDiningTimerAlertVisible(false);
          } else {
            setDiningTimerAlertVisible(true);
          }

          //queue: 離預約時間小於五分鐘時
          if (
            activeSystem === "queue" &&
            Object.keys(queueSeatedData).length !== 0
          ) {
            setOpacity(0.1);
          } else {
            setOpacity(1);
          }
        } else {
          setDiningTimerAlertVisible(false);
          setOpacity(1);

          if (
            activeSystem === "queue" &&
            Object.keys(queueSeatedData).length !== 0
          ) {
            //排隊入座模式
            setAddBookingBtnVisible(true);
          } else {
            setAddBookingBtnVisible(false);
            setTableSelectedVisible(false);
          }
          setTableStatusColor($tableStatusEmptyColor);
          setTableBorderColor($tableBorderColor_normal);
          setCanvasTableNameColor($canvasTableNameColor);
          setCanvasTableAttendanceColor($canvasTableAttendanceColor);
          setTableNextBookingColor($tableNextBookingColor_normal);
          setTableNextBookingTxtColor($tableNextBookingTxtColor_white);
        }

        // 交換模式
        if (swapMode || swapMoreSeatMode) {
          setSwappedBooking({
            seated: {
              current: timeline[0],
              pre: {},
              next: timeline[1] ? timeline[1] : {},
            },
            swap: {
              current: timeline[0] ? timeline[0] : {},
              pre: {},
              next: timeline[1] ? timeline[1] : {},
            },
          });
        }

        if (swapMoreSeatMode) {
          setDiningTimerAlertVisible(false);
          setTableStatusColor($forceBookingBackgroundColor);
          setTableBorderColor($forceBookingBackgroundColor);
          setCanvasTableNameColor($canvasTableNameColor);
          setCanvasTableAttendanceColor($canvasTableNameColor);
          setTableNextBookingColor($tableNextBookingColor_normal);
          setTableNextBookingTxtColor($tableNextBookingTxtColor_white);
          setTableNextBookingBorderColor($tableNextBookingTxtColor_white);
        }
      }

      //選桌子建立預約：不管是否小於5min
      if (tableBooking && tableBookingStep === 3) {
        setAddBookingBtnVisible(true);
        setDiningTimerAlertVisible(false);
        setOpacity(1);

        setTableStatusColor($forceBookingBackgroundColor);
        setTableBorderColor($forceBookingBackgroundColor);
        setCanvasTableNameColor($canvasTableNameColor);
        setCanvasTableAttendanceColor($forceBookingAttendanceTxtColor);
        setTableNextBookingColor($forceBookingProgressBackgroundColor);
        setTableNextBookingTxtColor($canvasTableNameColor_white);
        setTableNextBookingBorderColor($tableNextBookingTxtColor_white);
      }

      setProgressBarVisible(false);
      setNextTableBookingVisible(true);
      setNextTableBookingTime(timeline[0].time);

      // setCurrentBooking([]);
      // setNextBooking(timeline[0]);
    } else if (currentTimestamp >= firstBookingTimestamp) {
      // 已到第一筆預約時間

      // console.log('timeline------', timeline);

      for (let i = 0; i < timeline.length; i++) {
        const diningTime = JSON.parse(timeline[i].dining_time),
          lastBookingDiningTime = JSON.parse(
            timeline[timeline.length - 1].dining_time
          );
        const bookingTimestamp = moment(timeline[i].service_start_time),
          bookingTimeEnd = moment(bookingTimestamp)
            .add(diningTime, "m")
            .format("YYYY-MM-DD HH:mm");
        const bookingTimeEndTimestamp = moment(bookingTimeEnd);
        const lastBookingTimeEnd = moment(
            timeline[timeline.length - 1].service_start_time
          )
            .add(lastBookingDiningTime, "m")
            .format("YYYY-MM-DD HH:mm"),
          lastBookingTimeEndTimestamp = moment(lastBookingTimeEnd);

        if (
          bookingTimestamp <= currentTimestamp &&
          currentTimestamp <= bookingTimeEndTimestamp - 1
        ) {
          // 現在時間在某比預約時間內
          // console.log('---現在時間 在 某筆預約時間內---', timeline[i]);

          const passTimeDuration = moment.duration(
            currentTimestamp.diff(bookingTimestamp)
          );
          const passTimeDurationDiff = passTimeDuration.asMinutes();
          const pxUnit = progressBarWidth / diningTime;

          //一般顯示
          if (!tableBooking && tableBookingStep !== 3) {
            //用餐時間剩餘30分鐘
            if (passTimeDurationDiff > diningTime - 30) {
              setProgressBarColor($progressBarAlertColor);
            } else {
              setProgressBarColor($progressBarNormalColor);
            }

            setCanvasTableNameColor($canvasTableNameColor_white);
            setCanvasTableAttendanceColor($canvasTableAttendanceColor_white);
            setAddBookingBtnVisible(false);

            if (
              activeSystem === "queue" &&
              Object.keys(queueSeatedData).length !== 0
            ) {
              //排隊入座模式
              setOpacity(0.1);
            } else {
              //正常模式
              setOpacity(1);
              setTableSelectedVisible(false);
            }

            // 交換模式
            if (swapMode && isSwapSeat) {
              //交換模式且為欲交換的位置
              setTableStatusColor($selectedColor);
              setTableBorderColor($selectedColor);
            } else if (swapMoreSeatMode && isSelected) {
              setTableStatusColor($selectedTableColor);
              setTableBorderColor($selectedTableColor);
              setTableNextBookingTxtColor($tableNextBookingTxtColor_white);
            } else if (swapMoreSeatMode && !isSwapSeat) {
              setTableStatusColor($forceBookingBackgroundColor);
              setTableBorderColor($forceBookingBackgroundColor);
              setCanvasTableNameColor($canvasTableNameColor);
              setCanvasTableAttendanceColor($forceBookingAttendanceTxtColor);
              setTableNextBookingColor($forceBookingBackgroundColor);
              setTableNextBookingTxtColor($canvasTableNameColor);
            } else {
              //正常模式
              setTableStatusColor(tableStatus(timeline[i].status));
              setTableBorderColor(tableStatus(timeline[i].status));
            }

            if (swapMode || swapMoreSeatMode) {
              setSwappedBooking({
                seated: {
                  current: timeline[i],
                  pre: timeline[i - 1] ? timeline[i - 1] : {},
                  next: timeline[i + 1] ? timeline[i + 1] : {},
                },
                swap: {
                  current: timeline[i],
                  pre: timeline[i - 1] ? timeline[i - 1] : {},
                  next: timeline[i + 1] ? timeline[i + 1] : {},
                },
              });
            }
          }

          setProgressBarVisible(true);
          setProgressBar(passTimeDurationDiff * pxUnit);
          setTableNextBookingBorderColor($tableNextBookingTxtColor_gray);
          setDiningTimerAlertVisible(false);
          setNextTableBookingVisible(false);

          //選桌子建立預約
          if (tableBooking && tableBookingStep === 3) {
            setAddBookingBtnVisible(true);
            setOpacity(1);

            setTableStatusColor($forceBookingBackgroundColor);
            setTableBorderColor($forceBookingBackgroundColor);
            setCanvasTableNameColor($canvasTableNameColor);
            setCanvasTableAttendanceColor($forceBookingAttendanceTxtColor);
            setTableNextBookingColor($forceBookingProgressBackgroundColor);
            setTableNextBookingTxtColor($canvasTableNameColor_white);
          }

          break;
        } else if (
          currentTimestamp <= bookingTimestamp &&
          currentTimestamp <= bookingTimeEndTimestamp - 1
        ) {
          // 現在時間未到下一筆預約時間
          // console.log('---現在時間 已過上一筆預約 且未到 下一筆預約時間---', timeline[i]);

          const nextBookingTimestamp = moment(timeline[i].service_start_time);
          const nextBookingDiff = moment.duration(
              nextBookingTimestamp.diff(currentTimestamp)
            ),
            nextBookingDurationDiff = nextBookingDiff.asMinutes();

          // 一般顯示
          if (!tableBooking && tableBookingStep !== 3) {
            if (nextBookingDurationDiff <= 5) {
              setAddBookingBtnVisible(false);

              setTableStatusColor(tableStatus(timeline[i].status));
              setTableBorderColor(tableStatus(timeline[i].status));
              setCanvasTableNameColor($canvasTableNameColor_white);
              setCanvasTableAttendanceColor($canvasTableAttendanceColor_white);
              setTableNextBookingColor($tableNextBookingColor_normal);
              setTableNextBookingTxtColor($tableNextBookingTxtColor_white);

              //預約狀態：入座，不顯示鈴鐺
              if (timeline[i].status === "seated") {
                setDiningTimerAlertVisible(false);
              } else {
                setDiningTimerAlertVisible(true);
              }

              //離預約時間小於五分鐘時
              if (
                activeSystem === "queue" &&
                Object.keys(queueSeatedData).length !== 0
              ) {
                //排隊入座
                setOpacity(0.1);
              } else if (tableBooking && tableBookingStep === 3) {
                //選桌子建立預約
                setOpacity(0.1);
              } else {
                //正常模式
                setOpacity(1);
              }

              setAddBookingBtnVisible(false);
              setTableSelectedVisible(false);
            } else {
              setDiningTimerAlertVisible(false);

              setOpacity(1);

              if (
                activeSystem === "queue" &&
                Object.keys(queueSeatedData).length !== 0
              ) {
                //排隊入座
                setAddBookingBtnVisible(true);
              } else {
                //正常模式
                setAddBookingBtnVisible(false);
                setTableSelectedVisible(false);
              }

              setTableStatusColor($tableStatusEmptyColor);
              setTableBorderColor($tableBorderColor_normal);
              setCanvasTableNameColor($canvasTableNameColor);
              setCanvasTableAttendanceColor($canvasTableAttendanceColor);
              setTableNextBookingColor($tableNextBookingColor_normal);
              setTableNextBookingTxtColor($tableNextBookingTxtColor_white);
            }

            if (swapMode || swapMoreSeatMode) {
              setSwappedBooking({
                seated: {
                  current: {},
                  pre: timeline[i - 1] ? timeline[i - 1] : {},
                  next: timeline[i] ? timeline[i] : {},
                },
                swap: {
                  current: timeline[i],
                  pre: timeline[i - 1] ? timeline[i - 1] : {},
                  next: timeline[i + 1] ? timeline[i + 1] : {},
                },
              });
            }

            if (swapMoreSeatMode) {
              setDiningTimerAlertVisible(false);
              setTableStatusColor($forceBookingBackgroundColor);
              setTableBorderColor($forceBookingBackgroundColor);
              setCanvasTableNameColor($canvasTableNameColor);
              setCanvasTableAttendanceColor($canvasTableNameColor);
              setTableNextBookingColor($tableNextBookingColor_normal);
              setTableNextBookingTxtColor($tableNextBookingTxtColor_white);
              setTableNextBookingBorderColor($tableNextBookingTxtColor_white);
            }
          }

          setProgressBar(0);
          setProgressBarVisible(false);

          //選桌子建立預約：不管是否離下筆預約小於5min
          if (tableBooking && tableBookingStep === 3) {
            setAddBookingBtnVisible(true);
            setDiningTimerAlertVisible(false);
            setOpacity(1);

            setTableStatusColor($forceBookingBackgroundColor);
            setTableBorderColor($forceBookingBorderColor);
            setCanvasTableNameColor($canvasTableNameColor);
            setCanvasTableAttendanceColor($forceBookingAttendanceTxtColor);
            setTableNextBookingColor($forceBookingProgressBackgroundColor);
            setTableNextBookingTxtColor($canvasTableNameColor_white);
            setTableNextBookingBorderColor($forceBookingBorderColor);
          }

          setNextTableBookingVisible(timeline[i] ? true : false);
          setNextTableBookingTime(timeline[i] ? timeline[i].time : null);

          // setCurrentBooking({});
          // setNextBooking(timeline[i] ? timeline[i] : {});

          break;
        } else if (currentTimestamp > lastBookingTimeEndTimestamp - 1) {
          //現在時間已過最後一筆預約時間, 已無下一筆預約
          // console.log("------現在時間已過最後一筆預約時間, 已無下一筆預約-----")

          setOpacity(1);
          setDiningTimerAlertVisible(false);
          setTableStatusColor($tableStatusEmptyColor);
          setTableBorderColor($tableBorderColor_normal);
          setCanvasTableNameColor($canvasTableNameColor);
          setCanvasTableAttendanceColor($canvasTableAttendanceColor);

          setDiningTimerAlertVisible(false);
          setProgressBarVisible(false);
          setNextTableBookingVisible(false);

          if (swapMode || swapMoreSeatMode) {
            setSwappedBooking({
              seated: {
                current: {},
                pre: timeline[timeline.length - 1]
                  ? timeline[timeline.length - 1]
                  : {},
                next: {},
              },
              swap: {
                current: {},
                pre: timeline[timeline.length - 1]
                  ? timeline[timeline.length - 1]
                  : {},
                next: {},
              },
            });
          }

          if (
            activeSystem === "queue" &&
            Object.keys(queueSeatedData).length !== 0
          ) {
            //排隊入座
            setAddBookingBtnVisible(true);
          } else if (tableBooking && tableBookingStep === 3) {
            setAddBookingBtnVisible(true);
          } else {
            //正常模式
            setAddBookingBtnVisible(false);
            setTableSelectedVisible(false);
          }
          break;
        }
      }
    }
  }
};
