import React, { Fragment } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const GenderOption = ({ gender, customerInfo, handleChange, genderTxt }) => {
  const { t } = useTranslation("bookingSystem");
  return (
    <div key={gender} className="genderRadio">
      <input
        value={gender}
        name="gender"
        id={gender}
        type="radio"
        checked={gender === customerInfo.gender}
        onChange={(e) => handleChange(e, "gender")}
      />
      <label htmlFor={gender}>{t(`item.${genderTxt}`)}</label>
    </div>
  );
};

const ServiceTag = ({ ele, handleTagChange, customQAns }) => {
  const { t } = useTranslation("bookingSystem");
  const options = JSON.parse(ele.content);
  return (
    <div className="bookingContentRow">
      <div className="bookingContentRow-title">
        <p>
          {ele.title}
          <span style={{ marginLeft: "5px" }}>
            {ele.multiple_selected
              ? `(${t("settings:customBookingQuestionnaire.multiple")})`
              : `(${t("settings:customBookingQuestionnaire.single")})`}
          </span>
        </p>
      </div>
      <div className="bookingContentRow-value">
        <div className="tagWrapper">
          {Object.keys(options).map((o) => {
            let active = "";
            if (customQAns[o]) {
              active = "active";
            } else {
              active = "";
            }
            return (
              <span
                key={o}
                className={active}
                onClick={() =>
                  handleTagChange(ele.id, ele.multiple_selected, o)
                }
              >
                {options[o].name}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Questionnaire = ({ ele, customQAns, handleQuestionChange }) => {
  return (
    <div className="bookingContentRow">
      <div className="bookingContentRow-title">
        <p>{ele.title}</p>
      </div>
      <div className="bookingContentRow-value">
        <textarea
          value={customQAns}
          onChange={(e) => handleQuestionChange(ele.id, e)}
        />
      </div>
    </div>
  );
};

const Quantity = ({ ele, options, customQAns, handleQuantityChange }) => {
  const { t } = useTranslation("bookingSystem");
  const numberOption = JSON.parse(ele.content);
  return (
    <div className="bookingContentRow">
      <div className="bookingContentRow-title">
        <p>
          {ele.title}
          <span style={{ marginLeft: "5px" }}>
            {ele.multiple_selected
              ? `(${t("settings:customBookingQuestionnaire.multiple")})`
              : `(${t("settings:customBookingQuestionnaire.single")})`}
          </span>
        </p>
      </div>
      <div className="bookingContentRow-value">
        <div className="selectWrap">
          {Object.keys(numberOption).map((o) => {
            let active = "";
            let selectValue = customQAns[o];
            if (customQAns[o] && customQAns[o] !== 0) {
              active = "active";
            } else {
              active = "";
              selectValue = 0;
            }
            return (
              <select
                key={o}
                className={active}
                value={selectValue}
                onChange={(e) =>
                  handleQuantityChange(ele.id, e, ele.multiple_selected, o)
                }
              >
                {options[o].map((number) => {
                  return (
                    <option key={number} value={number}>
                      {numberOption[o].name} x {number}
                    </option>
                  );
                })}
              </select>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const DepositSetting = React.forwardRef(
  ({ depositDetail, handleDepositDetail, depositRef, msgPoint }) => {
    const { t } = useTranslation("bookingSystem");
    if (depositDetail.depositMode) {
      return (
        <div className="bookingContentRow-value">
          <input
            ref={depositRef}
            type="text"
            placeholder={t("addBooking.placeholder_deposit")}
            value={depositDetail.deposit}
            onChange={(e) => {
              handleDepositDetail(e, "deposit");
            }}
          />
          <div className="checkBlock">
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={msgPoint > 0 ? depositDetail.notification.sms : false}
                disabled={msgPoint <= 0}
                onChange={(e) => {
                  handleDepositDetail(e, "sms_notification");
                }}
              />
              <span>{t("addBooking.sendSMSNotification")}</span>
              {msgPoint <= 0 ? (
                <span className="text_point">
                  {t("addBooking.pls_SMSCredit")}
                </span>
              ) : (
                <span className="text_point">
                  {t("addBooking.remainingSMSCredit", { point: msgPoint })}
                </span>
              )}
            </label>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={depositDetail.notification.email}
                onChange={(e) => {
                  handleDepositDetail(e, "email_notification");
                }}
              />
              <span>{t("addBooking.sendEmailNotification")}</span>
            </label>
            {depositDetail.notificationError === "required" && (
              <div>{t("addBooking.pls_selectNotification")}</div>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
);

const DepositStatus = ({
  depositDetail,
  smsIsSend,
  emailIsSend,
  msgPoint,
  newSelected,
  handleDepositNotification,
}) => {
  const { t } = useTranslation("bookingSystem");
  const renderSmsNotificationBtn = () => {
    if (msgPoint <= 0) {
      return (
        <button className="btn_recharge" style={{ cursor: "default" }}>
          {t("addBooking.notEnoughSMSCredit")}
        </button>
      );
    } else {
      // 完成發送
      if (depositDetail.notificationStatus.sms === "send") {
        return (
          <div className="text_send">{t("addBooking.sentNotification")}</div>
        );
      } else if (depositDetail.notificationStatus.sms) {
        // 傳送中
        if (smsIsSend) {
          return (
            <button className="btn_resend" style={{ cursor: "default" }}>
              <LoadingOutlined />
            </button>
          );
        } else {
          // 已發送
          return (
            <button
              className="btn_resend"
              onClick={() => handleDepositNotification("sms")}
            >
              {t("addBooking.sendNotificationAgain")}
            </button>
          );
        }
      } else {
        // 傳送中
        if (smsIsSend) {
          return (
            <button className="btn_send" style={{ cursor: "default" }}>
              <LoadingOutlined />
            </button>
          );
        } else {
          // 尚未發送
          return (
            <button
              className="btn_send"
              onClick={() => handleDepositNotification("sms")}
            >
              {t("addBooking.sendNotification")}
            </button>
          );
        }
      }
    }
  };

  const renderEmailNotificationBtn = () => {
    // 完成發送
    if (depositDetail.notificationStatus.email === "send") {
      return (
        <div className="text_send">{t("addBooking.sentNotification")}</div>
      );
    } else if (depositDetail.notificationStatus.email) {
      // 傳送中
      if (emailIsSend) {
        return (
          <button className="btn_resend" style={{ cursor: "default" }}>
            <LoadingOutlined />
          </button>
        );
      } else {
        // 已發送
        return (
          <button
            className="btn_resend"
            onClick={() => handleDepositNotification("email")}
          >
            {t("addBooking.sendNotificationAgain")}
          </button>
        );
      }
    } else {
      if (emailIsSend) {
        return (
          <button className="btn_send" style={{ cursor: "default" }}>
            <LoadingOutlined />
          </button>
        );
      } else {
        // 尚未發送
        return (
          <button
            className="btn_send"
            onClick={() => handleDepositNotification("email")}
          >
            {t("addBooking.sendNotification")}
          </button>
        );
      }
    }
  };

  if (newSelected.status === "cancel") {
    return (
      <div style={{ color: "#676767" }}>{t("addBooking.invalidBooking")}</div>
    );
  }

  if (depositDetail.paid) {
    return (
      <div style={{ color: "#676767" }}>{t("addBooking.depositPaid")}</div>
    );
  } else {
    return (
      <Fragment>
        <div className="bookingDeposit_msg">
          <div className="title">{t("addBooking.smsNotification")}</div>
          {renderSmsNotificationBtn()}
        </div>
        {msgPoint <= 0 ? (
          <div className="text_point">{t("addBooking.pls_SMSCredit_2")}</div>
        ) : (
          <div className="text_point">
            {t("addBooking.remainingSMSCredit", { point: msgPoint })}
          </div>
        )}
        <div className="bookingDeposit_msg">
          <div className="title">{t("addBooking.emailNotification")}</div>
          {renderEmailNotificationBtn()}
        </div>
        {depositDetail.notificationError === "email" && (
          <div className="text_error">{t("addBooking.placeholder_email")}</div>
        )}
      </Fragment>
    );
  }
};

export {
  GenderOption,
  ServiceTag,
  Questionnaire,
  Quantity,
  DepositSetting,
  DepositStatus,
};
