import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Space } from "antd";

import {
  handleHeaderFilter,
  announcementSelected,
} from "../../../actions/announcementAction";
import useScrollableArrows from "../../../hooks/useScrollableArrows";

const AnnouncementHeaderFilter = () => {
  const { t } = useTranslation("bulletinSystem");
  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const { sortBy, order, currentTab, currentFilter, statusAnnouncementNum } =
    announcementListReducer;
  const dispatch = useDispatch();

  const shopFilter = {
    all: t("list.tab.all"),
    regular: t("list.tab.inProgress"),
    long_term: t("list.tab.persistent"),
    finished: t("list.tab.expired"),
  };
  const officialFilter = {
    all: t("list.tab.all"),
    regular: t("list.tab.regular"),
    finished: t("list.tab.expired"),
  };
  const { scrollRef, showLeftArrow, showRightArrow } = useScrollableArrows(currentTab);
  const scrollAmount = 100;

  const handleLeftArrowClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  const handleRightArrowClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const switchFilter = (filterType) => {
    dispatch(handleHeaderFilter(filterType, 1, sortBy, order));
    dispatch(announcementSelected([]));
  };

  const renderAnnouncementType = () => {
    const filterType = currentTab === "shop" ? shopFilter : officialFilter;
    return (
      <ul ref={scrollRef}>
        {Object.keys(filterType).map((item, index) => {
          return (
            <li
              key={item}
              className={`btn-text-lg color-intro btn-effect ${
                currentFilter === item ? "active" : ""
              }`}
              onClick={() => switchFilter(item)}
            >
              <Space size={4}>
                <span>{filterType[item]}</span>
                <span>{statusAnnouncementNum[index]}</span>
              </Space>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="announcementHeaderFilter scrollable-tabs">
      {showLeftArrow && (
        <div className="scroll-left-arrow" onClick={handleLeftArrowClick}>
          <div className="arrowLeft-icon-dark" />
        </div>
      )}
      {renderAnnouncementType()}
      {showRightArrow && (
        <div className="scroll-right-arrow " onClick={handleRightArrowClick}>
          <div className="arrowRight-icon-dark" />
        </div>
      )}
    </div>
  );
};

export default AnnouncementHeaderFilter;
