import { createContext } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { bookingStatusObj } from '../utils/data';

export const listState = {
	activeSystem: 'booking',
	bookings: [],
	originBookings: [],
	activeBookingStatusTab: 'confirmed', //confirmed確認預約 show已到店 seated已入座 finish結帳完成 unconfirmed待確認 no_show 未到店 cancel取消
	queueList: {},
	showOverdue: false,
	queueState: 'normal', //normal end pause
	currentTag: null,
	queueGroup: [],
	activeQueueStatusTab: 'waiting', //waiting standby canceled
	activeGroupTag: 'all',
	search: false, //for 搜尋預約
	searchBooking: null
};

export const ListStateContext = createContext(listState);

export function ListStateReducer(state, action) {
	const newState = _.cloneDeep(state);

	switch (action.type) {
		case 'setInitBookings':
			return setInitBookings(newState, action);
		case 'setActiveSystem':
			return Object.assign({}, newState, {
				activeSystem: action.systemTab
			});
		case 'setActiveBookingStatusTab':
			return changeActiveBooking(newState, action);
		case 'setInitQueueList':
			return setInitQueue(newState, action);
		case 'setActiveQueueStatusTab':
			return switchActiveQueueStatusTab(state, action);
		case 'setActiveGroupTag':
			return Object.assign({}, newState, {
				activeGroupTag: action.activeGroupTag
			});
		case 'switchSystemTab':
			return switchSystemTab(state, action);
		case 'changeQueueStatusTab':
			return Object.assign({}, newState, {
				activeQueueStatusTab: action.activeQueueStatusTab,
				activeGroupTag: 'all'
			});
		case 'setStatusFake':
			return fakeNewStatus(newState, action);
		case 'searchBooking':
			return toList(newState, action);
		case 'searchQueue':
			return toQueueList(newState, action);
		case 'clearSearch':
			return Object.assign({}, newState, {
				search: false,
				searchBooking: null
			})
		default:
			return state;
	}
}

function toList(state, action) {
	const { originBookings } = state;
	const { booking } = action;
	let activeBookingStatusTab = booking.status;
	let bookings = [];
	
	if (activeBookingStatusTab === 'confirmed') {
		bookings = originBookings.filter((booking) => booking.status === 'confirmed');
	} else if (activeBookingStatusTab === 'show') {
		bookings = originBookings.filter((booking) => booking.status === 'show');
	} else if (activeBookingStatusTab === 'seated') {
		bookings = originBookings.filter((booking) => booking.status === 'seated');
	} else if (activeBookingStatusTab === 'finish') {
		bookings = originBookings.filter((booking) => booking.status === 'finish');
	} else if (activeBookingStatusTab === 'unconfirmed') {
		bookings = originBookings.filter((booking) => booking.status === 'unconfirmed');
	} else if (activeBookingStatusTab === 'no_show') {
		bookings = originBookings.filter((booking) => booking.status === 'no_show');
	} else if (activeBookingStatusTab === 'cancel') {
		bookings = originBookings.filter((booking) => booking.status === 'cancel');
	}	

	return Object.assign({}, state, {
		bookings,
		activeBookingStatusTab,
		search:true,
		searchBooking: booking
	});
}

function toQueueList(state, action){
	const {booking} =action;

	return Object.assign({}, state, {
		activeQueueStatusTab: booking.status,
		activeGroupTag: 'all',
		search:true,
		searchBooking: booking
	});
}


function setInitBookings(state, action) {
	const { activeBookingStatusTab } = state;
	const { bookings } = action;

	let displayBookings = [];

	//confirmed確認預約
	if (activeBookingStatusTab === 'confirmed') {
		displayBookings = bookings.filter((booking) => {
			return booking.status === 'confirmed';
		});
	}

	//show已到店
	if (activeBookingStatusTab === 'show') {
		displayBookings = bookings.filter((booking) => {
			return booking.status === 'show';
		});
	}
	
	//seated已入座
	if (activeBookingStatusTab === 'seated') {
		displayBookings = bookings.filter((booking) => {
			return booking.status === 'seated';
		});
	}

	//finish結帳完成
	if (activeBookingStatusTab === 'finish') {
		displayBookings = bookings.filter((booking) => {
			return booking.status === 'finish';
		});
	}

	//unconfirmed待確認
	if (activeBookingStatusTab === 'unconfirmed') {
		displayBookings = bookings.filter((booking) => {
			return booking.status === 'unconfirmed';
		});
	}

	//no_show未到店
	if (activeBookingStatusTab === 'no_show') {
		displayBookings = bookings.filter((booking) => {
			return booking.status === 'no_show';
		});
	}

	//cancel取消
	if (activeBookingStatusTab === 'cancel') {
		displayBookings = bookings.filter((booking) => {
			return booking.status === 'cancel';
		});
	}

	return Object.assign({}, state, {
		bookings: displayBookings,
		originBookings: bookings
	});
}

function changeActiveBooking(state, action) {
	const { activeBookingStatusTab } = action;
	const { originBookings } = state;

	let bookings = [];
	if (activeBookingStatusTab === 'confirmed') {
		bookings = originBookings.filter((booking) => booking.status === 'confirmed');
	} else if (activeBookingStatusTab === 'show') {
		bookings = originBookings.filter((booking) => booking.status === 'show');
	} else if (activeBookingStatusTab === 'seated') {
		bookings = originBookings.filter((booking) => booking.status === 'seated');
	} else if (activeBookingStatusTab === 'finish') {
		bookings = originBookings.filter((booking) => booking.status === 'finish');
	} else if (activeBookingStatusTab === 'unconfirmed') {
		bookings = originBookings.filter((booking) => booking.status === 'unconfirmed');
	} else if (activeBookingStatusTab === 'no_show') {
		bookings = originBookings.filter((booking) => booking.status === 'no_show');
	} else if (activeBookingStatusTab === 'cancel') {
		bookings = originBookings.filter((booking) => booking.status === 'cancel');
	}

	return Object.assign({}, state, {
		bookings,
		activeBookingStatusTab
	});
}

function setInitQueue(state, action) {
	const { queueRecords, queueQroups } = action;

	const showOverdue = queueRecords.overdue.length === 0 ? false : true;
	let queueState = 'normal';
	let currentTag = null,
		queueGroup = [];
	if (queueQroups.length !== 0) {
		const goroupState = queueQroups[0].status;
		if (goroupState !== 'start') {
			queueState = goroupState;
		}

		currentTag = queueQroups[0].current_tag;
		queueGroup = queueQroups;
	}

	return Object.assign({}, state, {
		queueList: queueRecords,
		showOverdue,
		queueState,
		queueGroup,
		currentTag
	});
}

function fakeNewStatus(state, action) {
	let newState = _.cloneDeep(state);
	let { originBookings, activeBookingStatusTab } = newState;
	let bookings = [];
	const { booking, newStatus } = action;
	const findBookingIndex = originBookings.map((booking) => booking.id).indexOf(booking.id);
	const targetBooking = originBookings[findBookingIndex];
	const parseStatusTime = JSON.parse(targetBooking.status_time);

	if (newStatus === 'seated') {
		const bookingTime_min = moment().minute();
		const diff = 5 - bookingTime_min % 5;
		const seatedTime = moment().add(diff, 'm').format('YYYY/MM/DD HH:mm');
		parseStatusTime[newStatus] = seatedTime;
	} else {
		parseStatusTime[newStatus] = moment().format('YYYY-MM-DD HH:mm:ss');
	}

	targetBooking.status = newStatus;
	targetBooking.status_t = bookingStatusObj[newStatus];
	targetBooking.status_time = JSON.stringify(parseStatusTime);

	if (activeBookingStatusTab === 'confirmed') {
		bookings = originBookings.filter((booking) => {
			return booking.status === 'confirmed';});
	} else if (activeBookingStatusTab === 'show') {
		bookings = originBookings.filter((booking) => {
			return booking.status === 'show';
		});
	} else if (activeBookingStatusTab === 'seated') {
		bookings = originBookings.filter((booking) => {
			return booking.status === 'seated';
		});
	} else if (activeBookingStatusTab === 'finish') {
		bookings = originBookings.filter((booking) => {
			return booking.status === 'finish';
		});
	} else if (activeBookingStatusTab === 'unconfirmed') {
		bookings = originBookings.filter((booking) => {
			return booking.status === 'unconfirmed';
		});
	} else if (activeBookingStatusTab === 'no_show') {
		bookings = originBookings.filter((booking) => {
			return booking.status === 'no_show';
		});
	} else if (activeBookingStatusTab === 'cancel') {
		bookings = originBookings.filter((booking) => {
			return booking.status === 'cancel';
		});
	}

	newState.bookings = bookings;

	return newState;
}

function switchActiveQueueStatusTab(state, action) {
	let newState = _.cloneDeep(state);
	const { date } = action;
	const todayDate = moment().format('YYYY-MM-DD');
	const toDate = moment(new Date(date)).format('YYYY-MM-DD');

	const preDate = moment(todayDate).isAfter(toDate);
	const isToday = moment(todayDate).isSame(toDate);

	if (preDate) {
		//過去: 排隊列表只有過號、取消
		newState['activeQueueStatusTab'] = 'standby';
	}

	if (isToday) {
		//今天: 排隊列表等待中、過號、取消
		newState['activeQueueStatusTab'] = 'waiting';
	}

	return newState;
}

function switchSystemTab(state, action) {
	let newState = _.cloneDeep(state);
	const { isToday, systemTab } = action;

	newState['activeSystem'] = systemTab;
	newState['activeBookingStatusTab'] = 'confirmed';
	newState['activeGroupTag'] = 'all';
	newState['activeQueueStatusTab'] = isToday ? 'waiting' : 'standby';

	return changeActiveBooking(newState, {
    activeBookingStatusTab: 'confirmed',
  });
}
