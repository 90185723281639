import React, { useEffect, useState } from "react";
import { Modal, Table, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import $ from "jquery";
import { config } from "../../utils/config";
import { handleError } from "../../libs/handler";
import { useTranslation } from "react-i18next";
import CheckModal from "../popup/CheckModal";

const UserManagement = () => {
  const { t } = useTranslation("settings");
  const [users, setUsers] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [resetPwdUserId, setResetPwdUserId] = useState(null);
  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isResetModalVisible, setResetModalVisible] = useState(false);

  const permission = window.app.current_user.permission.user;

  useEffect(() => {
    if (typeof window.app.current_user !== "undefined") {
      getUsers();
    } else {
      setTimeout(() => getUsers(), 100);
    }
  }, []);

  const getUsers = () => {
    $.ajax({
      url: `${config.domain}/dashboard/users.json`,
      dataType: "json",
      xhrFields: { withCredentials: true },
      success: (data) => {
        const usersArray = Object.values(data.users).flat();
        setUsers(usersArray);
      },
      error: (xhr) => {
        handleError(xhr);
      },
    });
  };

  const addUser = (user, callback) => {
    const url = `${config.domain}/dashboard/users.json`;
    $.ajax({
      type: "POST",
      url: url,
      data: user,
      xhrFields: { withCredentials: true },
      success: (data) => {
        getUsers();
        setAddModalVisible(false);
        window.app.alert.setMessage(t('userSettings.userAddedSuccessfully'), "done");
        if (typeof callback === "function") {
          callback();
        }
      },
      error: (xhr) => {
        window.app.alert.setMessage(xhr.responseJSON.message, "error");
      },
    });
  };

  const deleteUser = (id) => {
    const url = `${config.domain}/dashboard/users/${id}`;
    $.ajax({
      type: "DELETE",
      dataType: "json",
      url: url,
      xhrFields: { withCredentials: true },
      success: () => {
        getUsers();
        setDeleteModalVisible(false);
        window.app.alert.setMessage(t("userSettings.userDeleteSuccessfully"), "done");
      },
      error: (xhr) => {
        setDeleteModalVisible(false);
        window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
      },
    });
  };

  const resetPassword = (id) => {
    const url = `${config.domain}/dashboard/users/${id}/generate_password`;
    $.ajax({
      type: "POST",
      url: url,
      xhrFields: { withCredentials: true },
      success: () => {
        getUsers();
        setResetModalVisible(false);
        window.app.alert.setMessage(t("userSettings.userResetSuccessfully"), "done");
      },
      error: () => {
        window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
      },
    });
  };

  return (
    <>
      <div div className="setting-container">
        <div className="func-title">
          <img
            src={require("../../images/component/f_controlpanel.svg")}
            alt=""
          />
          <h1>{t("userSettings.title")}</h1>
        </div>
        <div>
          {permission.create && (
            <button
              className="msBtn"
              style={{ margin: "20px 0" }}
              onClick={() => setAddModalVisible(true)}
            >
              + {t("userSettings.addUser")}
            </button>
          )}
          <UserTable
            permission={permission}
            users={users}
            onDelete={(id) => {
              setDeleteUserId(id);
              setDeleteModalVisible(true);
            }}
            onResetPwd={(id) => {
              setResetPwdUserId(id);
              setResetModalVisible(true);
            }}
          />
        </div>
      </div>

      {isAddModalVisible && (
        <AddModal
          onSubmit={(user) => addUser(user, () => setAddModalVisible(false))}
          onCancel={() => setAddModalVisible(false)}
        />
      )}
      {isDeleteModalVisible && (
        <CheckModal
          title={t('userSettings.confirmDelete')}
          content={t('userSettings.confirmDelete_content')}
          submit={() => deleteUser(deleteUserId)}
          cancel={() => setDeleteModalVisible(false)}
        />
      )}
      {isResetModalVisible && (
        <CheckModal
          title={t('userSettings.confirmReset')}
          content={t('userSettings.confirmReset_content')}
          submit={() => resetPassword(resetPwdUserId)}
          cancel={() => setResetModalVisible(false)}
        />
      )}
    </>
  );
};

const UserTable = ({ users, onDelete, onResetPwd, permission }) => {
  const { t } = useTranslation("settings");
  const columns = [
    {
      title: t("userSettings.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("userSettings.account"),
      dataIndex: "user_id",
      key: "user_id",
    },
    ...(permission.delete
      ? [
          {
            title: t("userSettings.oneTimePassword"),
            key: "first_time_login_password",
            render: (text, record) =>
              record.first_time_login_password ? (
                <span>{record.first_time_login_password}</span>
              ) : (
                <span />
              ),
          },
          {
            title: "",
            key: "action",
            render: (text, record) => (
              <div className="setting-user-panel-table-button">
                <button className="btn btn-color-white btn-style3" onClick={() => onDelete(record.id)}>
                  <DeleteOutlined />
                  <span style={{marginLeft: '4px'}}>{t("delete")}</span>
                </button>
                <button className="btn btn-color-white btn-style3" onClick={() => onResetPwd(record.id)}>
                  <EditOutlined />
                  <span style={{marginLeft: '4px'}}>{t("userSettings.resetPassword")}</span>
                </button>
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <Table
      dataSource={users}
      columns={columns}
      rowKey="id"
      pagination={false}
      scroll={{ x: 375 }}
    />
  );
};

const AddModal = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation("settings");
  const [form, setForm] = useState({
    user_id: "",
    name: "",
    first_time_login_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = () => {
    onSubmit(form);
  };

  return (
    <Modal
      title={t("userSettings.addUser")}
      className="modal-base modal-xl"
      visible
      destroyOnClose
      centered
      maskClosable={false}
      closable={false}
      width={640}
      wrapClassName="availableTimeSetting"
      footer={
        <Space size={10}>
          <button
            className="button-common button-secondary"
            onClick={() => {
              setForm({});
              onCancel();
            }}
          >
            {t("cancel")}
          </button>
          <button
            type="submit"
            className="button-common button-primary"
            onClick={handleSubmit}
          >
            {t("save")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
    >
      <form>
        <div className="form-group">
          <label className="control-label"><h4>{t('userSettings.account')}</h4></label>
          <input
            type="text"
            name="user_id"
            className="form-control input-lg"
            placeholder={t('userSettings.placeholder_account')}
            value={form.user_id}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label className="control-label"><h4>{t('userSettings.name')}</h4></label>
          <input
            type="text"
            name="name"
            className="form-control input-lg"
            placeholder={t('userSettings.placeholder_name')}
            value={form.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label className="control-label"><h4>{t('userSettings.oneTimePassword')}</h4></label>
          <input
            name="first_time_login_password"
            className="form-control input-lg"
            placeholder={t('userSettings.placeholder_oneTimePassword')}
            value={form.first_time_login_password}
            onChange={handleChange}
          />
        </div>
      </form>
    </Modal>
  );
};

export default UserManagement;