import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import BookingSystemApi from "../../booking_settings/BookingSystemApi";

import { bookingStatus, useInterval } from "../utils/data";
import CheckModal from "../../popup/CheckModal";

const BookingAPI = new BookingSystemApi();

export const StatusOptions = ({
  isToday,
  date,
  switchMode,
  swithcStatus,
  booking,
}) => {
  const { t } = useTranslation("bookingSystem");
  const [currentTime, setCurrentTime] = useState(moment());
  const today = moment();
  const pre = moment(new Date(booking.booking_datetime)).add(-60, "m");
  const tomorrow = moment(new Date(today)).add(1, "d");
  const [delay, setDelay] = useState(isToday ? 1000 : null);
  const [bookingStatusBtn, setBookingStatusBtn] = useState(3); //1: 前一天 2:當天時間>預約60分鐘 3:當天時間<=預約60分鐘
  const [showWarning, setShowWarning] = useState(false);
  const [warningTales, setWarningTales] = useState([]);

  const renderBookingStatusOptionHeader = () => {
    return (
      <div className="bookingStatusOption__header">
        <h3 className={`currentBooking__header__title bg-${booking.status}`}>{booking.time}</h3>
        <button onClick={() => switchMode(1, {})}>
          <div className="icon-normal dismiss-icon" />
        </button>
      </div>
    );
  };

  const findStatus = useCallback(() => {
    if (currentTime.isAfter(new Date(date), "date")) {
      //過去: 前一天
      //待確認 確認預約 取消預約 完成預約
      //不顯示 finish show
      setBookingStatusBtn(1);
    } else if (currentTime.isBefore(new Date(pre))) {
      //當天時間>預約60分鐘
      //待確認 確認預約 已到店 取消預約
      setBookingStatusBtn(2);
    } else if (
      currentTime.isBetween(new Date(pre), new Date(tomorrow), null, "[)")
    ) {
      //當天時間<=預約60分鐘
      //待確認 確認預約 已到店 取消預約 未到店 已入座 完成消費
      setBookingStatusBtn(3);
    }
  }, [currentTime, date, pre, tomorrow]);

  useEffect(() => {
    if (isToday) {
      setDelay(1000);
    } else {
      setDelay(null);
    }
    findStatus();
  }, [isToday, findStatus]);

  useInterval(() => {
    setCurrentTime(moment());
  }, delay);

  const seatedWarning = useCallback(() => {
    BookingAPI.checkSeated(booking.id).then((data) => {
      if (data.overlay_table.length !== 0) {
        setShowWarning(true);
        setWarningTales(data.overlay_table);
      } else {
        swithcStatus(booking, "seated");
      }
    });
  }, [booking, swithcStatus]);

  const renderBookingStatusOption = useCallback(() => {
    const excludedStatuses = booking.pos_sync_required
      ? ["unconfirmed", "confirmed", "show", "seated", "no_show"]
      : [];
    const filteredStatus = bookingStatus.filter((b) => {
      if (bookingStatusBtn === 1) {
        return !["show", "no_show", "seated"].includes(b.status);
      }
      return true;
    });
    return (
      <ul className="dropdownMenu-booking-status">
        {filteredStatus.map((b) => {
          const isActive = booking.status === b.status;
          const statusClassName = `${b.status + "Status"} ${
            isActive ? "active" : ""
          } btn-effect`;

          const handleClick =
            b.status === "seated"
              ? seatedWarning
              : () => swithcStatus(booking, b.status);

          if (
            booking.status === "seated" &&
            excludedStatuses.includes(b.status)
          ) {
            return null;
          }

          return (
            <li
              key={b.status}
              className={statusClassName}
              onClick={handleClick}
            >
              <span>{t(`status.${b.status}`)}</span>
            </li>
          );
        })}
      </ul>
    );
  }, [bookingStatusBtn, seatedWarning, booking, swithcStatus, t]);

  const cancelWarning = () => {
    setShowWarning(false);
    setWarningTales([]);
  };

  const confirmWarning = () => {
    setShowWarning(false);
    swithcStatus(booking, "seated");
    setWarningTales([]);
  };

  return (
    <div className="bookingStatusOption">
      {renderBookingStatusOptionHeader()}

      <div className="bookingStatusOption__body">
        {renderBookingStatusOption()}
      </div>

      {showWarning && (
        <CheckModal
          title={t("popup.overlapping_title")}
          content={t("popup.overlapping_content", {
            tables: warningTales.join("、"),
          })}
          submit={confirmWarning}
          cancel={cancelWarning}
        />
      )}
    </div>
  );
};
