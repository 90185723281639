import React, { Component } from "react";
import $ from "jquery";
import BookingSystemApi from "./BookingSystemApi";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import SettingsSaveButton from "../commons/SettingsSaveButton";
import { Modal, Space, Tooltip } from 'antd';

//tagEditable: 調用x-editable的class
const defaultCustomQSetting = JSON.stringify({
	question_type: "question",
	title: "",
	content: "{}",
	required: true,
	multiple_selected: false,
	is_available: true
});
const defaultSelect =
	'{"name": "", "min": 1, "max": 2, "available": true, "newOption": true}';
const QUANTITY = "quantity",
	TAG = "tag",
	QUESTION = "question";
let maxIndex = 0;
let optionStyle = false,
	emptyStyle = false;

class CustomQuestion extends Component {
	constructor(props) {
		super(props);

		this.state = {
			init: false,
			isHiddenModal: true,
			customized_questions: [],
			newCustomQ: [],
			deleteCustomQ: [],
			updateCustomQ: [],
			customQSetting: defaultCustomQSetting,
			qIndex: null,
			isSaved: true,
			sending: false
		};

		this.loadSetting = this.loadSetting.bind(this);
		this.renderCustomQ = this.renderCustomQ.bind(this);
		this.addQuestion = this.addQuestion.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.modify = this.modify.bind(this);
		this.deleteList = this.deleteList.bind(this);
		this.move = this.move.bind(this);
		this.submit = this.submit.bind(this);

		this.handleModalSubmit = this.handleModalSubmit.bind(this);
	}

	routerWillLeave = nextLocation => {
		const { t } = this.props;
		if (!this.state.isSaved)
			return t('leaveNotice');
	};

	componentDidMount() {
		this.API = new BookingSystemApi();
		this.loadSetting();
		this.props.router.setRouteLeaveHook(
			this.props.route,
			this.routerWillLeave
		);
	}

	loadSetting(status) {
		const { t } = this.props;
		this.API.getQuestions().then(data => {
			if (status === "update") {
				this.setState(
					{
						customized_questions: data
					},
					() => window.app.alert.setMessage(t('status.saved'), "done")
				);
			} else {
				this.setState({
					customized_questions: data,
					init: true
				});
			}
		});
	}

	renderCustomQ() {
		let { customized_questions } = this.state;
		let allQLength = customized_questions.length;

		return customized_questions.map((q, index) => {
			return (
				<TranslatedQTypeBlock
					key={index}
					index={index}
					detail={q}
					deleteList={this.deleteList}
					last={index + 1 === allQLength}
					move={this.move}
					modify={this.modify}
				/>
			);
		});
	}

	addQuestion() {
		this.setState(
			{
				isHiddenModal: false,
				customQSetting: defaultCustomQSetting,
				isSaved: false
			}
		);
	}

	closeModal() {
		this.setState({
			isHiddenModal: true,
			qIndex: null
		});
	}

	handleModalSubmit(customQ) {
		let { customized_questions, qIndex } = this.state;
		const { t } = this.props;

		if (customQ.id === undefined && !customQ.addNew) {
			//新增
			customized_questions.push(customQ);
			customQ["addNew"] = true;
			window.app.alert.setMessage(t('customBookingQuestionnaire.questionAdded'), "done");
		} else {
			//修改
			customized_questions[qIndex] = customQ;
			customized_questions[qIndex]["modify"] = true;
			window.app.alert.setMessage(t('customBookingQuestionnaire.questionModified'), "done");
		}

		this.setState({
			customized_questions,
			isHiddenModal: true,
			qIndex: null
		});
	}

	modify(index) {
		let { customized_questions } = this.state;
		let propsSelect = JSON.stringify(customized_questions[index]);

		this.setState(
			{
				customQSetting: propsSelect,
				isHiddenModal: false,
				qIndex: index,
				isSaved: false
			}
		);
	}

	deleteList(index, id) {
		const { t } = this.props;
		let { customized_questions, deleteCustomQ } = this.state;
		let question = customized_questions[index];

		if (question.id) {
			let res = window.confirm(
				t('customBookingQuestionnaire.pls_deleted')
			);

			if (res) {
				customized_questions.splice(index, 1);
				if (id) {
					deleteCustomQ.push(id);
				}

				this.setState(
					{
						customized_questions,
						deleteCustomQ,
						isSaved: false
					},
					() => window.app.alert.setMessage(t('customBookingQuestionnaire.deleted'), "done")
				);
			}
		} else if (!question.id) {
			customized_questions.splice(index, 1);
			if (id) {
				deleteCustomQ.push(id);
			}

			this.setState(
				{
					customized_questions,
					deleteCustomQ,
					isSaved: false
				},
				() => window.app.alert.setMessage(t('customBookingQuestionnaire.deleted'), "done")
			);
		}
	}

	move(index, type) {
		let { customized_questions } = this.state;
		let now = JSON.parse(JSON.stringify(customized_questions[index]));

		if (type === "up") {
			let pre = JSON.parse(
				JSON.stringify(customized_questions[index - 1])
			);

			pre["modify"] = true;
			now["modify"] = true;

			customized_questions[index] = pre;
			customized_questions[index - 1] = now;
		} else if (type === "down") {
			let next = JSON.parse(
				JSON.stringify(customized_questions[index + 1])
			);

			next["modify"] = true;
			now["modify"] = true;

			customized_questions[index] = next;
			customized_questions[index + 1] = now;
		}

		this.setState({
			customized_questions,
			isSaved: false
		});
	}

	submit() {
		let {
			customized_questions,
			deleteCustomQ,
			newCustomQ,
			updateCustomQ
		} = this.state;
		customized_questions.map((q, index) => {
			q.question_order = index + 1;

			if (q.question_type !== QUESTION) {
				let contentObject = JSON.parse(q.content);
				let newContent = contentObject;
				for (let i in newContent) {
					delete newContent[i]["newOption"];
				}
				q.content = JSON.stringify(newContent);
			}

			if (!q.id && q.addNew) {
				newCustomQ.push(JSON.parse(JSON.stringify(q)));
				q.id = -1; //以防按了兩次儲存造成重複新增
			} else if (q.modify) {
				updateCustomQ.push(JSON.parse(JSON.stringify(q)));
				delete q["modify"]; //以防按了兩次儲存造成重複新增
			}

			return true;
		});

		this.setState(
			{
				newCustomQ,
				updateCustomQ,
				deleteCustomQ,
				isSaved: true,
				sending: true
			},
			() => this.deleteApi()
		);
	}

	deleteApi = () => {
		const { t } = this.props;
		let { deleteCustomQ } = this.state;

		this.API.deleteCustomQuestion(deleteCustomQ)
			.then(data => {
				this.setState({ deleteCustomQ: [] }, () => this.createApi());
			})
			.catch(error => {
				window.app.alert.setMessage(t('status.pls_tryAgainLater'), "error");
				this.setState({
					sending: false
				});
			});
	};

	createApi = () => {
		const { t } = this.props;
		let { newCustomQ } = this.state;
		this.API.createCustomQuestion(newCustomQ)
			.then(data => {
				this.setState({ newCustomQ: [] }, () => this.updateApi());
			})
			.catch(error => {
				window.app.alert.setMessage(t('status.pls_tryAgainLater'), "error");
				this.setState({
					sending: false
				});
			});
	};

	updateApi = () => {
		const { t } = this.props;
		let { updateCustomQ } = this.state;

		this.API.updateCustomQuestion(updateCustomQ)
			.then(data => {
				this.setState(
					{
						updateCustomQ: [],
						sending: false
					},
					() => this.loadSetting("update")
				);
			})
			.catch(error => {
				window.app.alert.setMessage(t('status.pls_tryAgainLater'), "error");
				this.setState({
					sending: false
				});
			});
	};

	render() {
		const { t } = this.props;
		if (!this.state.init) {
			return <div className='pageLoading'>{t('status.loading')}</div>;
		}
		return (
			<>
				<div className='setting-container'>
					{this.state.sending && (
						<div className='lightBoxLayer'>
							<h4>{t('status.saving')}</h4>
						</div>
					)}
					<div style={{ display: "flex" }}>
						<div style={{ flexGrow: 1 }}>
							<h3>{t('customBookingQuestionnaire.title')}</h3>
							<h5>{t('customBookingQuestionnaire.description')}</h5>
						</div>
					</div>
					<hr />
					<div>
						<button
							className='msBtn'
							onClick={() => this.addQuestion()}>
							＋ {t('customBookingQuestionnaire.addQuestion')}
						</button>
						<br />
						<hr />
					</div>
					<div className='customQuestion'>{this.renderCustomQ()}</div>
					{!this.state.isHiddenModal && (
						<TranslatedCustomQuestionModal
							customQSetting={JSON.parse(this.state.customQSetting)}
							closeModal={this.closeModal}
							handleModalSubmit={this.handleModalSubmit}
							qIndex={this.state.qIndex}
						/>
					)}
				</div>
				<SettingsSaveButton handleSaved={this.submit} showDivider/>
			</>
		);
	}
}

class QTypeBlock extends Component {
	renderQType = () => {
		let { detail, t } = this.props;
		let multipleTxt = detail.multiple_selected ? `（${t('customBookingQuestionnaire.multiple')}）` : "";

		switch (detail.question_type) {
			case QUANTITY:
				return `${t('customBookingQuestionnaire.numerical')}${multipleTxt}`;
			case TAG:
				return `${t('customBookingQuestionnaire.multipleSelect')}${multipleTxt}`;
			case QUESTION:
				return `${t('customBookingQuestionnaire.freeText')}${multipleTxt}`;
			default:
				break;
		}
	};

	renderOption = () => {
		let { detail, t } = this.props;
		let newDetail = JSON.parse(detail.content);

		if (detail.question_type === QUANTITY) {
			return (
				<div className='settingTblRow quantity_tag'>
					<div className='header'>{t('customBookingQuestionnaire.setupOptions')}</div>
					<div className='text'>
						{Object.keys(newDetail).map((c, index) => {
							return (
								<QTag
									key={c}
									name={newDetail[c].name}
									min={newDetail[c].min}
									max={newDetail[c].max}
								/>
							);
						})}
					</div>
				</div>
			);
		} else if (detail.question_type === TAG) {
			return (
				<div className='settingTblRow'>
					<div className='header'>{t('customBookingQuestionnaire.setupOptions')}</div>
					<div className='text'>
						{Object.keys(newDetail).map((c, index) => {
							return <QTag key={c} name={newDetail[c].name} />;
						})}
					</div>
				</div>
			);
		}
	};

	renderMoveIcon = (index, last) => {
		if (index === 0) {
			return (
				<button
					className='msLink'
					onClick={() => this.props.move(index, "down")}>
					<img
						style={{ height: "20px" }}
						src={require("../../images/queue/down_icon.png")}
						alt='MoveDown'
					/>
				</button>
			);
		} else if (last) {
			return (
				<button
					className='msLink'
					onClick={() => this.props.move(index, "up")}>
					<img
						style={{ height: "20px" }}
						src={require("../../images/queue/up_icon.png")}
						alt='MoveUp'
					/>
				</button>
			);
		} else {
			return (
				<span>
					<button
						className='msLink'
						onClick={() => this.props.move(index, "up")}>
						<img
							style={{ height: "20px" }}
							src={require("../../images/queue/up_icon.png")}
							alt='MoveUp'
						/>
					</button>
					<button
						className='msLink'
						onClick={() => this.props.move(index, "down")}>
						<img
							style={{ height: "20px" }}
							src={require("../../images/queue/down_icon.png")}
							alt='MoveDown'
						/>
					</button>
				</span>
			);
		}
	};

	render() {
		let { index, detail, last, t } = this.props;
		return (
      <section
        className={classNames("cqBlock", {
          closeBlock: !detail.is_available,
        })}
      >
        <div className="cqTitle">
          <div className="cqTitle__cell">
            <img src={require("../../images/service_mark.png")} alt="" />
          </div>
          <div className="cqTitle__cell rangeTitle">
            <h4>
              {index + 1}：{detail.title}
              {detail.id && detail.id !== -1 ? (
                <span> ({t("customBookingQuestionnaire.published")})</span>
              ) : (
                ""
              )}
            </h4>
          </div>
          <div className="cqTitle__cell">
            <button className="msLink" onClick={() => this.props.modify(index)}>
              <img
                style={{ height: "20px" }}
                src={require("../../images/queue/pen_icon.png")}
                alt="Edit"
              />
            </button>
            <button
              className="msLink"
              onClick={() => this.props.deleteList(index, detail.id)}
            >
              <img
                style={{ height: "20px" }}
                src={require("../../images/queue/trashcan_icon.png")}
                alt="Delete"
              />
            </button>
            {this.renderMoveIcon(index, last)}
          </div>
        </div>
        <div className="settingTbl cq-content">
          <div className="settingTblRow">
            <div className="header">
              {t("customBookingQuestionnaire.questionType")}
            </div>
            <div className="text">{this.renderQType()}</div>
          </div>
          {this.renderOption()}
          <div className="settingTblRow">
            <div className="header">
              {t("customBookingQuestionnaire.requiredOrOptional")}
            </div>
            <div className="text">{detail.required ? t('customBookingQuestionnaire.required') : t('customBookingQuestionnaire.optional')}</div>
          </div>
          <div className="settingTblRow">
            <div className="header">
              <Tooltip
                placement="bottom"
                overlayClassName="setting-tooltip"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                title={t("customBookingQuestionnaire.status_tooltip")}
              >
                {t("customBookingQuestionnaire.status")}
                <i className="fa fa-question-circle-o" />
              </Tooltip>
            </div>
            <div className="text">
              {detail.is_available
                ? t("customBookingQuestionnaire.released")
                : t("customBookingQuestionnaire.hidden")}
            </div>
          </div>
        </div>
      </section>
    );
	}
}

class CustomQuestionModal extends Component {
	constructor(props) {
		super(props);
		let { customQSetting } = this.props;
		this.state = {
			customQSetting: customQSetting,
			contentArray_quantity: [],
			contentArray_tag: [],
			contentArray_question: [],
			reRender: false,
			modifyTag: JSON.parse(customQSetting.content)
		};
	}

	sortOption = () => {
		let {
			customQSetting,
			contentArray_quantity,
			contentArray_tag
		} = this.state;
		if (!customQSetting.id) {
			let contentObject = JSON.parse(customQSetting.content);

			if (customQSetting.question_type === TAG) {
				Object.keys(contentObject).map(c => {
					return contentArray_tag.push(contentObject[c]);
				});
			} else if (customQSetting.question_type === QUANTITY) {
				Object.keys(contentObject).map(c => {
					return contentArray_quantity.push(contentObject[c]);
				});
			}
			this.setState({ contentArray_tag, contentArray_quantity });
		}
	};

	handleQuestionTypeChange = e => {
		let { customQSetting } = this.state;
		let type = e.target.value;

		customQSetting["question_type"] = type;
		this.setState({ customQSetting });
	};

	addDefaultSelect = type => {
		let {
			customQSetting,
			contentArray_quantity,
			contentArray_tag,
			modifyTag
		} = this.state;
		const { t } = this.props;
		const defaultTag = `{"name": "${t('customBookingQuestionnaire.enter')}", "available": true, "newOption": true}`;

		if (type === TAG) {
			if (customQSetting.id) {
				modifyTag[maxIndex + 1] = JSON.parse(defaultTag);
			} else {
				contentArray_tag.push(JSON.parse(defaultTag));
			}
		} else if (type === QUANTITY) {
			if (customQSetting.id) {
				modifyTag[maxIndex + 1] = JSON.parse(defaultSelect);
			} else {
				contentArray_quantity.push(JSON.parse(defaultSelect));
			}
		}
		this.setState({ contentArray_quantity, contentArray_tag, modifyTag });
	};

	del = (id, type) => {
		let {
			customQSetting,
			contentArray_quantity,
			contentArray_tag,
			modifyTag
		} = this.state;
		if (customQSetting.id) {
			//刪除既有題目的選項
			delete modifyTag[id];
		} else {
			if (type === TAG) {
				contentArray_tag.splice(id, 1);
			} else if (type === QUANTITY) {
				contentArray_quantity.splice(id, 1);
			}
		}

		this.setState({ contentArray_quantity, contentArray_tag, modifyTag });
	};

	handleDefaultSetting = (e, id, type) => {
		let {
			contentArray_quantity,
			contentArray_tag,
			contentArray_question,
			customQSetting,
			modifyTag
		} = this.state;
		let attributeName = e.target.getAttribute("name");
		let txt = e.target.value;

		if (customQSetting.id) {
			if (attributeName === "min") {
				modifyTag[id]["min"] = parseInt(txt, 10);
			} else if (attributeName === "max") {
				modifyTag[id]["max"] = parseInt(txt, 10);
			} else if (attributeName === "name") {
				modifyTag[id]["name"] = txt;
			}
		} else {
			if (attributeName === "name") {
				if (type === TAG) {
					contentArray_tag[id]["name"] = txt;
				} else if (type === QUANTITY) {
					contentArray_quantity[id]["name"] = txt;
				}
			} else if (attributeName === "min") {
				contentArray_quantity[id]["min"] = parseInt(txt, 10);
			} else if (attributeName === "max") {
				contentArray_quantity[id]["max"] = parseInt(txt, 10);
			}
		}

		this.setState({
			contentArray_quantity,
			contentArray_tag,
			contentArray_question,
			modifyTag
		});
	};

	renderQType = () => {
		let { customQSetting } = this.state;
		const { t } = this.props;
		const qTypeMap = { quantity: t('customBookingQuestionnaire.numerical'), tag: t('customBookingQuestionnaire.multipleSelect'), question: t('customBookingQuestionnaire.freeText') };
		if (customQSetting.id) {
			// 已儲存題目不能修改類型
			return <div>{qTypeMap[customQSetting.question_type]}</div>;
		} else {
			// 新增的題目
			return (
				<select
					value={customQSetting.question_type}
					onChange={e => this.handleQuestionTypeChange(e)}>
					<option value={QUESTION}>{t('customBookingQuestionnaire.freeText')}</option>
					<option value={TAG}>{t('customBookingQuestionnaire.multipleSelect')}</option>
					<option value={QUANTITY}>{t('customBookingQuestionnaire.numerical')}</option>
				</select>
			);
		}
	};

	renderQuestionType = () => {
		let {
			customQSetting,
			contentArray_quantity,
			contentArray_tag,
			modifyTag
		} = this.state;
		let answerOption = null;
		if (customQSetting.id) {
			answerOption = modifyTag;
		} else {
			if (customQSetting.question_type === QUANTITY) {
				answerOption = contentArray_quantity;
			} else if (customQSetting.question_type === TAG) {
				answerOption = contentArray_tag;
			}
		}

		switch (customQSetting.question_type) {
			case QUESTION:
				return (
					<TranslatedQTypeThree
						customQSetting={customQSetting}
						handleModeSettingChange={this.handleModeSettingChange}
					/>
				);
			case TAG:
				return (
					<TranslatedQTypeTwo
						customQSetting={customQSetting}
						content={answerOption}
						addDefaultSelect={this.addDefaultSelect}
						del={this.del}
						handleModeSettingChange={this.handleModeSettingChange}
					/>
				);
			case QUANTITY:
				return (
					<TranslatedQTypeOne
						customQSetting={customQSetting}
						content={answerOption}
						addDefaultSelect={this.addDefaultSelect}
						del={this.del}
						handleDefaultSetting={this.handleDefaultSetting}
						handleModeSettingChange={this.handleModeSettingChange}
					/>
				);
			default:
				break;
		}
	};

	handleModeSettingChange = e => {
		let { customQSetting } = this.state;
		switch (e.target.getAttribute("name")) {
			case "required":
				customQSetting["required"] = !customQSetting["required"];
				this.setState({ customQSetting });
				break;
			case "is_available":
				customQSetting["is_available"] = !customQSetting[
					"is_available"
				];
				this.setState({ customQSetting });
				break;
			case "multiple_selected":
				customQSetting["multiple_selected"] = !customQSetting[
					"multiple_selected"
				];
				this.setState({ customQSetting });
				break;
			case "title":
				let titleTxt = e.target.value;
				customQSetting["title"] = titleTxt;
				this.setState({ customQSetting });
				break;
			default:
				break;
		}
	};

	check = () => {
		let {
			customQSetting,
			reRender,
			contentArray_quantity,
			contentArray_tag,
			modifyTag
		} = this.state;
		const { t } = this.props;
		let content = {};
		let index = null;

		if (customQSetting.question_type === QUANTITY) {
			if (customQSetting.id) {
				index = Object.keys(modifyTag)
					.map(t => {
						return modifyTag[t].name.trim();
					})
					.indexOf("");

				Object.keys(modifyTag).map(option => {
					if (
						parseInt(modifyTag[option].min, 10) >
						parseInt(modifyTag[option].max, 10)
					) {
						let min = parseInt(modifyTag[option].max, 10),
							max = parseInt(modifyTag[option].min, 10);

						modifyTag[option].max = max;
						modifyTag[option].min = min;
					}
					return true;
				});
			} else {
				index = contentArray_quantity
					.map(t => {
						return t.name.trim();
					})
					.indexOf("");
				contentArray_quantity.map(option => {
					if (option.min > option.max) {
						let min = parseInt(option.max, 10),
							max = parseInt(option.min, 10);

						option.max = max;
						option.min = min;
					}
					return true;
				});
			}
		} else if (customQSetting.question_type === TAG) {
			if (customQSetting.id) {
				index = Object.keys(modifyTag)
					.map(t => {
						return modifyTag[t].name;
					})
					.indexOf(t('customBookingQuestionnaire.enter'));
			} else {
				index = contentArray_tag
					.map(t => {
						return t.name;
					})
					.indexOf(t('customBookingQuestionnaire.enter'));
			}
		} else if (customQSetting.question_type === QUESTION) {
			index = -1;
		}

		if (customQSetting.title.trim() === "") {
			emptyStyle = true;
			this.setState({ reRender: !reRender });
		}

		if (index > -1) {
			optionStyle = true;
			this.setState({ reRender: !reRender });
		}

		if (index === -1 && customQSetting.title.trim() !== "") {
			if (customQSetting.id) {
				customQSetting["content"] = JSON.stringify(modifyTag);
			} else {
				if (customQSetting.question_type === QUANTITY) {
					contentArray_quantity.map((c, id) => {
						content[id] = c;
						return content;
					});
				} else if (customQSetting.question_type === TAG) {
					contentArray_tag.map((c, id) => {
						content[id] = c;
						return content;
					});
				} else if (customQSetting.question_type === QUESTION) {
					content = {};
				}

				customQSetting["content"] = JSON.stringify(content);
			}

			maxIndex = 0;
			this.setState(
				{
					customQSetting,
					contentArray_quantity: [],
					contentArray_tag: [],
					contentArray_question: []
				},
				() => this.props.handleModalSubmit(customQSetting)
			);
		}
	};

	renderFooterBtn = () => {
		const { t } = this.props;
		let {
			customQSetting,
			contentArray_quantity,
			contentArray_tag,
			modifyTag
		} = this.state;
		let disableBtn = true;

		if (customQSetting.id) {
			if (
				Object.keys(modifyTag).length === 0 &&
				customQSetting.question_type !== QUESTION
			) {
				disableBtn = true;
			} else {
				disableBtn = false;
			}
		} else {
			switch (customQSetting.question_type) {
				case QUANTITY:
					if (contentArray_quantity.length === 0) {
						disableBtn = true;
					} else {
						disableBtn = false;
					}
					break;
				case TAG:
					if (contentArray_tag.length === 0) {
						disableBtn = true;
					} else {
						disableBtn = false;
					}
					break;
				case QUESTION:
					disableBtn = false;
					break;
				default:
					break;
			}
		}

		return (
			<button
				disabled={disableBtn}
				onClick={() => this.check()}
				className='button-common button-primary'>
					{t('save')}
			</button>
		);
	};

	render() {
		const { t, qIndex } = this.props;
		return (
      <Modal
        title={qIndex ? t('customBookingQuestionnaire.addTheQuestion') : t('customBookingQuestionnaire.editTheQuestion')}
        visible
        destroyOnClose
        width={640}
        closable={false}
        centered
        className="modal-base modal-xl cqModal"
        footer={
          <Space size={10}>
            <button
              className="button-common button-secondary"
              onClick={() => this.props.closeModal()}
            >
              {t("cancel")}
            </button>
            {this.renderFooterBtn()}
          </Space>
        }
        transitionName="ant-move-down"
        maskClosable={false}
      >
        <div className="modalRow">
          <div className="modalRow__title">{t('customBookingQuestionnaire.questionType')}</div>
          <div className="modalRow__value">{this.renderQType()}</div>
        </div>
        {this.renderQuestionType()}
      </Modal>
    );
	}
}

class QTypeOne extends Component {
	constructor(props) {
		super(props);
		this.state = {
			content: this.props.content,
			customQSetting: this.props.customQSetting
		};

		optionStyle = false;
		emptyStyle = false;
	}

	autoGrow = e => {
		e.target.style.height = e.target.scrollHeight + "px";
	};

	renderNoteTxt = () => {
		const { t } = this.props;
		let { customQSetting } = this.state;

		if (customQSetting.id && customQSetting.id !== -1) {
			return (
				<p className='note'>
					<i
						className='fa fa-exclamation-circle'
						aria-hidden='true'
					/>
					{t('customBookingQuestionnaire.deleted_notice')}
				</p>
			);
		}
	};

	renderNumOption = () => {
		let option = [];
		for (let i = 1; i < 100; i++) {
			option.push(i);
		}

		return option.map(o => {
			return (
				<option key={o} value={o}>
					{o}
				</option>
			);
		});
	};

	renderOptionTitle = (selection, id) => {
		const { t } = this.props;
		if (!selection.newOption) {
			//既有的題目“舊”選項不能修改選項題目
			return (
				<input
					type='text'
					maxLength="20"
					className={
						"disableInputStyle" +
						(optionStyle && selection.name.trim() === ""
							? "emptyStyle"
							: "")
					}
					placeholder={t('customBookingQuestionnaire.placeholder_selectionName')}
					value={selection.name}
					name='name'
					disabled='disabled'
				/>
			);
		} else {
			//既有的題目“新”選項能修改選項題目
			//新增的題目選項皆可修改題目
			return (
				<input
					type='text'
					maxLength="20"
					className={
						optionStyle && selection.name.trim() === ""
							? "emptyStyle"
							: ""
					}
					placeholder={t('customBookingQuestionnaire.placeholder_selectionName')}
					value={selection.name}
					name='name'
					onChange={e =>
						this.props.handleDefaultSetting(e, id, QUANTITY)
					}
				/>
			);
		}
	};

	renderNumbersTooltip = () => {
		const { t } = this.props;
		return (
      <Tooltip
        placement="bottom"
        overlayClassName="setting-tooltip"
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        title={t('customBookingQuestionnaire.range_tooltip')}
      >
        <i className="fa fa-question-circle-o" />
      </Tooltip>
    );
	};

	renderTagRow = () => {
		const { t } = this.props;
		let { content, customQSetting } = this.state;
		if (customQSetting.id) {
			//既有的題目{}
			return Object.keys(content).map((select, id) => {
				//maxIndex儲存最大index已供新增選項時使用
				//check()的時候maxIndx = 0
				if (maxIndex < parseInt(select, 10)) {
					maxIndex = parseInt(select, 10);
				}
				return (
          <div key={id} className="tagRow">
            <div>{this.renderOptionTitle(content[select], select)}</div>
            <div>
              <span>
                {t('customBookingQuestionnaire.range')}
								{this.renderNumbersTooltip()}
              </span>
              <select
                value={content[select].min}
                name="min"
                onChange={(e) =>
                  this.props.handleDefaultSetting(e, select, QUANTITY)
                }
              >
                {this.renderNumOption()}
              </select>
              <span>~</span>
              <select
                value={content[select].max}
                name="max"
                onChange={(e) =>
                  this.props.handleDefaultSetting(e, select, QUANTITY)
                }
              >
                {this.renderNumOption()}
              </select>
            </div>
            <div>
              <button className="button-init" onClick={() => this.props.del(select, QUANTITY)}>
								<div className="icon-normal delete-mask-icon"/>
              </button>
            </div>
          </div>
        );
			});
		} else {
			//新的題目[]
			return content.map((select, id) => {
				return (
					<div key={id} className='tagRow'>
						<div>{this.renderOptionTitle(select, id)}</div>
						<div>
							<span>
								{t('customBookingQuestionnaire.range')}
								{this.renderNumbersTooltip()}
							</span>
							<select
								value={select.min}
								name='min'
								onChange={e =>
									this.props.handleDefaultSetting(
										e,
										id,
										QUANTITY
									)
								}>
								{this.renderNumOption()}
							</select>
							<span>~</span>
							<select
								value={select.max}
								name='max'
								onChange={e =>
									this.props.handleDefaultSetting(
										e,
										id,
										QUANTITY
									)
								}>
								{this.renderNumOption()}
							</select>
						</div>
						<div>
							<button className="button-init" onClick={() => this.props.del(id, QUANTITY)}>
								<div className="icon-normal delete-mask-icon"/>
							</button>
						</div>
					</div>
				);
			});
		}
	};

	render() {
		const { t } = this.props;
		let { customQSetting } = this.state;
		return (
      <div>
        <div className="modalRow">
          <div className="modalRow__title">{t('customBookingQuestionnaire.question')}</div>
          <div className="modalRow__value">
            <textarea
              className={
                emptyStyle && customQSetting.title.trim() === ""
                  ? "emptyStyle"
                  : ""
              }
              name="title"
              value={customQSetting.title}
              placeholder={t('customBookingQuestionnaire.placeholder_enterQuestion')}
              onKeyUp={(e) => this.autoGrow(e)}
              onChange={(e) => this.props.handleModeSettingChange(e)}
            />
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">{t('customBookingQuestionnaire.setupOptions')}</div>
          <div className="modalRow__value">
            <button
              className="button-tertiary"
              onClick={() => this.props.addDefaultSelect(QUANTITY)}
            >
              + {t('customBookingQuestionnaire.addOption')}
            </button>
            <div className="tag-container">
              {this.renderTagRow()}
              {this.renderNoteTxt()}
            </div>
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">
            {t('customBookingQuestionnaire.requiredOrOptional')}
          </div>
          <div className="modalRow__value">
            <label className="requireLable">
              <input
                type="radio"
                checked={customQSetting.required}
                name="required"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.required')}</span>
            </label>
            <label className="requireLable">
              <input
                type="radio"
                checked={!customQSetting.required}
                name="required"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.optional')}</span>
            </label>
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">
            {t('customBookingQuestionnaire.singleAndMultipleAnswer')}
          </div>
          <div className="modalRow__value">
            <label className="requireLable">
              <input
                type="radio"
                checked={!customQSetting.multiple_selected}
                name="multiple_selected"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.single')}</span>
            </label>
            <label className="requireLable">
              <input
                type="radio"
                checked={customQSetting.multiple_selected}
                name="multiple_selected"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.multiple')}</span>
            </label>
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">
            {t('customBookingQuestionnaire.status')}
            <Tooltip
              placement="bottom"
              overlayClassName="setting-tooltip"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              title={t('customBookingQuestionnaire.status_tooltip')}
            >
              <i className="fa fa-question-circle-o" />
            </Tooltip>
          </div>
					<div className="modalRow__value">
            <label className="requireLable">
              <input
                type="radio"
                checked={customQSetting.is_available}
                name="is_available"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.released')}</span>
            </label>
            <label className="requireLable">
              <input
                type="radio"
                checked={!customQSetting.is_available}
                name="is_available"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.hidden')}</span>
            </label>
          </div>
        </div>
      </div>
    );
	}
}

class QTypeTwo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultTagArray: this.props.content,
			customQSetting: this.props.customQSetting
		};
		optionStyle = false;
		emptyStyle = false;
	}
	componentDidMount() {
		this.qeditable();
	}

	componentDidUpdate() {
		this.qeditable();
	}

	autoGrow = e => {
		e.target.style.height = e.target.scrollHeight + "px";
	};

	qeditable = () => {
		const { t } = this.props;
		let { defaultTagArray } = this.state;
		let that = this;

		$(".tagEditable").editable({
			highlight: false,
			validate: function(value) {
				if (!$.trim(value)) {
					return "不能為空";
				}
			},
			success: function(response, newValue) {
				let { idx } = this.dataset;

				defaultTagArray[idx]["name"] = newValue;

				that.setState({ defaultTagArray });
			}
		});

		$(".tagEditable").on("shown", function(e, editable) {
			e.target.previousSibling.classList.add("show");

			if (e.target.innerText !== t('customBookingQuestionnaire.enter')) {
				editable.input.$input.val(e.target.innerText);
			} else {
				editable.input.$input.val("");
			}
		});

		$(".tagEditable").on("hidden", function(e) {
			e.target.previousSibling.classList.remove("show");
		});
	};

	renderNoteTxt = () => {
		const { t } = this.props;
		let { customQSetting } = this.state;

		if (customQSetting.id && customQSetting.id !== -1) {
			return (
				<p className='note'>
					<i
						className='fa fa-exclamation-circle'
						aria-hidden='true'
					/>
					{t('customBookingQuestionnaire.deleted_notice')}
				</p>
			);
		}
	};

	renderTagRow = () => {
		const { t } = this.props;
		let { customQSetting, defaultTagArray } = this.state;
		if (customQSetting.id) {
			return (
				//既有題目{}
				Object.keys(defaultTagArray).map(tag => {
					//maxIndex儲存最大index已供新增選項時使用
					//check()的時候maxIndx = 0
					if (maxIndex < parseInt(tag, 10)) {
						maxIndex = parseInt(tag, 10);
					}
					if (defaultTagArray[tag]["newOption"]) {
						//既有題目“新增”的選項：可修改
						if (defaultTagArray[tag]["name"] === t('customBookingQuestionnaire.enter')) {
							//尚未輸入選項時顯示紅色"empty"
							return (
								<div key={tag} className='menuTagBtn empty'>
									<div
										className='deleteBtn'
										onClick={() => this.props.del(tag, TAG)}
									/>
									<div
										data-idx={tag}
										className='tagTxt tagEditable'>
										{defaultTagArray[tag].name}
									</div>
								</div>
							);
						} else {
							//已輸入選項
							return (
								<div key={tag} className='menuTagBtn'>
									<div
										className='deleteBtn'
										onClick={() => this.props.del(tag, TAG)}
									/>
									<div
										data-idx={tag}
										className='tagTxt tagEditable'>
										{defaultTagArray[tag].name}
									</div>
								</div>
							);
						}
					} else {
						//既有題目的“舊”選項：不可修改
						return (
							<div key={tag} className='menuTagBtn disableTagBtn'>
								<div
									className='deleteBtn'
									onClick={() => this.props.del(tag, TAG)}
								/>
								<div className='tagTxt'>
									{defaultTagArray[tag].name}
								</div>
							</div>
						);
					}
				})
			);
		} else {
			return (
				//新增的題目[]：選項皆可修改
				defaultTagArray.map((tag, id) => {
					if (tag.name === t('customBookingQuestionnaire.enter')) {
						return (
							<div key={id} className='menuTagBtn empty'>
								<div
									className='deleteBtn'
									onClick={() => this.props.del(id, "tag")}
								/>
								<div
									data-idx={id}
									className='tagTxt tagEditable'>
									{tag.name}
								</div>
							</div>
						);
					} else {
						return (
							<div key={id} className='menuTagBtn'>
								<div
									className='deleteBtn'
									onClick={() => this.props.del(id, TAG)}
								/>
								<div
									data-idx={id}
									className='tagTxt tagEditable'>
									{tag.name}
								</div>
							</div>
						);
					}
				})
			);
		}
	};

	render() {
		const { t } = this.props;
		let { customQSetting } = this.state;
		return (
      <div>
        <div className="modalRow">
          <div className="modalRow__title">{t('customBookingQuestionnaire.question')}</div>
          <div className="modalRow__value">
            <textarea
              name="title"
              className={
                emptyStyle && customQSetting.title.trim() === ""
                  ? "emptyStyle"
                  : ""
              }
              value={customQSetting.title}
              placeholder={t('customBookingQuestionnaire.placeholder_enterQuestion')}
              onKeyUp={(e) => this.autoGrow(e)}
              onChange={(e) => this.props.handleModeSettingChange(e)}
            />
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">{t('customBookingQuestionnaire.setupOptions')}</div>
          <div className="modalRow__value">
						<button
              className="button-tertiary"
              onClick={() => this.props.addDefaultSelect(TAG)}
            >
              + {t('customBookingQuestionnaire.addOption')}
            </button>
            <div className="qcTag-container">
              {this.renderTagRow()}
              {this.renderNoteTxt()}
            </div>
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">
            {t('customBookingQuestionnaire.requiredOrOptional')}
          </div>
          <div className="modalRow__value">
            <label className="requireLable">
              <input
                type="radio"
                checked={customQSetting.required}
                name="required"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.required')}</span>
            </label>
            <label className="requireLable">
              <input
                type="radio"
                checked={!customQSetting.required}
                name="required"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.optional')}</span>
            </label>
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">
            {t('customBookingQuestionnaire.singleAndMultipleAnswer')}
          </div>
          <div className="modalRow__value">
            <label className="requireLable">
              <input
                type="radio"
                checked={!customQSetting.multiple_selected}
                name="multiple_selected"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.single')}</span>
            </label>
            <label className="requireLable">
              <input
                type="radio"
                checked={customQSetting.multiple_selected}
                name="multiple_selected"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.multiple')}</span>
            </label>
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">
            {t('customBookingQuestionnaire.status')}
            <Tooltip
              placement="bottom"
              overlayClassName="setting-tooltip"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              title={t('customBookingQuestionnaire.status_tooltip')}
            >
              <i className="fa fa-question-circle-o" />
            </Tooltip>
          </div>
					<div className="modalRow__value">
            <label className="requireLable">
              <input
                type="radio"
                checked={customQSetting.is_available}
                name="is_available"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.released')}</span>
            </label>
            <label className="requireLable">
              <input
                type="radio"
                checked={!customQSetting.is_available}
                name="is_available"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.hidden')}</span>
            </label>
          </div>
        </div>
      </div>
    );
	}
}

class QTypeThree extends Component {
	autoGrow = e => {
		e.target.style.height = e.target.scrollHeight + "px";
	};

	render() {
		let { customQSetting, t } = this.props;
		return (
      <div>
        <div className="modalRow">
          <div className="modalRow__title">{t('customBookingQuestionnaire.question')}</div>
          <div className="modalRow__value">
            <textarea
              name="title"
              className={
                emptyStyle && customQSetting.title.trim() === ""
                  ? "emptyStyle"
                  : ""
              }
              value={customQSetting.title}
              placeholder={t('customBookingQuestionnaire.placeholder_enterQuestion')}
              onKeyUp={(e) => this.autoGrow(e)}
              onChange={(e) => this.props.handleModeSettingChange(e)}
            />
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">
            {t('customBookingQuestionnaire.requiredOrOptional')}
          </div>
          <div className="modalRow__value">
            <label className="requireLable">
              <input
                type="radio"
                checked={customQSetting.required}
                name="required"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.required')}</span>
            </label>
            <label className="requireLable">
              <input
                type="radio"
                checked={!customQSetting.required}
                name="required"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.optional')}</span>
            </label>
          </div>
        </div>
        <div className="modalRow">
          <div className="modalRow__title">
            {t('customBookingQuestionnaire.status')}
            <Tooltip
              placement="bottom"
              overlayClassName="setting-tooltip"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              title={t('customBookingQuestionnaire.status_tooltip')}
            >
              <i className="fa fa-question-circle-o" />
            </Tooltip>
          </div>
					<div className="modalRow__value">
            <label className="requireLable">
              <input
                type="radio"
                checked={customQSetting.is_available}
                name="is_available"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.released')}</span>
            </label>
            <label className="requireLable">
              <input
                type="radio"
                checked={!customQSetting.is_available}
                name="is_available"
                onChange={(e) => this.props.handleModeSettingChange(e)}
              />
              <span>{t('customBookingQuestionnaire.hidden')}</span>
            </label>
          </div>
        </div>
      </div>
    );
	}
}

const QTag = props => {
	let min = props.min === undefined ? "" : `(${props.min}`,
		max = props.max === undefined ? "" : `~${props.max})`;

	return (
		<div className='menuTagBtn'>
			{props.name}
			{min}
			{max}
		</div>
	);
};

const TranslatedCustomQuestionModal = withTranslation('settings')(CustomQuestionModal);
const TranslatedQTypeBlock = withTranslation('settings')(QTypeBlock);
const TranslatedQTypeOne = withTranslation('settings')(QTypeOne);
const TranslatedQTypeTwo = withTranslation('settings')(QTypeTwo);
const TranslatedQTypeThree = withTranslation('settings')(QTypeThree);
export default withTranslation('settings')(CustomQuestion);