import React from 'react';
import { useTranslation } from 'react-i18next';

import '../menuUser.scss';


const MenuAccountBindFooter = () => {
  const { i18n } = useTranslation();

	return (
    <footer>
      <div>
        <a href="mailto:findlife99@gmail.com">
					<img src={require('../../../../images/pageIdx/email.png')} alt="" />
				</a>
				<a href="https://www.facebook.com/menushoptw/" target="_blank" rel="noopener noreferrer">
					<img src={require('../../../../images/pageIdx/fb.png')} alt="" />
				</a>
      </div>
      <div>
        {i18n.language === 'zh' && (
          <>
            找活股份有限公司版權所有
            <br />
          </>
        )}
        ＠2016 FindLife Inc. All rights reserved.
      </div>
    </footer>
	);
};

export default MenuAccountBindFooter;

