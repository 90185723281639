import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import BookingSystemApi from "../booking_settings/BookingSystemApi";
import { checkDudooTableSync } from "../../api/posSync";

import verifiedIcon from "../../images/icon_verified.svg";
import loadingIcon from "../../images/btn-loading.svg";
import ConfirmModal from "../popup/ConfirmModal";
import { Space, Switch } from "antd";
import SettingsSaveButton from "../commons/SettingsSaveButton";

import "../../sass/dudooPosSync.scss";

const API = new BookingSystemApi();

function DudooPosSync() {
  const { t } = useTranslation("settings");
  const auth = useSelector((state) => state.auth);
  const { shop } = auth;
  const { pos_enabled } = shop;

  const [init, setInit] = useState(false);
  const [sending, setSending] = useState(false);
  const [dudooAppId, setDudooAppId] = useState("");
  const [canvasEnabled, setCanvasEnabled] = useState(false); // 桌位圖開關
  const [fetchDudooSyncEnabled, setFetchDudooSyncEnabled] = useState(false); // 儲存後的狀態
  const [dudooSyncEnabled, setDudooSyncEnabled] = useState(false); // 編輯時的狀態
  const [isSyncSuccessful, setIsSyncSuccessful] = useState(false);
  const [isCheckSuccessful, setIsCheckSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    const getBookingSetting = async () => {
      try {
        const data = await API.getBookingSetting();
        const {
          pos_enabled,
          pos_settings_matched,
          canvas_enabled,
          dudoo_app_id,
        } = data;

        setInit(true);
        setDudooAppId(dudoo_app_id);
        setFetchDudooSyncEnabled(pos_enabled);
        setDudooSyncEnabled(pos_enabled);
        setIsSyncSuccessful(pos_settings_matched);
        setCanvasEnabled(canvas_enabled);
      } catch (err) {
        console.log("dudoo getBookingSetting err---", err);
        window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
      }
    };

    getBookingSetting();
  }, [t]);

  const onChange = () => {
    setDudooSyncEnabled((prev) => !prev);
  };

  const handleRetry = useCallback(async () => {
    try {
      setIsLoading(true);

      if (!canvasEnabled) {
        throw new Error("Canvas mode is disabled, skipping check");
      }

      await checkDudooTableSync();
      setIsCheckSuccessful(true);
      setIsSyncSuccessful(true);
      window.app.alert.setMessage(
        t("dudooPosSettings.pls_mappingConsistent"),
        "done"
      );
    } catch (error) {
      setIsSyncSuccessful(false);
      window.app.alert.setMessage(
        t("dudooPosSettings.pls_mappingInconsistent"),
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  }, [canvasEnabled, t]);

  const submit = async () => {
    try {
      setSending(true);

      if (!canvasEnabled && dudooSyncEnabled && !fetchDudooSyncEnabled)
        return setShowConfirmModal(true);
      if (dudooSyncEnabled) {
        try {
          await checkDudooTableSync();
          setIsSyncSuccessful(true);
        } catch (error) {
          setShowConfirmModal(true);
          setIsSyncSuccessful(false);

          console.error("Sync check error", error);
          window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
          return;
        }
      }

      const posEnabled = JSON.stringify({
        booking_settings: {
          pos_enabled: dudooSyncEnabled,
        },
      });

      await API.updateBookingSetting(posEnabled);
      setFetchDudooSyncEnabled(dudooSyncEnabled);
      window.app.alert.setMessage(t("status.saved"), "done");
    } catch (error) {
      console.error("Dudoo settings update error", error);
      window.app.alert.setMessage(t("status.pls_tryAgainLater"), "error");
    } finally {
      setSending(false);
    }
  };

  const renderSyncButton = () => {
    return (
      <div className="mb-32">
        <hr style={{ margin: "0 0 32px" }} />
        <div className="row_subtitle-16 mb-4">
          {t("dudooPosSettings.tableMappingCheck")}
        </div>
        <div className="additional_text">
          {t("dudooPosSettings.note_tableMappingCheck")}
        </div>
        <div className="syncButton-block">
          <div
            className={`syncText ${
              isSyncSuccessful ? "success_text" : "error_text"
            }`}
          >
            {isSyncSuccessful
              ? t("dudooPosSettings.recentlyUpdatedTable")
              : t("dudooPosSettings.tableConfigurationInconsistencies")}
          </div>
          {isSyncSuccessful ? (
            isCheckSuccessful ? (
              <button className="btn-sync-success">
                <Space size={4}>
                  <img src={verifiedIcon} alt="" />
                  {t("dudooPosSettings.checkConsistency")}
                </Space>
              </button>
            ) : (
              <button onClick={handleRetry}>
                {isLoading ? (
                  <img src={loadingIcon} alt="" />
                ) : (
                  t("dudooPosSettings.checkNow")
                )}
              </button>
            )
          ) : (
            <button onClick={handleRetry}>
              {t("dudooPosSettings.recheck")}
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderEnabled = () => {
    if (pos_enabled) {
      return (
        <>
          <div className="settingTbl">
            <div className="settingTblRow fix-settingTblRow">
              <div className="header fix-header">
                {t("dudooPosSettings.enableFeature")}
              </div>
              <div className="text fix-text">
                <Switch
                  onChange={onChange}
                  checked={dudooSyncEnabled}
                />
              </div>
            </div>
          </div>
          <div className="mb-32">
            <div className="row_subtitle-16 mb-4">
              {t("dudooPosSettings.dudooPOSAccountConnected")}
            </div>
            <input
              className="row_input"
              type="text"
              disabled
              value={dudooAppId ?? t("dudooPosSettings.notSet")}
            />
            <div className="additional_text">
              {t("dudooPosSettings.notice_dudooPOSAccountConnected")}
            </div>
          </div>
          {fetchDudooSyncEnabled && dudooSyncEnabled && renderSyncButton()}
          <div className="block_notice">
            <div className="title">{t("dudooPosSettings.notes")}</div>
            <ul>
              <li>{t("dudooPosSettings.note_1")}</li>
              <li>{t("dudooPosSettings.note_2")}</li>
              <li>{t("dudooPosSettings.note_3")}</li>
              <li>
                {t("dudooPosSettings.note_4")}
                <ul>
                  <li>{t("dudooPosSettings.note_4_1")}</li>
                  <li>{t("dudooPosSettings.note_4_2")}</li>
                  <li>{t("dudooPosSettings.note_4_3")}</li>
                </ul>
              </li>
            </ul>
          </div>
        </>
      );
    } else {
      return (
        <div className="content_notActivated">
          {t("dudooPosSettings.notYetActivated")}
        </div>
      );
    }
  };

  if (!init) return <div className="pageLoading">{t("status.loading")}</div>;
  return (
    <>
      <div className="setting-container dudooSyncSettings">
        {sending && (
          <div className="lightBoxLayer">
            <h4>{t("status.saving")}</h4>
          </div>
        )}
        <h3>{t("dudooPosSettings.title")}</h3>
        <h5>{t("dudooPosSettings.description")}</h5>
        <hr />
        {renderEnabled()}
      </div>
      {pos_enabled && <SettingsSaveButton handleSaved={submit} showDivider />}
      {showConfirmModal && (
        <ConfirmModal
          title={t("dudooPosSettings.popup.featureUnavailable_title")}
          content={t("dudooPosSettings.popup.featureUnavailable_content")}
          submit={() => {
            setShowConfirmModal(false);
            setDudooSyncEnabled(false);
          }}
        />
      )}
    </>
  );
}

export default DudooPosSync;
