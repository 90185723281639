import React from "react";
import { Link } from "react-router";
import { useTranslation } from 'react-i18next';

const AnnouncementIcon = () => {
	const { t } = useTranslation();
  return (
    <div className="dashboard-item">
      <Link to="/dashboard/announcement" role="button">
        <div className="dashboard-thumbnail">
          <img
            alt="booking"
            src={require("../../images/dashboard/bulletin.svg")}
          />
          <div className="dashboard-system-description text-body">
            {t('dashboard:announcementDescription')}
          </div>
          <div className="horizontal-between">
            <h3 className="heading-3-responsive">{t('common:system.bulletin')}</h3>
            <div className="icon-normal doubleArrowRight-icon" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default AnnouncementIcon;
