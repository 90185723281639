import React, { Fragment } from "react";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";

import {
  CrmCustomerBookingRecord,
  CrmCustomerQueueRecord,
  CrmCustomerRecord,
} from "./CrmCustomerRecord";

const queueStatus = {
  waiting: "等候中",
  seated: "已報到",
  canceled: "已經取消",
  passed: "過號",
  inactive: "失效",
};

const EmptyList = () => {
  const { t } = useTranslation("customer");
  return <div className="lists_empty">{t("info.listDefault")}</div>;
};

const CrmCustomerRecordList = ({
  customQ,
  bookingRecords,
  queueRecords,
  customerRecords,
  startIdx,
  endIdx,
  page,
  count,
  pageSize,
  pageClick,
  setCount,
  resetPageSetting,
  activeTab,
  setActiveTab,
  setSelectRecord,
  setShowRecordEditorModal,
}) => {
  const { t } = useTranslation("customer");

  const handleRecordTab = (tab) => {
    if (tab === activeTab) return;

    if (tab === "booking") setCount(bookingRecords.length);
    if (tab === "queue") setCount(queueRecords.length);
    if (tab === "customer") setCount(customerRecords.length);

    setActiveTab(tab);
    resetPageSetting();
  };

  const renderRecordTab = () => {
    const Tabs = {
      booking: t("info.bookingRecord.title"),
      queue: t("info.queueRecord.title"),
      customer: t("info.customerRecord.title"),
    };

    return (
      <div className="tabs">
        {Object.keys(Tabs).map((tab, index) => {
          let counts = "";

          if (tab === "booking") counts = bookingRecords.length;
          if (tab === "queue") counts = queueRecords.length;
          if (tab === "customer") counts = customerRecords.length;

          return (
            <div
              key={index}
              className={tab === activeTab ? "active" : ""}
              onClick={() => handleRecordTab(tab)}
            >
              {Tabs[tab]} {`(${counts})`}
            </div>
          );
        })}
      </div>
    );
  };

  const renderRecordStatus = () => {
    const bookingRecordStatus = {
      effective: t("info.bookingRecord.validBooking"),
      no_show: t("info.bookingRecord.noShow"),
      cancel: t("info.bookingRecord.canceledBooking"),
    };

    const queueRecordStatus = {
      seated: t("info.queueRecord.checkedIn"),
      canceled: t("info.queueRecord.cancelled"),
      passed: t("info.queueRecord.missed"),
      inactive: t("info.queueRecord.invalid"),
    };

    if (activeTab === "booking" && bookingRecords.length !== 0) {
      return (
        <div className="statusBar">
          {Object.keys(bookingRecordStatus).map((option, index) => {
            let statusBookings = [];

            if (option === "effective") {
              statusBookings = bookingRecords.filter(
                (r) =>
                  r.booking.status !== "no_show" &&
                  r.booking.status !== "cancel"
              );
            } else if (option === "no_show" || option === "cancel") {
              statusBookings = bookingRecords.filter(
                (r) => r.booking.status === option
              );
            }

            return (
              <div key={index}>
                <div className="num">{statusBookings.length}</div>
                {bookingRecordStatus[option]}
              </div>
            );
          })}
        </div>
      );
    }

    if (activeTab === "queue" && queueRecords.length !== 0) {
      return (
        <div className="statusBar">
          {Object.keys(queueRecordStatus).map((option, index) => {
            let statusQueues = queueRecords.filter(
              (r) => r.status_t === queueStatus[option]
            );

            return (
              <div key={index}>
                <div className="num">{statusQueues.length}</div>
                {queueRecordStatus[option]}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const renderRecords = () => {
    if (activeTab === "booking" && bookingRecords.length !== 0) {
      return (
        <Fragment>
          {bookingRecords.slice(startIdx, endIdx).map((item, idx) => {
            return (
              <CrmCustomerBookingRecord
                key={item.booking.id}
                data={item.booking}
                customQ={customQ}
              />
            );
          })}
        </Fragment>
      );
    }

    if (activeTab === "queue" && queueRecords.length !== 0) {
      return (
        <Fragment>
          {queueRecords.slice(startIdx, endIdx).map((item, idx) => {
            return (
              <CrmCustomerQueueRecord
                data={item}
                key={item.id}
                customQ={customQ}
              />
            );
          })}
        </Fragment>
      );
    }

    if (activeTab === "customer" && customerRecords.length !== 0) {
      return (
        <Fragment>
          {customerRecords.slice(startIdx, endIdx).map((item, idx) => {
            return (
              <CrmCustomerRecord
                data={item}
                key={item.id}
                setSelectRecord={setSelectRecord}
                setShowRecordEditorModal={setShowRecordEditorModal}
              />
            );
          })}
        </Fragment>
      );
    }

    return <EmptyList />;
  };

  const renderPagination = () => {
    if (activeTab === "booking" && bookingRecords.length === 0) return;
    if (activeTab === "queue" && queueRecords.length === 0) return;
    if (activeTab === "customer" && customerRecords.length === 0) return;

    return (
      <Pagination
        className="crm_record_page_picker"
        current={page}
        total={count}
        pageSize={pageSize}
        showSizeChanger={false}
        showPrevNextJumpers={false}
        onChange={pageClick}
        itemRender={(_, type, originalElement) => {
          if (type === "prev") {
            return <span>{t("list.page_pre")}</span>;
          }
          if (type === "next") {
            return <span>{t("list.page_next")}</span>;
          }
          return originalElement;
        }}
      />
    );
  };

  return (
    <div className="crmCustomerRecordList">
      {renderRecordTab()}
      <div className="lists">
        {renderRecordStatus()}
        <div className="records">{renderRecords()}</div>
        {renderPagination()}
      </div>
    </div>
  );
};

export default CrmCustomerRecordList;
