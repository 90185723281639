import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation, Trans } from "react-i18next";
import { browserHistory } from "react-router";
import $ from "jquery";
import moment from "moment";
import _ from "lodash";

import useScrollableArrows from "../../../hooks/useScrollableArrows";

import BookingSystemApi from "../../booking_settings/BookingSystemApi";

import { CommomSettingContext } from "../NewModuleReducer/CommomReuducer";
import { BaseSettingContext } from "../NewModuleReducer/BaseSettingReducer";
import { ListStateContext } from "../NewModuleReducer/ListReducer";

import del from "../../../images/newModule/delete.svg";

const BookingAPI = new BookingSystemApi();

const seatedTimePanelNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, "del", 0, "check"];

const QueueTags = ({ changeQueueStatusTab }) => {
  const { t } = useTranslation("queueSystem");
  const { CommomSettingState } = useContext(CommomSettingContext);
  const { todayDate, date, isToday } = CommomSettingState;

  const { ListReducerState } = useContext(ListStateContext);
  const { activeQueueStatusTab, showOverdue } = ListReducerState;

  const queueStatusTabJson = {
    waiting: t("status.waiting"),
    standby: t("status.missed"),
    canceled: t("status.cancelled_2"),
    overdue: t("status.invalid"),
  };

  const renderQueueStatus = () => {
    let today = moment(new Date(todayDate)),
      queryDate = moment(new Date(date));

    let isPassedDay = queryDate.isBefore(today);

    if (isToday && !isPassedDay) {
      //當日不顯示失效
      return Object.keys(queueStatusTabJson).map((status) => {
        let active =
          status === activeQueueStatusTab ? "queueSystem__status-active" : "";
        if (status === "overdue") {
          return null;
        }
        return (
          <li
            key={status}
            className={`${active} btn-effect`}
            onClick={() => {
              changeQueueStatusTab(status);
            }}
          >
            {queueStatusTabJson[status]}
          </li>
        );
      });
    } else if (!isToday && isPassedDay) {
      //昨日: 1.不顯示等候中 2.失效有才顯示
      return Object.keys(queueStatusTabJson).map((status) => {
        let active =
          status === activeQueueStatusTab ? "queueSystem__status-active" : "";
        if (status === "waiting") {
          return null;
        } else if (status === "overdue" && !showOverdue) {
          return null;
        }

        return (
          <li
            key={status}
            className={`${active} btn-effect`}
            onClick={() => {
              changeQueueStatusTab(status);
            }}
          >
            {queueStatusTabJson[status]}
          </li>
        );
      });
    }
  };
  return (
    <div className="queueSystem__status">
      <ul>{renderQueueStatus()}</ul>
    </div>
  );
};

const QueueGroups = ({
  queueList,
  tagContentsObject,
  filterQueueListByGroup,
  expandedSystemList,
}) => {
  const { t } = useTranslation("queueSystem");
  const { scrollRef, showLeftArrow, showRightArrow } =
    useScrollableArrows(queueList);
  const { ListReducerState } = useContext(ListStateContext);
  const { activeGroupTag } = ListReducerState;

  const statusTabWidth = 72;
  const scrollAmount = statusTabWidth * 2;

  const handleLeftArrowClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  const handleRightArrowClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const allActive =
    activeGroupTag === "all" ? "queueSystem__queueGroup-active" : "";
  return (
    <div className="queueSystem__status-bar scrollable-tabs">
      {showLeftArrow && (
        <div className="scroll-left-arrow" onClick={handleLeftArrowClick}>
          <div className="arrowLeft-icon-dark" />
        </div>
      )}
      <ul ref={scrollRef} className="queueSystem__queueGroup">
        <li
          className={`${allActive} btn-effect`}
          onClick={() => {
            filterQueueListByGroup("all");
            expandedSystemList(true);
          }}
        >
          {t("status.all")}
          <div>{queueList.length}</div>
        </li>
        {Object.keys(tagContentsObject).map((tag, index) => {
          const activeStyle =
            activeGroupTag === tag
              ? "queueSystem__queueGroup-active"
              : "btn-effect";
          return (
            <li
              key={index}
              className={activeStyle}
              onClick={() => {
                filterQueueListByGroup(tag);
                expandedSystemList(true);
              }}
            >
              {tag}
              <div>{tagContentsObject[tag]}</div>
            </li>
          );
        })}
      </ul>
      {showRightArrow && (
        <div className="scroll-right-arrow " onClick={handleRightArrowClick}>
          <div className="arrowRight-icon-dark" />
        </div>
      )}
    </div>
  );
};

const QueueStartNew = ({ openQueueSystem, showQueueAddBtn }) => {
  const { t } = useTranslation("queueSystem");
  if (showQueueAddBtn) {
    return (
      <div className="queueDefault">
        <div className="text-title color-subtitle">
          {t("newModule.notOpenQueuePeriod")}
        </div>
        <button
          className="btn-effect button-secondary btn-text-md color-subtitle"
          onClick={() => openQueueSystem("start")}
        >
          {t("panel.startQueuePeriod")}
        </button>
      </div>
    );
  }
};

const ToSetQueueGroup = () => {
  const { t } = useTranslation("queueSystem");
  const toSetting = () => {
    browserHistory.push({
      pathname: "/dashboard/setting/group",
    });
  };

  return (
    <div className="queueDefault">
      <div className="text-title color-subtitle">
        {t("newModule.queueTypeUndefined")}
      </div>
      <button
        className="btn-effect button-secondary btn-text-md color-subtitle"
        onClick={toSetting}
      >
        <span className="icon-sm doubleArrowRight-icon" />
        {t("option.setting")}
      </button>
    </div>
  );
};

const QueueEmpty = () => {
  const { t } = useTranslation("queueSystem");
  return (
    <div className="QueueEmpty queueDefault">
      <img
        src={require("../../../images/queue/img_customer_empty.png")}
        alt=""
      />
      <p className="text-title color-subtitle">{t("newModule.empty")}</p>
    </div>
  );
};

const SeatedPanel = ({
  checkToSeated,
  backToChooseTable,
  updateAfterSeated,
  closeQueueSelectedTable,
  setSeatedSuccess,
  setSeatedSuccessData,
  setBookingListInfoVisible,
}) => {
  const { t } = useTranslation("bookingSystem");
  const { BaseSettingState } = useContext(BaseSettingContext);
  const { diningTime } = BaseSettingState;

  const { CommomSettingState, setUuid } = useContext(CommomSettingContext);
  const { queueSeatedData, selectedBookingTableData } = CommomSettingState;

  const [queue, setQueue] = useState(queueSeatedData);
  const [table, setTable] = useState(selectedBookingTableData);
  const [step, setStep] = useState(1); //1選桌子 2輸入時間
  const [currentTime, setCurrentTime] = useState(moment());
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setBookingListInfoVisible(false);
    setQueue(queueSeatedData);
  }, [queueSeatedData, setBookingListInfoVisible]);

  useEffect(() => {
    setTable(selectedBookingTableData);
  }, [selectedBookingTableData]);

  const toSeated = () => {
    const bookingTime_min = moment().minute();
    const diff = 5 - (bookingTime_min % 5);
    const bookingTime_date = moment().add(diff, "m");

    setCurrentTime(bookingTime_date);

    setStep(2);
    checkToSeated();
  };

  const backToStep1 = () => {
    setStep(1);
    backToChooseTable();
  };

  const renderSelectedTableCount = useCallback(() => {
    const seatMaxAttendance = table.reduce(
      (prev, cur) => prev + cur.max_attendance,
      0
    );
    const chosenTableStyle = table.length > 0 ? "chosenTableStyle" : "";

    return (
      <React.Fragment>
        <p>
          <Trans
            i18nKey="bookingSystem:tableMap.selectedTable"
            values={{ tables: table.length }}
            components={{
							countStyle: <span className={chosenTableStyle} />,
						}}
          />
        </p>
        <p>
          <Trans
            i18nKey="bookingSystem:tableMap.tableCapacity"
            values={{ attendance: seatMaxAttendance }}
            components={{
							countStyle: <span className={chosenTableStyle} />,
						}}
          />
        </p>
      </React.Fragment>
    );
  }, [table]);

  const createBooking = (dataSubmit, checkBtn) => {
    BookingAPI.createBooking(dataSubmit)
      .then(() => {
        checkBtn.disabled = false;
        closeQueueSelectedTable();
        updateAfterSeated();
        setSeatedSuccessData(dataSubmit.last_name);
        setSeatedSuccess(true);
        setDisabled(false);
        setTimeout(() => {
          setSeatedSuccess(false);
          setSeatedSuccessData("");
        }, 3000);
      })
      .catch((error) => {
        console.log("createBooking error----", error);
        checkBtn.disabled = false;
        window.app.alert.setMessage(
          t("settings:status.pls_tryAgainLater"),
          "error"
        );
        setDisabled(false);
        setSeatedSuccess(false);
        setSeatedSuccessData("");
      });
  };

  const bookingSubmit = async (currentTime, leaveTime, checkBtn) => {
    setDisabled(true);

    const currentTimeFormat = currentTime.format("YYYY-MM-DD HH:mm");

    const diningTime = leaveTime.diff(moment(currentTimeFormat), "minute");
    const newSeat = _.cloneDeep(table);
    let dataSubmit = {};
    const customerInfoData = {
      customer: {
        last_name: queue.name,
        email: queue.email,
        phone_number: queue.phone.trim(),
        tele_number: "",
        gender: queue.gender,
        note: "",
      },
    };

    newSeat.forEach((t) => {
      t["max_seat"] = t.max_attendance;
      t["min_seat"] = t.min_attendance;
      t["table_number"] = t.name;

      delete t["max_attendance"];
      delete t["min_attendance"];
      delete t["name"];
      delete t["canvas_name"];
      delete t["canvas_space_id"];
      delete t["created_at"];
      delete t["height"];
      delete t["nextBooking"];
      delete t["positionX"];
      delete t["positionY"];
      delete t["shop_id"];
      delete t["table_type"];
      delete t["timeline"];
      delete t["updated_at"];
      delete t["width"];
    });

    //set uuid
    const eventId = uuidv4();
    setUuid(eventId);
    dataSubmit["event_id"] = eventId;

    dataSubmit["last_name"] = queue.name;
    dataSubmit["email"] = queue.email;
    dataSubmit["phone_number"] = queue.phone.trim();
    dataSubmit["gender"] = queue.gender;
    dataSubmit["booking_datetime"] = currentTimeFormat;
    dataSubmit["attendance"] = queue.people_count;
    dataSubmit["dining_time"] = diningTime;
    dataSubmit["seating_json"] = JSON.stringify(newSeat);
    //排隊入座
    dataSubmit["source"] = "line_up";
    dataSubmit["queue_record_id"] = queue.id;
    dataSubmit["shop_memo"] = queue.shop_memo;
    dataSubmit["memo"] = queue.memo;
    dataSubmit["waiting_time"] = moment().diff(
      moment(queue.created_at),
      "minutes"
    );

    BookingAPI.createCustomer(customerInfoData)
      .then(() => {})
      .catch(() => {})
      .then(() => {
        createBooking(dataSubmit, checkBtn);
      });
  };

  const emptyTableStyle = table.length === 0 ? "emptyTableStyle" : "";

  const renderStep = () => {
    if (step === 1) {
      return (
        <div className="seatedPanel text-center">
          <div className="seatedPanel__body">
            <div className="seatedPanel__body__top">
              <p className="seatedPanel__name">
                {t("tableMap.customer")} {queue.name}
              </p>
              <p>
                <Trans
                  i18nKey="bookingSystem:tableMap.totalAttendance"
                  values={{ attendance: queue.people_count }}
                >
                  共<span>{queue.people_count}</span>人即將入座
                </Trans>
              </p>
            </div>
            <div className="seatedPanel__body__bottom">
              {renderSelectedTableCount()}
            </div>
          </div>

          <div className={`seatedPanel__bottom ${emptyTableStyle}`}>
            <button onClick={() => toSeated()} disabled={table.length === 0}>
              {t("buttonStatus.seated")}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <SeatedTimePanel
          diningTime={diningTime}
          currentTime={currentTime}
          table={table}
          backToStep1={backToStep1}
          disabled={disabled}
          bookingSubmit={bookingSubmit}
        />
      );
    }
  };

  return <React.Fragment>{renderStep()}</React.Fragment>;
};

const SeatedTimePanel = ({
  currentTime,
  table,
  diningTime,
  backToStep1,
  disabled,
  bookingSubmit,
}) => {
  const { t } = useTranslation("bookingSystem");
  const checkBtnRef = useRef();
  const [longestDiningTime] = useState(8 * 60); //最長diningTime 8小時
  const [SeatedTimePanelCount, setSeatedTimePanelCount] = useState(0);
  const [displayTime, setDisplayTime] = useState("");
  const [timeCell, setTimeCell] = useState(["-", "-", ":", "-", "-"]);
  const [latestBookingTime, setLatestBookingTime] = useState([]);

  useEffect(() => {
    let hasNextBooking = false,
      closestTime = diningTime;

    if (table.length !== 0) {
      //計算最短dining time
      table.forEach((t) => {
        if (Object.keys(t.nextBooking).length !== 0) {
          hasNextBooking = true;
          const bookingTimestamp = moment(t.nextBooking.booking_datetime);
          const bookingDiff = moment.duration(
              bookingTimestamp.diff(currentTime)
            ),
            durationDiff = bookingDiff.asMinutes();

          if (closestTime > durationDiff) {
            closestTime = durationDiff;
          }
        }
      });
    } else {
      hasNextBooking = false;
    }

    if (!hasNextBooking) {
      //所選的桌子都沒有下一筆預約:
      //最晚預約時間longestDiningTime
      //顯示時間預設dining time
      const LatestBookingTime = moment(currentTime)
        .add(longestDiningTime, "m")
        .format("YYYY/MM/DD HH:mm");
      const time = moment(currentTime)
        .add(diningTime, "m")
        .format("HH:mm")
        .split("");
      setTimeCell(time);
      setLatestBookingTime(LatestBookingTime);
    } else {
      //所選的桌子都有下一筆預約: 最短的dining time
      const LatestBookingTime = moment(currentTime)
        .add(closestTime, "m")
        .format("YYYY/MM/DD HH:mm");
      const time = moment(currentTime)
        .add(closestTime, "m")
        .format("HH:mm")
        .split("");
      setTimeCell(time);
      setLatestBookingTime(LatestBookingTime);
    }
  }, [table, currentTime, longestDiningTime, diningTime]);

  useEffect(() => {
    const time = timeCell.join("");
    setDisplayTime(time);
  }, [timeCell]);

  const timeClick = (keyboard) => {
    let newTime = displayTime.split("");

    if (SeatedTimePanelCount === 0) {
      newTime = ["-", "-", ":", "-", "-"];
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(1);
    } else if (SeatedTimePanelCount === 1) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(3);
    } else if (SeatedTimePanelCount === 3) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(4);
    } else if (SeatedTimePanelCount === 4) {
      newTime[SeatedTimePanelCount] = keyboard;
      setSeatedTimePanelCount(0);
    }

    setTimeCell(newTime);
  };

  const timeDelete = () => {
    let newTime = displayTime.split("");

    if (SeatedTimePanelCount === 0) {
      newTime[4] = "-";
      setSeatedTimePanelCount(4);
    } else if (SeatedTimePanelCount === 4) {
      newTime[3] = "-";
      setSeatedTimePanelCount(3);
    } else if (SeatedTimePanelCount === 3) {
      newTime[1] = "-";
      setSeatedTimePanelCount(1);
    } else if (SeatedTimePanelCount === 1) {
      newTime[0] = "-";
      setSeatedTimePanelCount(0);
    }

    setTimeCell(newTime);
  };

  const timeCheck = () => {
    const displayTime_hour = parseInt(displayTime.substring(0, 2), 10),
      displayTime_min = parseInt(displayTime.substring(3), 10);

    if (displayTime_hour >= 24) {
      return window.app.alert.setMessage(
        t("tableMap.pls_enterCorrectTimeFormat"),
        "error"
      );
    }

    if (displayTime_min > 59) {
      return window.app.alert.setMessage(
        t("tableMap.pls_enterCorrectTimeFormat"),
        "error"
      );
    }

    if (displayTime_min % 5 !== 0) {
      return window.app.alert.setMessage(
        t("settings:bookingTimesCommon.pls_minimumTimeUnit"),
        "error"
      );
    }

    const todayDate = moment(new Date()).format("YYYY-MM-DD"),
      tomorrowDate = moment(new Date()).add(1, "day").format("YYYY-MM-DD");

    const latestBookingTimeMoment = moment(new Date(latestBookingTime));

    const displayTimeMoment_today = moment(todayDate + " " + displayTime);
    const displayTimeMoment_tomorrow = moment(tomorrowDate + " " + displayTime);

    const expiredTable = $(".queueTable.removeStyle").length;

    if (expiredTable > 0) {
      return window.app.alert.setMessage(
        t("tableMap.pls_removeWillBeSeatedTables"),
        "error"
      );
    }

    const $checkBtn = checkBtnRef.current;
    $checkBtn.disabled = true;

    if (
      displayTimeMoment_today.isAfter(currentTime) &&
      displayTimeMoment_today.isSameOrBefore(latestBookingTimeMoment)
    ) {
      $checkBtn.disabled = true;
      bookingSubmit(currentTime, displayTimeMoment_today, $checkBtn);
    } else if (
      displayTimeMoment_tomorrow.isAfter(currentTime) &&
      displayTimeMoment_tomorrow.isSameOrBefore(latestBookingTimeMoment)
    ) {
      $checkBtn.disabled = true;
      bookingSubmit(currentTime, displayTimeMoment_tomorrow, $checkBtn);
    } else {
      $checkBtn.disabled = false;
      window.app.alert.setMessage(t("tableMap.pls_outOfTimeRange"), "error");
    }
  };

  const renderKeyboardCell = () => {
    return seatedTimePanelNumber.map((keyboard, index) => {
      if (keyboard === "del") {
        return (
          <div
            key={index}
            className="keyboardCell keyboardCell-del"
            onClick={() => timeDelete()}
          >
            <img src={del} alt="delete" />
          </div>
        );
      } else if (keyboard === "check") {
        return (
          <button
            ref={checkBtnRef}
            key={index}
            style={{ opacity: disabled ? 0.3 : 1 }}
            className="keyboardCell keyboardCell-check"
            onClick={() => timeCheck()}
          >
            {t("settings:ok")}
          </button>
        );
      } else {
        return (
          <div
            key={index}
            className="keyboardCell"
            onClick={() => timeClick(keyboard)}
          >
            {keyboard}
          </div>
        );
      }
    });
  };

  return (
    <div className="seatedTimePanelWrap">
      <span className="seatedTimePanel__back">
        <button onClick={() => backToStep1()}>
          {t("popup.rearrangeTable")}
        </button>
      </span>
      <div className="seatedTimePanel text-center">
        <div className="seatedTimePanel__title">
          {t("tableMap.estimatedDepartureTime")}
        </div>
        <div className="seatedTimePanel__header">
          <span style={{ fontWeight: "bold" }}>{displayTime}</span>
          <p>
            {t("tableMap.theLatestAppointmentTime")} {latestBookingTime}
          </p>
        </div>
        <div className="seatedTimePanel__body">
          <div className="keyboard">{renderKeyboardCell()}</div>
        </div>
      </div>
    </div>
  );
};

export {
  QueueTags,
  QueueGroups,
  QueueStartNew,
  ToSetQueueGroup,
  QueueEmpty,
  SeatedPanel,
};
