import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Header from "./announcementHeader/AnnouncementHeader";
import List from "./AnnouncementListView";

import AddModal from "./announcementPopup/AnnouncementAddModal";

import {
  handleAuthority,
  resetAnnouncementList,
} from "../../actions/announcementAction";

const AnnouncementPanel = () => {
  const auth = useSelector((state) => state.auth);
  const { shop } = auth;
  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const { showAddAnnouncementModal } = announcementInfo;
  const dispatch = useDispatch();

  useEffect(() => {
    if (shop.id === 2569) {
      dispatch(handleAuthority("official"));
    } else {
      dispatch(handleAuthority("shop"));
    }

    return () => dispatch(resetAnnouncementList());
  }, [dispatch, shop]);

  return (
    <div className="announcementPanel system-layout">
      <Header />
      <List />
      {showAddAnnouncementModal && <AddModal />}
    </div>
  );
};

export default AnnouncementPanel;
