import React from "react";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

import SMSCreditsWithBtn from "../commons/SMSCreditsWithBtn";
import BookingSearch from "../Search";
import BulletinIcon from "../commons/BulletinDisplayIcon";

export default function BookingModeAction({
  mode,
  monthTotalEffectiveCount = 0,
  monthTotalAttendance = 0,
  smsCredits = 0,
  startTableBooking,
  showNewAddBookingModal,
  openQueueGroupPanel,
  isQueueButtonEnabled,
  searchType,
}) {
  const { t } = useTranslation("bookingSystem");
  const queryParams = new URLSearchParams(window.location.search);
  const date = queryParams.get("date");

  const renderAnnouncementBtn = () => {
    if (mode === "calendar") {
      return <BulletinIcon mode="month" />;
    } else {
      return <BulletinIcon mode="day" />;
    }
  };

  const renderExportBtn = () => {
    return (
      <a
        className="action-display-button"
        href={
          mode === "calendar"
            ? window.domain +
              "/dashboard/bookings.csv?yyyymm=" +
              moment(date, "YYYY-MM-DD").format("YYYYMM")
            : window.domain + "/dashboard/bookings.csv?date=" + date
        }
      >
        <button>
          <div className="icon-normal export-icon dark" />
        </button>
      </a>
    );
  };

  return (
    <div
      className={`horizontal-between bookingPanel-toolbar bookingPanel-toolbar-${mode}`}
    >
      {mode === "calendar" ? (
        <Space
          size={[10, 4]}
          wrap
          className="text-caption calendar-bookingCount"
        >
          {t("validBookings")}
          <span>|</span>
          <Space size={4}>
            <span className="icon-round">
              <div className="reservation-icon icon-sm" />
            </span>
            <span>
              {t("count.groupCount")}
              {monthTotalEffectiveCount}
            </span>
          </Space>
          <Space size={4}>
            <span className="icon-round">
              <div className="account-icon icon-sm" />
            </span>
            <span>
              {t("count.guestCount")}
              {monthTotalAttendance}
            </span>
          </Space>
        </Space>
      ) : (
        <SMSCreditsWithBtn smsCredits={smsCredits} />
      )}
      <Space size={8}>
        <BookingSearch searchType={searchType} />
        {renderAnnouncementBtn()}
        {renderExportBtn()}
        {mode !== "calendar" && (
          <div className="bookingPanel-action">
            {mode !== "list" && (
              <button
                onClick={openQueueGroupPanel}
                type="button"
                className="button-round-big button-secondary"
                disabled={!isQueueButtonEnabled}
              >
                <div className="plus-icon icon-sm" />
                {t("queue")}
              </button>
            )}
            <button
              onClick={
                mode === "tablemap" ? startTableBooking : showNewAddBookingModal
              }
              type="button"
              className="button-round-big button-primary"
            >
              <div className="plus-icon icon-sm" />
              {t("bookings")}
            </button>
          </div>
        )}
      </Space>
    </div>
  );
}
