import React from "react";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation("time");
  return (
    <div className="week-names">
      <div className="day-container">
        <span className="day">{t("sun")}</span>
      </div>
      <div className="day-container">
        <span className="day">{t("mon")}</span>
      </div>
      <div className="day-container">
        <span className="day">{t("tue")}</span>
      </div>
      <div className="day-container">
        <span className="day">{t("wed")}</span>
      </div>
      <div className="day-container">
        <span className="day">{t("thu")}</span>
      </div>
      <div className="day-container">
        <span className="day">{t("fri")}</span>
      </div>
      <div className="day-container">
        <span className="day">{t("sat")}</span>
      </div>
    </div>
  );
};
