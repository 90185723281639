import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { CouponStateContext } from "../MenuCouponContext/MenuCouponContext";
import MenuCouponApi from "../MenuCouponApi";
import { useTranslation } from "react-i18next";

const menuCouponAPI = new MenuCouponApi();

const PauseCouponPopup = () => {
  const { t } = useTranslation("couponSystem");
  const auth = useSelector((state) => state.auth);
  const { name } = auth.shop;
  const { CouponState, CouponDispatch } = useContext(CouponStateContext);
  const { couponName, couponLists, couponId } = CouponState;

  const cancelPaused = async () => {
    const findIndex = couponLists.map((list) => list.id).indexOf(couponId);
    const couponSetting = couponLists[findIndex];
    const fd = new FormData();

    fd.append("shopUserId", couponSetting.shopUserId);
    fd.append("id", couponSetting.id);
    fd.append("status", "enable");

    try {
      await menuCouponAPI.updateCoupon(fd);
      const list = await menuCouponAPI.getCoupon(name);
      CouponDispatch({ type: "confirmCancelPausePopup" });
      CouponDispatch({ type: "getCouponList", list });
    } catch (err) {
      console.log("updateCoupon err-----", err);
    }
  };

  const cancelPopup = () => {
    CouponDispatch({ type: "cancelPopup" });
  };

  return (
    <div className="menuCouponPopupCard">
      <div className="menuCouponPopupCard__header">
        <h2>{t("popup.cancelPauseConfirmation")}</h2>
      </div>
      <div className="menuCouponPopupCard__body">
        <p>{t("popup.cancelPauseConfirmation_content")}</p>
        <p>
          {t("coupon.title")}：{couponName}
        </p>
      </div>
      <div className="menuCouponPopupCard__footer">
        <div className="footrBtnWrap">
          <button className="grayThemeBtn" onClick={() => cancelPopup()}>
            {t("cancel")}
          </button>
          <button className="greenThemeBtn" onClick={() => cancelPaused()}>
            {t("ok")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PauseCouponPopup;
