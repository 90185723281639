import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, DatePicker } from "antd";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import {
  createCrmCustomer,
  updateCrmCustomerTags,
  getCrmCustomerTags,
} from "../../api/CrmCustomerApi";
import useBlockedList from "../../utils/useBlockedList";
import ConfirmBlockedCustomerModal from "./ConfirmBlockedCustomerModal";
import CheckModal from "../../../popup/CheckModal";
import { EMAIL_REGEX, DIGITS_ONLY_REGEX } from "../../../../utils/regex";
import { getTagsTranslationKey } from "../../utils/data";

const defaultPhonePlaceholder = "0912345678";

const CrmCustomerEditorModal = ({ load, selectedBooking, cancelPopup }) => {
  const { t } = useTranslation("customer");
  const [data, setData] = useState({
    last_name: selectedBooking.last_name,
    gender: selectedBooking.gender,
    phone_number: selectedBooking.phone_number,
    birthday: "",
    email: selectedBooking.email,
    note: "",
    company: "",
    company_id: "",
    company_tele: "",
  });
  const [displayChecked, setDisplayChecked] = useState([]);
  const [displayTags, setDisplayTags] = useState([]);

  const [blockedList, setBlockedList] = useState([]);
  const [blockedNumberError, setBlockedNumberError] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [showConfirmBlockedListModal, setShowConfirmBlockedListModal] =
    useState(false);
  const [showCancelEditModal, setShowCancelEditModal] = useState(false);
  const [isSaved, setIsSaved] = useState(true);

  const customerNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const emailRef = useRef(null);

  const { response } = useBlockedList();

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    if (response !== null) {
      setBlockedList(response);

      if (response.includes(selectedBooking.phone_number)) {
        setBlockedNumberError(true);
        phoneNumberRef.current.classList.add("input_error_empty");
      }
    }
  }, [response, selectedBooking]);

  const validateData = () => {
    const errorPhonePlaceholder = t(
      "bookingSystem:addBooking.placeholder_phone"
    );

    const setError = (field, ref, newPlaceholder, message) => {
      error.push(field);
      ref.current.classList.add("input_error_empty");
      if (newPlaceholder) {
        ref.current.placeholder = newPlaceholder;
      }
      window.app.alert.setMessage(message, "error");
    };

    const clearError = (field, ref, placeholder) => {
      const index = error.indexOf(field);
      if (index !== -1) error.splice(index, 1);
      ref.current.classList.remove("input_error_empty");
      if (placeholder) {
        ref.current.placeholder = placeholder;
      }
    };

    let error = [];

    if (data.last_name.trim() === "") {
      setError(
        "last_name",
        customerNameRef,
        "",
        t("editCustomerPopup.pls_enterNameAndPhone")
      );
    } else {
      clearError("last_name", customerNameRef, "");
    }

    if (data.phone_number.trim() === "") {
      setError(
        "phone_number",
        phoneNumberRef,
        errorPhonePlaceholder,
        t("editCustomerPopup.pls_enterNameAndPhone")
      );
    } else {
      clearError("phone_number", phoneNumberRef, defaultPhonePlaceholder);
    }

    if (data.email.trim() !== "" && !EMAIL_REGEX.test(data.email)) {
      setError("email", emailRef, "", "");
      setEmailFormatError(true);
    } else {
      clearError("email", emailRef, "");
      setEmailFormatError(false);
    }

    if (error.length !== 0) return;
    if (blockedNumberError) return setShowConfirmBlockedListModal(true);

    submit();
  };

  const submit = async () => {
    try {
      const result = await createCrmCustomer(data);
      await tagsSubmit(result.data.meta);
      cancelPopup();
      load();
      setIsSaved(true);
    } catch (error) {
      if (error.response.status === 422) {
        window.app.alert.setMessage(
          t("editCustomerPopup.pls_customerAlreadyExist"),
          "error"
        );
      } else {
        window.app.alert.setMessage(error.response.data.message, "error");
      }
    }
  };

  const tagsSubmit = async (id) => {
    const checkedTags = {
      checked: displayChecked,
      id: id,
    };

    try {
      await updateCrmCustomerTags(checkedTags).then((res) =>
        window.app.alert.setMessage(t("editCustomerPopup.addSuccess"), "done")
      );

      if (typeof load !== "undefined") {
        load();
      }
    } catch (err) {
      window.app.alert.setMessage(err.response.data.message, "error");
    }
  };

  const getTags = async () => {
    try {
      const { data } = await getCrmCustomerTags();
      // let tagsOptions = [];

      // data.customer_tags.map((tag) => {
      //   tagsOptions.push({
      //     label: tag.description,
      //     value: `${tag.id}`,
      //   });
      // });

      setDisplayTags(data.customer_tags);
    } catch (err) {
      console.log("get tags err ----------", err);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const isNumericInput =
      value.trim().length > 0 ? DIGITS_ONLY_REGEX.test(value.trim()) : true;

    let newData = _.cloneDeep(data);

    if (name === "last_name")
      customerNameRef.current.classList.remove("input_error_empty");
    if (name === "email")
      emailRef.current.classList.remove("input_error_empty");
    if (
      (name === "phone_number" || name === "company_tele") &&
      !isNumericInput
    ) {
      return;
    }

    if (name === "phone_number") {
      const isBlockedNumber = blockedList.includes(value);

      if (isBlockedNumber) {
        setBlockedNumberError(true);
        phoneNumberRef.current.classList.add("input_error_empty");
      } else {
        setBlockedNumberError(false);
        phoneNumberRef.current.classList.remove("input_error_empty");
        phoneNumberRef.current.placeholder = defaultPhonePlaceholder;
      }
    }

    newData[name] = value;
    setData(newData);
    setIsSaved(false);
  };

  const handleTagChange = useCallback((id) => {
    setDisplayChecked((prevChecked) => {
      if (prevChecked.includes(id)) {
        return prevChecked.filter((tagId) => tagId !== id);
      } else {
        return [...prevChecked, id];
      }
    });
  }, []);

  const renderTags = () => {
    return displayTags.map((tag) => {
      return (
        <label key={tag.id}>
          <input
            type="checkbox"
            value={tag.id}
            checked={displayChecked.includes(tag.id)}
            onChange={() => handleTagChange(tag.id)}
          />
          <span>{t(getTagsTranslationKey(tag.description))}</span>
        </label>
      );
    });
  };

  const renderDatePicker = () => {
    const disabledDate = (current) => {
      // Can not select days after today
      const time = moment().endOf("day").subtract(1, "days");
      return current && current > time;
    };

    const handleDateChange = (date, dateString) => {
      let newData = _.cloneDeep(data);
      newData["birthday"] = dateString;
      setData(newData);
    };

    return (
      <div className="customer_datepicker">
        <DatePicker
          showToday={false}
          defaultValue={null}
          dateFormat="YYYY-MM-DD"
          disabledDate={disabledDate}
          onChange={handleDateChange}
          placeholder="YYYY-MM-DD"
          inputReadOnly
        />
      </div>
    );
  };

  const handleCancel = () => {
    if (isSaved) {
      cancelPopup();
    } else {
      setShowCancelEditModal(true);
    }
  };

  return (
    <Modal
      title={t("editCustomerPopup.addCustomer")}
      visible
      centered
      closable={false}
      className="crmCustomerEditorModal"
      footer={
        <div className="btn_action">
          <div>
            <button
              type="button"
              className="button-common button-secondary"
              onClick={handleCancel}
            >
              {t("settings:cancel")}
            </button>
            <button
              type="button"
              className="button-common button-primary"
              onClick={validateData}
            >
              {t("settings:save")}
            </button>
          </div>
        </div>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className="wrap">
        <div className="customer_basic">
          <h4>{t("editCustomerPopup.addCustomer")}</h4>
          <div className="customer_row">
            <div className="title">{t("editCustomerPopup.name")}</div>
            <input
              type="text"
              ref={customerNameRef}
              name="last_name"
              placeholder={t("editCustomerPopup.placeholder_name")}
              value={data.last_name}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="customer_row">
            <div className="title">{t("editCustomerPopup.gender")}</div>
            <select
              name="gender"
              value={data.gender}
              onChange={(e) => handleInputChange(e)}
              placeholder=""
            >
              <option value="other">{t("editCustomerPopup.other")}</option>
              <option value="male">{t("editCustomerPopup.male")}</option>
              <option value="female">{t("editCustomerPopup.female")}</option>
            </select>
          </div>
          <div className="customer_row">
            <div className="title">{t("editCustomerPopup.phone")}</div>
            <input
              type="text"
              ref={phoneNumberRef}
              name="phone_number"
              placeholder="0912345678"
              value={data.phone_number}
              onChange={(e) => handleInputChange(e)}
            />
            {blockedNumberError && (
              <div className="text text_error">
                {t("bookingSystem:addBooking.isBlockedPhone")}
              </div>
            )}
          </div>
          <div className="customer_row">
            <div className="title">Email</div>
            <input
              type="text"
              ref={emailRef}
              name="email"
              placeholder="abc@example.com"
              value={data.email}
              onChange={(e) => handleInputChange(e)}
            />
            {emailFormatError && (
              <div className="text text_error">
                {t("bookingSystem:addBooking.pls_validEmail")}
              </div>
            )}
          </div>
          <div className="customer_row customer_row_checkbox">
            <div className="title">{t("info.tags")}</div>
            {renderTags()}
          </div>
          <hr />
        </div>
        <div className="customer_advanced">
          <h5>{t("editCustomerPopup.advancedOptional")}</h5>
          <div className="customer_row">
            <div className="title">{t("info.birth")}</div>
            {renderDatePicker()}
          </div>
          <div className="customer_row">
            <div className="title">{t("info.companyName")}</div>
            <input
              type="text"
              name="company"
              placeholder="MENU店+"
              onChange={(e) => handleInputChange(e)}
              value={data.company}
            />
          </div>
          <div className="customer_row">
            <div className="title">{t("info.companyPhone")}</div>
            <input
              type="text"
              name="company_tele"
              placeholder="0212345678"
              onChange={(e) => handleInputChange(e)}
              value={data.company_tele}
            />
          </div>
          <div className="customer_row">
            <div className="title">{t("info.texId")}</div>
            <input
              type="text"
              name="company_id"
              placeholder="66668888"
              onChange={(e) => handleInputChange(e)}
              value={data.company_id || ""}
            />
          </div>
          <div className="customer_row">
            <div className="title">{t("info.remarks")}</div>
            <textarea
              name="note"
              placeholder={t("editCustomerPopup.placeholder_remarks")}
              onChange={(e) => handleInputChange(e)}
              value={data.note}
            />
          </div>
        </div>
      </div>

      {showCancelEditModal && (
        <CheckModal
          title={t("popup.discardAdd_title")}
          content={t("popup.discardAddCustomer_content")}
          submit={() => {
            setShowCancelEditModal(false);
            cancelPopup();
          }}
          cancel={() => setShowCancelEditModal(false)}
        />
      )}

      {showConfirmBlockedListModal && (
        <ConfirmBlockedCustomerModal
          type="create"
          setShowConfirmBlockedListModal={setShowConfirmBlockedListModal}
          submit={submit}
        />
      )}
    </Modal>
  );
};

export default CrmCustomerEditorModal;
