import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Tooltip } from 'antd';
import { SearchOutlined, TagFilled, CheckOutlined } from '@ant-design/icons';
import _ from 'lodash';

import useDebounce from '../utils/useDebounce';
import DeleteConfirmModal from './crmSettingsPopup/DeleteConfirmModal';

const fakeTags = [
  {
    name: '標記',
    id: 1
  },
  {
    name: '這是一個名字很長超出範圍的標記',
    id: 2
  },
  {
    name: '超級無敵奧客',
    id: 3
  },
  {
    name: '測試測試測試',
    id: 4
  },
  {
    name: '尊榮貴賓',
    id: 5
  },
  {
    name: '111222333',
    id: 6
  },
  {
    name: 'abcdefghijklmnopqrstuvwxyz',
    id: 7
  },
]

const CrmSettingsTags = (props) => {
  const [ isSaved, setIsSaved ] = useState(true);
  const [ tagList, setTagList ] = useState(fakeTags); // []
  const [ currentList, setCurrentList ] = useState(fakeTags); // []
  const [ addTagName, setAddTagName ] = useState('');
  const [ addTagError, setAddTagError ] = useState(null); // existence: 輸入中已存在, empty: 新增空白標記
  const [ searchTagName, setSearchTagName] = useState('');
  const [ editTagId, setEditTagId ] = useState(null);
  const [ editTagName, setEditTagName ] = useState('');
  const [ editTagError, setEditTagError ] = useState(null); // existence: 輸入中已存在, empty: 新增空白標記
  const [ selectTag, setSelectTag ] = useState('');
  const [ showDeleteConfirmModal, setShowDeleteConfirmModal ] = useState(false);

  const textareaRef = useRef(null);
  const debouncedSearchTag = useDebounce(searchTagName, 300);

  // const emojiRegex = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;

	const routerWillLeave = useCallback(
		() => {
			if (!isSaved) return '您尚未儲存正在編輯中的標記。確定離開嗎？';
		},
		[ isSaved ]
	);

	useEffect(
		() => {
			props.router.setRouteLeaveHook(props.route, routerWillLeave);
		},
		[ props, routerWillLeave ]
	);

  useEffect(() => {
    textareaFocus();
  }, [ editTagId ]);

  useEffect(() => {
    const textArea = document.getElementsByClassName('edit_tag_input')[0];

    if(textArea) {
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }, [ editTagName ]);

  const handleDebounceSearch = useCallback((list = tagList) => {
    let newSearchTags = [];

    list.forEach((tag) => {
      if (tag.name.includes(debouncedSearchTag)) {
        newSearchTags.push(tag);
      }
    });

    setCurrentList(newSearchTags);
  }, [debouncedSearchTag, tagList]);

  useEffect(() => {
    if(debouncedSearchTag || searchTagName) handleDebounceSearch();
  }, [ debouncedSearchTag, handleDebounceSearch, searchTagName ]);

  const textareaFocus = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      // 移動光標到內容末尾
      textareaRef.current.selectionStart = textareaRef.current.value.length;
      textareaRef.current.selectionEnd = textareaRef.current.value.length;
    }
  };

  const handleTagName = (e) => {
    let value = e.target.value;
    let tagName = value.replace(/\s*/g, ""); // 去除所有空白

    if(tagList.some(tag => tag.name === tagName)) {
      setAddTagError('existence');
    } else {
      setAddTagError(null);
    }

    // if(emojiRegex.test(value)) {
    //   setAddTagName(value.replace(emojiRegex, ''));
    // } else {
    //   setAddTagName(value);
    // }
    setAddTagName(value);
  };

  const addTag = () => {
    let newTags = _.cloneDeep(tagList);
    let tag = {
      name: addTagName,
      id: tagList.length + 1
    };

    if(addTagName.trim() === '') {
      return setAddTagError('empty');
    }

    if(addTagError) return;

    newTags.unshift(tag);

    setTagList(newTags);
    setCurrentList(newTags);
    setAddTagName('');
    window.app.alert.setMessage('已新增標記', 'done');
  };

  const handleEditTag = (tag) => {
    if(editTagId) return;

    setEditTagId(tag.id);
    setEditTagName(tag.name);
    setIsSaved(false);
  };

  const handleEditTagChange = (e) => {
    let value = e.target.value;
    let tagName = value.replace(/\s*/g, ""); // 去除所有空白
    let tagIdx = tagList.findIndex((t) => t.id === editTagId);

    if(tagList.some(tag => tag.name === tagName)) {
      // 輸入和與原本標記相同名稱不顯示錯誤，與其他標記相同才顯示
      if(tagList[tagIdx].name !== tagName) {
        setEditTagError('existence');
      } else {
        setEditTagError(null);
      }
    } else {
      setEditTagError(null);
    }

    // if(emojiRegex.test(value)) {
    //   setEditTagName(value.replace(emojiRegex, ''));
    // } else {
    //   setEditTagName(value);
    // }
    setEditTagName(value);
  };

  const handleShowDeleteConfirmModalToggle = () => {
    setSelectTag('');
    setShowDeleteConfirmModal(!showDeleteConfirmModal);
  };

  const handleSelectTag = (tag) => {
    setSelectTag(tag);
    setShowDeleteConfirmModal(true);
  };

  const deleteTag = () => {
    let newTags = _.cloneDeep(tagList);
    let tagIdx = tagList.findIndex((t) => t.id === selectTag.id);

    newTags.splice(tagIdx, 1);
    setTagList(newTags);

    if(searchTagName) {
      handleDebounceSearch(newTags);
    } else {
      setCurrentList(newTags);
    }

    setShowDeleteConfirmModal(false);
    window.app.alert.setMessage('已刪除標記', 'done');
  };

  const saveEditTag = () => {
    let newTags = _.cloneDeep(tagList);
    let tagIdx = tagList.findIndex((tag) => tag.id === editTagId);

    if(editTagName.trim() === '') {
      textareaFocus();
      setEditTagError('empty');
      return;
    }

    if(editTagError) return;

    newTags[tagIdx].name = editTagName;
    setTagList(newTags);
    setCurrentList(newTags);
    closeEditTag();
    setEditTagError(null);
    setIsSaved(true);
    window.app.alert.setMessage('已更新標記', 'done');
  };

  const closeEditTag = () => {
    setEditTagId(null);
    setEditTagName('');
    setEditTagError(null);
  };

  const searchTag = (value) => {
    if(value.length === 0) {
      setSearchTagName('');
      setCurrentList(tagList);

      return;
    }

    setSearchTagName(value);
  };

  const renderEditTagError = () => {
    let errorText = '';

    if(editTagError === 'existence') {
      errorText = '此標記已建立';
    } else if(editTagError === 'empty') {
      errorText = '標記內容不得為空白';
    }

    return <div className='edit_tag_error'>{errorText}</div>;
  }

  const renderAddTagError = () => {
    let errorText = '';

    if(addTagError === 'existence') {
      errorText = '此標記已建立';
    } else if(addTagError === 'empty') {
      errorText = '請輸入標記文字';
    }

    return <div className='settingList__error'>{errorText}</div>;
  };

  const renderCurrentList = () => {
    return (
			<div className='crmSettings__block'>
        <div className={`crmSettings__block_list ${editTagId ? 'editing_tag' : ''}`}>
          {
            currentList.map((tag) => {
              return (
                <div
                  className={`tag_block ${editTagId === tag.id ? 'edit_tag_name' : ''}`}
                  key={tag.id}
                >
                  <div className='tag_title'>
                    <div className='tag_name'>
                      <TagFilled className='tag_icon'/>
                      {
                        editTagId === tag.id ?
                        <textarea
                          className='edit_tag_input'
                          ref={textareaRef}
                          value={editTagName}
                          placeholder='請輸入標記文字'
                          onChange={(e) => handleEditTagChange(e)}
                        /> :
                        <Tooltip
                          title={tag.name}
                          trigger='click'
                          placement='bottom'
                          color='#333'
                          overlayClassName='tag_tooltip'
                          getPopupContainer={triggerNode => triggerNode.parentNode} // scroll position fixed
                        >
                          <span className='tag_name_display'>{tag.name}</span>
                        </Tooltip>
                      }
                    </div>
                    <div className='tag_num'>{'0'}名顧客</div>
                    { editTagId === tag.id && renderEditTagError() }
                  </div>
                  {
                    editTagId === tag.id ?
                    <div className='tag_edit'>
                      <CheckOutlined className='tag_edit_confirm' onClick={saveEditTag} />
                      <div className='tag_edit_cancel' onClick={closeEditTag} />
                    </div> :
                    <div className='tag_action'>
                      <button onClick={() => handleEditTag(tag)}>
                        <div className='tag_action_edit'/>
                      </button>
                      <button onClick={() => handleSelectTag(tag)}>
                        <div className='tag_action_delete' />
                      </button>
                    </div>
                  }
                </div>
              )
            })
          }
        </div>
			</div>
		)
  };

  const renderListEmpty = () => {
    if(searchTagName.length !== 0) {
      return (
        <div className='crmSettings__block'>
          <div className='crmSettings__search-empty'>無相符的標記。</div>
        </div>
      )
    } else {
      return <div className='crmSettings_empty'>您尚未設定任何標記。</div>
    }
  }

  return (
    <div className='crmSettings crmSettingsTag'>
      <div className='crmSettings_bar'>
        <h2>標記管理</h2>
        <div className='crmSettings_search'>
          <SearchOutlined />
          <input
            type="text"
            value={searchTagName}
            placeholder='搜尋標記'
            onChange={(e) => searchTag(e.target.value)}
            disabled={editTagId}
          />
          { searchTagName.length > 0 && 
						<img
							src={require('../../../images/announcement/icon_close.svg')}
							onClick={() => searchTag('')}
							alt=''
						/>
          }
        </div>
      </div>
      <div className='crmSettings_list'>
        <div className='crmSettings_input'>
          <>
            <input
              type="text"
              className={`${addTagError ? 'crmSettings_input-error' : ''} form-control`}
              value={addTagName}
              onChange={(e) => handleTagName(e)}
              disabled={editTagId || searchTagName.length !== 0}
              placeholder='新增標記'
            />
            <button
              className='crmSettings_btn_add'
              onClick={addTag}
              disabled={editTagId || searchTagName.length !== 0}
            >
              新增
            </button>
          </>
        { addTagError && renderAddTagError() }
        </div>
        { currentList.length > 0 ? renderCurrentList() : renderListEmpty() }
      </div>

      { showDeleteConfirmModal &&
        <DeleteConfirmModal
          type='settingTag'
          submit={deleteTag}
          cancel={handleShowDeleteConfirmModalToggle}
          selectTag={selectTag}
        />
      }
    </div>
  )
}

export default CrmSettingsTags;