import React, { useEffect, useState, useCallback, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const TableInput = ({ groups, tables, addTable, removeAddTable }) => {
	const { t } = useTranslation('settings');
	const [ tableName, setTableName ] = useState('');
	const inputRef = useRef();

	const checkTable = useCallback(
		(e) => {
			if (e.key === 'Enter' || e === 'submit') {
				const flattenTableNumbers = _.flatten(
					groups.map(group => 
						group.table_objs?.filter(obj => obj.available === true).map(obj => obj.table_number) || []
					)
				);
				const tablesNumber = tables.filter((t) => t.available === true).map((table) => table.table_number);
				const concatTableNumbers = _.concat(flattenTableNumbers, tablesNumber);

				const findIndex = concatTableNumbers.indexOf(tableName.trim());

				if (tableName.trim() === '') {
					return window.app.alert.setMessage(t('tableSettings.pls_tableNameCannotBeEmpty'), 'error');
				}

				if (findIndex !== -1) {
					return window.app.alert.setMessage(t('tableSettings.pls_tableNameMustBeUnique'), 'error');
				}

				addTable(tableName);
				setTableName('');
			}
		},
		[ groups, tables, tableName, addTable, t ]
	);

	useEffect(() => {
		if (inputRef.current) inputRef.current.focus();

		return () => {
			inputRef.current = null;
		};
	}, []);

	const remove = () => {
		setTableName('');
		removeAddTable();
	};

	return (
		<div className="tableInput">
			<div>
				<input
					ref={inputRef}
					autoFocus={true}
					className="newTableInput"
					type="text"
					value={tableName}
					onKeyUp={(e) => checkTable(e)}
					onChange={(e) => setTableName(e.target.value.trim())}
				/>
			</div>

			<button className="addBtn" name="submit" onClick={() => checkTable('submit')}>
				<i className="fa fa-check" aria-hidden="true" />
			</button>
			<button className="cancelBtn" onClick={remove}>
				<i className="fa fa-times" aria-hidden="true" />
			</button>
		</div>
	);
};

export default TableInput;
