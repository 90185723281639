import React from 'react'
import $ from 'jquery'
import { config } from '../../utils/config'
import { handleError } from '../../libs/handler'
import moment from 'moment/min/moment-with-locales.min'
import { withTranslation } from 'react-i18next';
import SettingsSaveButton from '../commons/SettingsSaveButton'

moment.locale('zh-tw');

let getDate = new Date();
let currentYear = getDate.getFullYear();

class BookingUnavailableDatesConfig extends React.Component {
  constructor(props){
    super(props)
    this.load();
    this.state = {
      activeYear: currentYear,
      disableDates: [],
      init: false,
      isSaved: true,
      disableDatesObj:{},
      sending: false
    }
    this.chooseDate = this.chooseDate.bind(this);
    this.removeDisableDates = this.removeDisableDates.bind(this);
    this.nextYear = this.nextYear.bind(this);
    this.preYear = this.preYear.bind(this);
    this.createRows = this.createRows.bind(this);
  }
  routerWillLeave = (nextLocation) => {
    const { t } = this.props;
    // return false to prevent a transition w/o prompting the user,
    // or return a string to allow the user to decide:
    // return `null` or nothing to let other hooks to be executed
    //
    // NOTE: if you return true, other hooks will not be executed!
    if(!this.state.isSaved) return t('leaveNotice');
  }
  componentDidMount(){
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
  }

  load = () => {
    $.ajax({url: config.domain + '/dashboard/booking_settings', 
          method: 'GET', 
          xhrFields: { withCredentials: true }})
      .done(function(data){
        this.setState({disableDates: data.disable_dates ,init: true});
      }.bind(this))
      .fail(function(xhr){
        handleError(xhr);
      });
  }
  submit = () => {
    const { t } = this.props;
    this.setState({sending: true})
    $.ajax({url: window.domain + '/dashboard/booking_settings', 
          method: 'PATCH',
          dataType: "json",
          processData: false,
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify({
            booking_settings: {
              disable_dates: this.state.disableDates,
            }
          }),
          xhrFields: { withCredentials: true }})
      .done((data) => {
        this.setState({isSaved: true, sending: false})
        window.app.alert.setMessage(t('status.saved'),'done')
      })
      .fail(function(xhr){
        handleError(xhr);
        this.setState({sending: false})
        window.app.alert.setMessage(t('status.pls_tryAgainLater'),'done')
      });
  }
  

  createRows(year, month){
    let {disableDatesObj, disableDates} = this.state;

    let createTable=[];

    let firstday = new Date(year, month-1, 1),  //查詢月份的第一天
        dayOfWeek = firstday.getDay(), //第一天星期幾 0:Sun 1:Mon 2:Tues
        days_per_month = new Date(year,month,0).getDate();  //當月天數

    let days_per_lastMonth =  new Date(year, month-1, 0).getDate(); //上個月天數
    let todayStyle='',
        vacantStyle='vacant',
        chosenDateStyle='';

    let str_nums = Math.ceil((dayOfWeek + days_per_month)/7)  //日曆需要幾行

    disableDates.map((item)=>{
      return disableDatesObj[item]=1;
    })

    for(let i =0; i< str_nums ; i++){
      let tableTr=[];
      for(let j=0; j<7; j++){
        let dayIndex = 7 * i + j; //建立索引, 從0開始
        let date = dayIndex - dayOfWeek + 1;
        let cell;
        let dateKey =  dayIndex - dayOfWeek + 1;
        if(date <= 0){
          date = date + days_per_lastMonth;
          vacantStyle = 'vacant';
        }
        else if(date > days_per_month){
            vacantStyle = 'vacant';
            date = date - days_per_month;
        }
        else{
          date= dayIndex - dayOfWeek + 1;
          vacantStyle='';
        }

        if (dateKey<10 && dateKey>0){
          dateKey = "0"+dateKey
        }

        if(month<10 && month>0 && month.toString().length<2){
          month = "0"+ month;
        }

        cell = year+'-'+month+'-'+dateKey;
    

        if(disableDatesObj[cell] === 1){
          chosenDateStyle = "chosenDate" ;
        }else{
          chosenDateStyle = "" ;
        }

        tableTr.push(
          <div key={dateKey} data-dayindex={dateKey} data-month={month} data-year={year} className={["tableCell", todayStyle, vacantStyle].join(' ')}>
            <span className={chosenDateStyle} onClick={(e)=> this.chooseDate(e)}>
              <span>
                {date}
              </span>
            </span>
          </div>
        )
      }

      createTable.push(
        <div key={i} className="tableRow tableCellRow">{tableTr}</div>
      )
    }

      return  createTable;
  }

  chooseDate(e){
    const { parentNode } = e.target;
    const { disableDates } = this.state;

    let invalidDate = parentNode.parentNode.className.indexOf("vacant"),
        chosenDateStyle =  parentNode.className.indexOf("chosenDate");

    let chosenDay = parentNode.parentNode.dataset.dayindex,
        chosenMonth = parentNode.parentNode.dataset.month,
        chosenYear = parentNode.parentNode.dataset.year;

    let disableDay = chosenYear + "-" + chosenMonth + "-" + chosenDay;

    if(chosenYear && chosenMonth && chosenDay){
      if(invalidDate === -1){
        if(chosenDateStyle === -1){
          e.target.parentNode.className = "chosenDate";
          this.state.disableDates.push(disableDay);
          this.state.disableDates.sort();
        }else{
          e.target.parentNode.className = "";
          this.removeDisableDates(disableDates, disableDay)
          this.state.disableDates.sort();
        }
      }
    }

    this.setState({isSaved: false})
  }

  removeDisableDates(array, ele){
    const index = array.indexOf(ele);
    array.splice(index, 1)
  }

  createFullYearMonth(){
    let {activeYear} = this.state;
    return(
      [1,2,3,4,5,6,7,8,9,10,11,12].map((e, i)=>{
        return(
          <TranslatedMonth
            key={i}
            createRows={this.createRows(activeYear, e)}
            showYear={activeYear}
            showMonth = {e}
          />
        )
      })
    )
  }

  nextYear(){
    let {activeYear} = this.state;
    this.setState({activeYear:activeYear+1})
  }

  preYear(){
    let {activeYear} = this.state;
    this.setState({activeYear:activeYear-1})
  }

  render() {
    const { t } = this.props;
    if (this.state.init !== true) return <div className="pageLoading">{t('status.loading')}</div>;
    return (
      <>
        <div className="setting-container">
          {this.state.sending && (
              <div className='lightBoxLayer'>
                <h4>{t('status.saving')}</h4>
              </div>
            )}
          <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1}}>
              <h3>{t('unavailableBookingDates.title')}</h3>
              <h5>{t('unavailableBookingDates.description')}</h5>
            </div> 
          </div>
          <hr />
          <div className="disableDateCalendar">
          <h1> 
              <span onClick={()=> this.preYear()}> <i className="fa fa-chevron-left" aria-hidden="true"></i> </span>
              <span>
                {this.state.activeYear} 
              </span>
              <span onClick={()=> this.nextYear()}> <i className="fa fa-chevron-right" aria-hidden="true"></i> </span>

              <span className="note">
                <div className="dis_dot"></div>
                {t('unavailableBookingDates.unavailable')}
              </span>
              
            </h1>
          
            <div className="radio monthWrapper">
              {
                this.createFullYearMonth()
              }
            </div>
          </div>
          <br />
        </div>
        <SettingsSaveButton handleSaved={this.submit} showDivider/>
      </>
    );
  }
}

class Month extends React.Component {
  render() {
    const { t, showMonth, showYear, createRows } = this.props;

    const monthNames = [];
    for (let i = 1; i <= 12; i++) {
      monthNames[i] = t(`months.${i}`);
    }

    const daysOfWeek = [
      t('sun'),
      t('mon'),
      t('tue'),
      t('wed'),
      t('thu'),
      t('fri'),
      t('sat')
    ];

    return (
      <div className="calendarTable">
        <div className="tableMonth">
          {monthNames[showMonth]}, {showYear}
        </div>
        <div className="tableRow tableRowTitle">
          {daysOfWeek.map((day, index) => (
            <div key={index} className="tableCell">{day}</div>
          ))}
        </div>
        <div className="monthCell">
          {createRows}
        </div>
      </div>
    );
  }
}
const TranslatedMonth = withTranslation('time')(Month);
export default withTranslation('settings')(BookingUnavailableDatesConfig);

