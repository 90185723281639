import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import BookingSystemApi from "../../booking_settings/BookingSystemApi";

const BookingAPI = new BookingSystemApi();
export default (updateBooking) => {
  const { t } = useTranslation("settings");
  const [conflictTables, setConflictTables] = useState([]);
  const [isBookingLoading, setIsBookingLoading] = useState(false);

  return {
    conflictTables,
    setConflictTables,
    isBookingLoading,
    setIsBookingLoading,
    tableBookingSubmit: useCallback(
      async ({ customerInfoData, dataSubmit, btn }, closeTableBookingPanel) => {
        if (btn) btn.setAttribute("disabled", true);

        await BookingAPI.createCustomer(customerInfoData).catch(() =>
          console.log("")
        );
        await BookingAPI.createBooking(dataSubmit)
          .then(({ booking: { overlay_tables } }) => {
            if (btn) btn.setAttribute("disabled", false);

            setConflictTables(overlay_tables ? overlay_tables : []);
            setIsBookingLoading(false);

            closeTableBookingPanel();
            updateBooking();
          })
          .catch((error) => {
            setConflictTables(null);
            setIsBookingLoading(false);
            try {
              const erroMsg = error.responseJSON.errors;
              window.app.alert.setMessage(erroMsg, "error");
              if (btn) btn.setAttribute("disabled", false);
            } catch (e) {
              if (btn) btn.setAttribute("disabled", false);
              window.app.alert.setMessage(
                t("status.pls_tryAgainLater"),
                "error"
              );
            }
          });
      },
      [updateBooking, t]
    ),
  };
};
