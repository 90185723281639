import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router";

import { updateCrmBlockedList } from "../api/CrmSettingsApi";
import useDebounce from "../utils/useDebounce";
import useBlockedList from "../utils/useBlockedList";
import DeleteConfirmModal from "./crmSettingsPopup/DeleteConfirmModal";
import ExpandSearchInput from "../../commons/ExpandSearchInput";
import { DIGITS_ONLY_REGEX } from "../../../utils/regex"

const CrmBlockedList = (props) => {
  const { t } = useTranslation("customer");
  const [isSaved, setIsSaved] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [blockedList, setBlockedList] = useState([]);
  const [blockedListNumber, setBlockedListNumber] = useState("");
  const [blockedListError, setBlockedListError] = useState(""); // empty 未填, duplicate 重複的封鎖號碼
  const [searchBlockedListNumber, setSearchBlockedListNumber] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const [currentList, setCurrentList] = useState([]);
  const [selectNumbers, setSelectNumbers] = useState([]);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const debouncedSearchNum = useDebounce(searchBlockedListNumber, 300);
  const { response, loading, getBlockedList } = useBlockedList();

  const searchInputRef = useRef(null);

  const routerWillLeave = useCallback(() => {
    if (!isSaved && isEditing) return t("settings:leaveNotice");
  }, [isSaved, isEditing, t]);

  useEffect(() => {
    props.router.setRouteLeaveHook(props.route, routerWillLeave);
  }, [props, routerWillLeave]);

  const handleDebounceSearch = useCallback((list = blockedList) => {
    let newBlockedList = [];

    list.forEach((num) => {
      let matchNumber = true;

      for (let i = 0; i < debouncedSearchNum.length; i++) {
        if (debouncedSearchNum[i] !== num[i]) matchNumber = false;
      }

      if (matchNumber) newBlockedList.push(num);
    });

    setCurrentList(newBlockedList);
  }, [blockedList, debouncedSearchNum]);

  useEffect(() => {
    if (response) {
      if (searchBlockedListNumber) {
        handleDebounceSearch(response);
      } else {
        setCurrentList(response);
      }

      setBlockedList(response);
    }
  }, [response, searchBlockedListNumber, handleDebounceSearch]);

  useEffect(() => {
    if (debouncedSearchNum || searchBlockedListNumber) handleDebounceSearch();
  }, [debouncedSearchNum, searchBlockedListNumber, handleDebounceSearch]);

  const handleBlockedListNum = (e) => {
    const value = e.target.value;
    const regTest = DIGITS_ONLY_REGEX.test(value);

    if (value.length === 0 || regTest) {
      setBlockedListNumber(value);
    }
  };

  const updateBlockedList = async (type) => {
    try {
      let newBlockedList = [...currentList];

      if (type === "add") {
        await updateCrmBlockedList({
          phone_numbers: [blockedListNumber],
          operation: "add",
        });

        newBlockedList.push(blockedListNumber);
        setBlockedListError("");
        setBlockedListNumber("");
        window.app.alert.setMessage(t("blockList.addBlockedSuccess"), "done");
      }

      if (type === "delete") {
        await updateCrmBlockedList({
          phone_numbers: selectNumbers,
          operation: "delete",
        });

        newBlockedList = newBlockedList.filter(
          (number) => !selectNumbers.includes(number)
        );
        setSelectNumbers([]);
        setIsEditing(false);
        setIsSaved(true);
        setShowDeleteConfirmModal(false);
        window.app.alert.setMessage(t("blockList.editSuccess"), "done");
      }

      setCurrentList(newBlockedList);
      getBlockedList();
    } catch (err) {
      console.log("crm settings update blocked list err -----", err);
    }
  };

  const addBlockedList = async () => {
    // error: empty 號碼為空, duplicate 重複號碼
    if (blockedListNumber.trim().length === 0) {
      return setBlockedListError("empty");
    }

    if (blockedList.indexOf(blockedListNumber) !== -1) {
      setBlockedListError("duplicate");
      return;
    }

    updateBlockedList("add");
  };

  const cancelSearch = () => {
    setSearchBlockedListNumber("");
    setCurrentList(blockedList);
    setIsSearching(false);
  };

  const handleSearchBlockedList = (e) => {
    const value = e.target.value;
    const regTest = DIGITS_ONLY_REGEX.test(value);

    if (value.length === 0) return cancelSearch();
    if (regTest) {
      setSearchBlockedListNumber(value);
    }
  };

  const handleEditToggle = () => {
    if (!isEditing) setIsSaved(false);
    setIsEditing(!isEditing);
    setSelectNumbers([]);
  };

  const handleCheckboxChange = (phoneNumber) => {
    setSelectNumbers((prevSelected) => {
      if (prevSelected.includes(phoneNumber)) {
        // 如果已經包含，則移除
        return prevSelected.filter((num) => num !== phoneNumber);
      } else {
        // 如果不包含，則加入
        return [...prevSelected, phoneNumber];
      }
    });
  };

  const handleShowDeleteBlockedModalToggle = () => {
    setShowDeleteConfirmModal(!showDeleteConfirmModal);
  };

  const handleRemoveSelected = () => {
    // 清空狀態
    updateBlockedList("delete");
  };

  const renderEditButton = () => {
    if (!isEditing) {
      return (
        <button onClick={handleEditToggle}>
          <img src={require("../../../images/crm/edit_pen_icon.svg")} alt="" />
          {t("blockList.edit")}
        </button>
      );
    } else {
      return (
        <>
          <button
            className="btn_delete"
            onClick={handleShowDeleteBlockedModalToggle}
            disabled={selectNumbers.length === 0}
          >
            <div className="delete_icon" />
            {t("blockList.delete")}
          </button>
          <button className="btn_cancel" onClick={handleEditToggle}>
            <div className="cross_icon"></div>
            {t("blockList.cancel")}
          </button>
        </>
      );
    }
  };

  const renderCurrentList = () => {
    return (
      <div className="crmSettings__block">
        <div
          className={`crmSettings__block_list ${isEditing ? "edit_list" : ""}`}
        >
          {currentList.map((num) => {
            return (
              <div className="crmSettings__list_num" key={num}>
                <label>
                  {isEditing ? (
                    <input
                      type="checkbox"
                      value={num}
                      onClick={() => handleCheckboxChange(num)}
                    />
                  ) : (
                    <div className="placeholderDiv" />
                  )}
                  {num.length > 10 && !isEditing ? (
                    <Tooltip
                      placement="bottomLeft"
                      title={num}
                      trigger="click"
                      color="#333"
                      overlayClassName="crmSettings__list_num_tooltip"
                    >
                      <span style={{ cursor: "pointer" }}>{num}</span>
                    </Tooltip>
                  ) : (
                    <span>{num}</span>
                  )}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderListEmpty = () => {
    if (searchBlockedListNumber.length !== 0) {
      return (
        <div className="crmSettings__block">
          <div className="crmSettings__search-empty">
            {t("blockList.noSearchResult")}
          </div>
        </div>
      );
    } else {
      return (
        <div className="crmSettings_empty">
          {loading ? (
            <LoadingOutlined style={{ fontSize: "36px" }} />
          ) : (
            t("blockList.empty")
          )}
        </div>
      );
    }
  };

  const renderBlockedListError = () => {
    if (blockedListError === "empty")
      return (
        <div className="settingList__error">
          {t("blockList.pls_enterCompleteNumber")}
        </div>
      );
    if (blockedListError === "duplicate")
      return (
        <div className="settingList__error">
          {t("blockList.pls_numberAlreadyExist")}
        </div>
      );
  };

  return (
    <div className="crmSettings crm_setting_sticky">
      <div className="return-bar">
        <Link className="button-round-sm btn-effect" to="/dashboard/customers">
          <img src={require("../../../images/crm/return_icon.svg")} alt="" />
          {t("info.back")}
        </Link>
      </div>
      <div className={`crmSettings_bar ${isSearching ? "in_search_m" : ""}`}>
        <h2 className="heading-2-responsive">{t("blockList.title")}</h2>
        <div className="crmSettings_search action-display-button">
          <ExpandSearchInput
            ref={searchInputRef}
            disabled={isEditing}
            handleInput={handleSearchBlockedList}
            placeholder={t("blockList.search")}
            clearResult={cancelSearch}
            digitalOnly={true}
          />
        </div>
      </div>
      <div className="crmSettings_list">
        <div className="crmSettings_input">
          <>
            <input
              type="text"
              className={`${
                blockedListError ? "crmSettings_input-error" : ""
              } form-control`}
              value={blockedListNumber}
              onChange={(e) => handleBlockedListNum(e)}
              disabled={isEditing || searchBlockedListNumber.length !== 0}
              placeholder={t("blockList.placeholder_add")}
            />
            <button
              className="button-primary"
              onClick={addBlockedList}
              disabled={isEditing || searchBlockedListNumber.length !== 0}
            >
              {t("blockList.add")}
            </button>
          </>
          {blockedListError && renderBlockedListError()}
        </div>
        {currentList.length > 0 && (
          <div className="crmSettings__block_edit">{renderEditButton()}</div>
        )}
        {currentList.length > 0 ? renderCurrentList() : renderListEmpty()}
      </div>

      {showDeleteConfirmModal && (
        <DeleteConfirmModal
          type="blockedList"
          submit={handleRemoveSelected}
          cancel={handleShowDeleteBlockedModalToggle}
          selectNumbers={selectNumbers}
        />
      )}
    </div>
  );
};

export default CrmBlockedList;
