import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, Space } from "antd";

const DeleteBlockedModal = ({
  type,
  submit,
  cancel,
  selectNumbers,
  selectTag = {},
}) => {
  const { t } = useTranslation("customer");

  const renderContent = () => {
    if (type === "blockedList") {
      return t("blockList.deleteNumber_content", {
        count: selectNumbers.length,
      });
    }

    if (type === "settingTag") {
      return `刪除後有「${selectTag.name}」標記的顧客都將同時移除此標記註記。確定刪除嗎？`;
    }
  };
  return (
    <Modal
      title={t("blockList.deleteNumber_title")}
      visible
      destroyOnClose
      width={374}
      centered
      closable={false}
      className="modal-base modal-sm"
      footer={
        <Space size={8}>
          <button className="button-common button-secondary" onClick={cancel}>
            {t("settings:cancel")}
          </button>
          <button className="button-common button-primary" onClick={submit}>
            {t("settings:ok")}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {renderContent()}
    </Modal>
  );
};

export default DeleteBlockedModal;
