import React, { Fragment } from "react";
import $ from "jquery";
import { Space } from "antd";
import { withTranslation } from "react-i18next";

import BookingRow from "./BookingRow";
import { BookingListTab } from "./BookingListTab";
import { bookingStatuses } from "../../../utils/constants";
import NoBookingsView from "../NoBookingsView";

class BookingListView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bookings: this.props.bookings,
      customQ: [],
      bookingTimeCount: {},
      serviceTimeOptionEnabled: this.props.serviceTimeOptionEnabled,
    };
  }

  componentDidMount() {
    $('[data-toggle="popover"]').popover({
      html: true,
      trigger: "click",
      container: ".container",
    });
    $("body").on("click", function (e) {
      if (
        $(e.target).data("toggle") !== "popover" &&
        $(e.target).parents('[data-toggle="popover"]').length === 0 &&
        $(e.target).parents(".popover.in").length === 0
      ) {
        $('[data-toggle="popover"]').popover("hide");
      }
    });

    this.countAttendancePerBookingTime();
  }

  countAttendancePerBookingTime = () => {
    const { bookings } = this.state;
    let bookingTimeCount = {};

    bookings.forEach((booking) => {
      if (bookingStatuses.includes(booking.status)) {
        let hour = booking.time.slice(0, 2);
        let bookingTime = `${hour}:00`;

        // init
        if (!bookingTimeCount[bookingTime]) {
          bookingTimeCount[bookingTime] = {
            groupCount: {},
            statusCount: {},
          };
        }

        // group
        if (!bookingTimeCount[bookingTime].groupCount[booking.status]) {
          bookingTimeCount[bookingTime].groupCount[booking.status] = 0;
        }
        bookingTimeCount[bookingTime].groupCount[booking.status] += 1;

        // attendance
        if (!bookingTimeCount[bookingTime].statusCount[booking.status]) {
          bookingTimeCount[bookingTime].statusCount[booking.status] = 0;
        }
        bookingTimeCount[bookingTime].statusCount[booking.status] +=
          booking.attendance;
      }
    });

    this.setState({ bookingTimeCount });
  };

  componentDidUpdate(oldProps, oldState) {
    let { customQ, bookings } = this.props;
    $('[data-toggle="popover"]').popover({
      html: true,
      trigger: "click",
      container: ".container",
    });

    if (oldState.bookings !== bookings) {
      this.setState(
        {
          bookings,
        },
        () => this.countAttendancePerBookingTime()
      );
    }

    if (oldState.customQ !== customQ) {
      this.setState({ customQ });
    }
  }

  handleScroll = () => {
    let { scrollToActiveCode } = this.props;

    if (scrollToActiveCode) {
      let scrollDiv = document.getElementById(scrollToActiveCode.activeCode);

      if (scrollDiv) {
        let scrollDiv_offsetTop = scrollDiv.offsetTop;
        window.scrollTo(0, scrollDiv_offsetTop);
        scrollDiv.classList.add("active_scroll");

        setTimeout(() => {
          scrollDiv.classList.remove("active_scroll");
          delete scrollToActiveCode["activeCode"];
        }, 1500);
      }
    }
  };

  filterBookings = (status) => {
    const { bookings } = this.state;
    return bookings.filter((booking) => booking.status === status);
  };

  renderBookingsByStatus = (status) => {
    const { customQ, bookingTimeCount, serviceTimeOptionEnabled } = this.state;
    const filteredBookings = this.filterBookings(status);
    const { t } = this.props;

    if (filteredBookings.length === 0) {
      return <NoBookingsView />;
    }

    return (
      <section>
        {filteredBookings.map((booking, index) => {
          let hour = booking.time.slice(0, 2);
          let timeZone = `${hour}:00`;
          const showTimeTitle =
            index === 0 ||
            filteredBookings[index - 1].time.slice(0, 2) !== hour;
          const isLastInTimeSlot =
            index === filteredBookings.length - 1 ||
            filteredBookings[index + 1].time.slice(0, 2) !== hour;

          const groupCount =
            bookingTimeCount[timeZone]?.groupCount[status] || 0;
          const attendanceCount =
            bookingTimeCount[timeZone]?.statusCount[status] || 0;

          return (
            <Fragment key={booking.id}>
              {showTimeTitle && (
                <div className="bookingSectionTitle">
                  <span className="horizontal-center">
                    <Space size={6} className="horizontal-center">
                      <span className="icon-sm clock-icon" />
                      {timeZone}
                    </Space>
                  </span>
                  <span className="separation-line-icon" />
                  <span>
                    {t("count.groupCount")}
                    {groupCount}
                  </span>
                  <span>
                    {t("count.guestCount")}
                    {attendanceCount}
                  </span>
                </div>
              )}
              <BookingRow
                customQ={customQ}
                booking={booking}
                bookings={filteredBookings}
                status={booking.status}
                setStatus={this.props.setStatus}
                showAddCustomerModal={this.props.showAddCustomerModal}
                showNewAddBookingModal={this.props.showNewAddBookingModal}
                serviceTimeOptionEnabled={serviceTimeOptionEnabled}
                isLastInTimeSlot={isLastInTimeSlot}
              />
              {!isLastInTimeSlot && index !== filteredBookings.length - 1 && (
                <div className="bookingList-row-divider">
                  <hr />
                </div>
              )}
              {isLastInTimeSlot && index !== filteredBookings.length - 1 && (
                <div className="timeSlot-dashed-divider" />
              )}
            </Fragment>
          );
        })}
      </section>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <div style={{ textAlign: "center", margin: "20px 0" }}>LOADING ...</div>
      );
    }

    return (
      <Fragment>
        <div className="bookingBar">
          <BookingListTab
            scrollToActiveCode={this.props.scrollToActiveCode}
            changeBookingListStatusTab={this.props.handleBookingListStatusTab}
            activeBookingListStatusTab={this.props.activeBookingListStatusTab}
            bookings={this.state.bookings}
          />
        </div>
        <div className="listViewWrapper">
          {this.renderBookingsByStatus(this.props.activeBookingListStatusTab)}
          {this.props.scrollToActiveCode && this.handleScroll()}
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("bookingSystem")(BookingListView);
