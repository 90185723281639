import React from "react";
import { useTranslation } from 'react-i18next';
import { Modal, Space } from 'antd';

const CheckModal = ({
  title,
  content,
  submit,
  cancel
}) => {
  const { t } = useTranslation('settings');
  return (
    <Modal
      title={title}
      visible
      destroyOnClose
      width={374}
      centered
      closable={false}
      className="modal-base modal-sm"
      footer={
        <Space size={8}>
          <button
            className="button-common button-secondary"
            onClick={cancel}
          >
            {t('cancel')}
          </button>
          <button
            className="button-common button-primary"
            onClick={submit}
          >
            {t('ok')}
          </button>
        </Space>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {content}
    </Modal>
  )
}

export default CheckModal;