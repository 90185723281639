import React, { Fragment } from "react";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Menu, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import {
  announcementSettingEnded,
  announcementChange,
  handleHeaderFilter,
  showAnnouncementAddModal,
  clearAnnouncement,
} from "../../../actions/announcementAction";

import { updateOfficialAnnouncementDisplay } from "../api/OfficialAnnouncementApi";
import { dayList, dayListAbbrevEN } from "../../../utils/constants";

const AnnouncementPreviewModal = ({ isDashboard, currentTab }) => {
  const { t, i18n } = useTranslation("bulletinSystem");
  const announcementListReducer = useSelector(
    (state) => state.announcementListReducer
  );
  const { role, page, currentFilter } = announcementListReducer;
  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const {
    id,
    type,
    category,
    title,
    content,
    startDateTime,
    endDateTime,
    showEnabled,
    finished,
    officialShowEnabled,
    officialDisplayMode,
  } = announcementInfo;
  const dispatch = useDispatch();

  const typeTxt = {
    regular: t("editAnnouncement.status.normal"),
    long_term: t("editAnnouncement.status.persistent"),
  };
  const announceEditList = [
    { title: t("edit"), action: "edit" },
    { title: t("list.setAsExpired"), action: "finished" },
    { title: t("list.delete"), action: "deleted" },
  ];

  const statusSelectList = [
    { title: t("list.displayOnceDaily"), action: "once" },
    { title: t("list.neverShowAgain"), action: "never" },
  ];

  const cancelPopup = () => {
    dispatch(clearAnnouncement());
  };

  const handleShowFrequency = async (value, id) => {
    let setting = {
      id: id,
      display_mode: value,
    };

    try {
      await updateOfficialAnnouncementDisplay(setting);
      dispatch(handleHeaderFilter(currentFilter, page));
      dispatch(announcementChange("officialDisplayMode", value));
    } catch (err) {
      console.log("set official announcement frequency err ----------", err);
    }
  };

  const handleEditor = (item) => {
    if (item === "edit") {
      dispatch(showAnnouncementAddModal());
    } else if (item === "finished" || item === "deleted") {
      dispatch(announcementSettingEnded(item));
    } else if (item === "once" || item === "never") {
      handleShowFrequency(item, id);
    }
  };

  const renderEditList = () => {
    if (currentTab === "shop" || role === "official") {
      const editList = _.cloneDeep(announceEditList);
      let newEditList = [];

      if (finished) {
        newEditList = editList.filter((i) => i.action !== "finished");
      } else {
        newEditList = announceEditList;
      }

      return (
        <Menu>
          {newEditList.map((item) => {
            return (
              <Menu.Item
                className={item.action === "deleted" ? "option_deleted" : ""}
                key={item.action}
                onClick={() => handleEditor(item.action)}
              >
                {item.title}
              </Menu.Item>
            );
          })}
        </Menu>
      );
    } else {
      return (
        <Menu>
          {statusSelectList.map((item) => {
            return (
              <Menu.Item
                key={item.action}
                onClick={() => handleEditor(item.action)}
              >
                {item.title}
              </Menu.Item>
            );
          })}
        </Menu>
      );
    }
  };

  const renderTitleAndEdit = () => {
    if (!isDashboard) {
      return (
        <div className="title">
          {title}
          <Dropdown
            className={`${
              type === "official" && role === "shop" && finished
                ? "listCardDropDown_disabled"
                : ""
            } listCardDropDown`}
            placement="bottomRight"
            overlay={renderEditList()}
            trigger={["click"]}
            overlayClassName="announcementPreviewModal_dropDown_menu"
            disabled={type === "official" && role === "shop" && finished}
          >
            <MoreOutlined />
          </Dropdown>
        </div>
      );
    } else {
      return <div className="title">{title}</div>;
    }
  };

  const renderStatus = () => {
    if (type === "official") {
      if (role === "official") {
        return (
          <Fragment>
            <span>
              {finished
                ? t("previewPopup.official.finished")
                : t("list.systemAnnouncement")}
            </span>
            {!officialShowEnabled ? t("previewPopup.official.closed") : null}
          </Fragment>
        );
      }

      if (role === "shop") {
        return (
          <Fragment>
            <span>{t("list.systemAnnouncement")}</span>
            {finished ? t("previewPopup.shop.closed") : null}
            {!finished && officialDisplayMode === "never"
              ? t("previewPopup.shop.officialDisplayDailyClosed")
              : null}
          </Fragment>
        );
      }
    }

    return (
      <Fragment>
        <span>
          {finished ? t("previewPopup.official.finished") : typeTxt[category]}
        </span>
        {!finished && showEnabled && category !== "long_term"
          ? t("previewPopup.shop.displayOnSystem")
          : null}
      </Fragment>
    );
  };

  const renderStartTime = () => {
    const days = i18n.language === "zh" ? dayList : dayListAbbrevEN;
    let day_start = new Date(startDateTime).getDay();
    let dateTime = "";

    if (startDateTime) {
      dateTime =
        moment(startDateTime).format("YYYY-MM-DD") +
        "（" +
        days[day_start] +
        "）" +
        moment(startDateTime).format("HH:mm");
    }

    return (
      <div className="dateTime">
        {t("list.startDate")}：{dateTime}
      </div>
    );
  };

  const renderEndTime = () => {
    const days = i18n.language === "zh" ? dayList : dayListAbbrevEN;
    let day_end = new Date(endDateTime).getDay();
    let dateTime = "";

    if (endDateTime) {
      dateTime =
        moment(endDateTime).format("YYYY-MM-DD") +
        "（" +
        days[day_end] +
        "）" +
        moment(endDateTime).format("HH:mm");

      return (
        <div className="dateTime">
          {t("list.endDate")}：{dateTime}
        </div>
      );
    }
  };

  return (
    <Modal
      visible
      centered
      destroyOnClose
      width={640}
      closable={false}
      className="modal-base modal-xl announcementPreviewModal"
      footer={
        <button className="button-common button-primary" onClick={cancelPopup}>
          {t("close")}
        </button>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className="header">
        {renderTitleAndEdit()}
        <div className="type">{renderStatus()}</div>
        {renderStartTime()}
        {renderEndTime()}
      </div>
      <div className="content">{content}</div>
    </Modal>
  );
};

export default AnnouncementPreviewModal;
