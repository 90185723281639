import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import QueueListItem from "./QueueListItem";

class QueueSearchResult extends Component {
  refreshAction = (item) => {
    this.props.reSearchAction();
    this.props.refreshAction();
  };

  render() {
    const { t } = this.props;
    let recordContents = [];
    let count = 0;
    if (this.props.records !== undefined) {
      this.props.records.forEach((item) => {
        if (item.status === "waiting") {
          recordContents.push(
            <QueueListItem
              data={item}
              searching={true}
              hideAction={false}
              hideSeatedAction={false}
              hideStandbyAction={false}
              timeCheckingCondition={this.props.timeCheckingCondition}
              notificationAction={this.props.notificationAction}
              refreshAction={() => {
                this.refreshAction(item);
              }}
              groupInfo={this.props.groupTypes.find(
                (group) => group.title === item.group_name
              )}
              waitingRecords={this.props.waitingRecords}
            />
          );
        } else if (item.status === "seated") {
          recordContents.push(
            <QueueListItem
              data={item}
              searching={true}
              hideAction={true}
              hideSeatedAction={true}
              hideStandbyAction={true}
              timeCheckingCondition={this.props.timeCheckingCondition}
              notificationAction={this.props.notificationAction}
              refreshAction={() => {
                this.refreshAction(item);
              }}
            />
          );
        } else if (item.status === "standby") {
          recordContents.push(
            <QueueListItem
              data={item}
              searching={true}
              hideAction={false}
              hideSeatedAction={false}
              hideStandbyAction={true}
              timeCheckingCondition={this.props.timeCheckingCondition}
              notificationAction={this.props.notificationAction}
              refreshAction={() => {
                this.refreshAction(item);
              }}
            />
          );
        } else if (item.status === "canceled") {
          recordContents.push(
            <QueueListItem
              data={item}
              searching={true}
              hideAction={true}
              hideSeatedAction={true}
              hideStandbyAction={true}
              timeCheckingCondition={this.props.timeCheckingCondition}
              notificationAction={this.props.notificationAction}
              refreshAction={() => {
                this.refreshAction(item);
              }}
            />
          );
        }
      });
      count = this.props.records.length;
    }

    return (
      <div className="queue-search-result">
        <div className="search-title-container">
          <h3 className="search-result-title">{t("panel.searchResult")}</h3>
          <button
            className="button-round-icon btn-effect"
            onClick={this.props.clearSearchResult}
          >
            <img src={require("../../images/queue/icon_dismiss.png")} alt="" />
          </button>
        </div>
        <div>
          <p className="search-result-subtitle">
            {t("panel.search")}：
            {this.props.keyword !== undefined ? this.props.keyword : ""}{" "}
            {t("panel.searchCount", { count })}
          </p>
          {recordContents}
        </div>
      </div>
    );
  }
}

export default withTranslation("queueSystem")(QueueSearchResult);
