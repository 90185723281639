import React from "react";

const MenuCouponPopup = ({ children }) => {
  return (
    <div className="menuCouponPopup">
      <div className="menuCouponPopupLayout" />
      {children}
    </div>
  );
};

export default MenuCouponPopup;
