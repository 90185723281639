import React from "react";
import moment from "moment";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";

const BookingIcon = () => {
  const { t } = useTranslation();
  return (
    <div className="dashboard-item">
      <Link
        to={{
          pathname: "/dashboard/new_module",
          query: { date: moment().format("YYYY-MM-DD"), mode: "timeline" },
        }}
        role="button"
      >
        <div className="dashboard-thumbnail">
          <img
            alt="booking"
            src={require("../../images/dashboard/booking.svg")}
          />
          <div className="dashboard-system-description text-body">
            {t("dashboard:bookingSystemDescription")}
          </div>
          <div className="horizontal-between">
            <h3 className="heading-3-responsive">
              {t("common:system.booking")}
            </h3>
            <div className="icon-normal doubleArrowRight-icon" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BookingIcon;
