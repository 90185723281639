
//桌位圖桌子無預約狀態
export const $tableStatusEmptyColor = 'rgb(255, 255, 255)';

//桌子名稱＆人數
export const $canvasTableNameColor = '#676767';
export const $canvasTableNameColor_white = '#fff';
export const $canvasTableAttendanceColor = 'rgba(51, 51, 51, 0.60)';
export const $canvasTableAttendanceColor_white = '#fff';

//桌位圖桌子Border狀態
export const $tableBorderColor_normal = '#DCDCDC';

//選中桌子
export const $selectedColor = '#F8BA4C';
export const $selectedTableColor = '#676767'; // 換位模式：一對多

//桌子各種icon
export const $canvasAddBookingBtnColor = '#3fba88';
export const $canvasCancelBookingBtnColor = '#c8c8c8';
export const $tableNextBookingColor_normal = '#525252';
export const $tableNextBookingColor_white = '#fff';
export const $tableNextBookingTxtColor_gray = 'rgba(51, 51, 51, 0.75)';
export const $tableNextBookingTxtColor_white = '#fff';
export const $progressBarNormalColor = '#fff';
export const $progressBarAlertColor = '#EF6868';
export const $progressBaseBarColor = 'rgba(0, 0, 0, 0.30)';

//for tableStatus function (桌子預約狀態)
export const $status_confirmed = '#61B087';
export const $status_unconfirmed = '#FF991F';
export const $status_cancel = '#DCDCDC';
export const $status_noShow = '#DCDCDC';
export const $status_show = '#4B9AC3';
export const $status_seated = '#647E96';
export const $status_check = 'rgb(67, 70, 73)';

//強制建立預約
export const $forceBookingBackgroundColor= '#dcdcdc';
export const $forceBookingBgColor= '#fff';
export const $forceBookingBorderColor= '#dcdcdc';
export const $forceBookingProgressBackgroundColor= '#525252';
export const $forceBookingAttendanceTxtColor= '#979797';
export const $forceBookingAttendanceTxtColor_selected= '#ffffff';
