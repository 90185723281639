import React from "react";
import { Tooltip } from "antd";

function NotificationTitle({ title, tooltip }) {
  return (
    <div className="heading-2-responsive notification-title">
      {title}
      <Tooltip
        placement="bottom"
        overlayClassName="setting-tooltip"
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        title={tooltip}
      >
        <i className="fa fa-question-circle-o" />
      </Tooltip>
    </div>
  );
}

export default NotificationTitle;
